import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Styled from "styled-components";
import ico_checked from "images/ico_check_w.png";
import m_ico_checked from "images/m/ico_check_w.png";
import filter_c from "images/filter_black.png";
import m_filter_c from "images/m/filter_black.png";
import filter_w from "images/filter_white.png";
import icon_refresh from "images/icon_refresh.png";
import m_icon_refresh from "images/m/icon_refresh.png";
import filter_close from "images/filter_close.png";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import m_list_open from "images/m/m_list_open.png"

const CategoryFilter = ({ mainCategoryId, onSubmit, update, mainCateType, selectedVal }) => {
  const isPc = useMediaQuery({ query: '(min-width: 961px)' })
  const dispatch = useDispatch();
  const category = useSelector(
    (state) => state.categoryReducer.categoryProducts
  );

  useEffect(() => {
  }, [category])
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState("");
  const fetchFilters = async () => {
      setShow(false);
    try {
      const varramAPI = `${process.env.REACT_APP_API_URL}api/product/filter/${mainCategoryId}`;
      const res = await axios.get(varramAPI);
      let sort_data = res.data.sort(function (a,b){
        return a.order - b.order
      });
      var a ={};

      sort_data.map(data=>{
        if(!a[data.id]){
          a[data.id] = {all: false}
        }
        data.sub_filters.map(filter=>{
          a[data.id][filter.id] = false;
        })
      })
      setFilters(sort_data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, [setFilters, mainCategoryId]);
  useEffect(() => {
      for (let i = 0; i <filters.length; i++) {
          filters[i].selected = false;
          for (let j = 0; j < filters[i].sub_filters.length; j++) {
              filters[i].sub_filters[j].selected = false;
          }
      }
      setFilters(filters);
      setShow(false);
  }, [update])

  const removeFilter = () => {
    fetchFilters();
    onSubmit(null);
    setShow(show);
  };
  const submitFilter = () => {
    onSubmit(filters);
    if(isPc){
        setShow(show);
    }else{
        setShow(!show);
    }

  };
  const clickAllHander = (group_id, boolean) => {
    let parent = filters.find(filter => filter.id == group_id);
    parent.sub_filters.map(sub => {
        sub.selected = boolean;
      return sub
    });
    parent.selected = boolean;
    let refilter =  filters.map(filter => {
      if(filter.id == parent.id){
        return parent;
      }
      return filter
    })
    setFilters(refilter);
  };
  const clickHander = (tag, boolean, group_id) => {
    let parent = filters.find(filter => filter.id == group_id);
    tag.selected = boolean;
    let boolean_all = true;

    parent.sub_filters.map(sub => {
      if(sub.selected != true){
        boolean_all = false;
      }
      if(sub.id == tag.id){
        return tag;
      }
      return sub
    });
    parent.selected = boolean_all;
    let refilter =  filters.map(filter => {
      if(filter.id == parent.id){
        return parent;
      }
      return filter
    })
    setFilters(refilter);
  };

  const showFilter = (e) => {
      setShow(!show);
      if (e.target.name === "close") {
          for (let i = 0; i < filters.length; i++) {
              for (let j = 0; j < filters[i].sub_filters.length; j++) {
                  if (filters[i].sub_filters[j].selected) {
                      filters[i].sub_filters[j].selected = false
                  }
              }
              clickAllHander(filters[i].id, false)
          }
          setFilters(filters);
      }
  };
    return (
        <>
            {mainCategoryId === 2 || mainCategoryId === 19 ?
              <FilterBtn>
                  <button className="drag_button" onClick={showFilter} >
                      <span className="filter_icon"></span>
                      맞춤 필터 적용{isPc && "하기"}
                  </button>
                  {/* 정기배송 필터 */}
                  {mainCateType !== "regular" ? null :<select className="select_filter" onChange={(e) => {selectedVal(e)}}>
                      <option value="추천순">추천순</option>
                      <option value="인기순">인기순</option>
                      <option value="리뷰순">리뷰순</option>
                      <option value="낮은가격순">낮은가격순</option>
                      <option value="높은가격순">높은가격순</option>
                  </select>}
              </FilterBtn> : null }
            {/* 맞춤 필터 적용 */}
            {show ? <Category>
                <div className="category_wrap">
                    <p className="tit_wrap">맞춤 필터 적용</p>
                    <div className="content_wrap custom_scroll">
                        {filters.map((tags) => {
                                return <div className="wrapper"  key={tags.id}>
                                    <div className="contents-left">
                                        <span className="text">{tags.name}</span>
                                    </div>
                                    <div className="contents-right-fold">
                                        <div className="checks">
                                            <input type="radio" key={tags.id} name={tags.id} id={tags.id}
                                                   checked={tags.selected ? "checked" : ""}
                                                   className={
                                                     tags.selected ? 'clickedBtn' : 'unClickedBtn'
                                                   }
                                                   onChange={
                                                     tags.selected
                                                           ? (e) => clickAllHander(tags.id, false)
                                                           : (e) => clickAllHander(tags.id, true)
                                                   }
                                            />
                                            <label htmlFor={tags.id}>전체</label>
                                        </div>
                                        {tags["sub_filters"].map((tag) => {
                                            return <div className="checks" key={tag.id} >
                                                <input type="radio" name={tag.id} id={tag.id}
                                                       checked={tag.selected ? "checked" : ""}
                                                       className={
                                                         tag.selected ? 'clickedBtn' : 'unClickedBtn'
                                                       }
                                                       onChange={
                                                         tag.selected
                                                               ? (e) => clickHander(tag, false, tags.id)
                                                               : (e) => clickHander(tag, true, tags.id)
                                                      }
                                                />
                                                <label htmlFor={tag.id}>{tag.name}</label>
                                            </div>
                                        })}
                                    </div>
                                </div>
                        })}
                    </div>
                </div>
                <div className ="controll_btn">
                    <button onClick={submitFilter}>적용하기</button>
                    <button onClick={removeFilter} className="reset_btn"><span>초기화</span></button>
                </div>
                <div className="close_btn_wrap">
                    <span onClick={showFilter} className="close_btn" name="close">
                        <img src={filter_close} alt="닫기" name="close"/>
                    </span>
                </div>
            </Category> : null}
        </>
    );
};

const Category = Styled.div`
  position: relative;
  margin: 15px auto 87px;
  display: flex;
  flex-direction: column;
  max-width:1480px;
  width: 100%;
  padding: 35px 40px 30px 40px;
  border-radius: 5px;
  background-color: #f6f6f6;

  @media only screen and (max-width: 960px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    min-height: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background: #fff;
  }

  & .tit_wrap {
    display: none;
    @media only screen and (max-width: 960px) {
      position: relative;
      display: block;
      padding: 17px 0;
      text-align: center;
      font-size: 1.125rem;
      line-height: 27px;
      font-weight: 500;
      border-bottom: 1px solid #F2F4F6;

      img {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 17px;
        right: 17px;
      }
    }
  }

  & .content_wrap {
    overflow-y: initial;
    @media only screen and (max-width: 960px) {
      overflow-y: auto;
      padding-bottom: 87px;
      height: calc(100vh - 125px);
    }
    & .wrapper {
      display: flex;
      font-size: 1rem;
      padding-bottom: 25px;
      margin-bottom: 25px;
      &:not(:last-child) {
        border-bottom: 1px solid #fff;
      }
      @media only screen and (max-width: 960px) {
        padding: 24px 15px 0 24px;
        margin: 0;
        flex-direction: column;
        &:not(:last-child) {
          & .contents-right-fold {
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
    
    & .contents-left {
      padding-left: 20px;
      font-weight: 500;
      display: flex;
      flex: 120px 0 0;
      align-items: center;
      color: #222;

      & span {
        line-height: 24px;
      }

      @media only screen and (max-width: 960px) {
        flex: auto 0 0;
        padding: 0;
        font-size: 1.125rem;
        line-height: 27px;

        & span {
          margin-bottom: 11px;
        }
      }
    }
  }

  & .contents-right-fold {
    display: flex;
    align-items:center;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (max-width: 960px) {
      padding-bottom: 24px;
    }

    & .checks {
      padding: 5px 0;
      & input[type="radio"] + label {
        position: relative;
        min-width: 130px;
        font-size: 1rem;
        cursor: pointer;
      }
      &:first-child {
        & input[type="radio"] + label {
          min-width: 110px;
        }
      }
      & input[type="radio"] + label::before{
        background-repeat: no-repeat;
        background-position: center;
        background-size: 10px auto;
      }

      & input[type="radio"].unClickedBtn + label {
        color: #515355;
      }

      & input[type="radio"].clickedBtn + label{
        color: #1EA0FA;
      }
      & input[type="radio"].clickedBtn + label::before{
        background-image: url(${ico_checked});
        background-color: #1EA0FA;
        border: 1px solid #1EA0FA;
      }
      
      & input[type="radio"].clickedBtn + label::after{
        color: #fff;
      }

      @media only screen and (max-width: 960px) {
        padding: 0;
        margin: 5px 10px 5px 0;
        & input[type="radio"] + label {
          min-width: auto;
          font-size: 0.938rem;
        }
        &:first-child {
          & input[type="radio"] + label {
            min-width: auto;
          }
        }
        & input[type="radio"] + label::before{
          background-size: 12px auto;
        }
        & input[type="radio"].unClickedBtn + label {
          padding: 10px 20px 10px 16px;
          background: #F2F4F6;
          border-radius: 20px;
          color: #515355;
          font-size: 0.938rem;
        }
        & input[type="radio"].unClickedBtn + label:before {
          margin-right: 5px;
          width: 15px;
          height: 16px;
          border: none;
          background-color: transparent;
        }

        & input[type="radio"].clickedBtn + label {
          padding: 10px 20px 10px 16px;
          background-color: #1ea0fa;
          border-radius: 20px;
          color: #fff;
        }
        & input[type="radio"].clickedBtn + label:before {
          margin-right: 5px;
          width: 15px;
          height: 16px;
          border: none;
          background-image: url(${m_ico_checked});
        }
      }
    }
  }
  
  & .controll_btn{
    display:flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 960px) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 9999;
    }
    
    & button {
      position: relative;
      border: none;
      border: 1px solid #f6f6f6;
      width: 140px;
      height: 44px;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 4px;
      box-sizing: border-box;
      background: #f6f6f6;
      color: #888;
      cursor: pointer;
      &:first-child {
        background-color: #1EA0FA;
        color: #fff;
        @media only screen and (max-width: 960px) {
          width: calc(100% - 63px);
          margin-left: 63px;
          background: #1EA0FA;
          border: 1px solid #1EA0FA;
          color: #fff;
        }
      }
      
      &.reset_btn span {
        padding-right: 20px;
        background: url(${icon_refresh}) no-repeat right center;
      }
      
      &:first-child:hover{
          background-color: #1EA0FA;
          color: #fff;
      }

      @media only screen and (max-width: 960px) {
        width: auto;
        height: 63px;
        border-radius: 0;
        &.reset_btn {
          position: absolute;
          top: 0;
          left: 0;
          width: 63px;
          background: #fff;
          border: 1px solid #ddd;

          & span {
            display: inline-block;
            content: "";
            width: 20px;
            height: 20px;
            background: url(${m_icon_refresh}) no-repeat right center;
            background-size: 20px auto;
            text-indent: -9999px;
          }
          & img {
            width: 20px;
            margin: 0;
            vertical-align: middle;
          }
        }
      }
    }
  }

  & .close_btn_wrap {
    position: absolute;
    bottom: 30px;
    right: 30px;
    color: #222;
    cursor: pointer;
    @media only screen and (max-width: 960px) {
      top: 20px;
      right: 20px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
const FilterBtn = Styled.div`
    display: flex;
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
    @media only screen and (max-width: 960px) {
        padding: 0 15px;
        margin-bottom: 30px;
    }
    
  & .drag_button {
     position: relative;
     width: 236px;
     height: 57px;
     border-radius: 5px;
     background-color:#fff;
     box-sizing: border-box;
     border: 1px solid #515355;
     line-height: 30px;
     cursor: pointer;
     font-size: 18px;
     font-weight: 500;

    @media only screen and (max-width: 960px) {
        width: 150px;
        height: 45px;
        font-size: 0.875rem;
        line-height: 21px;
    }
     
     & .filter_icon {
        background: url(${filter_c});
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        display: inline-block;
        vertical-align: -7px;
        padding-right: 10px;

        @media only screen and (max-width: 960px) {
            width: 15px;
            height: 15px;
            background: url(${m_filter_c});
            background-size: cover;
            vertical-align: -3px;
            margin-right: 10px;
        }
     }
  }
  @media only screen and (min-width: 961px) {
    & .drag_button:hover,
      .drag_button:focus{
          color: #fff;
          background-color: #1EA0FA;
          border: 1px solid #1ea0fa;
    }
    
    & .drag_button:hover > .filter_icon,
    .drag_button:focus > .filter_icon{
          background: url(${filter_w});
          background-repeat: no-repeat;
    }
  }

  & .select_filter {
        width: auto;
        margin-left: auto;
        border: none;
        background: url(${m_list_open}) no-repeat right center;
        background-size: 14px 8px;
        padding: 0 25px 0 10px;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        @media only screen and (max-width: 960px) {
            background-size: 9px auto;
            font-size: 0.813rem;
            padding: 0 15px 0 10px;
            line-height: 19px;
        }
  }
`;

export default CategoryFilter;
