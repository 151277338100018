import {
    FETCH_REVIEW_REQUEST,
    FETCH_REVIEW_SUCCESS,
    FETCH_REVIEW_FAIL, FETCH_REVIEW_ALL, DELETE_REVIEW,
} from '../actions/types';

export const userReviewList = (state = { loading: true, reviews: []}, action) => {
    switch(action.type){
        case FETCH_REVIEW_ALL:
            return {reviews: action.payload}
        case FETCH_REVIEW_REQUEST:
            return { ...state, loading: true, reviews: [] }
        case FETCH_REVIEW_SUCCESS:
            return {loading: false, reviews: action.payload,}
        case FETCH_REVIEW_FAIL:
            return {loading: false, error: action.payload};
        case DELETE_REVIEW:
            return { ...state, reviews: state.reviews.filter(x => x.id !== action.payload),}
        default:
            return state;
    }
}
