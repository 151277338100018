import React from "react";
import styled from "styled-components";
import icon_call from "images/icon/icon_call.png";

const CustomerPage = () => {
	return (
    <CustomerContainer>
      <div className="board_wrap">
        <div className="wrap_title board_title">
          <h2>고객만족센터</h2>
        </div>
        <div className="customer_wrap">
          <div className="left">
            <p className="main_tit">더 행복한 반려생활,<br />가치있는 반려생활을 위해<br />최고의 서비스를 약속합니다. 
</p>
            <div className="customr_info">
              <p>
                <span className="tit">운영 시간</span>
                <span className="txt">09:30 ~ 17:30</span>
              </p>
              <p>
                <span className="tit">점심 시간</span>
                <span className="txt">12:00 ~ 13:00</span>
              </p>
              <p>
                <span className="tit">전화</span>
                <span className="txt">1670.8921</span>
              </p>
            </div>
            <a href="http://pf.kakao.com/_JTxbpT/chat" rel="noopener noreferrer" target="_blank">빠르고 쉬운 카카오톡 채널 1:1 문의하기</a>
            <p className="cutomer_desc">문의가 많아 상담사 연결이 지연되고 있습니다.<br />카카오톡 채널로 문의 사항을 남겨주시면 가장 빠른 확인이 가능합니다.</p>
          </div>
          <div className="right">
            <img src={icon_call} alt="이미지" />
          </div>
        </div>
      </div>
    </CustomerContainer>
	);
}

const CustomerContainer = styled.div`
  & .customer_wrap {
    display: flex;
    margin-top: 60px;
    padding-right: 20px;

    & .left {
      & .main_tit {
        display: inline-block;
        max-width: 500px;
        margin-bottom: 40px;
        font-size: 1.875rem;
        font-weight: 700;
        line-height: 45px;
        color: #222;
      }
      & .customr_info {
        margin-bottom: 40px;
        p {
          &:not(:first-child) {
            margin-top: 10px;
          }
          & .tit {
            display: inline-block;
            min-width: 77px;
            font-size: 0.938rem;
            color: #515355;
            line-height: 19.5px;
            font-weight: 700;
          }
          & .txt {
            font-size: 1.125rem;
            color: #515355;
            line-height: 23.4px;
          }
        }
      }

      a {
        display: inline-block;
        padding: 20px 30px;
        margin-bottom: 20px;
        border-radius: 4px;
        background: #FEE458;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 27px;
        color: #222;
      }

      .cutomer_desc {
        font-size: 1rem;
        color: #222;
        line-height: 24px;
      }
    }

    & .right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 740px;
      height: 430px;
      margin-left: auto;
      background: #f0f3f5;
      border-radius: 20px;
    }
  }

  @media only screen and (max-width: 960px) {
    & .customer_wrap {
      flex-direction: column-reverse;
      margin-top: 0;
      padding: 0;

      & .left {
        text-align: center;
        & .main_tit {
          margin-bottom: 30px;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 30px;
          text-align: center;
        }
        & .customr_info {
          display: inline-block;
          margin-bottom: 50px;
          p {
            text-align: left;
            & .tit {
              min-width: 78px;
              font-size: 0.875rem;
              line-height: 18px;
            }
            & .txt {
              font-size: 1.063rem;
              line-height: 22px;
            }
          }
        }

        a {
          display: block;
          padding: 20px 0;
          border-radius: 5px;
          font-size: 1rem;
          line-height: 24px;
        }

        .cutomer_desc {
          font-size: 0.875rem;
          line-height: 21px;
        }
      }

      & .right {
        display: inline-flex;
        width: 120px;
        height: 120px;
        margin: 0 auto 20px;
        border-radius: 50%;
        img {
          width: 70px;
        }
      }
    }
  }
`

export default CustomerPage;