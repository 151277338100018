import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import Dialog from './Dialog';
import DotGuide from './DotGuide';
import { useMediaQuery } from 'react-responsive';
import Select from "react-select";
import Radiobox from './RadioBox';
import axios from "axios";
import {useSelector} from "react-redux";
import Button from "./Button";

const ChangeCardDialog = ({ visible, setVisibleCard, changeCard, setChangeCard, customer_id }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const userInfo = useSelector((state) => state.userLogin.userInfo.data || "");
  const [cardInputs, setCardInputs] = useState({
    customer_uid: customer_id,
    card1 : "",
    card2 : "",
    card3 : "",
    card4 : "",
    expiry_month: "",
    expiry_year: "",
    expiry: "",
    birth: "",
    business: "",
    radioCheck: "개인",
    pwd_2digits: ""
  })

  const {card1, card2, card3, card4, expiry_month, expiry_year, birth, business, radioCheck, pwd_2digits, customer_uid} = cardInputs

  const yearArr = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]
  const monthArr = ["01", "02", "03", "04", "05", "06","07", "08", "09", "10", "11", "12", ]

  const month = monthArr.map((el) => { return {value: el, label: el} })
  const year = yearArr.map((el) => { return {value: el, label: el} })

  const onSubmit = (data) => {console.log(data)}

  const clickedConfirm = async () =>{
    try{
      axios.defaults.headers.common["cid"] = userInfo.cid;
      axios.defaults.headers.common["encoded"] = userInfo.encoded;
      const res = await axios.post(`${process.env.REACT_APP_API_URL}api/payment/change/card`, {
          "card_number": `${card1}-${card2}-${card3}-${card4}`,
          "customer_uid": customer_uid,
          "expiry": `${expiry_year}-${expiry_month}`,
          "birth": birth || business,
          "pwd_2digit": pwd_2digits
      })

      alert("카드 변경 되었습니다.")
    }catch(error){
      alert("카드 변경 실패.")
      console.log(error)
    }
    setChangeCard(false);
    setVisibleCard(false);
  }


  const handleInputsChange = async (e) => {
    const {name, value} = e.target;
    const reg = /^(?=.{1,4}$).*/g.test(value);
    switch (name) {
      case "card1":
        if(reg){
          return setCardInputs(prevState => ({...prevState, ["card1"]:value}));
        } else {
          return setCardInputs(prevState => ({...prevState, ["card1"]:""}));
        }
        break
      case "card2":
        if(reg){
          return setCardInputs(prevState => ({...prevState, ["card2"]:value}));
        } else {
          return setCardInputs(prevState => ({...prevState, ["card2"]:""}));
        }
        break
      case "card3":
        if(reg){
          return setCardInputs(prevState => ({...prevState, ["card3"]:value}));
        } else {
          return setCardInputs(prevState => ({...prevState, ["card3"]:""}));
        }
        break
      case "card4":
        if(reg){
          return setCardInputs(prevState => ({...prevState, ["card4"]:value}));
        } else {
          return setCardInputs(prevState => ({...prevState, ["card4"]:""}));
        }
        break
      case "pwd_2digits":
        if(/^(?=.{1,2}$).*/g.test(value)){
          return setCardInputs(prevState => ({...prevState, ["pwd_2digits"]:value}));
        } else {
          return setCardInputs(prevState => ({...prevState, ["pwd_2digits"]:""}));
        }
        break

      case "birth":
        if(/^(?=.{1,6}$).*/g.test(value)){
          return setCardInputs(prevState => ({...prevState, ["birth"]:value}));
        } else {
          return setCardInputs(prevState => ({...prevState, ["birth"]:""}));
        }
        break
      case "business":
        if(/^(?=.{1,10}$).*/g.test(value)){
          return setCardInputs(prevState => ({...prevState, ["business"]:value}));
        } else {
          return setCardInputs(prevState => ({...prevState, ["business"]:""}));
        }
        break
    }
    return setCardInputs(prevState => ({...prevState, [name]:value}));
  }

  const [checkVal, setCheckVal] = useState("개인");
  const handleChange = (e) => {
    let checked = e.target.value

    setCheckVal(e.target.value)
  }

const checkCard = card1.length + card2.length + card3.length + card4.length >= 16
const checkDate = expiry_month !== "" && expiry_year !== ""
const checkPW = pwd_2digits.length === 2
const checkBirth = birth.length === 6
const checkBusiness = business.length === 10
const checkNumberPut = checkBirth || checkBusiness

  const handleInputs = useCallback((key) => (e) => {
    if(key === "card_number"){
      return setCardInputs({
        ...cardInputs,
        ["card_number"]: cardInputs.card_number += e.target.value
      })
    }

    return setCardInputs({...cardInputs,
      [key]: e.target.value
    })

  }, []);

  const handleSelectMon = (option) => {
    setCardInputs({
      ...cardInputs,
      ["expiry_month"]: option
    })
  }
  const handleSelectYear = (option) => {
    setCardInputs({
      ...cardInputs,
      ["expiry_year"]: option
    })
  }



  return (
    <ChangeCardContainer
      visible={visible}
      title={changeCard ? "결제카드 변경" : ["새로운 결제카드를", isMobile && <br />, "입력해주세요 :)"]}
      confirmText={changeCard ? "" : "변경"}
      cancelText={changeCard ? "" : "취소"}
      onConfirm={changeCard ? "" : () => setChangeCard(true)}
      onCancel={() => {setChangeCard(false); setVisibleCard(false)}}
      fullHeight={changeCard ? true : false}
      fixHeight={changeCard ? true : false}
      changeCard={changeCard}
    >
      {changeCard
      ? (
        <div className="card_form custom_scroll">
          <form handleSubmit={onSubmit}>
            <div className="row">
              <div className="form_title">카드번호</div>
              <div className="form_cont card_no">
                <input type="number" name="card1" onChange={handleInputsChange} value={cardInputs.card1} />
                <input type="number" name="card2" onChange={handleInputsChange} value={cardInputs.card2}  />
                <input type="number" name="card3" onChange={handleInputsChange} value={cardInputs.card3}  />
                <input type="password" name="card4" onChange={handleInputsChange} value={cardInputs.card4}  />
              </div>
            </div>
            <div className="row">
              <div className="form_title">유효기간</div>
              <div className="form_cont card_date">
                <Select
                  placeholder="00"
                  className="selectBox"
                  onChange={(month) => {handleSelectMon(month.value)}}
                  classNamePrefix="select"
                  options={month}
                /> 월
                <Select
                  placeholder="00"
                  className="selectBox"
                  classNamePrefix="select"
                  onChange={(year) => {handleSelectYear(year.value)}}
                  options={year}
                /> 년
              </div>
            </div>
            <div className="row">
              <div className="form_title">비밀번호</div>
              <div className="form_cont">
                <input type="password" placeholder="앞 2자리만 입력해주세요." name="pwd_2digits" onChange={handleInputsChange} value={cardInputs.pwd_2digits} />
              </div>
            </div>
            <div className="row">
              <div className="form_title">카드구분</div>
              <div className="form_cont">
                <div className="radios_wrap">
                  <Radiobox
                    id="card_type1"
                    name="radioCheck"
                    label="개인"
                    value="개인"
                    onChange ={(e) => {handleChange(e);}}
                    checked={checkVal === "개인"}
                  />
                  <Radiobox
                    id="card_type2"
                    name="radioCheck"
                    label="법인"
                    value="법인"
                    onChange ={(e) => {handleChange(e);}}
                    checked={checkVal === "법인"}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {checkVal === "개인" ? <div className="form_title">주민등록번호 앞 6자리</div> : <div className="form_title">사업자번호 10자리</div>}
              <div className="form_cont">
                {checkVal === "개인" ? <input type="number" placeholder="ex. 220107" name="birth" onChange={handleInputsChange} value={cardInputs.birth}  /> :
                    <input type="number" placeholder="ex. 4252539524" name="business" onChange={handleInputsChange} value={cardInputs.business}  />}
              </div>
            </div>
          </form>
          <div className="button_wrap">
            {checkCard && checkPW && checkDate && checkNumberPut ? <Button className="btn_wrap" onClick={() => {clickedConfirm();}} fullWidth>변경하기</Button> : <Button className="btn_wrap" color="gray" fullWidth>변경하기</Button> }
          </div>
        </div>
      ) : ( 
        <div className="sub_txt">
          <DotGuide size="large" text="결제 정보 변경시 모든 정기배송이 새로운 결제수단으로 결제됩니다." />
          <DotGuide size="large" text="다음 결제부터 변경된 결제 정보로 결제가 진행됩니다." />
          <DotGuide size="large" text="카드 분실, 한도 초과 등의 사유로 정기 결제가 진행되지 않았을 경우, 결제수단 변경 후 정상적으로 결제가 진행됩니다." />
        </div>
      )}
    </ChangeCardContainer>
  )
}

const ChangeCardContainer = styled(Dialog)`
  & .btn_wrap{
    margin-top: 30px;
  }
  
  & .dialog_wrap {
    padding: ${({ changeCard }) => changeCard ? "30px 0" : "50px 30px"};
    @media only screen and (max-width: 960px) {
      padding: ${({ changeCard }) => changeCard ? "0" : "40px 22px 30px"};
    }
  }

  & .close {
    display: ${({ changeCard }) => changeCard ? "inline-block" : "none"} !important;
  }
  & .dialog_inner {
    padding: ${({ changeCard }) => changeCard ? "0 30px" : "0"};
    & .main_txt {
      margin-bottom: 40px;
      font-size: 22px;
      line-height: 33px;
      color: #222;
      font-weight: 500;
      text-align: center;
    }
    & .sub_txt p {
      font-size: 1rem;
      color: #222;
      line-height: 24px;
      text-align: left;
      & span {
        line-height: 24px;
      }
    }

    & .card_form {
      overflow-y: auto;
      max-height: calc(90vh - 242px);
      & .row {
        & .form_title {
          margin-bottom: 10px;
          font-size: 1rem;
          line-height: 24px;
        }
        & .form_cont {
          & select, & input {
            font-size: 1rem;
            padding: 11px 20px;
            color: #222;
          }
          &.card_no {
            display: flex;
            justify-content: space-between;
            & input {
              width: calc((100% - 15px) / 4);
            }
          }
          &.card_date {
            display: flex;
            align-items: center;
            & .selectBox {
              width: 110px;
              height: 46px;
              margin-right: 5px;
              &:not(:first-child) {
                margin-left: 20px;
              }
              & .select__control {
                border: 1px solid #ccc;
              }
              & .select__placeholder {
                color: #999;
              }
            }
          }
          & .radios_wrap {
            display: flex;
            margin: 15px 0 37px;
            & div:not(:last-child) {
              margin-right: 30px;
            }
            & label {
              font-size: 16px;
            }
          }
        }
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }

    @media only screen and (max-width: 960px) {
      margin-top: ${({ changeCard }) => changeCard ? "0" : "30px"};
      padding: ${({ changeCard }) => changeCard ? "30px 15px" : "0"};
      & .main_txt {
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 30px;
      }
      & .sub_txt p {
        font-size: 1rem;
      }
      & .card_form {
        overflow-y: initial;
        max-height: 100vh;
      }
    }
  }

  & .ButtonWrap {
    padding: ${({ changeCard }) => changeCard ? "0 30px" : "0 65px"};
    & > div {
      width: 100%;
      font-weight: 700;
    }
    @media only screen and (max-width: 960px) {
      margin-top: 40px;
      padding: ${({ changeCard }) => changeCard ? "0 15px" : "0 7px"};
      & > div + div {
        margin-left: 10px;
      }
    }
  }
`;

export default ChangeCardDialog;