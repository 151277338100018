import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ico_filter from "images/icon/ico_filter.png";
import ico_filter_w from "images/icon/ico_filter_w.png";
import m_ico_filter from "images/m/ico_filter.png";
import m_ico_filter_w from "images/m/ico_filter_w.png";
import { IoIosArrowDown } from 'react-icons/io';
import { Link } from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {setProjectCategory, setProjectLists} from "../actions/boardActions";
import Loader from "./components/Loader";

const ProjectPage = () => {
  const [filter, setFilter] = useState(false);
  const [show, setShow] = useState(false);
  const category = useSelector((state) => state.projectCategory.category);
  const projectLists = useSelector((state) => state.projectLists)
  const {loading, projects} = projectLists;
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(6);

  useEffect(() => {
    dispatch(setProjectCategory());
    dispatch(setProjectLists(0, 100))
  },[dispatch])

  const lowTitles = (temp) => {
    let length = 20;
    if (temp.length > length) {
     return temp = temp.substr(0, length-2) + '...';
    }
    return temp
  }

  const [checkCate, setCheckCate] = useState({id: 0, name: "전체"});
  const handleFilter = (id, name) => {
    setCheckCate( {...checkCate, id: id, name: name})
    setFilter(!filter)
  }

  const matchLists = projects.filter((el) => {
    if(checkCate.name === "전체"){
      return el
    }else{
      return el.ProjectCategoryId === checkCate.id
    }
  })

  const moreView = () => {
    setLimit(limit + 6);
  }
  return (
    <ProjectWrapper>
      <div className="board_wrap">
        <div className="wrap_title board_title">
          <h2>바램 육아백과</h2>
        </div>
        <div className="project_wrap">
          <div className="filter_wrap">
            <span onClick={() => setShow(prevState => !prevState) } className={show ? "active" : ""}>{checkCate.name}</span>
            <ul className={show ? "active" : ""}>
              <li className={filter === "전체" ? "selected" : ""} onClick={() => {handleFilter(0, "전체"); setFilter("전체"); setShow(false)}}>
              <Link to="/support/project">
                전체
              </Link></li>
              {category.map((el) =>
              <li key={el.id} onClick={() => {handleFilter(el.id, el.name); setFilter(el.name); setShow(false)}} className={filter === el.name ? "selected" : ""}>
                {el.name}
              </li>)}
            </ul>
          </div>
          {
              loading ?
                  <Loader /> :
                  <ul className="project_list">
                      {
                          projects.length !== 0 && matchLists.length !== 0 ?
                              (matchLists.slice(0, limit).map((list) => {
                                  return <li key={list.id}>
                                      <Link to={{
                                          pathname: `/support/projectdetail/${list.id}`,
                                          state: { list, category: list.ProjectCategory},
                                      }}>
                                          <div className="thumb"><img src={list.thumbnail} alt={list.title} /></div>
                                          <p className="category">{list.ProjectCategory.name}</p>
                                          <p className="project_title">{lowTitles(list.title)}</p>
                                      </Link>
                                  </li>})) :
                              (<p style={{margin: '0 auto'}}>등록된 프로젝트가 없습니다.</p>)}
                  </ul>
          }
        </div>
        <div className="list_more_btn">
          {matchLists.length > 6 && matchLists.length >= limit ? <button onClick={() => {moreView()}}>더보기 <IoIosArrowDown /></button> : null}
        </div>
      </div>
    </ProjectWrapper>
  );
};

const ProjectWrapper = styled.div`
  & .board_wrap {
    padding-bottom: 10px;
  }
  & .wrap_title {
    display: flex;
    align-items: center;
    & p {
      margin-left: 40px;
      font-size: 0.875rem;
      line-height: 21px;
      color: #515355;
    }
    @media only screen and (max-width: 960px) {
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;
      & p {
        margin: 10px 0 0 0;
        font-size: 0.875rem;
        line-height: 20px;
        text-align: center;
      }
    }
  }

  & .filter_wrap {
    position: relative;
    margin: 40px 0 20px;
    text-align: right;
    & span {
      display: inline-flex;
      align-items: center;
      padding: 0 56px 0 24px;
      height: 50px;
      border-radius: 5px;
      border: 1px solid #515355;
      background: url(${ico_filter}) no-repeat right 20px center;
      color: #222;
      font-weight: 500;
      font-size: 1.125rem;
      cursor: pointer;
      &.active {
        border: 1px solid #1EA0FA;
        color: #f2f4f6;
        font-weight: 500;
        background: #1EA0FA url(${ico_filter_w}) no-repeat right 20px center;
      }
    }
    & ul {
      display: none;
      position: absolute;
      top: 60px;
      right: 0;
      background: #f2f4f6;
      border-radius: 5px;
      padding: 12px 0;
      z-index: 99;
      &.active {
        display: inline-block;
      }
      & li {
        padding: 8px 56px 8px 24px;
        color: #222;
        font-size: 1.125rem;
        line-height: 27px;
        text-align: left;
        cursor: pointer;
        transition: .3s;
        &:hover {
          color: #1EA0FA;
        }
        &.selected {
          color: #1EA0FA;
          & a {
            color: #1EA0FA;
          }
        }
      }
    }
    @media only screen and (max-width: 960px) {
      position: relative;
      margin: 0 0 15px 0;
      text-align: right;
      & span {
        padding: 0 48px 0 20px;
        height: 40px;
        background: url(${m_ico_filter}) no-repeat right 20px center;
        background-size: 18px auto;
        font-size: 0.938rem;
        &.active {
          background: #1EA0FA url(${m_ico_filter_w}) no-repeat right 20px center;
          background-size: 18px auto;
        }
      }
      & ul {
        top: 48px;
        padding: 8px 0;
        & li {
          padding: 7px 48px 7px 20px;
          font-size: 0.938rem;
          line-height: 22px;
          transition: .3s;
          &.active {
            color: #1EA0FA;
          }
        }
      }
    }
  }

  & .project_list {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    & li {
      width: calc((100% - 70px) / 3);
      margin-bottom: 76px;
      margin-right: 35px;
      text-align: center;
      & a {
        display: block;
      }
      & .thumb {
        position: relative;
        overflow: hidden;
        width: 100%;
        margin-bottom: 25px;
        &::after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
        & img{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
          width: auto;
          height: auto;
          width: 100%;
        }
      }
      & .category {
        margin-bottom: 8px;
        color: #1EA0FA;
        font-size: 1.125rem;
        line-height: 27px;
      }
      & .project_title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1.375rem;
        color: #222;
        line-height: 33px;
      }
    }
    & li:nth-child(3n){
      margin-right: 0;
    }
    @media only screen and (max-width: 960px) {
      & li {
        width: 100%;
        margin-bottom: 50px;
        margin-right: 0;
        & .thumb {
          margin-bottom: 20px;
        }
        & .category {
          margin-bottom: 8px;
          font-size: 1rem;
          line-height: 22px;
        }
        & .project_title {
          font-size: 1.25rem;
          font-weight: 500;
          line-height: 28px;
        }
      }
    }
  }

  & .list_more_btn {
    margin-top: 24px;
    @media only screen and (max-width: 960px) {
      margin-top: 0;
    }
  }
`;

export default ProjectPage;