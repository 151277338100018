export const stringPrice = (price) => {
    if (price === 0 || price === "0") {
        return "0"
    }
    return price && price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const comma = (num) => {
    const wonList = ['원', '만'];
    const insertComma = num.toString().replace(/\B(?=(\d{4})+(?!\d))/g, ",");
    const splitComma = insertComma.split(",");
    let isLastWon = false;
    let result = "";
    if (wonList[splitComma.length -1] === undefined) return num + "원";


    if(parseInt(splitComma[splitComma.length - 1]) === 0){
        splitComma.splice(splitComma.length -1, 1)
        isLastWon =  true;
    }

    if (!isLastWon) {
        for (let i = 0; i < splitComma.length; i++) {
            result = splitComma.join(wonList[i]);
        }
    } else {
        result = splitComma.join() + wonList[1];
    }
    result += "원"
    return result;
}