import {
    PROJECT_LISTS_REQUEST,
    PROJECT_LISTS_SUCCESS,
    PROJECT_LISTS_FAIL, SET_PROJECT_CATEGORY,
    PROJECT_DETAILES_SUCCESS,
    PROJECT_DETAILES_FAIL,
    PROJECT_DETAILES_REQUEST,
    EVENT_LISTS_SUCCESS,
    EVENT_LISTS_FAIL,
    EVENT_LISTS_REQUEST,
    BOARD_LISTS_REQUEST,
    BOARD_LISTS_SUCCESS,
    BOARD_LISTS_FAIL,
    EVENT_DETAILES_SUCCESS,
    EVENT_DETAILES_FAIL,
    EVENT_DETAILES_REQUEST
} from "actions/types";

export const boardListsReducer = (state = {lists: []}, action ) => {
    switch (action.type){
        case BOARD_LISTS_REQUEST:
            return {loading: true, lists: []};
        case BOARD_LISTS_SUCCESS:
            return {loading: false, lists: action.payload};
        case BOARD_LISTS_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}

export const setPrjectCategory = (state = {category: [] }, action) =>{
    switch (action.type){
        case SET_PROJECT_CATEGORY:
            return {
                category: action.payload.categories
            }
        default:
            return state;
    }
}

export const eventBoardReducer = (state={events: []}, action) => {
    switch(action.type){
        case EVENT_LISTS_REQUEST:
            return {loading: true, events: []};
        case EVENT_LISTS_SUCCESS:
            return {
                loading: false,
                events: action.payload.data,
            };
        case EVENT_LISTS_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}

export const eventDetailReducer = (state = {eventDetail:[]}, action) => {
    switch(action.type){
        case EVENT_DETAILES_REQUEST:
            return {loading: true, eventDetail: []};
        case EVENT_DETAILES_SUCCESS:
            return {loading: false, eventDetail: action.payload.data}
        case EVENT_DETAILES_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const dryProjectReducer = (state = {projects: []}, action ) => {
    switch (action.type){
        case PROJECT_LISTS_REQUEST:
            return {loading: true, projects: []};
        case PROJECT_LISTS_SUCCESS:
            return {
                loading: false,
                projects: action.payload.data,
            };
        case PROJECT_LISTS_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}

export const dryProjectDetailReducer = (state = {projectDetail:[]}, action) => {
    switch(action.type){
        case PROJECT_DETAILES_REQUEST:
            return {loading: true, projectDetail: []};
        case PROJECT_DETAILES_SUCCESS:
            return {loading: false, projectDetail: action.payload.data}
        case PROJECT_DETAILES_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}
