import {
    ADD_TO_ORDER,
    FETCH_ORDER_LISTS,
    FETCH_ORDER_DETAILS,
    FETCH_ORDER_RESET,
    RESET_ORDER_DETAILS,
    APPEND_ORDER_LISTS,
    UPDATE_ORDER_LISTS,
    FETCH_ORDER_REG_LISTS,
    APPEND_ORDER_REG_LISTS,
    UPDATE_ORDER_REG_LISTS

} from 'actions/types';

export const orderReducer = (state = {orderItems: {price: ""}, orderLists: []}, action) => {
    switch (action.type) {
        case ADD_TO_ORDER:
            return {orderItems: action.payload}
        default:
            return state;
    }
};

export const orderFetchLists = (state = {orderLists: [], data_count: 0, total_count: 0}, action) => {
    switch (action.type) {
        case FETCH_ORDER_RESET:
            return {orderLists: [], data_count: 0, total_count: 0};
        case APPEND_ORDER_LISTS:
            if (isNaN(action.payload.data_count)) {
                action.payload.data_count = 0
            }
            if (isNaN(action.payload.count)) {
                action.payload.count = 0
            }
            return {
                orderLists : [...state.orderLists, ...action.payload.data],
                data_count : state.data_count + action.payload.data_count,
                total_count: action.payload.total_count
            }
        case FETCH_ORDER_LISTS:
            if (isNaN(action.payload.data_count)) {
                action.payload.data_count = 0
            }

            return {
                orderLists : action.payload.data,
                data_count : action.payload.data_count,
                total_count: action.payload.total_count
            }
        case UPDATE_ORDER_LISTS:
            let orderlist = state.orderLists;
            if (orderlist.length === 0) {
                orderlist = action.payload.data;
            } else if (orderlist.length >= 1) {
                let isUpdate = false;
                for (let i = 0; i < orderlist.length; i++) {
                    const list = orderlist[i];
                    let idx = action.payload.data.findIndex(v => v.id === list.id);
                    if (idx >= 0) {
                        isUpdate = true;
                        let objectList = Object.keys(list);
                        for (let j = 0; j < objectList.length; j++) {
                            if (objectList[j] !== 'id') {
                                list[objectList[j]] = action.payload.data[idx][objectList[j]]
                            }
                        }
                    }
                }
                if (!isUpdate) {
                    orderlist = [...orderlist, ...action.payload.data[1]]
                }
            }
            return {
                orderLists : orderlist,
                data_count : state.data_count,
                total_count: action.payload.total_count
            }
        default:
            return state;
    }
}

export const orderFetchRegLists = (state = {orderLists: [], data_count: 0, total_count: 0}, action) => {
    switch (action.type) {
        case FETCH_ORDER_RESET:
            return {orderLists: [], data_count: 0, total_count: 0};
        case APPEND_ORDER_REG_LISTS:
            if (isNaN(action.payload.data_count)) {
                action.payload.data_count = 0
            }
            if (isNaN(action.payload.count)) {
                action.payload.count = 0
            }
            return {
                orderLists : [...state.orderLists, ...action.payload.data],
                data_count : state.data_count + action.payload.data_count,
                total_count: action.payload.total_count
            }
        case FETCH_ORDER_REG_LISTS:
            if (isNaN(action.payload.data_count)) {
                action.payload.data_count = 0
            }

            return {
                orderLists : action.payload.data,
                data_count : action.payload.data_count,
                total_count: action.payload.total_count
            }
        case UPDATE_ORDER_REG_LISTS:
            let orderlist = state.orderLists;
            if (orderlist.length === 0) {
                orderlist = action.payload.data;
            } else if (orderlist.length >= 1) {
                let isUpdate = false;
                for (let i = 0; i < orderlist.length; i++) {
                    const list = orderlist[i];
                    let idx = action.payload.data.findIndex(v => v.id === list.id);
                    if (idx >= 0) {
                        isUpdate = true;
                        let objectList = Object.keys(list);
                        for (let j = 0; j < objectList.length; j++) {
                            if (objectList[j] !== 'id') {
                                list[objectList[j]] = action.payload.data[idx][objectList[j]]
                            }
                        }
                    }
                }
                if (!isUpdate) {
                    orderlist = [...orderlist, ...action.payload.data[1]]
                }
            }
            return {
                orderLists : orderlist,
                data_count : state.data_count,
                total_count: action.payload.total_count
            }

        default:
            return state;
    }
}



export const orderFetchDetails = (state = {orderDetails: {}}, action) => {
    switch (action.type) {
        case FETCH_ORDER_DETAILS:
            return {orderDetails: action.payload}
        case RESET_ORDER_DETAILS:
            return {orderDetails: {}}
        default:
            return state;
    }
}