import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import Button from "pages/components/Button";
import { useMediaQuery } from 'react-responsive';
import Dialog from 'pages/components/Dialog';
import {useDispatch, useSelector} from "react-redux";
import {accessUserInfo} from "actions/userActions";
import UserInfoAfterPW from "./UserInfoAfterPW";
import { useCookies } from 'react-cookie';

const UserInfoFix = ({checkUser}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['userInfoChange', 'userApp']);

    // 개인정보 업데이트
    const [updateInfo, setUpdateInfo] = useState(false);

    const UserInfoLogin = () => {
        const [userid, setUserid] = useState('');
        const [userpw, setUserpw] = useState('');

        const onIdChange = (e) => {
            setUserid(e.target.value);
        }
        const onPwChange = (e) => {
            setUserpw(e.target.value);
        }
        const idInput = useRef();
        const pwInput = useRef();

        const loginAction = () => {
            dispatch(accessUserInfo(userid, userpw, setCookie, removeCookie))
        }

        const onIdKeyEvent = (e) => {
            if (e.keyCode === 13) {
                if (idInput.current.value === "") { return }
                pwInput.current.focus();
            }
        }

        const onPwKeyEvent = (e) => {
            if (e.keyCode === 13) {
                if (pwInput.current.value === "") { return }
                loginAction();
            }
        }


        useEffect(()=> {
            if(!checkUser){return}
            if(checkUser){
                setUserid(checkUser?.data?.email)
                if(checkUser?.data?.phone_number === null && cookies.userApp === undefined ){
                    setUpdateInfo(true);
                }
            }
        },[])



        return <div>
            <div className="mypage_title">
                <h2>회원정보 수정
                    <span>회원님의 정보를 안전하게 보호하기 위해 { isMobile && <br /> }비밀번호를 다시 한번 확인해주세요.</span>
                </h2>
            </div>
            <div className="user_info_wrap">
                <div className="basic_login">
                    <form>
                        <p>
                            <span>아이디</span>
                            <input
                                ref={idInput}
                                type="text"
                                name="user_id"
                                onKeyDown={onIdKeyEvent}
                                onChange={onIdChange}
                                placeholder="아이디를 입력해주세요"
                                value={userid}
                            ></input>
                        </p>
                        <p>
                            <span>비밀번호</span>
                            <input
                                ref={pwInput}
                                type="password"
                                name="user_pw"
                                placeholder="비밀번호"
                                onKeyDown={onPwKeyEvent}
                                onChange={onPwChange}
                            ></input>
                        </p>
                        <div className="button_wrap">
                            <MainButton fullWidth className="loginBtn" onClick={loginAction}>
                                확인
                            </MainButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    }

    const closingPop = () => {
        setCookie('userApp', true, {expire: 1000 * 600});
    };


    return (
        <UserInfoContainer>
            {checkUser?.data?.login_type === "kakao" ? //
                <UserInfoAfterPW checkUser={checkUser} /> : // 가입한 회원이 카카오톡이면 렌더링
                <React.Fragment>
                    {cookies.userInfoChange ? <UserInfoAfterPW checkUser={checkUser} /> : <UserInfoLogin />}
                </React.Fragment> }

            {/* 팝업 */}
            <UpdateInfoDialog>
                <Dialog
                    confirmText="업데이트"
                    cancelText="닫기"
                    onConfirm={() => window.location.href='/userinfoupdate'}
                    onCancel={() => {closingPop(); setUpdateInfo(false);}}
                    visible={updateInfo}
                >
                    <p>홈페이지 새 단장을 맞이하여,<br />오래된 개인정보를 업데이트 해주세요!</p>
                </Dialog>
            </UpdateInfoDialog>
        </UserInfoContainer>
    );
};

const UpdateInfoDialog = styled.div`
  & .dialog_wrap {
    padding: 123px 25px 30px;
    & .close {
      display: none;
    }
  }
  & .dialog_inner {
    & p {
      text-align: center;
    }
  }
  & .ButtonWrap {
    width: 100%;
    margin: 84px 0 0 0;
    & div {
      width: calc((100% - 10px) / 2);
      font-weight: 700;
    }
  }

  @media only screen and (max-width: 960px) {
    & .dialog_wrap {
      padding: 80px 25px 30px;
    }
    & .dialog_inner {
      & p {
        text-align: center;
      }
    }
    & .ButtonWrap {
      margin: 50px auto 0;
      & div {
        width: calc((100% - 10px) / 2);
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }
`

const MainButton = styled(Button)`
  @media only screen and (max-width: 960px) {
    height: 50px;
    font-weight: 700;
    font-size: 1rem;
  }
`;

const UserInfoContainer = styled.div`
  & .mypage_title {
    margin-bottom: 15px;
    & span {
      margin-left: 20px;
      font-size: 0.875rem;
      color: #515355;
      line-height: 21px;
      font-weight: 400;
    }
  }
  & .user_info_wrap {
    border-top: 1px solid #555;
    border-bottom: 1px solid #ccc;
  }

  & .basic_login {
    width: 500px;
    margin: auto;
    padding: 80px 0;
    & p {
      display: flex;
      align-items: center;
      & span {
        display: inline-block;
        width: 100px;
      }
      & + p {
        margin-top: 10px;
      }
    }
    input {
      width: 400px;
      padding: 11px 20px;
      line-height: 24px;
      font-size: 1rem;
      color: #222;
      background: #f6f6f6;
      border: none;
      margin-top: 0;

      &::placeholder {
        line-height: 24px;
        font-size: 1rem;
        color: #999;
      }
    }

    .error_msg {
      margin-top: 8px;
      color: #ff2727;
      font-size: 0.75rem;
      line-height: 18px;
    }
  }

  & .button_wrap {
    margin-top: 40px;
    width: 400px;
    margin-left: auto;
  }

  @media only screen and (max-width: 960px) {
    & .mypage_title {
      margin-bottom: 0;
      & span {
        display: block;
        margin-left: 0;
        margin-top: 40px;
        font-size: 0.938rem;
        color: #222;
        line-height: 22px;
      }
    }
    & .user_info_wrap {
      border: none;
    }

    & .basic_login {
      width: 100%;
      padding: 0 15px;
      & p {
        & span {
          display: none;
        }
      }
      input {
        width: 100%;
        line-height: 22px;
        font-size: 0.938rem;

        &::placeholder {
          line-height: 22px;
          font-size: 0.938rem;
        }
      }

      .error_msg {
        margin-top: 8px;
        color: #ff2727;
        font-size: 0.75rem;
        line-height: 18px;
      }
    }

    & .button_wrap {
      width: 100%;
      margin-left: auto;
      margin-bottom: 40px;
    }
  }
`

export default UserInfoFix;