import axios from "axios";
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_SIGNUP_REQUEST,
    USER_SIGNUP_SUCCESS,
    USER_SIGNUP_FAIL,
    USER_LOGOUT,
    USER_KAKAO_LOGIN_REQUEST,
    USER_DETAIL_REQUEST,
    USER_DETAIL_SUCCESS,
    USER_DETAIL_FAIL,
    DEFINE_APP_USER,
} from "./types";
import {validateEmail} from '../utils/Validated'

export const kakaoLoginRequest = (token) => async dispatch => {
    try{
        dispatch({
            type: USER_KAKAO_LOGIN_REQUEST,
        });

        axios.post(`${process.env.REACT_APP_API_URL}api/auth/kakao/checkUser`, { //카카오메일 클릭 후 접속
            access_token: token
        }).then((response) => {
            console.log(response)
            return response
        })
    }catch(error){
        console.log(error)
    }
}

export const signUp = (data) => async (dispatch) => {
    dispatch({
        type: USER_SIGNUP_REQUEST
    })

    const res = await axios.post(`${process.env.REACT_APP_API_URL}api/auth/signup`, data).then(res => {
        if(res.data.type === "success"){
            dispatch({
                type: USER_SIGNUP_SUCCESS,
                payload: res.data
            })
            dispatch(login(data.email, data.password))
            document.location.href = "/JoinComplete"
        }else{
            dispatch({
                type: USER_SIGNUP_FAIL,
                payload: res.data.msg
            })
            if(res.data.msg === "InvalidAuthRequest"){
                alert("이미 등록된 휴대폰 번호입니다.")
            }
        }
    })
    console.log(res)
}

export const setUserInfo= (data) => async dispatch =>{
    dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
    })
}

export const login = (user_id, password) => async dispatch => {
        let email = "", phone_number = "";
        dispatch({
            type:USER_LOGIN_REQUEST,
        });

    if (user_id === "") {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: "UnauthorizedUserError"
        })
        return
    }
    if (password === "") {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: "InvalidPasswordError"
        })
             return
    }
        if(validateEmail(user_id)){
             email = user_id
        }else{
             phone_number = user_id
        }

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}api/auth/signin`,
            {email, password, phone_number});

        if(res.data.type === "fail"){
            dispatch({
                type: USER_LOGIN_FAIL,
                payload: res.data.msg
            })
        }else{
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: res.data,
            })

            localStorage.setItem("userInfo", JSON.stringify({loading: false, userInfo:res.data, errorMsg: {}}));
        }
}


export const accessUserInfo = (email, password, setCookie, removeCookie) => async (dispatch, getState) => {
    const { userLogin: {userInfo}} = getState();
    const config={
        headers: {
            'Content-Type': 'application/json',
            "cid" : userInfo.data.cid,
            "encoded" : userInfo.data.encoded,
        },
    }

    const response= await axios.post(
        `${process.env.REACT_APP_API_URL}api/user/access`,
        {email, password}, config
    );
    if(response.data.type === "success"){
        setCookie('userInfoChange', true, {expire: 1000 * 600});
    }else{
        if(response.data.msg === "IvalidParameterError") {
            alert("잘못 입력했습니다.")
        }else if(response.data.msg === "UnauthorizedUserError"){
            alert("등록되지 않은 이메일입니다.")
        }else if(response.data.msg === "InvalidPasswordError"){
            alert("비밀번호가 틀렸습니다.")
        }
    }
}

export const checkUserDevice = () => async(dispatch, getState) => {
    const { userLogin: {userInfo}} = getState();
    const config={
        headers: {
            'Content-Type': 'application/json',
            "cid" : userInfo.data.cid,
            "encoded" : userInfo.data.encoded,
        },
    }

    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/user/device`, config
    );

    if(response.data.data.devices > 0){
        dispatch({
            type: DEFINE_APP_USER,
            payload: true,
        })
    }else{
        dispatch({
            type: DEFINE_APP_USER,
            payload: false,
        })
    }
}

export const userOutService = () => async (dispatch, getState) => {
    const { userLogin: {userInfo}} = getState();
    const config={
        headers: {
            'Content-Type': 'application/json',
            "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
        },
    }

    const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/user/signout`,{}, config
    );
    localStorage.removeItem('userInfo');
}


export const getUserInfo = () => async (dispatch, getState) => {
    const {userLogin: {userInfo}} = getState();

    try{
        dispatch({type: USER_DETAIL_REQUEST});
        const config={
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                "encoded" : userInfo.data.encoded,
            },
        }
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}api/user/info`, config);

        dispatch({
            type: USER_DETAIL_SUCCESS,
            payload: data
        })
        setTimeout(() => {
            dispatch({
                type: USER_DETAIL_SUCCESS,
                payload: data
            })
        }, 1000)
    }catch(error){
        console.log(error)
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({
            type: USER_DETAIL_FAIL,
            payload: message,
        });
    }
}

export const logout = (id) => async (dispatch, getState) => {
    const {userLogin: {userInfo}} = getState();
    localStorage.removeItem('userInfo');

    dispatch({type: USER_LOGOUT});

    // 서버로 로그아웃 요청을 하지 않고 프론트단만 로그아웃 처리하도록 수정
    // try{
    //     const config={
    //         headers: {
    //             'Content-Type': 'application/json',
    //             "cid" : userInfo.data.cid,
    //             "encoded" : userInfo.data.encoded,
    //         },
    //     }
    //     const logout = await axios.post(`${process.env.REACT_APP_API_URL}api/user/logout`,{
    //         "user_id": id
    //     }, config);
    //     console.log(logout)
    // }catch(err){
    //     console.log(err)
    // }
}


