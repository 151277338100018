import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import pageExpand_move from "images/page_expand_move.png";
import pageMove from "images/page_move.png";
import m_pageExpand_move from "images/m/page_expand_move.png";
import m_pageMove from "images/m/page_move.png";
import {useMediaQuery} from "react-responsive";

const Pagination2 = ({firstPage, lastPage, currentPage, setCurrentPage, totalData, LIMIT}) => {
    const buttonArray = new Array(lastPage).fill(1).map((_, index) => index + 1);
    const [active, setActive] = useState(1);

    useEffect(() => {
        setActive(parseInt(currentPage));
    }, [currentPage])
    const handlePrev = () => {
        if (parseInt(currentPage) > 1) {
            setCurrentPage((prevState) => prevState - 1);
        }
    };
    const handleNext = () => {
        if (parseInt(currentPage) < lastPage) {
            setCurrentPage((prevState) => prevState + 1);
        }
    };
    const prevPageExpand = () => {
        if (parseInt(currentPage) > 1) {
            setCurrentPage(1);
        }
    }
    const nextPageExpand = () => {
        if (parseInt(currentPage) < lastPage) {
            setCurrentPage(lastPage);
        }
    }
    const handlePageNavigation = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const isPc = useMediaQuery({query: "(min-width: 961px)"});
    const isMobile = useMediaQuery({query: "(max-width: 960px)"});

    return (
        <Pagination_cover>
            <nav>
                <div className="move_prev">
                    {totalData > 10 ?
                        <a onClick={prevPageExpand}>
                            {isPc && <img src={pageExpand_move} alt="맨 앞으로 이동"/>}
                            {isMobile && <img src={m_pageExpand_move} alt="맨 앞으로 이동"/>}
                        </a>
                        : null}
                    <a onClick={handlePrev}>
                        {isPc && <img src={pageMove} alt="한 페이지 앞으로 이동"/>}
                        {isMobile && <img src={m_pageMove} alt="한 페이지 앞으로 이동"/>}
                    </a>
                </div>
                <PageUl className="pagination">
                    {buttonArray.map((item, index) => {
                        return (
                            <PageLi key={index} className={'page-item ' + (item === active ? "active" : null)} onClick={() => handlePageNavigation(item)}>
                                <PageSpan disabled={currentPage === item} key={index} className="page-link">
                                    {item}
                                </PageSpan>
                            </PageLi>
                        );
                    })}
                </PageUl>
                <div className="move_next">
                    <a onClick={handleNext}>
                        {isPc && <img src={pageMove} alt="한 페이지로 뒤로 이동"/>}
                        {isMobile && <img src={m_pageMove} alt="한 페이지로 뒤로 이동"/>}
                    </a>
                    {totalData > 10 ?
                        <a onClick={nextPageExpand}>
                            {isPc && <img src={pageExpand_move} alt="끝으로 이동"/>}
                            {isMobile && <img src={m_pageExpand_move} alt="끝으로 이동"/>}
                        </a>
                        : null}
                </div>
            </nav>
        </Pagination_cover>
    );
};

const Pagination_cover = styled.div`
  & nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px 0 100px;

    @media only screen and (max-width: 768px) {
      margin: 40px 0 40px;
    }

    ul {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 768px) {
        margin-top: -4px;
      }

      li {
        vertical-align: middle;
      }
      & .active {
        color: #222222;
      }
    }
  }

  & nav > div {
    a, span {
      display: inline-block;
      margin: 0 5px;

      img {
        display: inline-block;
        width: 22px;
      }
    }
  }

  & .move_prev {
    margin-right: 10px;
  }

  & .move_next {
    margin-left: 10px;

    img {
      transform: rotate(180deg);
    }
  }
`;

const PageUl = styled.ul`
  list-style: none;
  text-align: center;
  border-radius: 3px;
  padding: 1px;
`;

const PageLi = styled.li`
  display: inline-block;
  min-width: 24px;
  margin: 0 5px;
  text-align: center;
  color: #888;

  &:hover {
    cursor: pointer;
    color: #222;
  }

  &:focus::after {
    color: #222;
  }

  @media only screen and (max-width: 768px) {
    color: #999;
    margin: 0 4px;
  }
`;

const PageSpan = styled.span`
  line-height: 24px;
  font-weight: 400;
  font-size: 1rem;

  @media only screen and (max-width: 768px) {

    font-size: 0.938rem;
  }

  &:hover::after,
  &:focus::after {
    color: #222;
  }
`;

export default Pagination2;
