import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import img_10 from 'images/bg/10.png'
import img_12 from 'images/bg/12.png'
import img_13 from 'images/bg/13.png'
import img_15 from 'images/bg/15.png'
import img_16 from 'images/bg/16.png'
import img_19 from 'images/bg/19.png'
import img_20 from 'images/bg/20.png'
import img_21 from 'images/bg/21.png'
import img_22 from 'images/bg/22.png'
import img_2008 from 'images/bg/2008.png'
import img_2009 from 'images/bg/2009.png'
import img_2010 from 'images/bg/2010.png'
import img_2011 from 'images/bg/2011.png'
import img_2015 from 'images/bg/2015.png'
import img_2016 from 'images/bg/2016.png'
import img_2017 from 'images/bg/2017.png'
import img_2018 from 'images/bg/2018.png'
import img_2020 from 'images/bg/2020.png'
import img_2022 from 'images/bg/2022.png'
import img_10_cover from 'images/bg/10_cover.png'
import img_12_cover from 'images/bg/12_cover.png'
import img_13_cover from 'images/bg/13_cover.png'
import img_15_cover from 'images/bg/15_cover.png'
import img_16_cover from 'images/bg/16_cover.png'
import img_19_cover from 'images/bg/19_cover.png'
import img_20_cover from 'images/bg/20_cover.png'
import img_21_cover from 'images/bg/21_cover.png'
import img_22_cover from 'images/bg/22_cover.png'
import img_2008_cover from 'images/bg/2008_cover.png'
import img_2009_cover from 'images/bg/2009_cover.png'
import img_2010_cover from 'images/bg/2010_cover.png'
import img_2011_cover from 'images/bg/2011_cover.png'
import img_2015_cover from 'images/bg/2015_cover.png'
import img_2016_cover from 'images/bg/2016_cover.png'
import img_2017_cover from 'images/bg/2017_cover.png'
import img_2018_cover from 'images/bg/2018_cover.png'
import img_2020_cover from 'images/bg/2020_cover.png'
import img_2022_cover from 'images/bg/2022_cover.png'
import comma_blue from 'images/bg/comma_blue.png'
import comma_white from 'images/bg/comma_white.png'
import award_tit_bg from 'images/bg/award_tit_bg.png'
import award_tit_bg2 from 'images/bg/award_tit_bg2.png'
import AOS from "aos";
import "aos/dist/aos.css";
import {useMediaQuery} from "react-responsive";

const AwardsPage = () => {
    const isPc = useMediaQuery({ query: '(min-width: 961px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
		// 스크롤시 헤더 상단 고정
		const [ScrollY, setScrollY] = useState(0);
		const [ScrollActive, setScrollActive] = useState(false);
	
		function handleScroll() {
			if (ScrollY > 100) {
				setScrollY(window.pageYOffset);
				setScrollActive(true);
			} else {
				setScrollY(window.pageYOffset);
				setScrollActive(false);
			}
		}
    useEffect(() => {
      const watch = () => {
        window.addEventListener('scroll', handleScroll)
      }
      watch();
          return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    })

    useEffect(() => {
      AOS.init({
          duration : 1000
      });
    });

    return (
        <AwardsContainer>
          <div className="awards_wrap center intro_tech">
            <div className={ScrollActive ? "tit_wrap active" : "tit_wrap"} data-aos="active" data-aos-offset={isPc ? "1000" : "0"}>
              <p className="sub_tit">VARRAM PRODUCT</p>
              <p className="main_tit">반려생활의 새로운 기준<br />반려동물 홈 IOT 로봇</p>
            </div>
            <div className="cont_wrap">
              <ul>
              <li data-aos="active" data-aos-offset={isPc ? "500" : "400"}>
                  <p>
                    <img src={img_10} alt="10" className="no_active" />
                    <img src={img_10_cover} alt="10" className="active" />
                  </p>
                  <div>
                    <p className="cont_tit">CTS&amp;DRC</p>
                    <p className="cont_txt">양방향 무선 카메라<br />컬러트랭킹 센서</p>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "500" : "400"}>
                  <p>
                    <img src={img_12} alt="12" className="no_active" />
                    <img src={img_12_cover} alt="12" className="active" />
                  </p>
                  <div>
                    <p className="cont_tit">CAMERA ROBOT</p>
                    <p className="cont_txt">캐터필터형 카메라 로봇<br />로봇 디자인상 수상</p>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "500" : "400"}>
                  <p>
                    <img src={img_13} alt="13" className="no_active" />
                    <img src={img_13_cover} alt="13" className="active" />
                  </p>
                  <div>
                    <p className="cont_tit">증강 현실 로봇</p>
                    <p className="cont_txt">증강 현실을 적용한 영어 교육용 로봇</p>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "500" : "400"}>
                  <p>
                    <img src={img_15} alt="15" className="no_active" />
                    <img src={img_15_cover} alt="15" className="active" />
                  </p>
                  <div>
                    <p className="cont_tit">앱봇 링크</p>
                    <p className="cont_txt">세계 최초 상용화 로봇<br />홈 cctv 로봇 2년의 개발로 상용화 완료<br />2015년 출시와 동시에 수출 성공<br />유럽 미국 등 40만 달러 수출 달성</p>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "500" : "400"}>
                  <p>
                    <img src={img_16} alt="16" className="no_active" />
                    <img src={img_16_cover} alt="16" className="active" />
                  </p>
                  <div>
                    <p className="cont_tit">앱봇 라일리</p>
                    <p className="cont_txt">2세대 앱봇 안정성 및 기능 확장<br />인디고 크라우드 펀딩<br />17만 달러달성<br />2016년 1만대 수출 달성<br />총 수출 100만 달러 달성</p>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "500" : "400"}>
                  <p>
                    <img src={img_19} alt="19" className="no_active" />
                    <img src={img_19_cover} alt="19" className="active" />
                  </p>
                  <div>
                    <p className="cont_tit">펫 피트니스</p>
                    <p className="cont_txt">국내 유일 반려동물 건강 증진을 위한<br />간식 토출 기능을 갖는 로봇 특허 출원<br />와디즈 크라우드 펀딩 6억 달성<br />미국 일본 등 수출 성공<br />국내 10만대 판매 돌파</p>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "500" : "400"}>
                  <p>
                    <img src={img_20} alt="20" className="no_active" />
                    <img src={img_20_cover} alt="20" className="active" />
                  </p>
                  <div>
                    <p className="cont_tit">바램펫 밀리</p>
                    <p className="cont_txt">로드셀 스마트 센싱으로 실시간 섭취량 확인 {isPc && <br />}및 맞춤 식사량 케어가 가능한 반려동물 급식기<br />출시 한달에 1만대 판매 돌파</p>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "500" : "400"}>
                  <p>
                    <img src={img_21} alt="21" className="no_active" />
                    <img src={img_21_cover} alt="21" className="active" />
                  </p>
                  <div>
                    <p className="cont_tit">바램펫 밀리</p>
                    <p className="cont_txt">로드셀 고정밀 업그레이드</p>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "500" : "400"}>
                  <p>
                    <img src={img_22} alt="22" className="no_active" />
                    <img src={img_22_cover} alt="22" className="active" />
                  </p>
                  <div>
                    <p className="cont_tit">바램펫 웰리</p>
                    <p className="cont_txt">고정밀 음수량 측정 수반 출시(1ml 센싱)<br />고정밀 음수량 측정 스마트 정수기 출시(1ml 센싱)</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="awards_wrap center awards">
            <div className={ScrollActive ? "tit_wrap active" : "tit_wrap"} data-aos="active" data-aos-offset={isPc ? "500" : "0"}>
              <p className="main_tit">바램펫 수상 및 인증</p>
            </div>
            <div className="cont_wrap">
              <ul>
              <li data-aos="active" data-aos-offset={isPc ? "700" : "400"}>
                  <p>
                    <span>
                      <img src={img_2008} alt="2008" className="no_active" />
                      <img src={img_2008_cover} alt="2008" className="active" />
                    </span>
                  </p>
                  <div>
                    <p className="cont_tit">08</p>
                    <p className="cont_txt"><b>(주) 바램시스템 설립</b></p>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "650" : "400"}>
                  <p>
                    <span>
                      <img src={img_2009} alt="2009" className="no_active" />
                      <img src={img_2009_cover} alt="2009" className="active" />
                    </span>
                  </p>
                  <div>
                    <p className="cont_tit">02</p>
                    <p className="cont_txt"><b>벤처 기업 인증</b></p>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "650" : "400"}>
                  <p>
                    <span>
                      <img src={img_2010} alt="2010" className="no_active" />
                      <img src={img_2010_cover} alt="2010" className="active" />
                    </span>
                  </p>
                  <div>
                    <p className="cont_tit">07</p>
                    <p className="cont_txt"><b>기업부설연구소 설립 및 인증</b><br />(제 2010111996호)</p>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "650" : "400"}>
                  <p>
                    <span>
                      <img src={img_2011} alt="2011" className="no_active" />
                      <img src={img_2011_cover} alt="2011" className="active" />
                    </span>
                  </p>
                  <div>
                    <p className="cont_tit">03</p>
                    <p className="cont_txt"><b>본사 이전</b>{isMobile && <br />} 대전테크노파크 IT전용벤처타운</p>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "650" : "400"}>
                  <p>
                    <span>
                      <img src={img_2015} alt="2015" className="no_active" />
                      <img src={img_2015_cover} alt="2015" className="active" />
                    </span>
                  </p>
                  <div className="cont_box">
                    <div>
                      <p className="cont_tit">05</p>
                      <p className="cont_txt">품질경영시스템 ISO9001 인증</p>
                    </div>
                    <div>
                      <p className="cont_tit">08</p>
                      <p className="cont_txt"><b>공장 등록 완료</b> 기타문선 통신 장비 제조업</p>
                    </div>
                    <div>
                      <p className="cont_tit">09</p>
                      <p className="cont_txt"><b>대전광역시 고용우수기업인증</b> 제2015-18호</p>
                    </div>
                    <div>
                      <p className="cont_tit">12</p>
                      <p className="cont_txt">앱봇 링크 해외 수출 <b>37만달러 성과 달성</b></p>
                    </div>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "650" : "400"}>
                  <p>
                    <span>
                      <img src={img_2016} alt="2016" className="no_active" />
                      <img src={img_2016_cover} alt="2016" className="active" />
                    </span>
                  </p>
                  <div className="cont_box">
                    <div>
                      <p className="cont_tit">01</p>
                      <p className="cont_txt">본사 및 공장 확장 이전</p>
                    </div>
                    <div>
                      <p className="cont_tit">03</p>
                      <p className="cont_txt">회전 스테이션 국내 특허 등록</p>
                    </div>
                    <div>
                      <p className="cont_tit">06</p>
                      <p className="cont_txt">크라우드 펀딩 고고 <b>17만 달러 달성</b></p>
                    </div>
                    <div>
                      <p className="cont_tit">08</p>
                      <p className="cont_txt"><b>미국 수출 3,000대 달성</b></p>
                    </div>
                    <div>
                      <p className="cont_tit">10</p>
                      <p className="cont_txt">홍콩 추계 전자 전시 참가 <b>로봇 분과 금상 수상</b></p>
                    </div>
                    <div>
                      <p className="cont_tit">12</p>
                      <p className="cont_txt">미국 2천대, 독일 1천대 등 <b>총 100만 달러 수출 달성</b><br />서병조 대표이사 <b>대통령 표창 수상</b></p>
                    </div>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "650" : "400"}>
                  <p>
                    <span>
                      <img src={img_2017} alt="2017" className="no_active" />
                      <img src={img_2017_cover} alt="2017" className="active" />
                    </span>
                  </p>
                  <div className="cont_box">
                    <div>
                      <p className="cont_tit">02</p>
                      <p className="cont_txt"><b>미국 법인, 유럽.일본 지사 설립</b></p>
                    </div>
                    <div>
                      <p className="cont_tit">06</p>
                      <p className="cont_txt"><b>일본 수출 6,000대 달성</b></p>
                    </div>
                    <div>
                      <p className="cont_tit">12</p>
                      <p className="cont_txt">외부기관 투자 유치 성공<br /><b>제 54회 무역의 날 기념 행사 백만불 수출의 탑</b></p>
                    </div>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "650" : "400"}>
                  <p>
                    <span>
                      <img src={img_2018} alt="2018" className="no_active" />
                      <img src={img_2018_cover} alt="2018" className="active" />
                    </span>
                  </p>
                  <div className="cont_box">
                    <div>
                      <p className="cont_tit">01</p>
                      <p className="cont_txt">사옥 및 공장 확장 이전 <b>첨단기술기업 지정</b></p>
                    </div>
                    <div>
                      <p className="cont_tit">09</p>
                      <p className="cont_txt"><b>GS Shop 30억 투자 유치</b></p>
                    </div>
                    <div>
                      <p className="cont_tit">10</p>
                      <p className="cont_txt">반려동물 건강 증진 <b>로봇 피트니스 개발</b></p>
                    </div>
                    <div>
                      <p className="cont_tit">12</p>
                      <p className="cont_txt">와디즈 6억 펀딩 성공</p>
                    </div>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "300" : "300"}>
                  <p>
                    <span>
                      <img src={img_2020} alt="2020" className="no_active" />
                      <img src={img_2020_cover} alt="2020" className="active" />
                    </span>
                  </p>
                  <div>
                    <p className="cont_tit">08</p>
                    <p className="cont_txt"><b>바램펫 피트니스 10만대 판매 달성</b></p>
                  </div>
                </li>
                <li data-aos="active" data-aos-offset={isPc ? "300" : "300"}>
                  <p>
                    <span>
                      <img src={img_2022} alt="2022" className="no_active" />
                      <img src={img_2022_cover} alt="2022" className="active" />
                    </span>
                  </p>
                  <div>
                    <p className="cont_tit">01</p>
                    <p className="cont_txt"><b>누적 투자 100억 유치</b></p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </AwardsContainer>
    );
};

const AwardsContainer = styled.div`
  width: 100%;
  padding-bottom: 160px;

  & .awards_wrap {
    display: flex;
    position: relative;

    &:first-child {
      margin-bottom: 160px;
    }

    & .tit_wrap {
      position: sticky;
      position: -webkit-sticky;
      flex-basis: 740px;
      top: 0;
      padding-top: 80px;
      color: #222;
      align-self: flex-start;
      transition: .3s;
			&.active {
				padding-top: 160px;
			}
      &[data-aos=active] {
        &.aos-animate {
          opacity: 1;
        }
      }
      & .sub_tit {
        margin-bottom: 6px;
        font-size: 1.5rem;
        line-height: 36px;
      }
      & .main_tit {
        font-size: 3.375rem;
        line-height: 70px;
        font-weight: 500;
      }
    }

    & .cont_wrap {
      margin-top: 280px;

      & li {
        display: flex;
        transition: .3s;

        &:not(:first-child) {
          margin-top: 60px;
        }

        & > p {
          display: inline-block;
          padding-left: 15px;
          padding-top: 5px;
          margin-right: 30px;
          background: url(${comma_white}) no-repeat left top;

          & img {
            height: 50px;
            transition: .3s;
            &.active {
              display: none;
            }
          }
        }

        & div {
          padding-top: 5px;
          & p {
            padding-left: 170px;
          }

          & .cont_tit {
            margin-bottom: 6px;
            background: url(${award_tit_bg2}) no-repeat left center;
            font-size: 1.5rem;
            line-height: 36px;
            font-weight: 500;
            color: #888;
            transition: .3s;
          }

          & .cont_txt {
            line-height: 24px;
            font-size: 1rem;
            color: #888;
          }
        }
      }
    }
  }

  & .intro_tech li:first-child {
    & > p {
      background: url(${comma_blue}) no-repeat left top;
      & img {
        &.active {
          display: inline-block !important;
        }
        &.no_active {
          display: none !important;
        }
      }
    }
    & .cont_tit {
      color: #333 !important;
      background: url(${award_tit_bg}) no-repeat left center !important;
    }
  }

  & .awards {
    & .tit_wrap {
      padding-top: 80px;
      color: #fff;
      &[data-aos=active] {
        &.aos-animate {
          color: #222;
        }
      }
    }
    & .cont_wrap {
      margin-top: 170px;
      & li {
        & .cont_box {
          flex-direction: column;
          & > div:not(:first-child) {
            margin-top: 30px;
          }
        }
      }
    }
  }

  & .awards_wrap li.top {
    & > p {
      background: url(${comma_blue}) no-repeat left top;
      & img {
        &.active {
          display: inline-block;
        }
        &.no_active {
          display: none;
        }
      }
    }
    & div {
      & .cont_tit {
        color: #333;
        background: url(${award_tit_bg}) no-repeat left center;
      }
    }
  }

  & .awards_wrap li[data-aos=active] {
    &.aos-animate {
      & > p {
        background: url(${comma_blue}) no-repeat left top;
        & img {
          &.active {
            display: inline-block;
          }
          &.no_active {
            display: none;
          }
        }
      }
      & .cont_tit {
        color: #333;
        background: url(${award_tit_bg}) no-repeat left center;
      }
    }
  }

  @media only screen and (max-width: 960px) {
    padding: 50px 15px 50px 15px;

    & .awards_wrap {
      display: block;
      position: relative;
      width: 100%;

      &:first-child {
        margin-bottom: 60px;
      }

      & .tit_wrap {
        position: sticky;
        position: --webkit-sticky;
        background: #fff;
        top: 0;
        padding: 0;
				&:after {
					position: absolute;
					content:"";
					left: 0;
					bottom: -40px;
					height: 40px;
					width: 100%;
					background: linear-gradient(to top, rgba(255, 255, 255, 0), #fff);
				}
        align-self: flex-start;
				&.active {
					padding-top: 80px;
				}
        & .sub_tit {
          font-size: 1rem;
          line-height: 24px;
        }
        & .main_tit {
          font-size: 2rem;
          line-height: 48px;
        }
      }

      & .cont_wrap {
        margin: 40px 0 0 0;

        & li {
          display: block;

          &:not(:first-child) {
            margin-top: 40px;
          }

          & > p {
            display: block;
            padding-left: 15px;
            padding-top: 3px;
            margin-right: 30px;
            background-size: 8px;

            & img {
              height: 36px;
            }
          }

          & div {
            padding-top: 25px;
            padding-left: 10px;
            & p {
              padding-left: 70px;
            }

            & .cont_tit {
              background-position: left -82px center;
              font-size: 1.125rem;
              line-height: 27px;
            }
          }
        }
      }

      &.intro_tech li:nth-child(-n+2) {
        & > p {
          background-size: 8px;
        }
        & .cont_tit {
          color: #333;
          background-position: left -82px center !important;
        }
      }
    }

    & .awards {
			margin-top: 50px;
      & .cont_wrap {
        & li {
          & > p {
            & span {
              display:inline-block;
              overflow: hidden;
              min-width: 90px;

              & img {
                margin-left: -53px;
              }
            }
          }
          & .cont_box {
            flex-direction: column;
            padding-top: 0;
            & div {
              padding-left: 0;
              &:not(:first-child) {
                padding-top: 0;
                margin-top: 15px;
              }
            }
          }

          & .cont_tit {
            color: #333 !important;
          }
        }
      }
    }

    & .awards_wrap li[data-aos=active] {
      &.aos-animate {
        & > p {
          background-size: 8px;
        }
        & .cont_tit {
          background-position: left -82px center;
        }
      }
    }
  }
`

export default AwardsPage;