import { useState, useEffect } from 'react'
import styled from 'styled-components';
import icon_btn_top from "images/icon/icon_btn_top.png";
import $ from 'jquery';

function TopButton() {
  const [BtnStatus, setBtnStatus] = useState(false);

  const handleTop = () => {
      $("html, body").animate({ scrollTop: 0 }, "slow");
      return false;
    setBtnStatus(false);
  }


  useEffect(() => {
      let isScrollAble = false;
      function handleFollow() {
          if (!isScrollAble) {
              isScrollAble = true;
              setTimeout(() => {
                  if (window.pageYOffset >= 600) {
                      setBtnStatus(true);
                  } else {
                      setBtnStatus(false);
                  }
                  isScrollAble = false;
              }, 100)
          }
      }
      window.addEventListener('scroll', handleFollow)
  },[])

  return (
    <Button 
      className={BtnStatus ? "topBtn active" : "topBtn"}
      onClick={handleTop}
    >
        <img src={icon_btn_top} alt="상단으로"></img>
    </Button>
  );
}

const Button = styled.div`
  opacity: 0;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9990;
  transition: .3s;
  cursor: pointer;
  & img {
    width: 50px;
  }
  &.active {
    opacity: 1;
  }

  @media only screen and (max-width: 960px) {
    bottom: 10px;
    right: 15px;
  }
`

export default TopButton;