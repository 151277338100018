import React , {useState , useEffect , useRef , useCallback} from "react";
import styled from "styled-components";
import ProductOption from "./components/ProductOption";
import RegularOrderOption from "./components/RegularOrderOption";
import DetailRate from "./components/DetailRate";
import DetailDesc from "./components/DetailDesc";
import Loader from "./components/Loader";
import {CLEAR_OPTIONS, PRODUCT_DETAILS_RESET, REGULAR_DETAILS_RESET} from "actions/types"
import SwiperCore , {Navigation , Pagination , Scrollbar , Mousewheel} from "swiper";
import {useSelector , useDispatch} from "react-redux";
import arrow_top from "images/arrow_top.png";
import arrow_bottom from "images/arrow_bottom.png";
import icon_dog_pictogram from "images/icon_dog_pictogram.png";
import {Swiper , SwiperSlide} from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import {useMediaQuery} from "react-responsive";
import $ from 'jquery';
import ReviewPopup from 'pages/components/ReviewPopup';
import {listProductDetails , ListRegularDetails} from "actions/productActions"
import axios from "axios";

SwiperCore.use([Navigation , Pagination , Scrollbar , Mousewheel]);

const ItemDetailsPage = (props) => {
    const isPc = useMediaQuery({query: '(min-width: 961px)'})
    let product = {};
    let match = props.match.params.id;
    const [bests , setBests] = useState([]);
    const [reviewInfo , setReviewInfo] = useState({});
    const [reviewPoint , setReviewPoint] = useState([]);
    const productDetail = useSelector((state) => state.productDetails || state.regularInfo);
    const {loading, productInfo , regularInfo} = productDetail;
    const [reviewItem , setReviewItem] = useState({})
    const dispatch = useDispatch();
    const is_regular = props.match.params.type === "regular"
    const [priceInfo , setPriceInfo] = useState({});
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    useEffect(() => {
        return () => {
            setItemInfo({});
            setReviewItem({})
            setReviewInfo({});
            setReviewPoint([]);
            setBests([]);
            setPriceInfo({});
        }
    }, [])
    useEffect(() => {
        if (is_regular) {
            if (!regularInfo) {
                return
            }
            if (!regularInfo.info) {
                return
            }
            setPriceInfo({
                "sellPrice": regularInfo.info.sell_price || "0" ,
                "beforePrice": regularInfo.info.price ,
                "shippingPrice": regularInfo.info.shipping_price || "0" ,
                "review": regularInfo.info.review_count ,
            })
            setItemInfo(regularInfo)
        } else {
            if (!productInfo) {
                return
            }
            if (!productInfo.info) {
                return
            }
            setPriceInfo({
                "sellPrice": productInfo.info.sell_price || "0" ,
                "beforePrice": productInfo.info.price ,
                "shippingPrice": productInfo.info.shipping_price || "0" ,
                "review": productInfo.info.review_count ,
            })
            setItemInfo(productInfo)
        }
    } , [productInfo , regularInfo])

    const getImgs = useCallback(() => {
        if (!productInfo) {
            return
        } else {
            if (!productInfo.info) {
                return
            }
        }
        if (!regularInfo) {
            return
        } else {
            if (!regularInfo.info) {
                return

            }
        }
        let image = "";
        if (is_regular) {
            image = productInfo.info.imgs;
        } else {
            image = regularInfo.info.imgs
        }
        return image
    } , [is_regular , productInfo , regularInfo])


    const fetchReviewInfo = async (page) => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/review/best/${props.match.params.id}?type=${props.match.params.type}&start=0?&length=20` , config);
        setReviewInfo(res.data.info);
        setReviewPoint(res.data.point);
        setBests(res.data.data);
    }

    useEffect(() => {
        fetchReviewInfo();
    } , []);


    const [itemInfo , setItemInfo] = useState({});

    if (props.location.state) {
        product = props.location.state.item;
    }
    useEffect( () => {

        if (is_regular) {
            dispatch(ListRegularDetails(match))
            return
        }
        dispatch(listProductDetails(match))

        return () => {
            dispatch({type: CLEAR_OPTIONS});
            dispatch({type: PRODUCT_DETAILS_RESET});
            dispatch({type: REGULAR_DETAILS_RESET});
        };

    } , [dispatch , match]);



    //PC 왼쪽 제품이미지 슬라이드 네비게이션
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    // 리뷰 팝업창
    const [review , setReview] = useState(false);
    const onCancelReview = () => {
        setReview(false);
    }

    const [Mobile , setMobile] = useState(false);
    const resizingHandler = () => {
        if (window.innerWidth > 960) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    };

    useEffect(() => {
        var windowWidth = $(window).width();
        if (loading) {

        } else {
            $(function () {
                $(".productSwiper .slide:first-child > div").addClass("active");
                $(".productSwiper .slide").on('click' , function () {
                    var src = $(this).find("img").attr("src");
                    $(".main_img img").attr('src' , src);
                    $(".productSwiper .slide > div").removeClass("active");
                    $(this).find("div").addClass("active");
                });
            });
        }
        //};
    } , [loading]);


    return (
        <ProductDetail id="ProductDetail">
            {loading ? <Loader/> : itemInfo.info ? <div className="product_wrapper">
                <div className="product_view">
                    <div className="product_img_wrap">
                        <Swiper
                            direction={isPc ? "vertical" : "horizontal"}
                            spaceBetween={isPc ? 10 : 0}
                            slidesPerView={isPc ? 6.3 : 1}
                            navigation={{
                                prevEl: navigationPrevRef.current ,
                                nextEl: navigationNextRef.current ,
                            }}
                            onInit={(swiper) => {
                                setTimeout(() => {
                                    if (swiper.params) {
                                        if (swiper.params.navigation) {
                                            swiper.params.navigation.prevEl = navigationPrevRef.current
                                            swiper.params.navigation.nextEl = navigationNextRef.current
                                        }
                                    }
                                    swiper.navigation.destroy()
                                    swiper.navigation.init()
                                    swiper.navigation.update()
                                })
                            }}
                            mousewheel={isPc ? true : false}
                            scrollbar={isPc ? false : true}
                            className="productSwiper"
                        >
                            <SwiperSlide className="slide">
                                {itemInfo.info.main_img === "" ?
                                    <div><img src={icon_dog_pictogram} alt="제품이미지"/></div> :
                                    <div><img src={itemInfo.info.main_img} alt="제품이미지"/></div>}
                            </SwiperSlide>
                            {
                                itemInfo.info.imgs ?
                                    typeof itemInfo.info.imgs === "string" ?
                                        itemInfo.info.imgs.split(",").map(v => {
                                            return (
                                                <SwiperSlide className="slide">
                                                    <div><img src={v} alt="제품이미지"/></div>
                                                </SwiperSlide>
                                            )
                                        }) : Array.isArray(itemInfo.info.imgs) ?
                                            itemInfo.info.imgs.map(v => {
                                                return (
                                                    <SwiperSlide className="slide">
                                                        <div><img src={v} alt="제품이미지"/></div>
                                                    </SwiperSlide>
                                                )
                                            }) : null :
                                    null

                            }
                            {
                                itemInfo ?
                                    getImgs() ?
                                        typeof getImgs() === "string" ?
                                            <SwiperSlide className="slide" key={9999}>
                                                <div><img src={getImgs()} alt="제품이미지"/></div>
                                            </SwiperSlide> :
                                            getImgs().map((el , idx) => (
                                                <SwiperSlide className="slide" key={idx}>
                                                    <div><img src={el} alt="제품이미지"/></div>
                                                </SwiperSlide>
                                            )) :
                                        null :
                                    null
                            }
                        </Swiper>

                        <div
                            ref={navigationPrevRef}
                            className="swiper-button-prev"
                        >이전
                        </div>
                        <div
                            ref={navigationNextRef}
                            className="swiper-button-next"
                        >다음
                        </div>
                    </div>
                    <div className="main_img_wrap">
                        <div className="status_box">
                            {itemInfo && itemInfo.info.Icon.map((icon , idx) => {
                                return (
                                    <span className="status" key={idx}>
                                    <img src={icon.url} alt={icon.label}/>
                                </span>
                                )
                            })}
                        </div>
                        <div className="main_img">
                            {itemInfo.info.main_img !== "" ? (
                                <img src={itemInfo.info.main_img} alt="제품메인이미지"/>
                            ) : (
                                <img src={icon_dog_pictogram} alt="제품대체이미지"/>
                            )}
                        </div>
                    </div>
                    {/*옵션창 분리 */}
                    <div className="product_option">
                        {is_regular ?
                            <RegularOrderOption itemInfo={itemInfo.info}/> :
                            <ProductOption itemInfo={itemInfo.info} priceInfo={priceInfo}/>
                        }
                    </div>
                    {/*옵션창 분리 */}
                </div>
                <div className="product_review">
                    <DetailRate info={reviewInfo} point={reviewPoint} bests={bests} setReview={setReview}
                                setReviewItem={setReviewItem} id={itemInfo.info.id}
                                type={props.match.params.type}/>
                </div>
                <div className="product_desc">
                    {
                        <DetailDesc product={itemInfo.info} itemInfo={itemInfo.info} reviewCount={productInfo.info.review_count} 
                                    setReview={setReview} setReviewItem={setReviewItem} id={itemInfo.info.id}
                                    type={props.match.params.type}/>
                    }
                </div>
                {reviewItem && review &&
                    <ReviewPopup visible={review} onCancelReview={onCancelReview} review={reviewItem} />
                }

            </div> : <Loader/>}

        </ProductDetail>
    );
};

const ProductDetail = styled.div`
  & .product_wrapper {
    max-width: 1480px;
    padding: 60px 0 110px;
    margin: 0 auto;
    position: relative;
    @media only screen and (max-width: 960px) {
      padding: 0 0 93px 0;
      width: 100%;
    }
  }

  & .product_view {
    display: flex;
    justify-content: space-between;
    margin: 0 0 100px 0;

    @media only screen and (max-width: 960px) {
      flex-direction: column;
      margin: 0;
    }

    & .product_img_wrap {
      position: relative;
      width: 80px;
      height: 660px;

      & .swiper-button-prev,
      & .swiper-button-next {
        display: flex;
        content: "";
        text-indent: -9999px;
        width: 80px;
        height: 34px;
        left: 0;
        justify-content: center;
        align-items: center;
        margin: 0;

        &::after {
          content: none;
        }

        img {
          opacity: 0.2;
        }
      }

      & .swiper-button-prev {
        top: 0;
        background: url(${arrow_top}) no-repeat center;
        @media only screen and (max-width: 960px) {
          display: none;
        }
      }

      & .swiper-button-next {
        top: inherit;
        bottom: 0;
        background: url(${arrow_bottom}) no-repeat center;
        @media only screen and (max-width: 960px) {
          display: none;
        }
      }

      & .productSwiper {
        height: 558px;
        min-width: 80px;
        margin-top: 50px;

        @media only screen and (max-width: 960px) {
          margin: 0;
        }

        & .slide {
          display: flex;
          flex-direction: column;
          width: 80px;
          height: 80px !important;
          cursor: pointer;

          & > div {
            position: relative;
            height: 100%;
            width: 100%;
            opacity: 0.6;
            padding-bottom: 100%;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 100%;
              width: 100%;
              object-fit: cover;
            }

            &.active, &:hover {
              border: 1px solid #1EA0FA;
              opacity: 1;
              @media only screen and (max-width: 960px) {
                border: none
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    margin: 0;

    & .product_view {
      & .product_img_wrap {
        width: 100%;
        height: auto;
        order: 1;

        & .productSwiper {
          height: 100%;
          margin: 0;

          & .swiper-wrapper {
            overflow: visible;
            margin: 0;
          }

          & .swiper-slide {
            display: block;
            width: 100%;
            height: auto !important;

            & > div {
              opacity: 1;
              flex-direction: row;
            }
          }
        }

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      & .swiper-container-horizontal > .swiper-scrollbar {
        bottom: 15px;
        left: 15px;
        height: 3px;
        width: calc(100% - 30px);
        background: #eee;
        border-radius: 3px;

        & .swiper-scrollbar-drag {
          background: #222;
          border-radius: 3px;
        }
      }
    }
  }

  & .main_img_wrap {
    position: relative;
    flex-basis: 660px;
    height: 660px;
    margin: 0 20px;
    @media only screen and (max-width: 960px) {
      flex-basis: 0;
      height: 0;
      order: 0;
      margin: 0;
    }

    & .status_box {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      @media only screen and (max-width: 960px) {
        top: 15px;
        left: 15px;
      }

      & span {
        display: block;
        height: 30px;
        @media only screen and (max-width: 960px) {
          height: 28px;
        }
      }

      & .status {
        margin: 0 0 0 0;

        & img {
          height: 100%;
        }
      }
    }
  }

  & .main_img {
    overflow: hidden;
    position: relative;

    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    @media only screen and (max-width: 960px) {
      display: none;
    }
  }

  & .product_option {
    flex-basis: 500px;

    @media only screen and (max-width: 960px) {
      min-width: auto;
      flex-basis: auto;
      margin-top: 24px;
      order: 2;
    }
  }

  h2 {
    margin-bottom: 8px;
    font-size: 2.625rem;
    line-height: 54.6px;
    font-weight: 500;
    color: #222;

    @media only screen and (max-width: 960px) {
      font-size: 24px;
      line-height: 31.2px;
      padding: 0 15px;
    }
  }

  .sub_tit {
    margin-bottom: 40px;
    font-size: 15px;
    font-weight: 400;
    color: #515355;
    line-height: 22.5px;

    @media only screen and (max-width: 960px) {
      margin-bottom: 0;
      font-size: 14px;
      padding: 0 15px;
      line-height: 21px;
      color: #888;
    }
  }
`;

export default ItemDetailsPage;
