import React, {useCallback, useState} from "react";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import Button from "./components/Button";
import Dialog from "./components/Dialog";
import DotGuide from "./components/DotGuide";
import axios from "axios";
import {isPhoneNumOk, validateCodeNum} from "utils/Validated";
import AuthTimer from "./components/AuthTimer";

const FindIdPage = () => {
  const history = useHistory();
  const [inputState, setInputState] = useState({phone_number: "", number_check: ""}); //인풋에 작성하는 값
  const {phone_number, number_check} = inputState; //인풋 작성의 구조분해 할당 값
  const [accounts, setAccounts] = useState([]); //인증받은 후 정보 저장
  const [hideNumber, setHideNumber] = useState(true);
  const [phoneNumSend, setPhoneNumSend] = useState({ //번호 전송시 변하는 버튼 값
    btnColor: "gray",
    phoneDisabled: false,
    codeNumDisabled: true,
  })
  const [counter, setCounter] =useState(0); //카운터 default 값
  const [accountCheck, setAccountCheck] = useState({ //팝업 값
      open: false,
      btnLogin: true,
  })

  const inputHandler = useCallback((key) => (e) => { //인풋 핸들러
        return setInputState({
            ...inputState,
            [key]: e.target.value,
        });
    },[inputState]
  )

    const checkPhone = useCallback( //휴대폰 번호 전송
        (e) => {
            e.preventDefault();
            axios.post(`${process.env.REACT_APP_API_URL}api/auth/validPhone`, {phone_number})
                .then((res)=>{
                    if(res.data.success){
                        setCounter(180)
                        setPhoneNumSend( {
                            ...phoneNumSend,
                            btnColor: "blue",
                            phoneDisabled: true,
                            codeNumDisabled: false})
                    }else{
                        console.log(res)
                    }
                }).catch((error)=>{
                console.log(error)
            })
        }
    )

    const checkNumber = useCallback( //인증된 번호 전송
        (e) => {
            e.preventDefault();
            axios.post(`${process.env.REACT_APP_API_URL}api/auth/checkPhone`,{
                phone_number, "code": number_check, "type": "find_id"
            }).then((res) => {
                console.log(res.data)
                setAccounts([...accounts, res.data.data.accounts])
                if(res.data.type === "success"){  //인증번호 승인
                    alert("인증되었습니다.")
                    setPhoneNumSend( {
                        ...phoneNumSend,
                        btnColor: "blue",
                        phoneDisabled: false,
                        codeNumDisabled: true})
                }else{
                    alert("인증번호가 맞지않습니다.")
                }
            }).catch((error)=>{
                console.log(error)
            })
        }
    )
  const submitPhone = () => { //인증하기 버튼 실행
      setAccountCheck({...accountCheck, open: true})
  }

  const hide_string = (s) => {
    let result = "*".repeat(s.length - 15) + s.slice(-15)
    return result
  }

	return (
    <FindIdContainer>
      <div className="title_wrap">
        <h2>아이디 찾기</h2>
      </div>
      <div className="find_wrap">
        <p><span>휴대폰 번호</span>를 입력해 주세요.</p>
        <div className="find_form chk_phone">
          <form>
            <p>
              <input type="number" name="phone_number" value={phone_number} onChange={inputHandler("phone_number")} disabled={phoneNumSend.phoneDisabled ? true : false} placeholder="숫자만 입력해주세요."></input>
                {isPhoneNumOk(phone_number) ? <SmallButton onClick={!phoneNumSend.phoneDisabled ? checkPhone : null} size="small">인증번호 받기</SmallButton>
                    : <SmallButton size="small" >인증번호 받기</SmallButton>}
            </p>
            <p>
              <input type="text" name="number_check" value={number_check} onChange={inputHandler("number_check")} onFocus={(e) => {setHideNumber(!hideNumber)}} disabled={phoneNumSend.codeNumDisabled ? true : false}  placeholder="인증번호 입력" />
              <SmallButton size="small" color={phoneNumSend.btnColor} onClick={validateCodeNum(number_check) ? checkNumber : null}>인증번호 확인</SmallButton>
            </p>
              {phoneNumSend.phoneDisabled ? <span>
                  {counter === 0 ? <p className="sub_desc"><span className="counter_msg">인증번호를 받지못했습니다.</span></p> : <p className="sub_desc">인증번호를 발송하였습니다.
                        <span className="counter_msg">유효시간 : <AuthTimer counter={counter} setCounter={setCounter} /></span></p>}
            </span> : null}
            <div className="button_wrap">
                {accounts.length === 0 ? <MainButton color="gray" fullWidth >아이디 찾기</MainButton> : <MainButton color="blue" fullWidth onClick={submitPhone}>아이디 찾기</MainButton> }
            </div>
          </form>
        </div>
      </div>
        <div className="account_check_dialog">
            <Dialog
                onCancel= {() => {setAccountCheck({ ...accountCheck, open: false}); setAccounts([]); history.push("/login");}}
                onConfirm={() => {setAccountCheck({ ...accountCheck, open: false}); setAccounts([]); history.push("/login");}}
                confirmText="로그인"
                dialogSize="small"
                btnLogin={accountCheck.btnLogin}
                visible={accountCheck.open}
            >
                <p>이메일 계정을 찾았습니다.</p>
                {accounts[0]?.map((el) => {
                    if(accounts[0].length === 0){
                        return (
                            <div><p>가입된 기록이 없습니다.</p></div>
                        )
                    }else{
                        return (
                            <div>
                                <p>{el.login_type === "local" ? "이메일" : "카카오"} 아이디</p>
                                <p>{hide_string(el.email)}</p>
                                <p>마지막 로그인 기록 {String(el.login_time).replaceAll("-", ".")}</p>
                            </div>
                        )
                    }
                })}
                <p style={{marginTop: "10px", fontSize: "13px", color: "#888888", textAlign:"left"}}><DotGuide size="small" text="전체 이메일을 확인하고자 하는 경우에는 고객센터로 문의해주세요." /> </p>
            </Dialog>
        </div>
    </FindIdContainer>
	);
}

const SmallButton = styled(Button)`
  width: auto;
  padding: 0 14px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
  @media only screen and (max-width: 960px) {
    flex: 100px 0 0;
    padding: 0;
    border-radius: 0;
    font-size: 14px;
  }
`

const MainButton = styled(Button)`
	@media only screen and (max-width: 960px) {
		height: 50px;
    font-weight: 700;
    font-size: 1rem;
	}
`

const FindIdContainer = styled.div`
  max-width: 1480px;
  margin: 0 auto;

  & .find_wrap {
    width: 500px;
    margin: 0 auto;
    & > p {
      margin: 100px 0 60px;
      font-size: 26px;
      font-weight: 300;
      line-height: 39px;
      & span {
        font-weight: 400;
      }
    }
    & .find_form {
      p:not(:first-child) {
        margin-top: 10px;
      }
    }

    & .chk_phone {
      p {
        display: flex;
      }
    }

    & .button_wrap {
      margin: 80px 0;
    }
    
    & .sub_desc{
      margin-top: 8px;
      color: #1ea0fa;
      font-size: 0.75rem;
      line-height: 18px;
      
      & .counter_msg{
        color: #ff2727 !important;
        font-size: 0.75rem;
        line-height: 18px;
      }
    }
  }

  @media only screen and (max-width: 960px) {
    margin: 0;
    & .find_wrap {
      width: 100%;
      margin: 0;
      padding: 0 15px;
      & > p {
        margin: 60px 0 30px;
        font-size: 22px;
        line-height: 33px;
        & span {
          font-weight: 400;
        }
      }
      & .find_form {
        p:not(:first-child) {
          margin-top: 5px;
        }
        input {
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
      }
      
      & .button_wrap {
        margin: 40px 0 46px;
      }
    }
  }

  & .account_check_dialog {
    & .dialog_wrap {
      padding: 50px 30px 30px 30px;
      text-align: center;
      & .close {
        display: none;
      }
      & .dialog_inner {
        & > p:first-child {
          margin-bottom: 30px;
          font-size: 20px;
          line-height: 30px;
          font-weight: 500;
          & span {
            display: block;
            margin-top: 2px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
          }
        }
        & div {
          margin-top: 10px;
          padding: 20px 0;
          background: #f7f7f7;
          & p:first-child {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            color: #555;
          }
          & p:nth-child(2) {
            margin-bottom: 12px;
            font-size: 18px;
            line-height: 27px;
            color: #1EA0FA;
          }
          & p:nth-child(3) {
            font-size: 13px;
            line-height: 19.5px;
            color: #888;
          }
        }
        & .dot_guide {
          margin-top: 10px;
        }
      }
      & .ButtonWrap {
        width: 100%;
        margin-top: 40px;
        & div {
          width: calc((100% - 5px) / 2);
          &:not(:first-child) {
            margin-left: 5px;
          }
        }
      }
    }
    @media only screen and (max-width: 960px) {
      & .dialog_wrap {
        padding: 40px 27.5px 30px 27.5px;
        text-align: center;
        & .close {
          display: none;
        }
        & .dialog_inner {
          & > p {
            font-size: 18px;
            line-height: 27px;
          }
        }
        & .ButtonWrap {
          & div {
            width: calc((100% - 10px) / 2);
            font-weight: 700;
            &:not(:first-child) {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
`

export default FindIdPage;