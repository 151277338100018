import React from 'react';
import styled, { css } from 'styled-components';
import { BsDot } from 'react-icons/bs';

const sizes = {
  large: {
    fontSize: '16px',
    lineHeight: '24px'
  },
  medium: {
    fontSize: '15px',
    lineHeight: '22.5px'
  },
  small: {
    fontSize: '13px',
    lineHeight: '19.5px'
  },
  tiny: {
    fontSize: '12px',
    lineHeight: '18px'
  }
}

const sizeStyles = css`
  ${({ size }) => css`
    font-size: ${sizes[size].fontSize};
    line-height: ${sizes[size].lineHeight};
  `}
`

const colors = {
  black: {
    fontColor: '#222',
  },
  gray: {
    fontColor: '#515355'
  },
  lightgray: {
    fontColor: '#888'
  }
}

const colorStyles = css`
  ${({ color }) => css`
    color: ${colors[color].fontColor};
  `}
`

const DotGuide = ({ text, size, color }) => {
  return (
    <DotGuideCotainer size={size} color={color} className="dot_guide">
      <span><BsDot /></span><span style={{wordBreak: "keep-all"}}>{text}</span>
    </DotGuideCotainer>
  )
}

const DotGuideCotainer = styled.p`
  display: flex;
  text-align: left;
  & span {
    ${sizeStyles};
    ${colorStyles};
    word-break: break-all;
    @media only screen and (max-width: 960px) {
        letter-spacing: -0.01em;
    }
    &:first-child {
      display: inline-block;
      flex: 20px 0 0;
    }
    & svg {
      vertical-align: middle;
      margin-top: -2px;
    }
  }
`

DotGuide.defaultProps = {
  size: 'medium',
  color: 'black'
}

export default DotGuide;