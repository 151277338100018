import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from "react-router-dom";
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/controller/controller.scss';
import { useMediaQuery } from 'react-responsive';
import { Tabs } from 'antd';
import Button from './components/Button';
import { lighten } from 'polished'
import axios from "axios";
import {stringPrice} from "../utils/StringPrice";
import MainPopup from "./components/MainPopup.js";
import {useCookies} from "react-cookie";
import {useSelector, useDispatch} from "react-redux";
import {addToCart} from "../actions/cartActions";
import Dialog from "./components/Dialog";
import Moment from "moment"
import main_slide_prev from 'images/icon/main_slide_prev.png';
import main_slide_next from 'images/icon/main_slide_next.png';
import ico_pause from 'images/icon/ico_pause.png';
import ico_pause_c from 'images/icon/ico_pause_c.png';
import ico_start from 'images/icon/ico_start.png';
import ico_start_c from 'images/icon/ico_start_c.png';
import m_more_btn_b from 'images/m/more_btn_b.png';
import main_bg_section1 from 'images/bg/main-bg-section1.png';
import kakao_banner from 'images/kakao_banner.png';
import m_kakao_banner from 'images/m_kakao_banner.png';
import healthcare_next from 'images/icon/healthcare_next.png';
import healthcare_prev from 'images/icon/healthcare_prev.png';
import m_healthcare_next from 'images/m/healthcare_next.png';
import icon_dog_pictogram from "images/icon/dog_pictogram.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const { TabPane } = Tabs;

const MainPage = () => {
    const isPc = useMediaQuery({ query: '(min-width: 961px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const [cookies, setCookie] = useCookies(["mainPop_:id"])
    const {userInfo} = useSelector((state) => state.userLogin)
    const cartRefresh = useSelector((state) => state.cartRefresh);
    const cartNoUser = useSelector((state) => state.cartItems);
    const mainContainerRef = useRef();
    const swiperRef = useRef(null)
    const dispatch = useDispatch();
    const [mainProducts, setMainProducts] = useState([])
    const [popup, setPopup] = useState([])
    const [mainBanner, setMainBanner] = useState([])
    const [subBanner, setSubBanner] = useState([])
    const [healthBanner, setHealthBanner] = useState([])
    const [mainBannerMo, setMainBannerMo] = useState([])
    const [subBannerMo, setSubBannerMo] = useState([])
    const [healthBannerMo, setHealthBannerMo] = useState([])
    const [visiblePopup, setVisiblePopup] = useState([])
    const [checkExpire, setCheckExpire] = useState(false);
    const [youtubeVideo, setYoutubeVideo] = useState([]);
    const [youtubeVideoM, setYoutubeVideoM] = useState([]);
    const [ dialog, setDialog ] = useState({
        open: false,
        confirmText: "",
        cancelText: "",
        dialogContent: "",
        btnHide: false,
        data : {}
    });

    const onClickToCart = useCallback((data) => {
        let option = [];
        if (data.OptionGroups.length >= 0) {
            if (data.OptionGroups[0]) {
                data.OptionGroups[0].Options[0].quantity = 1;
                option.push(data.OptionGroups[0].Options[0]);
            }
        }
        let cartitem = [];
        let isInCart = [];
        if (userInfo.data) {
            if (data.is_regular_order && !data.is_device) {
                cartitem = cartRefresh.cartRegularItems;
            } else {
                cartitem = cartRefresh.cartNomalItems;
            }
        } else {
            cartitem = cartNoUser.cartItems
        }
        isInCart = cartitem.filter(v => {return v.ProductId === data.id});

        for (let i = 0; i < data.OptionGroups.length; i++) {
            const optionGroup = data.OptionGroups[i];
            if (optionGroup.require) {
                optionGroup.Options[0].require = true;
                let operator = ""

                if (optionGroup.Options[0].price >= 0) {
                    operator = "+"
                }
                data.selectedOption = [{...optionGroup.Options[0]}];

                data.selectedOption[0].name = `${optionGroup.Options[0].name} (${operator}${optionGroup.Options[0].price})`
            }
        }
        if (isInCart.length > 0) {
            let str = '장바구니에 동일한 상품이 담겨있습니다.\n 장바구니에 추가하시겠습니까?';
            setDialog({...dialog, open: true, confirmText: "확인", cancelText: "취소", dialogContent: str, data: data});
        }else if(data.is_soldout === true || data.sell === false){
            setDialog({...dialog, open: true, btnHide: true, confirmText: "확인", cancelText: "확인", dialogContent: "상품이 품절되었습니다!"});
        }else{
            dispatch(addToCart(data));
            setDialog({...dialog, open: true, confirmText: "장바구니로 이동", cancelText: "계속 쇼핑하기", dialogContent: "장바구니에 잘 담겼어요!"});
        }
    }, [cartRefresh])

    useEffect(() => {
        if (visiblePopup.length >= 1) {
            if (isMobile) {
                mainContainerRef.current.style.zIndex = 100000;
            }
        } else {
            mainContainerRef.current.style.zIndex = "auto";
        }
    }, [visiblePopup, isMobile])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/main`).then((res) => {
            //배너
            let MainBanner = res.data.banner.filter((item) => item.type === 'main-pc').sort(function(a, b) {
                return a.order - b.order;
            });
            setMainBanner(MainBanner);
            let MainBannerMo = res.data.banner.filter((item) => item.type === 'main-mobile').sort(function(a, b) {
                return a.order - b.order;
            });

            setMainBannerMo(MainBannerMo);
            setSubBanner(res.data.banner.filter((item) => item.type === 'sub-pc').sort(function(a, b) {
                return a.order - b.order;
            }));
            setSubBannerMo(res.data.banner.filter((item) => item.type === 'sub-mobile').sort(function(a, b) {
                return a.order - b.order;
            }));
            setHealthBanner(res.data.banner.filter((item) => item.type === 'health-pc').sort(function(a, b) {
                return a.order - b.order;
            }));
            setHealthBannerMo(res.data.banner.filter((item) => item.type === 'health-mobile').sort(function(a, b) {
                return a.order - b.order;
            }));

            if(MainBanner && !isPc){
                document.querySelector("#noneForSec").style.display = "block";
            }

            for (let i = 0; i < MainBanner.length; i++) {
                if (MainBanner[i].video) {
                    if (MainBanner[i].video !== "") {
                        setYoutubeVideo(prevState => [...prevState, {id: MainBanner[i].id.toString(), videoId: youtube_parser(MainBanner[i].video)}])
                    }
                }
            }
            for (let i = 0; i < MainBannerMo.length; i++) {
                if (MainBannerMo[i].video) {
                    if (MainBannerMo[i].video !== "") {
                        setYoutubeVideoM(prevState => [...prevState, {id: MainBannerMo[i].id.toString(), videoId: MainBannerMo[i].video.replaceAll("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/")}])
                    }
                }
            }

            const lastPageination = document.querySelector(".swiper-pagination");
            if(isPc){
                MainBanner.map((item, idx) => {
                    if (!document.querySelector(`.swiper-pagination span:nth-child(${idx+1})`)) return
                    document.querySelector(`.swiper-pagination span:nth-child(${idx+1})`)
                        .innerHTML = `<strong>${item.title}</strong><i></i>`;
                })
            }else {
                MainBannerMo.map((item, idx) => {
                    document.querySelector(`.swiper-pagination span:nth-child(${idx + 1})`)
                        .innerHTML = `<strong>${item.title}</strong><i></i>`;
                })
            }
            // const swiperController = document.querySelector(".swiper-controller");
            // lastPageination?.appendChild(swiperController);

            //메인상품
             setMainProducts(res.data.main);
            //팝업
            setPopup(res.data.popup);
        })
    }, [isPc]);
    useEffect(() => {
        if(popup.length > 0){
            popup.map((el) => {
                const createdAt = Moment(el.start_date, "YYYY-MM-DD").format();
                const end_date = Moment(el.end_date, "YYYY-MM-DD").format();
                const now = Moment().format();
                const duration_create = Moment.duration({from: now, to: createdAt});
                const duration_end = Moment.duration({from: now, to: end_date});
                if (duration_create.asDays() >= 0) {
                    return
                }
                if (duration_end.asDays() <= 0) {
                    return
                }
                if (isMobile) {
                    if (el.type === "mobile") {
                        setVisiblePopup((visiblePopup) => [...visiblePopup, el.id])
                        if(cookies[`mainPop_${el.id}`] !== undefined){
                            setVisiblePopup(visiblePopup.filter((el) => el !== el.id))
                        }
                    }
                } else {
                    if (el.type === "pc") {
                        setVisiblePopup((visiblePopup) => [...visiblePopup, el.id])
                        if(cookies[`mainPop_${el.id}`] !== undefined){
                            setVisiblePopup(visiblePopup.filter((el) => el !== el.id))
                        }
                    }
                }
            })
        }
    }, [popup])


    const swiperStop = useCallback(() => {
        swiperRef.current.swiper.autoplay.stop();
        document.querySelector(".swiper-stop").style.display = "none";
        document.querySelector(".swiper-start").style.display = "inline-block";
        document.querySelector(".swiper-pagination").className += " paused";
    }, [swiperRef])

    const swiperStart = useCallback(() => {
        swiperRef.current.swiper.autoplay.start();
        document.querySelector(".swiper-stop").style.display = "inline-block";
        document.querySelector(".swiper-start").style.display = "none";
        document.querySelector(".swiper-pagination").className = "swiper-pagination";
    }, [swiperRef])

    const getMainProducts = useCallback((el) => {
        var lists = el.Products.filter((el) => el.display === true).map((product, idx) =>{
            let requireOptionPrice = 0;
            for (let i = 0; i < product.OptionGroups.length; i++) {
                const optionGroup = product.OptionGroups[i];
                if (optionGroup.require) {
                    requireOptionPrice = optionGroup.Options[0]?.price;
                }
            }
            return (
                <SwiperSlide key={idx}>
                    {isPc ?
                        <div className="thumb">
                            <img src={product.main_img ? product.main_img : icon_dog_pictogram} alt="제품썸네일"/>
                        </div> :
                        <Link className="thumb moveimg" to={`/products/details/normal/${product.id}`}>
                            <img src={product.main_img ? product.main_img : icon_dog_pictogram} alt="제품썸네일"/>
                        </Link>
                    }
                    <p className="itemTitle">{product.name}</p>
                    <p className="price">
                        {product.discount_percent ? <span className="discount">{product.discount_percent ? product.discount_percent : 0}%</span> : null}
                        <span className="after_discount">{stringPrice(product.sell_price + requireOptionPrice)}<i className="won">원</i></span>
                        {product.discount_percent ?<span className="before_discount">{stringPrice(product.price + requireOptionPrice)}<i className="won">원</i></span> : null}
                    </p>
                    <div className="button_wrap">
                        <Link to={`/products/details/normal/${product.id}`}><Button size="small" color="white" outline>더알아보기</Button></Link>
                        <a onClick={() => {onClickToCart(product)}}><Button size="small">장바구니</Button></a>
                    </div>
                </SwiperSlide>
            )
        })
        return lists;
    }, [cartRefresh])

    const closePop = useCallback((id) => {
        setVisiblePopup(prevState => prevState.filter((el) => el !== id))
    }, [])

    const handleExpire = useCallback((id, close) => {
        let now = new Date;
        let today = new Date();

        let Exp = close === "month" ? 30 : close === "week" ? 7 : 1

        today.setDate(now.getDate() + Exp)
        if(cookies[`mainPop_${id}`] === undefined){
            setCheckExpire(true);
            setCookie(`mainPop_${id}`, id, {path: '/', expires: today});
            setVisiblePopup(prevState => prevState.filter((el) => el !== id))
            setCheckExpire(false);
        }else{
            setVisiblePopup(prevState => prevState.filter((el) => el !== id))
        }
    }, [cookies])

    const onConfirm = useCallback(async (dialogText, data) => {
        if(dialogText === "장바구니로 이동"){
            window.location.href="/shoppingcart";
        } else {
            try{
                dispatch(addToCart(data));
                setDialog({...dialog, open: true, confirmText: "장바구니로 이동", cancelText: "계속 쇼핑하기", dialogContent: "장바구니에 잘 담겼어요!"});
            }catch (error) {
                console.log(error);
            }
        }
    }, [])

    const onCancel = useCallback(async (dialogText) => {
      if(dialogText === "계속 쇼핑하기"){
             window.location.href="/";
        } else {
           setDialog(false);
        }
        
    })

    const onClose = () => {
       setDialog(false);
    }

    const youtube_parser = useCallback((url) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length === 11)? match[7] : false;
    }, [])

    return (
      <>
        <MainContainer ref={mainContainerRef}>
            <Dialog
                confirmText={`${dialog.confirmText}`}
                cancelText={`${dialog.cancelText}`}
                onConfirm = {() => {onConfirm(dialog.confirmText, dialog.data)}}
                onCancel={() => {onCancel(dialog.cancelText)}}
                onClose={onClose}
                dialogSize="small"
                visible={dialog.open}
                btnHide={dialog.btnHide}
                className="cartLayer"
            >
                <p style={{whiteSpace: "pre-wrap"}}>{dialog.dialogContent}</p>
            </Dialog>
            <React.Fragment>
                {popup.map((p, idx)=>{
                    return visiblePopup.length !== 0 && visiblePopup.indexOf(p.id) > -1 &&
                        <MainPopup key={idx} popup={p} visible={visiblePopup.indexOf(p.id) > -1} closePop={() => closePop(p.id)} handleExpire={() => handleExpire(p.id, p.close)}  />
                })}
            </React.Fragment>


            {isPc && mainBanner &&
                <Swiper
                    ref={swiperRef}
                    autoplay={{delay: 4000}}
                    navigation
                    pagination={{clickable: true}}
                    setWrapperSize={true}
                    className="mainSwiper"
                    onSlideChange={(e) => {
                        const current = e.slides[e.activeIndex];
                        const id = current.children[0].id;
                        for (let i = 0; i < e.slides.length; i++) {
                            const isYoutube = youtubeVideo.filter(v => v.id === id);
                            if (isYoutube.length >= 1) {
                                e.slides[i].childNodes[0].childNodes[0].src = `https://www.youtube.com/embed/${isYoutube[0].videoId}?autoplay=1`;
                            } else {
                                if (e.slides[i].childNodes[0].tagName === "DIV") {
                                    const anotherVideo = youtubeVideo.filter(v => v.id === e.slides[i].childNodes[0].id);
                                    if (anotherVideo.length >= 1) {
                                        e.slides[i].childNodes[0].childNodes[0].src = `https://www.youtube.com/embed/${anotherVideo[0].videoId}?autoplay=0`;
                                    }
                                }
                            }
                        }
                    }}
                >
                    {/* <div className="swiper-controller">
                        <span className='swiper-stop' onClick={swiperStop}>정지</span>
                        <span className='swiper-start' onClick={swiperStart}>재생</span>
                    </div> */}
                    {mainBanner.map((item, idx) => {
                        return (
                            <SwiperSlide key={idx}>
                                {item.video ?
                                    <div//className="video_wrap"
                                        id={item.id}
                                        style={{position: "relative", top: "0", left: "0", width: "100%", height: "100%"}}>
                                        <iframe
                                            style={{position:"absolute", top:"0", left: "0", width: "100%", height: "100%"}}
                                            id="player"
                                            src={`https://www.youtube.com/embed/${youtube_parser(item.video)}?autoplay=0`} frameBorder='0'
                                            width="100%" height="100%"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture"
                                            allowFullScreen
                                        />
                                    </div>
                                    : <a href={item.link} target="_blank">
                                    <div><img src={item.image} alt=""/></div>
                                </a>}
                            </SwiperSlide>
                        );
                    })
                    }
                </Swiper>
            }

            {isMobile && mainBannerMo &&
                <Swiper
                    ref={swiperRef}
                    autoplay={{delay: 4000}}
                    navigation
                    pagination={{clickable: true}}
                    setWrapperSize={true}
                    className="mainSwiper"
                    onSlideChange={(e) => {
                        const current = e.slides[e.activeIndex];
                        const id = current.children[0].id;
                        for (let i = 0; i < e.slides.length; i++) {
                            const isYoutube = youtubeVideoM.filter(v => v.id === id);
                            if (isYoutube.length >= 1) {
                                document.querySelector('.mainSwiper').classList.add('dark_bg');
                                e.slides[i].childNodes[0].childNodes[0].src = `${isYoutube[0].videoId}?autoplay=1`;
                            } else {
                                document.querySelector('.mainSwiper').classList.remove('dark_bg');
                                if (e.slides[i].childNodes[0].tagName === "DIV") {
                                    const anotherVideo = youtubeVideoM.filter(v => v.id === e.slides[i].childNodes[0].id);
                                    if (anotherVideo.length >= 1) {
                                        e.slides[i].childNodes[0].childNodes[0].src = `${anotherVideo[0].videoId}?autoplay=0`;
                                    }
                                }
                            }
                        }
                    }}
                >
                    {/* <div className="swiper-controller" id="noneForSec">
                        <span className='swiper-stop' onClick={swiperStop}>정지</span>
                        <span className='swiper-start' onClick={swiperStart}>재생</span>
                    </div> */}
                    {mainBannerMo.map((item, idx) => {
                        return (
                            <SwiperSlide key={idx}>
                                {item.video ?
                                    <div id={item.id}
                                        style={{position: "relative", padding: "30px 0 56.25%", height: "0", overflow: "hidden"}}>
                                        <iframe
                                            style={{position: "absolute", top: "0", left: "0", width: "100%", height: "100%"}}
                                            id="player"
                                            src={`${item.video.replaceAll("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/") + "?autoplay=0"}`}
                                            frameBorder='0'
                                            width="100%" height="100%"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture"
                                            allowFullScreen
                                        />
                                    </div> : <a href={item.link} target="_blank">
                                        <div><img src={item.image} alt=""/></div>
                                    </a>}
                            </SwiperSlide>
                        );
                    })
                    }
                </Swiper>
            }
            <div className="section1">
                <h2>우리 가족 맞춤 펫 케어를<br />시작해보세요!</h2>
                <Tabs_wrapper className="center">
                    <Tabs centered={true} size={"large"} >
                        {
                            mainProducts.length >= 0 ?
                                mainProducts.map((el) => {
                                    let ment = "";
                                    let ment2 = "";
                                    switch (el.id) {
                                        case 1:
                                            ment = `새벽부터 울리는 배꼽시계,`;
                                            ment2=`세심한 케어로 올바른 식습관을 만들어줍니다.`;
                                            break;
                                        case 2:
                                            ment = `호기심 많은 냥이,`;
                                            ment2=`정밀한 로드셀로 식사량을 한눈에 확인합니다.`;
                                            break;
                                        case 3:
                                            ment = `혼자있는 시간에도 척척,`;
                                            ment2=`성장기 체중관리를 함께합니다.`;
                                            break;
                                        case 4:
                                            ment = `한번에 조금씩, 혹은 빠르게`;
                                            ment2=`제한급식으로 더욱 건강한 식사관리를 합니다.`;
                                            break;
                                    }
                                    return (
                                        <TabPane tab={el.name} key={el.id}>
                                            <div className="items">
                                                <p className="main_tit">{ment}<br />{ment2}</p>
                                                <Swiper
                                                    slidesPerView={isMobile ? 1 : 3}
                                                    navigation
                                                    className="item_swiper"
                                                    spaceBetween={isMobile ? 0 : 100}
                                                >
                                                    {getMainProducts(el)}
                                                </Swiper>
                                            </div>
                                        </TabPane>
                                    )
                                }) :
                                null
                        }
                    </Tabs>
                </Tabs_wrapper>
            </div>
            {/* <div className="section2">
                <h2>지금 받을 수 있는 혜택</h2>
                <p className="main_tit">똑똑한 반려생활의 시작 바램펫,<br />보호자님을 위한 특별한 혜택을 보여드려요!</p>
                <ul className="center">
                    {isPc && subBanner && subBanner.map((item, idx) =>{
                        return (
                        <li key={idx}>
                            <a href={`${item.link}`}>
                                <div className="banner_img"><img src={item.image} alt={item.title} /></div>
                            </a>
                        </li>
                        )
                    })}
                    {isMobile && subBannerMo && subBannerMo.map((item, idx) =>{
                        return (
                            <li key={idx}>
                                <a href={`${item.link}`}>
                                    <div className="banner_img"><img src={item.image} alt={item.title} /></div>
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </div> */}
            <div className="section3">
                <h2>프리미엄 헬스케어</h2>
                <p className="main_tit">15만 반려동물이 함께하고 있어요.</p>
                <Swiper
                    slidesPerView={1.4}
                    spaceBetween={isMobile ? 10 : 30}
                    centeredSlides={true}
                    navigation
                    pagination={{
                        "type": "fraction"
                    }}
                    className="premium_swiper"
                >
                    {isPc && healthBanner && healthBanner.map((item, idx) =>{
                        return (
                            <SwiperSlide key={idx}>
                                {/* <a href={`${item.link}`}> */}
                                    <div className="premium_img"><img src={item.image} alt={item.title} /></div>
                                {/* </a> */}
                            </SwiperSlide>
                        )
                    })}
                    {isMobile && healthBannerMo && healthBannerMo.map((item, idx) =>{
                        return (
                            <SwiperSlide key={idx}>
                                {/* <a href={`${item.link}`}> */}
                                    <div className="premium_img"><img src={item.image} alt={item.title} /></div>
                                {/* </a> */}
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>

        </MainContainer>
      </>
    );
};
const rightMove = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`

const MainContainer = styled.div`
  @media only screen and (min-width: 961px) {
    .center {
      max-width: 1340px;
    }
  }
  width: 100%;
  margin: auto;
  padding-bottom: 126px;
  .dialog_wrap {
    overflow-y: initial;
    width: auto;
    & p {
      text-align: center;
    }
    @media only screen and (max-width: 960px) {
      width: calc(100% - 30px);
    }
  }
  & .mainSwiper {
    @media only screen and (min-width: 961px) {
      .swiper-slide {
        & div {
          position: relative;
          width: 100%;
          &::before {
            content: "";
            display: block;
            padding-top: 37.5%;
            background: #000;
          }
          &.video_wrap::before {
            content: none;
            padding-top: 0;
          }
        }
        & img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
    }
    #noneForSec{
      display: none;
    }


    & .swiper-button-prev,
    & .swiper-button-next {
      width: 23px;
      height: 40px;
      &::after {
        content: none;
      }
    }
    & .swiper-button-prev {
      left: 4%;
      background: url(${main_slide_prev}) no-repeat center;
    }
    & .swiper-button-next {
      right: 4%;
      background:url(${main_slide_next}) no-repeat center;
    }

    & .swiper-pagination {
      width: 100%;
      bottom: 40px;
      &.paused {
        & .swiper-pagination-bullet-active i {
            animation-play-state: paused;
        }
      }
      & .swiper-pagination-bullet {
        position: relative;
        width: 120px;
        height: 2px;
        background: rgba(256,256,256,0.4);
        margin: 0 10px;
        opacity: 1;
        border-radius: 0;
        cursor: pointer;

        &.swiper-pagination-bullet-active i{
          position: absolute;
          top: 0;
          left: 0;
          height: 2px;
          background: #fff;
          animation-name: ${rightMove};
          animation-duration: 4s;
          animation-timing-function: ease-out;
          animation-fill-mode: forwards;
        }
        & strong {
          position: absolute;
          bottom: 5px;
          left: 0;
          width: 100%;
          text-align: center;
          color: #fff;
          font-weight: 500;
          font-size: 0.813rem;
          line-height: 22.5px;
        }
      }
    }

    & .swiper-controller {
      display: inline-block;
      margin-left: 6px;
      & span {
        display: inline-block;
        content:'';
        width: 9px;
        height: 12px;
        text-indent: -9999px;
        cursor: pointer;
        &.swiper-stop {
          background: url(${ico_pause}) no-repeat center;
        }
        &.swiper-start {
          display: none;
          background: url(${ico_start}) no-repeat center;
        }
      }
    }

    & .more {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 500px;
      left: 220px;
      width: 200px;
      height: 50px;
      border: 1px solid #fff;
      border-radius: 25px;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      & img {
        margin-left: 11px;
      }
    }
  }

  & .section1 {
    width: 100%;
    margin: auto;
    padding-top: 140px;
    //background: url(${main_bg_section1}) no-repeat center 140px;
  }

  h2 {
    font-size: 3.125rem;
    line-height: 65px;
    text-align: center;
    font-weight: 400;
  }

  & .main_tit {
    margin-top: 20px;
    line-height: 30px;
    font-size: 1.25rem;
    color: #515355;
    text-align: center;
  }

  & .section2 {
    padding: 126px 0;
    background: linear-gradient(0deg, #F7F8F8, #F7F8F8), #E8F2F8;
    & ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 72px;

      & li {
        position: relative;
        overflow: hidden;
        width: calc((100% - 40px) / 2);
        //max-height: 380px;
        box-shadow: 2px 2px 20px rgba(15, 105, 167, 0.05);
        border-radius: 20px;
        transition: .3s;
        cursor: pointer;

        &:not(:nth-child(-n+2)) {
          margin-top: 36px;
        }

        @media only screen and (min-width: 961px) {
          &:hover {
            margin: 32px 0 4px;
            box-shadow: 2px -2px 20px rgba(15, 105, 167, 0.15);
            &:nth-child(-n+2) {
              margin-top: -4px;
            }
            
          }
        }
        
        & a {
          display: block;
          & .banner_img {
            position: relative;
            overflow: hidden;
            width: 100%;
            &::after {
              content: "";
              display: block;
              padding-bottom: 58.463%;
            }
            & img{
              position: absolute;
              top: 0;
              right: 0;;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  & .section3 {
    padding: 126px 0 108px;
    & .premium_swiper {
      margin-top: 54px;
      & .swiper-slide {
        & .premium_img {
          overflow: hidden;
          position: relative;
          width: 100%;
          max-width: 1340px;
          max-height: 490px;
          padding-top: 36.278%;
          margin: 0 auto;
          border-radius: 20px;
          & img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &:not(.swiper-slide-active) {
          transform: scaleY(0.9);
          opacity: 0.5;
        }
      }
    }
    & .swiper-button-prev,
    & .swiper-button-next {
      width: 19px;
      height: 38px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      &::after {
        content: none;
      }
    }
    & .swiper-button-prev {
      left: 4%;
      background-image: url(${healthcare_prev});
    }
    & .swiper-button-next {
      right: 4%;
      background-image:url(${healthcare_next});
    }
    & .swiper-pagination {
      display: none;
    }
  }

  @media only screen and (max-width: 960px) {
    min-width: 100%;
    padding-bottom: 60px;
    & .mainSwiper {
      &.dark_bg {
        background: #000;
      }
      & .swiper-wrapper {
        align-items: center;
        .swiper-slide div{
          display: flex;
          width: 100%;
          max-height: 100%;
          & > img {
            width: 100%;
          }
        }
      }
      & .swiper-button-prev,
      & .swiper-button-next {
        display: none;
      }
      & .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 35px;
        & .swiper-pagination-bullet {
          width: 58px;
          height: 2px;
          background: #515355;
          margin: 0 5px;
          opacity: 0.4;
          &.swiper-pagination-bullet-active i{
            background: #000;
          }
          & strong {
            display: none;
          }
        }
      }

      & .swiper-controller {
        & span {
          &.swiper-stop {
            background: url(${ico_pause_c}) no-repeat center;
          }
          &.swiper-start {
            display: none;
            background: url(${ico_start_c}) no-repeat center;
          }
        }
      }

      & .more {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        top: 35%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 150px;
        height: 40px;
        color: #fff;
        font-size: 0.938rem;
        & img {
          height: 9px;
          margin-left: 9px;
        }
      }
    }

    & .section1 {
      padding-top: 100px;
      // background: url(${main_bg_section1}) no-repeat left -228px top 78px;
      background-size: 950px;
    }

    h2 {
      font-size: 1.875rem;
      line-height: 42px;
    }

    & .main_tit {
      margin-top: 20px;
      line-height: 22.4px;
      font-size: 1rem;
    }

    & .section2 {
      padding: 80px 15px;
      & ul {
        margin-top: 40px;
        & li {
          width: 100%;
          height: auto;
          border-radius: 10px;
          box-shadow: 2px 2px 20px rgba(15, 105, 167, 0.1);
          &:not(:first-child) {
            margin-top: 23px;
          }
          & img {
            width: 100%;
          }
        }
      }
    }

    & .section3 {
      padding: 80px 0 60px;
      & .premium_swiper {
        padding-bottom: 66px;
        margin-top: 40px;
        & .swiper-slide {
          & .premium_img {
            padding-top: 125%;
            border-radius: 10px;
          }
        }
      }
      & .swiper-button-prev,
      & .swiper-button-next {
        top: auto;
        bottom: 0;
        width: 6px;
        height: 12px;
        z-index: 999;
        opacity: 1;
        &::after {
          content: none;
        }
      }
      & .swiper-button-prev {
        left: 83px;
        background: url(${m_healthcare_next}) no-repeat center;
        background-size: cover;
        transform: rotate(-180deg);
      }
      & .swiper-button-next {
        right: 83px;
        background:url(${m_healthcare_next}) no-repeat center;
        background-size: cover;
      }
      & .swiper-pagination {
        display: inline-block;
        width: auto;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
        color: #888;
        & span {
          font-size: 0.875rem;
          &.swiper-pagination-current {
            color: #222;
            font-weight: 700;
          }
        }
      }
    }
  }
`

const Tabs_wrapper = styled.div`
  margin-top: 54px;
  text-align: center;
  & .ant-tabs-nav {
    margin-bottom: 54px;
    &::before {
      border: none !important;
    }
  }
  & .ant-tabs-tab {
    width: 216px;
    height: 50px;
    border-radius: 27px;
    text-align: center;
    padding: 0 !important;
    &:not(:first-child) {
      margin-left: 20px;
    }
    &:not(.ant-tabs-tab-active) {
      background: #f2f4f6;
      &:hover {
        background: #1EA0FA;
        & .ant-tabs-tab-btn {
          color: #fff;
        }
      }
      & .ant-tabs-tab-btn {
        color: #515355;
      }
    }
    & .ant-tabs-tab-btn {
      color: #515355;
      margin: 0 auto;
      line-height: 27px;
      font-size: 1.125rem;
    }
    &.ant-tabs-tab-active {
      background: #1EA0FA;
      :hover {
        background: ${lighten(0.1, "#1EA0FA")};
      }
      & .ant-tabs-tab-btn {
        color: #fff;
      }
    }
  }

  & .items {
    position: relative;
    margin-bottom: 122px;
    & .main_tit {
      margin: 0 0 21px 0;
    }

    & > a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 45px;
      margin-bottom: 54px;
      border-radius: 22px;
      border: 1px solid #1EA0FA;
      color: #1EA0FA;
      font-size: 1rem;
      line-height: 27px;
      font-weight: 500;
      & img {
        margin-left: 11px;
        margin-top: 2px;
        width: 5px;
      }
    }

    & .swiper-slide {
      width: 33.3%;
      padding-bottom: 58px;
      &:hover {
        & > .button_wrap {
          display: inline-block;
        }
      }

      & .button_wrap {
        div {
          width: 108px;
          height: 41px;
          border-radius: 5px;
          font-size: 0.875rem;
        }
      }
    }

    & .swiper-button-prev,
    & .swiper-button-next {
      display: none;
      &::after {
        content: none;
      }
    }

    & .thumb {
      display: flex;
      position: relative;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0 auto 15px;
      padding-top: 100%;
      & img {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }

    & .itemTitle {
      font-size: 1.24rem;
      margin-bottom: 7px;
      line-height: 30px;
      color: #222;
    }

    & .price {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .discount {
      color: #1ea0fa;
      font-size: 1.35rem;
      font-weight: 500;
      line-height: 36px;
    }

    & .won {
      font-weight: 400;
      font-style: normal;
      font-size: 1.125rem;
    }

    & .after_discount {
      margin: 0 10px;
      font-size: 1.35rem;
      font-weight: 500;
      line-height: 36px;
    }

    & .before_discount{
      text-decoration: line-through;
      font-size: 0.9rem;
      font-weight: 300;
      color: #888888;
    }

    & .button_wrap {
      display: none;
      position: absolute;
      width: 290px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      & a:first-child {
        margin-right: 10px;
      }
    }
  }
  @media only screen and (max-width: 960px) {
    margin-top: 30px;
    & .ant-tabs-nav {
      margin-bottom: 30px;
    }
    & .ant-tabs-tab {
      width: auto;
      height: 40px;
      padding: 0 30px !important;
      border-radius: 20px;
      &:first-child {
        margin-left: 15px;
      }
      &:not(:first-child) {
        margin-left: 10px;
      }
      & .ant-tabs-tab-btn {
        line-height: 21px;
        font-size: 0.938rem;
      }
    }

    & .items {
      padding: 0;
      margin-bottom: 80px;
      & .main_tit {
        margin: 0 0 18px 0;
      }

      & > a {
        width: 120px;
        height: 36px;
        margin-bottom: 45px;
        font-size: 0.75rem;
        line-height: 17px;
        & img {
          margin-left: 11px;
          margin-top: 0;
          width: 4px;
        }
      }

      & .swiper-slide {
        width: 100%;
        padding-bottom: 0;
        &:hover {
          & > .button_wrap {
            display: none;
          }
        }

        & .button_wrap {
          display: none;
        }
      }

      & .swiper-button-prev,
      & .swiper-button-next {
        display: inline-block;
        top: 40%;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 1px solid #ddd;
        &::after {
          content: none;
        }
      }
      & .swiper-button-prev {
        left: 15px;
        background: url(${m_more_btn_b}) no-repeat center;
        background-size: 6px 12px;
        transform: rotate(-180deg);
      }
      & .swiper-button-next {
        right: 15px;
        background:url(${m_more_btn_b}) no-repeat center;
        background-size: 6px 12px;
      }

      & .thumb {
        width: 65%;
        padding-top: 65%;
        & img {
          object-fit: cover;
        }
      }

      & .itemTitle {
        font-size: 1.125rem;
        margin-bottom: 10px;
        line-height: 25px;
      }

      & .discount {
        font-size: 1.25rem;
        line-height: 28px;
      }

      & .won {
        font-style: normal;
        font-size: 1rem;
      }

      & .after_discount {
        font-size: 1.25rem;
        line-height: 28px;
      }

      & .before_discount{
        font-size: 0.875rem;
      }
    }
  }
`
const PopupStyle = styled.div`
    display: ${({ visible }) => visible ? "block" : "none"};
    position: absolute;
    top: 90px;
    left: 120px;
    background: #fff;
    z-index: 9999;
    & .popup_inner {
        width: 450px;
        & img {
            display: block;
            width: 100%;
        }
        & .button_wrap {
            display: flex;
            & button {
                width: 50%;
                background: #fff;
                font-size: 0.938rem;
                line-height: 49px;
                text-align: center;
                &:first-child {
                    border-right: 1px solid #ddd;
                }
            }
        }
    }

    @media only screen and (max-width: 960px) {
        display: ${({ visible }) => visible ? "flex" : "none"};
        justify-content: center;
        position: fixed;
        background: rgba(0,0,0,0.5);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        & .popup_inner {
            position: absolute;
            top: 60px;
            width: 90%;
            & img {
                width: 100%;
            }
            & .button_wrap {
                display: flex;
                & button {
                    width: 50%;
                    background: #fff;
                    font-size: 0.938rem;
                    line-height: 49px;
                    text-align: center;
                    &:first-child {
                        border-right: 1px solid #ddd;
                    }
                }
            }
        }
    }
`

export default MainPage;