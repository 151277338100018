import React from "react";
import styled from "styled-components";
import 'rc-rate/assets/index.css';
import Dialog from './Dialog';

function RegularServicePopup({ visible, onCancel }) {
	return (
    <RegularServiceDialog visible={visible}>
      <Dialog
        title="정기/반복결제 이용약관 동의"
        sub_title="[필수]"
        cancelText="닫기"
        onCancel={onCancel}
        fullHeight
        fixHeight
        visible={visible}
      >
        <div className="cont_wrap">
          <div className="cont_box">
            <p>
              1. 귀하가 신청하신 정기/반복결제는 바램시스템에서 대행합니다.
            </p>
            <p>
              2. 귀하의 결제내역에는 본 정기/반복결제 이용가맹점으로 바램시스템이 표기됩니다. 귀하의 정기/반복결제 신청, 해지, 조정 등 관련된 모든 업무는 바램시스템을 통해 진행하실 수 있습니다.
            </p>
            <p>
              3. 정기/반복결제를 통해 귀하가 결제해야 할 요금은 결제일 3일 전 안내되며, 최초 등록하신 정보를 통해 가맹점의 청구대로 승인됩니다. 승인금액에 이의가 있는 경우에는 본인과 가맹점의 협의 하에 조정합니다.
            </p>
            <p>
              4. 정기/반복결제시 귀하가 보유한 쿠폰 등의 사용 여부는 최초 정기/반복결제 등록시점에서 선택할 수 있습니다.
            </p>
            <p>
              5. 바램시스템은 귀하의 본 정기/반복결제 신청에 따라 발생하는 결제내역을 설정하신 알림 수단으로 통보해 드리며, 마이페이지-나의 정기배송에서도 결제내역을 확인하실 수 있습니다.
            </p>
          </div>
        </div>
      </Dialog>
    </RegularServiceDialog>
	);
}

const RegularServiceDialog = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};

  & .dialog_wrap {
    & h3 {
      margin-bottom: 30px;
    }
    & .dialog_inner {
      max-height: calc(90vh - 113px);
      padding: 0 30px 30px 30px;
    }
    & .cont_wrap {
      padding: 0;
    }
    & .tit_wrap {
      margin-top: 40px;
      & p:last-child {
        font-size: 0.875rem;
        &::before {
          content: none;
        } 
      }
    }
    & .cont_wrap2 {
      padding-top: 20px;
    }
  }
  
  @media only screen and (max-width: 960px) {
    & .dialog_wrap {
      & h3 {
        margin-bottom: 0;
        text-align: left;
      }
      & .dialog_inner {
        padding: 25px 15px;
        max-height: calc(100vh - 60px);
      }
      & .tit_wrap {
        margin-top: 50px;
        & p:first-child {
          font-weight: 500;
        }    
        & p:last-child {
          display: flex;
          position: relative;
          align-items: center;
          padding-left: 15px;
          margin-left: auto;
          color: #515355;
          font-size: 0.813rem;
          & svg {
            margin-left: 5px;
          }
          &::before {
            content: none;
          }
        }
      }
    }
  }

  & .cont_wrap {
    padding: 60px 0;
    margin: 0 auto;
    &.displaynone {
      display: none;
    }

    & p {
      font-size: 0.875rem;
      color: #515355;
      line-height: 21px;
      & b {
        font-weight: 500;
      }
      &.pd10 {
        padding-left: 10px;
      }
      &.pd15 {
        padding-left: 15px;
      }
    }

    & p.popup_tit {
      margin: 30px 0 10px;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;
      color: #222;
    }

    & p.small_desc {
      margin-top: 10px;
      font-size: 0.813rem;
      & b {
        color: #515355;
      }
    }

    & table {
      width: 100%;
      margin-top: 10px;
      border: 1px solid #ddd;
      
      & tbody tr {
        border-top: 1px solid #ddd;
      }
      & tr{
        & th, & td {
          padding: 8px;
          font-size: 0.813rem;
          color: #515355;
          line-height: 19.5px;
          vertical-align: middle;
          &:not(:last-child) {
            border-right: 1px solid #ddd;
          }
        }
        & th {
          font-weight: 500;
          background: #f6f6f6;
        }
      }

      &.center_align {
        & tr {
          & th, & td {
            text-align: center;
          }
        }
      }
    }
  }

  & .tit_wrap {
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 24px;
    cursor: pointer;
    & p:first-child {
      font-weight: 500;
    }    
    & p:last-child {
      display: flex;
      position: relative;
      align-items: center;
      padding-left: 15px;
      margin-left: auto;
      color: #515355;
      & svg {
        margin-left: 10px;
      }
      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 10%;
        width: 1px;
        height: 80%;
        background: #999;
      }
    }
  }

  @media only screen and (max-width: 960px) {
    width: 100%;
    & .cont_wrap {
      padding: 0;
      & table {
        &.privacy_agree {
          margin-bottom: 40px;
          font-size: 0.813rem;

          & th, & td {
            line-height: 19.5px;
          }
        }
        

        &.privacy_agree2 {
          border: 1px solid #ddd;
          & tbody tr {
            border-top: 1px solid #ddd;
          }
          & tr{
            & th, & td {
              padding: 8px 2px;
              font-size: 0.75rem;
              line-height: 18px;
            }
          }
        }
      }
    }
    & .cont_wrap2 {
      padding-top: 30px;
    }
  }
`

export default RegularServicePopup;