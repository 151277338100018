import React,{useState} from "react";
import styled from "styled-components";
import Button from "./components/Button";
import FindPwEmailPage from "./FindPwEmailPage"
import FindPwPhonePage from "./FindPwPhonePage"

const FindPwPage = () => {
    const [clickBtn, setClickBtn] = useState({
        first: true,
        second: false,
        third: false
    })
  
	return (
    <FindPwContainer>
      <div className="title_wrap">
        <h2>비밀번호 찾기</h2>
      </div>
        {clickBtn.first ? <div className="find_wrap">
            <p><span>비밀번호 재설정 링크</span>를<br />어디로 보내드릴까요?</p>
            <div className="find_form">
                <div className="button_wrap">
                    <MainButton fullWidth onClick={() => {setClickBtn({fisrt: false, second: true, third: false})}}>휴대폰으로 받기</MainButton>
                    <MainButton fullWidth onClick={() => {setClickBtn({fisrt: false, second: false, third: true})}}>이메일로 받기</MainButton>
                </div>
            </div>
        </div> : null}
        {clickBtn.second ? <FindPwPhonePage /> : null}
        {clickBtn.third ? <FindPwEmailPage /> : null}
    </FindPwContainer>
	);
}

const MainButton = styled(Button)`
  &:not(:first-child) {
    margin-top: 15px;
  }
	@media only screen and (max-width: 960px) {
		height: 50px;
    font-weight: 700;
    font-size: 1rem;
	}
`

const FindPwContainer = styled.div`
  max-width: 1480px;
  margin: 0 auto;

  & .find_wrap {
    width: 500px;
    margin: 0 auto;
    & > p {
      margin: 100px 0 60px;
      font-size: 26px;
      font-weight: 300;
      line-height: 39px;
      & span {
        font-weight: 400;
      }
    }
    & .find_form {
      p:not(:first-child) {
        margin-top: 10px;
      }
    }

    & .chk_phone {
      p {
        display: flex;
      }
    }

    & .button_wrap {
      margin: 80px 0;
    }
  }

  @media only screen and (max-width: 960px) {
    margin: 0;
    & .find_wrap {
      width: 100%;
      margin: 0;
      padding: 0 15px;
      & > p {
        margin: 60px 0 30px;
        font-size: 22px;
        line-height: 33px;
        & span {
          font-weight: 400;
        }
      }
      & .find_form {
        p:not(:first-child) {
          margin-top: 5px;
        }
        input {
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
      }

      & .button_wrap {
        margin: 40px 0 46px;
      }
    }
  }
`

export default FindPwPage;