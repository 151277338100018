import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import axios from "axios";
import { useTabs, TabPanel } from "react-headless-tabs"
import { TabSelector } from "pages/components/TabSelector";
import { useMediaQuery } from 'react-responsive';
import Pagination from 'pages/components/Pagination';
import { GrClose } from 'react-icons/gr'
import { BsArrowRightShort } from 'react-icons/bs';
import { BsArrowLeftShort } from 'react-icons/bs';
import {useDispatch, useSelector} from "react-redux";
import {setBoardLists} from "actions/boardActions";
import Loader from "../../components/Loader";
import {useHistory} from "react-router-dom";
import QueryString from "qs";

const VideoGuidance = (props) => {
    const [viewMovie, setViewMovie] = useState(false);
    const isPc = useMediaQuery({ query: '(min-width: 960px)' })
    const dispatch = useDispatch();
    const boardLists = useSelector((state) => state.boardLists)
    const {lists, loading} = boardLists;
    const [selectedTab, setSelectedTab] = useTabs([
        "밀리",
        "피트니스 (Pro)",
        "피트니스",
        "라일리",
        "웰리"
    ]);
    const [noticeData, setNoticeData] = useState([]);
    const [selectSub, setSelectSub] = useState("listMain1");
    const [viewDetail, setViewDetail] = useState({
        data: "",
        urlId: "",
        currentIdx: 0
    });
    const [totalPage, setTotalPage] = useState(0);
    const [listsMap, setListsMap] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [clickContent, setClickContent] = useState({id:0,state:false});
    let startContent = 0;
    let endContent = 0;
    const history =   useHistory();
    const queryData = QueryString.parse(window.location.search, {ignoreQueryPrefix: true});

    let is_rendering = false
    useEffect(() => {
        is_rendering = true
        setSelectedTab("밀리");
        setSelectSub("listMain1");
        setTimeout(() => {
            is_rendering = false
        }, 500)
    },[])


    useEffect(() => {
        setClickContent({id: viewDetail.currentIdx, state: !clickContent.state});
    }, [viewDetail])
    useEffect(() => {
        if (!noticeData[`${selectSub}`]) {
            return
        }
        const selectedNotice = noticeData[`${selectSub}`].filter((e) => e.category === `${selectedTab}`);
        setTotalPage(selectedNotice.length);
    }, [noticeData, selectSub, selectedTab])
    useEffect(() => {
        dispatch(setBoardLists("Guide"));
    }, [dispatch]);

    useEffect(()=>{
        const makeDatas = (lists) => {
            setNoticeData({
                listMain1: lists?.data?.filter((e) => e.category === "밀리"),
                listMain2: lists?.data?.filter((e) => e.category === "피트니스 (Pro)"),
                listMain3: lists?.data?.filter((e) => e.category === "피트니스"),
                listMain4: lists?.data?.filter((e) => e.category === "라일리"),
                listMain5: lists?.data?.filter((e) => e.category === "웰리"),
            });
        };
        makeDatas(lists);
    },[lists])
    useEffect(() => {
        const params = new URLSearchParams();
        params.set("id",props.id);
        params.set("category", selectedTab);
        if (currentPage >= 1) {
            params.set("page", currentPage);
        }
        history.push({search: params.toString() });
    }, [selectSub, selectedTab, currentPage])
    useEffect(() => {
        if (!noticeData[`${selectSub}`]) {
            return
        }
        const selectedNotice = noticeData[`${selectSub}`].filter((e) => e.category === `${selectedTab}`);
        setTotalPage(selectedNotice.length);
        endContent = 10;
        startContent = (currentPage * 10) - 10;

        let pagedata = noticeData[`${selectSub}`]
            .filter((e) => e.category === `${selectedTab}`)
            .map((el, idx) => {
                return (
                    <li key={el.id} onClick={() => {setViewMovie(!viewMovie); onClickVideo(el.id, idx + 1)}}>
                        <div className="thumb"><img src={el.thumbnail} alt={el.title} /></div>
                        <p>{el.title}</p>
                    </li>)
            })
        const splice = pagedata.splice(startContent, endContent);
        setListsMap(splice);
        if (splice.length === 0) {
            setCurrentPage(1);
        }
    }, [noticeData, selectSub, selectedTab, currentPage, clickContent])
    useEffect( () => {
        if (Object.keys(queryData).length < 1 || !queryData.category) { return }
        if(is_rendering){return}
        if (!boardLists.loading) {
            for (let i = 0; i < Object.keys(queryData).length; i++) {
                switch (Object.keys(queryData)[i]) {
                    case "category":
                        let category = "";
                        switch (queryData.category) {
                            case "밀리":
                                category = "listMain1";
                                break;
                            case "피트니스 (Pro)":
                                category = "listMain2";
                                break;
                            case "피트니스":
                                category = "listMain3";
                                break;
                            case "라일리":
                                category = "listMain4";
                                break;
                            case "웰리":
                                category = "listMain5";
                                break;
                        }
                        setSelectSub(category)
                        setSelectedTab(queryData.category);
                        break;
                    case "page":
                        setCurrentPage(queryData.page);
                        break;

                }
            }
        }
    }, [loading])

    const onClickVideo = async (id, idx) => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/board/detail/Guide/${id}`)
        let video_id = res.data.data.url

        function youtube_parser(url){
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = url.match(regExp);
            return (match&&match[7].length==11)? match[7] : false;
        }

        setViewDetail({...viewDetail, urlId: youtube_parser(video_id), data: res.data, currentIdx: idx})
    }

    return (
        <StyledVideo>
            <nav className="tabNav">
                <TabSelector className="tab" isActive={selectedTab === '밀리'} onClick={() => {setSelectedTab('밀리'); setSelectSub("listMain1");}}>
                    밀리
                </TabSelector>
                <TabSelector className="tab" isActive={selectedTab === '웰리'} onClick={() => {setSelectedTab('웰리'); setSelectSub("listMain5");}}>
                    웰리
                </TabSelector>
                <TabSelector className="tab" isActive={selectedTab === '피트니스 (Pro)'} onClick={() => {setSelectedTab('피트니스 (Pro)'); setSelectSub("listMain2");}}>
                    피트니스 PRO
                </TabSelector>
                <TabSelector className="tab" isActive={selectedTab === '라일리'} onClick={() => {setSelectedTab('라일리'); setSelectSub("listMain4");}}>
                    라일리
                </TabSelector>
                <TabSelector className="tab" isActive={selectedTab === '피트니스'} onClick={() => {setSelectedTab('피트니스'); setSelectSub("listMain3");}}>
                    피트니스
                </TabSelector>
            </nav>
            <div className="TabContents">
                <TabPanel>
                    <div className="video_lists">
                        <ul>
                            {loading ? <Loader /> : (listsMap && listsMap.length !== 0 ? (
                                listsMap
                            ) : (
                                <p className="noList" style={{width:"100%"}}>등록된 게시물이 없습니다.</p>
                            ))}
                        </ul>
                    </div>
                    <ul className="reviewChat_cover_ul">
                        <Pagination
                            postsPerPage={10}
                            totalPosts={
                                totalPage
                            }
                            paginate={setCurrentPage}
                            currentpage={currentPage}
                        ></Pagination>
                    </ul>
                </TabPanel>
            </div>
                {viewMovie ? <div className="movie_popup">
                    <div className="pagination">{viewDetail?.currentIdx} / {noticeData[`${selectSub}`].length}</div>
                    <div className="navigation">
                      {viewDetail?.data?.next && <span className="left_btn" onClick={() => {onClickVideo(viewDetail?.data?.next?.id, viewDetail.currentIdx - 1 )}}><BsArrowLeftShort color="#888" size="40" /></span>}
                      {viewDetail?.data?.prev && <span className="right_btn" onClick={() => {onClickVideo(viewDetail?.data?.prev?.id, viewDetail.currentIdx + 1 )}}><BsArrowRightShort color="#888" size="40" /></span>}
                    </div>
                    <iframe src={`https://www.youtube.com/embed/${viewDetail.urlId}`} frameBorder='0'
                            width={isPc ? "1155px" : "100%"} height={isPc ? "650px" : "330px"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture"
                            allowFullScreen title={viewDetail?.data?.data?.title}
                            controls="1"
                    />
                    <p>{viewDetail?.data?.data?.title}</p>
                    <GrClose size="20" color="#eee" style={{position:"absolute", top:"15px", right:"15px", cursor:"pointer"}} onClick={() => setViewMovie(false)} />
                </div> : null}
        </StyledVideo>
    );
};

const StyledVideo = styled.div`
  width: 100%;
  position: relative;
  & .tabNav{
    margin-bottom: 80px;
    border-bottom: 1px solid #ddd;
    button {
      width: calc(100% / 4);
      height: 78px;
      position: relative;
      font-size: 20px;
      font-weight: 400;
    }
    button:after{
      content: '';
      background: #ddd;
      width: 1px;
      height: 21px;
      left: 0px;
      display: inline-block;
      position: absolute;
    }
    button:first-child:after{
      display: none;
    }
    & .disabled{
      color: #888888;
    }
    & .active{
      font-weight: 500;
      color: #0065AB;
      background: #E8F2F8;
    }

    @media only screen and (max-width: 960px) {
      margin-bottom: 40px;
      border-bottom: none;
      border-top:1px solid #ddd;
      button {
        width: calc(100% / 2);
        height: 64px;
        border-bottom: 1px solid #ddd;
        font-size: 1rem;
        &:nth-child(2n-1) {
          border-right: 1px solid #ddd;
        }
      }
      button:after{
        content: none;
      }
      button:first-child:after{
        display: none;
      }
    }
  }

  & .TabContents{
    & .video_lists{
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
      li{
        width: calc((100% - 54px) / 3);
        cursor: pointer;
        margin-bottom: 80px;
        margin-right: 17px;
        p{
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          color: #222;
          padding-top: 20px;
        }
        .thumb {
          position: relative;
          overflow: hidden;
          padding-top: 56.2%;
        }
        img{
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      li:nth-child(3n){
        padding-right: 0px;
      }
      @media only screen and (max-width: 960px) {
        padding: 0 15px;
        ul {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
        }
        li{
          width: 100%;
          margin-bottom: 40px;
          margin-right: 0;
          &:last-child {
            margin-bottom: 20px;
          }
          p{
            font-size: 1.125rem;
          }
        }
      }
    }
  }
  
  & .movie_popup{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,0.9);
    display:flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    & .pagination {
      position: absolute;
      top: 15px;
      left: 15px;
      color: #888;
    }

    & .navigation {
      position: absolute;
      width: 100%;
      top: 46%;

      & .left_btn {
        position: absolute;
        top: 0;
        left: 15px;
        cursor: pointer;
      }

      & .right_btn {
        position: absolute;
        top: 0;
        right: 15px;
        cursor: pointer;
      }
    }

    & video {
      width: 1100px;
    }
    & path {
      stroke: #888;
    }
    & p {
      position: absolute;
      bottom: 30px;
      left: 15px;
      color: #eee;
    }

    @media only screen and (max-width: 960px) {
      & video {
        width: 100%;
      }

      & .navigation {
        position: absolute;
        top: 47%;

        & span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          background: rgba(50,50,50,0.2);
          border-radius: 5px;

          & svg {
            color: #908F90 !important;
          }
        }

        & .left_btn {
          position: absolute;
          top: 0;
          left: 15px;
          cursor: pointer;
        }

        & .right_btn {
          position: absolute;
          top: 0;
          right: 15px;
          cursor: pointer;
        }
      }

      
  }
   .ytp-cued-thumbnail-overlay {
        display: flex;
        align-items: center;
        & .ytp-cued-thumbnail-overlay-image {
          height: auto;
          padding-bottom: 50%;
        }
      }
    }
`

export default VideoGuidance;