import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io'
import { BsDot } from "react-icons/bs";
import { useMediaQuery } from 'react-responsive';
import axios from "axios";
import {useSelector, } from "react-redux";

const MyCoupon = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const [couponList, setCouponList] = useState([])
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const [coupon, setCoupon] = useState("")
  const [checkUser, setCheckUser] = useState("")

  useEffect(()=>{
    try {
      const fetchProfile = async () => {
          const config = {
            headers: {
              'Content-Type': 'application/json',
              "cid" : userInfo.data.cid,
               "encoded" : userInfo.data.encoded,
            },
          }
          try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/user/info`, config);
            setCheckUser(res.data)
          } catch (error) {
            console.log(error)
          }
        }
      fetchProfile()
    } catch (e) {
      console.log(e)
    }

  },[setCheckUser])

    const [limit, setLimit] = useState(20)

    const moreView = () => {  //더보기 버튼
        setLimit(limit + 10);
    }

    const handleSubmitCoupon = async () => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          "cid" : userInfo.data.cid,
          "encoded" : userInfo.data.encoded,
        },
      }
        try{
          const res = await axios.post(`${process.env.REACT_APP_API_URL}api/user/issue/coupon`, {
            "coupon_code" : coupon
          }, config)
          document.location.href='/mypage/coupon'
        }catch(e){
          console.log(e)
        }
    }

  const onInputChange = (e) => {
    const value = e.target.value;
    switch (e.target.name) {
      case "coupon":
        setCoupon(value);
        break;
    }
  }
  const dateFormat = (date) => {
    return date.slice(0, 10).replaceAll("-", ".")
  }

    return (
    <MyCouponContainer>
      <div className="mypage_title">
        <h2>나의 쿠폰</h2>
      </div>
      <div className="coupon_reg_wrap">
      { isMobile &&
        <p>쿠폰 번호</p>
      }
      </div>
      { isMobile && <p className="noArea"></p>}
      <div className="coupon_list">
        <div className="list_header">
          <span className="coupon_name">쿠폰명</span>
          <span className="date">사용기간</span>
        </div>
        <ul>
            {checkUser && checkUser?.coupon?.length !== 0 ? checkUser?.coupon?.slice(0, limit).filter((coupon) => {
              return coupon
            }).map((list) => {
              if(!list.Coupon){
                return
              }
                return (
                    <li>
                        <div className="coupon_name">
                            <p>{list?.Coupon?.title}</p>
                        </div>
                        <p className="date">{dateFormat(list?.Coupon?.start_date)} ~ {dateFormat(list?.Coupon?.end_date)}</p>
                    </li>
                )
            }) : <li>
                <div className="no_list">
                    <p>등록된 쿠폰이 없습니다.</p>
                </div>
            </li>}
        </ul>
      </div>
      <div className="list_more_btn">
        {couponList.length > limit && couponList.length >= limit ? <button onClick={moreView}>더보기<IoIosArrowDown /></button>: null}
      </div>
      <div className="mypage_guide">
        <p><span><BsDot /></span><span>사용된 쿠폰은 재발급되지 않습니다.</span></p>
        <p><span><BsDot /></span><span>쿠폰 적용 상품 및 쿠폰 사용 기한을 꼭 확인해주세요.</span></p>
      </div>
    </MyCouponContainer>
  );
};

const MyCouponContainer = styled.div`
  & .coupon_reg_wrap {
    margin-bottom: 30px; 
    input {
      width: 370px;
      margin-right: 10px;
    }
    & button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 46px;
      border: 1px solid #ccc;
      background: #f6f6f6;
      font-size: 1rem;
      color: #222;
      font-weight: 500;
    }

    @media only screen and (max-width: 960px) {
      padding: 0 15px;
      & p {
        margin-bottom: 10px;
        font-size: 0.938rem;
        color: #222;
        line-height: 22px;
      }
      input {
        width: calc(100% - 105px);
        margin-right: 5px;
        padding: 11px 14px;
        font-size: 0.938rem;
        &::placeholder {
          font-size: 0.938rem;
        }
      }
      & button {
        width: 100px;
        height: 46px;
        font-size: 0.875rem;
      }
    }
  }

  & .coupon_list {
    border-top: 1px solid #222;
    & .list_header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      & span {
        color: #222;
        font-size: 0.938rem;
        line-height: 22px;
      }
    }

    & ul {
      & li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #E1E2E3;
      }
    }
    & .coupon_name {
      width: calc(100% - 210px);
      padding: 17px 30px;
      & p {
        line-height: 24px;
        color: #222;
        &.use_limit {
          margin-top: 2px;
          color: #888;
          font-size: 0.875rem;
          line-height: 21px;
        }
      }
    }
    & .no_list {
      width: 100%;
      & p {
        line-height: 130px;
        color: #888;
        text-align: center;
      }
    }
    & .date {
      width: 210px;
      color: #222;
      text-align: center;
    }

    @media only screen and (max-width: 960px) {
      border-top: none;
      & .list_header {
        display: none;
      }

      & ul {
        padding: 0 15px;
        & li {
          padding: 20px 0;
          flex-direction: column;
          border-bottom: 1px solid #E1E2E3;
        }
      }
      & .coupon_name {
        width: 100%;
        padding: 0;
        & p {
          color: #222;
          font-weight: 500;
          &.use_limit {
            margin-top: 4px;
            font-weight: 400;
            font-size: 0.813rem;
          }
        }
      }
      & .date {
        display: block;
        width: 100%;
        margin-top: 4px;
        color: #515355;
        text-align: left;
        font-size: 0.813rem;
        line-height: 19px;
      }
    }
  }
`

export default MyCoupon;