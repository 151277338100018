import React from 'react';
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import ico_error from 'images/icon/ico_error.png';
import Button from './components/Button.js';

const ErrorPage = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const goToMain = () => {
        document.location.href = "/"
    }

    return (
            <StyleErrorPage>
                <div className="title_404">
                    <p><img src={ico_error} alt='에러' /></p>
                    <p>예기치 못한 문제로 에러가 {isMobile && <br />}발생했습니다.</p>
                </div>
                <p className="text_wrap">요청하신 페이지를 처리 중에 {isMobile && <br />}오류가 발생했습니다.<br />홈페이지 이용에 불편을 드려 죄송합니다.</p>
                <Longbutton size="large" onClick={goToMain}>홈으로 가기</Longbutton>
            </StyleErrorPage>
        );
};


const Longbutton = styled(Button)`
  width: 500px;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
`;

const StyleErrorPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 160px auto;
  
  & .title_404 {
    margin-bottom: 20px;
    & p:first-child {
      margin-bottom: 60px;
      text-align: center;
    }
    & p:last-child {
      font-size: 2.5rem;
      line-height: 60px;
    }
  }
  
  & .text_wrap {
    margin-bottom: 80px;
    font-size: 1.25rem;
    line-height: 30px;
    text-align: center;
  }

  @media only screen and (max-width: 960px) {
    padding: 0 15px;
    margin: 60px auto;
  
    & .title_404 {
      margin-bottom: 15px;
      & p:first-child {
        margin-bottom: 30px;
      }
      & p:last-child {
        font-size: 1.75rem;
        line-height: 36px;
        text-align: center;
      }
    }
    
    & .text_wrap {
      margin-bottom: 60px;
      font-size: 1.063rem;
      line-height: 25px;
      color: #515355;
    }
  }
`

export default ErrorPage;