import React, {useState, useEffect} from 'react';
import ico_kakao from "images/ico_kakao.png";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import axios from "axios"
import Dialog from "./Dialog";

const KakaoLogin = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [responseKakao, setResponseKakao] = useState({})
    const userLogin = useSelector((state) => state.userLogin)
    let {loading, userInfo} = userLogin;


    useEffect(()=>{
        const href = window.location.href;
        let params = new URL(document.location).searchParams;
        let code = params.get("code");
    },[])

    function getCookie(name) {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    const handleClickKakaoLogin = () => {
        const {Kakao} = window
        try {
            return new Promise((resolve, reject) => {
                if (!Kakao) {
                    reject('인스턴스 없음');
                }
                Kakao.Auth.login({
                    success: res => {
                        axios.post(`${process.env.REACT_APP_API_URL}api/auth/kakao/checkUser`, { //카카오메일 클릭 후 접속
                            access_token : res.access_token
                        }).then(response => {
                            setResponseKakao(response);
                            if(response.data.phone_number){
                                axios.post(`${process.env.REACT_APP_API_URL}api/auth/kakao/login`, {
                                    "kakao_id" : response.data.id,
                                    "phone_number" : response.data.phone_number
                                }).then((res)=>{
                                    if(res.data.email && res.data.login_time){
                                        setAccounts([...accounts, res.data])
                                        setAccountCheck({
                                            ...accountCheck,
                                            open: true,
                                            email: res.data.email
                                        })
                                    }else if(res.data.data.access_token){  //연동된 계정, 카카오가입된 계정
                                        localStorage.setItem("userInfo", JSON.stringify({loading: false, userInfo:res.data, errorMsg: {}}));
                                        document.location.href= "/";
                                    }
                                })
                            }else{
                                axios.post(`${process.env.REACT_APP_API_URL}api/auth/kakao/login`, {
                                    "kakao_id" : response.data.id
                                }).then((answer) => {
                                    localStorage.setItem("userInfo", JSON.stringify({loading: false, userInfo: answer.data, errorMsg: {}}));
                                    document.location.href= "/";
                                }).catch((err)=>{
                                    if(err.response.data.msg === "NoKakaoPhoneNumberError"){
                                        history.push({
                                            pathname: "/Join",
                                            state: {kakaoInfo: response.data}
                                        });
                                    }
                                })
                            }
                        }).catch((error) => {
                            console.log(error)
                            if(error.response.data.msg === "DeletedAccountDuplicationError"){
                                alert("기존 가입 이력이 있는 아이디입니다. 탈퇴 30일 후 재가입 또는 다른 아이디로 회원가입을 진행해주세요.")
                            }else if(error.response.data.msg === "tryLimitError"){
                                alert("비밀번호를 5회 이상 잘못 입력하셨습니다. 해당 계정은 5분간 잠금처리됩니다.")
                            }
                        })
                    },
                    fail: err => {
                        console.log("카카오  첫 클릭 부터 에러: ",err);
                        alert(err.error)
                    },
                });
            });
        } catch (err) {
            console.error(err);
        }
    };

    const loginContinue = () => {
        axios.post(`${process.env.REACT_APP_API_URL}api/auth/kakao/login`, { //카카오 가입 요청
            "email" : responseKakao.data.id,
            "kakao_id" : responseKakao.data.id,
            "phone_number" : responseKakao.data.phone_number,
            "local_sharing": true
        }).then(response => { //카카오 로그인 이후 토큰 저장
            localStorage.setItem("userInfo", JSON.stringify({loading: false, userInfo:response.data, errorMsg: {}}));
            document.location.href= "/joinComplete"
        })
    }

    const [accountCheck, setAccountCheck] = useState({open: false, email: "", time: "", type: ""});
    const [accounts, setAccounts] = useState([]);
    const hide_string = (email) => {
        let domain = email.split("@")[1]
        let id = email.split("@")[0]
        let answer = ""
        for(let i=0; i < id.length; i++){
            if(i < id.length/2){
                answer = answer + "*";
            }else{
                answer = answer + id[i]
            }
        }
        return answer + "@" + domain
    }

    return (
        <KakaoWrapper>
            <a id="custom-login-btn" onClick={handleClickKakaoLogin} >
                <img src={ico_kakao} alt="" /> 카카오 회원가입/로그인
            </a>


            <div className="account_check_dialog">
                <Dialog
                    onCancel= {() => {setAccountCheck({ ...accountCheck, open: false, email: "", time: ""}); setAccounts([]);}}
                    onConfirm={() => {setAccountCheck({ ...accountCheck, open: false, email: "", time: ""}); setAccounts([]); loginContinue();}}
                    confirmText="진행하기"
                    cancelText="닫기"
                    dialogSize="small"
                    visible={accountCheck.open}
                >
                    <p>이미 가입된 계정입니다.</p>
                    {accounts.map((el, idx) => {
                        return <div key={idx}>
                            <p>이메일 아이디</p>
                            <p>{hide_string(el.email)}</p>
                        </div>
                    })}
                </Dialog>
            </div>
        </KakaoWrapper>
    );
};


const KakaoWrapper = styled.div`
  & .account_check_dialog {
    & .dialog_wrap {
      padding: 50px 30px 30px 30px;
      text-align: center;
      & .close {
        display: none;
      }
      & .dialog_inner {
        & > p {
          margin-bottom: 30px;
          font-size: 20px;
          line-height: 30px;
          font-weight: 500;
          & span {
            display: block;
            margin-top: 2px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
          }
        }
        & div {
          margin-top: 10px;
          padding: 20px 0;
          background: #f7f7f7;
          & p:first-child {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            color: #555;
          }
          & p:nth-child(2) {
            margin-bottom: 12px;
            font-size: 18px;
            line-height: 27px;
            color: #1EA0FA;
          }
          & p:nth-child(3) {
            font-size: 13px;
            line-height: 19.5px;
            color: #888;
          }
        }
      }
      & .ButtonWrap {
        width: 100%;
        margin-top: 40px;
        & div {
          width: calc((100% - 5px) / 2);
          &:not(:first-child) {
            margin-left: 5px;
          }
        }
      }
    }
    @media only screen and (max-width: 960px) {
      & .dialog_wrap {
        padding: 40px 27.5px 30px 27.5px;
        text-align: center;
        & .close {
          display: none;
        }
        & .dialog_inner {
          & > p {
            font-size: 18px;
            line-height: 27px;
          }
        }
        & .ButtonWrap {
          & div {
            width: calc((100% - 10px) / 2);
            font-weight: 700;
            &:not(:first-child) {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
`



export default KakaoLogin;
