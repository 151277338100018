import React, {useState, useEffect, useCallback} from 'react';
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import 'rc-rate/assets/index.css';
import Button from './Button';
import Dialog from './Dialog';
import {setAddOption, setQuantity, removeOptions, checkOptions, clearOptions, setArrOptions} from 'actions/optionActions';
import { listProductDetails } from 'actions/productActions';
import { useSelector, useDispatch} from "react-redux";
import Select from 'react-select';
import { GrClose } from "react-icons/gr"
import {stringPrice} from "utils/StringPrice"
import axios from "axios";

function OptionPopup({ visible, onCancel, selectedCart, selectedCartId, onChange, change, setChange, setIsOptionChage}) {
    const isPc = useMediaQuery({ query: '(min-width: 961px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const items = useSelector((state) => state.productDetails.productInfo || []);
    let optionState = useSelector(((state) => state.optionReducer.product_option));
    const dispatch = useDispatch();
    const [options, setOptions] = useState([]);
    const userInfo = useSelector((state) => state.userLogin.userInfo);  //유저 토큰 조회
    const { info } = items
    const [optionStyle, setOptionStyle] = useState({
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            return { ...provided, opacity };
        }
    });
    useEffect(() => {
        for (let i = 0; i < selectedCart.Product.OptionGroups.length; i++) {
            for (let j = 0; j < selectedCart.Product.OptionGroups[i].Options.length; j++) {
                selectedCart.Product.OptionGroups[i].Options[j].label = selectedCart.Product.OptionGroups[i].Options[j].name;
            }
        }
        setOptions(selectedCart.Product.OptionGroups);
    }, [selectedCart])
    useEffect(() => {
        if (userInfo.data) {
            for (let i = 0; i < selectedCart.SelectedOptions.length; i++) {
                const id = selectedCart.SelectedOptions[i].OptionId;
                const quantity = selectedCart.SelectedOptions[i].quantity;
                const price = selectedCart.SelectedOptions[i].price;
                const getOption = selectedCart.Product.OptionGroups.reduce((acc, cur) => {
                const idx = cur.Options.findIndex(vj => vj.id === id);
                    if (idx >= 0) {
                        return cur.Options[idx];
                    }
                    return acc
                }, 0);
                let name = selectedCart.SelectedOptions[i].name;
                let require = false;
                let realname = ""

                if (getOption) {
                    require = getOption.require;
                    realname = getOption.name;
                }
                let operator = ""

                if (price >= 0) {
                    operator = "+"
                }
                if (require) {
                    name = `${realname} (${operator}${price}원)`
                } else {
                    name = `${realname} (${operator}${price}원)`
                }

                dispatch(setAddOption(id, name, price, require));
                dispatch(setQuantity(id, quantity, name, price, require));
            }
        } else {
            dispatch(clearOptions());
            for (let i = 0; i < selectedCart.Product.selectedOption.length; i++) {
                const id = selectedCart.Product.selectedOption[i].id;
                const name = selectedCart.Product.selectedOption[i].name;
                const quantity = selectedCart.Product.selectedOption[i].quantity;
                const price = selectedCart.Product.selectedOption[i].price;
                const require = selectedCart.Product.selectedOption[i].require;
                dispatch(setAddOption(id, name, price, require));
                dispatch(setQuantity(id, quantity, name, price, require));
            }
        }

    }, [options]);

    const optionGroups = info?.OptionGroups?.reduce((acc, cur) => {
        const options = cur.Options.map((el) => ({ ...el, require: cur.require, _id: el.id, value: el.id, label: el.name,}));
        const groupedMap = options.reduce(
            (entryMap, e) => entryMap.set(e.OptionGroupId, [...entryMap.get(e.OptionGroupId) || [], e]),
            new Map()
        );
        const groupedOpt = groupedMap.get(cur.id);
        const optionInfo = {
            id: cur.id,
            name: cur.name,
            require: cur.require,
            options: groupedOpt,
        }
        acc.push({...optionInfo});
        return acc;
    }, []);
    const reliseOptions = optionGroups && optionGroups?.reduce((acc, cur) => {
        acc.push(...cur.options)
        return acc
    }, []);

    const renderOptions = optionGroups && reliseOptions.filter((el) => optionState.map((el) => el.id).indexOf(el._id) > -1);
    const optionsTotal = [...optionState];

    useEffect(() => {
        if(selectedCartId !== undefined) {
            dispatch(listProductDetails(selectedCartId));
        }
        if (userInfo.data) {

                dispatch(clearOptions());
                let selOption = selectedCart && selectedCart?.Options?.reduce((acc, cur) => {
                    dispatch(checkOptions({ id: cur.OptionId, quantity: cur.quantity, price: cur.price, name: cur.name }));
                }, {})

        }
    },[dispatch, selectedCartId])

    const getTotalPrice = useCallback(() => {
        const option_id_arr = new Set(optionsTotal.map((el) => el.id));
        const noDupArr = [...option_id_arr];
        const total = {
            price: 0,
            quantity: 0,
        };
        let shippingPriceCondition = selectedCart.Product.free_shipping_price;
        let shippingPrice = selectedCart.Product.shipping_price;
        for (let i = 0; i < noDupArr.length; i++) {
            if(reliseOptions){
                const selectedOption = reliseOptions.filter((el) => el.id === optionsTotal[i].id);
                if (selectedOption.length === 0) {
                    continue
                }
                let quantity = optionsTotal[i].quantity
                let price = selectedOption[0]?.price;
                if (selectedOption[0].require) {
                    total.price = total.price + (quantity * (selectedCart.Product.sell_price + price));
                } else {
                    total.price = total.price + (quantity * price);
                }
                total.quantity = total.quantity + quantity;
            }
        }
        return total;
    }, [selectedCart, reliseOptions]);

    const handleSelect = (option, name, price, require) => {
        dispatch(setAddOption(option, name, price, require))
    }

    const handleQuantityMinus = (quantity, id, name, price, require, e) => {
        e.preventDefault();
        quantity !== 1 && dispatch(setQuantity(id, quantity-1, name, price, require));
    };

    const handleQuantityPlus = (quantity, id, name, price, require, e) => {
        e.preventDefault();
        dispatch(setQuantity(id, quantity + 1, name, price,require ));
    };

    const handleDelete = (option) => {
        dispatch(removeOptions(option));
    };

    const fixedOptions = info?.OptionGroups?.reduce((acc, cur) => {
        const options = cur.Options?.map((el) => ({ ...el, require: cur.require, _id: el.id, value: el.id, label: el.name,}))
        acc.push({...cur, Options: options})
        return acc
    }, [])


    return (
        <OptionDialog visible={visible}>
            <Dialog
                cancelText="닫기"
                onCancel={onCancel}
                dialogSize="medium"
                visible={visible}
                fullHeight
            >
                <p className="layer_tit">{selectedCart.Product.name}</p>
                <div className="select_options">
                    {options?.map((el) => {
                        let isRequire = el.require;
                        let optionss = [];
                        for (let i = 0; i < el.Options.length; i++) {
                            // if (!el.Options[i].display) {
                            //     el.Options[i].label += " (품절)";
                            //     el.Options[i].isDisabled = true;
                            // }
                            if (!el.Options[i].sell) {
                                el.Options[i].label += " (품절)";
                                el.Options[i].isDisabled = true;
                            }

                            el.Options[i].require = isRequire;
                            let operator = ""
                            if (el.Options[i].price >= 0) {
                                operator = "+"
                            }

                            if (isRequire) {
                                el.Options[i].label = `${el.Options[i].name} (${operator}${el.Options[i].price}원)`
                            } else {
                                el.Options[i].label = `${el.Options[i].name} (${operator}${el.Options[i].price}원)`
                            }

                            if (el.Options[i].display) {
                              optionss.push(el.Options[i]);
                            }
                        }
                        return (<div key={el.id}>
                            <label htmlFor={el.name}>{el.name}</label>
                            <Select
                                className="selectBox"
                                placeholder={`${el.name}`}
                                onChange={(option) => {handleSelect(option.id, option.label, option.price, option.require)}}
                                options={optionss}
                                styles={optionStyle}
                                classNamePrefix="select"
                            />
                        </div>)
                    })}
                </div>
                <div className="selected_option">
                    { optionGroups && optionState.map((el, idx) => {
                        const isRequireFilter = optionGroups.filter(v => v.options.findIndex(vj => vj.id === el.id) !== -1);
                        const option = optionState.filter(value => value.id === el.id)[0];
                        const price = option.price;
                        const quantity = option.quantity;
                        let isRequire = false;

                        if (isRequireFilter.length >= 1) {
                            isRequire = isRequireFilter[0].require;
                        }

                        return <div className="option_wrap" key={el.id}>
                            <p>{el.name}</p>
                            <div className="option_bottom">
                                <div className="option_count">
                                    <button onClick = {(e) => handleQuantityMinus(quantity, el.id, el.name, el.price, isRequire, e)}>-</button>
                                    <label>{quantity}</label>
                                    <button onClick = {(e) => handleQuantityPlus(quantity, el.id, el.name, el.price, isRequire, e)}>+</button>
                                </div>
                                <span>{
                                    isRequire ?
                                        stringPrice((info.sell_price + el.price) * quantity):
                                        stringPrice(el.price * quantity)
                                }원</span>
                            </div>
                            <GrClose alt="삭제" size={14} color={"#515355"} className="option_del"  onClick={() => handleDelete(el.id)}/>
                        </div> })
                    }
                </div>
                <div className="total_price">
                    <span>총 상품금액</span>
                    <span className="price"><b>{
                        reliseOptions?.length > 0 ?
                            getTotalPrice().price ?
                                optionState.length > 0 ?
                                    stringPrice(getTotalPrice().price) :
                                    0 :
                                0 :
                            stringPrice(0)
                    }</b>원</span>
                </div>
                <div className="option_btn">
                    <Button color="white" outline onClick={onCancel}>취소</Button>
                    <Button onClick={async () => {
                        try {
                            let findRequire = false;
                            for (let i = 0; i < optionState.length; i++) {
                                if (optionState[i].require) {
                                    findRequire = true
                                    break
                                }
                            }
                            if (!findRequire) {
                                return
                            }
                            if (userInfo.data) {
                                const config = {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "cid" : userInfo.data.cid,
                                        "encoded" : userInfo.data.encoded,
                                    }
                                }
                                const data = {
                                    id: selectedCart.id,
                                    options: optionState
                                }

                                await axios.put(`${process.env.REACT_APP_API_URL}api/order/cart`,data, config); //장바구니 AJAX 요청
                            } else {

                            }
                            // selectedCart
                            if(!userInfo.data){
                                let carts = JSON.parse(localStorage.getItem("cartItems"));
                                let idx = carts.findIndex(v => v.id === selectedCart.id);
                                if (idx !== -1) {
                                    carts[idx].Options = optionState;
                                    carts[idx].Product.selectedOption = optionState;
                                }
                                localStorage.setItem('cartItems', JSON.stringify(carts));
                            }
                            onChange()
                            setChange(!change)
                        } catch (error) {
                            console.log(error)
                        }
                    }}>변경</Button>
                </div>
            </Dialog>
        </OptionDialog>
    );
}

const OptionDialog = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  width: 480px;
  background: #fff;
  border: 1px solid #ddd;
  z-index: 9999;
  &.active {
    display: block;
  }

  & .dialog_wrap {
    min-height: auto;
    padding: 30px 17px 30px 30px;
    & .dialog_inner {
      padding-right: 5px;
    }
  }

  @media only screen and (max-width: 960px) {
    display: ${({ visible }) => visible ? 'flex' : 'none'};
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    min-height: auto;
    & > div {
      align-items: flex-end;
    }
    & .dialog_wrap {
      height: auto;
      min-height: auto;
      padding: 18px 0 30px;
    }
  }

  h3 {
    display: none;
  }

  .layer_tit {
    position: relative;
    margin-bottom: 16px;
    padding-right: 35px;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 23
    .4px;
    color: #222;

    @media only screen and (max-width: 960px) {
      line-height: 25px;
      padding-right: 41px;
    }
  }

  .close svg {
    width: 25px;
  }

  & .select_options {
    padding-right: 13px;
    @media only screen and (max-width: 960px) {
      display: block;
      margin: 0;
      padding-right: 10px;
    }

    & > div {
      flex-direction: column;
      margin-bottom: 14px;
      text-align: left;
      &:last-child {
        margin-bottom: 0;
      }
    }

    label {
      display: block;
      width: 100%;
      margin-bottom: 6px;
      line-height: 21px;
      font-size: 0.875rem;
      color: #222;
    }

    & .selectBox {
      width: 100%;
      height: 43px;
      span {
        display: none;
      }
      & .css-1wa3eu0-placeholder {
        color: #515355;
      }
      & .select__single-value {
        max-width: calc(100% - 25px);
        font-size: 0.875rem;
      }
      & .select__menu-list {
        overflow-y: auto;
        max-height: 129px;
        &::-webkit-scrollbar {
          position: absolute;
          right: 0;
          top: 0;
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #888B9D;
          border-radius: 15px;
        }
        &::-webkit-scrollbar-track {
          background-color: #DDDDDD;
          border-radius: 15px;
        }
      }
      @media only screen and (max-width: 960px) {
        width: 100%;
        height: auto;
        & .select__control {
          height: 43px;
        }
        & .select__placeholder {
          font-size: 14px;
        }
        & .select__single-value {
          font-size: 14px;
        }
        & .select__menu {          
          & .select__menu-list {
            overflow-y: auto;
            max-height: 129px;
            &::-webkit-scrollbar {
              position: absolute;
              right: 0;
              top: 0;
              width: 4px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #888B9D;
              border-radius: 15px;
            }
            &::-webkit-scrollbar-track {
              background-color: #DDDDDD;
              border-radius: 15px;
            }
          }
        }
      }
    }
  }

  & .selected_option{
    position: relative;
    margin-top: 20px;
    max-height: 188px;
    width: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      position: absolute;
      right: 0;
      top: 0;
      width: 3px;
      border-radius: 15px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #515355;
    }
    &::-webkit-scrollbar-track {
      background-color: #DDDDDD;
    }
    @media only screen and (max-width: 960px) {
      overflow-y: auto;
      max-height: 185px;
      margin-top: 10px;
    }
    & .option_wrap {
      position: relative;
      padding: 15px;
      background: #f6f6f6;
      margin-top: 10px;

      &:not(:first-child) {
        margin-top: 10px;
        margin-right: 10px;
      }
      &:first-child {
        margin-top: 0px;
        margin-right: 10px;
      }

      &:last-child {
        margin-bottom: 10px;
      }
      & p {
        display: inline-block;
        max-width: 360px;
        color: #222;
        font-size: 0.938rem;
        line-height: 22.5px;
      }

      & .option_bottom {
        display: flex;
        align-items: center;
        margin-top: 12px;
        & .option_count {
          display: flex;
          & button {
            height: 26px;
            width: 26px;
            border: 1px solid #ccc;
            background: #fff;
            font-size :20px;
            line-height: 21px;
          }
          & label {
            display: inline-block;
            align-items: center;
            width: 48px;
            height: 26px;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            background: #fff;
            font-size: 0.875rem;
            line-height: 24px;
            color: #222;
            text-align: center;
          }
        }
        & span {
          margin-left: auto;
          font-size: 0.938rem;
          line-height: 22.5px;
          color: #515355;
        }
      }
      & .option_del {
        position: absolute;
        top: 18px;
        right: 18px;
        width: 17px;
        cursor: pointer;
      }
    }

    @media only screen and (max-width: 960px) {
      & .option_wrap {
        flex-direction: column;
        &:not(:first-child) {
          margin-top: 6px;
        }
        & p {
          display: block;
          max-width: 100%;
          margin-bottom: 10px;
          margin-right: 0;
          padding-right: 30px;
          font-size: 0.875rem;
          line-height: 21px;
        }

        & .option_bottom {
          & .option_count {
            & button {
              height: 28px;
              width: 28px;
            }
            & label {
              width: 40px;
              height: 28px;
              font-size: 0.813rem;
              line-height: 28px;
            }
          }
          & span {
            margin-left: auto;
            padding: 0 5px;
            font-size: 0.938rem;
            line-height: 22.5px;
            color: #515355;
          }
        }
      }
    }
  }

  & .product_option {
    padding-right: 13px;
    min-width: 100%;
  }

  & .total_price {
    display:flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 13px);
    margin-top: 20px;
    padding: 16px 0 0 0;
    border-top: 1px solid #ddd;
    font-size: 1.25rem;
    font-weight: 500;
    & span:first-child {
      font-size: 0.938rem;
      color: #222222;
      line-height: 22px;
    }
    & .price {
      margin-left: auto;
      line-height: 45px;
      b {
        font-size: 30px;
        font-weight: 500;
      }
    }
    @media only screen and (max-width: 960px) {
      display: flex;
      align-items: center;

      padding-top: 16px;
      & span:first-child {
        font-size: 0.875rem;
      }
      & .price {
        margin-left: auto;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 36px;
        b {
          font-size: 1.5rem;
          line-height: 36px;
          font-weight: 500;
        }
      }
    }
  }

  & .option_btn {
    display: flex;
    margin-top: 13px;
    padding-right: 13px;

    div {
      height: 50px;
      width: calc((100% - 5px) / 2);
      font-size: 1rem;
      box-sizing: border-box;
    }

    @media only screen and (max-width: 960px) {
      & div {
        font-weight: 700;
      }
    }
  }
`

export default OptionPopup;