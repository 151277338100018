import React from "react";
import styled from "styled-components";
import 'rc-rate/assets/index.css';
import Dialog from './Dialog';

function RegularUseTermsPopup({ visible, onCancel }) {
	return (
    <RegularUseTermsDialog visible={visible}>
      <Dialog
        title="정기/반복결제 이용약관 동의"
        sub_title="[필수]"
        cancelText="닫기"
        onCancel={onCancel}
        fullHeight
        fixHeight
        visible={visible}
      >
        <div className="cont_wrap">
          <div className="cont_box">
              <p>1.  정기배송의 해지는 3회차 배송완료 10일 후 [마이페이지]-[나의 정기배송]-[정기배송 상세] 하단에서 가능합니다.</p>
              <p>2.  스마트 디바이스 할인 구매는 3회 구독 약정 혜택으로,  만약 3회 이용 횟수 충족 전에 중도 해지할 경우 이미 받아보신 할인 혜택에 대한 반환금이 청구됩니다.</p>
              <p>3.  4회차 부터 언제든 해지 및 변경이 가능합니다.</p>
              <p>4.  다음 회차에서 사료 상품 가격이 변경될 수 있습니다.</p>
          </div>
        </div>
      </Dialog>
    </RegularUseTermsDialog>
	);
}

const RegularUseTermsDialog = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};

  & .dialog_wrap {
    & h3 {
      margin-bottom: 30px;
    }
    & .dialog_inner {
      max-height: calc(90vh - 113px);
      padding: 0 30px 30px 30px;
    }
    & .cont_wrap {
      padding: 0;
      & .cont_box p {
        margin-bottom: 5px;
      }
    }
    & .tit_wrap {
      margin-top: 40px;
      & p:last-child {
        font-size: 0.875rem;
        &::before {
          content: none;
        } 
      }
    }
    & .cont_wrap2 {
      padding-top: 20px;
    }
  }
  
  @media only screen and (max-width: 960px) {
    & .dialog_wrap {
      & h3 {
        margin-bottom: 0;
        text-align: left;
      }
      & .dialog_inner {
        padding: 25px 15px;
        max-height: calc(100vh - 60px);
      }
      & .tit_wrap {
        margin-top: 50px;
        & p:first-child {
          font-weight: 500;
        }    
        & p:last-child {
          display: flex;
          position: relative;
          align-items: center;
          padding-left: 15px;
          margin-left: auto;
          color: #515355;
          font-size: 0.813rem;
          & svg {
            margin-left: 5px;
          }
          &::before {
            content: none;
          }
        }
      }
    }
  }

  & .cont_wrap {
    padding: 60px 0;
    margin: 0 auto;
    &.displaynone {
      display: none;
    }

    & p {
      font-size: 0.875rem;
      color: #515355;
      line-height: 21px;
      & b {
        font-weight: 500;
      }
      &.pd10 {
        padding-left: 10px;
      }
      &.pd15 {
        padding-left: 15px;
      }
    }

    & p.popup_tit {
      margin: 30px 0 10px;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;
      color: #222;
    }

    & p.small_desc {
      margin-top: 10px;
      font-size: 0.813rem;
      & b {
        color: #515355;
      }
    }

    & table {
      width: 100%;
      margin-top: 10px;
      border: 1px solid #ddd;
      
      & tbody tr {
        border-top: 1px solid #ddd;
      }
      & tr{
        & th, & td {
          padding: 8px;
          font-size: 0.813rem;
          color: #515355;
          line-height: 19.5px;
          vertical-align: middle;
          &:not(:last-child) {
            border-right: 1px solid #ddd;
          }
        }
        & th {
          font-weight: 500;
          background: #f6f6f6;
        }
      }

      &.center_align {
        & tr {
          & th, & td {
            text-align: center;
          }
        }
      }
    }
  }

  & .tit_wrap {
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 24px;
    cursor: pointer;
    & p:first-child {
      font-weight: 500;
    }    
    & p:last-child {
      display: flex;
      position: relative;
      align-items: center;
      padding-left: 15px;
      margin-left: auto;
      color: #515355;
      & svg {
        margin-left: 10px;
      }
      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 10%;
        width: 1px;
        height: 80%;
        background: #999;
      }
    }
  }

  @media only screen and (max-width: 960px) {
    width: 100%;
    & .cont_wrap {
      padding: 0;
      & table {
        &.privacy_agree {
          margin-bottom: 40px;
          font-size: 0.813rem;

          & th, & td {
            line-height: 19.5px;
          }
        }
        

        &.privacy_agree2 {
          border: 1px solid #ddd;
          & tbody tr {
            border-top: 1px solid #ddd;
          }
          & tr{
            & th, & td {
              padding: 8px 2px;
              font-size: 0.75rem;
              line-height: 18px;
            }
          }
        }
      }
    }
    & .cont_wrap2 {
      padding-top: 30px;
    }
  }
`

export default RegularUseTermsPopup;