export const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validateCodeNum = number => {
    return /^[0-9]{6}$/.test(number);
}

export const isPassWordUpper = password => {
    return /(?=.*[A-Z])\w+/.test(password);
}
export const isPassWordLower = password => {
    return /(?=.*[a-z])\w+/.test(password);
}
export const isPassWordSpecial = password => {
    let checkSpc = /[~!@#$%^&*()_+|<>?:{}]/;
    return checkSpc.test(password)
    // return /(?=.*?[#?!@$%^&*-])\w+/.test(`${password}`);
}
export const isPasswordOk = password => {
    return /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}/gi.test(password);
};

export const isPhoneNumOk = phone => {
    return /01[016789][^0][0-9]{2,3}[0-9]{3,4}/.test(phone);
}

export const validatePassword = password => {
    let pw1 = false
    let pw2 = false
    let pw3 = false
    if(/\w/gi.test(password)){
        pw1 = true
        pw2 = true
    }else{
        pw1 = false
        pw2 = false
    }
    if(/[~!@#$%^&*()_+|<>?:{}]/g.test(password)){
        pw3 = true
    }else{
        pw3 = false
    }
    return [pw1, pw2, pw3]

    // return /^^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password);
};

export const matchPassword = (password, passwordConfirm) => {
    if (!password || passwordConfirm.length < 8) {
        return false;
    }
    return password === passwordConfirm;
};

export const onlyNumber = (str) => {
    if(/[^0-9]/g.test(str)){
        return true
    }
    return false
}

export const morethanLength = (str, n) => {
    if (!str) {
        return false;
    }
    return str.length >= n;
};

export const validateKoreanName = str => {
    // const re = /([^가-힣\x20])/i;
    // return !re.test(str) && str.length >= 2;
    return str.length >= 2;
};

export const checkAllItems = (email, password, passwordConfirm, name) => {
    return (
        validateEmail(email) &&
        validatePassword(password) &&
        matchPassword(password, passwordConfirm) &&
        validateKoreanName(name)
    );
};

export const checkAllItemsForPayment = (email, name, phone) => {
    return (
        validateEmail(email) &&
        isPhoneNumOk(phone) &&
        validateKoreanName(name)
    );
}

export const checkKakaoItems = (email) => {
    return (
        validateEmail(email)
    )
}
