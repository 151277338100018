import React from 'react';
import styled, { css } from 'styled-components'
import { darken, lighten } from 'polished';

const colors = {
    blue: {
      color: '#1EA0FA',
      fontColor: '#fff'
    },
    white: {
      color: '#1EA0FA',
      fontColor: '#fff'
    },
    gray: {
      color: '#ddd',
      fontColor: '#fff'
    },
    darkgray: {
      color: '#5F5F5F',
      fontColor: '#fff'
    },
    black: {
      color: '#f6f6f6',
      fontColor: '#222'
    }
}

const colorStyles = css`
  ${({ color }) => {
    return css`
      background: ${colors[color].color};
      @media only screen and (min-width: 961px) {
        ${color === "blue" && css`
          &:hover {
            background: ${lighten(0.1, colors[color].color)};
          }
          &:active {
            background: ${darken(0.1, colors[color].color)};
          }
        `}
      }
      color: ${colors[color].fontColor};
      ${props => props.outline && css`
        color: ${colors[color].color};
        background: #fff;
        border: 1px solid ${colors[color].color};
        @media only screen and (min-width: 961px) {
          &:hover {
            background: ${lighten(0.1, '#fff')};
          }
        }
      `}
      }
    `;
  }}
`;

const sizes = {  
  large: {
    height: '54px',
    fontSize: '1.125rem',
    width: 'calc((100% - 10px) / 2);',
    fontWeight: '700',
    borderRadius: '5px'
  },
  medium: {
    height: '54px',
    width: 'calc((100% - 10px) / 2);',
    fontSize: '1.125rem',
    borderRadius: '4px'
  },
  small: {
    height: '46px',
    width: '120px',
    fontSize: '1rem',
    fontWeight: '400'
  }
}

const sizeStyles = css`
  /* 크기 */
  ${({ size }) => css`
    height: ${sizes[size].height};
    width: ${sizes[size].width};
    font-size: ${sizes[size].fontSize};
    font-weight: ${sizes[size].fontWeight};
    border-Radius: ${sizes[size].borderRadius};
  `}
`

const fullWIdthStyle = css`
  ${props => props.fullWidth && css`
    width: 100%;
    justify-content: center;
    border-radius: 5px;
    &:not(:first-child) {
      margin-top: 10px;
      margin-left: 0;
    }
  `}
`

const StyledButton = styled.div`
  /* 공통 스타일 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
  font-weight: 500;

  &:not(:first-child) {
    margin-left: 10px;
    margin-top: 0;
    @media only screen and (max-width: 768px) {
      margin-left: 5px;
    }
  }  

  ${sizeStyles};
  ${colorStyles};
  ${fullWIdthStyle};
`;

function Button({ children, color, size, outline, fullWidth, className, ...rest }) {
  return (
    <StyledButton color={color} size={size} outline={outline} fullWidth={fullWidth} className={className} {...rest}>
      {children}
    </StyledButton>
  )
}

Button.defaultProps = {
  color: 'blue',
  size: 'medium'
}

export default Button;