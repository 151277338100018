import React, { useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import 'rc-rate/assets/index.css';
import Button from './Button';
import Dialog from './Dialog';
import arr_more from "images/arr_more.png";
import DotGuide from './DotGuide';
import { GrNext } from 'react-icons/gr';
import {GrPrevious} from 'react-icons/gr';
import {RiArrowRightSLine} from 'react-icons/ri';
import {RiArrowLeftSLine} from 'react-icons/ri';
import sublist_open from 'images/icon/sublist_open.png';
import m_sublist_open from 'images/m/m_sublist_open.png';
import {stringPrice} from "../../utils/StringPrice";
import {useForm} from "react-hook-form";
import PostCode from "./PostCode";
import axios from "axios";
import {useSelector} from "react-redux";

function ReqExchangePopup({ visible, setReqExchange, item , onClose}) {
  const isPc = useMediaQuery({ query: '(min-width: 961px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const userInfo = useSelector((state) => state.userLogin.userInfo.data || "");
  const {register, handleSubmit, setValue, watch} = useForm();
  const [postCodePopup, setPostCodePopup] = useState(false);
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [checkItems, setCheckItems] = useState([]);
  const [checkId, setCheckId] = useState([]);

  const showStep1 = () => {
    setStep2(false);
    setStep1(true);
    document.querySelector(".dialog_inner").scrollTo(0,0);
  }

  const showStep2 = () => {
    if(checkItems.length !== 0){
      setStep1(false);
      setStep2(true);
    }else{
      return
    }
    document.querySelector(".dialog_inner").scrollTo(0,0);
  }

  const checkReason = watch("refund_reason");
  const checkReasonSel = watch("refund_select_reason");
  const checkUserName = watch("userName");
  const checkPhone = watch(["p1", "p2", "p3"]);
  const checkAddress = watch(["address", "addressDetail"])

  const showStep3 = () => {
    if(checkReason !== "" && checkReasonSel !== ""){
      setStep1(false);
      setStep2(false);
      setStep3(true);
    }else{
      return
    }
    document.querySelector(".dialog_inner").scrollTo(0,0);
  }

  const onCancel = () => {
    setStep1(true);
    setStep2(false);
    setStep3(false);
    setReqExchange(false);
  }

  const selectReasonOpt = [
    {value: "자동급식기 불만족", label: "자동급식기 불만족"},
    {value: "정기배송 서비스 불만족", label: "정기배송 서비스 불만족"},
    {value: "다른 상품 잘못주문", label: "다른 상품 잘못주문"},
    {value: "상품 불량", label: "상품 불량"},
    {value: "배송 지연", label: "배송 지연"},
    {value: "배송 누락", label: "배송 누락"},
    {value: "상품 품절", label: "상품 품절"},
    {value: "상품 파손", label: "상품 파손"},
    {value: "상품 정보 상이", label: "상품 정보 상이락"},
    {value: "오배송", label: "오배송"},
    {value: "기기교환", label: "기기교환"},
    {value: "사료교환", label: "사료교환"},
  ]

  const handleCheckRadio = (checked, product, device) => {
    if(checked){
      setCheckItems([...checkItems, product, device]);
    }else{
      setCheckItems(checkItems.filter((el) => { return el !== product && el !== device}));
    }
  }

  const handleCheckId = (checked, id) => {
    if(checked){
      setCheckId([...checkId, id]);
    }else{
      setCheckId(checkId.filter((el) => el !== id));
    }
  }

  const itemId = item.last_id ? item.last_id : item.RegularOrdersId
  const [deviceChecking, setDeviceChecking] = useState(false);
  const diviceChecked = (checked) => {
    if(checked){
      setDeviceChecking(!deviceChecking);
    }
  }



  const onSubmit = async (data) => {
    data = {
      id: item.RegularOrdersId ? item.id : item.RegularOrderId,
      regularOrdersId: item.RegularOrdersId ? item.RegularOrdersId : item.id,
      product_name: checkItems.includes(item.product_name) ? item.product_name : null,
      device_return_product: item.devices && checkItems.includes(item.devices.name) ? item.devices.name : null,
      phone : data.p1 + data.p2 + data.p3,
      return_reason: data.refund_select_reason,
      reason_content: data.refund_reason,
      return_way: "롯데택배",
      return_status: "교환신청",
      status_sort : "교환",
      name: data.userName,
      place_address: `${data.address} / ${data.addressDetail}`
    }
      try{
        axios.defaults.headers.common["cid"] = userInfo.cid;
        axios.defaults.headers.common["encoded"] = userInfo.encoded;
        const response = await axios.post(`${process.env.REACT_APP_API_URL}api/regular/order/return`, {data : data})
        console.log(response)
        setStep1(true);
        setStep2(false);
        setStep3(false);
        onClose();
        setReqExchange(false);
      }catch(error){
        console.log(error)
      }
  }
  let string1 = '상품 불량, 오배송의 경우 상품 배송일로부터 10일 이내 교환이 가능하며, 자세한 사항은 고객만족센터로 문의부탁드립니다. \n' +
      '단, 불량 상품의 경우 고객만족센터를 통한 불량 검수 후 정상 처리 여부가 결정됩니다. 상품 및 서비스 개선을 위하여 내용물 사진 등 상세한 정보를 준비부탁드립니다.'

  const [textarea, setTextArea] = useState(false);


	return (
    <ReqRefundDialog
      title="교환신청"
      cancelText="닫기"
      onCancel={onCancel}
      dialogSize="medium"
      fullHeight
      fixHeight
      visible={visible}
    >
      <div className="process">
        <ul>
          <li className={step1 ? "step1 active" : "step1"}>교환 상품 선택</li>
          <li className={step2 ? "step2 active" : "step2"}>교환신청</li>
          <li className={step3 ? "step3 active" : "step3"}>교환 방법 선택</li>
        </ul>
      </div>
      {isMobile && <p className="noArea"></p>}
        <div className={step1 ? "step1_wrap active" : "step1_wrap"}>
          <div className="step_box">
            <p className="sub_tit">교환 상품 선택</p>
            <div className="order_lists">
              <ul>
                <li className="order_lists_list">
                  <ul>
                    <li className="order_list_content">
                      <div className="order_list_content_in">
                        <div className="checks full_check">
                          <input type="checkbox" name={item.product_name} id={item.product_id}
                                 onClick = {(e) => {handleCheckRadio(e.target.checked, item.product_name, item?.devices?.name); handleCheckId(e.target.checked, itemId);}}
                                 checked={checkItems.includes(item.product_name) ? true : false} />
                          <label htmlFor={item.product_id}></label>
                        </div>
                        <div className="order_list_content_img">
                          <img src={item.product_image} alt={item.product_name}/>
                          <div className="order_list_content_p">
                            <p className="order_title">{item.product_name}</p>
                            <p className="order_price">{stringPrice(item.order_price_amount)}원 / {item.product_amount} 개</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                {item.devices !== null ?
                <li className="order_lists_list">
                  <ul style={{borderTop: "0px"}}>
                    <li className="order_list_content">
                      <div className="order_list_content_in">
                        <div className="checks full_check">
                          <input type="checkbox" name={item.devices.name} id={item.devices.id} onClick = {(e) => {
                            handleCheckRadio(e.target.checked, item.product_name, item.devices.name); handleCheckId(e.target.checked, itemId); diviceChecked(e.target.checked)}}
                                 checked={checkItems.includes(item.devices.name) ? true : false} />
                          <label htmlFor={item.devices.id}></label>
                        </div>
                        <div className="order_list_content_img">
                          <img src={item.devices.main_img} alt={item.devices.name}/>
                          <div className="order_list_content_p">
                            <p className="order_title">{item.devices.name}</p>
                            <p className="order_price">{stringPrice(item.devices.regular_order_price)}원 / 1개</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li> : null}
              </ul>
            </div>
          </div>
          <div className="mypage_guide">
            <p className="guide_tit">교환 안내</p>
            <DotGuide size={isPc ? "small" : "tiny"} text={string1} />
            <DotGuide size={isPc ? "small" : "tiny"} text="단순 변심으로 인한 교환·반품 요청의 미개봉 제품만 7일 이내에만 가능합니다." />
            <DotGuide size={isPc ? "small" : "tiny"} text="정기배송 상품은 일부 교환이 어렵습니다. 일부 상품 교환 시 사유 내  [기기교환], [사료교환] 항목을 선택해 주시면 고객만족센터를 통해 답변드릴 수 있도록 하겠습니다." />
            <p className="guide_tit">정기배송 해지 안내</p>
            <DotGuide size={isPc ? "small" : "tiny"} text="정기배송 상품의 반품을 원하실 경우 정기배송 해지로 접수해주셔야 합니다." />
            <DotGuide size={isPc ? "small" : "tiny"} text="정기배송의 해지는 4회차부터 적용됩니다." />
          </div>
          <p className="next_btn_wrap">
            <button onClick={showStep2} className="step1_btn next_btn">
              다음 { isPc ? <GrNext /> : <RiArrowRightSLine size={20} />}
            </button>
          </p>
        </div>
        <div className={step2 ? "step2_wrap active" : "step2_wrap"}>
          <div className="step_box">
            <p className="sub_tit">교환 사유 선택</p>
            <select className="refund_select" required {...register("refund_select_reason")}>
              <option value="" disabled selected hidden>교환 사유를 선택해 주세요.</option>
              {selectReasonOpt.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
            <textarea
              placeholder="[필수] 상세사유를 입력해 주세요."
              rows="5"
              {...register("refund_reason")}></textarea>
          </div>
          <div className={textarea ? "next_btn_wrap relative_btn" : "next_btn_wrap"}>
            <button onClick={showStep1} className="prev_btn">
            { isPc ? <GrPrevious /> : <RiArrowLeftSLine size={20} />} 이전{textarea}
            </button>
            <button onClick={showStep3} className="next_btn">
              다음 { isPc ? <GrNext /> : <RiArrowRightSLine size={20} />}
            </button>
          </div>
        </div>
        <div className={step3 ? "step3_wrap active" : "step3_wrap"}>
          <div className="step_box">
            <p className="sub_tit">교환 방법 선택</p>
            <div className="agree_box">
              <div className="checks full_check">
                <input type="checkbox" name="chk_delivery" id="chk_delivery" {...register("chk_delivery")} checked={true} />
                <label htmlFor="chk_delivery">쇼핑몰 지정반품 택배 (롯데택배)</label>
              </div>
            </div>
          </div>
          {isMobile && <p className="noArea"></p>}
          <div className="step_box">
            <p className="sub_tit">상품 수거지 확인<span style={{cursor:"pointer"}} onClick={() => {
              setValue("userName", "");
              setValue("zoneCode", "");
              setValue("address", "");
              setValue("addressDetail", "");
              setValue("p2", "");
              setValue("p3", "");
            }}>수정</span></p>
            <div className="address_form">
              <div className="sub_cont_wrap address_info">
                <div className="row">
                  <div className="form_title">이름</div>
                  <div className="form_cont"><input type="text" name="userName" {...register("userName")}  /></div>
                </div>
                <div className="row address">
                  <div className="form_title">주소</div>
                  <div className="form_cont">
                    <p><input type="text" id="Address1" name="zoneCode" {...register("zoneCode")} disabled /><Button size="small" color="white" outline className="post_num" onClick={() => {setPostCodePopup(!postCodePopup)}}>우편번호</Button></p>
                    <p><input type="text" id="Address2" name="address" {...register("address")} disabled /></p>
                    <p><input type="text" id="Address3" name="addressDetail" {...register("addressDetail")} placeholder="상세주소를 입력하세요." /></p>
                  </div>
                </div>
                <div className="row phone">
                  <div className="form_title">핸드폰 번호</div>
                  <div className="form_cont">
                    <p>
                      <select name="p1" {...register("p1")}>
                        <option>010</option>
                        <option>011</option>
                        <option>012</option>
                        <option>013</option>
                      </select>
                      <span>-</span>
                      <input type="number" name="p2" {...register("p2")} />
                      <span>-</span>
                      <input type="number" name="p3" {...register("p3")} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isMobile && <p className="noArea"></p>}
          <div className="step_box">
            <p className="sub_tit">교환 상품 도착지 확인</p>
            <div className="address_box">
              <p>
                바램시스템<br />
                1670.8921<br />
                (34002) 대전 유성구 국제과학 22로 44 (구룡동) 바램시스템
              </p>
            </div>
          </div>
          {isMobile && <p className="noArea"></p>}
          <DotGuide text="교환 상품 도착 후 추가적으로 안내를 드립니다." />
          <div className="button_wrap">
            {checkUserName !== "" && checkPhone[1] !== "" && checkPhone[2] !== "" && checkAddress[0] !== undefined && checkAddress[1] !== "" ?
                <Button size="small" onClick={handleSubmit(onSubmit)}>신청하기</Button> :
                <Button size="small" color="gray">신청하기</Button>
            }
          </div>
        </div>
      <PostCode
          setValue={setValue}
          visible={postCodePopup}
          setPostCodePopup={setPostCodePopup}
          onCancel={() => setPostCodePopup(!postCodePopup)}
      />
    </ReqRefundDialog>
	);
}

const ReqRefundDialog = styled(Dialog)`
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  @media only screen and (max-width: 960px) {
    padding-bottom: 0;
    height: 100vh;

    & .step_box {
      padding: 30px 15px;
    }
  }
  
  & .dialog_wrap {
    overflow-y: auto;
    max-height: 90vh;
    height: auto;
    box-sizing: border-box;
    padding-top: 0;
    &::-webkit-scrollbar {
      position: absolute;
      right: 0;
      top: 0;
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888B9D;
      border-radius: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: #DDDDDD;
      border-radius: 15px;
    }

    & .dialog_inner {
      overflow-y: initial;
      height: auto;
      max-height: 100%;
      padding: 0;
      padding: 30px 50px 20px;
    }

    @media only screen and (max-width: 960px) {
      overflow-y: hidden;
      width: 100%;
      max-width: 540px;
      max-height: 100vh;
      padding-bottom: 0 !important;
      & .dialog_inner {
        overflow-y: auto;
        height: calc(100vh - 60px);
        padding: 0 0 30px 0 !important;
      }
    }
  }

  & h3 {
    display: none;

    @media only screen and (max-width: 960px) {
      display: block;
    }
  }

  & .process {
    & ul {
      display: flex;
      justify-content: center;
      & li {
        line-height: 22px;
        color: #515355;
        font-size: 0.938rem;
        &:not(:last-child) {
          padding-right: 28px;
          background: url(${arr_more}) no-repeat right 10px top 7px;
          background-size: 6px auto;
        }
        &.active {
          font-weight: 500;
          color: #1EA0FA;
        }
      }
    }
    @media only screen and (max-width: 960px) {
      & ul {
        padding: 20px 0 18px;
      }
    }
  }

  & .sub_tit {
    margin: 30px 0 10px;
    line-height: 24px;
    color: #222;
    font-weight: 500;
    line-height: 24px;
    @media only screen and (max-width: 960px) {
      margin: 0 0 20px 0;
      font-size: 1.063rem;
      line-height: 25px;
    }
  }

  & .step1_wrap {
    display: none;
    &.active {
      display: block;
    }
    & .order_lists{
      & ul > li.order_lists_list  {
        box-sizing: border-box;
        margin: 0;
        
        ul {
          border: 1px solid #ccc;
          
          & li.order_list_content{
            padding: 0 15px !important;

            & .checks {
              margin: 0 5px;
            }

            &:not(:last-child) {
              & .order_list_content_in {
                border-bottom: 1px solid #E1E2E3;    
              }
            }
            & .order_list_content_in {
              display: flex;
              align-items: center;
              padding: 15px 0 !important;
              
              & .order_list_content_img{
                width: 80px;
                display: flex;
                align-items: center;
                flex-grow: 1;
                
                img {
                  display: inline-block;
                  box-sizing: border-box;
                  width: 80px;
                  height: 80px;
                  margin-right: 20px;
                }
                
                & .order_list_content_p{
                  p:not(:first-child) {
                    margin-top: 4px;
                  }
                  
                  p.order_title {
                    font-size: 15px;
                    color: #222;
                    line-height: 22px;
                  }

                  p.order_option {
                    font-size: 14px;
                    line-height: 21px;
                    color: #555;
                  }

                  p.order_price {
                    font-size: 14px;
                    line-height: 21px;
                    color: #555;
                    & b {
                        font-weight: 500;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    & .mypage_guide {
      padding: 20px;
      margin-top: 20px;
      & .guide_tit {
        font-size: 13px;
        margin-bottom: 10px;
        line-height: 19px;
        font-weight: 500;
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
      & span {
        font-size: 13px;
        line-height: 19px;
      }
    }

    @media only screen and (max-width: 960px) {
      & .step_box {
        padding-bottom: 0;
        & .sub_tit {
          margin-bottom: 10px;
        }
      }
      & .order_lists{
        background: none;
        padding: 0;
        & ul > li.order_lists_list  {
          ul {
            border: none;
            & li.order_list_content{
              padding: 0 !important;

              & .checks {
                margin: 0;
              }

              &:not(:last-child) {
                & .order_list_content_in {
                  border-bottom: 1px solid #EDEDED;    
                }
              }
              & .order_list_content_in {
                padding: 20px 0 !important;
                align-items: flex-start;
                
                & .order_list_content_img{
                  align-items: flex-start;
                  width: 75px;
                  
                  img {
                    width: 75px;
                    height: 75px;
                    margin-right: 15px;
                  }
                  
                  & .order_list_content_p{
                    p:not(:first-child) {
                      margin-top: 4px;
                    }
                    
                    p.order_title {
                      font-size: 1rem;
                    }

                    p.order_option {
                      font-size: 0.813rem;
                      line-height: 18px;
                      color: #888;
                    }

                    p.order_price {
                      font-size: 0.813rem;
                      line-height: 18px;
                      color: #222;
                      & b {
                          font-weight: 700;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      & .mypage_guide {
        padding: 20px;
        margin-top: 20px;
        & .guide_tit {
          font-size: 13px;
          margin-bottom: 10px;
          line-height: 19px;
          font-weight: 500;
        }
        & span {
          font-size: 13px;
          line-height: 19px;
        }
        @media only screen and (max-width: 960px) {
          padding: 30px 15px;
          margin-top: 10px;
          & .guide_tit {
            font-size: 0.875rem;
            line-height: 21px;
          }
          & span {
            font-size: 0.75rem;
            line-height: 18px;
            letter-spacing: -0.01em;
          }
        }
      }
    }
  }

  & .step2_wrap {
    display: none;
    &.active {
      display: block;
    }

    & .step_box {
      padding-bottom: 80px;
    }

    & .sub_tit {
      & span {
        display: block;
        margin-top: 4px;
        font-size: 13px;
        line-height: 19px;
        color: #888;
        font-weight: 400;
      }
    }

    & select {
      margin-bottom: 10px;
      font-size: 0.938rem;
      line-height: 22px;
      padding: 10px 15px;
      border: 1px solid #ddd;
      background: url(${sublist_open}) no-repeat right 15px center;
    }
    & textarea {
      font-size: 0.938rem;
      line-height: 22px;
      padding: 10px 15px;
      border: 1px solid #ddd;
      &::placeholder {
        font-size: 0.938rem;
        line-height: 22px;
        color: #999;
      }
    }
    & .refund_account {
      margin-top: 16px;
      & p {
        display: flex;
        align-items: center;
        &:not(:first-child) {
          margin-top: 10px;
        }
        & span:first-child {
          width: 95px;
          font-size: 15px;
          line-height: 22px;
          color: #222
        }
        & span:last-child {
          width: calc(100% - 95px);
          & input {
            padding: 10px 15px;
            border: 1px solid #ddd;
            font-size: 15px;
            color: #222;
            line-height: 21px;
          }
        }
      }
    }

    & .next_btn_wrap {
      position: absolute;
      display: flex;
      bottom: 30px;
      left: 0;
      width: 100%;
      padding: 0 50px;
      & button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 40px;
        border-radius: 40px;
        border: 1px solid #1EA0FA;
        color: #1EA0FA;
        font-weight: 500;
        line-height: 22px;
        font-size: 15px;
        & svg {
          font-size: 12px;
          & polyline {
            stroke: #1EA0FA;
          }
        }
        &.prev_btn {
          & svg {
            margin-right: 8px;
          }
        }
        &.next_btn {
          margin-left: auto;
          & svg {
            margin-left: 8px;
          }
        }
      }
    }

    @media only screen and (max-width: 960px) {
      & select {
        border: 1px solid #ccc;
        background: url(${m_sublist_open}) no-repeat right 15px center;
        background-size: 12px 6px;
      }
      & textarea {
        border: 1px solid #ccc;
      }
      & .step_box {
        padding-bottom: 30px;
      }
      & .refund_account {
        margin-top: 0;
        & p {
          flex-direction: column;
          align-items: flex-start;
          & span:first-child {
            width: 100%;
          }
          & span:last-child {
            width: 100%;
            margin-top: 10px;
            & input {
              border: 1px solid #ccc;
            }
          }
        }

        & .refund_desc {
          font-size: 0.75rem;
          letter-spacing: -0.02em;
          line-height: 18px;
          color: #888;
        }
      }
      & .next_btn_wrap {
        position: relative;
        padding: 0 15px;
        margin-top: 0;
        bottom: 0;
        /* &.relative_btn {
          position: relative;
        } */
      }
    }
  }

  & .step3_wrap {
    display: none;
    &.active {
      display: block;
    }
    & .sub_tit {
      display: flex;
      align-items: center;
      & span {
        margin-left: auto;
        color: #888;
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
      }
    }

    & .agree_box {
      padding: 20px;
      border: 1px solid #ddd;

      & label {
        font-size: 15px;
      }
    }
    & .address_form {
      margin-top: 20px;

      & input, & select {
        padding: 10px 15px;
        font-size: 15px;
        color: #222;
        line-height: 21px;
        &::placeholder {
          font-size: 15px;
        }
      }
      & .row {
        display: flex;
        & .form_title {
          width: 95px;
          padding-top: 12px;
          font-size: 15px;
        }
        & .form_cont {
          width: calc(100% - 95px);
        }
        &.address {
          margin-top: 10px;
          & p:not(:first-child) {
            margin-top: 5px;
          }
          & p:first-child {
            display: flex;
            & input {
              width: calc(100% - 115px);
            }
          }
          & .post_num {
            width: 110px;
            margin-left: 5px;
            font-size: 15px;
            font-weight: 400;
          }
        }

        &.phone {
          margin-top: 10px;
          & .form_cont {
            & p {
              display: flex;
              align-items: center;
              & span {
                display: inline-block;
                width: 18px;
                text-align: center;
                color: #222;
              }
              & select {
                width: 90px;
              }
              & input {
                width: calc((100% - 126px) / 2);
              }
            }
          }
        }
      }
    }

    & .address_box {
      background: #F7F7F7;
      padding: 20px;
      & p {
        line-height: 21px;
        font-size: 14px;
        color: #222;
      }
    }

    & .button_wrap {
      margin-bottom: 10px;
      text-align: center;
      & button {
        width: 160px;
        border-radius: 4px;
      }
    }

    @media only screen and (max-width: 960px) {
      & .agree_box {
        padding: 0;
        border: none;

        & label {
          font-size: 15px;
        }
      }
      & .address_form {
        margin-top: 0;

        & input, & select {
          padding: 11px 14px;
        }
        & .row {
          flex-direction: column;
          & .form_title {
            width: 100%;
            padding-top: 0;
            font-size: 0.938rem;
          }
          & .form_cont {
            width: 100%;
            margin-top: 10px;
          }
          &.address {
            margin-top: 20px;
            & p:first-child {
              & input {
                width: calc(100% - 105px);
              }
            }
            & .post_num {
              width: 100px;
            }
          }

          &.phone {
            margin-top: 20px;
            & .form_cont {
              & p {
                display: flex;
                align-items: center;
                & span {
                  display: inline-block;
                  width: 18px;
                  text-align: center;
                  color: #222;
                }
                & select, & input {
                  width: calc((100% - 36px) / 3);
                }
              }
            }
          }
        }
      }

      & .address_box {
        background: #F7F7F7;
        padding: 20px;
        & p {
          line-height: 21px;
          font-size: 14px;
          color: #222;
        }

        @media only screen and (max-width: 960px) {
          background: none;
          padding: 0;
          & p {
            line-height: 22px;
            font-size: 0.938rem;
          }
        }
      }

      & .button_wrap {
        margin-bottom: 0;
        & button {
          width: 170px;
          border-radius: 4px;
        }
        @media only screen and (max-width: 960px) {
          padding: 0 15px;
          & div {
            width: 100%;
            height: 55px;
            font-size: 1.125rem;
            font-weight: 700;
          }
        }
      }
    }
  }

  & .next_btn_wrap {
    margin-top: 20px;
    text-align: right;
    & .next_btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 40px;
      border-radius: 40px;
      border: 1px solid #1EA0FA;
      color: #1EA0FA;
      font-weight: 500;
      line-height: 22px;
      font-size: 15px;
      & svg {
        margin-left: 8px;
        font-size: 12px;
        & polyline {
          stroke: #1EA0FA;
        }
      }
    }
    @media only screen and (max-width: 960px) {
      margin-top: 30px;
      margin-right: 15px;
      & .next_btn {
        padding-left: 4px;
        & svg {
          margin-left: 3px;
        }
      }
    }
  }

  & .dot_guide {
    margin-top: 10px;
    & span {
      color: #888;
      font-size: 13px;
    }
    @media only screen and (max-width: 960px) {
      margin-left: 15px;
    }
  }

  &  .button_wrap {
    display: flex;
    margin: 30px auto 0;
    justify-content: center;
    & div {
      width: 170px;
      border-radius: 4px;
    }
  }

  & .ButtonWrap {
    display: none;
  }
`

export default ReqExchangePopup;