import React, {useEffect, useState} from 'react';
import axios from "axios";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {IoIosArrowDown} from "react-icons/io";
import {useMediaQuery} from "react-responsive";
import {IoIosArrowForward} from "react-icons/io";
import ChangeCardDialog from 'pages/components/ChangeCardDialog';
import Dialog from 'pages/components/Dialog';
import DotGuide from 'pages/components/DotGuide';
import DatePicker, {registerLocale} from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import arr_my_nav from 'images/icon/arr_my_nav.png';
import Checkbox from 'pages/components/Checkbox';
import {FiSearch} from 'react-icons/fi';
import no_delivery from 'images/no_delivery.png';
import AddressDialog from 'pages/components/AddressDialog';
import {useDispatch, useSelector} from "react-redux";
import { appendOrderLists_reg, fetchOrderLists, fetchOrderLists_reg, updateOrderLists_reg} from "actions/orderActions";
import {stringPrice} from "../../../utils/StringPrice";
import {useForm} from "react-hook-form";
import {fetchAllRegulars} from "../../../actions/productActions";
import Button from 'pages/components/Button';
import Moment from "moment";
import {FETCH_ORDER_RESET} from "../../../actions/types";

registerLocale('ko', ko);

const MyDelivery = ({checkUser}) => {
        const dispatch = useDispatch();
        const isPc = useMediaQuery({query: '(min-width: 960px)'})
        const isMobile = useMediaQuery({query: '(max-width: 960px)'})
        const orderlist = useSelector((state) => state.regularOrderList);
        const regularInfo = useSelector((state) => state.regularLists.regulars || []) //정기배송 교환할 상품리스트
        const userInfo = useSelector((state) => state.userLogin.userInfo.data || "");
        const [getId, setGetId] = useState(0) //결제일 버튼 클릭시 해당 idx값 가져오기
        const [limit, setLimit] = useState(3) //더보기 리스트 수 제한
        const [payDate, setPayDate] = useState(Moment().format("YYYY-MM-DD")); //datePicker 라이브러리에 선택된 날짜
        const {register, handleSubmit} = useForm()
        const [searchList, setSearchList] = useState([])
        const [nextDay, setNextDay] = useState(Moment().format("YYYY-MM-DD"));
        const [payDay, setPayDay] = useState(Moment().format("YYYY-MM-DD"));
        const [isUpdate, setIsUpdate] = useState({date: new Date().getTime(), page: 1});
        const [addressList, setAddressList] = useState([])
        const [orderPage, setOrderPage] = useState(1);
        const [dataLoad, setDataLoad] = useState(false);
        const [updatePage, setUpdatePage] = useState(1);

        const checkCustom_id = (arr) => {
            for (let i = 0; arr.length > i; i++) {
                return arr[i].customer_uid;
            }
        }
        useEffect(() => {
            return () => {
                dispatch({type: FETCH_ORDER_RESET})
                setDataLoad(false);
                setGetId(0);
                setLimit(3);
                setPayDate(Moment().format("YYYY-MM-DD"));
                setNextDay(Moment().format("YYYY-MM-DD"))
                setPayDay(Moment().format("YYYY-MM-DD"));
                setSearchList([])
                setIsUpdate({date: new Date().getTime(), page: 1});
                setAddressList([]);
                setOrderPage(1);
                setUpdatePage(1);
            }
        }, [])
        useEffect(() => {
            dispatch(fetchOrderLists_reg(orderPage, true, {tab1: "정기배송", tab2: "전체"}));
        }, [])
        useEffect(() => {
            if (orderlist.data_count < 3) { // 더보기 눌렀을때 데이터 중첩시키기
                return
            }
            dispatch(appendOrderLists_reg(orderPage, true, {tab1: "정기배송", tab2: "전체"}));
        }, [orderPage])
        useEffect(() => { // 내용 업데이트 필요할때
            if (orderlist.data_count < 1) {
                return
            }
            dispatch(updateOrderLists_reg(updatePage, true, {tab1: "정기배송", tab2: "전체"}));
        }, [isUpdate])
        useEffect(() => {
            if (orderlist.orderLists.length >= 2) {
                    setDataLoad(true);
            }

            if (orderlist.orderLists.length === 0) {
                    setDataLoad(false);
            }

        }, [orderlist])
        useEffect(() => {
            dispatch(fetchAllRegulars())
            axios.defaults.headers.common["cid"] = userInfo.cid;
            axios.defaults.headers.common["encoded"] = userInfo.encoded;
            axios.get(`${process.env.REACT_APP_API_URL}api/address`).then((res) => {
                setAddressList(res.data);
            })
        }, [dispatch, searchList, setVisibleCalendar, getId])


        //react-datepicker 라이브러리
        const DatePickerContainer = () => {
            const isWeekday = (date) => {
                const day = date.getDay(date);
                return day !== 0 && day !== 6;
            };

            const ExampleCustomInput = ({value, onClick}) => (
                <button className="example-custom-input" onClick={onClick}>
                    {value}
                </button>
            );

            let start_time = Moment(payDay, "YYYY-MM-DD").format('YYYY-MM-DD');
            let end_time = Moment(payDay, "YYYY-MM-DD").add(8, 'weeks').format('YYYY-MM-DD');

            if (Moment(start_time).isBefore(Moment())) {
                start_time = Moment().format('YYYY-MM-DD');
            }

            return (
                <DatePickerWrap>
                    <DatePicker
                        locale="ko"
                        selected={new Date(payDate)}
                        minDate={new Date(start_time)} //결제일
                        maxDate={new Date(end_time)} //결제일 + 10일
                        onChange={(date) => {setPayDate(date)}}
                        filterDate={isWeekday}
                        inline
                        dateFormatCalendar="YYY년&nbsp;&nbsp;MMM"
                        customInput={<ExampleCustomInput/>}
                    />
                </DatePickerWrap>
            );
        }

        const formatDate = (date) => {
            let setDate = new Date(date)
            var year = setDate.getFullYear();
            var month = ('0' + (setDate.getMonth() + 1)).slice(-2);
            var day = ('0' + setDate.getDate()).slice(-2);
            var dateString = year + '-' + month + '-' + day;
            return dateString.replaceAll("-", '.')
        }

        // 결제카드변경
        const [visibleCard, setVisibleCard] = useState(false);
        const [changeCard, setChangeCard] = useState(false);

        // 배송지변경
        const [Address, setAddress] = useState({
            open : false,
            regId: 0
        });

        // 결제일 변경
        const [visibleCalendar, setVisibleCalendar] = useState({
            visible : false,
            orderId : "",
            curDate : "",
            curCycle: "",
            render  : false,
        });
        const [changeListDate, setChangelistDate] = useState(null)
        const [changeDate, setChangeDate] = useState(false);

        const handleChangeDate = () => {
            setVisibleCalendar({...visibleCalendar, visible: false,});
            setChangeDate(true);
        }

        //결제일 변경요청
        const onVisiblePeriod = async (id, curDate, changeDate, curCycle, changeCycle) => {
            try {
                axios.defaults.headers.common["cid"] = userInfo.cid;
                axios.defaults.headers.common["encoded"] = userInfo.encoded;
                const res = await axios.post(`${process.env.REACT_APP_API_URL}api/regular/order/change/cycle`, {
                    "id"            : id,
                    "next_paid_date": curDate.slice(0, 10),
                    "changed_date"  : changeDate,
                    "cycle"         : curCycle,
                    "changed_cycle" : changeCycle
                })
                setVisibleCalendar({visibleCalendar, render: true})
            } catch (error) {
                console.log(error)
            }
            setVisibleCalendar({visibleCalendar, render: false})
        }

        // 배송주기 변경
        const currentCycle = orderlist.orderLists[getId]?.cycle
        const [visiblePeriod, setVisiblePeriod] = useState(false);
        const handleChangePeriod = async (id, curDate, changeDate, curCycle, optionCycle) => {
            try {
                axios.defaults.headers.common["cid"] = userInfo.cid;
                axios.defaults.headers.common["encoded"] = userInfo.encoded;
                const res = await axios.post(`${process.env.REACT_APP_API_URL}api/regular/order/change/cycle`, {
                    "id"            : id,
                    "next_paid_date": curDate.slice(0, 10),
                    "changed_date"  : changeDate,
                    "cycle"         : curCycle,
                    "changed_cycle" : optionCycle
                })
            } catch (error) {
                console.log(error)
            }
            setVisiblePeriod(false);
        }
        const [optionCycle, setOptionCycle] = useState(2);

        const handleSelect = (e) => {
            setOptionCycle(e.target.value);
        };

        const regularOptions = [
            {value: 2, label: "2주"},
            {value: 3, label: "3주"},
            {value: 4, label: "4주"},
            {value: 5, label: "5주"},
            {value: 6, label: "6주"},
            {value: 7, label: "7주"},
            {value: 8, label: "8주"}
        ]

        // 건너뛰기
        const [visiblePassPay, setVisiblePassPay] = useState({
            visible       : false,
            orderId       : "",
            next_paid_date: "",
            cycle         : ""
        });
        const [checkedPass, setCheckedPass] = useState(false);
        const onCheckedElement = () => {
            setCheckedPass(!checkedPass);
        }

        //건너뛰기 요청
        const handlePassPay = async (id, date, cycle) => {
            if (checkedPass) {
                try {
                    axios.defaults.headers.common["cid"] = userInfo.cid;
                    axios.defaults.headers.common["encoded"] = userInfo.encoded;

                    const res = await axios.post(`${process.env.REACT_APP_API_URL}api/regular/order/change/jump`, {
                        "id": id, "next_paid_date": date.slice(0, 10).replaceAll(), "cycle": cycle
                    })
                } catch (error) {
                    console.log(error)
                }
                setVisiblePassPay({...visiblePassPay, visible: false});
            } else {
                alert("이번 결제/배송 건너뛰기에 체크해주세요.")
            }
        }


        // 사료변경
        const [visibleChangePrd, setVisibleChangePrd] = useState({
            visible       : false,
            curProductId  : "",
            curProductName: "",
            orderId       : ""
        });
        const onSubmit = (data) => {
            handleSearch(data.keyword)
        }


        const handleSearch = (keyword) => {
            let value = String(keyword).toLowerCase()
            let result = [];
            result = regularInfo.filter((item) => {
                if (item.sell || item.display) {

                    return item.name.indexOf(value) > -1;
                }
            });
            setSearchList(result);
        }

        const [checkProduct, setCheckProduct] = useState({id: "", name: ""});
        const handleCheckRadio = (checked, id, name) => {
            const finditem = regularInfo.filter(v => v.id === id);
            if (finditem.length >= 1) {
                if (finditem[0].is_soldout) {
                    alert("상품이 품절 되었습니다")
                    return
                }
            }
            if (checked) {
                setCheckProduct({...checkProduct, id: id, name: name});
            }
        }

        const handleChangePrd = async (id, name) => {
            try {
                axios.defaults.headers.common["cid"] = userInfo.cid;
                axios.defaults.headers.common["encoded"] = userInfo.encoded;
                const res = await axios.post(`${process.env.REACT_APP_API_URL}api/regular/order/change/product`, {
                    "id"                  : visibleChangePrd.orderId,
                    "product_id"          : visibleChangePrd.curProductId,
                    "product_name"        : visibleChangePrd.curProductName,
                    "changed_product_id"  : id,
                    "changed_product_name": name,
                })
                console.log(res)
            } catch (error) {
                alert(`${error.response.data.message}`);
            }
            setSearchList([]);
            setVisibleChangePrd({...visibleChangePrd, visible: false});
            dispatch(fetchOrderLists(orderPage, true));
        }
        const moreView = () => {  //더보기 버튼
            setLimit(prevState => prevState + 3);
            setOrderPage(prevState => prevState + 1)
        }
        const MemoizedOrderList = React.memo(({orderlist, addressList, isMobile}) => {
            if (orderlist.orderLists.length < 1) {
                return <></>
            }
            const orderLists = orderlist.orderLists;
            if (orderLists.findIndex((v) => {
                return v.OrderProducts
            }) >= 0) { return <></>}
            return orderLists.map((list, idx) => {
                const start_time = Moment(list.treat_date, "YYYY-MM-DD").format('LLL');
                const end_time = Moment(list.next_paid_date, "YYYY-MM-DD").subtract(1, 'days').add(14, "h").format('LLL');
                const now = Moment().format('LLL')
                const isShowing = Moment(now).isBetween(start_time, end_time)
                const orderCurrentStatus = list.front_status
                const regId = list.id
                const orderDate = list.createdAt.slice(0, 10).replaceAll("-", ".")
                const nextYear = list.next_paid_date.slice(0, 4)
                const nextMon = list.next_paid_date.slice(5, 7)
                const nextDay = list.next_paid_date.slice(8, 10)
                const cancelYear = String(list.return_apply_date).slice(0, 4)
                const cancelMon = String(list.return_apply_date).slice(5, 7)
                const cancelDay = String(list.return_apply_date).slice(8, 10)

                return (
                    <li className={orderCurrentStatus === "해지완료" ? "order_lists_list canceled" : "order_lists_list"} key={list.merchant_uid}>
                        <ul>
                            <div className="order_lists_number">
                                <h4><span>ID&nbsp;&nbsp;
                                    <b>#{list.order_number}</b></span><span>(최초 결제일 <b>{orderDate}</b>)</span></h4>
                                <Link to={`/mypage/delivery/detail_list?id=${list.id}`}>
                                    <IoIosArrowForward size="23" color="#5F5F5F"/></Link>
                            </div>
                            <li className="order_list_content">
                                <div className="order_list_content_in">
                                    <Link to={`/products/details/regular/${list.product_id}`}>
                                        <div className="order_list_content_img">
                                            <div className="item_img">
                                                <img src={list.product_image} alt={list.product_name}/>
                                                {orderCurrentStatus === "해지완료" ? <span className="dimmed"></span> : null}
                                            </div>
                                            <div className="order_list_content_p">
                                                <span className="delivery_count">{list.total_ordered_times}회차</span>{isMobile &&
                                                <span className="order_state">
                                                    {orderCurrentStatus}
                                                </span>}
                                                <p className="item_name">{list.product_name}</p>
                                                <p className="order_price">
                                                    <b>{stringPrice(list.device_name ? list.paid_amount : list.order_price_amount)}</b>원 / {list.product_amount}개
                                                </p>
                                                {
                                                    orderCurrentStatus === "해지완료" || orderCurrentStatus === "해지신청" || orderCurrentStatus === "반납중" ?
                                                        <p className="delivery_info">해지 결제일 : {cancelYear}년 {cancelMon}월 {cancelDay}일</p> :
                                                        <p className="delivery_info">다음 예상 결제일 : {nextYear}년 {nextMon}월 {nextDay}일 ({list.total_ordered_times + 1}회차)
                                                            <br/>나의 배송 주기 : {list.cycle}주간격
                                                            <br/>다음 배송 사료 : {list.RegularProduct.name}
                                                        </p>
                                                }
                                            </div>
                                        </div>
                                    </Link>
                                    <span className="order_state">{orderCurrentStatus === "배송지연" ? <>{orderCurrentStatus}<br/><span>({list.delay_reason})</span></> : orderCurrentStatus}</span>
                                    <div className="order_control_btn">
                                        {isShowing && list.front_status === "배송완료" ?
                                            //나의 정기배송 관리 주기능 버튼
                                            <MemoizedDeliveryBtn orderCurrentStatus={orderCurrentStatus} addressList={addressList} list={list} isShowing={isShowing} regId={regId} idx={idx}/> : null}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                )
            })
        })

    
    //나의 정기배송 목록이 없을 때
    const NoList = React.memo(({orderList, dataLoad}) => {
        const [timer, setTimer] = useState(false)
        setTimeout(() => {
            setTimer(true)
        }, 500)
        if(orderList.length !== 0){
          return <></>
        }else if(dataLoad){
            return <></>
        }else{
            return(
                <>
                    {timer ? <div className="no_delivery">
                            <img src={no_delivery} alt="이미지"/>
                            <p>아직 이용중인 정기배송이 없어요.</p>
                    </div> : null}
                </>
            )
        }
    })


        //나의 정기배송 관리 주 기능버튼
        const MemoizedDeliveryBtn = React.memo(({orderCurrentStatus, addressList, list, isShowing, regId, idx}) => {
            switch (orderCurrentStatus) {
                case "배송완료":
                case "해지완료":
                case "반납중":
                case "배송중":
                case "배송준비중":
                case "배송지연":
                case "환불신청":
                case "환불보류":
                case "환불완료":
                case "결제완료":
                    return (
                        <>
                            {
                                addressList.length !== 0 ?
                                    <button onClick={() => {
                                        isShowing ?
                                            setAddress(prevState => ({...prevState, open: true, regId: regId})) :
                                            alert("다음 결제 예정일 하루 전 14시까지만 변경이 가능합니다.")
                                        setUpdatePage(list.page);
                                    }} className="control_btn">배송지 변경</button> :
                                    <button className="control_btn">배송지 변경</button>
                            }
                            <button onClick={() => {
                                isShowing ?
                                    setVisibleCalendar(prevState => ({
                                        ...prevState,
                                        visible : true,
                                        orderId : regId,
                                        curCycle: list.cycle,
                                        curDate : list.next_paid_date
                                    })) :
                                    alert("다음 결제 예정일 하루 전 14시까지만 변경이 가능합니다.");

                                setGetId(idx);
                                setUpdatePage(list.page);
                                setNextDay(list.next_paid_date.slice(0, 10).replaceAll("-", "."));
                                setPayDay(Moment(list.next_paid_date, "YYYY-MM-DD").subtract(list.cycle, "w").format("YYYY-MM-DD"))

                            }} className="control_btn">{isMobile && "다음 "}결제일 변경
                            </button>
                            <button onClick={() => {
                                isShowing ?
                                    setVisibleChangePrd(prevState => ({
                                        ...prevState,
                                        visible       : true,
                                        orderId       : regId,
                                        curProductId  : list.RegularProduct.id,
                                        curProductName: list.RegularProduct.name
                                    })) :
                                    alert("다음 결제 예정일 하루 전 14시까지만 변경이 가능합니다.");
                                setUpdatePage(list.page);
                            }} className="control_btn">사료변경
                            </button>
                        </>
                    )
                default:
                    return <></>
            }
        })

        const MemoizedMoreView = React.memo(({count, total, limit}) => {
            if (limit === total) {
                return <></>
            }
            if (count === limit) {
                return (
                    <div className="list_more_btn">
                        <button className="moreView" onClick={() => {moreView()}}>더보기<IoIosArrowDown/></button>
                    </div>
                )
            } else {
                return <></>
            }
        })

        return (
            <OrderLists>
                <div className="order_header mypage_title" id="order_header">
                    <div className="order_title ">
                        <h2>나의 정기배송</h2>
                    </div>
                    {orderlist.orderLists.length === 0 ? <button className="card_btn disabled">결제카드 변경</button> :
                        <button onClick={() => setVisibleCard(true)} className="card_btn">결제카드 변경</button>}
                </div>
                <div className="order_lists">
                    <ul>
                        {orderlist.orderLists.length !== 0 ? <MemoizedOrderList orderlist={orderlist} addressList={addressList} isMobile={isMobile}/> : <NoList orderList={orderlist.orderLists} dataLoad={dataLoad} />}
                    </ul>
                </div>
                <MemoizedMoreView count={orderlist.data_count} total={orderlist.total_count} limit={limit}/>
                {visibleCard &&
                    <ChangeCardDialog
                        visible={visibleCard}
                        setVisibleCard={setVisibleCard}
                        changeCard={changeCard}
                        setChangeCard={setChangeCard}
                        customer_id={checkCustom_id(orderlist.orderLists)}
                    />}
                {Address.open &&
                    <AddressDialog
                        visible={Address.open}
                        Address={Address}
                        setAddress={setAddress}
                        token={userInfo}
                        regId={Address.regId}
                        checkUser={checkUser}
                        isRegularAddress={true}
                        onClose={async (data) => {
                            const config = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    "cid"         : userInfo.cid,
                                    "encoded"     : userInfo.encoded,
                                },
                            }
                            const res = await axios.post(`${process.env.REACT_APP_API_URL}api/regular/order/change/address`, {
                                "id"            : Address.regId,
                                "buyer_addr"    : data.address,
                                "buyer_postcode": data.zipcode,
                                "buyer_name"    : data.name,
                                "buyer_tel"     : data.phone,
                                "buyer_email"   : data.email,
                            }, config).then((res) => {
                                setAddress({...Address, open: false})
                            })
                        }}
                    />}
                {visibleCalendar.visible &&
                    <ChangeDateDialog
                        visible={visibleCalendar.visible}
                        onConfirm={handleChangeDate}
                        confirmText="변경"
                        onCancel={() => setVisibleCalendar(false)}
                        cancelText="취소"
                        fullHeight
                    >
                        <p className="main_txt">다음 결제/배송 주기를{isMobile && <br/>}변경하고 싶어요 :)</p>
                        <div className="next_date">
                            <span>다음 결제 예상일</span>
                            <span>{nextDay}</span>
                        </div>
                        <div className="calendar_wrap">
                            <p>변경할 결제일을 선택해주세요.</p>
                            <DatePickerContainer/>
                        </div>
                        <div className="small_guide">
                            <DotGuide size="tiny" color="gray" text="결제일 변경은 다음 결제 1일전 14시까지 가능합니다."/>
                        </div>
                    </ChangeDateDialog>}
                {changeDate &&
                    <CompleteChangeDateDialog
                        visible={changeDate}
                        onConfirm={() => {
                            setVisiblePeriod(true);
                            setChangeDate(false);
                        }}
                        confirmText="변경"
                        onCancel={() => {
                            onVisiblePeriod(visibleCalendar.orderId, visibleCalendar.curDate,
                                formatDate(payDate), visibleCalendar.curCycle, visibleCalendar.curCycle);
                            setChangeDate(false);
                            setPayDate(Moment().format("YYYY-MM-DD"));
                            setIsUpdate(new Date().getTime());
                        }}
                        cancelText="유지"
                    >
                        <p className="main_txt">결제일을 변경했어요.</p>
                        <div className="next_date">
                            <p>다음 결제 예상일</p>
                            <p>{formatDate(payDate)}</p>
                        </div>
                        <p className="sub_txt">변경된 결제일 기준으로 <b>{currentCycle}주</b>마다 배송됩니다.</p>
                        <p className="sub_txt2"><b>{isPc && "배송 주기를"}변경하시겠습니까?</b></p>
                    </CompleteChangeDateDialog>}
                {visiblePeriod &&
                    <ChangePeriodDialog
                        visible={visiblePeriod}
                        onConfirm={() => {
                            handleChangePeriod(visibleCalendar.orderId,
                                visibleCalendar.curDate,
                                formatDate(payDate),
                                visibleCalendar.curCycle,
                                optionCycle)
                            setIsUpdate(new Date().getTime());
                        }}
                        confirmText="변경"
                        onCancel={() => {
                            onVisiblePeriod(visibleCalendar.orderId, visibleCalendar.curDate, formatDate(payDate), visibleCalendar.curCycle, visibleCalendar.curCycle);
                            setVisiblePeriod(false);
                            setPayDate(Moment().format("YYYY-MM-DD"));
                        }}
                        cancelText="취소"
                    >
                        <p className="main_txt">배송 주기를 선택해 주세요.</p>
                        <p className="sub_txt">현재 배송주기는 <b>{currentCycle}주</b>마다 배송됩니다.</p>
                        <select className="period_select" name="" id="" onChange={handleSelect}>
                            {regularOptions.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </ChangePeriodDialog>}
                {visiblePassPay &&
                    <PassPayDialog
                        visible={visiblePassPay.visible}
                        onConfirm={() => handlePassPay(visiblePassPay.orderId, visiblePassPay.next_paid_date, visiblePassPay.cycle)}
                        confirmText="변경"
                        onCancel={() => setVisiblePassPay({...visiblePassPay, visible: false})}
                        cancelText="취소"
                    >
                        <p className="main_txt">결제/배송을 건너뛰고 싶어요 :)</p>
                        <Checkbox
                            id="chk_pass"
                            label="이번 결제/배송 건너뛰기"
                            onChange={onCheckedElement}
                            checked={checkedPass ? true : false}
                            size="small"
                        />
                    </PassPayDialog>}
                {visibleChangePrd &&
                    <ChangePrdDialog
                        visible={visibleChangePrd.visible}
                        title="사료를 변경하고 싶어요 :)"
                        fullHeight
                        fixHeight
                    >
                        <div className="prd_search_wrap">
                            <div className="search_wrap">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <input type="text" placeholder="변경할 사료의 브랜드를 검색해주세요." {...register("keyword")}  />
                                    <button type="submit"><FiSearch size="30"/></button>
                                </form>
                            </div>
                            <div className="prd_list">
                                <ul className="custom_scroll_nobg">
                                    {searchList.length ? searchList.map((list, idx) => {
                                        return (
                                            <li key={idx}>
                                                <div className="checks full_check">
                                                    <input type="checkbox" name="checkProduct" id={list.id} onChange={(e) => {handleCheckRadio(e.target.checked, list.id, list.name)}} checked={checkProduct.id === list.id ? true : false}/>
                                                    <label htmlFor={list.id}></label>
                                                </div>
                                                <div className="item_img">
                                                    <img src={list.main_img} alt={list.name}/>
                                                </div>
                                                <div className="item_info">
                                                    <p className="item_name">[{list.brand}]&nbsp;{list.name}</p>
                                                    <p className="item_price">{stringPrice(list.price)}원</p>
                                                </div>
                                            </li>
                                        )
                                    }) : <li style={{justifyContent: "center"}}>
                                        <p className="no_data">검색 결과가 없습니다.</p>
                                    </li>}
                                </ul>
                            </div>
                        </div>
                        <div className="guide_area">
                            <DotGuide size="tiny" color="gray" text="사료가격은 공급사 정책에 따라 달라질 수 있습니다."/>
                            <DotGuide size="tiny" color="gray" text="다음 결제일에 변경된 사료로 결제 및 배송됩니다."/>
                            <DotGuide size="tiny" color="gray" text="결제 3일 전 결제 금액을 알려드립니다."/>
                            <DotGuide size="tiny" color="gray" text="사료변경은 다음 결제 1일전 14시까지 가능합니다."/>
                        </div>
                        <div className="button_wrap_del">
                            <Button onClick={() => setVisibleChangePrd({
                                ...visibleChangePrd,
                                visible: false
                            })} color="white" outline>취소</Button>
                            <Button onClick={() => {handleChangePrd(checkProduct.id, checkProduct.name)}}>변경</Button>
                        </div>
                    </ChangePrdDialog>}
            </OrderLists>
        );
    }
;

const OrderLists = styled.div`
  max-width: 1230px;
  width: 100%;
  @media only screen and (max-width: 960px) {
    margin-top: 0 !important;
  }

  & .order_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 16px;
    border-bottom: 1px solid #555;

    & .order_title {
      h2 {
        font-size: 24px;
        font-weight: 400;

        span {
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: #515355;
          margin-left: 20px;
        }
      }

    }

    & .card_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 120px;
      height: 36px;
      border-radius: 4px;
      background: #1EA0FA;
      color: #fff;
      font-size: 13px;
      font-weight: 700;
    }

    & .card_btn.disabled {
      background: #ddd;
    }

    @media only screen and (max-width: 960px) {
      display: block;
      padding-bottom: 30px;
      border-bottom: none;
      & .order_title {
        h2 {
          font-size: 1.375rem;
          line-height: 33px;
          color: #222;
          text-align: center;

          span {
            display: none;
          }
        }
      }

      & .card_btn {
        width: 110px;
        margin: 40px auto 0;
        background: none;
        border: 1px solid #1EA0FA;
        color: #1EA0FA;
        font-weight: 500;
      }
    }
  }

  & .order_lists {
    & ul > li.order_lists_list {
      margin: 30px 0;
      box-sizing: border-box;

      ul {
        border: 1px solid #ccc;

        & li.order_list_content {
          padding: 0 25px;

          &:not(:last-child) {
            & .order_list_content_in {
              border-bottom: 1px solid #E1E2E3;
            }
          }

          & .order_list_content_in {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px 0;

            & a {
              width: 100%;
              height: 100%;
            }

            & .order_list_content_img {
              display: flex;
              width: calc(100% - 250px);

              & .item_img {
                position: relative;
                overflow: hidden;
                flex: 160px 0 0;
                height: 160px;

                &::after {
                  content: "";
                  display: block;
                  padding-bottom: 100%;
                }

                & img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  height: 100%;
                  width: auto;
                  height: auto;
                  width: 100%;
                }
              }

              & .order_list_content_p {
                padding-top: 5px;
                font-size: 14px;
                color: #515355;
                padding-left: 1.875rem;

                & .delivery_count {
                  display: inline-block;
                  padding: 3px 12px;
                  border-radius: 35px;
                  background: #1EA0FA;
                  color: #fff;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 21px;
                }

                p.item_name {
                  margin-top: 10px;
                  font-size: 18px;
                  color: #222;
                  line-height: 27px;
                  font-weight: 500;
                }

                p.order_price {
                  margin-top: 4px;
                  font-size: 16px;
                  line-height: 24px;
                  color: #515355;

                  & b {
                    font-weight: 500;
                  }
                }

                p.delivery_info {
                  margin-top: 14px;
                  font-size: 14px;
                  color: #888;
                  line-height: 21px;
                }
              }
            }

            & .order_state {
              width: 250px;
              padding-right: 100px;
              //padding-left: 20px;
              color: #222;
              text-align: center;
            }

            & .order_control_btn {
              flex-basis: 130px;
              margin-left: auto;

              & .control_btn {
                width: 100px;
                height: 30px;
                font-size: 13px;
                color: #222;
                margin: 3px 0;
                border: 1px solid #ccc;
                box-sizing: border-box;
                border-radius: 40px;
              }

              & .control_btn.order_review {
                border: 1px solid #1EA0FA;
                color: #1EA0FA;
              }
            }
          }
        }
      }

      & .order_lists_number {
        display: flex;
        align-items: center;
        background-color: #F7F7F7;
        padding: 15px 25px 15px 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;

        & h4 {
          & span:first-child {
            font-size: 1rem;
            font-weight: 400;
            line-height: 24px;
            color: #515355;

            & b {
              color: #222;
            }
          }

          & span:last-child {
            display: inline-block;
            margin-left: 5px;
            font-size: 0.875rem;
            color: #888;
          }
        }

        & a {
          margin-left: auto;
        }
      }

      &.canceled {
        ul {
          & li.order_list_content {
            & .order_list_content_in {
              & .order_list_content_img {
                & .order_list_content_p {
                  font-size: 14px;
                  color: #888;

                  & .delivery_count {
                    background: #515355;
                  }

                  p.item_name {
                    color: #888;
                  }

                  p.order_price {
                    color: #888;
                  }
                }
              }

              & .order_state {
                color: #888;
              }
            }
          }
        }

        & .dimmed {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.6);
        }
      }
    }

    @media only screen and (max-width: 960px) {
      background: #f6f6f6;
      padding-bottom: 8px;
      &:first-child {
        padding-top: 0;
      }

      & ul > li.order_lists_list {
        margin: 0;

        &:not(:first-child) {
          margin-top: 8px;
        }

        background: #fff;

        ul {
          border: none;

          & .order_lists_number {
            padding: 18px 12px 18px 15px;
            border-top: 1px solid #eee;
            border-bottom: 1px solid #eee;
            background: #F7F7F7;

            & h4 {
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              & span:first-child {
                display: flex;
                align-items: center;
                margin-right: 6px;
                font-size: 0.938rem;
                font-weight: 400;
                line-height: 24px;
                color: #515355;
                font-weight: 500;
                flex-wrap: nowrap;

                & b {
                  color: #222;
                  font-size: 1rem;
                }
              }

              & span:last-child {
                margin: 0;
                font-size: 0.75rem;
                color: #888;
                font-weight: 400;
                line-height: 18px;
                white-space: nowrap;
              }
            }

            & svg {
              width: 20px;
              width: 20px;
              color: #888 !important;
            }
          }

          & li.order_list_content {
            display: none;
            padding: 0;

            &:nth-child(2) {
              display: block;
            }

            &:not(:last-child) {
              & .order_list_content_in {
                border-bottom: none;
              }
            }

            & .order_list_content_in {
              display: block;
              padding: 0;

              & h5 {
                display: inline-block;
                width: 90px;
                color: #515355;
                font-weight: 400;
                line-height: 22px;
              }

              & .order_list_content_img {
                width: auto;
                flex-basis: 100%;
                display: flex;
                justify-content: flex-start;
                padding: 15px 15px 20px 15px;

                & .item_img {
                  flex: 80px 0 0;
                  height: 80px;
                }

                & .order_list_content_p {
                  padding: 0 0 0 10px;
                  font-size: 14px;
                  color: #515355;

                  & .delivery_count {
                    padding: 2.5px 10px;
                    border-radius: 30px;
                    font-size: 12px;
                    line-height: 18px;
                  }

                  p.item_name {
                    margin-top: 8px;
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                  }

                  p.order_price {
                    font-size: 13px;
                    line-height: 19.5px;
                    color: #222;

                    & b {
                      font-weight: 700;
                    }
                  }

                  p.delivery_info {
                    margin-top: 4px;
                    font-size: 13px;
                    line-height: 19.5px;
                  }

                  & .order_state {
                    display: inline-block;
                    width: auto;
                    padding-left: 0;
                    margin-left: 6px;
                    font-weight: 500;
                  }
                }
              }

              & .order_state {
                display: none;
              }

              & .order_control_btn {
                display: flex;
                width: 100%;
                flex-basis: 100%;
                flex-wrap: wrap;
                border-bottom: 1px solid #e5e5e5;

                & .control_btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 50%;
                  height: 50px;
                  font-size: 14px;
                  color: #515355;
                  margin: 0;
                  border: none;
                  border-top: 1px solid #e5e5e5;
                  box-sizing: border-box;
                  border-radius: 0;

                  &:nth-child(2n-1) {
                    border-right: 1px solid #e5e5e5;
                  }

                  &:nth-child(3n) {
                    width: 100%;
                    border-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  & .dialog_block {
    padding: 50px 24px;
    @media only screen and (max-width: 960px) {
      padding: 40px 20px 30px;
    }
  }

  & .close_btn {
    display: none;
  }

  & .button_wrap_del {
    margin-top: 30px;
    @media only screen and (max-width: 960px) {
      margin-top: 40px;
    }
  }

  & .dialog_inner {
    & .main_txt {
      margin-bottom: 40px;
      font-size: 22px;
      line-height: 33px;
      color: #222;
      font-weight: 500;
    }

    & .sub_txt p {
      font-size: 1rem;
      color: #222;
      line-height: 24px;
      text-align: left;

      & span {
        line-height: 24px;
      }
    }

    @media only screen and (max-width: 960px) {
      & .main_txt {
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 30px;
      }

      & .sub_txt p {
        font-size: 1rem;
      }
    }
  }


  & .no_delivery {
    padding: 150px 0;
    text-align: center;

    & img {
      height: 160px;
      margin-bottom: 22px;
    }

    & p {
      color: #888;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
    }

    @media only screen and (max-width: 960px) {
      padding: 100px 0;
      & img {
        height: 140px;
      }

      & p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`

const ChangeDateDialog = styled(Dialog)`
  & .close {
    display: none;
  }

  & .dialog_wrap {
    padding: 30px;

    & .main_txt {
      margin-bottom: 20px;
      text-align: center;
    }

    & .next_date {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 57px;
      margin-bottom: 10px;
      border: 1px solid #cccccc;

      & span:first-child {
        margin-right: 10px;
        font-size: 14px;
        color: #0065AB;
        font-weight: 500;
      }

      & span:last-child {
        font-weight: 500;;
        font-size: 16px;
        color: #222222;
      }
    }

    & .calendar_wrap {
      padding: 20px 0;
      background: #f6f6f6;
      text-align: center;

      & > p {
        margin-bottom: 14px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #222222;
      }
    }

    & .small_guide {
      margin-top: 10px;

      & > p {
        justify-content: flex-end;
      }
    }

    & .ButtonWrap > div {
      width: 229px;
      font-weight: 700;

      &:last-child {
        margin-left: 5px;
      }
    }

    @media only screen and (max-width: 960px) {
      overflow-y: auto;
      padding: 40px 0 30px;
      & .dialog_inner {
        padding: 0 !important;
      }

      & .main_txt {
        font-size: 21px;
        font-weight: 500;
        line-height: 31.5px;
        color: #000;
      }

      & .next_date {
        flex-direction: column;
        height: 88px;
        width: calc(100% - 30px);
        margin: 0 auto 10px;
        border: 1px solid #ddd;
        border-radius: 5px;

        & span {
          display: block;
        }

        & span:first-child {
          margin: 0 0 4px 0;
          font-size: 14px;
          line-height: 21px;
        }

        & span:last-child {
          font-size: 18px;
          line-height: 27px;
        }
      }

      & .calendar_wrap {
        padding: 30px 0;

        & > p {
          font-size: 15px;
          line-height: 22.5px;
        }
      }

      & .small_guide {
        margin-right: 15px;

        & > p {
          justify-content: flex-end;
        }
      }

      & .ButtonWrap {
        padding: 0 15px;
        margin-top: 30px;

        & > div {
          font-weight: 700;

          &:last-child {
            margin-left: 5px;
          }
        }
      }
    }
  }
`
const DatePickerWrap = styled.div`
  & .react-datepicker {
    width: 310px;
    border-radius: 0;
    border: 1px solid #ccc;

    & .react-datepicker__month-container {
      float: none;
    }
  }

  /* 월 이동 버튼 */

  & .react-datepicker__current-month {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #333;
  }

  & .react-datepicker__navigation {
    top: 12px;
  }

  & .react-datepicker__navigation-icon {
    display: inline-block;
    width: 8px;
    height: 13px;
  }

  & .react-datepicker__navigation-icon::before {
    display: none;
  }

  & .react-datepicker__navigation-icon--previous,
  & .react-datepicker__navigation-icon--next {
    background-image: url(${arr_my_nav});
  }

  & .react-datepicker__navigation--next {
    right: 8px;
  }

  & .react-datepicker__navigation-icon--previous {
    transform: rotate(180deg);
    left: 8px;
  }

  /* 연도,월,요일 영역*/

  & .react-datepicker__header {
    padding: 15px 0 0 0;
    background: #fff;
    border-bottom: none;

    & .react-datepicker__current-month {
      margin-bottom: 19px;
    }

    & .react-datepicker__day-names {
      margin: 0 0 2px 0;
    }
  }

  /*요일, 날짜*/

  & .react-datepicker__day-name,
  & .react-datepicker__day,
  & .react-datepicker__time-name {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin: 0;
    color: #333;
    font-size: 15px;
    line-height: 1;

    &:not(:first-child) {
      margin-left: 11px;
    }

    &:hover {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
  }

  & .react-datepicker__day--disabled {
    color: #999;
  }

  & .react-datepicker__day--outside-month {
    color: #999;
  }

  & .react-datepicker__day--today {
    width: 28px;
    height: 28px;
    background: rgba(30, 160, 250, 0.15);
    border-radius: 50%;
  }

  & .react-datepicker__day--selected {
    position: relative;
    width: 28px;
    height: 28px;
    background: #1EA0FA;
    border-radius: 50%;
    color: #fff;
    font-weight: 500;
  }

  & .react-datepicker__month {
    margin: 0;
    padding-bottom: 20px;

    & .react-datepicker__week {
      margin-top: 8px;
    }
  }
`

const CompleteChangeDateDialog = styled(Dialog)`
  & .close {
    display: none;
  }

  & .dialog_wrap {
    padding: 50px 30px 30px 30px;
    text-align: center;

    & .main_txt {
      margin-bottom: 30px;
    }

    & .next_date {
      padding: 20px 0;
      margin-bottom: 30px;
      background: #F7F7F7;

      & p:first-child {
        margin-bottom: 5px;
        font-size: 14px;
        color: #0065AB;
        font-weight: 500;
        line-height: 21px;
      }

      & p:last-child {
        font-weight: 500;
        font-size: 18px;
        color: #222222;
        line-height: 27px;
      }
    }

    & .sub_txt {
      margin-bottom: 4px;
      font-size: 15px;
      line-height: 22px;
      color: #222;
    }

    & .sub_txt2 {
      font-size: 16px;
      line-height: 24px;
      color: #222;
    }

    & .ButtonWrap {
      margin-top: 40px;

      & > div {
        width: 229px;
        font-weight: 700;

        &:last-child {
          margin-left: 5px;
        }
      }
    }

    @media only screen and (max-width: 960px) {
      padding: 40px 27px 30px;
      & .next_date {
        padding: 15px 0;
      }

      & .sub_txt {
        margin-bottom: 4px;
        font-size: 15px;
        line-height: 22px;
        color: #222;
      }

      & .sub_txt2 {
        font-size: 16px;
        line-height: 24px;
      }

      & .ButtonWrap {
        & > div {
          width: calc(100% / 2);
          font-weight: 700;

          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }
`

const ChangePeriodDialog = styled(Dialog)`
  & .close {
    display: none;
  }

  & .dialog_wrap {
    padding: 50px 30px 30px 30px;
    text-align: center;

    & .main_txt {
      margin-bottom: 50px;
    }

    & .sub_txt {
      margin-bottom: 16px;
      font-size: 15px;
      line-height: 22px;
      color: #222;
    }

    & .period_select {
      width: 300px;
      height: 43px;
      padding: 11px 15px;
      margin: auto;
      font-size: 14px;
      color: #515355;
      line-height: 21px;
    }

    & .ButtonWrap {
      margin-top: 60px;

      & > div {
        width: 229px;
        font-weight: 700;

        &:last-child {
          margin-left: 5px;
        }
      }
    }

    @media only screen and (max-width: 960px) {
      padding: 40px 27px 30px;
      & .main_txt {
        margin-bottom: 40px;
      }

      & .sub_txt {
        font-size: 14px;
        line-height: 21px;
      }

      & .period_select {
        width: 100%;
      }

      & .ButtonWrap {
        margin-top: 70px;

        & > div {
          width: calc(100% / 2);
          font-weight: 700;

          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }
`

const PassPayDialog = styled(Dialog)`
  text-align: center;

  & .close {
    display: none;
  }

  & .dialog_wrap {
    padding: 50px 0;

    & .dialog_inner {
      & .main_txt {
        margin-bottom: 60px;
      }

      & > div {
        justify-content: center;
      }
    }

    & .ButtonWrap {
      margin-top: 60px;

      & > div {
        width: 160px;

        &:last-child {
          margin-left: 5px;
        }
      }
    }

    @media only screen and (max-width: 960px) {
      padding: 50px 0 30px;
      & .dialog_inner {
        & .main_txt {
          margin-bottom: 52px;
        }
      }

      & .ButtonWrap {
        margin-top: 52px;

        & > div {
          width: 140px;

          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }
`;

const ChangePrdDialog = styled(Dialog)`
  & .dialog_wrap {
    max-height: 90vh;
    padding: 25px 30px 30px;

    & h3 {
      margin-bottom: 30px;
    }

    & .close {
      display: none;
    }

    & .main_txt {
      margin-bottom: 30px;
      text-align: center;
    }

    & .prd_search_wrap {
      margin-bottom: 10px;
      background: #f9f9f9;

      & .search_wrap {
        position: relative;
        padding: 30px;

        & input {
          height: 40px;
          padding: 3px 30px 13px 0;
          background: none;
          border: none;
          border-bottom: 2px solid #222;
          font-size: 16px;

          &::placeholder {
            color: #515355;
            font-size: 16px;
          }
        }

        & button {
          position: absolute;
          right: 30px;
          top: 30px;
        }
      }

      & .prd_list {
        padding: 0 10px 30px 10px;

        & ul {
          overflow-y: auto;
          padding: 0 16px 0 20px;
          height: 306px;

          & li {
            display: flex;

            &:not(:first-child) {
              margin-top: 24px;
            }

            & .item_img {
              position: relative;
              overflow: hidden;
              flex: 80px 0 0;
              height: 80px;

              &::after {
                content: "";
                display: block;
                padding-bottom: 100%;
              }

              & img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 100%;
                width: auto;
                height: auto;
                width: 100%;
              }
            }

            & .item_info {
              margin-left: 10px;
              font-size: 15px;
              line-height: 22.5px;

              & .item_name {
                margin-bottom: 6px;
                word-break: break-all;
              }

              & .item_price {
                color: #515355;
              }
            }

            & .no_data {
              margin-top: 150px;
              color: #999;
            }
          }
        }
      }
    }

    & .guide_area {
      padding: 0 15px;
    }

    @media only screen and (max-width: 960px) {
      overflow-y: auto;
      max-height: 100vh;
      padding: 0;
      & h3 {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 27px;
      }

      & .dialog_inner {
        padding: 30px 0;
      }

      & .main_txt {
        padding: 16px 0;
        margin-bottom: 30px;
        border-bottom: 1px solid #F2F4F6;
        text-align: center;
        line-height: 27px;
        font-size: 18px;
      }

      & .prd_search_wrap {
        width: calc(100% - 30px);
        margin: 0 auto 10px;
        background: #f9f9f9;

        & .search_wrap {
          position: relative;
          padding: 15px 15px 22px 15px;

          & button {
            right: 15px;
            top: 15px;
          }
        }

        & .prd_list {
          padding: 0 7px 14px 7px;

          & ul {
            overflow-y: auto;
            padding: 0 4px 0 8px;
            min-height: 170px;
            max-height: 345px;
            height: auto;

            & li {
              &:not(:first-child) {
                margin-top: 14px;
              }

              & .item_info {
                & p {
                  font-size: 14px;
                  line-height: 20px;
                }

                & .item_name {
                  margin-bottom: 4px;
                  word-break: break-all;
                }
              }

              & .no_data {
                margin-top: 70px;
                color: #999;
              }
            }
          }
        }
      }

      & .button_wrap_del {
        padding: 0 15px;

        & div {
          height: 50px;
          width: calc((100% - 5px) / 2);
          font-size: 1rem;
          font-weight: 700;
        }
      }
    }
  }

  & .dot_guide {
    & span {
      font-size: 13px;
      line-height: 19.5px;
      color: #515355;
    }

    @media only screen and (max-width: 960px) {
      padding: 0 15px;
    }
  }

  & .ButtonWrap {
    & > div {
      width: calc((100% - 5px) / 2);
      font-weight: 700;

      &:last-child {
        margin-left: 5px;
      }
    }

    @media only screen and (max-width: 960px) {

      padding: 0 15px;
      margin-top: 30px;
      & > div {
        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
`

export default MyDelivery;