import React from 'react'
import App from './App'
import {BrowserRouter as Router} from 'react-router-dom'
import ScrollTop from "./pages/components/ScrollTop";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import TopButton from "./pages/components/TopButton";
import Chat from "./pages/components/Chat";

const Home = () => {

    return (
        <Router>
            <ScrollTop />
            <Header />
            <App />
            <Footer />
            <TopButton />
            <Chat />
        </Router>
    );
}
export default Home;
