import React,{useState} from "react";
import styled from "styled-components";
import 'rc-rate/assets/index.css';
import Button from './Button';
import Dialog from './Dialog';
import { useMediaQuery } from 'react-responsive';
import sublist_open from "images/sublist_open.png";
import m_sublist_open from "images/m/m_sublist_open.png";
import axios from "axios";
import {useSelector} from "react-redux";

function OrderCancelPopup({setOrderCancel, OrderCancel, visible, onCancel, id, info, idx }) {
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const userInfo = useSelector((state) => state.userLogin.userInfo.data || "");
  const [reason, setReason] = useState({
    select: "",
    textArea: "",
    refund_holder: "",
    refund_account : "",
    refund_bank: ""
  });
  const handleSelect = (e) => {
    setReason({...reason, select: e.target.value});
  };
  const handleSelectBank = (e) => {
      setReason({...reason, refund_bank: e.target.value})
  }
  const handleInput = (e) => {
    setReason({...reason, textArea: e.target.value});
  }

 const handleInput2 = (e) => {
    setReason({...reason, refund_holder: e.target.value});
 }

 const handleInput3 = (e) => {
    setReason({...reason, refund_account: e.target.value});
 }


  const selectReasonOpt = [
    {value: "", label: "취소 사유를 선택해 주세요."},
    {value: "단순 변심", label: "단순 변심"},
    {value: "상품 및 옵션 변경", label: "상품 및 옵션 변경"},
    {value: "추가 주문", label: "추가 주문"},
    {value: "결제 수단 변경", label: "결제 수단 변경"},
    {value: "상품 가격", label: "상품 가격"},
    {value: "기타", label: "기타"},
  ]
    const handleCancel = async () => {
        let idx2 = [];
        if (!idx) {
            for (let i = 0; i < info.product.length; i++) {
                idx2.push(i)
            }
        } else {

            idx2 = [info.product[idx].id]
        }
        if(info.front_status === "입금전"){
            try{
                axios.defaults.headers.common["cid"] = userInfo.cid;
                axios.defaults.headers.common["encoded"] = userInfo.encoded;
                const response = await axios.post(`${process.env.REACT_APP_API_URL}api/payment/delete/vbank`, {
                    "mileage_spent_amount": info.mileage_spent_amount,
                    "merchant_uid" : info.merchant_uid,
                    "imp_uid": info.imp_uid,
                    "order_id": info.id,
                    "return_reason" : `${reason.select}`,
                    "reason_content" : `${reason.textArea}`,
                })
                document.location.href = "/mypage/myorders"
            }catch(e){
                console.log(e)
            }
        }else{
            try{
                axios.defaults.headers.common["cid"] = userInfo.cid;
                axios.defaults.headers.common["encoded"] = userInfo.encoded;
                const res = await axios.post(`${process.env.REACT_APP_API_URL}api/payment/cancel`,{
                    "order_id": info.id,
                    "product_ids": idx2,
                    "data": {
                        "imp_uid" : info.imp_uid,
                        "merchant_uid" : info.merchant_uid,
                        "mileage_spent_amount": info.mileage_spent_amount,
                        "amount" : info.actual_payment_amount,
                        "tax_free" : null,
                        "checksum" : null,
                        "return_reason" : `${reason.select}`,
                        "reason_content" : `${reason.textArea}`,
                        "refund_holder" : `${reason.refund_holder}`,
                        "refund_bank" : `${reason.refund_bank}`,
                        "refund_account" : `${reason.refund_account}`,
                        "refund_tel" : null
                    }
                })
                document.location.href = "/mypage/myorders"
            }catch(error){
                console.log(error)
            }
        }
  }

	return (
    <OrderCancelDialog visible={visible}>
      <Dialog
        title="주문 취소"
        cancelText="닫기"
        onCancel={onCancel}
        fullHeight
        fixHeight
        visible={visible}
      >
        <div className="step_box">
          <p>취소 사유 선택</p>
          <select className="period_select" required onChange={handleSelect} >
            {selectReasonOpt.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
          <textarea placeholder="[필수] 상세사유를 입력해 주세요." rows="5" onChange={handleInput} ></textarea>
        </div>
        {info.pay_method === "card" || info.front_status === "입금전" ?  null : <>
          {isMobile && <p className="noArea"></p>}
          <div className="step_box">
            <p>환불 계좌 정보 입력</p>
            <p className="refund_desc">원결제 방법을 통한 환불 불가시 입력해주신 계좌로 환불됩니다.</p>
            <div className="refund_account">
              <div>
                <p>은행명</p>
                <p>
                  <select required onChange={handleSelectBank}>
                      <option disabled selected value="">은행을 선택해 주세요.</option>
                      <option value="국민">KB국민은행</option>
                      <option value="SC제일은행">SC제일은행</option>
                      <option value="경남은행">경남은행</option>
                      <option value="광주은행">광주은행</option>
                      <option value="기업은행">기업은행</option>
                      <option value="농협">농협</option>
                      <option value="대구은행">대구은행</option>
                      <option value="부산은행">부산은행</option>
                      <option value="산업은행">산업은행</option>
                      <option value="새마을금고">새마을금고</option>
                      <option value="수협">수협</option>
                      <option value="신한은행">신한은행</option>
                      <option value="신협">신협</option>
                      <option value="외환은행">외환은행</option>
                      <option value="우리은행">우리은행</option>
                      <option value="우체국">우체국</option>
                      <option value="축협">축협</option>
                      <option value="카카오뱅크">카카오뱅크</option>
                      <option value="케이뱅크">케이뱅크</option>
                      <option value="하나은행">하나은행</option>
                      <option value="한국씨티은행">한국씨티은행</option>
                      <option value="토스뱅크">토스뱅크</option>
                  </select>
                </p>
              </div>
              <div><p>계좌번호</p><p><input type="number" name="bank_number" onChange={handleInput3} /></p></div>
              <div><p>예금주</p><p><input type="text" name="bank_owner" onChange={handleInput2} /></p></div>
            </div>
          </div>
        </>}
        <div className="button_wrap">
            {info.pay_method === "card" || info.front_status === "입금전"   ? <React.Fragment>
                {reason.select !== "" && reason.textArea !== "" ? <Button size="small" onClick={() => {setOrderCancel({...OrderCancel, visible: false}); handleCancel()}}>취소하기</Button> :
                    <Button color="gray" size="small">취소하기</Button>}
            </React.Fragment> : <React.Fragment>
                {reason.select !== "" && reason.textArea !== "" && reason.refund_holder !== "" && reason.refund_bank !== "" && reason.refund_account !== "" ?
                    <Button size="small" onClick={() => {setOrderCancel({...OrderCancel, visible: false}); handleCancel()}}>취소하기</Button> :
                    <Button color="gray" size="small">취소하기</Button>}
            </React.Fragment>}

        </div>
      </Dialog>
    </OrderCancelDialog>
	);
}

const OrderCancelDialog = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};

  & .dialog_wrap {
    overflow-y: auto;
    max-height: 90vh;
    padding: 50px 50px 30px 50px;
    & h3 {
      margin-bottom: 10px;
    }
  }

  & .step_box:not(:first-child) {
    margin-top: 30px;
  }

  & .step_box {
    & > p {
      margin-bottom: 10px;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;
    }
    & select {
      margin-bottom: 10px;
      font-size: 0.938rem;
      line-height: 22.5px;
      padding: 10px 15px;
      border: 1px solid #ddd;
      background: url(${sublist_open}) no-repeat right 15px center;
    }
    & textarea {
      height: 120px;
      font-size: 0.938rem;
      line-height: 22.5px;
      padding: 10px 15px;
      border: 1px solid #ddd;
      &::placeholder {
        font-size: 0.938rem;
        line-height: 22.5px;
      }
    }

    & .refund_desc {
      margin-top: 4px;
      font-size: 0.813rem;
      line-height: 19.5px;
      font-weight: 400;
      color: #888;
    }

    & .refund_account {
      margin-top: 16px;
      & > div {
        display: flex;
        align-items: center;
        &:not(:first-child) {
          margin-top: 10px;
        }
        & p:first-child {
          width: 95px;
          font-size: 0.938rem;
          line-height: 22px;
        }
        & p:last-child {
          width: calc(100% - 95px);
          & input {
            padding: 10px 15px;
            border: 1px solid #ddd;
            font-size: 15px;
            line-height: 21px;
          }
          & select {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  & .button_wrap {
    margin-top: 40px;
    text-align: center;
    & div {
        width: 160px;
        border-radius: 4px;
    }
  }

  @media only screen and (max-width: 960px) {
    & .dialog_wrap {
      overflow-y: hidden;
      max-height: 100vh;
      padding: 0;
      & h3 {
        margin-bottom: 0;
      }
      & .dialog_inner {
        overflow-y: auto;
        max-height: calc(100vh - 60px);
        padding: 0 0 30px 0;
      }
    }

    & .step_box:not(:first-child) {
      margin-top: 0;
    }

    & .step_box {
      display: flex;
      flex-direction: column;
      padding: 30px 15px;
      & > p {
        margin-bottom: 20px;
        font-size: 1.063rem;
        line-height: 25.5px;
      }
      & select {
        border: 1px solid #ccc;
        background: url(${m_sublist_open}) no-repeat right 15px center;
        background-size: 12px;
      }
      & textarea {
        border: 1px solid #ccc;
      }
      & .refund_desc {
        order: 4;
        margin-top: 10px;
        font-size: 0.75rem;
        letter-spacing: -0.02em;
        line-height: 18px;
      }
      & .refund_account {
        margin-top: 0;
        & div {
          flex-direction: column;
          align-items: flex-start;
          & p:first-child {
            width: 100%;
          }
          & p:last-child {
            width: 100%;
            margin-top: 10px;
            & input {
              border: 1px solid #ccc;
            }
          }
        }
      }
    }
    & .button_wrap {
      margin: 0 auto;
      & div {
          width: 170px;
          height: 50px;
          font-weight: 700;
      }
    }
  }
`

export default OrderCancelPopup;