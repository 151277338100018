import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import list_open from "images/m/m_list_open.png";
import list_close from "images/m/m_list_close.png";
import sublist_open from "images/m/m_sublist_open.png";
import sublist_close from "images/m/m_sublist_close.png";
import $ from 'jquery';
import {useSelector, useDispatch} from "react-redux";
import {getCategoryId} from "../../actions/categoryActions";

const LeftNavigation = styled.div`
  overflow-y: hidden;
  height: calc(100% - 135px);
  & .gnb_wrapper {
      overflow-y: auto;
      height: 100%;
      width: 100%;

    & .gnb_wrap {
      display: block;
      padding: 0 15px;
      flex-direction: column;

      & > li {
        width: 100%;
        border-bottom: 1px solid #ddd;
        & .menu {
          display: block;
          width: 100%;
          padding: 24px 10px;
          line-height: 23px;
          background: url(${list_open}) no-repeat right 10px top 32px;
          background-size: 14px auto;
          font-weight: 500;
          font-size: 1.125rem;
          transition: .3s;
          cursor: pointer;
          &.active {
            padding-bottom: 14px;
            background: url(${list_close}) no-repeat right 10px top 32px;
            background-size: 14px auto;
          }
        }
      }

      & .gnbsub_wrap {
        display: none;

        & .gnbsub {
          display: block;
          padding-bottom: 14px;
          box-sizing: border-box;

          & > ul {
            display: block;
            padding: 0;
            & > li {
              display: block;
              flex-direction: column;
              padding: 0 10px 0 25px;
              margin: 0 !important;
            }

            & .sub1_menu {
              display: block;
              width: 100%;
              padding: 12px 5px;
              margin-right: 0;
              color: #515355;
              font-size: 1rem;
              font-weight: 500;
              transition: .3s;
              cursor: pointer;
              &.gnbsub2_wrap {
                background: url(${sublist_open}) no-repeat right center;
                background-size: 14px auto;
                &.active {
                  background: url(${sublist_close}) no-repeat right center;
                  background-size: 14px auto;
                }
              }
            }

            & ul.gnbsub2 {
              display: none;
              flex-direction: column;
              background: #f6f6f6;
              margin: 4px 0 12px;
              padding: 10px 0;
              & li {
                display: block;
                margin: 0 !important;
                & a {
                  display: block;
                  padding: 10px 25px;
                  color: #515355;
                  font-size: 0.938rem;
                  line-height: 19px;
                }
              }
            }
          }
        }
      }
    }
  }

`;

const LeftNav = ({ open, setOpen }) => {
  const category = useSelector((state) => state.categoryReducer);
  const dispatch = useDispatch()
  const {loading, categoryProducts} = category;
  const clickLink = (id, type, order) => {
    dispatch(getCategoryId(id, type, order))
  }
  const [display, setDisplay] = useState(false);

  // 햄버거메뉴 리스트 토글
  useEffect(() => {
    if(loading) {
      return
    } else {
      $(function(){
        $(".m_nav .gnb_wrap > li > .menu").on('click', function(){
          $(".m_nav .gnbsub2").not($(this).find('.gnbsub2')).slideUp(300);
          $(".m_nav .gnbsub_wrap").not($(this).siblings('.gnbsub_wrap')).slideUp(300);
          $(this).siblings(".gnbsub_wrap").slideToggle(300);
          $(".m_nav .menu, .m_nav .sub1_menu").not($(this)).removeClass("active");
          $(this).toggleClass("active");
        });

        $(".m_nav .sub1_menu").on('click', function(){
          $(".m_nav .gnbsub2").not($(this).siblings('.gnbsub2')).slideUp(300);
          $(this).siblings(".gnbsub2").slideToggle(300);
          $(this).toggleClass("active");
          $(".m_nav .sub1_menu").not($(this)).removeClass("active");
        });
      });
    }
  },[loading])

  return (
    <LeftNavigation open={ open } className="m_nav">
      <div className="gnb_wrapper">
        <ul className="gnb_wrap">
            <li>
              <p className="menu">브랜드</p>
              <div className="gnbsub_wrap">
                <div className="gnbsub">
                  <ul>
                    <li><Link to="/brand" className="sub1_menu" onClick={() => setOpen(false)}>바램펫 이야기</Link></li>
                    <li><Link to="/brand/awards" className="sub1_menu" onClick={() => setOpen(false)}>어워즈</Link></li>
                  </ul>
                </div>
              </div>
            </li>
            {categoryProducts.map((depth1) => (
                <li key={depth1.id}>
                  <p to={{
                    pathname: `/products/list/${depth1.type}${depth1.order}/${depth1.id}`,
                    mainId: depth1.id
                  }} className="menu">{depth1.name}</p>
                  <div className="gnbsub_wrap">
                    <div className="gnbsub">
                      <ul>
                        {depth1.sub_category.map((depth2) =>(
                            <li key={depth2.id}>
                              <p key={depth2.id} className="sub1_menu gnbsub2_wrap">{depth2.name}</p>
                              <ul className="gnbsub2">
                                  {depth2.sub_category.length>1?
                                  <li>
                                      <Link to={{
                                    pathname: `/products/list/${depth1.type}${depth1.order}/${depth2.id}`,
                                    mainId: depth1.id
                                  }} onClick={() => {setOpen(false); clickLink(depth1.id, depth1.type, depth1.order);}}>전체보기</Link></li>:null}
                                {depth2.sub_category.map((depth3) => (
                                    <li key={depth3.id}><Link key={depth3.id} to={{
                                      pathname: `/products/list/${depth1.type}${depth1.order}/${depth3.id}`,
                                      mainId: depth1.id
                                    }} className="sub2_menu" onClick={() => {setOpen(false); clickLink(depth1.id, depth1.type, depth1.order)}}>{depth3.name}</Link></li>
                                ))}
                              </ul>
                            </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </li>
            ))}
            <li>
              <p className="menu">서포트</p>
              <div className="gnbsub_wrap">
                <div className="gnbsub">
                  <ul>
                    <li><Link to="/support/event" className="sub1_menu" onClick={() => setOpen(false)}>이벤트</Link></li>
                    <li><Link to="/support/notice" className="sub1_menu" onClick={() => setOpen(false)}>공지사항</Link></li>
                    <li><Link to="/support/guide?id=0" className="sub1_menu" onClick={() => setOpen(false)}>FAQ</Link></li>
                    <li><Link to="/support/project" className="sub1_menu" onClick={() => setOpen(false)}>바램 육아백과</Link></li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
      </div>
    </LeftNavigation>
  );
};

export default LeftNav;
