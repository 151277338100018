import React, {useState,useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {IoIosArrowDown} from "react-icons/io";
import {useMediaQuery} from "react-responsive";
import { IoIosArrowForward } from "react-icons/io";
import WriteReviewPopup from 'pages/components/WriteReviewPopup';
import ReqExchangePopup from 'pages/components/ReqExchangePopup';
import ReqCancelDeliveryPopup from 'pages/components/ReqCancelDeliveryPopup';
import ChangeCardDialog from 'pages/components/ChangeCardDialog';
import {stringPrice} from "../../../utils/StringPrice";
import {useDispatch, useSelector} from "react-redux";
import {fetchOrderRegularDetails} from "../../../actions/orderActions";
import QueryString from "qs";
import Loader from "../../components/Loader";
import MyPageReviewPopup from "../../components/MyPageReviewPopup";
import Moment from "moment";

const DeliveryProductDetailList = () => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const orderLists = useSelector((state) => state.regularOrderList.orderLists[1] || []); //정기배송 주문내역 리스트
    const itemitemInfo = useSelector((state) => state.orderDetails.orderDetails || {}); //정기배송 주문상세 리스트
    const queryData = QueryString.parse(window.location.search, {ignoreQueryPrefix: true}); //쿼리정보
    const [limit, setLimit] = useState(3) //더보기 리스트 수 제한

    // 결제카드변경
    const [visibleCard, setVisibleCard] = useState(false);
    const [changeCard, setChangeCard] = useState(false);

    // 구매평 작성
    const [isUpdate, setIsUpdate] = useState(0);
    const [ WriteReview, setWriteReview ] = useState(false);
    const [ getReview, setGetReview ] = useState(false);
    const [ newReview, setNewReview ] = useState({});


    useEffect(()=>{
        dispatch(fetchOrderRegularDetails(queryData.id))
    },[dispatch, isUpdate])

    const openNewReview = (item) => {
        if (itemitemInfo.review) {
            if (itemitemInfo.review.is_hide || itemitemInfo.review.deleted) {
                alert("관리자에 의해 삭제된 구매평입니다.")
                return
            }
            if(itemitemInfo.review.user_deleted){
                alert("구매평은 1회 작성 가능하며, 삭제시 재작성이 불가합니다.")
                return
            }
        }
        setWriteReview(true);
        setNewReview({item:item, review: itemitemInfo.review});
    }
    const openModReview = (item) => {
        if (itemitemInfo.review) {
            if (itemitemInfo.review.is_hide || itemitemInfo.review.deleted) {
                alert("관리자에 의해 삭제된 구매평입니다.")
                return
            }
            if(itemitemInfo.review.user_deleted){
                alert("구매평은 1회 작성 가능하며, 삭제시 재작성이 불가합니다.")
                return
            }
        }
        setGetReview(true);
        setNewReview({item:item, review: itemitemInfo.review});
    }

    // 교환
    const [ reqExchange, setReqExchange ] = useState({open : false, item: "",});

    // 정기배송 해지
    const [reqCancelDelivery, setReqCancelDelivery] = useState({open: false, data: {}});

    //주문상태값 변경
    const orderStatus = (status, delay) => {
        switch(status){
            case "입금전" : return "입금전";
            case "환불관리" : return "취소";
            case "교환" : return "교환신청";
            case "재배송" : return "재배송";
            case "배송지연" : return "배송지연"
            case "취소완료" : return "취소";
            default: return status
        }
    }

    //주문된 제품 상태값에 따라 나오는 버튼
    const orderBtn = (status, item, device) => {
        const ship_completed = Moment(item.treat_date, "YYYY-MM-DD").format('LLL');
        const ship_completed_after = Moment(item.treat_date, "YYYY-MM-DD").add(10, "days").format('LLL');
        const now = Moment().format('LLL')
        const isOkToChange = Moment(now).isBetween(ship_completed, ship_completed_after)
        item.devices = item.total_discount_amount === 0 ? device : null
        switch(status){
            case "결제완료" : return <></>
            case "배송준비중" : return <></>
            case "배송중" : return <><button className="control_btn delivery_btn"><a href={"https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=" + (item.re_waybill_number?item.re_waybill_number:item.waybill_number)} target="_blank">배송조회</a></button></>
            case "배송지연" : return <>
                <button className="control_btn delivery_btn">
                    <a onClick={() => {alert("송장번호가 입력되지 않았습니다.")}}>배송조회</a>
                </button>
            </>
            case "배송완료" : return <>
                <button className="control_btn delivery_btn"><a href={"https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=" + (item.re_waybill_number?item.re_waybill_number:item.waybill_number)} target="_blank">배송조회</a></button>
                {
                    itemitemInfo.review ?
                        <button onClick={() => {openModReview(item)}} className="control_btn btn_b">구매평보기</button> :
                        <button onClick={() => {openNewReview(item)}} className="control_btn btn_b">구매평작성</button>
                }
                {item.return_status !== null ? <button onClick={() => {alert("교환 상품이 있어, 교환 및 반품 진행이 어렵습니다. 바램펫 고객센터를 통해 접수해주세요.")}} className="control_btn">교환</button>
                    : isOkToChange ? <button onClick={() => {setReqExchange({open: true, item: item});}} className="control_btn">교환</button>
                    : <button onClick={() => {alert("교환 및 환불 가능한 기간이 지났습니다.\n" + "교환, 환불은 배송완료 후 10일 이내에 가능합니다.")}} className="control_btn">교환</button>}

            </>

        }
    }
    const moreView = () => {  //더보기 버튼
        setLimit(limit + 3);
    }


    const checkCustom_id = (arr) => {
        for(let i = 0; arr.length > i; i++){
            return arr[i].customer_uid;
        }
    }

    const closePop = () => {
        dispatch(fetchOrderRegularDetails(queryData.id))
    }

    const [isOkToRemove, setIsOkToRemove] = useState(false)
    const [findCancel, setFindCancel] = useState(false);

    useEffect(()=>{
        if(itemitemInfo.hasOwnProperty('RegularOrders')){
            const afterThreeShipping = Moment(itemitemInfo.RegularOrders[0].treat_date).add(10, "days").format("YYYY-MM-DD");
            const now = Moment().format('YYYY-MM-DD')
            setIsOkToRemove(Moment(now).isAfter(afterThreeShipping) && itemitemInfo.RegularOrders[0].front_status === "배송완료")

            if(itemitemInfo.RegularOrders[0].product_status.includes("해지")){
                setFindCancel(true)
            }else{
                setFindCancel(false)
            }
        }
    },[itemitemInfo])


    return (
        <React.Fragment>
            {
                !itemitemInfo.RegularOrders ?
                    <Loader/> :
                    <React.Fragment>
                        <DeliveryList>
                            <div className="order_header mypage_title">
                                <div className="order_title ">
                                    <h2>나의 정기배송 상세</h2>
                                </div>
                                {itemitemInfo.RegularOrders.length === 0 ? <button className="card_btn disabled">결제카드 변경</button> :
                                    <button onClick={() => setVisibleCard(true)} className="card_btn">결제카드 변경</button>}
                            </div>
                            <div className="order_lists">
                                <ul>
                                    {itemitemInfo.RegularOrders.slice(0, limit).map((list, idx) => {
                                        const paymentDate = list.createdAt.slice(0, 10).replaceAll("-",".");
                                        const year = itemitemInfo.next_paid_date.slice(0, 4)
                                        const month = itemitemInfo.next_paid_date.slice(5, 7)
                                        const day = itemitemInfo.next_paid_date.slice(8, 10)
                                        const prevDate = list.createdAt.slice(0,10).replaceAll("-", ".")
                                        const prevYear = prevDate.slice(0, 4)
                                        const prevMon = prevDate.slice(5, 7)
                                        const prevDay = prevDate.slice(8, 10)
                                        const is_first = itemitemInfo.RegularOrders[itemitemInfo.RegularOrders.length -1] === list
                                        const device = itemitemInfo.devices
                                        const cancelYear = String(list.return_apply_date).slice(0, 4)
                                        const cancelMon = String(list.return_apply_date).slice(5, 7)
                                        const cancelDay = String(list.return_apply_date).slice(8, 10)

                                        return (
                                            <li key={idx} className={list.front_status === "해지완료" ? "order_lists_list canceled" : "order_lists_list"}>
                                                <p className="order_lists_date">{paymentDate}</p>
                                                <ul>
                                                    <div className="order_lists_number">
                                                        <h4>주문번호&nbsp;&nbsp;<b>{list.merchant_uid}</b></h4>
                                                        <Link to={`/mypage/delivery/detail_list/detail?id=${list.id}`}> <IoIosArrowForward size="23" color="#5F5F5F" /></Link>
                                                    </div>
                                                    <li className="order_list_content">
                                                        {/*정기제품 사료 */}
                                                        <div className="order_list_content_in">
                                                            <Link to={`/products/details/regular/${list.product_id}`}>
                                                            <div className="order_list_content_img">
                                                                <div className="item_img">
                                                                    {
                                                                        idx !== 0 ?
                                                                            <img src={list.product_image} alt={list.product_name}/> :
                                                                            <img src={list.product_image} alt={list.product_name}/>
                                                                    }
                                                                    {
                                                                        list.front_status === "해지완료" || list.front_status === "해지신청" || list.front_status === "반납중" ?
                                                                            <span className="dimmed"></span> :
                                                                            null
                                                                    }
                                                                </div>
                                                                <div className="order_list_content_p">
                                                                    <span className="delivery_count">{ itemitemInfo.RegularOrders.length - idx }회차</span>
                                                                    {
                                                                        isMobile && <span className="order_state">{orderStatus(list.front_status)}</span>
                                                                    }
                                                                    {
                                                                        idx !== 0 ?
                                                                            <p className="item_name">{list.product_name}</p> :
                                                                            <p className="item_name">{list.product_name}</p>
                                                                    }
                                                                    <p className="order_price">
                                                                        <b>{stringPrice(list.device_name ? list.order_price_amount : list.paid_amount)}</b>원 / {list.product_amount}개
                                                                    </p>
                                                                    {orderStatus(list.front_status) === "해지완료" || orderStatus(list.front_status) === "해지신청" || orderStatus(list.front_status) === "반납중" ? <p className="delivery_info">해지 결제일 : {cancelYear}년 {cancelMon}월 {cancelDay}일</p> : idx !== 0 ?
                                                                            <p className="delivery_info">{idx !== 0 ? "결제일" : "다음 예상 결제일"} : {prevYear}년 {prevMon}월 {prevDay}일 ({itemitemInfo.RegularOrders.length - idx + 1}회차)</p> :
                                                                            <p className="delivery_info">다음 예상 결제일 : {year}년 {month}월 {day}일 ({itemitemInfo.RegularOrders.length - idx + 1}회차)</p>}
                                                                </div>
                                                            </div>
                                                            </Link>
                                                            <span className="order_state">{
                                                                list.front_status === "배송지연" ?
                                                                    <React.Fragment>{orderStatus(list.front_status)}<br/>
                                                                        <span>{list.delay_reason ? <>({list.delay_reason})</> : null}</span>
                                                                    </React.Fragment> :
                                                                    orderStatus(list.front_status)}
                                                            </span>
                                                            <div className="order_control_btn">
                                                                {orderBtn(orderStatus(list.front_status), list, device)}
                                                            </div>
                                                        </div>
                                                    </li>
                                                    {/*정기 제품 디바이스 */}
                                                    {device !== null ?
                                                        <React.Fragment>
                                                            {is_first && itemitemInfo.devices.length !== 0 && <li className="order_list_content">
                                                                <div className="order_list_content_in">
                                                                    <Link to={`/products/details/normal/${device.id}`}>
                                                                        <div className="order_list_content_img">
                                                                            <div className="item_img">

                                                                                {
                                                                                    idx !== 0 ?
                                                                                        <img src={device.main_img} alt={device.name} /> :
                                                                                        <img src={device.main_img} alt={device.name} />
                                                                                }
                                                                                {
                                                                                    list.front_status === "해지완료" ?
                                                                                        <span className="dimmed"></span> :
                                                                                        null
                                                                                }
                                                                            </div>
                                                                            <div className="order_list_content_p">
                                                                                {isMobile && <span className="order_state">{itemitemInfo.RegularOrders[0].device_front_status}</span>}
                                                                                <p className="item_name">{device.name}</p>
                                                                                <p className="order_price"><b>{stringPrice(device.regular_order_price ? device.regular_order_price : device.price)}</b>원 / 1개</p>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                    <span className="order_state">{
                                                                        list.front_status === "배송지연" ?
                                                                            <React.Fragment>{orderStatus(list.front_status)}<br/>
                                                                                <span>{list.delay_reason ? <>({list.delay_reason})</> : null}</span>
                                                                            </React.Fragment> :
                                                                            orderStatus(list.front_status)}
                                                                    </span>
                                                                    <div className="order_control_btn">
                                                                        {orderBtn(orderStatus(itemitemInfo.RegularOrders[0].device_front_status), list, device)}
                                                                    </div>
                                                                </div>
                                                            </li>}
                                                        </React.Fragment> :
                                                        null
                                                    }
                                                </ul>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            {/*정기 해지 버튼 */}
                            {!findCancel ? itemitemInfo.RegularOrders.length >= 3 && isOkToRemove ? <div className="text_btn_wrap">
                                        <button onClick={() => setReqCancelDelivery({...reqCancelDelivery, open: true, data: itemitemInfo})} type="button" className="text_btn">정기배송해지</button>
                                    </div> : itemitemInfo.RegularOrders.length >= 4 && <div className="text_btn_wrap"> <button onClick={() => setReqCancelDelivery({...reqCancelDelivery, open: true, data: itemitemInfo})} type="button" className="text_btn">정기배송해지</button></div>
                                : null}
                            {/*정기 제품 리스트 더보기 */}
                            {itemitemInfo.RegularOrders.length > limit && itemitemInfo.RegularOrders.length >= limit ?
                                    <div className="list_more_btn">
                                        <button onClick={moreView}>더보기<IoIosArrowDown /></button>
                                    </div> :
                                    null}
                        </DeliveryList>
                        {/* 구매평작성, 구매평보기, 교환/반품, 카드교환 */}
                        {getReview && <MyPageReviewPopup visible={getReview} product={newReview} onCancel={() => {setGetReview(false); setIsUpdate(new Date().getTime());}}/>}
                        {WriteReview && <WriteReviewPopup visible={WriteReview} product={newReview} onCancel={() => setWriteReview(false)} />}
                        {reqExchange.open && <ReqExchangePopup visible={reqExchange.open} setReqExchange={setReqExchange} item={reqExchange.item} onClose={closePop}  />}
                        {reqCancelDelivery.open && <ReqCancelDeliveryPopup visible={reqCancelDelivery.open} setReqCancelDelivery={setReqCancelDelivery} cancelData ={reqCancelDelivery.data} />}
                        {visibleCard && <ChangeCardDialog visible={visibleCard} setVisibleCard={setVisibleCard} changeCard={changeCard} setChangeCard={setChangeCard} customer_id = {checkCustom_id(orderLists)} />}
                    </React.Fragment>

            }
        </React.Fragment>
    );
};

const DeliveryList = styled.div`
    max-width: 1230px;
    width: 100%;
    @media only screen and (max-width: 960px) {
        margin-top: 0 !important;
    }
    
    & .order_header{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 16px;
        border-bottom: 1px solid #555;
        & .order_title{
            h2{
                font-size: 24px;
                font-weight: 400;
                span {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 400;
                    color: #515355;
                    margin-left: 20px;
                }
            }
            
        }
        & .card_btn{
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 120px;
            height: 36px;
            border-radius: 4px;
            background: #1EA0FA;
            color: #fff;
            font-size: 13px;
            font-weight: 700;
        }
      & .card_btn.disabled{
        background: #1EA0FA;
      }

        @media only screen and (max-width: 960px) {
            display: block;
            padding-bottom: 30px;
            border-bottom: none;
            & .order_title{
                h2{
                    font-size: 1.375rem;
                    line-height: 33px;
                    color: #222;
                    text-align: center;
                    span {
                        display: none;
                    }
                }
            }
            & .card_btn{
                width: 110px;
                margin: 40px auto 0;
                border: 1px solid #1EA0FA;
                color: #fff;
                font-weight: 500;
            }
        }
    }
    
    & .order_lists{
        & ul > li.order_lists_list  {
            margin-top: 30px;
            box-sizing: border-box;
            ul {
                border: 1px solid #ccc;
                & li.order_list_content{
                    padding: 0 25px;
                    &:not(:last-child) {
                        & .order_list_content_in {
                            border-bottom: 1px solid #E1E2E3;    
                        }
                    }
                   
                    & .order_list_content_in {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 25px 0;
                        & a {
                          width: 100%;
                        }
                        & .order_list_content_img{
                            display: flex;
                            flex-basis: 50%;
                            &.aligncenter {
                                align-items: center;
                                & .order_list_content_p {
                                    padding-top: 0;
                                }
                            }
                            & .item_img {
                                position: relative;
                                overflow: hidden;
                                flex: 160px 0 0;
                                height: 160px;
                                &::after {
                                  content: "";
                                  display: block;
                                  padding-bottom: 100%;
                                }
                                img{
                                  position: absolute;
                                  top: 50%;
                                  left: 50%;
                                  transform: translate(-50%, -50%);
                                  height: 100%;
                                  width: auto;
                                  height: auto;
                                  width: 100%;
                                }
                            }
                            & .order_list_content_p{
                                padding-top: 5px;
                                font-size: 14px;
                                color: #515355;
                                padding-left: 1.875rem;
                                & .delivery_count {
                                    display: inline-block;
                                    margin-bottom: 10px;
                                    padding: 3px 12px;
                                    border-radius: 35px;
                                    background: #1EA0FA;
                                    color: #fff;
                                    font-weight: 700;
                                    font-size: 14px;
                                    line-height: 21px;
                                }                            
                                p.item_name{
                                    font-size: 18px;
                                    color: #222;
                                    line-height: 27px;
                                    font-weight: 500;
                                }
                                p.order_price {
                                    margin-top: 4px;
                                    font-size: 16px;
                                    line-height: 24px;
                                    color: #515355;
                                    & b {
                                        font-weight: 500;
                                    }
                                }
                                p.delivery_info {
                                    margin-top: 14px;
                                    font-size: 14px;
                                    color: #888;
                                    line-height: 21px;
                                }
                            }
                        }
                        & .order_state {
                          width: 250px;
                          padding-right: 100px;
                          color: #222;
                          text-align: center;
                        }
                        & .order_control_btn{
                            flex-basis: 150px;
                            & .control_btn{
                                width: 100px;
                                height: 30px;
                                font-size: 13px;
                                color: #222;
                                margin: 3px 0;
                                border: 1px solid #ccc;
                                box-sizing: border-box;
                                border-radius: 40px;
                            }
                            & .control_btn.btn_b{
                                border: 1px solid #1EA0FA;
                                color: #1EA0FA;
                            }
                        } 
                    }
                }
            }
            
            p.order_lists_date{
                font-size: 18px;
                color: #515355;
                line-height: 27px;
                margin-bottom: 16px;
            }
            & .order_lists_number {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #F7F7F7;
                padding: 15px 25px 15px 30px;
                box-sizing: border-box;
                border-bottom: 1px solid #ccc;
                
                & h4 {
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 24px;
                    color: #515355;
                    & b {
                        color: #222;
                    }
                    & span {
                        display: inline-block;
                        margin-left: 5px;
                        font-size: 0.875rem;
                        color: #888;
                        & b {
                            color: #888;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 960px) {
            background: #f6f6f6;
            padding-bottom: 8px;
            &:first-child {
                padding-top: 0;
            }
            & ul > li.order_lists_list  {
                margin: 0;
                background: #fff;
                &:not(:first-child) {
                    margin-top: 8px;
                }
                & p.order_lists_date {
                    display: none;
                }
                ul {
                    border: none;
                    & .order_lists_number {
                        padding: 18px 12px 18px 15px;
                        border-top: 1px solid #eee;
                        border-bottom: 1px solid #eee;
                        background: #F7F7F7;
                        & h4 {
                            display: flex;
                            align-items: center;
                            font-size: 0.938rem;
                            font-weight: 400;
                            line-height: 24px;
                            color: #515355;
                            font-weight: 500;
                            & b {
                                color: #222;
                                font-size: 1rem;
                            }
                            & span {
                                display: inline-block;
                                margin-left: 5px;
                                font-size: 0.75rem;
                                color: #888;
                                font-weight: 400;
                                & b {
                                    color: #888;
                                    font-size: 0.75rem;
                                }
                            }
                        }
                        & svg {
                            width: 20px;
                            width: 20px;
                            color: #888 !important;
                        }
                    }

                      &.canceled {
                        ul {
                          & li.order_list_content{
                            & .order_list_content_in {
                              & .order_list_content_img{
                                & .order_list_content_p{
                                  font-size: 14px;
                                  color: #888;
                                  & .delivery_count {
                                    background: #515355;
                                  }
                                  p.item_name{
                                    color: #888;
                                  }
                                  p.order_price {
                                    color: #888;
                                  }
                                }
                              }
                              & .order_state {
                                width: auto;
                                color: #888;
                              }
                            }
                          }
                        }
                        & .dimmed {
                          position: absolute;
                          top: 0;
                          right: 0;
                          bottom: 0;
                          left: 0;
                          background: rgba(0,0,0,0.6);
                        }
                      }
                    & li.order_list_content{
                        //display: none;
                        padding: 0;
                        
                        &:nth-child(2) {
                            display: block;
                        }
                        &:not(:last-child) {
                            & .order_list_content_in {
                                border-bottom: none;    
                            }
                        }
                    
                        & .order_list_content_in {
                            display: block;
                            padding: 0;
                            & h5 {
                                display: inline-block;
                                width: 90px;
                                color: #515355;
                                font-weight: 400;
                                line-height: 22px;
                            }
                            
                            & .order_list_content_img{
                                flex-basis: 100%;
                                display: flex;
                                justify-content: flex-start;
                                padding: 15px 15px 20px 15px;
                                
                                & .item_img {
                                    flex: 80px 0 0;
                                    height: 80px;
                                }
                                
                                & .order_list_content_p{
                                    padding: 0 0 0 10px;
                                    font-size: 14px;
                                    color: #515355;
                                    & .delivery_count {
                                        margin-bottom: 8px;
                                        padding: 2.5px 10px;
                                        border-radius: 30px;
                                        font-size: 12px;
                                        line-height: 18px;
                                    }                            
                                    p.item_name{
                                        font-size: 16px;
                                        line-height: 22px;
                                        font-weight: 400;
                                    }
                                    p.order_price {
                                        font-size: 13px;
                                        line-height: 19.5px;
                                        color: #222;
                                        & b {
                                            font-weight: 700;
                                        }
                                    }
                                    p.delivery_info {
                                        margin-top: 4px;
                                        font-size: 13px;
                                        line-height: 19.5px;
                                    }
                                    & .order_state {
                                        display: inline-block;
                                        width: auto;
                                        padding-left: 0;
                                        margin-left: 6px;
                                        font-weight: 500;
                                    }
                                }
                            }
                            & .order_state {
                                display: none;
                            }
                            & .order_control_btn{
                                display: flex;
                                width: 100%;
                                flex-basis: 100%;
                                flex-wrap: wrap;
                                border-bottom: 1px solid #e5e5e5;
                                & .control_btn{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 50%;
                                    height: 50px;
                                    font-size: 14px;
                                    color: #515355;
                                    margin: 0;
                                    border: none;
                                    border-top: 1px solid #e5e5e5;
                                    box-sizing: border-box;
                                    border-radius: 0;
                                    &.btn_b {
                                        border: none;
                                        border-top: 1px solid #e5e5e5;
                                        color: #515355;
                                    }
                                    &:last-child {
                                        border-left: 1px solid #e5e5e5;
                                    }
                                }
                                & .delivery_btn {
                                    width: 100%;
                                }
                            } 
                        }
                    }
                }
            }
        }
    }

    & .text_btn_wrap {
        margin-top: 60px;
        text-align: center;
        & button {
            font-size: 1rem;
            color: #555;
            text-decoration: underline;
            line-height: 24px;
        }
        @media only screen and (max-width: 960px) {
            margin: 40px 0;
            & button {
              font-size: 0.875rem;
                line-height: 19.6px;
            }
        }
    }

    & .dialog_block {
        padding: 50px 24px;
        @media only screen and (max-width: 960px) {
            padding: 40px 20px 30px;
        }
    }
    & .close_btn {
        display: none;
    }
    & .button_wrap {
        margin-top: 30px;
        @media only screen and (max-width: 960px) {
            margin-top: 40px;
        }
    }
    & .dialog_inner {
        & .main_txt {
            margin-bottom: 40px;
            font-size: 22px;
            line-height: 33px;
            color: #222;
            font-weight: 500;
        }
        & .sub_txt p {
            font-size: 1rem;
            color: #222;
            line-height: 24px;
            text-align: left;
            & span {
                line-height: 24px;
            }
        }
        @media only screen and (max-width: 960px) {
            & .main_txt {
                margin-bottom: 30px;
                font-size: 20px;
                line-height: 30px;
            }
            & .sub_txt p {
                font-size: 1rem;
            }
        }
    }
`

export default DeliveryProductDetailList ;