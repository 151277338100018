import React from "react";
import styled from "styled-components";
import Button from "./components/Button";
import {useForm} from "react-hook-form";
import axios from "axios";

const FindPwEmailPage = () => {
  const {register, handleSubmit} = useForm();

  const onSubmit = async (data) => {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}api/auth/pwrEmail`, {
       "email": data.email
    })
    if(res.data.type === "fail"){
      alert("등록안된 이메일입니다.");
    }else{
      alert("비밀번호 재설정 링크를 발송했습니다.")
      document.location.href = "/login"
    }
  }

	return (
    <FindPwEmailContainer>
      <div className="find_wrap">
        <p><span>이메일 주소</span>를 입력해 주세요.</p>
        <div className="find_form">
            <p><input type="text" name="email" {...register("email")} placeholder="@까지 입력해주세요." /></p>
            <div className="button_wrap">
              <MainButton fullWidth onClick={handleSubmit(onSubmit)}>비밀번호 찾기</MainButton>
            </div>
        </div>
      </div>
    </FindPwEmailContainer>
	);
}

const MainButton = styled(Button)`
  &:not(:first-child) {
    margin-top: 15px;
  }
	@media only screen and (max-width: 960px) {
		height: 50px;
    font-weight: 700;
    font-size: 1rem;
	}
`

const FindPwEmailContainer = styled.div`
  max-width: 1480px;
  margin: 0 auto;

  & .find_wrap {
    width: 500px;
    margin: 0 auto;
    & > p {
      margin: 100px 0 60px;
      font-size: 26px;
      font-weight: 300;
      line-height: 39px;
      & span {
        font-weight: 400;
      }
    }
    & .find_form {
      p:not(:first-child) {
        margin-top: 10px;
      }
    }

    & .button_wrap {
      margin: 80px 0;
    }
  }

  @media only screen and (max-width: 960px) {
    margin: 0;
    & .find_wrap {
      width: 100%;
      margin: 0;
      padding: 0 15px;
      & > p {
        margin: 60px 0 30px;
        font-size: 22px;
        line-height: 33px;
        & span {
          font-weight: 400;
        }
      }
      & .find_form {
        p:not(:first-child) {
          margin-top: 5px;
        }
        input {
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
      }

      & .button_wrap {
        margin: 40px 0 46px;
      }
    }
  }
`

export default FindPwEmailPage;