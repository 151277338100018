import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Button from './Button';
import { CgClose } from 'react-icons/cg';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(200px);
  }
  to {
    transform: translateY(0px);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(200px);
  }
`;

const DarkBackground = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.5);
  z-index: 9999;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;

  ${props => props.disappear && css`
    animation-name: ${fadeOut}
  `}
`;

const DialogBlock = styled.div`
  position: relative;
  overflow-y: hidden;
  width: 520px;
  height: auto;
  padding: 123px 40px 50px 40px;
  background: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${slideUp};
  animation-fill-mode: forwards;
  text-align: center;

  & .close_btn {
    position: absolute;
    top: 28px;
    right: 28px;
  }

  & p {
    text-align: center;
    line-height: 24px;
    font-size: 1rem;
  }

  ${props => props.disappear && css`
    animation-name: ${slideDown}
  `}
  
  @media only screen and (max-width: 960px) {
    width: calc(100% - 30px);
    padding: 40px 20px 30px;
  }
`;

const ButtonWrap = styled.div`
  display: inline-flex;
  justify-content: center;
  width: 330px;
  margin-top: 64px;
  @media only screen and (max-width: 960px) {
    width: 100%;
    margin-top: 40px;
  }
`

const ShortButton = styled(Button)`
  width: 160px;
  border-radius: 4px;
  @media only screen and (max-width: 960px) {
    width: 140px;
    font-weight: 700;
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`

function ConfirmDialog({ children, cancelText, confirmText, visible, onConfirm, onCancel, ...rest }) {
  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(visible);

  useEffect(() => {
    if (localVisible && !visible) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
    }
    setLocalVisible(visible);
  }, [localVisible, visible]);

  if (!localVisible && !animate) return null;

  return (
    <DarkBackground disappear={!visible} rest={rest}>
      <DialogBlock disappear={!visible} className="dialog_block">
        <button onClick={onConfirm} className="close_btn"><CgClose size="29" /></button>
        {children}
        <ButtonWrap className="button_wrap">
          {onCancel ?
            <ShortButton onClick={onCancel} size="small" color="white" outline>
              {cancelText}
            </ShortButton>
          : null}
          <ShortButton onClick={onConfirm} size="small">
            {confirmText}
          </ShortButton>
        </ButtonWrap>
      </DialogBlock>
    </DarkBackground>
  );
}

ConfirmDialog.defaultProps = {
  cancelText: '취소',
  confirmText: '삭제'
}

export default ConfirmDialog;