import React, {useEffect} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import './App.css';
import axios from "axios";
import {useSelector, useDispatch} from "react-redux";
import {setUserInfo} from "./actions/userActions";
import MainPage from "./pages/MainPage";
import LoginPage from "./pages/LoginPage";
import FindIdPage from "./pages/FindIdPage";
import FindPwPage from "./pages/FindPwPage";
import FindPwPhonePage from "./pages/FindPwPhonePage";
import FindPwEmailPage from "./pages/FindPwEmailPage";
import JoinPage from "./pages/JoinPage";
import JoinCompletePage from "./pages/JoinCompletePage";
import BrandPage from "./pages/BrandPage";
import AwardsPage from "./pages/AwardsPage";
import ItemListsPage from "./pages/ItemListsPage";
import SearchListsPage from "./pages/SearchListPage";
import ItemDetailsPage from "./pages/ItemDetailsPage";
import ShoppingcartPage from "./pages/ShoppingcartPage";
import OrderPage from "./pages/OrderPage";
import PayCompletePage from "./pages/PayCompletePage";
import OrderCompletePage from "./pages/OrderCompletePage";
import ReviewPage from "./pages/ReviewPage";
import NoticePage from "./pages/NoticePage";
import AppNewsPage from "./pages/AppNewsPage";
import GuidePage from './pages/GuideBook/GuidePage';
import CustomerPage from "./pages/CustomerPage";
import EventPage from "./pages/EventPage";
import ProjectPage from "./pages/ProjectPage";
import ProjectDetailPage from "./pages/ProjectDetailPage";
import EventDetailFull from "./pages/EventDetailFull";
import EventDetail from "./pages/EventDetail";
import MyPage from "./pages/MyPage/MyPage";
import MyPageMo from "./pages/MyPage/MyPageMo";
import NotFound from "pages/NotFound";
import ErrorPage from "pages/ErrorPage";
import "./styles/GlobalStyles.scss";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";
import UserInfoUpdate from "./pages/UserInfoUpdate";
import ErrorBoundary from "./pages/components/ErrorBoundary";

function App({history}) {
    const userInfo = useSelector((state) => state.userLogin.userInfo);
    let savedUser = localStorage.getItem("userInfo");
    const dispatch = useDispatch();

    useEffect( async () => {
        let auth = new URLSearchParams(history.location.search).get("auth");
        if(!userInfo & savedUser){
            dispatch(setUserInfo(JSON.parse(savedUser).userInfo.data));
        }
        if (auth) {
            let res = await axios.post(
                `${process.env.REACT_APP_API_URL}api/auth/signinByKey`,
                {auth: auth});
            localStorage.setItem("userInfo", JSON.stringify({loading: false, userInfo:res.data, errorMsg: {}}));
            dispatch(setUserInfo(res.data));
            document.location.href = `${history.location.pathname}${history.location.search.substr(0, history.location.search.indexOf('&auth='))}`;
        }
    }, [history.location.pathname]);

  return (
      <ErrorBoundary key={history.location.pathname}>
        <Switch>
            <Route path="/" component={MainPage} exact /> {/*메인페이지*/}
            <Route exact path="/login" component={LoginPage} /> {/*로그인*/}
            <Route exact path="/FindId" component={FindIdPage} /> {/*아이디 찾기*/}
            <Route exact path="/FindPw" component={FindPwPage} /> {/*비밀번호 찾기*/}
            <Route exact path="/FindPw/phone" component={FindPwPhonePage} /> {/*비밀번호 휴대폰으로 찾기*/}
            <Route exact path="/FindPw/email" component={FindPwEmailPage} /> {/*비밀번호 이메일로 찾기*/}
            <Route exact path="/Join" component={JoinPage} />  {/*회원가입*/}
            <Route path="/mypage" component={MyPage}/> {/* 마이페이지 메뉴 페이지*/}
            <Route path="/mypage_m" component={MyPageMo}/> {/* 마이페이지 메뉴 MOBILE 페이지 */}
            <Route exact path="/JoinComplete" component={JoinCompletePage} /> {/* 회원가입 이후 완료 페이지 */}
            <Route exact path="/order" component={OrderPage} /> {/* 주문/결제 페이지  */}
            <Route exact path="/payComplete" component={PayCompletePage} /> {/* 결제완료 페이지  */}
            <Route exact path="/orderComplete" component={OrderCompletePage} /> {/* 주문완료 페이지  */}
            <Route exact path="/brand" component={BrandPage} /> {/* 브랜드 페이지  */}
            <Route exact path="/brand/awards" component={AwardsPage} /> {/* 브랜드/어워즈 페이지  */}
            <Route exact path="/products/list/:name/:id" component={ItemListsPage} /> {/* 바른먹거리 제품리스트 페이지  */}
            <Route exact path="/products/details/:type/:id" component={ItemDetailsPage} /> {/* 바른먹거리 제품상세페이지  */}
            <Route exact path="/searchlist" component={SearchListsPage} /> {/* 제품검색 결과페이지  */}
            <Route exact path="/shoppingcart" component={ShoppingcartPage} /> {/* 장바구니 페이지  */}
            <Route exact path="/review" component={ReviewPage} /> {/* 고객후기 페이지  */}
            <Route exact path="/support/event" component={EventPage} /> {/* 서포트/이벤트 리스트 페이지  */}
            <Route exact path="/support/notice" component={NoticePage} /> {/* 서포트/공지사항 페이지  */}
            <Route exact path="/support/AppNews" component={AppNewsPage} /> {/* 서포트/앱소식 페이지  */}
            <Route exact path="/support/guide" component={GuidePage} /> {/* 서포트/FAQ 페이지 */}
            <Route exact path="/support/customer" component={CustomerPage} /> {/* 서포트/고객만족센터 페이지  */}
            <Route exact path="/support/project" component={ProjectPage} /> {/* 서포트/바램 육아백과 리스트 페이지   */}
            <Route exact path="/support/projectdetail/:id" component={ProjectDetailPage} /> {/* 바램 육아백과 상세페이지   */}
            <Route exact path="/event/eventdetailfull/:id" component={EventDetailFull} /> {/* 이벤트 FULL상세페이지  */}
            <Route exact path="/event/eventdetail/:id" component={EventDetail} /> {/* 이벤트 상세페이지   */}
            <Route exact path="/terms" component={TermsPage} /> {/* 이용약관 페이지   */}
            <Route exact path="/privacy" component={PrivacyPage} /> {/* 개인정보 처리방침약관 페이지   */}
            <Route exact path="/userinfoupdate" component={UserInfoUpdate} /> {/* 마이페이지 회원정보 업데이트 페이지 */}
            <Route path="*" component={ErrorPage} /> {/* 에러 발생시 접근페이지 */}
            <Route path="*" component={NotFound} /> {/* 잘못된 경로 접근시 페이지  */}
        </Switch>
      </ErrorBoundary>
  );
}

export default withRouter(App);
