import React, {useEffect} from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import icon_event_list from "images/icon/icon_event_list.png";
import {useSelector, useDispatch} from "react-redux";
import {setProjectCategory, setProjectDetail} from "../actions/boardActions";
import Loader from "./components/Loader";

const ProjectDetailPage = (props) => {
  const isPc = useMediaQuery({ query: '(min-width: 961px)' })
  const dispatch = useDispatch();
  const detailInfo = useSelector((state) => state.projectDetail)
  const {loading, projectDetail} = detailInfo;
  const category = useSelector((state) => state.projectCategory.category);
  const filiterCate = category.filter((e) => e.id === projectDetail?.data?.ProjectCategoryId)

    useEffect(() => {
        dispatch(setProjectCategory());
        dispatch(setProjectDetail(props.match.params.id));
    }, [dispatch, props.match])

  return (
    <>
        {
            filiterCate.length < 1 ?
                <Loader/> :
                (loading ?
                        <Loader /> : <ProjectDetailContainer className="center">
                        <div className="wrap_title board_title">
                            <h2>바램 육아백과</h2>
                        </div>
                        <div className="event_title_wrap">
                            <p className="sub_tit">{filiterCate[0].name}</p>
                            <p className="main_tit">{projectDetail.data?.title}</p>
                        </div>
                        <div className="event_cont">
                            <React.Fragment>
                                { projectDetail?.data?.content.indexOf('</') !== -1
                                    ? (
                                        <div className="board_cont" dangerouslySetInnerHTML={{__html: projectDetail?.data?.content.replace(/(<? *script)/gi, 'illegalscript')}} >
                                        </div>
                                    )
                                    : projectDetail?.data?.content
                                }
                            </React.Fragment>
                        </div>
                        <div className="list_nav">
                            <div className="list_prev">
                                {projectDetail?.next ? <Link to={{
                                    pathname: `/support/projectdetail/${projectDetail?.next?.id}`,
                                    state: projectDetail?.next, category: props.history.location.state.category
                                }}>
                                    <span>이전글</span>
                                    {projectDetail?.next ? <p>{!isPc ? null :<b style={{fontWeight: "400", color: "#222"}}>이전글: </b>}{projectDetail?.next?.title}</p> : <p>이전글이 없습니다.</p>}
                                </Link> : <a style={{cursor: "default"}}><span>이전글</span>
                                    {projectDetail?.next ? <p>{projectDetail?.next?.title}</p> : <p>이전글이 없습니다.</p>}</a>}
                            </div>
                            <span className="go_list"><Link to="/project"><img src={icon_event_list} alt="전체목록보기" /></Link></span>
                            <div className="list_next">
                                {projectDetail?.prev ? <Link to={{
                                    pathname : `/support/projectdetail/${projectDetail?.prev?.id}`,
                                    state: projectDetail?.prev, category: props.history.location.state.category
                                }}>
                                    {projectDetail?.prev ? <p>{projectDetail?.prev?.title} {!isPc ? null : <b style={{fontWeight: "400", color: "#222"}}>: 다음글</b>}</p> : <p>다음글이 없습니다.</p>}
                                    <span>다음글</span>
                                </Link> : <a style={{cursor: "default"}}>{projectDetail?.prev ? <p>{projectDetail?.prev?.title}</p> : <p>다음글이 없습니다.</p>}<span>다음글</span></a>}
                            </div>
                        </div>
                    </ProjectDetailContainer>)

        }
    </>
  );
};

const ProjectDetailContainer = styled.div`
  padding: 100px 0;
  & .wrap_title {
    display: flex;
    align-items: center;
    & p {
      margin-left: 40px;
      font-size: 0.875rem;
      line-height: 21px;
      color: #515355;
    }
    @media only screen and (max-width: 960px) {
      display: flex;
      flex-direction: column;
      & p {
        margin: 10px 0 0 0;
        font-size: 0.875rem;
        line-height: 20px;
        text-align: center;
      }
    }
  }

  & .event_title_wrap {
    padding: 30px 20px;
    border-bottom: 1px solid #ddd;

    & .sub_tit {
      margin-bottom: 6px;
      font-size: 1rem;
      color: #1EA0FA;
      line-height: 24px;
    }

    & .main_tit {
      font-size: 1.625rem;
      line-height: 39px;
      color: #222;
    }
  }

  & .event_cont {
    padding: 60px 40px 0 40px;
    & p {
      line-height: 1.5;
      word-break: break-all;
    }
    & img {
      max-width: 100%;
    }
  }

  @media only screen and (max-width: 960px) {
    padding: 50px 15px;

    & .event_title_wrap {
      padding: 20px 0;
      border-top: 1px solid #555;

      & .sub_tit {
        margin-bottom: 5px;
        font-size: 0.875rem;
        line-height: 21px;
      }

      & .main_tit {
        font-size: 1.125rem;
        line-height: 25px;
        font-weight: 500;
      }
    }

    & .event_cont {
      padding: 30px 0;

      & img {
        display: block;
        max-width: 100%;
      }
    }
  }
`;

export default ProjectDetailPage;