import React, {useEffect, useLayoutEffect} from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import icon_event_list from "images/icon/icon_event_list.png";
import {useDispatch, useSelector} from "react-redux";
import {setEventDetail} from "../actions/boardActions";
import list_prev from "../images/icon/prev_list.png";
import list_next from "../images/icon/next_list.png";

const EventDetail = (props) => {
  const isPc = useMediaQuery({ query: '(min-width: 961px)' })
  const dispatch = useDispatch();
  const detailInfo = useSelector((state) => state.eventDetail.eventDetail)
  const getId = props.match.params.id

  useLayoutEffect(() => {
    if(window.resetMap === undefined){
      document.location.href = "/ErrorPage"
    }
    function updateSize() {
      window.resetMap();
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    const jquery = document.createElement('script');
    jquery.async = true;
    document.body.appendChild(jquery);
    return () => {
      document.body.removeChild(jquery);
    };

  }, []);

  useEffect(() => {
    dispatch(setEventDetail(getId));
  }, [props.match])

  useEffect(() => {
    if(window.resetMap === undefined){
      document.location.href = "/ErrorPage"
    }
    window.resetMap();
    }, [detailInfo])

  return (
      <>
        {detailInfo?.data?.size === "1920" ? <EventDetailFullContainer>

              {isPc ?
                  <div className="event_cont board_cont" dangerouslySetInnerHTML={{__html: detailInfo?.data?.content}} />:
                  <div className="event_cont board_cont" dangerouslySetInnerHTML={{__html: detailInfo?.data?.mobile_content}} />
              }

          <div className="event_list">
                <div className="list_prev">
                  {detailInfo?.prev ? <Link to={{
                    pathname:`/event/eventdetail/${detailInfo?.prev.id}`,
                    state: detailInfo?.prev }}>
                    <span>이전글</span>
                    {detailInfo?.prev ? <p>{!isPc ? null :<b style={{fontWeight: "400", color: "#222"}}>이전글: </b>}{detailInfo?.prev?.title}</p> : <p>이전글이 없습니다.</p>}
                  </Link> : <p>이전글이 없습니다.</p>}
                </div>
                <span className="go_list"><Link to="/event"><img src={icon_event_list} alt="전체목록보기" /></Link></span>
                <div className="list_next">

                  {detailInfo?.next ? <Link to={{
                    pathname: `/event/eventdetail/${detailInfo?.next.id}`,
                    state: detailInfo?.next }}>
                    {detailInfo?.next ? <p>{detailInfo?.next?.title}{!isPc ? null :<b style={{fontWeight: "400", color: "#222"}}>: 다음글</b>}</p> : <p>다음글이 없습니다.</p>}
                    <span>다음글</span>
                  </Link> : <p>다음글이 없습니다.</p>}
                </div>
          </div>

        </EventDetailFullContainer> :
            <EventDetailContainer className="center">
          <div className="wrap_title board_title">
            <h2>{detailInfo?.data?.title}</h2>
          </div>
          <div className="event_title_wrap">
            <p className="sub_tit">{detailInfo?.data?.sub}</p>
            <p className="main_tit">{detailInfo?.data?.main}</p>
          </div>
          {isPc ?
              <div className="event_cont board_cont" dangerouslySetInnerHTML={{__html: detailInfo?.data?.content.replace(/(<? *script)/gi, 'illegalscript')}} />:
              <div className="event_cont board_cont" dangerouslySetInnerHTML={{__html: detailInfo?.data?.mobile_content.replace(/(<? *script)/gi, 'illegalscript')}} />
          }


          <div className="list_nav">
            <div className="list_prev">
              {detailInfo?.prev ? <Link to={{
                pathname:`/event/eventdetail/${detailInfo?.prev.id}`,
                state: detailInfo?.prev }}>
                <span>이전글</span>
                {detailInfo?.prev ? <p>{!isPc ? null :<b style={{fontWeight: "400", color: "#222"}}>이전글: </b>}{detailInfo?.prev?.title}</p> : <p>이전글이 없습니다.</p>}
              </Link> : <p>이전글이 없습니다.</p>}
            </div>

            <span className="go_list"><Link to="/event"><img src={icon_event_list} alt="전체목록보기" /></Link></span>

            <div className="list_next">
              {detailInfo?.next ? <Link to={{
                pathname: `/event/eventdetail/${detailInfo?.next.id}`,
                state: detailInfo?.next }}>
                {detailInfo?.next ? <p>{detailInfo?.next?.title}{!isPc ? null :<b style={{fontWeight: "400", color: "#222"}}>: 다음글</b>}</p> : <p>다음글이 없습니다.</p>}
                <span>다음글</span>
              </Link> : <p>다음글이 없습니다.</p>}
            </div>
          </div>
        </EventDetailContainer>}
      </>
  );
};

const EventDetailFullContainer = styled.div`
  & .event_cont{
    & p {
      font-size: 1rem;
      line-height: 24px;
      color: #222;
      word-break: break-all;
    }

    & img {
      display: block;
      width: 100% !important;
      margin: 0 auto;
    }
  }
  

  & .event_list {
    display: flex;
    position: relative;
    max-width: 1480px;
    margin: 60px auto 60px;
    margin-top: 60px;
    padding-top: 40px;
    border-top: 1px solid #515355;
    justify-content: space-between;

    & div {
      display: flex;
      align-items: center;
      width: calc((100% - 70px) / 2);

      & a {
        display: flex;
        align-items: center;
      }
      
      & p {
        width: 280px;
        font-size: 1.125rem;
        color: #222;
        line-height: 27px;
      }

      & span {
        display: inline-block;
        content: "";
        width: 28px;
        height: 20px;
        text-indent: -9999px;
      }

      &.list_prev {
        & span {
          margin-right: 31px;
          background: url(${list_prev}) no-repeat;
        }
      }

      &.list_next {
        justify-content: flex-end;
        & span {
          margin-left: 31px;
          background: url(${list_next}) no-repeat;
        }
      }
      & .go_list {
        & img {
          width: 60px;
        }
      }
    }
    @media only screen and (max-width: 960px) {
      display: block;
      position: relative;
      margin-top: 0;
      padding: 0 0 90px 0;

      & div {
        display: block;
        width: 100%;
        padding: 20px 10px;
        &:first-child {
          border-bottom: 1px solid #E5E5E5;
        }
        &:last-child {
          border-bottom: 1px solid #515355;
        }

        & a {
          display: flex;
          width: 100%;
          align-items: center;
        }
        
        & p {
          display: -webkit-box;
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 1rem;
          line-height: 24px;
        }

        & span {
          display: inline-block;
          margin-right: 20px;
          content: "";
          width: auto;
          height: auto;
          text-indent: inherit;
          color: #999;
          font-size: 0.875rem;
          line-height: 21px;
          white-space: nowrap;
        }

        &.list_prev {
          & span {
            margin-right: 20px;
            background: none;
          }
        }

        &.list_next {
          justify-content: left;
          & a {
            flex-direction: row-reverse;
            justify-content: left;
          }
          & span {
            margin-left: 0;
            background: none;
          }
        }
      }
    }

    & .go_list {
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      & img {
        width: 60px;
      }
    }
  }
`;

const EventDetailContainer = styled.div`
  padding: 100px 0;

  & .event_title_wrap {
    padding: 30px 20px;
    border-bottom: 1px solid #DDDDDD;

    & .sub_tit {
      margin-bottom: 6px;
      font-size: 1rem;
      color: #222;
      line-height: 24px;
    }

    & .main_tit {
      font-size: 1.625rem;
      line-height: 39px;
      color: #222;
    }
  }

  & .event_cont {
    padding: 60px 40px 0 40px;
    
    & p{
        font-size: 1rem;
        line-height: 24px;
        color: #222;
        word-break: break-all;
      }

    & img {
      display: inline-block;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 960px) {
    padding: 40px 15px;

    & .event_title_wrap {
      border-top: 1px solid #555;
      padding: 20px 0;

      & .sub_tit {
        margin-bottom: 5px;
        font-size: 0.875rem;
        line-height: 20px;
      }

      & .main_tit {
        font-size: 1.125rem;
        line-height: 25px;
        font-weight: 500;
      }
    }

    & .event_cont {
      padding: 30px 0;
    }
  }
`;

export default EventDetail;