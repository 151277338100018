import React, { useEffect, useState, useCallback } from "react";
import Item from "./components/Item";
import styled from "styled-components";
import SearchArea from "pages/components/SearchArea";
import axios from "axios";
import QueryString from "qs";

const SearchListsPage = ({location}) => {
    const [baseuri, setBase64] = useState("");
    const [itemsLists, setItemsLists] = useState([]);
    const [limit, setLimit] = useState(8);
    const [total, setTotal] = useState(0);
    const queryData = QueryString.parse(window.location.search, {ignoreQueryPrefix: true});
    const query = baseuri ? JSON.parse(Buffer.from(baseuri, "base64").toString('utf8')).params : null
    const handleSearch = useCallback( async () => {
        const res = await axios.get( `${process.env.REACT_APP_API_URL}api/product/search?base=${queryData.base}`);
        setBase64("")
        setTotal(res.data.total);
        setItemsLists(res.data.data);
    })
    useEffect(() => {
        setTotal(0);
        setItemsLists([]);
        setLimit(8);
        handleSearch()
    },[location])

    const moreView = async () => {
        let decoded_base64 = Buffer.from(queryData.base, "base64").toString('utf8');
        let json_data = JSON.parse(decoded_base64)
        let start = 0;
        if (!json_data.params.start) {
            start = json_data.params.start;
        }
        let base64 = Buffer.from(JSON.stringify({
            params: {
                search: json_data.params.search,
                start: (parseInt(json_data.params.start) + 8),
                limit: (parseInt(json_data.params.start) + 16)
            }
        }, "utf8")).toString('base64')
        setBase64(base64)
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/product/search?base=${encodeURIComponent( base64)}`);
        setTotal(res.data.total);
        setItemsLists([...itemsLists,...res.data.data]);
    }

    return (
        <SearchListContainer>
            <SearchArea state={location.state}/>
            <div className="container center">
                <>
                    {itemsLists.length !== 0 ? <p className="item_cnt">총 <b>{total}</b>개 상품</p> : null}
                    <div className="itemList">
                        {itemsLists.length !== 0 ? (
                            itemsLists.map((item, idx) => {
                                return (
                                    <Item key={idx} item={item}/>
                                )})
                        ) : (
                            <div className="noList">
                                <p>검색결과가 없습니다</p>
                                <p>다른 검색어를 입력해 제품을 찾아보세요.</p>
                            </div>
                        )}

                        {
                            (query && query.limit ? query.limit :8) < total ?
                                total > 8 ? <button className="moreView" onClick={() => {moreView()}} >더보기 +</button> :
                                    null :
                                null
                        }
                    </div>
                </>
            </div>
        </SearchListContainer>
    );
};

const SearchListContainer = styled.div`
  margin-top: 60px;
  @media only screen and (max-width: 960px) {
    margin-top: 15px;
  }
  & .container {
    & .itemList {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      margin: 20px auto 100px;

      @media only screen and (max-width: 960px) {
        padding: 0 15px;
        margin: 40px auto 100px;
        width: 100%;
      }
    }
    & .item_cnt {
      margin-top: 40px;
      font-size: 0.875rem;
      color: #888;
      line-height: 21px;
      & b {
        color: #222;
        font-weight: 500;
      }
      @media only screen and (max-width: 960px) {
        margin-bottom: 10px;
        margin-left: 16px;
        font-size: 0.75rem;
        line-height: 18px;
      }
    }
  }

  & .noList {
    width: 100%;
    border-bottom: none;
  }

  & .moreView {
    cursor: pointer;
    padding: 18px 50px;
    width: 200px;
    margin: 100px auto 50px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 63px;
    font-size: 1.125rem;
    line-height: 27px;
    color: #222;
    font-weight: 500;
    @media only screen and (max-width: 960px) {
      padding: 16px 60px;
      margin: 60px auto 80px;
      width: auto;
      font-size: 0.875rem;
      line-height: 21px;
    }
  }
`;

export default SearchListsPage;
