import React, {useState, useEffect, useCallback} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import Button from './components/Button';
import arr_more from "images/arr_more.png";
import { MdClose } from "react-icons/md";
import {useSelector, useDispatch } from "react-redux"
import {adjustSessionToAuth, removeFromCart} from "actions/cartActions.js";
import {addToOrder} from "../actions/orderActions";
import OptionPopup from "./components/OptionPopup";
import axios from "axios"
import {stringPrice} from "utils/StringPrice";
import {BsDot} from "react-icons/bs";
import {SET_CART_NOMAL, SET_CART_REGUALAR} from "../actions/types";
import OptionRegularPopup from "./components/OptionRegularPopup";
import Dialog from "./components/Dialog";

const ShoppingcartPage = (location) => {
    const dispatch = useDispatch();
    const isPc = useMediaQuery({ query: '(min-width: 960px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const {loading,userInfo} = useSelector((state) => state.userLogin);  //유저 토큰 조회
    const [checkedItems, setCheckedItems] = useState([]);  //밀리 체크박스 선택
    const [checkRadio, setCheckRadio] = useState([]); //배송 체크박스 선택
    const [cartItems, setCartItems] = useState([]);  //로그인 한 유저의 장바구니 목록 상태값 관리
    const [cartRegItems, setCartRegItems] = useState([]);  //로그인 한 유저의 배송 장바구니 목록 상태값 관리
    const [healthDevice, setHealthDevice] = useState([]);  //헬스케어디바이스 관리
    const [limit, setLimit] = useState(10); //더보기 리미트
    const [regularDelivery, setRegularDelivery] = useState(false); // 일반배송,정기배송 탭
    const item = JSON.parse(localStorage.getItem("cartItems")) || []; //세션에 저장된 장바구니 가져오기
    const [change, setChange] = useState(false); //옵션이 변경되었는지 확인
    const [isOptionChange, setIsOptionChage] = useState(false);
    const [isOptionRegularChange, setIsOptionRegularChage] = useState(false);
    const [updateShipping, setUpdateShipping] = useState(0);
    const [isSetAllCheck, setIsSetAllCheck] = useState(false);
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedNomaltems, setSelectedNomaltems] = useState({
        price:0,
        discount:0,
        shipprice: 0,
        shippricecontition: 0,
        totalprice:0,
    });
    const [selectedRegulartems, setSelectedRegularItems] = useState({
        price:0,
        discount:0,
        shipprice: 0,
        shippricecontition: 0,
        totalprice:0,
    });

    const [ dialog, setDialog ] = useState({
        open: false,
        confirmText: "",
        cancelText: "",
        dialogContent: "",
        btnHide: false
    });

    const onClose = () => {
       setDialog({...dialog, open: false});
    }

    const maxFreeShippingPrice = useCallback(() => {
        let maxPrice = 0;
        if (item.length >= 1) {
            for (let i = 0; i < item.length; i++) { // 장바구니에 담긴 목록
                for (let j = 0; j < checkedItems.length; j++) { // 선택된 장바구니 목록
                    if (item[i].id === checkedItems[j]) { // id값 비교
                        if (item[i].Product.is_free_shipping) {
                            return 0
                        }
                        maxPrice = Math.max(maxPrice, item[i].Product.free_shipping_price); // 선택된 장바구니들의 제일높은 무료배송값 찾기
                        break;
                    }
                }
            }
        } else {
            for (let i = 0; i < cartItems.length; i++) { // 장바구니에 담긴 목록
                for (let j = 0; j < checkedItems.length; j++) { // 선택된 장바구니 목록
                    if (cartItems[i].id === checkedItems[j]) { // id값 비교
                        if (cartItems[i].Product.is_free_shipping) {
                            return 0
                        }
                        maxPrice = Math.max(maxPrice, cartItems[i].Product.free_shipping_price); // 선택된 장바구니들의 제일높은 무료배송값 찾기
                        break;
                    }
                }
            }
        }
        return maxPrice;
    }, [checkedItems, cartItems])
    const getCartList = useCallback(async () => {
        if (!userInfo.data) {
            return
        }
        const config={
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
            },
        }
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/order/cart`, config); //장바구니 AJAX 요청
        setHealthDevice(res.data.devices);
        setCartItems(res.data.carts);
        setCartRegItems(res.data.regular_carts);
    }, [userInfo])

    let notRegItems  = [];
    let regItems = [];
    if (!userInfo.data) {
        notRegItems = item.filter((el) => el.regular === false);  //밀리 세션 아이템
        regItems = item.filter((el) => el.regular === true); //배송 세션 아이템
    }

    useEffect(() => {
        if (regularDelivery) {
            setCheckedItems([]);
        } else {
            setCheckRadio([]);
        }
        if(checkedItems.length === cartItems.length){
            setSelectedAll()
        }
    }, [regularDelivery])
    useEffect(() => {
        if (isOptionChange || isOptionRegularChange) {
            document.body.style.overflowY = "hidden"
        } else {
            document.body.style.overflowY = ""
        }
    }, [isOptionChange, isOptionRegularChange])
    useEffect(() => {
        let price = 0;
        let discountPrice = 0;
        let ShipPrice = 0;
        let totalPrice = 0;

        if (userInfo.data) { // 회원
            for (let i = 0; i < cartRegItems.length; i++) {
                if (checkRadio.includes(cartRegItems[i].id)) {
                    price += (cartRegItems[i].price * cartRegItems[i].quantity);
                }
            }
            if (price > 50000) {
                ShipPrice = 0;
            }
            totalPrice += price + ShipPrice;
            setSelectedRegularItems({
                shipprice: ShipPrice,
                price: price,
                discount: discountPrice,
                totalprice: totalPrice,
            })
            dispatch({type: SET_CART_NOMAL, payload:cartItems})
            dispatch({type: SET_CART_REGUALAR, payload:cartRegItems})
        } else {
            for (let i = 0; i < regItems.length; i++) {
                if (checkRadio.includes(regItems[i].id)) {
                    price += regItems[i].Options[0].price * regItems[i].Options[0].quantity;
                }
            }
            if (price >= 50000) {
                ShipPrice = 0;
            }
            totalPrice += price + ShipPrice;
            setSelectedRegularItems({
                shipprice: ShipPrice,
                price: price,
                discount: discountPrice,
                totalprice: totalPrice,
            })
        }
    }, [checkRadio, cartRegItems, updateShipping])
    useEffect(() => {
        if (!isSetAllCheck) {
            if (cartItems.length + notRegItems.length > 1) {
                handleAllCheck(true)
                setIsSetAllCheck(true)
            }
        }
        let price = 0;
        let discountPrice = 0;
        let ShipPrice = 0;
        let totalPrice = 0;
        let shippricecontition = 0;
        let optionsRequireCount = 0;
        let optionsCount = 0;
        let optionValue = 0;
        if (item.length >= 1) { // 비회원
            for (let i = 0; i < item.length; i++) {
                if (item[i].regular) {
                    continue
                }
                if (checkedItems.includes(item[i].id)) {
                    optionsRequireCount = 0;
                    optionValue = 0;
                    ShipPrice = item[i].Product.shipping_price;
                    shippricecontition = item[i].Product.free_shipping_price;
                    item[i].Product.selectedOption.map(v => {
                        if (v.require) {
                            totalPrice += (item[i].Product.sell_price + v.price) * v.quantity;
                            optionsRequireCount += v.quantity
                        } else {
                            totalPrice += v.price * v.quantity;
                        }
                        optionsCount += v.quantity;
                        optionValue += v.price * v.quantity
                    })
                    discountPrice += (item[i].Product.price - item[i].Product.sell_price) * optionsRequireCount
                    price += item[i].Product.price * optionsRequireCount;
                    price += optionValue;
                }
            }

            console.log(totalPrice, maxFreeShippingPrice())
            if (totalPrice >= maxFreeShippingPrice()) {
                ShipPrice = 0;
            }
            totalPrice += ShipPrice;
            setSelectedNomaltems({
                shipprice: ShipPrice,
                price: price,
                discount: discountPrice,
                shippricecontition: shippricecontition,
                totalprice: totalPrice,
            })
        } else { // 회원
            for (let i = 0; i < cartItems.length; i++) {
                optionsRequireCount = 0;
                optionValue = 0;
                if (checkedItems.includes(cartItems[i].id)) {
                    ShipPrice = cartItems[i].Product.shipping_price;
                    shippricecontition = cartItems[i].Product.free_shipping_price;
                    cartItems[i].SelectedOptions.map(v => {
                        if (v.require) {
                            totalPrice += (cartItems[i].Product.sell_price + v.price) * v.quantity;
                            optionsRequireCount += v.quantity
                        } else {
                            totalPrice += v.price * v.quantity;
                        }
                        optionsCount += v.quantity;
                        optionValue += v.price * v.quantity
                    })
                    discountPrice += (cartItems[i].Product.price - cartItems[i].Product.sell_price) * optionsRequireCount
                    price += cartItems[i].Product.price * optionsRequireCount;
                    price += optionValue;
                }
            }

            if (totalPrice >= maxFreeShippingPrice()) {
                ShipPrice = 0;
            }

            totalPrice += ShipPrice;

            setSelectedNomaltems({
                shipprice: ShipPrice,
                price: price,
                shippricecontition: shippricecontition,
                discount: discountPrice,
                totalprice: totalPrice,
            })
        }
        if (userInfo.data) {
            dispatch({type: SET_CART_NOMAL, payload:cartItems})
            dispatch({type: SET_CART_REGUALAR, payload:cartRegItems})
        }
    }, [checkedItems, cartItems, updateShipping])


    useEffect(async () => {
        if (loading){return}

        if (item) {
            if (item.length >= 0) {
                item.forEach((el) => {
                    let data = {product_id: el.ProductId, Options : el.Options, regular: el.regular}
                    dispatch(adjustSessionToAuth({data}))
                    localStorage.removeItem('cartItems');

                    getCartList();
                });
            }
        }

        await getCartList();
        if(location.location.hash == "#regular"){
            setRegularDelivery(true)
        }
    },[userInfo])

    useEffect(() => {
        const handleVisibilityChange = async () => {
            if (document.visibilityState === 'visible') {
               await getCartList();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const handleCheckChange = useCallback((checked, id, soldOutCheck) => {
        if (userInfo.data) {
            if(checked){
                if(!soldOutCheck){
                    setCheckedItems([...checkedItems, id]);
                    setCheckRadio([])
                }
            }else{
                setCheckedItems(checkedItems.filter((el) => el !== id));
            }
        } else {
            if(checked){
                setCheckedItems([...checkedItems, id]);
                setCheckRadio([])
            }else{
                setCheckedItems(checkedItems.filter((el) => el !== id));
            }
        }
    }, [userInfo, checkedItems]);

    const handleCheckRadio = useCallback((checked, id, soldOutCheck) => {
        if(checked){
            if (!soldOutCheck) {
                setCheckRadio([id]);
            }
            setCheckedItems([])
        } else {
            setCheckRadio(checkRadio.filter((el) => el !== id));
        }
    }, [])

    const handleAllCheck = useCallback((checked) => {
        setSelectedAll((preveState) => !preveState)
        if(checked){
            let cartItemSelected = [];

            setCheckRadio([])
            if (!userInfo.data) {
                setCheckedItems([...notRegItems].map((el) => el.id));
            } else{
                for(let i = 0; i < cartItems.length; i++){
                    const item = cartItems[i]

                    if(item.Product.is_soldout || item.Product.sell){
                        cartItemSelected.push(item)
                    }

                }
                setCheckedItems([...cartItemSelected].map((el) => el.id));
            }
        }else{
            setCheckedItems([]);
        }
    }, [notRegItems, cartItems])

    const moreView = useCallback(() => {
        setLimit(prevState => prevState + 10);
    }, [])

    const handleDelete = useCallback(async (CartId, type) => {
        var ids = [];
        try{
            if(userInfo.data){
                if(Array.isArray(CartId)){
                    if (CartId.length === 0) {
                        return
                    }
                    if (type === "nomal") {
                        CartId = checkedItems
                    } else {
                        CartId = checkRadio
                    }
                    if (type === "nomal") {
                        for (let i = 0; i < cartItems.length; i++) {
                            if (checkedItems.includes(cartItems[i].id)) {
                                ids.push(cartItems[i].id);
                            }
                        }
                    } else {
                        for (let i = 0; i < cartRegItems.length; i++) {
                            if (checkRadio.includes(cartRegItems[i].id)) {
                                ids.push(cartRegItems[i].id);
                            }
                        }
                    }
                }else{
                    ids.push(CartId);

                }
                await axios.delete(`${process.env.REACT_APP_API_URL}api/order/cart`, {
                    headers: {
                        "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
                    },
                    data: {
                        id: ids,
                        type: type
                    }
                })
                await getCartList()

                if (type === "nomal") {
                    if(Array.isArray(CartId)){
                        const remove = cartItems.filter(v => !checkedItems.includes(v.id))

                        setCheckedItems([]);
                    } else {
                        let removeIdx = checkedItems.findIndex( (v) => v === CartId);
                        if (removeIdx >= 0) {
                            checkedItems.splice(removeIdx, 1);
                            setCheckedItems(checkedItems.filter(v =>  v !== cartItems[removeIdx].id));
                        }
                    }
                } else {
                    setCheckRadio([]);
                }
            } else {
                if (type === "nomal") {
                    if(Array.isArray(CartId)){
                        const remove = notRegItems.filter(v => !checkedItems.includes(v.id))
                        const removeitem = notRegItems.filter(v => checkedItems.includes(v.id))
                        let checkboxitem = [...checkedItems];
                        for (let i = 0; i < removeitem.length; i++) {
                            dispatch(removeFromCart(removeitem[i].id));
                        }
                        setCheckedItems([]);
                    } else {
                        let removeIdx = notRegItems.findIndex( (v) => v.id === CartId);
                        if (removeIdx >= 0) {
                            dispatch(removeFromCart(notRegItems[removeIdx].id));
                            setCheckedItems(checkedItems.filter(v =>  v !== notRegItems[removeIdx].id));
                        }
                    }
                } else  {
                    if(Array.isArray(CartId)){
                        const remove = regItems.filter(v => !checkRadio.includes(v.id))
                        const removeitem = regItems.filter(v => checkRadio.includes(v.id))
                        let checkboxitem = [...checkRadio];
                        for (let i = 0; i < removeitem.length; i++) {
                            dispatch(removeFromCart(removeitem[i].id));
                        }
                    } else {
                        let removeIdx = regItems.findIndex( (v) => v.id === CartId);
                        if (removeIdx >= 0) {
                            dispatch(removeFromCart(regItems[removeIdx].id));
                        }
                    }
                    setCheckRadio([]);
                }
            }

        }catch(err){
            console.log("삭제 오류", err)
        }
    }, [userInfo, cartItems, cartRegItems, regItems, notRegItems, checkedItems, checkRadio])


    const Purchase = useCallback((e) => {
        let isRegular = false;
        let selectedItems = [];
        let removeSoldout = [];
        if (cartItems.length >= 0) {
            for (let i = 0; i < cartItems.length; i++) {
                if (checkedItems.includes(cartItems[i].id)) {
                    if (cartItems[i].Product.is_soldout) {
                        removeSoldout.push(cartItems[i].id)
                    }
                }
            }
            if (removeSoldout.length >= 1) {
                const removed = cartItems.filter(v => !removeSoldout.includes(v.id))
                setCheckedItems([...removed]); // 품절된 상품 자동 체크해제
            }
            for (let i = 0; i < cartItems.length; i++) {
                if (checkedItems.includes(cartItems[i].id)) {
                    selectedItems.push(cartItems[i])
                }
            }

            let dialogText1 = ``;
            let dialogText2 = ``;
            let calculatedOptipons = {}; 
            // 상품 재고 수량 체크
            for (let i = 0; i < selectedItems.length; i++) {
                let optionsCount = 0;

                for (let j = 0; j < selectedItems[i].SelectedOptions.length; j++) {
                    const opt = selectedItems[i].SelectedOptions[j];
                    const optionId = opt.OptionId;
                    const selectedOption = selectedItems[i].Product.OptionGroups[0].Options.filter((item) => {
                        return optionId === item.id
                    })
                    let optionSku = selectedOption[0].sku;
                    let countInPackage = 1;

                    if (optionSku.slice(-3, optionSku.length).search(/_[1-9]P/) > -1) {
                        countInPackage = Number(optionSku.slice(-2, optionSku.length-1));
                    }
                    optionsCount += (countInPackage * opt.quantity);
                }

                let sku = selectedItems[i].Product.sku;
                if (calculatedOptipons[sku]) {
                    calculatedOptipons[sku].quantity += optionsCount;
                } else {
                    calculatedOptipons[sku] = {
                        "name": selectedItems[i].Product.name, 
                        "stock": selectedItems[i].Product.stock,
                        "quantity": optionsCount,
                        "max_quantity": selectedItems[i].Product.max_quantity};
                }
            }

            for (const key in calculatedOptipons) {
                const productName = calculatedOptipons[key].name;
                const productStock = calculatedOptipons[key].stock;
                const productQuantity = calculatedOptipons[key].quantity;
                const productMaxQuantity = calculatedOptipons[key].max_quantity;

                if (productStock - productQuantity < 0) {
                    if (dialogText1.length > 0) {
                        dialogText1 += `\n\n`;
                    }
                    dialogText1 += `${productName}\n상품 재고 수량이 부족합니다.\n(재고 수량: ${productStock})`;
                }

                if (productMaxQuantity > 0 && productMaxQuantity < productQuantity) {
                    dialogText2 = `${productName} 상품은\n최대 구매수량이 ${productMaxQuantity}개로 제한된 상품입니다.`;
                }
            }

            if (dialogText1.length > 0) {
                 return setDialog({...dialog, open: true, btnHide: true, cancelText: "확인", dialogContent: dialogText1});
            }

            if (dialogText2.length > 0) {
                 return setDialog({...dialog, open: true, btnHide: true, cancelText: "확인", dialogContent: dialogText2});
            }
        }
        if (cartRegItems.length >= 0) {
            for (let i = 0; i < cartRegItems.length; i++) {
                if (checkRadio.includes(cartRegItems[i].id)) {
                    if (cartRegItems[i].Product.is_soldout) {
                        removeSoldout.push(cartRegItems[i].id)
                    }
                }
            }

            if (removeSoldout.length >= 1) {
                const removed = cartRegItems.filter(v => !removeSoldout.includes(v.id))
                setCheckRadio([...removed]); // 품절된 상품 자동 체크해제
            }

            for (let i = 0; i < cartRegItems.length; i++) {
                if (checkRadio.includes(cartRegItems[i].id)) {
                    cartRegItems[i].regular=true
                    selectedItems.push(cartRegItems[i])
                    isRegular = true;
                }
            }
        }

        if(userInfo.data){
            if (isRegular) {
                dispatch(addToOrder(selectedItems, selectedRegulartems, healthDevice));
            } else {
                dispatch(addToOrder(selectedItems, selectedNomaltems, healthDevice));
            }
        }else{
            document.location.href="/login"
        }
    }, [userInfo, cartItems, cartRegItems, checkedItems, checkRadio, selectedRegulartems, selectedNomaltems])



    // 스크롤시 오른쪽 결제금액 높이조절
    const [ScrollActive, setScrollActive] = useState(false);

    //옵션변경 팝업
    const [optionLayer, setOptionLayer] = useState({
        open: false,
        cart: {},
        cartId: ""
    });
    const [optionRegularLayer, setOptionRegularLayer] = useState({
        open: false,
        cart: {},
        cartId: ""
    });
    const calcNomalProductPrice = useCallback((el) => {
        const shippingPriceCondition = el.Product.free_shipping_price;
        const shippingPrice = el.Product.shipping_price;
        let optionPrice = el.SelectedOptions.reduce((acc, cur) => {
            if (cur.require) {
                return acc + ((el.Product.sell_price + cur.price) * cur.quantity);
            } else {
                return acc + (cur.price * cur.quantity);
            }
        }, 0)

        return optionPrice
    }, [])

    const calcNomalProductDiscountPrice = useCallback((el) => {
        const shippingPriceCondition = el.Product.free_shipping_price;
        const shippingPrice = el.Product.shipping_price;
        let optionPrice = el.SelectedOptions.reduce((acc, cur) => {
            if (cur.require) {
                return acc + ((el.Product.price + cur.price) * cur.quantity);
            } else {
                return acc + (cur.price * cur.quantity);
            }
        }, 0)

        return optionPrice
    }, [])
    const calcNouserNomalProductPrice = useCallback((el) => {
        const shippingPriceCondition = el.Product.free_shipping_price;
        const shippingPrice = el.Product.shipping_price;
        let optionPrice = el.Product.selectedOption.reduce((acc, cur) => {
            if (cur.require) {
                return acc + ((el.Product.sell_price + cur.price) * cur.quantity);
            } else {
                return acc + (cur.price * cur.quantity);
            }
        }, 0)

        return optionPrice
    }, [])
    const calcNouserNomalProductDiscountPrice = useCallback((el) => {
        const shippingPriceCondition = el.Product.free_shipping_price;
        const shippingPrice = el.Product.shipping_price;
        let optionPrice = el.Product.selectedOption.reduce((acc, cur) => {
            if (cur.require) {
                return acc + ((el.Product.price + cur.price) * cur.quantity);
            } else {
                return acc + (cur.price * cur.quantity);
            }
        }, 0)

        if (optionPrice <= shippingPriceCondition) {
            optionPrice += shippingPrice;
        }
        return optionPrice
    }, [])
    const calcRegularProductPrice = useCallback((el) => {
        const shippingPriceCondition = 50000;
        const shippingPrice = 2500;
        let optionPrice = 0;
        if (el.Options) {
            optionPrice = el.Options[0].price * el.Options[0].quantity;
        } else {
            optionPrice = el.price * el.quantity
        }

        return optionPrice
    }, [])
    const calcRegularProductDiscountPrice = useCallback((el) => { // 미구현
        const shippingPriceCondition = 50000;
        const shippingPrice = el.Product.shipping_price;
        let optionPrice = el.SelectedOptions.reduce((acc, cur) => {
            if (cur.require) {
                return acc + ((el.Product.price + cur.price) * cur.quantity);
            } else {
                return acc + (cur.price * cur.quantity);
            }
        }, 0)
        if (optionPrice <= shippingPriceCondition) {
            optionPrice += shippingPrice;
        }
        return optionPrice
    }, [])

    return (
        <ShoppingcartWrapper className="center">
            <Dialog
                confirmText={`${dialog.confirmText}`}
                cancelText={`${dialog.cancelText}`}
                onConfirm = {onClose}
                onCancel={onClose}
                onClose={onClose}
                dialogSize="small"
                visible={dialog.open}
                btnHide={dialog.btnHide}
                className="cartLayer">
                <p style={{whiteSpace: "pre-wrap"}}>{dialog.dialogContent}</p>
            </Dialog>

            <div className="wrap_title">
                <h2>장바구니</h2>
                <div className="purchase_step">
                    <ul>
                        <li className="active">01. 장바구니</li>
                        <li>02. 주문/결제</li>
                        <li>03. 결제완료</li>
                    </ul>
                </div>
            </div>
            <ul className="delivery_type">
                <li className={regularDelivery ? null : "active"}><div onClick={() => setRegularDelivery(false)}><span>일반배송 ({cartItems.length + notRegItems.length})</span></div></li>
                {/* 독립몰 serect 정기배송 관련 hidden 처리 */}
                {/* <li className={regularDelivery ? "active" : null}><div onClick={() => {setRegularDelivery(true); setSelectedAll(false);}}><span>정기배송 ({cartRegItems.length + regItems.length})</span></div></li> */}
            </ul>
            { isMobile && <p className="noArea"></p>}
            <div className="shopping_wrap">
                <div className="shopping_left">
                    <div className="sub_tit_wrap">
                        {regularDelivery ?
                            <div className="guide dot_guide"><span><BsDot /></span><span>정기배송은 1회 1개만 가능해요! {isMobile && <br />}하나를 먼저 결제 후에 새로 신청해주세요!</span></div>
                            :
                            <div className="checks full_check">
                                {
                                    cartItems.length + notRegItems.length === 0 ?
                                        <input type="checkbox" id="all_check" checked={false} onChange={(e) => null}/>:
                                        <input type="checkbox" id="all_check" checked={selectedAll}  onChange={(e) => handleAllCheck(e.target.checked)}/>
                                }
                                <label htmlFor="all_check">전체선택 ({checkedItems.length}/{cartItems.length + notRegItems.length})</label>
                            </div>
                        }
                        {regularDelivery ?
                            <button onClick={() => handleDelete(checkRadio, 'regular')}>선택삭제</button>
                            :
                            <button onClick={() => handleDelete(checkedItems, 'nomal')}>선택삭제</button>
                        }
                    </div>
                    <ul>
                        {regularDelivery ?
                            <React.Fragment>
                                {cartRegItems ? cartRegItems.length >= 0 ?
                                    cartRegItems.slice(0, limit).map((el) => {
                                        let soldOutCheck = false;

                                        if (el.Product.is_soldout) {
                                            soldOutCheck = true;
                                        } else if (!el.Product.sell) {
                                            soldOutCheck = true;
                                        }
                                        soldOutCheck = !el.Product.sell

                                        return <li key={el.id}>
                                            <div className="checks full_check">
                                                <input type='checkbox' name="reg_delivery_item" id={el.id}
                                                       onChange={(e) => {
                                                           handleCheckRadio(e.target.checked, el.id, soldOutCheck)
                                                       }}
                                                       checked={checkRadio.includes(el.id) ? true : false}
                                                />
                                                <label htmlFor={el.id}></label>
                                            </div>
                                            <div className="item_box">
                                                <div className="item_info_wrap">
                                                    <div className="item_img">
                                                        <Link to={`/products/details/regular/${el.ProductId}`}>
                                                            <img src={el.Product.main_img} className="item_img"
                                                                 alt="제품이미지"/>
                                                            {soldOutCheck ? <div className="dimmed">품절</div> : null}
                                                        </Link>
                                                    </div>
                                                    {isMobile && <div>
                                                        <p>{el.Product.name}</p>
                                                        {
                                                            soldOutCheck ?
                                                                <p className="price_cnt"><i>품절</i></p> :
                                                                <p className="price_cnt">
                                                                    {stringPrice(calcRegularProductPrice(el))}
                                                                    원/ {el.quantity}개
                                                                </p>
                                                        }
                                                    </div>}
                                                </div>
                                                <div className="item_wrap">
                                                    <div className="item_option_wrap">
                                                        <Link to={`/products/details/regular/${el.ProductId}`}>
                                                            {isPc && <p>{el.Product.name}</p>}
                                                        </Link>
                                                        <div className="option_wrap" key="">
                                                            {<p key={el.id}>배송 주기 : {el.cycle}주<b>({el.quantity}개)</b></p>}
                                                        </div>
                                                        <button className="option_btn"
                                                                onClick={() => {
                                                                    setIsOptionRegularChage(true);
                                                                    setOptionRegularLayer({
                                                                        ...optionRegularLayer,
                                                                        open: true,
                                                                        cart: el,
                                                                        cartId: el.ProductId
                                                                    })
                                                                }}>옵션변경
                                                        </button>
                                                    </div>
                                                    <div className="item_price">
                                                        {
                                                            soldOutCheck ?
                                                            <p className="price"><i>품절</i></p> :
                                                            <p className="price">
                                                                {
                                                                    stringPrice(calcRegularProductPrice(el))
                                                                }
                                                                <i>원</i>
                                                            </p>
                                                        }
                                                    </div>
                                                    <span className="item_del" onClick={() => {
                                                        handleDelete(el.id, 'regular')
                                                    }}><MdClose alt="삭제" size={23} color={isPc ? "#ccc" : "#888"}/></span>
                                                </div>
                                            </div>
                                        </li>
                                    }) : null : null
                                }
                                {regItems ? regItems.length >= 0 ?
                                    regItems.slice(0, limit).map((el) => {
                                        let soldOutCheck = false;
                                        if (el.Product.is_soldout) {
                                            soldOutCheck = true;
                                        } else if (!el.Product.sell) {
                                            soldOutCheck = true;
                                        }
                                        return <li key={el.id}>
                                            <div className="checks full_check">
                                                <input type='checkbox' name="reg_delivery_item" id={el.id}
                                                       onChange={(e) => {
                                                           handleCheckRadio(e.target.checked, el.id, soldOutCheck)
                                                       }}
                                                       checked={checkRadio.includes(el.id) ? true : false}
                                                />
                                                <label htmlFor={el.id}></label>
                                            </div>
                                            <div className="item_box">
                                                <div className="item_info_wrap">
                                                    <div className="item_img">
                                                        <Link to={`/products/details/regular/${el.ProductId}`}>
                                                            <img src={el.Product.main_img} className="item_img"
                                                                 alt="제품이미지"/>
                                                            {soldOutCheck ? <div className="dimmed">품절</div> : null}
                                                        </Link>

                                                    </div>
                                                    {isMobile && <div>
                                                        <p>{el.Product.name}</p>
                                                        <p className='price_cnt'><b>13,000</b>원 / 1개</p>
                                                    </div>}
                                                </div>
                                                <div className="item_wrap">
                                                    <div className="item_option_wrap">
                                                        <Link to={`/products/details/regular/${el.ProductId}`}>
                                                            {isPc && <p>{el.Product.name}</p>}
                                                        </Link>
                                                        <div className="option_wrap" key="">
                                                            {<p key={el.id}>배송 주기 : {el.Options[0].cycle}주<b>({el.Options[0].quantity}개)</b></p>}
                                                        </div>
                                                        <button className="option_btn"
                                                                onClick={() => {
                                                                    setIsOptionRegularChage(true);
                                                                    setOptionRegularLayer({
                                                                        ...optionRegularLayer,
                                                                        open: true,
                                                                        cart: el,
                                                                        cartId: el.ProductId
                                                                    })
                                                                }}>옵션변경
                                                        </button>
                                                    </div>
                                                    <div className="item_price">
                                                        {soldOutCheck ?
                                                            <p className="price"><i>품절</i></p> :
                                                            <p className="price">{ stringPrice(calcRegularProductPrice(el))}<i>원</i></p>
                                                        }
                                                    </div>
                                                    <span className="item_del" onClick={() => {
                                                        handleDelete(el.id, 'regular')
                                                    }}><MdClose alt="삭제" size={23} color={isPc ? "#ccc" : "#888"}/></span>
                                                </div>
                                            </div>
                                        </li>
                                    }) : null : null
                                }
                                {loading ? item.length === 0 ?
                                        <p className="noList" > 장바구니에 담긴 상품이 없습니다.</p> :
                                        null :
                                    cartRegItems.length === 0 ? <p className="noList" > 장바구니에 담긴 상품이 없습니다.</p>: null}
                                {!loading ?
                                    cartRegItems.length > 10 && cartRegItems.length >= limit ? <button className="moreView" onClick={() => {moreView()}} >더보기 +</button> :
                                        null:
                                    null
                                }
                            </React.Fragment>
                            : <React.Fragment>
                                {cartItems ? cartItems.length >= 0 ?
                                        cartItems.slice(0, limit).map((el) => {
                                            let soldOutCheck = false;

                                            if (el.Product.is_soldout) {
                                                soldOutCheck = true;
                                            } else if (!el.Product.sell) {
                                                soldOutCheck = true;
                                            }
                                            return <li key={el.id}>
                                                <div className="checks full_check">
                                                    <input type='checkbox' id={el.id} onChange={(e) => {handleCheckChange(e.target.checked, el.id, soldOutCheck)}}
                                                           checked={!soldOutCheck && checkedItems.includes(el.id) ? true : false}
                                                    />
                                                    <label htmlFor={el.id}></label>
                                                </div>
                                                <div className="item_box">
                                                    <div className="item_info_wrap">
                                                        <div className="item_img">
                                                          <Link to={`/products/details/normal/${el.ProductId}`}>
                                                              <img src={el.Product.main_img} className="item_img" alt="제품이미지"/>
                                                              {soldOutCheck ? <div className="dimmed">품절</div> : null}
                                                          </Link>
                                                        </div>
                                                        {isMobile && <p>{el.Product.name}</p>}
                                                    </div>
                                                    <div className="item_wrap">
                                                        <div className="item_option_wrap">
                                                            <Link to={`/products/details/normal/${el.ProductId}`}>
                                                                {isPc && <p>{el.Product.name}</p>}
                                                            </Link>
                                                            <div className="option_wrap" key="">
                                                                {el.SelectedOptions.map((e) => <p key={e.id}>{e.name}<b>({e.quantity}개)</b></p>)}
                                                            </div>
                                                            <button className="option_btn" onClick={() => {
                                                                setIsOptionChage(true);
                                                                setOptionLayer({
                                                                    ...optionLayer,
                                                                    open: true,
                                                                    cart: el,
                                                                    cartId: el.ProductId
                                                                })
                                                            }}>옵션변경</button>
                                                        </div>
                                                        <div  className="item_price">
                                                            {soldOutCheck ? <p className="price"><i>품절</i></p> : <>
                                                                <p className="price">{
                                                                    stringPrice(calcNomalProductPrice(el))
                                                                }<i>원</i>
                                                                </p>
                                                                {
                                                                    el.Product.discount_percent >= 1 ?
                                                                        <p className="before_discount">
                                                                            {stringPrice( calcNomalProductDiscountPrice(el)) }원
                                                                        </p> :
                                                                        null
                                                                }
                                                            </>}
                                                        </div>
                                                        <span className="item_del" onClick={() => {handleDelete(el.id, 'nomal')}}><MdClose alt="삭제" size={23} color={isPc ? "#ccc" : "#888"} /></span>
                                                    </div>
                                                </div>
                                            </li>})
                                        : null
                                    : null
                                }
                                {notRegItems ? notRegItems.length >= 0 ?
                                        notRegItems.slice(0, limit).map((el) => {
                                            return <li key={el.id}>
                                                <div className="checks full_check">
                                                    <input type='checkbox' id={el.id} onChange={(e) => {handleCheckChange(e.target.checked, el.id)}}
                                                           checked={checkedItems.includes(el.id) ? true : false}
                                                    />
                                                    <label htmlFor={el.id}></label>
                                                </div>
                                                <div className="item_box">
                                                    <div className="item_info_wrap">
                                                        <Link to={`/products/details/normal/${el.ProductId}`}>
                                                            <img src={el.Product.main_img} className="item_img" alt="제품이미지"/>
                                                            {isMobile && <p>{el.Product.name}</p>}
                                                        </Link>

                                                    </div>
                                                    <div className="item_wrap">
                                                        <div className="item_option_wrap">
                                                            <Link to={`/products/details/normal/${el.ProductId}`}>
                                                                {isPc && <p>{el.Product.name}</p>}
                                                            </Link>
                                                            <div className="option_wrap" key="">
                                                                {el.Product.selectedOption.map((e) => <p key={e.id}>{e.name}<b>({e.quantity}개)</b></p>)}
                                                            </div>
                                                            <button className="option_btn" onClick={() => {
                                                                setIsOptionChage(true);
                                                                setOptionLayer({
                                                                    ...optionLayer,
                                                                    open: true,
                                                                    cart: el,
                                                                    cartId: el.ProductId
                                                                })
                                                            }}>옵션변경</button>
                                                        </div>
                                                        <div  className="item_price">
                                                            <p className="price">{
                                                                stringPrice(calcNouserNomalProductPrice(el))
                                                            }<i>원</i>
                                                            </p>
                                                            <p className="before_discount">
                                                                {stringPrice(calcNouserNomalProductDiscountPrice(el)) }원
                                                            </p>
                                                        </div>
                                                        <span className="item_del" onClick={() => {handleDelete(el.id, 'nomal')}}><MdClose alt="삭제" size={23} color={isPc ? "#ccc" : "#888"} /></span>
                                                    </div>
                                                </div>
                                            </li>})
                                        : null
                                    : null
                                }
                                {!userInfo.data ?  item.length === 0 ? <p className="noList"> 장바구니에 담긴 상품이 없습니다.</p> : null : null }
                                {userInfo.data ?  cartItems.length === 0 ? <p className="noList"> 장바구니에 담긴 상품이 없습니다.</p> : null : null }

                                {item ?
                                    cartItems.length > 10 && cartItems.length >= limit ? <button className="moreView" onClick={() => {moreView()}} >더보기 +</button> :
                                        null:
                                    null
                                }
                            </React.Fragment>
                        }
                    </ul>
                </div>
                { isMobile && <p className="noArea"></p>}
                <div className="shopping_right">
                    <div className="total_price_fix">
                        <div className="price_info">
                            <p>
                                <span>상품금액</span>
                                <span>{stringPrice(selectedNomaltems.price || selectedRegulartems.price)}<i>원</i></span>
                            </p>
                            <p>
                                <span>상품할인금액</span>
                                <span>- {stringPrice(selectedNomaltems.discount  || selectedRegulartems.discount)}<i>원</i></span>
                            </p>
                            <React.Fragment>
                                <p>
                                    <span>배송비</span>
                                    <span>+ {stringPrice(selectedNomaltems.shipprice || selectedRegulartems.shipprice)}<i>원</i></span>
                                </p>
                                {
                                    regularDelivery ?
                                        <>{userInfo ? selectedRegulartems.shipprice !== 0 ? <p className="delivery_msg">{(selectedRegulartems.shippricecontition - (selectedRegulartems.totalprice - selectedRegulartems.shipprice)).toLocaleString()}원 추가주문 시, <b>무료배송</b></p> : null : null }</>:
                                        <>{userInfo ? selectedNomaltems.shipprice !== 0 ? <p className="delivery_msg">{(selectedNomaltems.shippricecontition - (selectedNomaltems.totalprice - selectedNomaltems.shipprice)).toLocaleString()}원 추가주문 시, <b>무료배송</b></p> : null : null }</>

                                }
                            </React.Fragment>
                        </div>
                        <div className="total_price">
                            <p>
                                <span>결제예정금액</span>
                                <span>{stringPrice(selectedNomaltems.totalprice || selectedRegulartems.totalprice ? Number(selectedNomaltems.totalprice + selectedRegulartems.totalprice) : 0)}<i>원</i></span>
                            </p>
                        </div>
                        {checkedItems.length !== 0 || checkRadio.length !== 0 ? <Button size="large" fullWidth onClick={Purchase} className="purchase_btn">구매하기</Button>
                            : <Button size="large" color="gray" fullWidth className="purchase_btn">구매하기</Button>}

                        <p className="cart_info">
                            {!regularDelivery && <>* 쿠폰 및 알갱이 사용은 결제페이지에서 적용이 가능합니다. <br /></> }
                            {regularDelivery && "* 스마트 디바이스는 결제페이지에서 선택이 가능합니다." } <br/>
                            {regularDelivery && "* 쿠폰은 결제페이지에서 사용이 가능합니다." }
                        </p>
                    </div>
                </div>
            </div>
            {!isOptionChange ?
                null :

                <OptionPopup visible={optionLayer.open} selectedCart={optionLayer.cart} selectedCartId={optionLayer.cartId} change={change} setChange={setChange}
                             onCancel={() => {
                                 setIsOptionChage(false);
                                 setOptionLayer(false);
                             }}
                             onChange={() => {
                                 setIsOptionChage(false);
                                 setOptionLayer(false);
                                 setCheckedItems([...checkedItems]);
                                 getCartList()
                                 setUpdateShipping(new Date().getTime());
                             }}
                             setIsOptionChage={setIsOptionChage}

                />
            }
            {!isOptionRegularChange ?
                null :
                <OptionRegularPopup selectedCart={optionRegularLayer.cart} selectedCartId={optionRegularLayer.cartId}
                             onCancel={() => {
                                 setIsOptionRegularChage(false);
                                 setOptionRegularLayer({
                                     open: false,
                                     cart: {},
                                     cartId: ""
                                 });
                             }}
                             onChange={() => {
                                 setIsOptionRegularChage(false);
                                 setOptionRegularLayer({
                                     open: false,
                                     cart: {},
                                     cartId: ""
                                 });
                                 getCartList()
                                 setUpdateShipping(new Date().getTime());
                             }}
                             setIsOptionChage={setIsOptionRegularChage}

                />
            }
        </ShoppingcartWrapper>
    );
};

const ShoppingcartWrapper = styled.div`
  padding: 80px 0;

  & .cartLayer {
    .dialog_wrap {
      overflow-y: initial;
      width: auto;
      & p {
        text-align: center;
      }
    }
  }

  & .wrap_title {
    display: flex;
    align-items: flex-end;
    & .purchase_step {
      margin-left: auto;
      & ul {
        display: flex;
        & li {
          padding-right: 26px;
          line-height: 22.5px;
          color: #888;
          font-size: 0.938rem;
          &:not(:last-child) {
            background: url(${arr_more}) no-repeat right 11px center;
            background-size: 4.5px 9px;
          }
          &.active {
            font-weight: 500;
            color: #222;
          }
        }
      }
    }
  }

  & .delivery_type {
    display: flex;

    & li {
      position: relative;
      // {/* 독립몰 serect 정기배송 관련 hidden 처리 */} -> 정기배송 원복 시 50%로 수정
      flex-basis: 100%;
      & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        cursor: pointer;
      }
      & span {
        display: block;
        font-size: 18px;
      }
      &.active {
        border-bottom: 2px solid #1EA0FA;
        & span {
          color: #1EA0FA;
        }
      }
      &::after {
        position: absolute;
        content: "";
        top: 19px;
        right: 0;
        width: 1px;
        height: 26px;
        background: #E1E2E3;
      }
    }
  }

  & .shopping_wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;

    & .shopping_left {
      width: 1040px;
      & .sub_tit_wrap {
        display: flex;
        align-items: center;
        padding-bottom: 18px;
        border-bottom: 1px solid #ccc;
        & .checks {
          font-size: 1.125rem;
          color: #222;
          line-height: 27px;

          & input[type="checkbox"] + label:before {
            margin-right: 20px;
          }
        }
        & .guide span{
          font-size: 18px;
          line-height: 27px;
          color: #222;
        }

        & > button {
          margin-left: auto;
          color: #515355;
          font-size: 0.875rem;
          line-height: 21px;
          text-decoration: underline;
        }
      }

      & .noList {
        padding: 126px 0;
        border-bottom: 1px solid #eee;
        text-align: center;
        font-size: 1.125rem;
        color: #888;
        line-height: 27px;
        font-weight: 500;
      }
    }

    & ul {
      & li {
        display: flex;
        align-items: flex-start;
        padding: 20px 0;
        border-bottom: 1px solid #eee;

        & .full_check {
          margin-top: 40px;
          margin-right: 10px;
        }

        & .item_info_wrap {
          align-items: flex-start;
          & .item_img {
            position: relative;
            overflow: hidden;
            flex: 100px 0 0;
            width: 100px;
            height: 100px;
            margin-right: 20px;
            &::after {
              content: "";
              display: block;
              padding-bottom: 100%;
            }
            & img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
            & .dimmed {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background: rgba(0,0,0,0.6);
              color: #fff;
              font-size: 18px;
              font-weight: 500;
            }
          }
        }

        & .item_box {
          display: flex;
          align-items: flex-start;
          flex-grow: 1;

          & .item_wrap {
            display: flex;
            align-items: center;
            flex-basis: 866px;
            & .item_option_wrap {
              display: flex;
              & > a {
                display: flex;
              }
              display: flex;
              flex-basis: 640px;
              flex-direction: column;
              & > p {
                display: flex;
                align-items: center;
                font-size: 1rem;
                line-height: 24px;
                color: #222;
                font-weight: 500;
              }
              & .option_wrap {
                margin-top: 5px;
                & p {
                  color: #888;
                  line-height: 21px;
                  font-size: 0.875rem;
                  &:not(:first-child) {
                    margin-top: 2px;
                  }
                  & b {
                    font-weight: 500;
                  }
                }
              }

              & .option_btn {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                height: 27px;
                margin-top: 8px;
                border: 1px solid #ddd;
                border-radius: 2px;
                font-size: 0.75rem;
                color: #515355;
              }
            }

            & .item_price {
              flex-basis: 150px;
              text-align: right;
              & .price {
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 30px;
                color: #222;
                & i {
                  font-size: 1rem;
                  line-height: 24px;
                  font-weight: 400;
                }
              }
              & .before_discount {
                font-size: 0.875rem;
                line-height: 21px;
                color: #888;
                text-decoration: line-through;
              }
            }

            & .item_del {
              cursor:pointer;
              flex-basis: 84px;
              padding-right: 20px;
              text-align: right;
            }
          }
        }
      }
    }

    & .shopping_right {
      position: relative;
      width: 400px;
      margin-left: 20px;
      &.scroll {
        padding-top: 80px;
      }

      & .total_price_fix {
        position: sticky;
        top: 140px;
        left: 0;
        width: 100%;
        padding: 30px;
        background: #f6f6f6;
        &.scroll {
          top: 140px;
        }

        & .price_info {
          padding-bottom: 22px;
          border-bottom: 1px solid #ccc;
          & p {
            display: flex;
            align-items: center;
            &:not(:first-child) {
              margin-top: 13px;
            }

            & span {
              font-size: 1rem;
              color: #222;
              line-height: 24px;
            }

            & span:nth-child(2) {
              margin-left: auto;
              & i {
                font-size: 0.875rem;
                line-height: 21px;
              }
            }
          }

          & .delivery_msg {
            justify-content: flex-end;
            color: #1EA0FA;
            font-size: 0.813rem;
            line-height: 19.5px;
            & b {
              margin-left: 3px;
              font-weight: 700;
            }
          }
        }

        & .total_price {
          margin: 20px 0;
          & p {
            display: flex;
            align-items: center;
          }

          & span {
            color: #222;
          }

          & span:first-child {
            font-size: 1rem;
            color: #222;
            line-height: 24px;
          }

          & span:nth-child(2) {
            font-size: 1.375rem;
            font-weight: 700;
            line-height: 33px;
            margin-left: auto;
            & i {
              font-size: 1.125rem;
              line-height: 27px;
              font-weight: 400;
            }
          }
        }

        & .cart_info {
          margin-top: 10px;
          color: #515355;
          font-size: 0.813rem;
          line-height: 19.5px;
          word-break: break-all;
        }
      }
    }
  }


  @media only screen and (max-width: 960px) {
    padding: 40px 0;

    & .wrap_title {
      display: block;
      padding-bottom: 10px;
      & .purchase_step {
        display: none;
      }
    }

    & .delivery_type {
      & li {
        border-bottom: 2px solid #EEF1F3;
        & div {
          height: 60px;
        }
        & span {
          font-size: 14px;
        }
        &::after {
          content: none;
        }
      }
    }

    & .shopping_wrap {
      display: block;
      margin-top: 30px;

      & .shopping_left {
        width: 100%;

        & .sub_tit_wrap {
          display: flex;
          align-items: flex-end;
          padding: 0 15px 20px 15px;
          border-bottom: 1px solid #ccc;
          & .checks {
            font-size: 1rem;
            line-height: 22px;
            & input[type="checkbox"] + label:before {
              margin-right: 10px;
            }
          }
          & .guide span{
            font-size: 14px;
            line-height: 21px;
            color: #515355;
          }

          & > button {
            line-height: 23px;
          }
        }

        & .noList {
          padding: 160px 0;
          border-bottom: none;
          line-height: 25px;
          font-weight: 400;
        }
      }

      & ul {
        & li {
          display: flex;
          position: relative;
          padding: 20px 15px;
          border-bottom: 1px solid #eee;
          align-items: flex-start;

          & .full_check {
            margin: 0;
          }

          & .item_info_wrap {
            display: flex;
            margin-bottom: 10px;
            & > a {
              display: flex;
            }
            & .item_img {
              flex: 75px 0 0;
              width: 75px;
              height: 75px;
              margin-right: 10px;
              & .dimmed {
                font-size: 0.938rem;
              }
            }
            & p {
              display: block;
              padding-right: 20px;
              line-height: 21px;
              font-weight: 500;
              font-size: 0.938rem;
              color: #222;
            }

            & .price_cnt {
              margin-top: 4px;
              font-size: 0.813rem;
              line-height: 19px;
              font-weight: 400;
              & b {
                font-weight: 700;
              }
            }
          }

          & .item_box {
            display: block;
            width: 100%;
            align-items: Top;

            & .item_wrap {
              align-items: flex-start;
              flex-basis: 100%;
              flex-direction: column;
              & .item_option_wrap {
                flex-basis: 100%;
                width: 100%;
                flex-grow: 1;
                & .option_wrap {
                  margin-top: 0;
                  & p {
                    &:not(:first-child) {
                      margin-top: 4px;
                      padding-top: 4px;
                      border-top: 1px solid #f6f6f6;
                    }
                  }
                }
                & .option_btn {
                  position: absolute;
                  bottom: 20px;
                  left: 49px;
                  margin-top: 0;
                }
              }

              & .item_price {
                flex-basis: auto;
                margin-left: auto;
                margin-top: 21px;
                & .price {
                  line-height: 25px;
                  color: #222;
                  & i {
                    line-height: 22px;
                  }
                }
                & .before_discount {
                  display: none;
                }
              }

              & .item_del {
                position: absolute;
                top: 17px;
                right: 13px;
                flex-basis: auto;
                padding-right: 0;
              }
            }
          }
        }
      }

      & .noArea {
        width: 100%;
        height: 8px;
        background: #f6f6f6;
      }

      & .shopping_right {
        width: 100%;
        margin-left: 0;

        & .total_price_fix {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          padding: 20px 15px;
          background: #fff;
          &.scroll {
            top: 0;
          }

          & .price_info {
            padding-bottom: 20px;
            & p {
              &:not(:first-child) {
                margin-top: 16px;
              }
              & span:first-child {
                font-size: 0.875rem;
                line-height: 20px;
              }
              & span:nth-child(2) {
                font-size: 1rem;
                line-height: 22px;
                & i {
                  line-height: 20px;
                }
              }
              &.delivery_msg {
                margin-top: 6px;
              }
            }
          }



          & .total_price {
            margin: 20px 0 30px;

            & span:first-child {
              line-height: 22px;
            }

            & span:nth-child(2) {
              line-height: 31px;
              & i {
                line-height: 30px;
              }
            }
          }

          & .cart_info {
            line-height: 18px;
          }
        }
      }
    }

    & .purchase_btn {
      margin-top: 0 !important;
    }
  }

  & .moreView {
    cursor: pointer;
    display:flex;
    padding: 18px 50px;
    width: 170px;
    margin: 50px auto 0px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 63px;
    font-size: 1.125rem;
    color: #222;
    font-weight: 500;
    @media only screen and (max-width: 960px) {
      padding: 18px 40px;
      margin: 20px auto 30px;
      width: auto;
      font-size: 0.875rem;
      line-height: 16px;
    }
  }
`;

export default ShoppingcartPage;