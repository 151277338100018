import {createStore, combineReducers, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import {
    userLoginReducer, userRegisterReducer, userDetailsReducer
} from "reducers/userReducer";
import{productListReducer,regularListReducer, productDetailsReducer} from "reducers/itemReducer";
import filteredReducer from "reducers/filteredReducer";
import optionReducer from "reducers/optionReducer";
import categoryReducer from "reducers/categoryReducer"
import {cartReducer, refreshCartList} from "reducers/cartReducer";
import {
    boardListsReducer,
    dryProjectDetailReducer,
    dryProjectReducer,
    setPrjectCategory,
    eventBoardReducer,
    eventDetailReducer
} from "reducers/boardReducer";
import {orderReducer, orderFetchLists, orderFetchDetails, orderFetchRegLists} from "reducers/orderReducer";
import {userReviewList} from "reducers/reviewReducer";
import Moment from "moment"

const reducer = combineReducers({
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userReviews: userReviewList,
    productLists: productListReducer,
    regularLists: regularListReducer,
    productDetails : productDetailsReducer,
    cartItems: cartReducer,
    cartRefresh: refreshCartList,
    eventLists: eventBoardReducer,
    eventDetail: eventDetailReducer,
    projectLists: dryProjectReducer,
    projectDetail: dryProjectDetailReducer,
    projectCategory: setPrjectCategory,
    orderItems: orderReducer,
    nomalOrderList: orderFetchLists,
    regularOrderList: orderFetchRegLists,
    orderDetails: orderFetchDetails,
    boardLists: boardListsReducer,
    filteredReducer,
    optionReducer,
    categoryReducer,
});

let cartItemObject = [];
let cartitem = localStorage.getItem('cartItems');
if (cartitem) {
    let cartitemParse = JSON.parse(cartitem);
    for (let i = 0; i < cartitemParse.length; i++) {
        if (cartitemParse[i].date) {
            const checkif7day = Moment(cartitemParse[i].date, "YYYY-MM-DD hh:mm:ss");
            const now = Moment().format();
            const duration = Moment.duration({from: checkif7day, to: now});
            if (duration.asDays() <= 7) {
                cartItemObject.push(cartitemParse[i]);
            }
        } else {
            cartItemObject.push(cartitemParse[i]);
        }
    }
}
localStorage.setItem("cartItems", JSON.stringify(cartItemObject))
const userInfoFromStorage = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : {loading: true, userInfo:{}, errorMsg:{}};


let cartItemsFromStorage = localStorage.getItem('cartItems')
    ? JSON.parse(localStorage.getItem('cartItems'))
    : [];

const orderItemsFromStorage = sessionStorage.getItem('orderItems')
    ? JSON.parse(sessionStorage.getItem('orderItems'))
    : [];

const initialState = {
    userLogin: userInfoFromStorage,
    cartItems: {cartItems: cartItemsFromStorage},
    orderItems: {orderItems: orderItemsFromStorage}
};

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunk)));

export default store;

