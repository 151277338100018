import { SET_TAG, REMOVE_TAG } from 'actions/types';

const filteredReducer = (state = { filteredItems: []}, action) => {
    switch (action.type) {
        case SET_TAG:
            return { ...state, filteredItems: action.payload };
        case REMOVE_TAG:
            return { ...state, filteredItems: state.filteredItems.filter(el => el.filteredItems !== action.payload)}
        default:
            return state;
    }
};

export default filteredReducer;