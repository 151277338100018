import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {useSelector, useDispatch} from "react-redux";
import { TabSelector } from "pages/components/TabSelector";
import { useMediaQuery } from "react-responsive";
import icon_download from "images/icon/icon_download.png";
import m_icon_download from "images/m/icon_download.png";
import axios from "axios";
import {setBoardLists} from "actions/boardActions";
import QueryString from 'qs'
import {useHistory} from "react-router-dom"
import {useCookies} from "react-cookie";
import TabContents from "./TabContents";
import icon_paperclip from "../../../images/icon/icon_paperclip.png";
import m_icon_paperclip from "../../../images/m/icon_paperclip.png";
import icon_new from "../../../images/icon/icon_new.png";
import m_icon_new from "../../../images/m/icon_new.png";
import Moment from "moment";

const FAQ = (props) => {
    const isPc = useMediaQuery({ query: "(min-width: 960px)" });
    const dispatch = useDispatch();
    let boardLists = useSelector((state) => state.boardLists)
    const {lists, loading} = boardLists;
    const [cookies, setCookie, removeCookie] = useCookies(["FAQ_:id"])
    const [activeFilter, setActiveFilter] = useState(0);
    const [category, setCategory] = useState([]);
    const [selectedTab, setSelectedTab] = useState("");
    const [selectedTabIdx, setSelectedTabIdx] = useState(0);
    const [noticeData, setNoticeData] = useState([]);
    const [listsMap, setListsMap] = useState([]);
    const [selectSub, setSelectSub] = useState("전체");
    const [selectMain, setSelectMain] = useState("전체");
    const [showDetail, setShowDetail] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [clickContent, setClickContent] = useState({id:0,state:false});
    const [categoryReady, setCategoryReady] = useState(false);

    let startContent = 0;
    let endContent = 0;
    const history =   useHistory();
    const queryData = QueryString.parse(window.location.search, {ignoreQueryPrefix: true});
    const changeCategory = (name) => {
        if (name !== selectedTab) {
            if(category.filter(v => v.name === selectedTab).length > 0){
                setSelectSub(category.filter(v => v.name === selectedTab)[0].subcategory[0].name);
                setActiveFilter(0);
            }
        }
    }
    const changeSubCategory = (name) => {
        if (name !== selectSub) {
            setCurrentPage(1);
        }
    }

    const isItNew = (createdAt) => {
        const afterSeven = Moment(createdAt).add(7, "days").format("YYYY-MM-DD");
        const now = Moment().format('YYYY-MM-DD')

        return !Moment(now).isAfter(afterSeven)
    };

    useEffect( () => {
        if (Object.keys(queryData).length < 1 || !queryData.category) {
            setCategoryReady(true);
            return
        }
        const queryCategory = category.find(v => v.name === queryData.category);
        if (!queryCategory) {return}
        if (!boardLists.loading) {
            for (let i = 0; i < Object.keys(queryData).length; i++) {
                switch (Object.keys(queryData)[i]) {
                    case "category": // 메인카테고리
                        setSelectedTab(queryData.category);
                        setSelectedTabIdx(category.findIndex(v => v.name === queryData.category))
                        break;
                    case "subcategory": // 서브카테고리

                        let idx = queryCategory.subcategory.findIndex(v => v.name === queryData.subcategory)
                        if (idx === -1) {
                            setSelectSub(queryCategory.subcategory[0].name);
                        } else {
                            setActiveFilter(idx);
                            setSelectSub(queryData.subcategory);
                        }
                        break;
                    case "page":
                        setCurrentPage(queryData.page);
                        break;

                }
            }
            setCategoryReady(true);
        }
    }, [loading])


    useEffect(() => {
        if (!categoryReady) {return}
        const params = new URLSearchParams();
        params.set("id",props.id);
        params.set("category", selectedTab);
        params.set("subcategory", selectSub);
        if (currentPage >= 1) {
            params.set("page", currentPage);
        }
        history.push({search: params.toString() });
    }, [selectSub, selectedTab, currentPage, categoryReady])

    useEffect(async () => {
        dispatch(setBoardLists("Faq"));
        const categories = await axios.get(`${process.env.REACT_APP_API_URL}api/board/faq_categories`);
        const category = [];
        const tabs = [];
        for (let i = 0; i < categories.data.length; i++) {
            const categoryData = categories.data[i];
            const subcategory = [];
            if (categoryData.sub_category) {
                const sub = categoryData.sub_category;
                subcategory.push({name:"전체", order:-1});
                for (let j = 0; j < sub.length; j++) {
                    if (sub[j].name.trim() === "") {
                        continue
                    }
                    subcategory.push({name:sub[j].name.trim(), order:sub[j].order});
                }
            }
            subcategory.sort(function (a,b) {
                if (a.order > b.order) {
                    return 1;
                }
                if (a.order < b.order) {
                    return -1;
                }
                return 0;
            })
            tabs.push(categoryData.name);
            category.push({name: categoryData.name.trim(), order:categoryData.order, subcategory: subcategory});
        }

        category.sort(function (a,b) {
            if (a.order > b.order) {
                return 1;
            }
            if (a.order < b.order) {
                return -1;
            }
            return 0;
        })
        setCategory(category);

        if (selectedTab === "") {
            setSelectedTab(category[0].name);
        }

        setCategoryReady(true)
        if (selectedTab === '') {
            if (!queryData.category) {
                setSelectedTab(category[0].name);
                setSelectedTabIdx(0);
            }
        }
    }, [])

    useEffect(()=>{
        if (lists.data) {
            let categorylist = {};
            for (let i = 0; i < category.length; i++) {
                for (let j = 0; j < category[i].subcategory.length; j++) {
                    if (category[i].subcategory[j].name === "전체") {
                        const listcategory = lists.data.filter(v => (v.MainCategory.name === category[i].name));
                        category[i].subcategory[j].data = listcategory;
                        continue
                    }
                    const listcategory = lists.data.filter(v => (v.MainCategory.name === category[i].name) && (v.SubCategory.name.trim() === category[i].subcategory[j].name));
                    category[i].subcategory[j].data = listcategory;
                }

                categorylist[category[i].name] = category[i].subcategory;
            }
            setNoticeData(categorylist);
        }
    },[lists])

    useEffect(() => {
        if (!noticeData[`${selectedTab}`]) {
            return
        }

        const selectedNotice = noticeData[`${selectedTab}`].filter(v => v.name === selectSub)[0];
        if (!selectedNotice) {return}
        setTotalPage(selectedNotice.data.length);

        selectedNotice.data.map((e) => {
            if (!showDetail[e.id]) {
                showDetail[e.id] = false;
                setShowDetail(showDetail);
            }
        })
        endContent = 10;
        startContent = (currentPage * 10) - 10;

        let totalCount = selectedNotice.data.length;
        let pagedata = selectedNotice.data
            .map((el, idx) => (

                <li key={idx}>
                    <div
                        className="list_title_wrap"
                        onClick={(e) => viewDetail(el.id)}
                    >
                        <p className="num_wrap">
                            <span className="num">{totalCount - idx}</span>
                        </p>
                        <p className="filter">{el.SubCategory.name}</p>
                        <p className="notice_title">
                            <span className="title">{el.title}</span>
                            {el.file !== "" ?
                                <span className="clip">
                                    <img src={isPc ? icon_paperclip : m_icon_paperclip} alt="첨부파일" />
                                </span>
                                : null}
                            {isItNew(el.createdAt) ? (
                                <span className="new">
                                        <img src={isPc ? icon_new : m_icon_new} alt="new" />
                                    </span>
                            ) : null}
                        </p>
                    </div>
                    {showDetail[el.id] ? (
                        <div className="text_wrap">
                            <div>
                                <div className="board_cont" dangerouslySetInnerHTML={ {__html: el.content.replace(/(<? *script)/gi, 'illegalscript')}}/>
                                {el.file ? el.file !== "" ?
                                    <a href={`${process.env.REACT_APP_API_URL}api/download?url=`+el.file+`&filename=`+el.filename} download={el.file.replace(/^.*[\\\/]/, '')}>
                                        <span className="attachment">
                                            {el.filename}
                                        </span>
                                    </a>
                                    : null : null}
                            </div>
                        </div>
                    ) : null}
                </li>
            ))
        const spliceLists = pagedata.splice(startContent, endContent);
        setListsMap(spliceLists);
        if (spliceLists.length === 0) { // 페이지에 게시물이 없으면 1페이지로 이동
            setCurrentPage(1);
        }
    }, [noticeData, selectSub, selectedTab, currentPage, clickContent])



    const mainFilters = {
    밀리: "listMain1",
    "피트니스 PRO": "listMain2", // 백엔드에서 받아오는 값이름 수정
    피트니스: "listMain3",
    라일리: "listMain4",
  };

  const filters = {
    전체: "listsAll",
    친해지기: "lists1",
    기기등록: "lists2",
    기기사용: "lists3",
    APP: "lists4",
  };

  const viewDetail = async (id) => {
      for (let i = 1; i < showDetail.length; i++) {
          if (i === id) {
              showDetail[i] = !showDetail[i];
          } else {
              showDetail[i] = false;
          }
      }
      setShowDetail(showDetail);

    setClickContent({id:id, state: !clickContent.state}) // click event발생시 re render용 데이터적으로는 필요없는 데이터
    let now = new Date;
    let afterHour = new Date();
    afterHour.setMinutes(now.getMinutes() +60);
    setCookie(`FAQ_${id}`, id, {path: '/notice/guide', expires: afterHour});
    if(cookies[`FAQ_${id}`] === undefined){
      await axios.get(`${process.env.REACT_APP_API_URL}api/board/hit/Faq/${id}`, { withCredentials: true })
    }
  };

  return (
      <StyledFAQ>
        <div className="tabNav">
            {
                category.map((el, idx) => {
                    return (
                        <TabSelector
                            key={idx}
                            className="tab"
                            isActive={selectedTab === `${el.name}`}
                            onClick={() => {
                                changeCategory(el.name);
                                setSelectedTab(`${el.name}`);
                                setSelectedTabIdx(idx);
                                setSelectMain(`${el.name}`);
                            }}
                        >
                            {el.name}
                        </TabSelector>
                    );
                })
            }
        </div>
        <div className="filter_wrapper noscroll">
            {
                category.length !== 0 ?
                    category[selectedTabIdx].subcategory.map((el, idx) => {
                        return (
                            <button
                                className={
                                    activeFilter === idx ? "filterTag active" : "filterTag"
                                }
                                onClick={() => {
                                    changeSubCategory(el.name);
                                    setActiveFilter(idx);
                                    setSelectSub(el.name);
                                }}
                                key={idx}
                            >
                                {el.name}
                            </button>
                        );
                    })
                    : null
            }
        </div>
        <TabContents loading={loading} totalPage={totalPage} selectedTab={selectedTab} listsMap={listsMap} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </StyledFAQ>
  );
};

const StyledFAQ = styled.div`
  width: 100%;

  & .tabNav {
    margin-bottom: 80px;
    border-bottom: 1px solid #ddd;
    button {
      width: calc(100% / 4);
      height: 78px;
      position: relative;
      font-size: 20px;
      font-weight: 400;
    }
    button:after {
      content: "";
      background: #ddd;
      width: 1px;
      height: 21px;
      left: 0px;
      display: inline-block;
      position: absolute;
    }
    button:first-child:after {
      display: none;
    }
    & .disabled {
      color: #888888;
    }
    & .active {
      font-weight: 500;
      color: #0065ab;
      background: #e8f2f8;
    }

    @media only screen and (max-width: 960px) {
      margin-bottom: 40px;
      border-bottom: none;
      border-top: 1px solid #ddd;
      button {
        width: calc(100% / 2);
        height: 64px;
        border-bottom: 1px solid #ddd;
        font-size: 1rem;
        &:nth-child(2n-1) {
          border-right: 1px solid #ddd;
        }
      }
      button:after {
        content: none;
      }
      button:first-child:after {
        display: none;
      }
    }
  }

  & .filter_wrapper {
    margin-bottom: 30px;
    & .filterTag {
      height: 46px;
      padding: 0 40px;
      color: #515355;
      border: 1px solid #dddddd;
      border-radius: 50px;
      box-sizing: border-box;
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
    & .filterTag.active {
      background: #1ea0fa;
      color: #fff;
      border: 1px solid #1ea0fa;
    }

    @media only screen and (max-width: 960px) {
      overflow-x: auto;
      white-space: nowrap;
      margin-bottom: 15px;
      padding: 0 15px;
      & .filterTag {
        height: 37px;
        padding: 0 22px;
        border: 1px solid #ccc;
        border-radius: 40px;
        font-size: 0.813rem;
      }
      & .filterTag.active {
        font-weight: 500;
      }
    }
  }

  & .TabContents {
    & .notice_wrap {
      border-top: 2px solid #555;
    }
    & .list_title_wrap {
      display: flex;
      align-items: center;
      height: 75px;
      padding: 0 20px;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      &:hover {
        background: #f5fafc;
      }
      & .num_wrap {
        & span {
          display: inline-block;
          width: 80px;
          text-align: center;
          font-size: 0.875rem;
          color: #515355;
          line-height: 21px;
          &.ico_notice {
            padding: 3px 17px;
            background: #fff;
            color: #0065ab;
            border: 1px solid #0065ab;
            border-radius: 30px;
            font-weight: 700;
            line-height: 21px;
          }
        }
      }

      & .filter {
        width: 120px;
        line-height: 21px;
        font-size: 1.1rem;
        color: #1ea0fa;
        white-space: nowrap;
      }

      & .notice_title {
        & .title {
          display: inline-block;
          overflow: hidden;
          max-width: 900px;
          margin-left: 40px;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 1.125rem;
          line-height: 27px;
          color: #222;
          &.notice {
            font-weight: 500;
          }
        }

        & .clip {
          margin-left: 2px;
          img {
            width: 20px;
            vertical-align: top;
            margin-top: 5px;
          }
        }

        & .new {
          img {
            width: 22px;
            vertical-align: top;
            margin-top: 3px;
            margin-left: 5px;
          }
        }
      }
    }

    & .text_wrap {
      padding: 20px 10px;
      background: #f8f8f8;
      border-bottom: 1px solid #ddd;

      & > div {
        overflow-y: auto;
        max-height: 535px;
        padding: 40px 120px;

        &::-webkit-scrollbar {
          width: 6px;
          margin-right: 10px;
        }
        &::-webkit-scrollbar-thumb {
          height: auto;
          background-color: #ccc;
          border-radius: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      }

      img {
        margin-bottom: 30px;
      }

      p {
        font-size: 1rem;
        line-height: 24px;
        color: #222;
        word-break: break-all;
      }

      .attachment {
        display: inline-block;
        margin-top: 30px;
        padding: 12px 23px 12px 47px;
        border: 1px solid #ddd;
        border-radius: 4px;
        background: #fff url(${icon_download}) no-repeat left 23px center;
        color: #515355;
        line-height: 21px;
        font-size: 0.875rem;
      }
    }

    @media only screen and (max-width: 960px) {
      & .notice_wrap {
        border-top: 1px solid #555;
        padding: 0 15px;
      }
      & .list_title_wrap {
        flex-direction: column;
        align-items: flex-start;
        height: 91px;
        padding: 0;
        &:hover {
          background: #fff;
        }

        & .num_wrap {
          display: none;
        }

        & .filter {
          width: 100%;
          line-height: 19px;
          font-size: 0.813rem;
          padding: 21px 0 6px;
          color: #1ea0fa;
          font-weight: 700;
        }

        & .notice_title {
          overflow: hidden;
          width: 100%;
          & .title {
            margin-left: 0;
            max-width: 100%;
            font-size: 1rem;
            line-height: 24px;
          }
        }

        & .clip {
          display: none;
        }

        & .new {
          img {
            width: 18px;
            margin-top: 4px;
          }
        }
      }

      & .text_wrap {
        padding: 20px;
        background: #f8f8f8;
        border-bottom: 1px solid #ddd;

        & > div {
          overflow-y: auto;
          max-height: 100%;
          padding: 0;
        }

        img {
          width: 100%;
          margin-bottom: 16px;
        }

        p {
          font-size: 0.875rem;
          line-height: 21px;
        }

        .attachment {
          margin-top: 16px;
          padding: 12px 14px 12px 41px;
          background: #fff url(${m_icon_download}) no-repeat left 14px center;
          background-size: 15px 15px;
          line-height: 19px;
          font-size: 0.813rem;
        }
      }
    }
  }
`;

export default FAQ;
