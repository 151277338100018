import React, { useState, useEffect } from "react";
import styled from "styled-components";
import point from "images/icon/ico_point.png";
import coupon from "images/icon/ico_coupon.png";
import { Route, NavLink, useHistory, Redirect, Link, useLocation } from "react-router-dom";
import OrderDetails from "./MyPage_Contents/OrderDetails";
import OrderProductDetail from "./MyPage_Contents/Order_productDetail";
import DeliveryProductDetailList from "./MyPage_Contents/Delivery_productDetailList";
import DeliveryProductDetail from "./MyPage_Contents/Delivery_productDetail";
import MyDelivery from "./MyPage_Contents/MyDelivery";
import MyReviewAdmin from "./MyPage_Contents/MyReviewAdmin";
import MySavedMoney from "./MyPage_Contents/MySavedMoney";
import MyCoupon from "./MyPage_Contents/MyCoupon";
import MyPetsInfo from "./MyPage_Contents/MyPetsInfo";
import UserInfoFix from "./MyPage_Contents/UserInfoFix";
import ChangePhone from "./MyPage_Contents/ChangePhone";
import ChangePW from "./MyPage_Contents/ChangePW";
import { MdNavigateNext } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "actions/userActions";
import { useMediaQuery } from "react-responsive";
 import axios from "axios";
 import {USER_LOGOUT} from "../../actions/types";
import Moment from "moment";
import {stringPrice} from "../../utils/StringPrice";

const MyPage = (props) => {
    const isPc = useMediaQuery({ query: "(min-width: 960px)" });
    const pathLocation = props.location;
    const userInfo = useSelector((state) => state.userLogin.userInfo);
    const dispatch = useDispatch();
    const history = useHistory();
    const [checkUser, setCheckUser] = useState({})

    useEffect(()=>{
       try {
           const fetchProfile = async () => {
               if(!userInfo && !userInfo.data){
                   localStorage.removeItem('userInfo');
                   dispatch({type: USER_LOGOUT});
                   history.push("/login")
               }else {
                   if(userInfo.data) {
                       const config = {
                           headers: {
                               'Content-Type': 'application/json',
                               "cid" : userInfo.data.cid,
                               "encoded" : userInfo.data.encoded,
                           },
                       }
                       try {
                           const res = await axios.get(`${process.env.REACT_APP_API_URL}api/user/info`, config);
                           setCheckUser(res.data)
                       } catch (error) {
                           console.log(error)
                           localStorage.removeItem('userInfo');
                           dispatch({type: USER_LOGOUT});
                           history.push("/login")
                       }
                   }
               }
           }
           fetchProfile()
       } catch (e) {
           console.log(e)
           localStorage.removeItem('userInfo');
           dispatch({type: USER_LOGOUT});
           history.push("/Login")
       }

    },[userInfo])

    const handleLogout = () => {
        try {
            dispatch(logout(checkUser?.additional_info?.user_id));
            document.location.href = "/"
        } catch (error) {
            return console.log(error);
        }
    };

    const checkUserCoupon = () => {
        const couponArr = checkUser?.coupon?.filter((coupon) => {
            const now = Moment().format('LLL')
            const start_time = Moment(coupon?.Coupon?.start_date, "YYYY-MM-DD").format('LLL');
            const end_time = Moment(coupon?.Coupon?.end_date, "YYYY-MM-DD").format('LLL');
            const isShowing = Moment(now).isBetween(start_time, end_time)
            return isShowing
        })

        let arr = []
        for(let i = 0; i < couponArr?.length; i++){
            if(couponArr[i].Coupon !== null){
                arr.push(couponArr[i])
            }
        }
        return arr
    }


    return (
        <Container>
            {isPc && (
                <div className="mypage_nav">
                    <h1>마이페이지</h1>
                    <ul>
                        <li>
                            <NavLink to="/mypage/myorders" activeClassName="current">
                                주문 내역
                            </NavLink>
                        </li>
                        {/* 독립몰 serect 정기배송 관련 hidden 처리 */}
                        {/* <li>
                            <NavLink to="/mypage/delivery" activeClassName="current">
                                나의 정기배송
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/mypage/reviewadmin" activeClassName="current">
                                나의 후기관리
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/mypage/fund" activeClassName="current">
                                나의 알갱이
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/mypage/coupon" activeClassName="current">
                                나의 쿠폰
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/mypage/mypets" activeClassName="current">
                                우리 아이 정보
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/mypage/userinfofix" activeClassName="current">
                                회원정보 수정
                            </NavLink>
                        </li>
                        <li onClick={handleLogout}>로그아웃</li>
                    </ul>
                </div>
            )}
            <div className="mypage_info">
                {
                    window.location.pathname.indexOf("/mypage/myorders/detail") >= 0 || window.location.pathname.indexOf("/mypage/delivery/detail_list/detail") >= 0 ?
                        null :
                        (
                            <div className="mypage_userInfo">
                                <p>
                                    <span>{checkUser?.data?.user_name}</span>님, <br /> 안녕하세요.
                                </p>
                                <div>
                                    <p>
                                        <Link to="/mypage/fund">
                                              <span><img src={point} alt="알갱이" /></span>
                                                <br /> 나의 알갱이 <MdNavigateNext /> <br />{" "}
                                            <span className="investigate amount">
                                            <b>{stringPrice(checkUser?.balance?.data?.balance_point)}</b>
                                          </span>
                                        </Link>
                                    </p>
                                    <p>
                                        <Link to="/mypage/coupon">
                                              <span>
                                                <img src={coupon} alt="나의쿠폰" />
                                                <br />
                                              </span>
                                            나의 쿠폰 <MdNavigateNext /> <br />{" "}
                                            <span className="coupon_amount amount">
                                             <b>{checkUserCoupon().length}</b>개
                                            </span>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        )}
                <div className="mypage_content">
                    <Route exact path="/mypage/myorders/detail/:orderId">
                        <OrderProductDetail
                            pathLocation={pathLocation}
                            match={props.match}
                        />
                    </Route>
                    <Route
                        exact
                        path={[
                            "/mypage",
                            "/mypage/myorders",
                            "/mypage/delivery",
                            "/mypage/delivery/detail_list",
                            "/mypage/delivery/detail_list/detail",
                            "/mypage/reviewadmin",
                            "/mypage/fund",
                            "/mypage/coupon",
                            "/mypage/mypets",
                            "/mypage/userinfofix",
                            "/mypage/changephone",
                            "/mypage/userinfofix",
                            "/mypage/changpw",
                        ]}
                        render={({ pathname: path = window.location.pathname }) => {
                            if (path === "/mypage") return <Redirect to="/mypage/myorders" />;
                            if(path === "/mypage/delivery")
                                return (
                                    <MyDelivery
                                        checkUser={checkUser}
                                    />
                                )
                            if (path === "/mypage/delivery/detail_list")
                                return (
                                    <DeliveryProductDetailList />
                                );
                            if (path === "/mypage/delivery/detail_list/detail")
                                return (
                                    <DeliveryProductDetail />
                                );
                            if (path === "/mypage/reviewadmin")
                                return (
                                    <MyReviewAdmin />
                                );
                            if (path === "/mypage/fund")
                                return (
                                    <MySavedMoney />
                                );
                            if (path === "/mypage/coupon")
                                return (
                                    <MyCoupon />
                                );
                            if (path === "/mypage/mypets")
                                return (
                                    <MyPetsInfo />
                                );
                            if (path === "/mypage/userinfofix")
                                return (
                                    <UserInfoFix checkUser={checkUser} />
                                );
                            if (path === "/mypage/changephone")
                                return (
                                    <ChangePhone />
                                );
                            if (path === "/mypage/userinfoafterpw")
                                <Redirect to="/mypage/userinfofix" />
                            if (path === "/mypage/changpw")
                                return (
                                    <ChangePW />
                                );
                            return (
                                <OrderDetails />
                            );
                        }}
                    />
                </div>
            </div>
        </Container>
    );
};

const Container = styled.div`
  max-width: 1480px;
  width: 100%;
  margin: 80px auto 107px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 960px) {
    margin: 0;
    display: block;
  }

  & .mypage_nav {
    flex-basis: 250px;
    h1 {
      font-size: 32px;
      line-height: 48px;
      color: #222;
      margin-bottom: 40px;
    }
    li {
      font-size: 18px;
      color: #222;
      margin-bottom: 18px;
      cursor: pointer;
      & a {
        line-height: 27px;
      }
    }
    & .current {
      color: #1ea0fa;
      font-weight: 500;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  & .mypage_info {
    flex: 1 1 1230px;

    & .mypage_userInfo {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 45px 0;
      background: linear-gradient(90deg, #dff0f7 -1.59%, #e6f2f7 101.11%);

      & > p {
        flex: 1 1 570px;
        padding-left: 70px;
        font-weight: 300;
        font-size: 30px;
        line-height: 45px;
        & span {
          font-weight: 500;
        }
      }

      & div {
        display: flex;
        flex: 1 1 660px;
        justify-content: space-between;
        & p {
          width: 50%;
          font-size: 15px;
          line-height: 22px;

          & a {
            color: #222;
            &:hover {
              color: #222 !important;
            }
          }

          & img {
            margin-bottom: 10px;
          }
          & svg {
            vertical-align: middle;
            margin-top: -3px;
          }
          & .amount {
            display: block;
            margin-top: 10px;
            font-size: 20px;
            line-height: 30px;
            color: #0071bf;
            & b {
              font-weight: 500;
              font-size: 26px;
              line-height: 39px;
            }
          }
        }
        & p:nth-child(2) {
          & img {
            margin-top: 5px;
          }
        }
      }

      @media only screen and (max-width: 960px) {
        display: none;
      }
    }
  }

  & .mypage_content > div:first-child {
    margin-top: 60px;
    @media only screen and (max-width: 960px) {
      margin-top: 0;
    }
  }
`;

export default MyPage;
