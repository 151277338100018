import React, {useState, useEffect, useCallback} from 'react';
import styled from "styled-components";
import 'rc-rate/assets/index.css';
import Button from './Button';
import Dialog from './Dialog';
import { useSelector } from "react-redux";
import {stringPrice} from "utils/StringPrice"
import Select from 'react-select';
import axios from "axios";

function OptionRegularPopup({ onCancel, selectedCart, selectedCartId, onChange, setIsOptionChage}) {
    const userInfo = useSelector((state) => state.userLogin.userInfo);
    const [selectedOption, setSelectedOption] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const [optionCycle, setOptionCycle] = useState({
        label: `${selectedCart.cycle}주`,
        value: selectedCart.cycle
    });
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'blue',
            padding: 20,
        }),
        control: () => ({
            width: 200,
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    }

    useEffect(() => {
        if (userInfo.data) {
            setQuantity(selectedCart.quantity)
            setOptionCycle({
                label: `${selectedCart.cycle}주`,
                value: selectedCart.cycle
            })
        } else {
            setQuantity(selectedCart.Options[0].quantity)
            setOptionCycle({
                label: `${selectedCart.Options[0].cycle}주`,
                value: selectedCart.Options[0].cycle
            })
        }
    }, [])
    const regularOptions = [
        {value: 2, label: "2주"},
        {value: 3, label: "3주"},
        {value: 4, label: "4주"},
        {value: 5, label: "5주"},
        {value: 6, label: "6주"},
        {value: 7, label: "7주"},
        {value: 8, label: "8주"}
    ]
    const handleSelect = (value, label) => {
        setOptionCycle({
            ...optionCycle,
            value: value,
            label: label
        })
        setSelectedOption(true)
    };

    const onChangeQuantity = (type) => {
        switch (type) {
            case 0:
                setQuantity(prevState => {
                        if (prevState > 0) {
                            return prevState - 1
                        } else {
                            return 0
                        }
                });
                break;
            case 1:
                setQuantity(prevState => prevState + 1);
                break;

        }
    }
    const getTotalPrice = useCallback(() => {
        const total = {
            price: 0,
            quantity: 0,
            shippingPrice: 0
        };

        let shippingPriceCondition = 50000;
        if (userInfo.data) {
            total.price += selectedCart.price * quantity;
        } else {
            total.price += selectedCart.Options[0].price * quantity;
        }
        if (total.price >= shippingPriceCondition) {
            total.shippingPrice = 0;
        }
        return total;
    }, [selectedCart, quantity]);

    return (
        <OptionDialog visible="true">
            <Dialog
                cancelText="닫기"
                onCancel={onCancel}
                dialogSize="medium"
                visible="true"
                fullHeight
            >
                <p className="layer_tit">{selectedCart.Product.name}</p>
                <div className="select_options">
                    <div>
                        <label>배송 주기 변경하기</label>
                        <Select
                            placeholder="배송 주기 변경하기"
                            onChange={(option) => {handleSelect(option.value, option.label)}}
                            options={regularOptions}
                            className="selectBox"
                            classNamePrefix="select"
                            isSearchable={false}
                        />
                    </div>
                </div>
                <div className="selected_option">
                    <div className="option_wrap">
                        <div className="option_box">
                            <p>변경 될 배송주기 : {optionCycle.label}</p>
                        </div>
                        <div className="option_box">
                            {
                                userInfo.data ?
                                    <p>{selectedCart.name}</p> :
                                    <p>{selectedCart.Product.name}</p>
                            }
                            <div className="option_bottom">
                                <div className="option_count">
                                    <button onClick = {(e) => onChangeQuantity(0)}>-</button>
                                    <label>{quantity}</label>
                                    <button onClick = {(e) => onChangeQuantity(1)}>+</button>
                                </div>
                                <span>{stringPrice(getTotalPrice().price)}원</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="product_option">
                    <div className="total_price">
                        <span>총 상품금액</span>
                        <span className="price">
                            <b>
                                {stringPrice(getTotalPrice().price + getTotalPrice().shippingPrice)}
                        </b>원</span>
                    </div>
                </div>
                <div className="option_btn">
                    <Button color="white" outline onClick={onCancel}>취소</Button>
                    <Button onClick={async () => {
                        try {
                            if (userInfo.data) {
                                const config = {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
                                    }
                                }
                                const data = {
                                    id: selectedCart.id,
                                    price: selectedCart.price * quantity,
                                    quantity: quantity,
                                    cycle: optionCycle.value
                                }

                                await axios.put(`${process.env.REACT_APP_API_URL}api/order/regular_cart`,data, config); //장바구니 AJAX 요청
                            } else {
                                let carts = JSON.parse(localStorage.getItem("cartItems"));
                                let idx = carts.findIndex(v => v.id === selectedCart.id);
                                if (idx !== -1) {
                                    carts[idx].Options[0].cycle = optionCycle.value;
                                    carts[idx].Options[0].quantity = quantity;
                                }
                                localStorage.setItem('cartItems', JSON.stringify(carts));
                            }
                            onChange()
                        } catch (error) {
                            console.log(error)
                        }
                    }}>변경</Button>
                </div>
            </Dialog>
        </OptionDialog>
    );
};
const OptionDialog = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  width: 480px;
  background: #fff;
  border: 1px solid #ddd;
  z-index: 9999;
  &.active {
    display: block;
  }

  & .dialog_wrap {
    min-height: auto;
    padding: 30px 17px 30px 30px;
    & .dialog_inner {
      padding-right: 5px;
    }
  }

  @media only screen and (max-width: 960px) {
    display: ${({ visible }) => visible ? 'flex' : 'none'};
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    min-height: auto;
    & > div {
      align-items: flex-end;
    }
    & .dialog_wrap {
      height: auto;
      min-height: auto;
      padding: 18px 0 30px;
    }
  }

  h3 {
    display: none;
  }

  .layer_tit {
    position: relative;
    margin-bottom: 16px;
    padding-right: 35px;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 23.4px;
    color: #222;

    @media only screen and (max-width: 960px) {
      line-height: 25px;
      padding-right: 41px;
    }
  }

  .close svg {
    width: 25px;
  }

  & .select_options {
    padding-right: 13px;
    @media only screen and (max-width: 960px) {
      display: block;
      margin: 0;
      padding-right: 10px;
    }

    & > div {
      flex-direction: column;
      margin-bottom: 14px;
      text-align: left;
      &:last-child {
        margin-bottom: 0;
      }
    }

    label {
      display: block;
      width: 100%;
      margin-bottom: 6px;
      line-height: 21px;
      font-size: 0.875rem;
      color: #222;
    }

    & .selectBox {
      width: 100%;
      height: 43px;
      span {
        display: none;
      }
      & .css-1wa3eu0-placeholder {
        color: #515355;
      }
      & .select__single-value {
        max-width: calc(100% - 25px);
        font-size: 0.875rem;
      }
      & .select__menu-list {
        overflow-y: auto;
        max-height: 129px;
        &::-webkit-scrollbar {
          position: absolute;
          right: 0;
          top: 0;
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #888B9D;
          border-radius: 15px;
        }
        &::-webkit-scrollbar-track {
          background-color: #DDDDDD;
          border-radius: 15px;
        }
      }
      @media only screen and (max-width: 960px) {
        width: 100%;
        height: auto;
        & .select__control {
          height: 43px;
        }
        & .select__placeholder {
          font-size: 14px;
        }
        & .select__single-value {
          font-size: 14px;
        }
        & .select__menu {          
          & .select__menu-list {
            overflow-y: auto;
            max-height: 129px;
            &::-webkit-scrollbar {
              position: absolute;
              right: 0;
              top: 0;
              width: 4px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #888B9D;
              border-radius: 15px;
            }
            &::-webkit-scrollbar-track {
              background-color: #DDDDDD;
              border-radius: 15px;
            }
          }
        }
      }
    }
  }

  & .selected_option{
    position: relative;
    margin-top: 20px;
    max-height: 188px;
    width: 100%;
    overflow-y: auto;
    padding-right: 13px;

    &::-webkit-scrollbar {
      position: absolute;
      right: 0;
      top: 0;
      width: 3px;
      border-radius: 15px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #515355;
    }
    &::-webkit-scrollbar-track {
      background-color: #DDDDDD;
    }
    @media only screen and (max-width: 960px) {
      overflow-y: auto;
      max-height: 185px;
      margin-top: 10px;
      padding-right: 10px;
    }
    & .option_wrap {

      & .option_box {
        position: relative;
        padding: 15px;
        background: #f6f6f6;

        &:not(:first-child) {
          margin-top: 10px;
        }
        & p {
          display: inline-block;
          max-width: 440px;
          color: #222;
          font-size: 0.938rem;
          line-height: 22.5px;
        }

        & .option_bottom {
          display: flex;
          align-items: center;
          margin-top: 12px;
          & .option_count {
            display: flex;
            & button {
              height: 26px;
              width: 26px;
              border: 1px solid #ccc;
              background: #fff;
              font-size :20px;
              line-height: 21px;
            }
            & label {
              display: inline-block;
              width: 48px;
              height: 26px;
              margin-bottom: 0;
              border-top: 1px solid #ccc;
              border-bottom: 1px solid #ccc;
              background: #fff;
              font-size: 0.875rem;
              line-height: 24px;
              color: #222;
              text-align: center;
            }
          }
          & span {
            margin-left: auto;
            font-size: 0.938rem;
            line-height: 22.5px;
            color: #515355;
          }
        }
        & .option_del {
          position: absolute;
          top: 18px;
          right: 18px;
          width: 17px;
          cursor: pointer;
        }
      }
      @media only screen and (max-width: 960px) {
        & .option_box {
          &:not(:first-child) {
            margin-top: 8px;
          }

          & > div {
            display: flex;
            align-items: center;
          }
          & p {
            display: block;
            max-width: 100%;
            margin-right: 5px;
            font-size: 0.813rem;
            line-height: 19.5px;
          }

          & .option_bottom {
            & .option_count {
              & button {
                height: 30px;
                width: 30px;
                font-size: 1.2rem;
              }
              & label {
                width: 36px;
                height: 30px;
                font-size: 0.813rem;
                line-height: 30px;
              }
            }
            & span {
              margin-left: auto;
              padding: 0 5px;
              font-size: 0.938rem;
              line-height: 22.5px;
              color: #515355;
            }
          }
          & .option_del {
            position: absolute;
            top: 18px;
            right: 18px;
            width: 17px;
            cursor: pointer;
          }
        }
      }
    }

    @media only screen and (max-width: 960px) {
      & .option_wrap {
        flex-direction: column;
        &:not(:first-child) {
          margin-top: 6px;
        }
        & p {
          display: block;
          max-width: 100%;
          margin-right: 0;
          padding-right: 30px;
          font-size: 0.875rem;
          line-height: 21px;
        }

        & .option_bottom {
          & .option_count {
            & button {
              height: 28px;
              width: 28px;
            }
            & label {
              width: 40px;
              height: 28px;
              font-size: 0.813rem;
              line-height: 28px;
            }
          }
          & span {
            margin-left: auto;
            padding: 0 5px;
            font-size: 0.938rem;
            line-height: 22.5px;
            color: #515355;
          }
        }
      }
    }
  }

  & .product_option {
    padding-right: 13px;
    min-width: 100%;
  }

  & .total_price {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding: 16px 0 0 0;
    border-top: 1px solid #ddd;
    font-size: 1.25rem;
    font-weight: 500;
    & span:first-child {
      font-size: 0.938rem;
      color: #222222;
      line-height: 22px;
    }
    & .price {
      margin-left: auto;
      line-height: 45px;
      b {
        font-size: 30px;
        font-weight: 500;
      }
    }
    @media only screen and (max-width: 960px) {
      display: flex;
      align-items: center;

      padding-top: 16px;
      & span:first-child {
        font-size: 0.875rem;
      }
      & .price {
        margin-left: auto;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 36px;
        b {
          font-size: 1.5rem;
          line-height: 36px;
          font-weight: 500;
        }
      }
    }
  }

  & .option_btn {
    display: flex;
    margin-top: 13px;
    padding-right: 13px;

    div {
      height: 50px;
      width: calc((100% - 5px) / 2);
      font-size: 1rem;
      box-sizing: border-box;
    }

    @media only screen and (max-width: 960px) {
      & div {
        font-weight: 700;
      }
    }
  }
`
export default OptionRegularPopup;