import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import pageExpand_move from "images/page_expand_move.png";
import pageMove from "images/page_move.png";
import m_pageExpand_move from "images/m/page_expand_move.png";
import m_pageMove from "images/m/page_move.png";
import { useMediaQuery } from "react-responsive";

const Pagination = ({ postsPerPage, totalPosts, paginate, currentpage }) => {
    const isPc = useMediaQuery({ query: "(min-width: 961px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 960px)" });

    const [active, setActive] = useState(1);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }
    useEffect(() => {
        setActive(parseInt(currentpage));
    }, [currentpage])

    const pageMovenumber = (number) => {
        paginate(number);
    }
    const prevPage = () => {
        if (parseInt(currentpage) > 1) {
            paginate(parseInt(currentpage) -1);
        }
    }
    const nextPage = () => {
        if (parseInt(currentpage) < pageNumbers.length) {
            paginate(parseInt(currentpage) + 1);
        }
    }
    const prevPageExpand = () => {
        if (parseInt(currentpage) > 1) {
            paginate(1);
        }
    }
    const nextPageExpand = () => {
        if (parseInt(currentpage) < pageNumbers.length) {
            paginate(pageNumbers.length);
        }
    }
    return (
        <Pagination_cover>
            <nav>
                <div className="move_prev">
                    {totalPosts > 10 ?
                        <a onClick={prevPageExpand}>
                            {isPc && <img src={pageExpand_move} alt="한 페이지 뒤로 이동"/>}
                            {isMobile && <img src={m_pageExpand_move} alt="한 페이지 뒤로 이동"/>}
                        </a>
                        : null}
                    {totalPosts !== 0 ?
                        <a onClick={prevPage}>
                            {isPc && <img src={pageMove} alt="한 페이지 뒤로 이동"/>}
                            {isMobile && <img src={m_pageMove} alt="한 페이지 뒤로 이동"/>}
                        </a>
                        : null}
                </div>
                <PageUl className="pagination">
                    {pageNumbers.map((number) => {
                        return (
                            <PageLi key={number} className={'page-item ' + (number === active ? "active" : null)}  onClick={() => pageMovenumber(number)}>
                                <PageSpan
                                    className="page-link"
                                >
                                    {number}
                                </PageSpan>
                            </PageLi>
                        );
                    })}
                </PageUl>
                <div className="move_next">

                    {totalPosts !== 0 ?
                        <a onClick={nextPage}>
                            {isPc && <img src={pageMove} alt="한 페이지로 앞으로 이동" />}
                            {isMobile && <img src={m_pageMove} alt="한 페이지로 앞으로 이동" />}
                        </a> : null}
                    {totalPosts > 10 ?
                        <a onClick={nextPageExpand}>
                            {isPc && <img src={pageExpand_move} alt="한 페이지 뒤로 이동"/>}
                            {isMobile && <img src={m_pageExpand_move} alt="한 페이지 뒤로 이동"/>}
                        </a>
                        : null}
                </div>
            </nav>
        </Pagination_cover>
    );
};

const Pagination_cover = styled.div`
  & nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px 0 100px;

    @media only screen and (max-width: 768px) {
      margin: 40px 0 40px;
    }

    ul {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 768px) {
        margin-top: -4px;
      }

      li {
        vertical-align: middle;
      }
      & .active {
        color: #222222;
      }
    }
  }

  & nav > div {
    a {
      display: inline-block;
      margin: 0 5px;
      img {
        display: inline-block;
        width: 22px;
      }
    }
  }

  & .move_prev {
    margin-right: 10px;
  }

  & .move_next {
    margin-left: 10px;
    img {
      transform: rotate(180deg);
    }
  }
`;

const PageUl = styled.ul`
  list-style: none;
  text-align: center;
  border-radius: 3px;
  padding: 1px;
`;

const PageLi = styled.li`
  display: inline-block;
  min-width: 24px;
  margin: 0 5px;
  text-align: center;
  color: #888;
  &:hover {
    cursor: pointer;
    color: #222;
  }
  &:focus::after {
    color: #222;
  }
  @media only screen and (max-width: 768px) {
    color: #999;
    margin: 0 4px;
  }
`;

const PageSpan = styled.span`
  line-height: 24px;
  font-weight: 400;
  font-size: 1rem;
  &:hover::after {
    color: #222;
  }
  &.active {
    color: #222;
  }

  @media only screen and (max-width: 768px) {

    font-size: 0.938rem;
  }

  
`;

export default Pagination;
