import React, {useCallback, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import Item from "./components/Item";
import CategoryFilter from "./components/CategoryFilter";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import BannerImg0 from "images/navBannerImg0.png";
import BannerImg1 from "images/navBannerImg1.png";
import BannerImg2 from "images/navBannerImg2.png";
import BannerImg3 from "images/navBannerImg3.png";
import m_BannerImg0 from "images/m_navBannerImg0.png";
import m_BannerImg1 from "images/m_navBannerImg1.png";
import m_BannerImg2 from "images/m_navBannerImg2.png";
import m_BannerImg3 from "images/m_navBannerImg3.png";
import Loader from "./components/Loader";
import axios from "axios";

const ItemListsPage = (props) => {
    const mainId = props.history.location.mainId
    const productLocation = props.match.params.id;
    const category = useSelector((state) => state.categoryReducer.categoryFilters);
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [regularList, setRegularList] = useState([]);
    const params = new URLSearchParams(props.history.location.search);
    const categoryId =  params.get("categ_id");
    const CategoryOrder = params.get("categ_order");
    // 앱의 더보기 링크로 넘어온 경우 쿼리스트링 categ_id, categ_order이 있을면 이를 설정
    if (categoryId !== null && CategoryOrder !== null) {
        localStorage.setItem("mainCateId",categoryId)
        localStorage.setItem("mainCateType","product")
        localStorage.setItem("mainCateOrder", CategoryOrder)
        
    }
    const mainCateId = Number(localStorage.getItem("mainCateId"));
    const mainCateType = localStorage.getItem("mainCateType")
    const mainCateOrder = Number(localStorage.getItem("mainCateOrder"));
    const [categoryUpdate, setCategoryUpdate] = useState(0);
    const [selectedValue, setSelectedValue] = useState({
        column: "order",
        order: "desc"
    })
    const type_url = props.match.params.name;
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const fetchProducts = useCallback(async (init) => {
        if(init) {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/product/frontList/${productLocation}`, config);
            setProducts(res.data.data);
            setTotal(res.data.total);
        }else{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/product/frontList/${productLocation}?start=${products.length}`, config);
            setProducts([...products, ...res.data.data]);
            setTotal(res.data.total);
        }
        setCategoryUpdate(new Date().getTime());
    }, [productLocation, products])
    const fetchRegulars = useCallback(async (init) => {
        if(init) {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/product/frontRegularList/${productLocation}?column=${selectedValue.column}&order=${selectedValue.order}`, config)
            setRegularList(res.data.data);
            setTotal(res.data.total);
        }else{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/product/frontRegularList/${productLocation}?start=${regularList.length}&column=${selectedValue.column}&order=${selectedValue.order}`, config)
            setRegularList([...regularList, ...res.data.data]);
            setTotal(res.data.total);
        }
        setCategoryUpdate(new Date().getTime());
    }, [productLocation, regularList, selectedValue])

    useEffect(async () => {
        if (mainCateType !== "regular") {
            fetchProducts(true)
        } else {
            fetchRegulars(true)
        }
    }, [productLocation, mainId, selectedValue]);

    let getCategory = category.reduce((acc, cur) => {
        acc.push({ id: cur.id, categoryTag: cur.sub_category });
        return acc;
    }, []);

    const categoryFilter = getCategory?.filter((el) => el.id === mainCateId);
    const bannerText = () => {
        switch (type_url) {
            case "product0":
                return (<>
                    <span></span>
                    <p></p>
                </>)
            case "product1":
                return ( <>
                    <span></span>
                    <p></p>
                </>)
            case "product2":
                return (<>
                    <span></span>
                    <p></p>
                </>)
            case "product3":
                return ( <>
                    <span></span>
                    <p></p>
                </>)
            case "regular0":
            case "regular1":
            case "regular2":
            case "regular3":
            case "regular4":
            case "regular5":
            case "regular6":
            case "regular7":
            case "regular8":
            case "regular9":

                return (
                    <>
                        <p className="delivery">똑똑한 식사관리<br />바램펫 사료정기 배송</p>
                        <span className="delivery">지금 신청하면 <b>밀리가 무료</b></span>
                    </>
                )
            default:
                return (
                    <>
                        <span></span>
                        <p></p>
                    </>
                )
        }
    }

    const [regIdFilter, setRegIdFilter] = useState(mainCateId);
    const regFilter = (regIdFilter) => {
        return regularList.filter((el) => el.MainCategoryId === regIdFilter || el.MidCategoryId === regIdFilter || el.SubCategoryId === regIdFilter)
    }

    const selectedVal = async (e) => {
        const {value} = e.target;
        switch (value) {
            case "추천순":
                setSelectedValue(prevState => ({...prevState, column:"order", order: "desc"}));
                break
            case "인기순":
                setSelectedValue(prevState => ({...prevState, column:"selling_count", order: "desc"}));
                break
            case "리뷰순":
                setSelectedValue(prevState => ({...prevState, column: "review_count", order: "desc"}));
                break
            case "낮은가격순":
                setSelectedValue(prevState => ({...prevState, column:"price", order: "asc"}));
                break
            case "높은가격순":
                setSelectedValue(prevState => ({...prevState, column:"price", order: "desc"}));
                break
        }
    }

    let bannerImage;
    let mBannerImage;
    switch (type_url) {
        case "product0":
            bannerImage = BannerImg0
            mBannerImage = m_BannerImg0
            break;
        case "product1":
            bannerImage = BannerImg1
            mBannerImage = m_BannerImg1
            break;
        case "product2":
            bannerImage = BannerImg2
            mBannerImage = m_BannerImg2
            break;
        case "product3":
            bannerImage = BannerImg3
            mBannerImage = m_BannerImg3
            break;
        default:
            bannerImage = BannerImg0
            mBannerImage = m_BannerImg0
            break;
     }

    return (
        <ListContainer match={props.match} bannerImg={bannerImage} mBannerImg={mBannerImage} productLocation={productLocation}>
            <div className="banner">
                <div className="container center">
                    {bannerText()}
                </div>
            </div>

            <div className="container center">
                <ul className="categoryContainer">
                    {categoryFilter?.length !== 0 && <>
                        <li className="categoryList">
                            <NavLink exact to={{ pathname: `/products/list/${mainCateType}${mainCateOrder}/${mainCateId}`, mainCateId}} activeClassName="current" onClick={() => {
                                setRegIdFilter(mainCateId);
                                regFilter();
                            }}>전체</NavLink>
                        </li>
                        {categoryFilter[0]?.categoryTag.map((tag) => (
                            <li key={tag.id} className="categoryList">
                                <NavLink key={tag.id} to={{pathname: `/products/list/${mainCateType}${mainCateOrder}/${tag.id}`, mainCateId }} activeClassName="current" onClick={() => {
                                    setRegIdFilter(tag.id);
                                    regFilter()
                                }}>{tag.name}
                                </NavLink>
                            </li>
                        ))}
                    </>}
                </ul>

                <CategoryFilter
                    update={categoryUpdate}
                    mainCategoryId={mainCateId}
                    onSubmit={async (filters, reset) => {
                        if(filters) {
                            let a = {};
                            filters.map((filter) => {
                                let b = []
                                filter.sub_filters.map((sub) => {
                                    if (sub.selected) {
                                        b.push(sub.id);
                                    }
                                })
                                a[filter.id] = b;
                                return filter
                            })
                             if(mainCateType !== "regular") {
                                 const res = await axios.post(`${process.env.REACT_APP_API_URL}api/product/filterList/${productLocation}`,
                                     {
                                         start: products.length,
                                         filter: a
                                     },
                                     config);
                                 setProducts(res.data.data);
                                 setTotal(0);
                             }else{
                                 const res = await axios.post(`${process.env.REACT_APP_API_URL}api/product/filterRegularList/${productLocation}`,
                                     {
                                         start: products.length,
                                         filter: a
                                     },
                                     config);
                                 setRegularList(res.data.data);
                                 setTotal(0);
                             }
                        }else{
                            if(mainCateType !== "regular") {
                                fetchProducts(true);
                            }else{
                                fetchRegulars(true);
                            }
                        }
                    }}
                    mainCateType={mainCateType}
                    selectedVal={selectedVal}
                />

                {type_url.indexOf("regular") < 0 ? <>  {/* 메인카테고리 ID가 정기배송이 아닐 때 */}
                        {!products ? <Loader /> : <div className="itemList">
                            {
                                products.length === 0 ? <div className="noList">관련 상품이 아직 등록되지 않았습니다.</div>
                                    : (
                                        products.map((item) => {
                                            if (!item.display) {
                                                return
                                            }
                                            if(item.deleted){
                                                return
                                            }
                                            return(
                                                <Item key={item.id} item={item}  type={mainCateType}/>
                                            )
                                        })
                                    )}
                        </div>}
                    </> :
                    <div className="itemList"> {/* 메인카테고리 ID가 정기배송일 때 */}
                        {regularList.length === 0 ? <div className="noList">관련 상품이 아직 등록되지 않았습니다.</div>
                            :(
                                regularList.map((item) => {
                                    if(item.deleted){
                                        return
                                    }
                                    return(<Item key={item.id} item={item} type={mainCateType}/>)
                                })
                            )
                        }
                    </div>}
            </div>
            {mainCateType !== "regular" && products?.length < total ? products?.length >= 12 ? <button className="moreView" onClick={() => fetchProducts()}>제품더보기 +</button> : null : null}
            {mainCateType == "regular" && regularList.length < total ? regularList?.length >= 12 ? <button className="moreView" onClick={() => fetchRegulars()}>제품더보기 +</button> : null : null}
        </ListContainer>
    );
};

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & .container {
    & .itemList {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      margin: 60px auto 100px;

      @media only screen and (max-width: 960px) {
        padding: 0 15px;
        margin: 30px auto 100px;
        width: 100%;
      }
    }
  }

  .categoryContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    & .current {
      font-weight: bold;
    }

    @media only screen and (max-width: 960px) {
      overflow-x: auto;
      -ms-overflow-style: none;
      justify-content: flex-start;
      padding: 30px 0;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    & > li{
      position: relative;
      padding: 0 40px;
      cursor: pointer;
      font-size: 1.25rem;
      line-height: 30px;
      color: #888;

      @media only screen and (max-width: 960px) {
        white-space: nowrap;
        padding: 0 20px;
        color: #222;
        font-size: 1rem;
        line-height: 24px;
      }

      &:first-child {
        color: #222;
      }

      &:hover {
        color: #222;

        @media only screen and (max-width: 960px) {
          font-weight: normal;
          font-weight: 400;
        }
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 7.5px;
        right: 0;
        height: 15px;
        width: 1px;
        background: #ddd;

        @media only screen and (max-width: 960px) {
          top: 4.5px
        }
      }

      &:last-child::after {
        content: none;
      }
    }
  }

  ul > li

  & .noProducts {
    width: 400px;
    margin: 200px auto;
    font-size: 1.3rem;
    text-align: center;
  }

  & .noList {
    width: 100%;
    padding: 30px 0;
    border-bottom: none;
  }

  & .moreView {
    cursor: pointer;
    padding: 18px 50px;
    width: 200px;
    margin: 0 auto 100px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 63px;
    font-size: 1.125rem;
    line-height: 27px;
    color: #222;
    font-weight: 500;
    @media only screen and (max-width: 960px) {
      padding: 16px 60px;
      margin: 60px auto 80px;
      width: auto;
      font-size: 0.875rem;
      line-height: 21px;
    }
  }

  & .banner {
    display: flex;
    width: 100%;
    align-items: center;
    background-image: ${({ bannerImg }) => `url(${bannerImg})`};
    background-size: cover;
    height: 320px;

    @media only screen and (max-width: 960px) {
      display: block;
      background: ${({ mBannerImg }) => `url(${mBannerImg}) no-repeat center`};
      min-height: 320px;
      min-width: 100%;
      padding: 30px 0 8%;
      box-sizing: content-box;
      text-align: center;
      background-size: cover;
    }

    & .container {
      width: 100%;

      & span {
        display: block;
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 36px;
        &.delivery {
          margin-top: 10px;
          font-weight: 300;
          & b {
            color: #F86262;
            font-weight: 700;
          }
        }
        @media only screen and (max-width: 960px) {
          font-size: 1rem;
          line-height: 24px;
          &.delivery {
            margin-top: 0;
            & b {
              font-weight: 500;
            }
          }
        }
      }

      & p {
        font-size: 3rem;
        font-weight: 500;
        line-height: 72px;
        &.delivery {
          line-height: 57px;
        }
        @media only screen and (max-width: 960px) {
          font-size: 1.75rem;
          line-height: 42px;
          &.delivery {
            line-height: 42px;
          }
        }
      }
    }
  }
`;

export default withRouter(ItemListsPage);
