import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import kakao_channel from 'images/icon/kakao_channel.png';

function Chat() {
  return (
    <a href="http://pf.kakao.com/_JTxbpT" target="_blank" rel="noreferrer">
      <KakaoChat>
        <span><img src={kakao_channel} alt="카카오톡 채널추가" /></span>
      </KakaoChat>
    </a>
  );
}

const KakaoChat = styled.div`
  position: fixed;
  bottom: 75px;
  right: 20px;
  z-index: 100;
  cursor: pointer;

  & img {
    width: 100px;
  }

  @media only screen and (max-width: 960px) {
    bottom: 60px;
    right: 13px;
  }
`

export default Chat;