import React from 'react';
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import Button from './components/Button.js';
import {useHistory} from "react-router-dom";

const NotFound = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })

    const history = useHistory();
    const goToMain = () => {
      history.push("/");
    }

    return (
        <StyleNotFound>
            <div className="title_404">
                <p>404</p>
                <p>페이지를 찾을 수 없습니다.</p>
            </div>
            <p className="text_wrap">찾으시려는 페이지의 주소를 잘못 입력하였거나<br />페이지 주소의 변경 또는 삭제 등의 이유로{isMobile && <br />}페이지를 찾을 수 없습니다.</p>
            <Longbutton size="large" onClick={goToMain}>홈으로 가기</Longbutton>
        </StyleNotFound>
    );
};

const Longbutton = styled(Button)`
  width: 500px;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
`;

const StyleNotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 105px auto 150px;
  
  & .title_404 {
    margin-bottom: 20px;
    & p:first-child {
      font-weight: 700;
      font-size: 11.25rem;
      color: #1EA0FA;
      opacity: 0.3;
      line-height: 270px;
      text-align: center;
    }
    & p:last-child {
      font-size: 2.5rem;
      line-height: 60px;
    }
  }
  
  & .text_wrap {
    margin-bottom: 80px;
    font-size: 1.25rem;
    line-height: 30px;
    text-align: center;
  }

  @media only screen and (max-width: 960px) {
    padding: 0 15px;
    margin: 65px auto 80px;
  
    & .title_404 {
      margin-bottom: 15px;
      & p:first-child {
        font-size: 6.25rem;
        line-height: 150px;
      }
      & p:last-child {
        margin-top: 10px;
        font-size: 1.75rem;
        line-height: 36px;
      }
    }
    
    & .text_wrap {
      margin-bottom: 60px;
      font-size: 1.063rem;
      line-height: 25px;
    }
  }
`

export default NotFound;