import axios from "axios";
import {
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    REGULAR_LIST_REQUEST,
    REGULAR_LIST_SUCCESS,
    REGULAR_DETAILS_REQUEST,
    REGULAR_DETAILS_SUCCESS,
    REGULAR_DETAILS_FAIL,
} from "./types";
import dotenv from "dotenv";
dotenv.config();

export const fetchAllProducts = (items) => async dispatch => {
    try {
        dispatch({type: PRODUCT_LIST_REQUEST});
        const res = await axios.get( `${process.env.REACT_APP_API_URL}api/product/list/all`);

        dispatch({type: PRODUCT_LIST_SUCCESS, payload: res.data});
    }catch(error){
        dispatch({type: PRODUCT_LIST_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message});
    }
}

export const fetchAllRegulars = () => async dispatch => {
    try{
        dispatch({type: REGULAR_LIST_REQUEST})
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/regular/product/data/all`);

        dispatch({type: REGULAR_LIST_SUCCESS, payload: res.data});
    }catch(error){
        dispatch({type: PRODUCT_LIST_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message});
    }
}


export const listProducts = (id) => async dispatch => {
    try {
        dispatch({type: PRODUCT_LIST_REQUEST});
        const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/product/list/${id}`);
        dispatch({type: PRODUCT_LIST_SUCCESS, payload: data});
    } catch(error) {
        dispatch({type: PRODUCT_LIST_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message});
    }
}

export const listProductDetails = (id) => async dispatch => {
    try {
        dispatch({ type: PRODUCT_DETAILS_REQUEST });

        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/product/detail/${id}`);
        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: res.data.data,
        });
    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const ListRegularDetails = (id) => async dispatch => {
    try{
        dispatch({type:REGULAR_DETAILS_REQUEST});
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/regular/product/detail-page/${id}`);
        dispatch({
            type: REGULAR_DETAILS_SUCCESS,
            payload: res.data.data,
        });
    }catch(error){
        console.log("err", error)
        dispatch({
            type: REGULAR_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}


