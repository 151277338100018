import React, {useEffect} from 'react';
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import Button from './components/Button';
import arr_more from "images/arr_more.png";
import icon_chk_box from 'images/icon/icon_chk_box.png';
import { BsDot } from "react-icons/bs";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import {SET_CART_NOMAL, SET_CART_REGUALAR} from "../actions/types";
import {useDispatch, useSelector} from "react-redux";

const OrderCompletePage = (props) => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const {userInfo} = useSelector((state) => state.userLogin);
    let regular = props.location.state;

    useEffect(async () => {
        const config={
            headers: {
                'Content-Type': 'application/json',
               "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
            },
        };
        const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/order/cart`, config);
        dispatch({type: SET_CART_NOMAL, payload: [...data.carts]})
        dispatch({type: SET_CART_REGUALAR, payload: [...data.regular_carts]})
    }, [])
    return (
        <OrderCompleteContainer className="center">
            <div className="wrap_title">
                <h2>결제완료</h2>
                <div className="purchase_step">
                    <ul>
                        <li>01. 장바구니</li>
                        <li>02. 주문/결제</li>
                        <li className="active">03. 결제완료</li>
                    </ul>
                </div>
            </div>
            <div className="complete_wrap">
                <img src={icon_chk_box} alt="결제완료" />
                {regular ? <p>정기배송 신청이 완료되었습니다! <br/> 앞으로 밀리와 체계적인{isMobile && <br />}식사관리를 받아보세요 :)</p> : <p>결제가 완료되었습니다 :)</p> }
                <div className="button_wrap">
                    <Button onClick={() => window.location.href="/mypage/myorders"} color="white" outline>주문내역보기</Button>
                    <Button onClick={() => window.location.href="/"}>홈으로 가기</Button>
                </div>
                <div className="order_guide">
                    {regular ?
                        <React.Fragment>
                            <p style={{wordBreak:"keep-all"}}><span><BsDot /></span><span>정기배송의 해지는 3회차 배송완료 10일 후 [마이페이지]-[나의정기배송]-[정기배송상세]에서 가능합니다.</span></p>
                            <p style={{wordBreak:"keep-all"}}><span><BsDot /></span><span>정기배송 서비스 이용 중 불편사항이 있는 경우 언제든 카카오톡으로 문의해주시면 빠르게 해결해 드리겠습니다.</span></p>
                        </React.Fragment> :
                        <React.Fragment>
                            <p style={{wordBreak:"keep-all"}}><span><BsDot /></span><span>‘입금확인’ , ‘결제완료’ 상태일 때 [마이페이지] <MdKeyboardArrowRight /> [주문내역]에서 주문 최소가 가능합니다.</span></p>
                            <p style={{wordBreak:"keep-all"}}><span><BsDot /></span><span>주문 및 배송, 기타 문의가 있을 경우 바램펫 공식 카카오톡 채널 ‘바램시스템’으로 문의를 남겨주시면 신속히 해결해드리겠습니다.</span></p>
                        </React.Fragment>
                    }


                </div>
            </div>
        </OrderCompleteContainer>
    );
};

const OrderCompleteContainer = styled.div`
  padding: 80px 0;

  & .wrap_title {
    display: flex;
    align-items: flex-end;
    & .purchase_step {
      margin-left: auto;
      & ul {
        display: flex;
        & li {
          padding-right: 26px;
          line-height: 22.5px;
          color: #888;
          font-size: 0.938rem;
          &:not(:last-child) {
            background: url(${arr_more}) no-repeat right 11px center;
            background-size: 4.5px 9px;
          }
          &.active {
            font-weight: 500;
            color: #222;
          }
        }
      }
    }
  }

  & .complete_wrap {
    padding: 60px 0;
    text-align: center;
    & img {
      margin-bottom: 30px;
    }
    & > p {
      font-size: 1.625rem;
      line-height: 39px;
      color: #222;
    }
    & .button_wrap {
      margin: 80px auto 60px;
      width: 490px;
    }
    & .order_guide {
      width: 680px;
      padding: 40px;
      margin: auto;
      background: #f6f6f6;
      text-align: left;
      & p {
        display: flex;
        font-size: 1rem;
        color: #222;
        &:not(:first-child) {
          margin-top: 10px;
        }
        & span {
          line-height: 24px;
          letter-spacing: -1px;
          &:first-child {
            display: inline-block;
            width: 20px;
          }
          & svg {
            vertical-align: middle;
            margin-top: -2px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    padding: 40px 0;

    & .wrap_title {
      display: block;
      & .purchase_step {
        display: none;
      }
    }

    & .complete_wrap {
      padding: 0;
      text-align: center;
      & img {
        width: 80px;
        margin-bottom: 20px;
      }
      & > p {
        font-size: 1.25rem;
        line-height: 30px;
      }
      & .button_wrap {
        padding: 0 15px;
        margin: 50px auto;
        width: 100%;
        & > div {
          width: 100%;
          font-size: 16px;
          font-weight: 700;
          &:not(:first-child) {
            margin: 10px 0 0 0;
          }
        }
      }
      & .order_guide {
        width: 100%;
        padding: 30px 15px;
        text-align: left;
        & p {
          display: flex;
          & span {
            line-height: 21px;
            font-size: 0.875rem;
            color: #222;
            &:first-child {
              display: inline-block;
              width: 20px;
            }
          }
        }
      }
    }
  }
`;

export default OrderCompletePage;