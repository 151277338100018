import React, {useState, useEffect, useRef, useCallback} from 'react';
import styled from "styled-components"
import Button from "pages/components/Button";
import Dialog from "pages/components/Dialog";
import ico_unchecked from "images/ico_unchecked.png";
import ico_check_blue from "images/ico_check_blue.png";
import m_ico_check_blue from "images/m/ico_check_blue.png";
import {userOutService} from "actions/userActions";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {
    isPassWordLower,
    isPasswordOk,
    isPassWordSpecial,
    isPassWordUpper,
    validateCodeNum
} from "../../../utils/Validated";
import AuthTimer from "../../components/AuthTimer";
import {useHistory} from "react-router-dom";

const UserInfoAfterPW = ({checkUser}) => {
    const [ noOutUser, setNoOutUser ] = useState(false);
    const [ outUser, setOutUser ] = useState(false);
    const [ outUserFinish, setOutUserFinish ] = useState(false);
    const userInfo = useSelector((state) => state.userLogin.userInfo);
    const dispatch = useDispatch();
    const kakao = checkUser?.data?.login_type === "kakao"
    const [userProfile, setUserProfile] = useState({})
    const submit = useRef()


    useEffect(() => {
        const fetchProfile = async () => {
            if(userInfo.data) {
                const config={
                    headers: {
                        'Content-Type': 'application/json',
                        "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
                    },
                }
                try{
                    const res = await axios.get(`${process.env.REACT_APP_API_URL}api/user/info`, config);
                    setUserProfile(res.data)
                } catch(error){
                    console.log(error)
                }
            }
        }
        fetchProfile()
    },[])


    const goOutUserFinish = () => {
        setOutUser(false);
        setOutUserFinish(true);
    }

    const handleUserOutFirst = async () => {
        const config={
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                "encoded" : userInfo.data.encoded,
            },
        }
        try{
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}api/user/device`, config
            );

            if(response.data.data.devices !== 0){
                setNoOutUser(true);
            }else{
                setOutUser(true)
                setNoOutUser(false);
            }
        }catch(e){
            console.log(e);
        }
    }

    const handleUserOutEnd = () => {
        dispatch(userOutService());
        setOutUserFinish(true)
    }


    const UserInfoForm  = ({checkUser}) => {
        const [email, setEmail] = useState('');
        const [currrentPw, setcurrrentPw] = useState('');
        const [newPw, setnewPw] = useState('');
        const [newPwCheck, setnewPwCheck] = useState('');
        const [name, setname] = useState('');
        const [phone, setPhone] = useState('');
        const [phoneCheck, setPhoneCheck] = useState('');
        const [smsAgree, setsmsAgree] = useState(false);
        const [emailAgree, setemailAgree] = useState(false);
        const [hidePassword, setHidePassword] = useState(true);
        const history = useHistory();

        const [phoneCheckBtn, setPhoneCheckBtn] = useState(false);
        const [phoneSignBtn, setPhoneSignBtn] = useState(false);

        const [pw1, setPw1] = useState(false)
        const [pw2, setPw2] = useState(false)
        const [pw3, setPw3] = useState(false)

        const emailref = useRef();
        const pwref = useRef();
        const newpwref = useRef();
        const newpwcheckref = useRef();
        const nameref = useRef();
        const phoneref = useRef();
        const phoneBtnref = useRef();
        const phoneCheckref = useRef();
        const phoneCheckBtnref = useRef();

        useEffect(() => {
            if (isPasswordOk(newPw)) {
                setPw1(true);
                setPw2(true);
                setPw3(true);
            } else {
                if (isPassWordSpecial(newPw)) {
                    setPw3(true);
                }
                if (isPassWordUpper(newPw)) {
                    setPw1(true);
                }
                if (isPassWordLower(newPw)) {
                    setPw2(true);
                }
            }
        }, [newPw])
        useEffect(() => {
            if (userProfile.data) {
                setEmail(userProfile.data.email);
                setname(userProfile.data.user_name);
                setPhone(userProfile.data.phone_number);
                setsmsAgree(userProfile.additional_info?.marketing_ad_allow_sms);
                setemailAgree(userProfile.additional_info?.marketing_ad_allow_email);
            }
        }, [])

        const [counter, setCounter] =useState(0); //카운터 default 값


        const onInputChange = (e) => {
            const value = e.target.value;
            switch (e.target.name) {
                case "email":
                    setEmail(value);
                    break;
                case "password":
                    setcurrrentPw(value);
                    break;
                case "passwordConfirm":
                    setnewPw(value);
                    break;
                case "passwordConfirmcheck":
                    setnewPwCheck(value);
                    break;
                case "user_name":
                    setname(value);
                    break;
                case "phone_number":
                    setPhone(value);
                    break;
                case "phone_check":
                    setPhoneCheck(value);
                    break;
                case "agree_notice":
                    setemailAgree(e.target.checked? true: false);
                    setsmsAgree(e.target.checked? true: false);
                    break;
                case "agree_sms":
                    setsmsAgree(e.target.checked? true: false);
                    setemailAgree(e.target.checked? true: false);
                    break;
                case "agree_email":
                    setemailAgree(e.target.checked? true: false);
                    setsmsAgree(e.target.checked? true: false);
                    break;
            }
        }
        const onKeydown = (e) => {
            if (e.keyCode !== 13) { return }

            switch (e.target.name) {
                case "email":
                    pwref.current.focus();
                    break;
                case "password":
                    newpwref.current.focus();
                    break;
                case "passwordConfirm":
                    newpwcheckref.current.focus();
                    break;
                case "passwordConfirmcheck":
                    nameref.current.focus();
                    break;
                case "user_name":
                    phoneref.current.focus();
                    break;
                case "phone_number":
                    break;
            }
        }
        const removePhonenumberInput = () => {
            if (!phoneref.current) {return}
            phoneref.current.value = "";
        }
        const removeSignInput = () => {
            if (!phoneCheckref.current) {return}
            phoneref.current.value = "";
        }


        const [inputState, setInputState] = useState({phone_number: "", number_check: ""}); //인풋에 작성하는 값
        const {phone_number, number_check} = inputState; //인풋 작성의 구조분해 할당 값
        const [hideNumber, setHideNumber] = useState(true);
        const [phoneNumSend, setPhoneNumSend] = useState({ //번호 전송시 변하는 버튼 값
            btnColor: "gray",
            phoneDisabled: false,
            codeNumDisabled: true,
        })


        const checkPhone = useCallback( //휴대폰 번호 전송
            (e) => {
                e.preventDefault();
                axios.post(`${process.env.REACT_APP_API_URL}api/auth/validPhone`, {phone_number: phone})
                    .then((res)=>{
                        if(res.data.success){
                            setCounter(180)
                            setPhoneNumSend( {
                                ...phoneNumSend,
                                btnColor: "blue",
                                phoneDisabled: true,
                                codeNumDisabled: false})
                        }else{
                            console.log(res)
                        }
                    }).catch((error)=>{
                    console.log(error)
                })
            }
        )

        const checkNumber = useCallback( //인증된 번호 전송
            (e) => {
                e.preventDefault();
                axios.post(`${process.env.REACT_APP_API_URL}api/auth/checkPhone`,{
                    phone_number: phone, "code": number_check, "type": "general"
                }).then((res) => {
                    if(res.data.type === "success"){  //인증번호 승인
                        alert("인증되었습니다.")
                        setPhoneNumSend( {
                            ...phoneNumSend,
                            btnColor: "blue",
                            phoneDisabled: false,
                            codeNumDisabled: true})
                            editProfile()
                    }else{
                        alert("인증번호가 맞지않습니다.")
                    }
                }).catch((error)=>{
                    console.log(error)
                })
            }
        )

        const inputHandler = useCallback((key) => (e) => { //인풋 핸들러
                return setInputState({
                    ...inputState,
                    [key]: e.target.value,
                });
            },[inputState]
        )

        const editProfile = async () => {
            try{
                axios.defaults.headers.common["cid"] = userInfo.data.cid;
                axios.defaults.headers.common["encoded"] = userInfo.data.encoded;
                const response = await axios.post(`${process.env.REACT_APP_API_URL}api/user/edit`, { edit :"edit", data : {
                        user_name: name,
                        marketing_agree: smsAgree || emailAgree
                    }})
                changePhone();
                alert("변경되었습니다.")
                document.location.href="/mypage/userinfofix"
            }catch(e){
                console.log(e)
            }
        }
        const changePhone = async () => {
            try{
                axios.defaults.headers.common["cid"] = userInfo.data.cid;
                axios.defaults.headers.common["encoded"] = userInfo.data.encoded;
                const res = await axios.post(`${process.env.REACT_APP_API_URL}api/user/changePhone`, {
                    phone_number: phone
                })
            }catch(e){
                console.log(e)
            }
        }

        return (
            <div className="join_wrap">
                <div className="join_box">
                    <div className="join_title">이메일 아이디</div>
                    <div className="join_cont">
                        <p><input type="text" name="email" ref={emailref} disabled onChange={onInputChange} onKeyDown={onKeydown} value={email} placeholder="이메일을 입력해주세요" /></p>
                    </div>
                </div>
                {/*{kakao ? null :  <React.Fragment>*/}
                {kakao ? null : <React.Fragment>
                    <div className="join_box">
                        <div className="join_title">현재 비밀번호</div>
                        <div className="join_cont">
                            <p><input type="password" name="password" ref={pwref} onChange={onInputChange} onKeyDown={onKeydown} value={currrentPw} disabled placeholder="현재 비밀번호 입력"  /></p>
                        </div>
                    </div>
                    <div className="join_box">
                        <div className="join_title">새 비밀번호</div>
                        <div className="join_cont">
                            <p><input type="password" name="passwordConfirm" ref={newpwref} onChange={onInputChange} onKeyDown={onKeydown} value={newPw} disabled placeholder="영문 대소문자+특수문자 (8자리 이상)" /></p>
                            <p className="sub_desc onMarked">
                                <b></b>
                            </p>
                        </div>
                    </div>
                </React.Fragment>}
                <div className="join_box">
                    <div className="join_title">이름</div>
                    <div className="join_cont">
                        <p><input type="text" placeholder="ex) 홍길동" name="user_name" ref={nameref} onChange={onInputChange} onKeyDown={onKeydown} value={name} disabled={kakao ? true : false} /></p>

                    </div>
                </div>
                <div className="join_box">
                    <div className="join_title phone">휴대폰 번호</div>
                    <div className="join_cont phone_cont">
                        <div>
                            <p>
                                <input type="text" name="phone_number" value={phone} disabled={phoneNumSend.phoneDisabled ? true : false} tabIndex="5" placeholder="숫자만 입력해주세요." ref={phoneref} onChange={onInputChange} style={{marginBottom: "5px"}} value={phone} />

                            </p>
                            <p>
                                <SmallButton onClick={checkPhone} size="small">인증번호 받기</SmallButton>
                            </p>
                        </div>
                        <div>
                            <p>
                                <input type="text" name="phone_check" value={number_check} disabled={phoneNumSend.codeNumDisabled ? true : false} onFocus={(e) => {setHideNumber(!hideNumber)}} onChange={inputHandler("number_check")} placeholder="인증번호 입력" tabIndex="6" ref={phoneCheckref} />
                            </p>
                            <p>
                                <SmallButton className="sc-kDTinF jwpHme sc-jcFjpl ipsyil" color={phoneNumSend.btnColor} onClick={validateCodeNum(number_check) ? checkNumber : null} onClick={checkNumber}>인증번호
                                    확인
                                </SmallButton>
                            </p>
                        </div>
                        {phoneNumSend.phoneDisabled ? <span>
                              {counter === 0 ? <p className="sub_desc"></p> : <p className="sub_desc">인증번호를 발송하였습니다.
                                  <span className="counter_msg">유효시간 : <AuthTimer counter={counter} setCounter={setCounter} /></span></p>}
                        </span> : null}
                    </div>


                </div>
                <div className="join_box agreement_box">
                    <div className="join_title">이용약관동의</div>
                    <div className="join_cont">
                        <div className="agree_wrap">
                            <div className="checks full_check">
                                <input type="checkbox" name="agree_notice" id="agree_notice" checked={smsAgree || emailAgree} onChange={onInputChange}/>
                                <label htmlFor="agree_notice">[선택] 무료배송, 할인 쿠폰 등 마케팅&nbsp;&nbsp;혜택 및 정보 수신 동의</label>
                            </div>
                            <div className="sns_agree">
                                <div className="checks">
                                    <input type="checkbox" name="agree_sms" id="agree_sms" checked={smsAgree} onChange={onInputChange}/>
                                    <label htmlFor="agree_sms">SMS</label>
                                </div>
                                <div className="checks">
                                    <input type="checkbox" name="agree_email" id="agree_email" checked={emailAgree} onChange={onInputChange}/>
                                    <label htmlFor="agree_email">이메일</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {kakao ? <div className="button_wrap">
                    <MainButton onClick={() => {editProfile();}}>회원정보수정</MainButton>
                </div> : <div className="button_wrap">
                    <MainButton color="darkgray" onClick={handleUserOutFirst}>탈퇴하기</MainButton>
                    <MainButton onClick={() => {editProfile();}}>회원정보수정</MainButton>
                </div>}
                <input type="submit" ref={submit} style={{display:"none"}} />
            </div>
        )
    }

    return (
        <UserInfoAfterPWContainer kakao={kakao}>
            <div className="mypage_title">
                <h2>회원정보 수정</h2>
            </div>
            <div className="user_info_wrap">
                <UserInfoForm checkuser={checkUser}/>
            </div>
            <NoOutUserDialog>
                <Dialog
                    confirmText="확인"
                    onConfirm={() => {setNoOutUser(false);}}
                    visible={noOutUser}
                >
                    <p>바램펫 제품을 사용중인 회원님은<br />앱에서 탈퇴가 가능합니다.</p>
                </Dialog>
            </NoOutUserDialog>
            <OutUserDialog>
                <Dialog
                    title="탈퇴하기"
                    cancelText="취소"
                    onCancel={() => setOutUser(false)}
                    dialogSize="small"
                    visible={outUser}
                >
                    <p>회원 탈퇴시 알갱이와 쿠폰은 되돌려 드리지 않습니다.</p>
                    <p style={{marginTop: "10px"}}>회원 탈퇴를 진행하시겠습니까?</p>
                    <div className="button_wrap">
                        <Button size="small" onClick={() => setOutUser(false)}>취소</Button>
                        <Button size="small" color="darkgray" onClick={() => {setOutUser(false); handleUserOutEnd();}}>탈퇴하기</Button>
                    </div>
                </Dialog>
            </OutUserDialog>
            <OutUserFinishDialog>
                <Dialog
                    onCancel={() => setOutUserFinish(false)}
                    onConfirm={() => {setOutUserFinish(false); document.location.href = "/";}}
                    confirmText="확인"
                    dialogSize="medium"
                    visible={outUserFinish}
                >
                    <p>회원탈퇴 완료되었습니다.<br />보다 다은 바램펫으로 다시 만나뵐 수 있길 바랍니다.</p>
                </Dialog>
            </OutUserFinishDialog>
        </UserInfoAfterPWContainer>
    );
};
const MainButton = styled(Button)`
  @media only screen and (max-width: 960px) {
    height: 50px;
    font-weight: 700;
    font-size: 1rem;
  }
`;

const SmallButton = styled(Button)`
  width: 120px;
  height: 46px;
  border-radius: 0;
  margin-left: 10px;
  font-size: 0.938rem;
  @media only screen and (max-width: 960px) {
    height: 45px;
    width: 100px;
    font-weight: 500;
    font-size: 0.875rem;
    margin-left: 5px;
  }
`

const UserInfoAfterPWContainer = styled.div`
  & .mypage_title {
    margin-bottom: 15px;

    @media only screen and (max-width: 960px) {
      margin-bottom: 0;
    }
  }
  & .user_info_wrap {
    border-top: 1px solid #333;
    border-bottom: 1px solid #ccc;

    @media only screen and (max-width: 960px) {
      padding: 0 15px;
      border: none;
    }
  }

  & .join_wrap {
    width: 730px;
    margin: 0 auto;
    padding: 60px 0 30px;

    & .join_box {
      display: flex;
      align-items: flex-start;
      width: 100%;
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    & .join_title {
      width: 230px;
      padding-top: 10px;
      font-size: 1rem;
      line-height: 24px;
      color: #222;
    }

    & .join_cont {
      width: calc(100% - 230px);

      & > div {
        display: flex;
        width: 100%;

        & p:first-child {
          width: calc(${props => props.kakao ? "100%" : "100% - 130px"});
        }
        p {
          position: relative;
        }
      }

      &.phone_cont > div:last-child {
        margin-top: 10px;
      }

      input {
        color: #222;
      }
      & .del_text {
        z-index: 100;
        position: absolute;
        right: 10px;
        top: 13px;
        & path {
          stroke: #ccc;
        }
      }
      & .sub_desc {
        margin-top: 8px;
        color: #1ea0fa;
        font-size: 0.75rem;
        line-height: 18px;

        span {
          display: inline-block;
          margin-right:
                  24px;
          padding-right: 13px;
          background: url(${ico_unchecked}) no-repeat right top 5px;
          background-size: 8px;
          font-size: 0.75rem;
          color: #999;
          line-height: 18px;

          &.active {
            background: url(${ico_check_blue}) no-repeat right top 5px;
            color: #1EA0FA;
            background-size: 8px;
          }
        }
      }
    }

    & .counter_msg{
      color: #ff2727 !important;
      font-size: 0.75rem;
      line-height: 18px;
    }

    @media only screen and (max-width: 960px) {
      width: 100%;
      padding: 0;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;

      & .join_box {
        flex-direction: column;
        width: 100%;
        margin-top: 30px;
      }

      & .join_title {
        width: 100%;
        padding-top: 0;
        margin-bottom: 10px;
        font-size: 0.938rem;
        line-height: 22px;
      }

      & .join_cont {
        width: 100%;

        & > div {
          display: flex;
          width: 100%;

          & p:first-child {
            width: calc(${props => props.kakao ? "100%" : "100% - 105px"});
          }
        }

        & .sub_desc {
          span {
            display: inline-block;
            margin-right: 13px;
            padding-right: 18px;
            background: url(${ico_unchecked}) no-repeat right top 5px;
            background-size: 8px;

            &.active {
              background: url(${m_ico_check_blue}) no-repeat right top 5px;
              color: #1EA0FA;
              background-size: 8px;
            }
          }
        }
      }
    }
  }

  & .agreement_box {
    & .join_title {
      padding-top: 0;
    }

    & > div {
      .checks:not(.full_check) input[type="checkbox"] + label:before {
        width: 24px;
        height: 24px;
        background-position: center;
      }
      .checks:not(.full_check) input[type="checkbox"]:checked + label:before {
        background:url(${ico_check_blue}) no-repeat center;
        @media only screen and (max-width: 960px) {
          background:url(${m_ico_check_blue}) no-repeat center;
          background-size: 12px auto;
        }
      }
      label {
        font-size: 1.125rem;
        line-height: 27px;
        color: #222;
      }
    }

    & .agree_wrap {
      margin-top: 0 !important;
      margin-left: 5px;
      flex-direction: column;

      & >.checks {
        display: flex;
        align-items: flex-start;
        &:not(.no_more) {
          label {
            font-size: 14px;
            line-height: 21px;
            padding-left: 34px;
            &::before {
              position: absolute;
              left: 0;
              top: 0;
            }
          }
          &:not(:first-child) {
            margin-top: 12px;
          }
        }
      }
      .sns_agree {
        .checks input[type="checkbox"] + label:before {
          position: absolute;
          left: 0;
          top: 4px;
          width: 17px;
          height: 17px;
          margin-right: 17px;
          background-position: center;
          border: none;
        }
        .checks input[type="checkbox"]:checked + label:before {
          background-position: center;
        }
        label {
          font-size: 0.875rem;
          line-height: 21px;
          color: #222;
          padding: 0 27px;
        }
      }
    }

    & .sns_agree {
      display: flex;
      padding-left: 35px;
      margin-top: 13px;

      & .checks:first-child {
        margin-right: 105px;
      }
    }

    & .img_agree_sns {
      margin: 8px 0 0 28px;
    }

    @media only screen and (max-width: 960px) {
      margin-bottom: 30px;
      & > div {
        label {
          font-size: 0.875rem;
          line-height: 21px;
        }
      }

      & .agree_wrap {
        margin-top: 0 !important;
        margin-left: 5px;
        flex-direction: column;

        & >.checks {
          &:not(.no_more) {
            &:not(:first-child) {
              margin-top: 14px;
            }
          }
        }
      }

      & .sns_agree {
        padding-left: 45px;
        margin-top: 13px;
        & .checks:first-child {
          margin-right: 26%;
        }
      }
    }
  }

  & .button_wrap {
    margin: 60px auto 0;
    width: 490px;
    text-align: center;

    @media only screen and (max-width: 960px) {
      margin: 30px 0;
      padding: 0 15px;
      width: 100%;
    }
  }
`

const OutUserDialog = styled.div`
  & .dialog_wrap {
    padding: 50px 30px 35px;
    & .close {
      display: none;
    }
  }
  & .dialog_inner {
    margin-top: 40px;

    & p {
      font-size: 1rem;
      text-align: center;
      line-height: 24px;
      &:last-child {
        margin-top: 10px;
      }
    }
  }
  & div.button_wrap {
    width: 100%;
    margin: 50px 0 0 0;
    & div {
      width: calc((100% - 10px) / 2);
      border-radius: 4px;
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: 960px) {
    & .dialog_inner {
      margin-top: 20px;
    }
    & .button_wrap {
      width: 100%;
      margin: 30px auto 0;
      padding: 0;
      text-align: center;
      & div {
        width: calc((100% - 10px) / 2);
        max-width: 140px;
        border-radius: 4px;
        font-size: 1rem;
      }
    }
  }
`

const OutUserFinishDialog = styled.div`
  & .dialog_wrap {
    padding: 123px 0 30px;
    & .close {
      display: none;
    }
    & p {
      text-align: center;
    }
    & .ButtonWrap {
      margin-top: 84px;
      & div {
        width: 227px;
        font-weight: 700;
      }
    }
  }

  @media only screen and (max-width: 960px) {
    & .dialog_wrap {
      padding: 70px 30px 30px;
      & .ButtonWrap {
        margin-top: 50px;
        & div {
          width: 140px;
        }
      }
    }
  }
`

const NoOutUserDialog = styled.div`
  & .dialog_wrap {
    padding: 123px 0 30px;
    & .close {
      display: none;
    }
    & p {
      text-align: center;
    }
    & .ButtonWrap {
      margin-top: 84px;
      & div {
        width: 227px;
        font-weight: 700;
      }
    }
  }
  @media only screen and (max-width: 960px) {
    & .dialog_wrap {
      padding: 70px 30px 30px;
      & .ButtonWrap {
        margin-top: 50px;
        & div {
          width: 140px;
        }
      }
    }
  }
`

export default UserInfoAfterPW;