import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { IoIosArrowDown } from 'react-icons/io';
import {useSelector, useDispatch} from "react-redux";
import {setEventLists} from "../actions/boardActions";

const EventPage = () => {
    const dispatch = useDispatch();
    const isPc = useMediaQuery({ query: '(min-width: 961px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const eventLists = useSelector((state) => state.eventLists.events)

    useEffect(() => {
        dispatch(setEventLists(0, 10))
    }, []);

    const [limit, setLimit] = useState(3); //더보기 리미트
    const moreView = () => {
        setLimit(limit + 3);
    }

    return (
        <EventContainer>
            <div className="board_wrap">
                <div className="wrap_title board_title">
                    <h2>바램펫 이벤트</h2>
                </div>
                <div className="event_wrap">
                    {eventLists.length > 0 ? <React.Fragment>
                            {eventLists.slice(0, limit).map((list,idx) => {
                                return(
                                    <div className="event_list" key={idx}>
                                        <Link to={{pathname: `/event/eventdetail/${list.id}`,
                                            state: list
                                        }}>
                                            <div className="thumb">
                                                {isPc ?
                                                    <img src={list.thumbnail} alt={list.title} /> :
                                                    list.mobile_thumbnail ?
                                                        <img src={list.mobile_thumbnail} alt={list.title}/> :
                                                        null
                                                }
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })}
                        </React.Fragment> :
                        <React.Fragment>
                            <p style={{textAlign:"center", fontSize: "16px", lineHeight: "2.2rem"}}>현재 진행하는 이벤트가 없습니다.</p>
                        </React.Fragment>}
                    <div className="list_more_btn">
                        {eventLists.length > 3 && eventLists.length > limit ?
                            <button className="moreView" onClick={() => {moreView()}} >더보기<IoIosArrowDown />
                            </button> :
                            null}
                    </div>
                </div>
            </div>
        </EventContainer>
    );
}

const EventContainer = styled.div`
  & .board_wrap {
    padding-bottom: 10px;
  }
  & .event_wrap {
    margin-top: 60px;
    & .event_list {
      overflow: hidden;
      border-radius: 12px;
      &:not(:first-child) {
        margin-top: 20px;
      }
      & .thumb {
        position: relative;
        width: 100%;
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  & .button_wrap {
    margin-top: 60px;
    text-align: center;
    & span {
      display: inline-block;
      padding: 13px 64px;
      border: 1px solid #ddd;
      border-radius: 54px;
      font-size: 1.125rem;
      color: #222;
      line-height: 27px;
      cursor: pointer;
      font-weight: 500;
      & img {
        width: 12px;
        margin-left: 10px;
        margin-top: -3px;
        vertical-align: middle;
      }
    }
  }

  @media only screen and (max-width: 960px) {
    & .event_wrap {
      margin-top: 0;
      & .event_list {
        &:not(:first-child) {
          margin-top: 10px;
        }
        & .thumb {
          //padding-top: 43.47%;  
        }
      }

    }
    & .button_wrap {
      margin-top: 40px;
      & span {
        display: inline-block;
        padding: 16px 56px;
        border-radius: 63px;
        font-size: 0.875rem;
        line-height: 21px;
        & img {
          width: 8px;
          margin-top: -3px;
          vertical-align: middle;
        }
      }
    }
  }
`

export default EventPage;