import React from "react";
import styled from "styled-components";
import 'rc-rate/assets/index.css';
import Dialog from './Dialog';

function RegularServicePopup2({ visible, onCancel }) {
    return (
        <RegularServiceDialog visible={visible}>
            <Dialog
                title="정기배송 및 해지에 관한 내용 동의"
                sub_title="[필수]"
                cancelText="닫기"
                onCancel={onCancel}
                fullHeight
                fixHeight
                visible={visible}
            >
                <div className="cont_wrap">
                    <p>
                        ■ 이 정기배송 서비스 이용안내(이하 “이 이용안내”)는 회사가 제공하는 정기배송서비스(이하 “서비스”)의 이용조건 및 절차 등에 관한 기본적인 사항 등을 규정하는 데 그 목적이 있습니다.<br /><br />
                        ■ 회원은 서비스 이용계약을 체결하기 전에 이 이용안내에서 회사가 명시, 고지하는 사항을 숙지하고, 착오 없이 정확히 거래할 수 있도록 해야 합니다.
                    </p>
                    <div className="cont_box">
                        <p className="popup_tit">1. 서비스 이용 및 신청방법</p>
                        <p>
                            ① 이 서비스는 회사가 바램펫몰 회원에게 선택한 기간 동안 자동결제방식을 통하여 회원 지정 요일에 상품을 배송 받을 수 있도록 편의를 제공하는 서비스입니다.<br />
                            ② 서비스 신청 가능한 상품은 바램펫몰에서 지정한 상품에 한해 운영됩니다.<br />
                            ③ 서비스 신청 및 결제 시에는 자동결제 가능한 제휴신용카드만 사용할 수 있으며 쿠폰, 기프트카드, 알갱이 등 기타 결제수단 결제는 불가합니다.<br />
                            ④ 회사는 정기배송 상품에 대하여 적용된 할인 외에 추가 쿠폰 할인혜택 또는 알갱이 적립혜택을 제공하지 않습니다. 할인율, 거래조건 등은 상품별로 다르게 설정되며 변경될 수 있습니다.<br />
                            ⑤ 이 서비스 특성상 정기배송 상품의 가격은 프로모션에 따라 계속적으로 변경될 수 있으며, 회원에게는 1회차 서비스 신청 시 고지된 시점의 상품가격을 기준으로 정기 결제가 이루어집니다.
                        </p>
                    </div>
                    <div className="cont_box">
                        <p className="popup_tit">2. 서비스 이용계약의 성립</p>
                        <p>
                            ① 서비스 이용계약은 회원이 이 이용안내에 명시된 사항에 동의한다는 의사표시(동의함을 선택)와 함께 이용신청을 하고 회사가 이를 승낙함으로써 비로소 성립합니다.<br />
                            ② 회원이 이 이용안내에 대하여 “동의하기” 버튼을 클릭한 경우, 여기에 명시된 내용을 충분히 이해하고 그 적용에 동의한 것으로 봅니다.<br />
                            ③ 회사와 회원 간 서비스 이용계약은 회사의 승낙이 회원에게 도달한 시점(정기배송서비스의 “구매/결제완료 등”의 표시가 회원에게 절차상 표시된 시점)에 성립합니다.
                        </p>
                    </div>
                    <div className="cont_box">
                        <p className="popup_tit">3. 자동결제 및 알림톡/LMS 발송</p>
                        <p>
                            ① 회원은 상시 서비스를 신청할 수 있으며, 서비스 신청은 1회차 상품에 대한 결제일에 완료됩니다.<br />
                            ② 2회차 배송 상품에 대한 결제는 서비스 신청 시 결제한 신용카드정보를 기준으로 자동 결제되며, 자동결제 시기는 최초(1회차) 결제한 날짜입니다.<br />
                            ③ 카카오 알림톡 혹은 LMS 발송문자에 대한 확인 의무는 고객에게 있으며, 발송문자 미확인에 대한 손해는 회사에서 책임을 부담하지 않습니다.<br />
                            ④ 회원은 자동결제수단에 관한 결제정보를 회사에 정확히 제공하여야 하며, 변경사항 발생 시 회원은 지체 없이 회사가 정한 절차에 따라 이를 고지, 반영하여야 합니다.
                        </p>
                    </div>
                    <div className="cont_box">
                        <p className="popup_tit">4. 배송</p>
                        <p>
                            ① 회사는 결제일로부터 최대 5일 내에 회차 별 상품을 회원에게 발송합니다.<br />
                            ② 회사는 회원이 상품 등의 공급절차 및 진행사항을 확인할 수 있도록 적절한 조치를 합니다.<br />
                            ③ 각 회차 별 배송지 변경은 바램펫몰 내 ‘마이페이지 &#62; 정기배송조회 &#62; 주문내역 상세’에서 회원이 직접 진행할 수 있으며, 상품출고 이후에는 배송지 변경이 불가합니다.<br />
                            ④ 제품 품절/단종 등의 이슈로 상품 발송이 힘들 경우 회사는 고객에게 고지를 하고 고객 확인 후 상품 변경 혹은 주문 취소 등의 조치를 합니다.
                        </p>
                    </div>
                    <div className="cont_box">
                        <p className="popup_tit">5. 청약철회 및 패널티 부가 효과 등</p>
                        <p>
                            ① 이 서비스에 대한 청약철회는 바램펫몰 내 ‘마이페이지 &#62; 나의 정기배송 &#62; 정기배송 상세’에서 회원이 직접 진행할 수 있습니다. 이 경우 청약철회 시점부터 이 서비스를 전부 해지한 것으로 인정됩니다.<br />
                            ② 회원은 각 1회차 이후에는 회차 별 정기결제 전에는 별도 비용 없이 해당 정기 배송 상품 구매에 대한 청약을 철회할 수 있습니다.<br />
                            ③ 회원은 각 회차 별 배송 상품 출고 후 청약을 철회하는 경우에는 바램펫몰 교환/반품프로세스에 의해 처리됩니다.<br />
                            ④ 기타 청약철회 및 효과 등에 관한 사항은 바램펫 멤버십 약관의 규정이 그대로 적용됩니다.
                        </p>
                    </div>
                    <div className="cont_box">
                        <p className="popup_tit">6. 회원의 정보기재 등</p>
                        <p>
                            ① 회원은 서비스 신청을 위한 제반 사항을 기재할 경우 회원의 현재의 사실과 일치하는 정보를 기재해야 합니다.<br />
                            ② 회원은 정기배송 서비스 이용 기간 중 회원의 정보가 변경될 경우 다음 각호에 따라 변경사항을 회사에 고지, 반영해야 합니다.
                        </p>
                        <p class="pd10">
                            - 상품 수령지는 변경가능 하나, 각 회차 별 상품출고 이후에는 변경이 불가합니다.<br />
                            - 결제신용카드는 자동결제 전에 변경가능하며, 바램펫몰이 이 서비스 내에서 제공하고 있는 신용카드에 한해 변경 가능합니다.
                        </p>
                        <p>
                            ③ 회원은 바램펫몰 내 ‘마이페이지 &#62; 정기배송조회와 주문/배송내역’에서 이 서비스 신청을 위한 제반 사항을 기재하거나 변경사항을 직접 반영할 수 있습니다.
                        </p>
                    </div>
                    <div className="cont_box">
                        <p className="popup_tit">7. 서비스 이용 제한 및 종료</p>
                        <p>
                            ① 회사는 카드 이용한도 초과 또는 이용 정지•중단 등의 사유로 상품의 결제가 이루어 지지 않을 경우 해당 회차의 서비스 이용을 제한할 수 있습니다.<br />
                            ② 회사는 회원이 상품을 재판매 가능성이 있는 경우 또는 불법적이거나 부당한 행위와 관련된 경우 이 서비스 이용을 제한할 수 있습니다.<br />
                            ③ 제2항의 서비스 이용제한에 관한 구체적인 기준은 회사 내부운영정책 및 지침에 따릅니다.<br />
                            ④ 회사는 특정상품에 대하여 구매 수량을 제한할 수 있습니다.<br />
                            ⑤ 회원은 회사에게 통지함으로써 정기배송 서비스를 종료시킬 수 있습니다.<br />
                            ⑥ 회사는 정기배송 대상 상품을 더 이상 판매할 수 없거나 해당 상품을 정기배송 서비스로 제공할 수 없는 사유가 있는 경우, 회사는 해당 상품의 정기배송 서비스를 중지하거나 해당 회차의 상품을 공급하지 않을 수 있습니다.
                        </p>
                    </div>
                    <div className="cont_box">
                        <p className="popup_tit">8. 기타</p>
                        <p>
                            ① 이 이용안내에서 정하지 않은 사항은 바램펫 멤버십 이용약관의 규정을 적용합니다.<br />
                            ② 이 이용안내에서 사용하는 용어의 정의는 이 이용안내에서 정하는 것을 제외하고는 관계 법령 및 서비스 별 안내에서 정하는 바에 의합니다.<br /><br />
                        </p>
                    </div>
                </div>
            </Dialog>
        </RegularServiceDialog>
    );
}

const RegularServiceDialog = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};

  & .dialog_wrap {
    & h3 {
      margin-bottom: 30px;
    }
    & .dialog_inner {
      max-height: calc(90vh - 113px);
      padding: 0 30px 30px 30px;
    }
    & .cont_wrap {
      padding: 0;
    }
    & .tit_wrap {
      margin-top: 40px;
      & p:last-child {
        font-size: 0.875rem;
        &::before {
          content: none;
        } 
      }
    }
    & .cont_wrap2 {
      padding-top: 20px;
    }
  }
  
  @media only screen and (max-width: 960px) {
    & .dialog_wrap {
      & h3 {
        margin-bottom: 0;
        text-align: left;
      }
      & .dialog_inner {
        padding: 25px 15px;
        max-height: calc(100vh - 60px);
      }
      & .tit_wrap {
        margin-top: 50px;
        & p:first-child {
          font-weight: 500;
        }    
        & p:last-child {
          display: flex;
          position: relative;
          align-items: center;
          padding-left: 15px;
          margin-left: auto;
          color: #515355;
          font-size: 0.813rem;
          & svg {
            margin-left: 5px;
          }
          &::before {
            content: none;
          }
        }
      }
    }
  }

  & .cont_wrap {
    padding: 60px 0;
    margin: 0 auto;
    &.displaynone {
      display: none;
    }

    & p {
      font-size: 0.875rem;
      color: #515355;
      line-height: 21px;
      & b {
        font-weight: 500;
      }
      &.pd10 {
        padding-left: 10px;
      }
      &.pd15 {
        padding-left: 15px;
      }
    }

    & p.popup_tit {
      margin: 30px 0 10px;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;
      color: #222;
    }

    & p.small_desc {
      margin-top: 10px;
      font-size: 0.813rem;
      & b {
        color: #515355;
      }
    }

    & table {
      width: 100%;
      margin-top: 10px;
      border: 1px solid #ddd;
      
      & tbody tr {
        border-top: 1px solid #ddd;
      }
      & tr{
        & th, & td {
          padding: 8px;
          font-size: 0.813rem;
          color: #515355;
          line-height: 19.5px;
          vertical-align: middle;
          &:not(:last-child) {
            border-right: 1px solid #ddd;
          }
        }
        & th {
          font-weight: 500;
          background: #f6f6f6;
        }
      }

      &.center_align {
        & tr {
          & th, & td {
            text-align: center;
          }
        }
      }
    }
  }

  & .tit_wrap {
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 24px;
    cursor: pointer;
    & p:first-child {
      font-weight: 500;
    }    
    & p:last-child {
      display: flex;
      position: relative;
      align-items: center;
      padding-left: 15px;
      margin-left: auto;
      color: #515355;
      & svg {
        margin-left: 10px;
      }
      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 10%;
        width: 1px;
        height: 80%;
        background: #999;
      }
    }
  }

  @media only screen and (max-width: 960px) {
    width: 100%;
    & .cont_wrap {
      padding: 0;
      & table {
        &.privacy_agree {
          margin-bottom: 40px;
          font-size: 0.813rem;

          & th, & td {
            line-height: 19.5px;
          }
        }
        

        &.privacy_agree2 {
          border: 1px solid #ddd;
          & tbody tr {
            border-top: 1px solid #ddd;
          }
          & tr{
            & th, & td {
              padding: 8px 2px;
              font-size: 0.75rem;
              line-height: 18px;
            }
          }
        }
      }
    }
    & .cont_wrap2 {
      padding-top: 30px;
    }
  }
`

export default RegularServicePopup2;