import React, { Component } from 'react';
import ErrorPage from "../ErrorPage";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    render() {
        const { hasError } = this.state;

        if (hasError) {
            return <ErrorPage />
        }

        const { children } = this.props;
        return children;
    }
}

export default ErrorBoundary;