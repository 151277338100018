import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import brand_bg01 from 'images/bg/brand_bg01.png';
import brand_bg02 from 'images/bg/brand_bg02.jpg';
import m_brand_bg01 from 'images/m/bg/brand_bg01.png';
import m_brand_bg02 from 'images/m/bg/brand_bg02.jpg';
import brand_people from 'images/bg/brand_people.png';
import brand_varram from 'images/bg/brand_varram.png';
import brand_img01 from 'images/bg/brand_img01.png';
import brand_img02 from 'images/bg/brand_img02.png';
import brand_img03 from 'images/bg/brand_img03.png';
import brand_img04 from 'images/bg/brand_img04.png';
import pc_brand_banner from 'images/bg/pc_brand_banner.png';
import m_brand_banner from 'images/m/bg/brand_banner.jpg';
import AOS from "aos";
import "aos/dist/aos.css";

const BrandPage = () => {
  const isPc = useMediaQuery({ query: '(min-width: 961px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' })

  useEffect(() => {
    AOS.init({
        duration : 1000
    });
  });

  return (
    <Container>
      <div className="top_img">
        <div className="center">
          <p>건강한 반려생활의 시작<br /><b>프리미엄 펫 헬스케어, {isMobile && <br />}바램펫</b></p>
        </div>
      </div>
      <div className="brand01 brand_wrap">
        <div className="text_wrap">
          <p
            className="tit"
            data-aos="fade-up"
            data-aos-offset={isPc ? "100" : "0"}
          >우리는 더 건강한<br />반려생활을 위해 연구합니다.</p>
          <p
            className="txt"
            data-aos="fade-up"
            data-aos-offset={isPc ? "100" : "0"}
            data-aos-delay="100"
          >
            실시간 섭취량을 확인할 수 있는 정밀 로드센서,<br />언제 어디서나 앱으로 한 눈에 확인하는 건강지표,<br /><br />
            바램펫을 시작하는 순간<br />더 건강하고, 더 행복한 반려생활이 가까워집니다.
          </p>
        </div>
      </div>
      <div className="brand02 brand_wrap">
        <div className="text_wrap">
          <p
            className="tit"
            data-aos="fade-up"
            data-aos-offset={isPc ? "100" : "0"}
          >우리는{isPc && <br />}반려생활의 고민을 바꿀 수 있는 {isPc && <br />}로봇 기술을 연구합니다.</p>
          <p
            className="txt"
            data-aos="fade-up"
            data-aos-offset={isPc ? "100" : "0"}
            data-aos-delay="100"
          >세계 최초 상용화된 홈 로봇 앱봇(앱봇 링크, 앱봇 라일리)을 시작으로 {isPc && <br />}앞으로 더 나은 일상, 더 행복한 반려생활을 위해 노력하겠습니다.</p>
        </div>
      </div>
      <div className="brand03 brand_wrap">
        <div className="text_wrap">
          <div className="text_top">
            <p
              className="sub_text"
              data-aos="fade-up"
              data-aos-offset={isPc ? "100" : "0"}
            >VARRAM PET TEAM</p>
            <p
              className="main_text"
              data-aos="fade-up"
              data-aos-offset={isPc ? "100" : "0"}
              data-aos-delay="100"
            >진지한 고민과 {isMobile && <br />}빠른 실행</p>
          </div>
          <div className="text_bottom">
            <div className="img_wrap"><img src={brand_people} alt="이미지" /></div>
            <div>
              <p
                className="tit"
                data-aos="fade-up"
                data-aos-offset={isPc ? "100" : "0"}
              >우리의 고객은<br />반려인과 반려동물, 둘 입니다.</p>
              <p
                className="txt"
                data-aos="fade-up"
                data-aos-offset={isPc ? "100" : "0"}
                data-aos-delay="100"
              >
                어느 한 쪽에 치우치지 않고,<br />
                보다 건강하고 즐거운 반려생활에 도움이 되기 위해 일합니다.<br /><br />
                반려인에게 가장 필요한 것은 무엇인지,<br />
                아이가 가장 원하는 것은 무엇인지를 기반으로<br />
                다양한 위험과 불안에 대해 높은 기준으로 평가합니다.<br /><br />
                반려가족이 처음 로봇을 만났을 때부터 함께 하는 동안 {isPc && <br />}
                발생할 수 있는 모든 상황을 바램펫 모두가 함께 고민하고 빠르게 해결합니다.<br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="brand04 brand_wrap">
        <ul>
          <li
            data-aos="fade-up"
            data-aos-offset={isPc ? "100" : "0"}
          >
            <img src={brand_img01} alt="" />
            <p className="tit">우리가 먼저 감동받는 전략</p>
            <p className="txt">
              바램펫은 기획과 마케팅을 함께 진행합니다.<br />
              우리는 다 같이 서비스에 대해 고민하고{isPc && <br />}
              반려가족이 우리의 서비스를 잘 이해할 수 있도록 마케팅합니다.
            </p>
          </li>
          <li
            data-aos="fade-up"
            data-aos-offset={isPc ? "100" : "0"}
            data-aos-delay={isPc ? "100" : "0"}
          >
            <img src={brand_img02} alt="" />
            <p className="tit">어려운 기술을 쉽고 가깝게 바램연구소</p>
            <p className="txt">
              좋은 로봇을 만들기 위해서는 뛰어난 기술력도 중요하지만,{isPc && <br />}
              가장 중요한 것은 고객의 만족입니다.<br />
              어떤 제품보다 쉽게, 그리고 편리하게 일상에 행복을 주는 로봇을 개발합니다.
            </p>
          </li>
          <li
            data-aos="fade-up"
            data-aos-offset={isPc ? "100" : "0"}
          >
            <img src={brand_img03} alt="" />
            <p className="tit">앞장서서 숨은 걱정까지 고객만족팀</p>
            <p className="txt">
              고객이 궁금해 하는 부분, 제품 문제를 빠르게 해결해드립니다.<br />
              전해주신 이야기들 속에서 숨은 걱정까지 찾고,<br />
              그런 고민들을 해결하기 위해 연구소에 전달합니다.
            </p>
          </li>
          <li
            data-aos="fade-up"
            data-aos-offset={isPc ? "100" : "0"}
            data-aos-delay={isPc ? "100" : "0"}
          >
            <img src={brand_img04} alt="" />
            <p className="tit">국내 생산부터 유통까지 생산/물류팀</p>
            <p className="txt">
              더욱 만족스러운 고객 경험을 위해 효율적이되 안정적인 방법을 생각합니다.<br />
              바램펫의 모든 제품은 국내 생산 및 유통으로 무상 보증 기간 내 무상 A/S를 지원합니다.<br />
            </p>
          </li>
        </ul>
      </div>
    </Container>
  );
};

const Container = styled.div`
  & .top_img {
    display:flex; 
    justify-content:left;
    align-items:center;
    width: 100%;
    height: 500px;
    background: url(${pc_brand_banner}) no-repeat;
    background-size: cover;

    & p {
      max-width: 1480px;
      margin: 0 auto;
      text-align: left;
      font-size: 3.25rem;
      line-height: 78px;
      font-weight: 300;
      b {
        font-weight: 500;
      }
    }    

    @media only screen and (max-width: 960px) {
      display: block;
      min-width: 100%;
      max-height: 540px;
      padding: 50px 0 8%;
      box-sizing: content-box;
      background: url(${m_brand_banner}) no-repeat center;
      background-size: cover;

      & p {
        width: 100%;
        padding: 0 15px;
        font-size: 2rem;
        line-height: 48px;
      }
    }
  }
  & .brand_wrap {
    width: 100%;

    & .text_wrap {
      max-width: 1480px;
      margin: 0 auto;

      & .tit {
        margin-bottom: 50px;
        font-size: 2.5rem;
        line-height: 52px;
        color: #333;
        font-weight: 500;
      }

      & .txt {
        font-size: 1.25rem;
        line-height: 30px;
        color: #515355;
      }
    }

    &.brand01 {
      margin-top: 80px;
    }
  }
  
  & .brand01 {
    padding: 16% 0 13%;
    background: url(${brand_bg01}) no-repeat;
    background-size: cover;
  }
  & .brand02 {
    padding: 15% 0 17%;
    background: url(${brand_bg02}) no-repeat;
    background-size: cover;

    & .text_wrap {
      margin-left: 50%;
    }
  }

  & .brand03 {
    background: url(${brand_varram}) no-repeat right 40px top 240px;

    & .text_wrap {
      margin-bottom: 140px;
    }

    & .text_top {
      margin:280px 0 160px;
      color: #222;
      & .sub_text {
        margin-bottom: 10px;
        font-size: 1.5rem;
        line-height: 36px;
      }

      & .main_text {
        font-size: 3.5rem;
        line-height: 84px;
        font-weight: 700;
      }
    }

    & .text_bottom {
      display: flex;
      align-items: center;

      & div:first-child {
        flex-basis: 600px;
        & img {
          width: 100%;
        }
      }

      & div:last-child {
        flex-basis: 660px;
        padding-left: 20px;
        margin-left: auto;
        box-sizing: content-box;
      }
    }
  }

  & .brand04 {
    display: block;
    padding: 140px 0;
    background: #F7F8F8;
    & ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1480px;
      margin: 0 auto;

      & li {
        width: calc((100% - 120px) / 2);
        &:not(:nth-child(-n+2)) {
          margin-top: 120px;
        }
        & img {
          margin-bottom: 30px;
          width: 100%;
        }
        & .tit {
          margin-bottom: 15px;
          font-size: 1.875rem;
          line-height: 45px;
          color: #333;
          font-weight: 500;
        }
        & .txt {
          font-size: 1.25rem;
          line-height: 30px;
          color: #515355;
        }
        @media only screen and (max-width: 1480px) {
          width: 100%;
          &:not(:nth-child(-n+2)) {
            margin-top: 60px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    & .brand_wrap {
      width: 100%;
      min-width: 100%;

      & .text_wrap {
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;

        & .tit {
          margin-bottom: 20px;
          font-size: 1.75rem;
          line-height: 42px;
          color: #222;
        }

        & .txt {
          font-size: 1.063rem;
          line-height: 25.5px;
        }
      }
    }
    
    & .brand01 {
      padding: 0 0 93% 0;
      background: url(${m_brand_bg01}) no-repeat center bottom;
      background-size: cover;
    }
    & .brand02 {
      padding: 80px 0 90% 0;
      background: url(${m_brand_bg02}) no-repeat center;
      background-size: cover;

      & .text_wrap {
        padding-left: 15px;
      }
    }

    & .brand03 {
      background: none;

      & .text_wrap {
        margin-bottom: 80px;
        padding: 0;
      }

      & .text_top {
        margin:100px 0 40px;
        padding: 0 15px;
        & .sub_text {
          font-size: 1rem;
          line-height: 24px;
        }

        & .main_text {
          font-size: 2.5rem;
          line-height: 60px;
        }
      }

      & .text_bottom {
        display: block;
        overflow: hidden;
        

        & .img_wrap {
          background: url(${brand_varram}) no-repeat right bottom 10px;
          background-size: 118%;
          padding: 0 0 80px 0;

          & img {
            width: 104% !important;
            margin-left: -2%;
          }
        }

        & div:last-child {
          padding: 0 15px;
        }
      }
    }

    & .brand04 {
      display: block;
      padding: 80px 15px;
      & ul {
        flex-direction: column;
        width: 100%;
        &:not(:first-child) {
          margin-top: 60px;
        }

        & li {
          &:not(:first-child) {
            margin-left: 0;
            margin-top: 60px;
          }
          & img {
            width: 100%;
            margin-bottom: 20px;
          }
          & .tit {
            margin-bottom: 10px;
            font-size: 1.25rem;
            line-height: 30px;
            color: #222;
          }
          & .txt {
            font-size: 0.938rem;
            line-height: 22.5px;
          }
        }
      }
    }
  }
`

export default BrandPage;
