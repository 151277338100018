import React, {useCallback, useEffect, useState} from 'react';
import thunmnail_1 from "images/prd_thumbnail_01.png";
import Styled from "styled-components";
import OrderCancelPopup from "pages/components/OrderCancelPopup";
import {useMediaQuery} from 'react-responsive';
import list_open from "images/m/m_list_open.png";
import list_close from "images/m/m_list_close.png";
import arr_my_nav from "images/m/arr_my_nav.png"
import Button from "pages/components/Button";
import ReqRefundPopup from "pages/components/ReqRefundPopup";
import {fetchOrderDetails, fetchOrderRegularDetails} from "../../../actions/orderActions";
import {useDispatch, useSelector} from "react-redux";
import {stringPrice} from "utils/StringPrice";
import QueryString from "qs";
import {Link} from "react-router-dom";
import ReqExchangePopup from "../../components/ReqExchangePopup";
import {RESET_ORDER_DETAILS} from "../../../actions/types";
import {RiErrorWarningFill} from "react-icons/ri";
import DotGuide from "../../components/DotGuide";
import Moment from "moment";

const OrderProductDetail = ({pathLocation}) => {
    const isMobile = useMediaQuery({query: '(max-width: 960px)'})
    const dispatch = useDispatch();
    const itemitemInfo = useSelector((state) => state.orderDetails.orderDetails);
    const queryData = QueryString.parse(window.location.search, {ignoreQueryPrefix: true});
    const PathData = window.location.pathname.split("/")
    let itemInfo = itemitemInfo;
    const itemitemInfoReg = pathLocation.state
    itemInfo = itemitemInfoReg === undefined ? itemitemInfo : itemitemInfoReg;
    const [shippingAmount, setShippingAmount] = useState(0);
    const [refundAmount, setRefundAmount] = useState(0);
    const [isUpdate, setIsUpdate] = useState(0);
    const [OrderCancel, setOrderCancel] = useState({
        visible: false,
        id     : "",
        info   : "",
        idx    : "",
    });
    const closeOrderCancel = () => {
        setOrderCancel({...OrderCancel, visible: false});
    }
    const [orderList, setOrderList] = useState(true);
    const [deliveryitemInfo, setDeliveryitemInfo] = useState(true);
    const [deliveryState, setDeliveryState] = useState(true);
    const [payMethod, setPayMethod] = useState(true);
    const [payitemInfo, setPayitemInfo] = useState(true);
    const [reqRefund, setReqRefund] = useState(false);
    const [reqExchange, setReqExchange] = useState({open: false, item: "",});
    const [isRefund, setIsRefund] = useState(false);
    const [Mobile, setMobile] = useState(false);

    const resizingHandler = () => {
        if (window.innerWidth > 960) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    };
    useEffect(() => {
            if (queryData.isregular) {
                if (queryData.isregular === "true") {
                    dispatch(fetchOrderRegularDetails(PathData[PathData.length - 1]))
                } else {
                    dispatch(fetchOrderDetails(PathData[PathData.length - 1]))
                }
            } else {
                dispatch(fetchOrderDetails(PathData[PathData.length - 1]))
            }
    }, [dispatch])

    useEffect(() => {
        return () => {
            dispatch({type: RESET_ORDER_DETAILS})
        }
    }, [])

    useEffect(() => {
        if (itemitemInfo.OrderProducts) {
            let maxShippingPrice = 0;
            let maxShippingPriceCondition = 0;
            let tortalRefundAmount = 0
            //setRefundAmount
            for (let i = 0; i < itemitemInfo.product.length; i++) {
                const product = itemitemInfo.product[i];

                maxShippingPrice = Math.max(maxShippingPrice, Math.abs(itemitemInfo.shipping_amount));
                maxShippingPriceCondition = Math.max(maxShippingPriceCondition, product.free_shipping_price);
            }

            if (maxShippingPriceCondition <= itemitemInfo.actual_payment_amount) {
                setShippingAmount(0);
            } else {
                setShippingAmount(maxShippingPrice)
            }
            
            if (itemitemInfo.OrderProducts.length > 0) {
                let treateDateList = []
                let refundList = []
                
                for (let prod of itemitemInfo.OrderProducts) {
                    if(prod.treat_date !== null && prod.refund_amount !== null) {
                        if (treateDateList.length > 0) {
                            let sameTreatIndex = 0
                            let partRefundAmout = 0
                            
                            for (let i=0; i<treateDateList.length; i++) {
                                if (treateDateList[i] === prod.treat_date && prod.refund_amount > 0) {
                                    partRefundAmout = prod.refund_amount;
                                    sameTreatIndex = i
                                    break;
                                }
                            }

                            if (partRefundAmout > 0) {
                                refundList[sameTreatIndex] = refundList[sameTreatIndex] + partRefundAmout
                            } else {
                                treateDateList.push(prod.treat_date)

                                if (prod.change_sort === "plus") {
                                    refundList.push(prod.refund_amount + prod.change_amount)
                                } else if (prod.change_sort === "minus") {
                                    refundList.push(prod.refund_amount - prod.change_amount)
                                } else {
                                    refundList.push(prod.refund_amount)
                                }
                            } 

                        } else {
                            treateDateList.push(prod.treat_date)

                            if (prod.change_sort === "plus") {
                                refundList.push(prod.refund_amount + prod.change_amount)
                            } else if (prod.change_sort === "minus") {
                                refundList.push(prod.refund_amount - prod.change_amount)
                            } else {
                                refundList.push(prod.refund_amount)
                            }
                        }
                    }
                }

                for (let refund of refundList) {
                    tortalRefundAmount += refund
                }
            }
            
            switch (itemitemInfo.front_status) {
                case "취소완료":
                case "해지보류":
                case "해지완료":
                case "반납중":
                case "교환완료":
                case "반품완료":
                case "환불완료":
                case "환불보류":
                case "환불신청":
                    setIsRefund(true)
                    break;
                default:
                    setIsRefund(false)
                    break;
            }
            setRefundAmount(tortalRefundAmount)
        }else{
            switch (itemInfo.front_status) {
                case "취소완료":
                case "해지보류":
                case "해지완료":
                case "반납중":
                case "교환완료":
                case "반품완료":
                case "환불완료":
                case "환불보류":
                case "환불신청":
                    setIsRefund(true)
                    break;
                default:
                    setIsRefund(false)
                    break;
            }
            if (itemitemInfo.refund_amount) {
                setRefundAmount(itemitemInfo.refund_amount)
            } else {
                setRefundAmount(itemitemInfo.refund_amount)
            }

        }
    }, [itemitemInfo])


    useEffect(() => {
        if (!itemitemInfo.product) {
            return
        }
        if (itemitemInfo.product <= 0) {
            return
        }
    }, [itemitemInfo])

    const orderStatus = (status, delay) => {
        switch (status) {
            case "입금전" :
                return "입금전";
            case "환불관리" :
                return "취소";
            case "교환" :
                return "교환신청";
            case "재배송" :
                return "배송중";
            case "배송지연" :
                return <p className='delay_state'>{"배송지연"} {delay ? <span>({delay})</span> : null}</p>
            case "취소완료" :
                return "취소";
            default:
                return status
        }
    }


    const [data, setData] = useState({});
    const openReqRefund = (orderId, item, idx) => {
        setReqRefund(true);
        if (item.products) {
            item.products.items = itemitemInfo.product;
        }
        setData({...data, orderId, item, idx})
    }
    const openReqExchange = (orderId, item, idx) => {
        setReqExchange({open: true, item: item});
    }

    const orderBtn = (status, orderId, item, idx) => {
        const ship_completed = Moment(item.treat_date, "YYYY-MM-DD").format('LLL');
        const ship_completed_after = Moment(item.treat_date, "YYYY-MM-DD").add(10, "days").format('LLL');
        const now = Moment().format('LLL')
        const isOkToChange = Moment(now).isBetween(ship_completed, ship_completed_after)
        let CheckReturn = [];
        if (item.OrderProducts) {
            CheckReturn = item.OrderProducts.filter((e) => e.return_status !== null)
        } else {
            CheckReturn = (item.return_status && item.return_status != '') ? item.return_status : [];
        }

        switch (status) {
            case "입금전" :
                return <>
                    <button onClick={() => setOrderCancel({
                        ...OrderCancel,
                        visible: true,
                        id     : orderId,
                        info   : item,
                        idx    : idx
                    })}>주문내역 취소
                    </button>
                </>;
            // case "결제완료" :
            //     return <>
            //         {queryData.isregular === "true" ? null : <p>주문취소는 '입금확인' , '결제완료' 상태인 경우에만 가능합니다.</p>}
            //         {queryData.isregular !== "true" ? <button onClick={() => setOrderCancel({
            //             ...OrderCancel,
            //             visible: true,
            //             id     : orderId,
            //             info   : item,
            //             idx    : idx
            //         })}>주문내역 취소</button> : null}
            //     </>;
            case "배송지연" :
                return <></>
            case "배송완료" :
                return <>
                    <p></p>
                    {item.RegularProductId === undefined &&
                        <>
                            {isOkToChange ? CheckReturn.length !== 0 ?
                                <button onClick={() => {
                                    alert("교환 상품이 있어, 교환 및 반품 진행이 어렵습니다. 바램펫 고객센터를 통해 접수해주세요.")
                                }}>반품/교환</button>
                                :
                                <button onClick={() => openReqRefund(orderId, item, idx)}>반품/교환</button> : <button onClick={() => {
                                alert("교환 및 환불 가능한 기간이 지났습니다.\n" + "교환, 환불은 배송완료 후 10일 이내에 가능합니다.")
                            }}>반품/교환</button>
                            }
                        </>
                    }
                    {item.RegularProductId !== undefined &&
                        <>
                            {isOkToChange ? item.return_reason !== null ?
                                <button onClick={() => {
                                    alert("교환 상품이 있어, 교환 및 반품 진행이 어렵습니다. 바램펫 고객센터를 통해 접수해주세요.")
                                }}>교환</button>
                                :
                                <button onClick={() => openReqExchange(orderId, item, idx)}>교환</button> : <button onClick={() => {
                                alert("교환 및 환불 가능한 기간이 지났습니다.\n" + "교환, 환불은 배송완료 후 10일 이내에 가능합니다.")
                            }}>교환</button>
                            }
                        </>
                    }
                </>;
            case "배송중" :
                return <></>;
            case "교환완료" :
                return <></>;
            case "반품완료" :
                return <></>;
            case "교환신청" :
                return <>
                    <p></p>
                    {item.RegularProductId === undefined &&
                        <>
                            {CheckReturn.length !== 0 ?
                                <button onClick={() => {
                                    alert("교환 상품이 있어, 교환 및 반품 진행이 어렵습니다. 바램펫 고객센터를 통해 접수해주세요.")
                                }}>반품/교환</button>
                                :
                                <button onClick={() => openReqRefund(orderId, item, idx)}>반품/교환</button>
                            }
                        </>
                    }
                    {item.RegularProductId !== undefined &&
                        <>
                            {item.return_reason !== null ?
                                <button onClick={() => {
                                    alert("교환 상품이 있어, 교환 및 반품 진행이 어렵습니다. 바램펫 고객센터를 통해 접수해주세요.")
                                }}>교환</button>
                                :
                                <button onClick={() => openReqExchange(orderId, item, idx)}>교환</button>
                            }
                        </>
                    }
                </>;
            case "반품신청" :
                return <>
                    <p></p>
                    {item.RegularProductId === undefined &&
                        <>
                            {CheckReturn.length !== 0 ?
                                <button onClick={() => {
                                    alert("교환 상품이 있어, 교환 및 반품 진행이 어렵습니다. 바램펫 고객센터를 통해 접수해주세요.")
                                }}>반품/교환</button>
                                :
                                <button onClick={() => openReqRefund(orderId, item, idx)}>반품/교환</button>
                            }
                        </>
                    }
                    {item.RegularProductId != undefined &&
                        <>
                            {item.return_reason !== null ?
                                <button onClick={() => {
                                    alert("교환 상품이 있어, 교환 및 반품 진행이 어렵습니다. 바램펫 고객센터를 통해 접수해주세요.")
                                }}>교환</button>
                                :
                                <button onClick={() => openReqExchange(orderId, item, idx)}>교환</button>
                            }
                        </>
                    }
                </>;
            default :
                return <></>
        }
    }


    const orderBtnMobile = (status, orderId, item, idx) => {
        let CheckReturn = [];
        if (item.OrderProducts) {
            CheckReturn = item.OrderProducts?.filter((e) => e.return_status !== null)
        } else {
            CheckReturn = (item.return_status && item.return_status !== '') ? item.return_status : [];
        }
        switch (status) {
            case "입금전" :
                return <>
                    <Button onClick={() => setOrderCancel({
                        ...OrderCancel,
                        visible: true,
                        id     : orderId,
                        info   : item,
                        idx    : idx
                    })} fullWidth>주문내역 취소</Button>
                </>;
            // case "결제완료" :
            //     return <>
            //         <p>주문취소는 '입금확인' , '결제완료' 상태인 경우에만 가능합니다.</p>
            //         {queryData.isregular !== "true" ? <Button onClick={() => setOrderCancel({
            //             ...OrderCancel,
            //             visible: true,
            //             id     : orderId,
            //             info   : item,
            //             idx    : idx
            //         })} fullWidth>주문내역 취소</Button> : null}
            //     </>;
            case "배송지연" :
                return <></>
            case "배송완료" :
                return <>
                    <p></p>
                    {item.RegularProductId === undefined &&
                        <>
                            {CheckReturn.length !== 0 ?
                                <Button onClick={() => {
                                    alert("교환 상품이 있어, 교환 및 반품 진행이 어렵습니다. 바램펫 고객센터를 통해 접수해주세요.")
                                }} fullWidth>반품/교환</Button>
                                :
                                <Button onClick={() => openReqRefund(orderId, item, idx)} fullWidth>반품/교환</Button>
                            }
                        </>
                    }
                    {item.RegularProductId !== undefined &&
                        <>
                            {item.return_reason !== null ?
                                <Button onClick={() => {
                                    alert("교환 상품이 있어, 교환 및 반품 진행이 어렵습니다. 바램펫 고객센터를 통해 접수해주세요.")
                                }} fullWidth>교환</Button>
                                :
                                <Button onClick={() => openReqExchange(orderId, item, idx)} fullWidth>교환</Button>
                            }
                        </>
                    }
                </>;
            case "배송중" :
                return <></>;
            case "교환완료" :
                return <></>;
            case "반품완료" :
                return <></>;
            case "교환신청" :
                return <>
                    <p></p>
                    {item.RegularProductId === undefined &&
                        <>
                            {CheckReturn.length !== 0 ?
                                <Button onClick={() => {
                                    alert("교환 상품이 있어, 교환 및 반품 진행이 어렵습니다. 바램펫 고객센터를 통해 접수해주세요.")
                                }} fullWidth>반품/교환</Button>
                                :
                                <Button onClick={() => openReqRefund(orderId, item, idx)} fullWidth>반품/교환</Button>
                            }
                        </>
                    }
                    {item.RegularProductId !== undefined &&
                        <>
                            {item.return_reason !== null ?
                                <Button onClick={() => {
                                    alert("교환 상품이 있어, 교환 및 반품 진행이 어렵습니다. 바램펫 고객센터를 통해 접수해주세요.")
                                }} fullWidth>교환</Button>
                                :
                                <Button onClick={() => openReqExchange(orderId, item, idx)} fullWidth>교환</Button>
                            }
                        </>
                    }
                </>;
            case "반품신청" :
                return <></>;
            default :
                return <></>
        }
    }

    // 팝업, 햄버거메뉴 오픈 시 body스크롤 고정
    useEffect(() => {
        if (window.innerWidth > 960) {
            setMobile(false);
        } else {
            setMobile(true);
        }
        if (Mobile) {
            function handleTouchMove(event) {
                if (reqRefund) {
                    event.preventDefault();
                }
            }

            function disableScroll() {
                if (reqRefund) {
                    document.body.style.overflowY = 'hidden';
                    document.body.style.overflowX = 'visible';
                    document.querySelector('html').scrollTop = window.scrollY; // dimmed 되기 전 스크롤 위치 고정
                }
            }

            //window.addEventListener('touchmove', handleTouchMove, { passive: false })
            window.addEventListener('scroll', disableScroll);
            window.addEventListener("resize", resizingHandler);

            return () => {
                //window.removeEventListener('touchmove', handleTouchMove);
                window.removeEventListener('scroll', disableScroll);
                document.body.style.overflow = 'visible';
                // 메모리 누수를 줄이기 위한 removeEvent
                window.removeEventListener("resize", resizingHandler);
            }
        } else {
            window.addEventListener("resize", resizingHandler);
            return () => {
                // 메모리 누수를 줄이기 위한 removeEvent
                window.removeEventListener("resize", resizingHandler);
            };
        }
    }, [reqRefund, Mobile]);
    const phoneNumber = useCallback((ph) => {
        const regex = /^([0-9]{3})([0-9]{4})([0-9]{4})/gm;
        const subst = `$1-$2-$3`;

        return ph.replace(regex, subst);
    }, [])

    const MemoizedFinallyPrice = React.memo(({iteminfo, refundAmount, isRefund}) => {
        if (!iteminfo.product && !iteminfo.RegularProduct) {
            return <></>
        }
        let isRegular = iteminfo.RegularProduct !== null;

        let finallyString = "결제";
        let finallyPrice = 0;
        let discountShippingPrice = 0;
        let maxShippingPrice = 0;
        if (!isRegular) {
            if (isNaN(iteminfo.shipping_amount_discount)) {
                discountShippingPrice = 0;
            } else {
                discountShippingPrice = iteminfo.shipping_amount_discount;
            }
            for (let i = 0; i < iteminfo.product.length; i++) {
                maxShippingPrice = Math.max(maxShippingPrice, iteminfo.product[i].shipping_price);
            }
        }

        if (isRefund) {
            finallyString = "환불"
            if (itemInfo.total_ordered_times) {
                finallyPrice = itemInfo.refund_amount;
            } else {
                finallyPrice = refundAmount;
            }

            finallyPrice -= (maxShippingPrice - discountShippingPrice);
        } else {
            finallyPrice = itemInfo.actual_payment_amount;
            finallyString = "결제"
        }
        return <>
            <td>최종 {finallyString} 금액
            </td>
            <td>
                <b>{stringPrice(finallyPrice)}</b>원
            </td>
        </>
    })
    const MemoizedShippingPrice = React.memo(({iteminfo, isRefund}) => {
        if (!iteminfo.product) {
            return <></>
        }
        // let refundString = "배송비";
        // let signString = "+";
        // let maxPrice = 0;
        // let maxShippingPrice = 0;
        // let maxCondition = 0;
        // let isFreeShipping = false;
        // let shippingPrice = 0;
        // let discountShippingPrice = 0;
        // for (let i = 0; i < iteminfo.product.length; i++) {
        //     maxPrice = Math.max(maxPrice, iteminfo.product[i].shipping_price);
        //     maxShippingPrice = Math.max(maxShippingPrice, iteminfo.product[i].shipping_price);
        //     maxCondition = iteminfo.product[i].is_free_shipping !== true && Math.max(maxCondition, iteminfo.product[i].free_shipping_price)
        //     if (iteminfo.product[i].is_free_shipping) {
        //         isFreeShipping = true
        //     }
        // }

        // if (isFreeShipping) {
        //     maxPrice = 0;
        // } else {
        //     if (iteminfo.paid_amount >= maxCondition) {
        //         maxPrice = 0;
        //     }
        // }
        // if (isNaN(iteminfo.shipping_amount_discount)) {
        //     discountShippingPrice = 0;
        // } else {
        //     discountShippingPrice = iteminfo.shipping_amount_discount;
        // }

        // if (isRefund) {
        //     refundString = "배송비";
        //     signString = "+";
        //     maxPrice = maxShippingPrice - discountShippingPrice;
        // }

        // return <>
        //     <tr>
        //         <td>{refundString}</td>
        //         <td>
        //             <b>{stringPrice(maxPrice >= 1 ? `${signString}${maxPrice}` : "0")}</b>원
        //         </td>
        //     </tr>
        // </>

        // 도서산간비용이 반영된 배송비로 변경함
        let refundString = "배송비";
        let signString = "+";
        return <>
            <tr>
                <td>{refundString}</td>
                <td>
                    <b>{stringPrice(`${signString}${iteminfo.shipping_amount}`)}</b>원
                </td>
            </tr>
        </>
    })
    const MemoizedNomalOrder = React.memo(({iteminfo}) => {
        if (Object.keys(iteminfo).length <= 0) {
            return null;
        }

        if (iteminfo.OrderProducts === null) {
            return null
        }
        let old_option_idx = -1
        let op = [];
        let productinfo = {};
        let optionGroups = [];
        let totalPrice = 0;
        let item = {};

        return iteminfo.OrderProducts.map((orderitem, idx) => {
            const productinfo = iteminfo.product.filter(v => v.id === orderitem.ProductId);
            if (productinfo.length < 1) {
                return null
            }

            let op = [];
            const optionGroup = iteminfo.option.filter(v => {
                const idx = v.findIndex(vj => {
                    if (vj.id === orderitem.option_id) {
                        return true;
                    } else {
                        const optionId = vj.OptionId ? vj.OptionId : vj.id;
                        return optionId === orderitem.option_id;
                    }
                });
                if (idx === -1) {
                    return false
                }
                if (v[idx].require) {
                    return true;
                } else {
                    return false
                }
            })
            if (optionGroup.length <= 0) {
                return null
            }
            op = optionGroup[0];
            item = productinfo[0];
            let current_option_idx = iteminfo.option.findIndex(v => {
                const idx = v.findIndex(vj => {
                    if (vj.id === orderitem.option_id) {
                        return true;
                    } else {
                        const optionId = vj.OptionId ? vj.OptionId : vj.id;
                        return optionId === orderitem.option_id;
                    }
                });
                if (idx === -1) {
                    return false
                }
                if (v[idx].require) {
                    return true;
                } else {
                    return false
                }
            })
            if (old_option_idx !== current_option_idx) {
                old_option_idx = current_option_idx
            } else {
                return null
            }
            const productidx = iteminfo.product.findIndex(v => v.id === orderitem.ProductId);
            optionGroups = iteminfo.product[productidx].OptionGroups

            let requireQuantity = 0;
            totalPrice = op.reduce((acc, cur, idx) => {
                if (cur.require) {
                    requireQuantity += cur.quantity
                    return acc + ((orderitem.ordered_product_sell_price + cur.price) * cur.quantity)
                } else {
                    return acc + (cur.price * cur.quantity);
                }
            }, 0)
            return (
                <li className="order_list_content" key={idx}>
                    <Link className="order_list_content_in" to={{
                        pathname: `/products/details/normal/${item.id}`,
                    }}>
                        <div className="order_list_content_img">
                            <div className="item_img">
                                <img src={item.main_img} alt={item.name}/>
                            </div>
                            <div className="order_list_content_text">
                                <div className="order_list_content_p">
                                    <p className="order_title">{item.name}</p>
                                    <React.Fragment>
                                        {
                                            op.map((optlist, idx) => {
                                                const optdata = optionGroups.filter(v => {
                                                    return v.Options.findIndex(vj => vj.id === optlist.OptionId || vj.id === optlist.id) !== -1
                                                })
                                                if (optdata.length <= 0) {return}
                                                const isrequire = optlist.require
                                                return (
                                                    <React.Fragment key={idx}>
                                                        {isrequire && <>
                                                            <p key={idx} className="order_option">[{optdata[0].name}]{optlist.name} ({optlist.quantity}개)</p>
                                                        </>
                                                        }
                                                        {!isrequire &&
                                                            <>
                                                                <p key={idx} className="order_option_addition">[{optdata[0].name}]{optlist.name} ({optlist.quantity}개)</p>
                                                            </>
                                                        }

                                                    </React.Fragment>
                                                )
                                            })

                                        }
                                        <p className="order_price">
                                            <b>{stringPrice(totalPrice)}</b>원 / {requireQuantity}개
                                        </p>
                                    </React.Fragment>
                                </div>
                                <div className="order_state">{orderStatus(orderitem.return_status === "재배송" || orderitem.return_status === "교환" ? itemInfo.order_status : (orderitem.return_status ? orderitem.return_status: itemInfo.order_status))}</div>
                            </div>
                        </div>
                    </Link>
                </li>
            )
        })
    })

    if(itemitemInfo === {}){
        return
    }
    return (
        <>
            <Container>
                <div className="header mypage_title">
                    <div className="order_title">
                        {queryData.isregular === "true" ?
                            <h2>나의 정기배송 주문상세 <span>주문번호 : {itemInfo.merchant_uid}</span></h2> :
                            <h2>주문 내역 상세 <span>주문번호 : {itemInfo.merchant_uid}</span></h2> }
                    </div>
                </div>
                {isMobile && <p className="noArea"></p>}
                <div className="delivery_lists">
                    <div className="order_lists delivery_itemInformation">
                        {isMobile &&
                            <div className={orderList ? "header delivery active" : "header delivery"} onClick={() => setOrderList(!orderList)}>
                                <div className="order_title">
                                    <h2>주문번호<span>{itemInfo.merchant_uid}</span></h2>
                                </div>
                            </div>
                        }
                        <ul className={orderList ? "active" : ""}>
                            <li className="order_lists_list">
                                <ul>
                                    {itemInfo.RegularProductId !== undefined ? <React.Fragment>
                                            <li className="order_list_content">
                                                <Link className="order_list_content_in" to={{
                                                    pathname: `/products/details/regular/${itemInfo.RegularProductId}`,
                                                }}>
                                                    <div className="order_list_content_img">
                                                        <div className="item_img">
                                                            <img src={itemInfo.product_image} alt={itemInfo.name}/>
                                                        </div>
                                                        <div className="order_list_content_text">
                                                            <div className="order_list_content_p">
                                                                <p className="order_title">{itemInfo.name}</p>
                                                                <p className="order_price">
                                                                    <b>{stringPrice(itemInfo.order_price_amount)}</b>원 / <b>{itemInfo.product_amount}</b>개
                                                                </p>
                                                            </div>
                                                            <div className="order_state">{orderStatus(itemInfo.front_status)}</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            {itemInfo.device_name &&
                                                <li className="order_list_content">
                                                    <div className="order_list_content_in">
                                                        <div className="order_list_content_img">
                                                            <div className="item_img">
                                                                <img src={itemInfo.devices.main_img} alt={itemInfo.devices.name}/>
                                                            </div>
                                                            <div className="order_list_content_text">
                                                                <div className="order_list_content_p">
                                                                    <p className="order_title">{itemInfo.devices.name}</p>
                                                                    <p className="order_price">
                                                                        <b>{stringPrice(itemInfo.devices.regular_order_price ? itemInfo.devices.regular_order_price : itemInfo.devices.price)}</b>원 / <b>1</b>개
                                                                    </p>
                                                                </div>
                                                                <div className="order_state">{orderStatus(itemInfo.front_status)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            }
                                        </React.Fragment> :
                                        <React.Fragment>
                                            {<MemoizedNomalOrder iteminfo={itemitemInfo}/>}{
                                        }</React.Fragment>}
                                </ul>
                            </li>
                            <div className="order_all_delete">
                                {orderBtn(itemInfo.front_status, itemInfo.id, itemInfo)}
                            </div>
                        </ul>
                    </div>
                </div>
                {isMobile && <p className="noArea"></p>}
                <div className="delivery_itemInformation">
                    <div className={deliveryitemInfo ? "header delivery active" : "header delivery"} onClick={() => setDeliveryitemInfo(!deliveryitemInfo)}>
                        <div className="order_title">
                            <h2>주문자/배송정보</h2>
                        </div>
                    </div>
                    <table className={deliveryitemInfo ? "delivery_itemInfo address_itemInfo active" : "delivery_itemInfo address_itemInfo"}>
                        <tbody>
                        <tr>
                            <td>이름</td>
                            <td>{itemInfo.buyer_name}</td>
                        </tr>
                        <tr>
                            <td>주소</td>
                            <td>{itemInfo.buyer_addr ? itemInfo.buyer_addr.replace("/", "") : null}</td>
                        </tr>
                        <tr>
                            <td>핸드폰</td>
                            <td>{itemInfo.buyer_tel ? phoneNumber(itemInfo.buyer_tel) : null}</td>
                        </tr>
                        <tr>
                            <td>메모</td>
                            <td>{itemInfo.shipping_message}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                {isMobile && <p className="noArea"></p>}

                <div className="delivery_itemInformation">
                    {orderStatus(itemInfo.front_status) === "취소" || orderStatus(itemInfo.front_status) === "반품완료" || orderStatus(itemInfo.front_status) === "교환완료"
                    || orderStatus(itemInfo.front_status) === "해지완료" || orderStatus(itemInfo.front_status) === "반납중"
                        ? null : <React.Fragment>
                            <div className={deliveryState ? "header delivery active" : "header delivery"}
                                 onClick={() => setDeliveryState(!deliveryState)}>
                                <div className="order_title">
                                    <h2>배송정보</h2>
                                </div>
                            </div>
                            <table className={deliveryState ? "delivery_itemInfo active" : "delivery_itemInfo"}>
                                {!queryData.isregular === "true" ?
                                    <tbody>
                                    {orderStatus(itemInfo.front_status) === "배송중" || orderStatus(itemInfo.front_status) === "배송완료" || orderStatus(itemInfo.front_status) === "배송지연"
                                    || orderStatus(itemInfo.front_status) === "교환신청" || orderStatus(itemInfo.front_status) === "반품신청" || orderStatus(itemInfo.front_status) === "반납중"
                                    || orderStatus(itemInfo.front_status) === "교환보류" || orderStatus(itemInfo.front_status) === "반품보류"
                                    || orderStatus(itemInfo.front_status) === "회수중" || orderStatus(itemInfo.front_status) === "해지완료"
                                        ? <tr>
                                            <td className="delivery_item">{itemInfo.name}</td>
                                            <td>
                                                <button>
                                                    <a href={"https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=" + (itemInfo.re_waybill_number?itemInfo.re_waybill_number:itemInfo.waybill_number)} target="_blank">롯데택배</a>
                                                    <img src={arr_my_nav} alt="자세히보기"/></button>
                                            </td>
                                        </tr> : <tr className="no_delivery">
                                            <td colSpan="2" align="center">배송중 단계부터 조회가 가능합니다.</td>
                                        </tr>}
                                    </tbody> : <tbody>
                                    {orderStatus(itemInfo.front_status) === "배송중" || orderStatus(itemInfo.front_status) === "배송완료" || orderStatus(itemInfo.front_status) === "배송지연"
                                    || orderStatus(itemInfo.front_status) === "교환신청" || orderStatus(itemInfo.front_status) === "반품신청"
                                    || orderStatus(itemInfo.front_status) === "교환보류" || orderStatus(itemInfo.front_status) === "반품보류"
                                    || orderStatus(itemInfo.front_status) === "회수중" || orderStatus(itemInfo.front_status) === "해지완료"
                                        ? <tr>
                                            <td className="delivery_item">{itemInfo.name}</td>
                                            <td>
                                                <button><a href={"https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=" + (itemInfo.re_waybill_number?itemInfo.re_waybill_number:itemInfo.waybill_number)} target="_blank">롯데택배</a> <img src={arr_my_nav} alt="자세히보기"/></button>
                                            </td>
                                        </tr> : <tr className="no_delivery">
                                            <td colSpan="2" align="center">배송중 단계부터 조회가 가능합니다.</td>
                                        </tr>}
                                    </tbody>}
                            </table>
                        </React.Fragment>}
                </div>
                {isMobile && <p className="noArea"></p>}
                <div className="delivery_itemInformation">
                    <div className={payMethod ? "header delivery active" : "header delivery"} onClick={() => setPayMethod(!payMethod)}>
                        <div className="order_title">
                            <h2>결제수단</h2>
                        </div>
                    </div>
                    <table className={payMethod ? "delivery_itemInfo active" : "delivery_itemInfo"}>
                        <tbody>
                        <tr>
                            <td>
                                {
                                    queryData.isregular === "true" ?
                                        <>
                                            {itemInfo.pay_method === "card" ? "신용카드" : null}
                                        </> :
                                        <>
                                            {itemInfo.pay_method === "card" ? "신용카드" : null}
                                            {itemInfo.pay_method === "naverpay" ? "네이버페이" : null}
                                            {itemInfo.pay_method === "trans" ? "실시간계좌이체" : null}
                                            {itemInfo.pay_method === "vbank" ? "가상계좌" : null}
                                        </>
                                }
                            </td>
                            <td>
                                {
                                    queryData.isregular === "true" ?
                                        <>
                                            {itemInfo.pay_method === "card" ? <>{itemInfo.card_name}</> : null}
                                            {itemInfo.pay_method === "trans" ? <>{itemInfo.card_name}</> : null}
                                            {itemInfo.pay_method === "vbank" ? <b style={{
                                                display  : "block",
                                                textAlign: "right"
                                            }}>{itemInfo.vbank_name}<br/>{itemInfo.vbank_num}
                                            </b> : null}
                                        </> :
                                        <>
                                            {itemInfo.pay_method === "card" ? <>{itemInfo.card_name}</> : null}
                                            {itemInfo.pay_method === "trans" ? <>{itemInfo.card_name}</> : null}
                                            {itemInfo.pay_method === "vbank" ? <b style={{
                                                display  : "block",
                                                textAlign: "right"
                                            }}>{itemInfo.vbank_name}<br/>{itemInfo.vbank_num}</b> : null}
                                        </>
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                {isMobile && <p className="noArea"></p>}
                <div className="delivery_itemInformation">
                    <div className={payitemInfo ? "header delivery active" : "header delivery"} onClick={() => setPayitemInfo(!payitemInfo)}>
                        <div className="order_title">
                            <h2>결제정보</h2>
                        </div>
                    </div>
                    <table className={payitemInfo ? "delivery_cached_itemInfo active" : "delivery_cached_itemInfo"}>
                        <tbody>
                        <tr>
                            <td>상품금액</td>
                            <td>
                                <b>{stringPrice(queryData.isregular === "true" ?   //정기배송 일 경우
                                    itemInfo.device_name !== null ? //정기배송에 디바이스가 있을 경우
                                        itemInfo.devices.regular_order_price !== null ? //디바이스에 정기배송 가격이 있을경우
                                        itemInfo.order_price_amount + itemInfo.devices.regular_order_price
                                        : itemInfo.order_price_amount + itemInfo.devices.sell_price
                                        : itemInfo.order_price_amount
                                    : itemInfo.order_price_amount)}</b>원
                            </td>
                        </tr>
                        <tr>
                            <td>상품할인금액</td>
                            <td><b>
                                {stringPrice(itemInfo.total_discount_amount !== 0 ? "-" + (itemInfo.total_discount_amount - itemInfo.mileage_spent_amount) : 0)}
                            </b>원
                            </td>
                        </tr>
                        <MemoizedShippingPrice iteminfo={itemitemInfo} isRefund={isRefund}></MemoizedShippingPrice>
                        <tr>
                            <td>쿠폰할인금액</td>
                            <td><b>{itemInfo.coupon_discount_amount ? "-" : null}{stringPrice(itemInfo.coupon_discount_amount || 0)}</b>원</td>
                        </tr>
                        <tr>
                            <td>알갱이사용</td>
                            <td>
                                <b>{itemInfo.mileage_spent_amount ? "-" : null}{stringPrice(itemInfo.mileage_spent_amount || 0)}</b>(원)
                            </td>
                        </tr>
                        {itemInfo.front_status === "해지신청" || itemInfo.front_status === "해지보류" || itemInfo.front_status === "해지완료" || itemInfo.front_status === "반납중" ?
                        <tr>
                            <td>중도해지 반환금</td>
                            <td>
                                <b>{itemInfo.mileage_spent_amount ? itemInfo.mileage_spent_amount : itemitemInfo.mileage_spent_amount !== 0 ? "-" : null} {itemInfo.mileage_spent_amount ? itemInfo.mileage_spent_amount : 0}</b>원
                            </td>
                        </tr> : null}
                        {isRefund && <tr>
                            <td>총 결제 금액</td>
                            <td><b>{stringPrice(itemInfo.paid_amount || 0)}</b>원</td>
                        </tr>}
                        </tbody>
                        <tfoot>
                        <tr className="cached_total">
                            <MemoizedFinallyPrice iteminfo={itemitemInfo} refundAmount={refundAmount} isRefund={isRefund}/>
                        </tr>
                        {itemInfo.cycle === undefined ? itemInfo.front_status === "취소완료" || itemInfo.front_status === "해지완료" || itemInfo.front_status === "반납중"
                        || itemitemInfo.front_status === "교환완료"
                        || itemitemInfo.front_status === "반품완료"
                        || itemitemInfo.front_status === "환불완료"
                            ? <tr className="refund_itemInfo">
                                <td>반환 예정 알갱이</td>
                                <td>
                                    <b>{stringPrice(itemitemInfo.mileage_spent_amount)}</b>원
                                </td>
                            </tr> : null : null}
                        <tr className="refund_itemInfo_desc">
                            {queryData.isregular === "true" ? null : <td colSpan="2">결제 수단에 따라 3~7일 후 취소 금액 확인이 가능합니다.</td>}
                        </tr>
                        {queryData.isregular === "true" ? null : isRefund && <tr className="refund_itemInfo_desc add" >
                            <td>최종 환불 금액은 환불사유에 따라 상이할 수 있습니다.</td>
                        </tr> }
                        </tfoot>
                    </table>
                    {queryData.isregular === "true" ? <>
                        <div className="price_guide">
                            <p className="tit"><RiErrorWarningFill color="#515355" size="21" />&nbsp;&nbsp;유의사항 안내</p>
                            <div className="sub_guide">
                                <DotGuide
                                    size={!isMobile ? "small" : "tiny"}
                                    color="gray"
                                    text="정기배송의 해지는 3회차 배송완료 10일 후 [마이페이지]-[나의정기배송]-[정기배송 상세] 하단에서 가능합니다."
                                />
                                <DotGuide
                                    size={!isMobile ? "small" : "tiny"}
                                    color="gray"
                                    text="스마트 디바이스는 3회 구독 약정 혜택으로, 만약 3회 이용 횟수 충족 전에 중도 해지할 경우 이미 받아보신 혜택에 대한 반환금이 청구됩니다."
                                />
                                <DotGuide
                                    size={!isMobile ? "small" : "tiny"}
                                    color="gray"
                                    text="4회차 부터 언제든 해지 및 변경이 가능합니다."
                                />
                                <DotGuide
                                    size={!isMobile ? "small" : "tiny"}
                                    color="gray"
                                    text="다음 회차에서 공급사의 사정에 따라 사료 가격이 변경될 수 있습니다."
                                />
                                <DotGuide
                                    size={!isMobile ? "small" : "tiny"}
                                    color="gray"
                                    text="정기배송 결제 3일 전 알림톡을 통해 결제 금액을 안내 드립니다."
                                />
                                <DotGuide
                                    size={!isMobile ? "small" : "tiny"}
                                    color="gray"
                                    text="결제일 및 배송 사료 변경은 다음 결제 예정일 1일 전 14시까지 변경이 가능합니다."
                                />
                            </div>
                        </div>
                    </> : null}
                </div>
                {isMobile &&
                    <>
                        <p className="noArea"></p>
                        <div className="bottom_order_all_delete">
                            {!queryData.isregular === "true" ? orderBtnMobile(itemInfo.front_status, itemInfo.id, itemInfo) :
                                orderBtnMobile(itemInfo.front_status, itemInfo.id, itemInfo)}
                        </div>
                    </>
                }
            </Container>
            {OrderCancel.visible &&
                <OrderCancelPopup
                    setOrderCancel={setOrderCancel}
                    OrderCancel={OrderCancel}
                    visible={OrderCancel.visible}
                    onCancel={closeOrderCancel}
                    id={OrderCancel.id}
                    info={OrderCancel.info}
                    idx={OrderCancel.idx}
                />}
            {reqRefund &&
                <ReqRefundPopup visible={reqRefund} setReqRefund={setReqRefund} product={data} onCancel={() => {
                    setIsUpdate(new Date().getTime());
                    if (queryData.isregular) {
                        if (queryData.isregular === "true") {
                            dispatch(fetchOrderRegularDetails(PathData[PathData.length - 1]))
                        } else {
                            dispatch(fetchOrderDetails(PathData[PathData.length - 1]))
                        }
                    } else {
                        dispatch(fetchOrderDetails(PathData[PathData.length - 1]))
                    }
                }}/>}
            {reqExchange.open &&
                <ReqExchangePopup visible={reqExchange.open} setReqExchange={setReqExchange} item={reqExchange.item} onClose={() => {
                    setIsUpdate(new Date().getTime());
                    if (queryData.isregular) {
                        if (queryData.isregular === "true") {
                            dispatch(fetchOrderRegularDetails(PathData[PathData.length - 1]))
                        } else {
                            dispatch(fetchOrderDetails(PathData[PathData.length - 1]))
                        }
                    } else {
                        dispatch(fetchOrderDetails(PathData[PathData.length - 1]))
                    }
                }}/>}
        </>
    );
};

const Container = Styled.div`
    max-width: 1230px;
    width: 100%;
    
    & .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 14px;
        border-bottom: 1px solid #555;
        & .order_title{
            h2{
                font-size: 24px;
                font-weight: 400;
                span {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 400;
                    color: #515355;
                    margin-left: 20px;
                }
            }
            
        }
        & .order_select{
            cursor: pointer;
            font-size: 14px;
            color: #515355;
            padding: 10px 11px 10px;
            border: 1px solid #ddd;
        }

        @media only screen and (max-width: 960px) {
            &.mypage_title {
                justify-content: center;
                padding-bottom: 40px;
                border-bottom: none;
                & .order_title {
                    h2{
                        font-size: 1.375rem;
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    
    & .refund_info_desc {
        margin-top: 10px;
        font-size: 0.875rem;
        line-height: 21px;
        color: #888;
    }
    
    & .price_guide {
        margin-top: 60px;
        padding: 25px;
        background: #f6f6f6;
        & .tit {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            font-size: 14px;
            color: #515355;
            font-weight: 500;
        }
        & .sub_guide {
            padding-left: 30px;
        }
        @media only screen and (max-width: 960px) {
            margin-top: 30px;
            padding: 30px 15px;
            & .tit {
                margin-bottom: 5px;
                font-size: 13px;
                line-height: 19.5px;
            }
            & .sub_guide {
                padding-left: 5px;
                & span {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }

    & .delivery_lists {
        margin-bottom: 100px;
        @media only screen and (max-width: 960px) {
            margin-bottom: 0;
        }
    }
    
    & .order_lists{
        & ul > li.order_lists_list  {
            margin: 30px 0 20px;
            box-sizing: border-box;
            ul {
                border: 1px solid #ccc;
                
                & li.order_list_content{
                    padding: 0 25px;
                    &:not(:first-child) {
                        & .order_list_content_in {
                            border-top: 1px solid #E1E2E3;    
                        }
                    }
                    & .order_list_content_in {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 25px 0;
                        
                        & .order_list_content_img{
                            display: flex;
                            align-items: center;
                            width: 100%;
                            flex-grow: 1;

                            & .item_img {
                                position: relative;
                                overflow: hidden;
                                flex: 80px 0 0;
                                margin-right: 30px;
                                &::after {
                                  content: "";
                                  display: block;
                                  padding-bottom: 100%;
                                }
                                img{
                                  position: absolute;
                                  top: 50%;
                                  left: 50%;
                                  transform: translate(-50%, -50%);
                                  height: 100%;
                                  width: auto;
                                  height: auto;
                                  width: 100%;
                                }
                            }

                            & .order_list_content_text {
                                display: flex;
                                flex-grow: 1;
                                align-items: center;
                            }
                            & .order_list_content_p{
                                font-size: 14px;
                                color: #515355;
                                p:not(:first-child) {
                                    margin-top: 4px;
                                }
                                p.event_product{
                                    font-size: 13px;
                                    color: #1EA0FA;
                                    line-height: 19px;
                                }
                                
                                p.order_title {
                                    font-size: 16px;
                                    color: #222;
                                    line-height: 24px;
                                }

                                p.order_option {
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: #515355;
                                }
                                p.order_option_addition {
                                    color: #888888;
                                    line-height:1.2;
                                    font-size: 13px;
                                }

                                p.order_price {
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: #515355;
                                    & b {
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                        & .order_state{
                            margin-right: 75px;
                            margin-left: auto;
                            color: #222;
                        }
                        
                    }
                }
                 & li.order_list_content:last-child > .order_list_content_in {
                    border-bottom: 0;
                 }
            }
        }
        & .order_all_delete{
            display:flex;
            justify-content: space-between;
            
            p{
                color: #888888;
                font-size: 0.875rem;
                line-height: 21px;
            }
            button{
                color: #fff;
                width: 200px;
                height: 46px;
                background: #1EA0FA;
                border-radius:4px;
                font-weight: 500;
            }
        }

        @media only screen and (max-width: 960px) {
            & > ul {
                display: none;
                &.active {
                    display: block;
                }
            }
            & ul > li.order_lists_list  {
                margin: 0;
                padding-bottom: 10px;
                box-sizing: border-box;
                ul {
                    border: none;
                    
                    & li.order_list_content{
                        padding: 0 20px;
                        &:first-child {
                            & .order_list_content_in {
                                padding-top: 12px;
                            }
                        }
                        &:not(:first-child) {
                            & .order_list_content_in {
                                border-top: 1px solid #F0F0F0;    
                            }
                        }
                        & .order_list_content_in {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 20px 0;
                            
                            & .order_list_content_img{
                                display: flex;
                                align-items: flex-start;
                                width: 100%;
                                flex-grow: 1;
                                
                                & .item_img {
                                    margin-right: 10px;
                                }

                                & .order_list_content_text {
                                    flex-direction: column;
                                    width: calc(100% - 90px);
                                }
                                & .order_list_content_p{
                                    width: 100%;
                                    p {
                                      word-break: break-all;
                                    }
                                    p:not(:first-child) {
                                        margin-top: 4px;
                                    }
                                    p.event_product{
                                        font-size: 13px;
                                        color: #1EA0FA;
                                        line-height: 19px;
                                    }
                                    
                                    p.order_title {
                                        font-size: 1rem;
                                        line-height: 22px;
                                    }

                                    p.order_option {
                                        font-size: 0.813rem;
                                        line-height: 19px;
                                    }

                                    p.order_price {
                                        font-size: 0.813rem;
                                        line-height: 19px;
                                        color: #222;
                                        & b {
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }
                            & .order_state{
                                width: 100%;
                                margin: 4px 0 0 0;
                                font-size: 0.875rem;
                                font-weight: 500;
                                line-height: 21px;
                            }  
                        }
                    }
                    & li.order_list_content:last-child > .order_list_content_in {
                        border-bottom: 0;
                    }
                }
            }
            & .order_all_delete{
                display: none;
            }
        }
    }

    & .delivery_itemInformation{
        margin-top: 80px;
        &.order_lists {
            margin-top: 0;
        }
        & .header.delivery {
            border-bottom: 1px solid #CCCCCC;
            & h2{
                font-size: 1.25rem;
                font-weight: 500;
                line-height: 30px;
                color: #222;
            }
        }   
        & .delivery_itemInfo{
            width: 100%;
            & tr{
                & td {
                    padding: 21px 20px 0 20px;
                    color: #222;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    vertical-align: middle;
                    &:last-child {
                        text-align: right;
                    }
                    & img {
                        width: 6px;
                        margin-left: 5px;
                        margin-top: -2px;
                    }
                }
                &.delivery_item {
                  width: auto;
                }
                &.no_delivery {
                    color: #999;
                    line-height: 24px;
                    & td {
                        width: 100%;
                        padding-top: 40px;
                        text-align: center;
                        color: #999;
                        margin-top: 40px;
                    }
                }
            }

            &.address_itemInfo {
                & tr {
                    & td {
                        text-align: left;
                        &:first-child {
                            width: 210px;
                        }
                    }
                }
            }

            & button {
               white-space: nowrap;
            }
        }
        & .delivery_cached_itemInfo{
            position: relative;
            width: 100%;
            & tr{
                &:first-child {
                    & td {
                        padding-top: 20px;
                    }
                }
                & td {
                    padding: 0 20px 22px 20px;
                    color: #515355;
                    line-height: 24px;
                    vertical-align: middle;
                    & b {
                        font-weight: 500;
                    }
                    &:last-child {
                        text-align: right;
                    }
                }
            }
            & tfoot {
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                background: #F6F6F6;
                & tr {          
                    &:first-child {
                        & td {
                            padding-top: 20px;
                            padding-bottom: 16px;
                        }
                    }
                    &:last-child {
                        & td {
                            padding-bottom: 20px;
                        }
                    }
                    &.cached_total{
                        & td:first-child {
                            color: #222;
                            font-size: 1.125rem;
                            line-height: 27px;
                            font-weight: 700;
                        } 
                        & td:last-child {
                            color: #1EA0FA;
                            font-size: 1rem;
                            line-height: 24px;
                            & b {
                                line-height: 33px;
                                font-size: 1.375rem;
                                font-weight: 700;
                            }
                        }
                    }
                    &.refund_itemInfo {
                        padding-top: 0;
                        & td {
                            padding-bottom: 20px;
                        }
                    }

                    &.refund_itemInfo_desc {
                        position: absolute;
                        left: -20px;
                        margin-top: 10px;
                        & td {
                            font-size: 0.875rem;
                            line-height: 21px;
                            color: #888;
                            text-align: left;
                        }
                    }
                    &.refund_itemInfo_desc.add {
                        margin-top: 30px;
                    }
                }
            }
        }

        @media only screen and (max-width: 960px) {
            margin-top: 0;
            & .header.delivery {
                cursor: pointer;
                padding: 18px 16px;
                border-bottom: none;
                background: url(${list_open}) no-repeat right 16px center;
                background-size: 14px 8px;

                &.active {
                    background: url(${list_close}) no-repeat right 16px center;
                    background-size: 14px 8px;
                }

                & h2{
                    font-size: 1.125rem;
                    font-weight: 500;
                    line-height: 27px;
                    color: #222;
                    & span {
                        margin-left: 10px;
                        font-size: 0.938rem;
                        color: #515355;
                        font-weight: 500;
                    }
                }
            }   
            & .delivery_itemInfo{
                display: none;
                width: 100%;
                margin: 12px 0 30px;
                &.active {
                    display: table;
                }
                
                & tr{
                    & td {
                        line-height: 21px;
                        padding: 0 15px 16px 15px;
                        color: #222;
                        font-size: 0.875rem;
                        font-weight: 400;
                    }
                    &:last-child {
                        & td {
                            padding-bottom: 0;
                        }
                    }

                    &.no_delivery {
                        line-height: 21px;
                        & td {
                            padding-top: 0;
                            width: 100%;
                            font-size: 0.875rem;
                        }
                    }
                }
                &.address_itemInfo {
                    & tr {
                        & td {
                            text-align: left;
                            word-break: break-all;
                            &:first-child {
                                width: 80px;
                            }
                        }
                    }
                }
            }
            & .delivery_cached_itemInfo{
                display: none;
                width: calc(100% - 30px);
                margin: 0 auto;
                
                &.active {
                    display: table;
                }
                & tr{
                    & td {
                        padding: 0 0 16px 0;
                        line-height: 20px;
                        font-size: 0.875rem;
                        & b {
                            font-size: 1rem;
                            line-height: 22px;
                        }
                    }
                    &:first-child {
                        & td {
                            padding-top: 12px;
                        }
                    }
                    &:last-child {
                        & td {
                            padding-bottom: 25px;
                        }
                    }
                }

                & tfoot {
                    border-top: 1px solid #ccc;
                    border-bottom: none;
                    background: transparent;
                    & tr {          
                        &:first-child {
                            & td {
                                padding-top: 24px;
                                padding-bottom: 13px;
                            }
                        }
                        &:last-child {
                            & td {
                                padding-bottom: 30;
                            }
                        }
                        &.cached_total{
                            & td:first-child {
                                font-size: 1rem;
                                line-height: 22px;
                            } 
                            & td:last-child {
                                font-size: 1.125rem;
                                line-height: 25px;
                            }
                        }
                        &.refund_itemInfo td{
                            padding: 0 0 10px 0;
                        }
                        &.refund_itemInfo_desc {
                            position: relative;
                            left: initial;
                            margin-top: 0;
                            & td {
                                font-size: 0.75rem;
                                line-height: 17px;
                                color: #888;
                                letter-spacing: -2%;
                            }
                        }
                        &.refund_itemInfo_desc.add {
                            margin-top: 30px;
                        }
                    }
                }
            }
        }
    }

    & .bottom_order_all_delete {
        padding: 30px 15px 40px;
        & div {
            font-weight: 700;
        }
        & p {
            margin-top: 10px;
            font-size: 0.813rem;
            color: #888;
            line-height: 18px;
        }
    }
`

export default OrderProductDetail;