import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Dialog from './Dialog';
import DotGuide from './DotGuide';
import Button from './Button';
import Checkbox from './Checkbox';
import axios from "axios"
import {useSelector} from "react-redux";

const Step2 = ({ visible, setStep1, setReqCancelDelivery, cancelData }) => {
  const userInfo = useSelector((state) => state.userLogin.userInfo.data || "");
  const isPc = useMediaQuery({ query: '(min-width: 961px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const [checkedList, setCheckedLists] = useState([]);
  const [checkedReason, setCheckedReason] = useState([]);
  const [textReason, setTextReason] = useState('');

  const onCheckedElement = useCallback(
    (checked, id, value) => {
      if (checked) {
        setCheckedLists([id]);
        setCheckedReason([value]);
      } else {
        setCheckedLists(checkedList.filter((el) => el !== id));
        setCheckedReason(checkedReason.filter((el) => el !== value));
      }
    },
    [checkedList]
  );

  const handleCancelDelivery = async () => {
    try{
      setStep1(true);
      setReqCancelDelivery(false);
      axios.defaults.headers.common["cid"] = userInfo.cid;
      axios.defaults.headers.common["encoded"] = userInfo.encoded;
      const res = await axios.post(`${process.env.REACT_APP_API_URL}api/regular/order/terminate`, {
          "id": cancelData.id,
          "sub_id": cancelData.RegularOrders[0].id,
          "return_product": cancelData.RegularOrders[0].name,
          "return_reason" : checkedReason[0],
          "reason_content" : setTextReason
      })
      document.location.href = `/mypage/delivery/detail_list?id=${cancelData.id}`
      console.log(res);
    }catch(error){
      console.log(error)
    }
  }
  const handleInputs = (e) => {
    setTextReason(e.target.value)
  }
  
  return (
    <Step2Container visible={visible}>
      <div className="step_cont custom_scroll">
        <p className="main_txt">정기배송 취소 사유를 알려주세요!</p>
        <p className="sub_txt">고객님의 소중한 의견을 통해{isMobile && <br />}더 나은 바램펫이 {isPc && <br />}될 수 있도록{isMobile && <br />}노력하겠습니다.</p>
        <div className="select_box">
          <Checkbox
            id="chk1"
            label="구매하고 싶은 사료가 없어요."
            value={"구매하고 싶은 사료가 없어요."}
            onChange={(e) => onCheckedElement(e.target.checked, "chk1", "구매하고 싶은 사료가 없어요.")}
            checked={checkedList.includes("chk1") ? true : false}
          />
          <Checkbox
            id="chk2"
            label="자동급식기에 대한 만족도가 떨어져요."
            value={"자동급식기에 대한 만족도가 떨어져요."}
            onChange={(e) => onCheckedElement(e.target.checked, "chk2", "자동급식기에 대한 만족도가 떨어져요.")}
            checked={checkedList.includes("chk2") ? true : false}
          />
          <Checkbox
            id="chk3"
            label="전반적인 서비스 이용이 불편해요."
            value={"전반적인 서비스 이용이 불편해요."}
            onChange={(e) => onCheckedElement(e.target.checked, "chk3", "전반적인 서비스 이용이 불편해요.")}
            checked={checkedList.includes("chk3") ? true : false}
          />
          <Checkbox
            id="chk4"
            label="배송 서비스가 불편해요."
            value={"배송 서비스가 불편해요."}
            onChange={(e) => onCheckedElement(e.target.checked, "chk4", "배송 서비스가 불편해요.")}
            checked={checkedList.includes("chk4") ? true : false}
          />
          <Checkbox
            id="chk5"
            label="기타 사유"
            value={"기타 사유"}
            onChange={(e) => onCheckedElement(e.target.checked, "chk5", "기타 사유")}
            checked={checkedList.includes("chk5") ? true : false}
          />
          <textarea placeholder="소중한 의견을 남겨주세요!" onChange={(e) => handleInputs(e)}></textarea>
        </div>
      </div>
      <div className="button_wrap">
        {checkedReason.length !== 0 && textReason !== "" ?
            <Button type="button" onClick={handleCancelDelivery}>확인</Button> :
            <Button type="button" color="gray">확인</Button> }
      </div>
    </Step2Container>
  )
}

const ReqCancelDeliveryPopup = ({ visible, setReqCancelDelivery, cancelData }) => {
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);

  const isStep2 = () => {
    setStep1(false);
    setStep2(true);
  }

  return (
    <ReqCancelDeliveryContainer visible={visible}>
      <Dialog
        title="정기배송해지"
        dialogSize="medium"
        onCancel={() => setReqCancelDelivery(false)}
        fullHeight
        fixHeight
        visible={visible}
      >
        <Step1 visible={step1}>
          <div className="step_cont custom_scroll">
            <p className="main_txt">해지 전 확인해 주세요!</p>
            <div className="guide_box">
              <p className="main_guide">정기배송 해지 시 아래 혜택이 사라져요!</p>
              <DotGuide text="우리아이 식사 리포트" />
              <DotGuide text="신선한 사료를 위한 프리미엄 습기제거제 제공" />
            </div>
          </div>
          <div className="button_wrap">
            <Button onClick={isStep2} color="darkgray">해지하기</Button>
            <Button onClick={() => setReqCancelDelivery(false)}>유지하기</Button>
          </div>
        </Step1>
        <Step2 visible={step2} setStep1={setStep1} setReqCancelDelivery={setReqCancelDelivery} cancelData={cancelData}/>
      </Dialog>
    </ReqCancelDeliveryContainer>
  )
}

const ReqCancelDeliveryContainer = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};

  @media only screen and (max-width: 960px) {
    & div.dialog_wrap {
      & h3 {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
  
  & .dialog_wrap {
    & .dialog_inner {
      overflow-y: initial !important;
      & .step_cont {
        overflow-y: auto;
        max-height: calc(100vh - 293px);
        padding: 0 30px;
      }

      & .guide_box {
        padding: 30px;
        background: #F7F7F7;
        &:not(:first-child) {
          margin-top: 10px;
        }
        & .main_guide {
          margin-bottom: 10px;
          font-size: 16px;
          color: #0065AB;
          line-height: 24px;
          font-weight: 500;
        }
      }
      @media only screen and (max-width: 960px) {
        overflow-y: auto !important;
        padding: 0;
        & .step_cont {
          overflow-y: hidden;
          max-height: 100%;
          height: 100%;
          padding: 0;
        }

        & .guide_box {
          padding: 25px 20px;
          & .main_guide {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  & .button_wrap {
    position: fixed;
    width: 100%;
    padding: 30px;
    bottom: 0;
    background: #fff;
    @media only screen and (max-width: 960px) {
      position: relative;
      width: 100%;
      padding: 30px 0;
    }
  }
`

const Step1 = styled.div`
  display: ${({ visible }) => visible ? 'block' : 'none'};
  padding: 0 8px 114px 0;
  & .main_txt {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
  }
  & .button_wrap div {
    height: 46px;
    font-weight: 700;
    font-size: 16px;
  }
  @media only screen and (max-width: 960px) {
    padding: 0 15px;
    & .main_txt {
      margin: 50px 0 40px;
    }
    & .button_wrap {
      position: absolute;
      bottom: 30px;
      left: 0;
      padding: 0 15px;
      & div {
        height: 46px;
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
`

const Step2Container = styled.div`
  display: ${({ visible }) => visible ? 'block' : 'none'};
  padding: 0 8px 114px 0;
  & .step_cont {
    & .main_txt {
      margin-bottom: 6px;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
    }
    & .sub_txt {
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
    }
    & .select_box {
      padding: 30px;
      background: #F7F7F7;
      & > div {
        &:not(:first-child) {
          margin-top: 10px;
        }
      }
      & textarea {
        margin-top: 20px;
        padding: 20px;
        height: 150px;
        border:  1px solid #DDDDDD;
        font-size: 14px;
        line-height: 21px;
        &::placeholder {
          font-size: 14px;
          color: #999;
        }
      }
    }
  }
  & .button_wrap {
    & div {
      display: flex;
      width: 100%;
      height: 46px;
      font-weight: 700;
    }
  }
  @media only screen and (max-width: 960px) {
    padding: 50px 15px 30px;
    & .step_cont {
      & .main_txt {
        margin-bottom: 8px;
      }
      & .sub_txt {
        margin-bottom: 40px;
      }
      & .select_box {
        padding: 25px 20px;
        background: #F7F7F7;
        & textarea {
          padding: 15px;
        }
      }
    }
    & .button_wrap {
      & div {
        display: flex;
        width: 100%;
        height: 46px;
        font-weight: 700;
      }
    }
  }
`

export default ReqCancelDeliveryPopup;