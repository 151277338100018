import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import {useSelector} from "react-redux";
import NavContents from "./Nav_menu"
import SearchArea from "pages/components/SearchArea";
import m_logo from "images/m/logo_varram.png";
import search_ico from "images/icon/ico_search.png";
import m_ico_search from "images/m/ico_search.svg";
import cart_ico from "images/icon/ico_cart.png";
import m_ico_cart from "images/m/ico_cart.svg";
import mypage_ico from "images/icon/ico_mypage.png";
import event_item1 from "images/event_item1.png";
import event_item2 from "images/event_item2.png";
import ico_menu from "images/m/ico_menu.png";
import ico_menu_w from "images/m/ico_menu_new.png"
import { useMediaQuery } from 'react-responsive';
import mypage_ico_w from "images/icon/ico_mypage_w.png"
import search_ico_w from "images/icon/ico_search_w.png"
import m_search_ico_w from "images/m/ico_search_w.svg"
import cart_ico_w from "images/icon/ico_cart_w.png"
import m_cart_ico_w from "images/m/ico_cart_w.svg"
import m_logo_w from "images/m/logo_varram_w.png";
import { GrClose } from "react-icons/gr"
import { useLocation } from "react-router-dom";
import logo from "images/logo_varram.png";
import logo_w from "images/logo_varram_w.png";
import $ from 'jquery';

const Nav = ({location}) => {
  const isPc = useMediaQuery({ query: '(min-width: 961px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const { pathname } = useLocation();

  // 스크롤시 헤더 상단 고정
  const [ScrollActive, setScrollActive] = useState(false);

    useEffect(() => {
        let isScrollAble = false;
        function scrollFunc(){
            if (!isScrollAble) {
                isScrollAble = true;
                setTimeout(() => {
                    if (window.pageYOffset >= 10) {
                        setScrollActive(true);
                    } else {
                        setScrollActive(false);
                    }
                    isScrollAble = false;
                }, 100)
            }
        }
        window.addEventListener('scroll', scrollFunc);
    },[]);

  useEffect(() => {
    let logo_img = document.querySelector(".logo_img");
    // let search_img = document.querySelector(".search_img");
    let cart_img = document.querySelector(".cart_img");
    let mypage_img = document.querySelector(".mypage_img");

    if(pathname === "/") {
      if(ScrollActive){
        if(isPc) {
          logo_img.src = logo;
          // search_img.src = search_ico;
          cart_img.src = cart_ico;
          mypage_img.src = mypage_ico
        } else {
          logo_img.src = m_logo;
          // search_img.src = m_ico_search;
          cart_img.src = m_ico_cart;
        }
      } else {
        if(isPc) {
          logo_img.src = logo_w;
          // search_img.src = search_ico_w;
          cart_img.src = cart_ico_w;
          mypage_img.src = mypage_ico_w
        } else {
          logo_img.src = m_logo_w;
          // search_img.src = m_search_ico_w;
          cart_img.src = m_cart_ico_w;
        }
      }
    }


    $(".SearchWrapper").removeClass("scroll");
    setSearchOpen(false);
  },[pathname, isPc, ScrollActive])

  const onMouseEnter = () => {
    if(isPc) {
      const logo_img = document.querySelector(".logo_img");
      // const search_img = document.querySelector(".search_img");
      const cart_img = document.querySelector(".cart_img");
      const mypage_img = document.querySelector(".mypage_img");

      if(!ScrollActive){
        logo_img.src = logo;
        // search_img.src = search_ico;
        cart_img.src = cart_ico;
        mypage_img.src = mypage_ico;
      }
    }
  }

  const onMouseLeave = () => {
    if(isPc && location.pathname === "/") {
      const logo_img = document.querySelector(".logo_img");
      // const search_img = document.querySelector(".search_img");
      const cart_img = document.querySelector(".cart_img");
      const mypage_img = document.querySelector(".mypage_img");

      if(!ScrollActive){
        logo_img.src = logo_w;
        // search_img.src = search_ico_w;
        cart_img.src = cart_ico_w;
        mypage_img.src = mypage_ico_w;
      }
    }
  }

  // 검색창 열기
  const [searchOpen, setSearchOpen] = useState(false);
  const openSearch = () => {
    const logo_img = document.querySelector(".logo_img");

    setSearchOpen(!searchOpen)
    if(isPc) {
      logo_img.src = logo;
    }
  }
  const closeSearch = () => {
    setSearchOpen(false)
  }
  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      closeSearch(e)
    }
  }

  const [Mobile, setMobile] = useState(false);
  const resizingHandler = () => {
      if (window.innerWidth > 960) {
        setMobile(false);
      } else {
        setMobile(true);
      }
  };

  //검색창 오픈 시 body스크롤 고정
  useEffect(() => {
    if (Mobile) {
      function handleTouchMove(event) {
        if (searchOpen) { event.preventDefault(); }
      }
      function disableScroll() {
        if (searchOpen) {
          document.body.style.overflowY = 'hidden';
          document.body.style.overflowX = 'visible';
          document.querySelector('html').scrollTop = window.scrollY; // dimmed 되기 전 스크롤 위치 고정
        }
      }
      window.addEventListener('touchmove', handleTouchMove, { passive: false })
      window.addEventListener('scroll', disableScroll);
      window.addEventListener("resize", resizingHandler);

      return () => {
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('scroll', disableScroll);
        document.body.style.overflow = 'visible';
        // 메모리 누수를 줄이기 위한 removeEvent
        window.removeEventListener("resize", resizingHandler);
      }
    } else {
      window.addEventListener("resize", resizingHandler);
      return () => {
        // 메모리 누수를 줄이기 위한 removeEvent
        window.removeEventListener("resize", resizingHandler);
    };
  }}, [searchOpen, Mobile]);

  return (
      <Navigation className="Navigation" location={location} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {
            location.pathname === "/" ?
                <div className={ScrollActive ? "main_header main_fixed" : "main_header"}>
                    <NavContents location={location} openSearch={openSearch} />
                </div> :
                <div className={ScrollActive ? "header fixed" : "header"}>
                    <NavContents location={location} openSearch={openSearch} />
                </div>
        }
      <>
        <ModalOverlay searchOpen={searchOpen} ScrollActive={ScrollActive} className={ScrollActive ? "ModalOverlay scroll" : "ModalOverlay"} />
        <SearchWrapper
          ScrollActive={ScrollActive}
          searchOpen={searchOpen}
          closable={true}
          onClick={onMaskClick}
          onTouchMove={e => e.stopPropagation()}
          className={ScrollActive ? "SearchWrapper scroll" : "SearchWrapper"}
        >
          <SearchInner>
            <p className="search_close"><span><GrClose size={isMobile ? "22" : "17"} onClick={() => setSearchOpen(!searchOpen)} /></span></p>
            <SearchArea state={location.state} searchOpen={searchOpen} />
            <div className="event_wrap">
              <Link to="/" className="item"><img src={event_item1} alt="" /></Link>
              <Link to="/" className="item"><img src={event_item2} alt="" /></Link>
            </div>
          </SearchInner>
        </SearchWrapper>
      </>
    </Navigation>
  )
};

const Navigation = styled.div`
  width: 100%;
  z-index: ${(props) => (props.location.pathname === "/" ? 9999 : "none")};
  position: ${(props) => (props.location.pathname === "/" ? "fixed" : "relative")};

  & .header {
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
    background: #fff;
    z-index: 9999;
    transition: .3s;

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      @media only screen and (max-width: 960px) {
        z-index: 999;
      }
    }
  }
  & .main_header {
    width: 100%;
    border-bottom: 1px solid ${(props) => (props.location.pathname === "/" ? "transparent" : "#e1e1e1")};
    background-color: ${(props) => (props.location.pathname === "/" ? "transparent" : "#fff")};
    z-index: 9999;
    position: relative;

    &.main_fixed {
      position: fixed;
      background-color: #fff;
      border-bottom: 1px solid #e1e1e1;
      top: 0;
      left: 0;
      width: 100%;

      .menu{
        color: #222;
      }
    }
  }

  @media only screen and (min-width: 960px) {
    &:hover .main_header,
    &:hover .main_fixed{
      background-color: #fff;
      border-bottom: 1px solid #e1e1e1;

      .menu{
        color: #222;
      }
      .ham_menu {
        background-image: url(${ico_menu});
      }
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1480px;
    height: 79px;
    margin: 0 auto;
  }

  h1 {
    width: 138px;
    height: 30px;
    margin-right: auto;
    .logo {
      display: inline-block;
      & img {
        width: 138px;
      }
    }
  }

  li {
    display:inline-block;
  }

  & .util_wrap {
    display: flex;
    margin-left: auto;
    & > a, & > button, & > span {
      margin-left: 20px;
      cursor: pointer;
      & img {
        width: 30px;
      }
    }

    .h_cart {
      position: relative;

      span {
        display: flex;
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0px;
        right: -4px;
        border-radius: 50%;
        background: #1EA0FA;
        color: #fff;
        font-size: 0.5rem;
        text-indent: initial;
        justify-content: center;
        align-items: center;
        text-indent: 0;
      }
    }
  }


  @media only screen and (max-width: 960px) {
    .header {
      min-width: 100%;
    }
    .container {
      position: relative;
      width: 100%;
      height: 60px;
      padding: 0 10px;
    }
    h1 {
      margin: 0 auto;
      width: 93px;
      height: 20px;
      .logo {
        margin-right: 0;
        & img {
          width: 93px;
          vertical-align: top;
        }
      }
    }
    & .util_wrap {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      & > a, & > button, & > span {
        width: 28px;
        height: 28px;
        margin-left: 12px;
        cursor: pointer;
        & img {
          width: 28px;
        }
      }

      .h_cart {
        position: relative;
      }
    }
    .ham_menu {
      display: inline-block;
      content: "";
      position: absolute;
      top: 16px;
      left: 15px;
      width: 28px;
      height: 28px;
      background-image: ${(props) => (props.location.pathname === "/" ? `url(${ico_menu_w})` : `url(${ico_menu})`)};
      background-size: cover;
      background-repeat: no-repeat;
      text-indent: -9999px;
      cursor: pointer;
    }
    .main_header{
      min-width: 100%;
      .ham_menu {
        background-image: url(${ico_menu_w});
      }


    }
    & .main_fixed{
      .ham_menu {
        background-image: url(${ico_menu});
      }
    }
  }
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.searchOpen ? 'block' : 'none')};
  top: 120px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;

  &.scroll {
    top: 80px;
  }

  @media only screen and (max-width: 960px) {
    top: 0;
  }
`

const SearchWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.searchOpen ? 'block' : 'none')};
  top: 120px;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  text-align: right;

  &.scroll {
    top: 80px;
  }

  @media only screen and (max-width: 960px) {
    top: 0;
    z-index: 9999;
  }
`;

const SearchInner = styled.div`
  position: fixed;
  width: 100%;
  padding: 40px 0;
  background: #fff;

  & .event_wrap {
    display: flex;
    width: 800px;
    margin: 40px auto 0;

    & .item {
      display: inline-block;
      width: calc((100% - 20px) / 2);
      &:not(:first-child) {
        margin-left: 20px;
      }

      & img {
        width: 100%;
      }
    }
  }

  & .search_close {
    position: absolute;
    width: 100%;
    bottom: -60px;
    left: 0;
    text-align: center;

    & span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #fff;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 960px) {
    top: 0;
    left: 0;
    min-height: 100vh;
    padding: 0;
    background: #fff;

    & .event_wrap {
      display: block;
      width: 100%;
      padding: 0 15px;
      margin: 60px auto 0;

      & .item {
        display: block;
        width: 100%;
        margin: 0 0 12px 0 !important;
      }
    }

    & .search_close {
      position: relative;
      top: 0;
      padding: 18px;
      text-align: right;
      border-bottom: 1px solid #F2F4F6;

      & span {
        width: auto;
        height: auto;
        border-radius: initial;
      }
    }
  }
`;

export default withRouter(Nav);
