import React, {useState, useEffect} from 'react';
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";
import default_thumb from "images/default_thumb.png";

const ItemReviewChat = ({reviews, setReview,setReviewItem }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const [showReply, setShowReply ] = useState([]);
    const handleImgError = (e) => {
        e.target.src = default_thumb;
    }

    useEffect(() => {
        setShowReply([])
    },[])

    const getThumbnail = (url, type) =>{
        if (url === null) { return }
        if(type === 'video'){
            let thumbnail = url.replace('/review', '/review-thumbnail')
            return thumbnail.substring(0, thumbnail.lastIndexOf(".")) + ".jpg";
        }else{
            return url.replace('/review', '/review-thumbnail');
        }
    }
    const hide_string = (email) => {
        if(email === null){return}
        if(String(email).includes("@")){
            id = email
        }
        let id = String(email).split("@")[0]
        let answer = ""
        for(let i = 0; i < id.length; i++){
            if(i < id.length/2){
                answer = answer + id[i]
            }else{
                answer = answer + "*";
            }
        }
        return answer
    }

    const viewReply = async (id) => {
        if(showReply.indexOf(id) > -1){
            const idx = showReply.findIndex(v => v === id);
            setShowReply(prevState => [...prevState.filter(v => v !== id)]);
        } else {
            setShowReply(prevState => [...prevState, id]);
        }
    };

    return (
        <>
            <ul>
                {reviews.map((el) => {
                    if(el.is_dummy){
                        return (
                            <li key={el.id} className="reviewChat_li">
                                <div className="li_tit"><StyledRate size="22" disabled  value={el.point} /><span>{el.email ? hide_string(el.email) : hide_string(el.d_username)}</span></div>
                                <div className="review_wrap" onClick={() => {setReviewItem(el); setReview(true);}}>
                                    <div>
                                        <span className="reviewChat_options">{el.name}</span>
                                        <div className="reviewDesc">
                                            <p>{el.content}</p>
                                        </div>
                                        {el.admin_reply && el.admin_reply !== "" ? <React.Fragment>
                                            <span className="reply_btn" onClick={() => viewReply(el.id)}>댓글 1</span>
                                            <div className={showReply.indexOf(el.id) > -1  ?  "reply" : "reply hide"}>
                                                <p>고객만족팀</p>
                                                <p>{el.admin_reply} </p>
                                            </div>
                                        </React.Fragment> : null}

                                    </div>
                                    {
                                        !isMobile ?
                                            <React.Fragment>
                                                {
                                                    el.d_images !== null && el.d_images.length >= 1 ? <div className="review_img">
                                                        <img src={el.d_images[0]} onError={handleImgError} alt="리뷰이미지"/>
                                                        <span className="img_cnt">{el.d_images.length}</span>
                                                    </div> : null
                                                }
                                            </React.Fragment>:
                                            <React.Fragment>
                                                <div className="review_wrap_2">
                                                    {
                                                        el.d_images !== null && el.d_images.map((v, idx) => {
                                                            if (idx > 3) {
                                                                return null
                                                            }
                                                            return (
                                                                <div className="review_img">
                                                                    <img src={v} onError={handleImgError} alt="리뷰이미지"/>
                                                                    {
                                                                        idx === 3 ?
                                                                            <span className="img_cnt">{el.d_images.length}</span> :
                                                                            null
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </React.Fragment>
                                    }
                                </div>
                            </li>
                        )
                    }
                    if (!el.RegularProductId) {
                        if (!el.Order) { return null }
                        if (!el.Order.option) { return null }
                        const idx = el.Order.option.findIndex(v => v.findIndex(vj => vj.id === el.cart_option_id) !== -1);
                        if (idx === -1) return null

                        const reviewOption = el.Order.option[idx];
                        const productidx = el.Order.product.findIndex(v => v.id === el.ProductId);
                        if (productidx === -1) { return null }
                        const optionGroups = el.Order.product[productidx].OptionGroups

                        return (
                            <li key={el.id} className="reviewChat_li">
                                <div className="li_tit">
                                    <StyledRate size="22" disabled value={el.point}/><span>{hide_string(el.email)}</span>
                                </div>
                                <div className="review_wrap">
                                    <div>
                                        <div onClick={() => {
                                            setReviewItem(el);
                                            setReview(true);
                                        }}>
                                            {
                                                reviewOption.map(v => {
                                                    const optdata = optionGroups.filter(vj => {
                                                        return vj.Options.findIndex(vk=> vk.id === v.OptionId) !== -1
                                                    })
                                                    if (optdata.length > 0) {
                                                        return <span className="reviewChat_options">[{optdata[0].name}] {v.name}  / {v.quantity} 개</span>
                                                    } else {
                                                        return <span className="reviewChat_options">[옵션] {v.name}  / {v.quantity} 개</span>
                                                    }
                                                })
                                            }
                                            <div className="reviewDesc">
                                                <p>{el.content}</p>
                                            </div>
                                        </div>
                                        {el.admin_reply && el.admin_reply !== "" ? <React.Fragment>
                                            <span className="reply_btn" onClick={() => viewReply(el.id)}>댓글 1</span>
                                            <div className={showReply.indexOf(el.id) > -1  ?  "reply" : "reply hide"}>
                                                <p>고객만족팀</p>
                                                <p>{el.admin_reply} </p>
                                            </div>
                                        </React.Fragment> : null}

                                    </div>
                                    {
                                        !isMobile ?
                                            <React.Fragment>
                                                {
                                                    el.Files.length >= 1 ?
                                                        el.Files [0].mimetype.indexOf("image") > -1 &&
                                                        <div className="review_img" onClick={() => {setReviewItem(el); setReview(true);}}>
                                                            <img src={getThumbnail(el.Files [0].publicPath,'image')} onError={handleImgError} alt="리뷰이미지2"/>
                                                            {
                                                                el.Files.length > 1 ?
                                                                    <span className="img_cnt">{el.Files.length}</span>:
                                                                    null
                                                            }
                                                        </div> : null
                                                }
                                            </React.Fragment>:
                                            <React.Fragment>
                                                <div className="review_wrap_2">
                                                    {
                                                        el.Files.map((v, idx) => {
                                                            if (idx > 3) {
                                                                return null
                                                            }
                                                            return (
                                                                <div className="review_img" onClick={() => {setReviewItem(el); setReview(true);}}>
                                                                    <img src={getThumbnail(v.publicPath, 'image')} alt="리뷰이미지2" onError={handleImgError}/>
                                                                    {
                                                                        idx === 3 ?
                                                                            (
                                                                                el.Files.length - 4 > 0 ?
                                                                                    <span className="img_cnt">+{el.Files.length - 4}</span> :
                                                                                    null
                                                                            ) :
                                                                            null
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </React.Fragment>
                                    }
                                    {el.Files.length > 0 && el.Files[0].mimetype.indexOf("video") > -1 &&
                                        <div className="review_img">
                                        <img src={getThumbnail(el.Files[0].publicPath,'video')} onError={handleImgError} alt="리뷰이미지"/>
                                        </div>
                                    }
                                </div>
                            </li>
                        )
                    } else {
                        return (
                            <li key={el.id} className="reviewChat_li">
                                <div className="li_tit"><StyledRate size="22" disabled  value={el.point} /><span>{hide_string(el.email)}</span></div>
                                <div className="review_wrap" onClick={() => {setReviewItem(el); setReview(true);}}>
                                    <div>
                                        {
                                            el.Order ?
                                                el.Order.option ?
                                                    el.Order.option.map((v) => {
                                                        return v.map(vj => {
                                                            return (<span className="reviewChat_options">[옵션] {vj.name}  / {vj.quantity} 개</span>)
                                                        })
                                                    }):
                                                    null :
                                                null
                                        }
                                        <div className="reviewDesc">
                                            <p>{el.content}</p>
                                        </div>
                                        {el.admin_reply && el.admin_reply !== "" ? <React.Fragment>
                                            <span className="reply_btn" onClick={() => viewReply(el.id)}>댓글 1</span>
                                            <div className={showReply.indexOf(el.id) > -1  ?  "reply" : "reply hide"}>
                                                <p>고객만족팀</p>
                                                <p>{el.admin_reply} </p>
                                            </div>
                                        </React.Fragment> : null}

                                    </div>
                                    {
                                        !isMobile ?
                                            <React.Fragment>
                                                {
                                                    el.Files.length >= 1 ?
                                                        el.Files [0].mimetype.indexOf("image") > -1 &&
                                                        <div className="review_img">
                                                            <img src={getThumbnail(el.Files[0].publicPath,'image')} onError={handleImgError} alt="리뷰이미지2"/>
                                                            <span className="img_cnt">{el.Files.length}</span>
                                                        </div> : null
                                                }
                                            </React.Fragment>:
                                            <React.Fragment>
                                                <div className="review_wrap_2">
                                                    {
                                                        el.Files.map((v, idx) => {
                                                            if (idx > 3) {
                                                                return null
                                                            }
                                                            return (
                                                                <div className="review_img">
                                                                    <img src={getThumbnail(v.publicPath, 'image')} onError={handleImgError} alt="리뷰이미지2"/>
                                                                    {
                                                                        idx === 3 ?
                                                                            <span className="img_cnt">{el.Files.length}</span> :
                                                                            null
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </React.Fragment>
                                    }
                                    {el.Files.length > 0 && el.Files[0].mimetype.indexOf("video") > -1 &&
                                        <div className="review_img">
                                        <img src={getThumbnail(el.Files[0].publicPath, 'video')} onError={handleImgError} alt="리뷰이미지2"/>
                                        </div>
                                    }
                                </div>
                            </li>
                        )
                    }
                })}
            </ul>
        </>
    );
};

const StyledRate = styled(Rate)`
    &.rc-rate {
        font-size: ${({ size }) => size}px;

        & .rc-rate-star{
            margin: 20px 0px 12px 0;
        }

        & .rc-rate-star-half .rc-rate-star-first, .rc-rate-star-full .rc-rate-star-second {
            color: #1EA0FA;
        }
     }
`

export default ItemReviewChat;