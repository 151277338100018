import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {comma, stringPrice} from "../../utils/StringPrice";
import styled from "styled-components";
import {Link} from "react-router-dom";
import Select from "react-select";
import {GrClose} from "react-icons/gr";
import arrow_blue from "images/arrow_blue.png";
import Button from "./Button";
import {useSelector, useDispatch} from "react-redux";
import {removeOptions, setAddOption, setQuantity, clearOptions} from "../../actions/optionActions";
import {addToCart, fetchCartLists} from "../../actions/cartActions";
import Dialog from "./Dialog";
import icon_close from "images/icon_close.png";
import {useMediaQuery} from "react-responsive";
import {addToOrder} from "../../actions/orderActions";
import axios from "axios";
import pointImg from "images/icon/ico_point.png";

const ProductOption = ({itemInfo, priceInfo}) => {
    const isPc = useMediaQuery({ query: '(min-width: 961px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const [optionLayer, setOptionLayer] = useState(false);
    const cartState = useSelector((state => state.cartItems.cartItems));
    const userInfo = useSelector((state) => state.userLogin.userInfo?.data || "");
    const cartlists = useSelector((state) => state.cartRefresh);
    const optionState = useSelector(((state) => state.optionReducer.product_option));
    const dispatch = useDispatch();
    const [checkPoint, setCheckPoint] = useState([]);
    const [selectedOption , setSelectedOption] = useState(false);
    const mainCateOrder = Number(localStorage.getItem("mainCateOrder"));

    const optionGroups = itemInfo?.OptionGroups?.reduce((acc, cur) => {
        const options = cur.Options.map((el) => ({ ...el, require: cur.require, _id: el.id, value: el.id, label: el.name,}));
        const groupedMap = options.reduce(
            (entryMap, e) => entryMap.set(e.OptionGroupId, [...entryMap.get(e.OptionGroupId) || [], e]),
            new Map()
        );
        const groupedOpt = groupedMap.get(cur.id);
        const optionInfo = {
            id: cur.id,
            name: cur.name,
            require: cur.require,
            options: groupedOpt,
        }
        acc.push({...optionInfo});
        return acc;
    }, []);
    const reliseOptions = optionGroups && optionGroups.reduce((acc, cur) => {
        acc.push(...cur.options)
        return acc
    }, []);
    const [nomaltems, setNomaltems] = useState({
        price:0,
        discount:0,
        shipprice: 0,
        totalprice:0,
    });

    const renderOptions = optionGroups && reliseOptions.filter((el) => optionState.map((el) => el.id).indexOf(el._id) > -1);
    const optionsTotal = [...optionState];
    const optionReq = optionGroups && renderOptions.filter(({require}) => require);
    const [ScrollY, setScrollY] = useState(0);
    const [BtnStatus, setBtnStatus] = useState(false);
    const [purchaseFix, setPurchaseFix] = useState(true);
    const [checkCartsPro, setCheckCartsPro] = useState([])
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'blue',
            padding: 20,
        }),
        control: () => ({
            width: 200,
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    }
    const [optionStyle, setOptionStyle] = useState({
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            return { ...provided, opacity };
        }
    });

    const handleFollow = () => {
        if(!document.getElementById("ProductDetail")){return}
        if (!document.getElementById("footer")) return
        setScrollY(window.pageYOffset);
        if (ScrollY > 500) {
            setBtnStatus(true);
        } else {
            setBtnStatus(false);
            if (isPc) {
              setOptionLayer(false);
            }
        }

        if ( document.getElementById("footer") || document.getElementById("ProductDetail")) {
            let footerHeight = document.getElementById("footer").offsetHeight;
            let mainHeight = document.getElementById("ProductDetail").offsetHeight;
            let height = mainHeight - footerHeight - 550;
            if (ScrollY > height) {
                setPurchaseFix(false);
            } else {
                setPurchaseFix(true);
            }
        }
    };
    useEffect(() => {
        dispatch(clearOptions())
    }, [])

    const handleSelect = (option, name, price, require) => {
        dispatch(setAddOption(option, name, price,require));
        setSelectedOption(true)
    };
    const handleQuantityMinus = (quantity, id, name, price,require, e) => {
        e.preventDefault();
        quantity !== 1 && dispatch(setQuantity(id, quantity-1, name, price,require));
    };
    const handleQuantityPlus = (quantity, id, name, price,require, e) => {
        e.preventDefault();
        dispatch(setQuantity(id, quantity + 1, name, price,require));
    };
    const handleDelete = (option) => {
        dispatch(removeOptions(option));
    };

    const getTotalPrice = () => {
        const option_id_arr = new Set(optionsTotal.map((el) => el.id));
        const noDupArr = [...option_id_arr];
        const total = {
            price: 0,
            quantity: 0,
        };
        for (let i = 0; i < noDupArr.length; i++) {
            if(reliseOptions){
                const selectedOption = reliseOptions.filter((el) => el.id === optionsTotal[i].id);
                let quantity = optionsTotal[i].quantity

                if (selectedOption.length >= 1) {
                    let price = reliseOptions.filter((el) => el.id === optionsTotal[i].id)[0]?.price;
                    if (selectedOption[0].require) {
                        total.price += quantity * (itemInfo.sell_price + price);
                    } else {
                        total.price += quantity *  price;
                    }
                }
                total.quantity = total.quantity + quantity;
            }
        }
        return total;
    };
    const total = getTotalPrice();

    // 할갱이 적용률에 따른 할인 가격 계산
    const priceProduct = Number(total.price>0 ? total.price : priceInfo.sellPrice)
    const pointDiscoundAmount = priceProduct*itemInfo.point_usage_range/100
    const tempDiscountPrice = pointDiscoundAmount > checkPoint ? (priceProduct - checkPoint) : (priceProduct - pointDiscoundAmount)
    const calculatedDiscoundPrice =  Math.round(tempDiscountPrice/100)*100

    const orderProduct = {...itemInfo, selectedOption: optionState, total: total.price };
    const handleClickPayment = () => {
        if(optionReq?.length === 0){
            setDialog({...dialog, open: true, btnHide: true, cancelText: "확인", dialogContent: "필수 옵션값을 선택해주세요"});
        }else if(userInfo){
            if(itemInfo.is_soldout === true || itemInfo.sell === false){
                return setDialog({...dialog, open: true, btnHide: true, confirmText: "확인", cancelText: "확인", dialogContent: "상품이 품절되었습니다!"});
            }

            let dialogText1 = ``;
            let dialogText2 = ``;
            let optionsRequireCount = 0;
            let optionsCount = 0;
            let optionValue = 0;
            let price = {
                price: 0,
                discount: 0,
                shipprice: 0,
                totalprice: 0,
            }
            let item = {
                SelectedOptions: orderProduct.selectedOption,
                ProductId: orderProduct.id,
                Product: itemInfo
            }
            let calculatedOptipons = {}; 

            for (let i = 0; i < orderProduct.selectedOption.length; i++) {
                const opt = orderProduct.selectedOption[i];
                const optionId = opt.id;
                const selectedOption = orderProduct.OptionGroups[0].Options.filter((item) => {
                    return optionId === item.id
                })
                let optionSku = selectedOption[0].sku;
                let countInPackage = 1;

                if (optionSku.slice(-3, optionSku.length).search(/_[1-9]P/) > -1) {
                    countInPackage = Number(optionSku.slice(-2, optionSku.length-1));
                }
                optionsCount += (countInPackage * opt.quantity);

                let sku = orderProduct.sku;
                if (calculatedOptipons[sku]) {
                    calculatedOptipons[sku].quantity += optionsCount;
                } else {
                    calculatedOptipons[sku] = {
                        "name": orderProduct.name, 
                        "stock": orderProduct.stock,
                        "quantity": optionsCount,
                        "max_quantity": orderProduct.max_quantity};
                }

                if (opt.require) {
                    price.totalprice += (itemInfo.sell_price + opt.price) * opt.quantity
                    optionsRequireCount += opt.quantity
                } else {
                    price.totalprice += opt.price * opt.quantity
                }
               
                optionValue += opt.price * opt.quantity
            }

            for (const key in calculatedOptipons) {
                const productName = calculatedOptipons[key].name;
                const productStock = calculatedOptipons[key].stock;
                const productQuantity = calculatedOptipons[key].quantity;
                const productMaxQuantity = calculatedOptipons[key].max_quantity;

                if (productStock - productQuantity < 0) {
                    dialogText1 = `${productName}\n상품 재고 수량이 부족합니다.\n(재고 수량: ${productStock})`;
                }

                if (productMaxQuantity > 0 && productMaxQuantity < productQuantity) {
                    dialogText2 = `${productName} 상품은\n최대 구매수량이 ${productMaxQuantity}개로 제한된 상품입니다.`;
                }
            }

            if (dialogText1.length > 0) {
                 return setDialog({...dialog, open: true, btnHide: true, cancelText: "확인", dialogContent: dialogText1});
            }

            if (dialogText2.length > 0) {
                 return setDialog({...dialog, open: true, btnHide: true, cancelText: "확인", dialogContent: dialogText2});
            }

            price.discount += (itemInfo.price - itemInfo.sell_price) * optionsRequireCount
            price.price += itemInfo.price * optionsRequireCount;
            price.price += optionValue;
            let maxPrice = 0;
            maxPrice = itemInfo.is_free_shipping !== true && Math.max(maxPrice, itemInfo.free_shipping_price);
            if (price.totalprice >= maxPrice) {
                price.shipprice += 0
            } else {
                price.shipprice += itemInfo.shipping_price;
                price.totalprice += itemInfo.shipping_price
            }
            dispatch(addToOrder([item], price, {}));
        }else{
           document.location.href = "/login"
        }
    }
    useEffect(() => {
        setCheckCartsPro(cartlists.cartNomalItems)
    }, [cartlists])

    const handleClickOrder = (item) => {
        if(reliseOptions.length > 0 && optionReq?.length === 0){
            setDialog({...dialog, open: true, btnHide: true, cancelText: "확인", dialogContent: "필수 옵션값을 선택해주세요"});
        }else if(checkCartsPro.length !== 0 && checkCartsPro.map((e) => e.ProductId).includes(item.id)){
            let str = '장바구니에 동일한 상품이 담겨있습니다.\n 장바구니에 추가하시겠습니까?';
            setDialog({...dialog, open: true, confirmText: "확인", cancelText: "취소", btnHide: false,  dialogContent: str });
        }else if(cartState.map((el) => el.ProductId).includes(item.id)) {
            let str = '장바구니에 동일한 상품이 담겨있습니다.\n 장바구니에 추가하시겠습니까?';
            setDialog({...dialog, open: true, confirmText: "확인", cancelText: "취소",btnHide: false, dialogContent: str});
        }else if(item.is_soldout === true || item.sell === false){
            setDialog({...dialog, open: true, btnHide: true, confirmText: "확인", cancelText: "확인", dialogContent: "상품이 품절되었습니다!"});
        }else{
            dispatch(addToCart(item));
            setDialog({...dialog, open: true, confirmText: "장바구니로 이동",btnHide: false, cancelText: "계속 쇼핑하기", dialogContent: "장바구니에 잘 담겼어요!"});
        }
    };

    const [ dialog, setDialog ] = useState({
        open: false,
        confirmText: "",
        cancelText: "",
        dialogContent: "",
        btnHide: false
    });

    const onConfirm = async (dialogText) => {
        if(dialogText === "장바구니로 이동"){
            window.location.href="/shoppingcart";
        }else{
            try{
                dispatch(addToCart(orderProduct));
                setDialog({...dialog, open: true, confirmText: "장바구니로 이동", cancelText: "계속 쇼핑하기", dialogContent: "장바구니에 잘 담겼어요!"});
            }catch (error) {
                console.log(error);
            }
        }
    }

    const onCancel = async (dialogText) => {
       if(dialogText === "계속 쇼핑하기"){
          window.location.href= `/products/list/product${mainCateOrder}/${itemInfo.SubCategoryId}`;
        } else {
           setDialog({...dialog, open: false});
        }
    }
    
    const onClose = () => {
       setDialog({...dialog, open: false});
    }

    useEffect(() => {
        const watch = () => {
            window.addEventListener("scroll", handleFollow);
        };
        watch();
        return () => {
            window.removeEventListener("scroll", handleFollow);
        };
    });

    useEffect(async () => {
        if (!userInfo) {return}
        try{
            axios.defaults.headers.common["cid"] = userInfo.cid;
            axios.defaults.headers.common["encoded"] = userInfo.encoded;
            const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/order/cart`);
            setCheckCartsPro(data.carts)
        }catch(error){
            console.log(error);
        }
    },[])

    useEffect(()=>{
      const fetchPoints = async () => {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            "cid" : userInfo.cid,
            "encoded" : userInfo.encoded,
          },
        }
        try{
          const res = await axios.post(`${process.env.REACT_APP_API_URL}api/user/point/balance`,{}, config);
          setCheckPoint(res.data.balance.data.balance_point)          
        } catch(error){
          console.log(error)
        }
      }
      fetchPoints()
    },[setCheckPoint])

    return (
        <OptionDetail>
            <Dialog
                confirmText={`${dialog.confirmText}`}
                cancelText={`${dialog.cancelText}`}
                onConfirm = {() => {onConfirm(dialog.confirmText)}}
                onCancel={() => {onCancel(dialog.cancelText)}}
                onClose={onClose}
                dialogSize="small"
                visible={dialog.open}
                btnHide={dialog.btnHide}
                className="cartLayer">
                <p style={{whiteSpace: "pre-wrap"}}>{dialog.dialogContent}</p>
            </Dialog>
            <PurchaseBtn className={BtnStatus ? "fix_purchase_btn active" : "fix_purchase_btn"} purchaseFix={purchaseFix}>
                {orderProduct.sell && !orderProduct.is_soldout ? <Button size="large" className="purchase_btn" fullWidth onClick={() => setOptionLayer(!optionLayer)}>구매하기</Button>
                    : <Button size="large" color="gray" className="purchase_btn" fullWidth >일시품절</Button>
                }
            </PurchaseBtn>
            {isMobile &&
            <LayerWrap className={optionLayer ? "layer_wrap active" : "layer_wrap"} onClick={() => setOptionLayer(!optionLayer)}></LayerWrap>
            }
            <OptionLayer className={optionLayer ? "option_layer active" : "option_layer"} purchaseFix={purchaseFix}>
                <p className="layer_tit">{itemInfo.name}<img src={icon_close} alt="옵션팝업닫기" onClick={() => setOptionLayer(!optionLayer)} /></p>
                <div className="select_options">
                    {optionGroups ? optionGroups.map((el) =>{
                        let options = [];
                        for (let i = 0; i < el.options.length; i++) {
                            if (!el.options[i].display) {continue}
                            if (!el.options[i].sell) {continue}
                            let operator = ""
                            if (el.options[i].price >= 0) {
                                operator = "+"
                            }
                            if (el.options[i].require) {
                                el.options[i].label += ` (${operator}${el.options[i].price}원)`
                                el.options[i].name += ` (${operator}${el.options[i].price}원)`
                            } else {
                                el.options[i].label += ` (${operator}${el.options[i].price}원)`
                                el.options[i].name += ` (${operator}${el.options[i].price}원)`
                            }
                            options.push(el.options[i]);
                        }
                        return  <span key={el.id}>
                            <label htmlFor={el.name}>{el.name}</label>
                            <Select
                                placeholder={el.name}
                                onChange={(option) => {handleSelect(option.id, option.name, option.price,option.require)}}
                                options={options}
                                blurInputOnSelect={false}
                                className="selectBox"
                                classNamePrefix="select"
                                isSearchable={false}
                            />
                        </span>
                    }) : <p style={{justifyContent:"center", color: "#222"}}>
                        <span>옵션을 등록안했습니다.</span>
                    </p> }
                    <div className="selected_option">
                        <div className="option_wrap">
                            {optionState.length !== 0 && optionGroups && renderOptions.map((el, idx) => {
                                const option = optionState.filter(value => value.id === el._id)[0];
                                const quantity = option.quantity;
                                const isrequire = option.require;
                                return <div className="option_box" key={idx}>
                                    <p>{el.label}</p>
                                    <div className="option_bottom">
                                        <div className="option_count">
                                            <button onClick = {(e) => handleQuantityMinus(quantity, el._id, el.label, el.price,el.require, e)}>-</button>
                                            <label>{quantity}</label>
                                            <button onClick = {(e) => handleQuantityPlus(quantity, el._id, el.label, el.price,el.require, e)}>+</button>
                                        </div>
                                        <span>{
                                            isrequire ?
                                                stringPrice((itemInfo.sell_price + el.price) * quantity) :
                                                stringPrice(el.price * quantity)
                                        } 원</span>
                                    </div>
                                    <GrClose alt="삭제" size={14} color={"#515355"} className="option_del" onClick={() => handleDelete(el._id)} />
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                {selectedOption ? <div className="total_price">
                    <span>총 상품 금액</span>
                    <span className="price"><b>{
                        reliseOptions?.length > 0 ?
                            total.price ?
                                optionState.length > 0 ?
                                    stringPrice(total.price) :
                                    0 :
                                0 :
                            stringPrice(0)
                    }</b>원</span>
                </div> : null}
                {
                  optionState.length > 0 ?
                      itemInfo.use_point ? 
                        userInfo ?
                              <div className="m_point_price">
                                <span className="m_point_image" ><img src={pointImg} alt="알갱이" /></span>
                                <span className="m_point_text" >
                                  <div>내 보유 알갱이 사용시</div>
                                  <div className="m_point_text_p">최대 {itemInfo.point_usage_range}% 까지 가능</div>
                                </span>
                                <div >
                                  <span className="m_apply_point_price" >
                                    {stringPrice(calculatedDiscoundPrice)}
                                    <span className="m_won">원</span>
                                    </span>
                                </div>
                              </div>  
                        : null
                      : null
                    : null
                }
                <div className="option_btn">
                    <Button color="white" outline onClick={() => {handleClickOrder(orderProduct)}}>장바구니</Button>
                    <Button onClick={handleClickPayment}>구매하기</Button>
                </div>
            </OptionLayer>

            <h2>{itemInfo.name}</h2>
            <div className="sub_tit" dangerouslySetInnerHTML={ {__html: itemInfo.simple_description.replace(/(<? *script)/gi, 'illegalscript')}}></div>
            <form>
                <div className="price_option">
                    <span className="discount_span">혜택가</span>
                    <span className="price">{stringPrice(priceInfo.sellPrice)}<span className="won">원</span></span>
                    {
                        itemInfo.discount_percent >= 1 ?
                            <React.Fragment>
                                <span className="discount">{itemInfo.discount_percent === null ? "" : `${itemInfo.discount_percent}% `}</span>
                                <span className="before_discount">{stringPrice(priceInfo.beforePrice) === 0 ? null : `${stringPrice(priceInfo.beforePrice)}원 ` }</span>
                            </React.Fragment> :
                            null
                    }
                </div>
                {
                  itemInfo.use_point ? 
                    userInfo ?
                          <div className="point_price">
                            <span className="point_image" ><img src={pointImg} alt="알갱이" /></span>
                            <span className="point_text" >
                              <div>내 보유 알갱이 사용시</div>
                              <div className="point_text_p">최대 {itemInfo.point_usage_range}% 까지 가능</div>
                            </span>
                            
                            <div >
                              <span className="apply_point_price" >
                                {stringPrice(calculatedDiscoundPrice)}
                                <span className="won">원</span>
                                </span>
                            </div>
                          </div>  
                        :
                        <div className="option_advertise">
                            <Link to={"/Join"}><p>신규 회원 가입 하고 추가 할인 혜택 받기</p></Link>
                        </div> 
                        :
                     userInfo ? null : 
                        <div className="option_advertise">
                            <Link to={"/Join"}><p>신규 회원 가입 하고 추가 할인 혜택 받기</p></Link>
                        </div> 
                }
                <div className="option_announcement">
                    <table>
                        <tbody>
                        <tr>
                            <td>배송비 안내</td>
                            <td>
                                {
                                    !itemInfo.is_free_shipping ?
                                    <p>
                                        <span style={{fontWeight:"700", color:"#515355"}}>기본 {stringPrice(priceInfo.shippingPrice)}원, </span>
                                        {comma(itemInfo.free_shipping_price)} 이상 구매 시 무료
                                    </p> : "무료배송"
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>배송 안내</td>
                            <td>
                               <p>
                                   {
                                       itemInfo.is_free_shipping ?
                                       itemInfo.shipping_info !== "" ? itemInfo.shipping_info :
                                           itemInfo.ShippingInfo.shipping_info : itemInfo.ShippingInfo.shipping_info
                                   }
                               </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                 <div className="select_options_wrap">
                    <div className="select_options">
                        {optionGroups ? optionGroups.map((el) =>{
                            let options = [];
                            for (let i = 0; i < el.options.length; i++) {
                                let option = el.options[i];
                                // if (!option.display) {
                                //     option.label += " (품절)";
                                //     option.isDisabled = true;
                                // }
                                if (!option.sell) {
                                    option.label += " (품절)";
                                    option.isDisabled = true;
                                }

                                if (option.display) {
                                  options.push(option);
                                }
                            }

                            let placeholder = "";
                            if (el.require) {
                                placeholder = "필수 옵션 선택하기"
                            } else {
                                placeholder = "옵션 추가하고 할인받기 (선택)"
                            }

                            return  <span key={el.id}>
                                <label>{el.name}</label>
                                <Select
                                    placeholder={placeholder}
                                    onChange={(option) => {
                                        if (option.sell) {
                                            handleSelect(option.id, option.name, option.price, option.require)
                                        } else {
                                            console.log(option);
                                        }
                                    }}
                                    options={options}
                                    blurInputOnSelect={false}
                                    className="selectBox"
                                    classNamePrefix="select"
                                    isSearchable={false}
                                    styles={optionStyle}
                                />
                            </span>
                        }) : <p style={{justifyContent:"center", color: "#222"}}>
                            <span>옵션을 등록안했습니다.</span>
                        </p> }
                    </div>
                    <div className="selected_option">
                        <div className="option_wrap">
                            {optionState.length !== 0 && optionGroups && renderOptions.map((el, idx) => {
                                const option = optionState.filter(value => value.id === el._id)[0];
                                const quantity = option.quantity;
                                const isrequire = option.require;
                                return <div className="option_box" key={idx}>
                                    <p>{el.label}</p>
                                    <div className="option_bottom">
                                        <div className="option_count">
                                            <button onClick = {(e) => handleQuantityMinus(quantity, el._id, el.label, el.price,el.require, e)}>-</button>
                                            <label>{quantity}</label>
                                            <button onClick = {(e) => handleQuantityPlus(quantity, el._id, el.label, el.price,el.require, e)}>+</button>
                                        </div>
                                        <span>{
                                            isrequire ?
                                                stringPrice((itemInfo.sell_price + el.price) * quantity) :
                                                stringPrice(el.price * quantity)
                                        } 원</span>
                                    </div>
                                    <GrClose alt="삭제" size={14} color={"#515355"} className="option_del" onClick={() => handleDelete(el._id)} />
                                </div>
                            })}
                            {reliseOptions?.length === 0 &&
                                <div className="option_box">
                                    <p>{itemInfo.name}</p>
                                    <div className="option_bottom">
                                        <div className="option_count">
                                            <button onClick = {(e) => handleQuantityMinus()}>-</button>
                                            <label>1</label>
                                            <button onClick = {(e) => handleQuantityPlus()}>+</button>
                                        </div>
                                        <span>{stringPrice(itemInfo.sell_price)}</span>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
               
                <div className="total_price">
                    {selectedOption ? <>
                        <span>총 상품 금액</span>
                        <span className="price"><b>{
                            reliseOptions?.length > 0 ?
                                total.price ?
                                    optionState.length > 0 ?
                                        stringPrice(total.price  ) :
                                        0 :
                                    0 :
                                stringPrice(0)
                        }</b>원</span>
                    </> : null}
                </div>
                {orderProduct.sell && !orderProduct.is_soldout ?
                    <div className="option_btn">
                        <Button color="white" outline onClick={() => {handleClickOrder(orderProduct)}}>장바구니</Button>
                        <Button onClick={handleClickPayment}>구매하기</Button>
                    </div> : <div className="option_btn"><Button fullWidth Large color="gray">일시품절</Button></div>}
            </form>
            {/* 독립몰 secret hidden 처리 */}
            {/* <div className="talk_coupon">
                <a href="https://www.varram.co.kr/notice/?q=YToxOntzOjEyOiJrZXl3b3JkX3R5cGUiO3M6MzoiYWxsIjt9&bmode=view&idx=4344989&t=board"  rel="noopener noreferrer" target="_blank"><p>톡 채널 추가하고 <span>3,000원 쿠폰 받기</span></p></a>
            </div> */}
        </OptionDetail>
    );
};
const OptionDetail = styled.div`
  @media only screen and (max-width: 960px) {
    form {
      padding: 0 15px;
    }
  }

  & .price_option {
    display:flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 20px 0;
    @media only screen and (max-width: 960px) {
      border-top: none;
      padding: 0;
      margin: 30px 0;
    }

    & .discount_span{
      line-height: 22.5px;
      flex: 2;

      @media only screen and (max-width: 960px) {
        font-size: 0.875rem;
        line-height: 21px;
        color: #222;
        order: 1;
      }
    }

    & .price {
      display: inline-block;
      margin-right: 8px;
      font-size: 1.625rem;
      font-weight: 700;

      @media only screen and (max-width: 960px) {
        margin-right: 0;
        margin-left: 8px;
        font-size: 1.5rem;
        line-height: 36px;
        order: 3;
      }

      .won {
        font-size: 1.25rem;
        font-weight: 500;

        @media only screen and (max-width: 960px) {
          font-size: 0.875rem;
        }
      }
    }

    & .discount {
      display: inline-block;
      font-size: 1.625rem;
      font-weight: 500;
      color: #1EA0FA;
      line-height: 39px;

      @media only screen and (max-width: 960px) {
        font-size: 24px;
        order: 2;
      }
    }

    & .before_discount {
      display: inline-block;
      margin-left: 8px;
      text-decoration: line-through;
      font-size: 1rem;
      font-weight: 400;
      color: #999;
      line-height: 24px;

      @media only screen and (max-width: 960px) {
        position: absolute;
        right: 0;
        bottom: -21px;
        font-size: 0.875rem;
      }
    }
  }

& .point_price{
    display:flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    font-size: 15px;
    font-weight: 480;
    width: 100%;
    padding: 8px 0;
    background: #fdf8df;
    border-radius: 2px;
    margin-bottom: 20px;

    & .point_image{
      margin-left: 10px;
      margin-right: 10px;
    }

    & .point_text{
      color: #222;
      font-size: 13px;
      font-weight: 480;
      line-height: 20px;
      flex-grow: 1;
    }

    & .point_text_p{
      font-size: 11px;
    }

    & .apply_point_price {
      display: inline-block;
      margin-top: 3px;
      margin-bottom: 3px;
      margin-right: 8px;
      font-size: 1.425rem;
      font-weight: 500;
      algin: right;
      color: #DF8549;

      @media only screen and (max-width: 960px) {
        margin-right: 0;
        margin-left: 8px;
        font-size: 1.5rem;
        line-height: 36px
      }

      .won {
        font-size: 1.0rem;
        font-weight: 400;
        color: #222;

        @media only screen and (max-width: 960px) {
          font-size: 0.875rem;
        }
      }
    }
  }

  & .option_advertise{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0;
    background: #f6f6f6;
    border-radius: 2px;
    cursor: pointer;
    margin-bottom: 20px;

    @media only screen and (max-width: 960px) {
      padding: 10px 15px;
      margin-bottom: 15px;
    }

    & p {
      width: 100%;
      background: url(${arrow_blue}) no-repeat right 15px center;
      color: #0065AB;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;

      @media only screen and (max-width: 960px) {
        background: url(${arrow_blue}) no-repeat right center;
        text-align: left;
        font-size: 0.813rem;
        line-height: 19.5px;
        font-weight: 700;
      }
    }
    & a {
      width: 100%;
    }
  }
  & .option_announcement {
    width: 100%;
    color: #515355;
    font-size: 0.813rem;

    @media only screen and (max-width: 960px) {
      border-top: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
      padding: 15px;
    }

    & table {
      width: 100%;
      & tr {
        & td:first-child {
          width: 90px;
          line-height: 19.5px;
        }
        & td:last-child {
            padding-left: 10px;
            p {
                line-height: 19.5px;
                &:not(:first-child) {
                    margin-top: 3px;
                }
            }
        }
      }
    }
    & tr:first-child td {
      padding-bottom: 8px;
    }
  }
  & .select_options_wrap {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #ddd;
    @media only screen and (max-width: 960px) {
      display: none;
    }
  }
  & .select_options {
    & .selectBox {
      margin-left: 20px;
      width: calc(100% - 133px);
      height: 43px;
    }
    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: #222;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
    & label {
      flex: 113px 0 0;
      line-height: 21px;
    }
    @media only screen and (max-width: 960px) {
      & .selectBox {
        margin-left: 0;
      }
      & label {
        flex: auto;
      }
    }
  }
  & .option_wrap {
    @media only screen and (max-width: 960px) {
      display: none;
    }

    & .option_box {
      position: relative;
      padding: 15px;
      background: #f6f6f6;

      &:first-child {
        margin-top: 20px;
      }

      &:not(:first-child) {
        margin-top: 10px;
      }
      & p {
        display: inline-block;
        max-width: 440px;
        padding-right: 22px;
        color: #222;
        font-size: 0.938rem;
        line-height: 22.5px;
      }

      & .option_bottom {
        display: flex;
        align-items: center;
        margin-top: 12px;
        & .option_count {
          display: flex;
          & button {
            height: 26px;
            width: 26px;
            border: 1px solid #ccc;
            background: #fff;
            font-size :20px;
            line-height: 21px;
          }
          & label {
            display: inline-block;
            width: 48px;
            height: 26px;
            margin-bottom: 0;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            background: #fff;
            font-size: 0.875rem;
            line-height: 24px;
            color: #222;
            text-align: center;
          }
        }
        & span {
          margin-left: auto;
          font-size: 0.938rem;
          line-height: 22.5px;
          color: #515355;
        }
      }
      & .option_del {
        position: absolute;
        top: 18px;
        right: 18px;
        width: 17px;
        cursor: pointer;
      }
    }
    @media only screen and (max-width: 960px) {
      & .option_box {
        &:not(:first-child) {
          margin-top: 8px;
        }

        & > div {
          display: flex;
          align-items: center;
        }
        & p {
          display: block;
          max-width: 100%;
          margin-right: 5px;
          font-size: 0.813rem;
          line-height: 19.5px;
        }

        & .option_bottom {
          & .option_count {
            & button {
              height: 30px;
              width: 30px;
              font-size: 1.2rem;
            }
            & label {
              width: 36px;
              height: 30px;
              font-size: 0.813rem;
              line-height: 30px;
            }
          }
          & span {
            margin-left: auto;
            padding: 0 5px;
            font-size: 0.938rem;
            line-height: 22.5px;
            color: #515355;
          }
        }
        & .option_del {
          position: absolute;
          top: 18px;
          right: 18px;
          width: 17px;
          cursor: pointer;
        }
      }
    }
  }
  & .total_price {
    display:flex;
    position: relative;
    //justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    padding: 20px 0 0 0;
    border-top: 1px solid #ddd;
    font-size: 1.25rem;
    font-weight: 500;
    & span:first-child {
      font-size: 0.938rem;
      color: #222222;
      line-height: 22px;
    }
    & .price {
      margin-left: auto;
      line-height: 45px;
    }
    @media only screen and (max-width: 960px) {
      display: none;
    }
    b {
      font-size: 30px;
      font-weight: 500;
    }
  }

  & .option_btn {
    padding: 20px 0;
    display: flex;
    @media only screen and (max-width: 960px) {
      display: none;
    }

    & .option_submit {
      display: flex;
      justify-content: space-between;
    }

    & button {
      width: 245px;
      height: 54px;
      border: 1px solid #1EA0FA;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fff;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }

    & button:first-child {
      background-color: #fff;
      color: #1EA0FA;
    }
    & button:last-child {
      background-color: #1EA0FA;
      color: #fff;
    }
  }
  & .talk_coupon {
    & p {
      background: #FFE13F;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      font-size: 1rem;
      cursor: pointer;
      color: #222;
      & span {
        margin-left: 5px;
        font-weight: 700;
        color: #222;
      }
      @media only screen and (max-width: 960px) {
        height: 51px;
        margin-top: 15px;
        font-size: 0.875rem;
      }
    }
  }
  & .cartLayer {
    .dialog_wrap {
      overflow-y: initial;
      width: auto;
      & p {
        text-align: center;
      }
    }
  }
`;
const OptionLayer = styled.div`
  display: none;
  position: ${({ purchaseFix }) => (purchaseFix ? "fixed" : "absolute")};
  bottom: 0;
  right: calc((100% - 1480px) / 2);
  width: 480px;
  padding: 30px 17px 20px 30px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px 4px 0px 0px;
  z-index: 9998;
  &.active {
    display: block;
  }

  @media only screen and (max-width: 960px) {
    display: none;
    left: 0;
    right: 0;
    width: 100%;
    padding: 25px 5px 30px 15px;
    border: none;
    border-radius: 0;
  }

  .layer_tit {
    position: relative;
    margin-bottom: 16px;
    padding-right: 30px;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 23.4px;
    color: #222;

    img {
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    @media only screen and (max-width: 960px) {
      display: none;
    }
  }

  & .select_options {
    & label {
      flex: auto;
    }
    & .selectBox {
      margin-left: 0;
      & .select__menu-list {

      }
    }
    @media only screen and (max-width: 960px) {
      display: block;
      margin: 0;
    }

    span {
      flex-direction: column;
      padding-right: 13px;
      text-align: left;
      @media only screen and (max-width: 960px) {
        padding-right: 10px;
      }
      &:not(:first-child) {
        margin-top: 14px;
      }
    }

    label {
      display: block;
      width: 100%;
      margin-bottom: 6px;
      line-height: 21px;
    }

    & .selectBox {
      width: 100%;
      height: auto;
      span {
        display: none;
      }
      & .select__control {
        height: 43px;
      }
      & .select__placeholder {
        font-size: 14px;
      }
      & .select__single-value {
        font-size: 14px;
      }
      & .select__menu {
        position: relative !important;
        top: -1px;
        & .select__menu-list {
          overflow-y: auto;
          max-height: 129px;
          &::-webkit-scrollbar {
            position: absolute;
            right: 0;
            top: 0;
            width: 4px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #888B9D;
            border-radius: 15px;
          }
          &::-webkit-scrollbar-track {
            background-color: #DDDDDD;
            border-radius: 15px;
          }
        }
      }
    }
  }

  & .selected_option {
    max-height: 185px;
    width: 100%;
    overflow-y: auto;
    margin-top: 20px;

    &::-webkit-scrollbar {
      width: 3px;
      border-radius: 15px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #515355;
    }
    &::-webkit-scrollbar-track {
      background-color: #dddddd;
    }
  }


  .option_wrap {
    & .option_box {
      width: 420px;
      &:first-child {
        margin-top: 0;
      }
    }
    @media only screen and (max-width: 960px) {
      display: block;
      & .option_box {
        width: calc(100% - 10px);
      }
    }
  }

  .option_wrap .option_box p {
    max-width: 375px;
    margin: 0 0 10px 0;
    padding-right: 29px;
  }

  & .total_price {
    width: calc(100% - 13px);
    padding-top: 16px;
    margin: 16px 0;
    @media only screen and (max-width: 960px) {
      display: flex;
      align-items: center;
      margin: 20px 0;
      & span:first-child {
        font-size: 0.875rem;
      }
      & .price {
        margin-left: auto;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 36px;
        b {
          font-size: 1.5rem;
          line-height: 36px;
          font-weight: 500;
        }
      }
    }
  }

  & .soldOut_btn {
    padding: 0 13px 0 0;

    @media only screen and (max-width: 960px) {
      display: flex;
      padding: 10px 11px 0 0;
    }

    div {
      height: 50px;
      width: 100%;
      font-size: 1rem;
    }
  }

  & .m_point_price{
    display:flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    font-size: 15px;
    font-weight: 480;
    width: 100%;
    padding: 8px 0;
    background: #fdf8df;
    border-radius: 2px;
    margin-bottom: 20px;

    & .m_point_image{
      margin-left: 10px;
      margin-right: 10px;
    }

    & .m_point_text{
      color: #222;
      font-size: 13px;
      font-weight: 480;
      line-height: 20px;
      flex-grow: 1;
    }

    & .m_point_text_p{
      font-size: 11px;
    }

    & .m_apply_point_price {
      display: inline-block;
      margin-top: 3px;
      margin-bottom: 3px;
      margin-right: 8px;
      font-size: 1.425rem;
      font-weight: 500;
      algin: right;
      color: #DF8549;

      @media only screen and (max-width: 960px) {
        margin-right: 0;
        margin-left: 8px;
        font-size: 1.5rem;
        line-height: 36px
      }

      .m_won {
        font-size: 1.0rem;
        font-weight: 400;
        color: #222;

        @media only screen and (max-width: 960px) {
          font-size: 0.875rem;
        }
      }
    }
  }

\` ;


  & .option_btn {
    padding: 0 13px 0 0;

    @media only screen and (max-width: 960px) {
      display: flex;
      padding: 10px 11px 0 0;
    }

    div {
      height: 50px;
      width: calc((100% - 5px) / 2);
      font-size: 1rem;
    }
  }
`;
const PurchaseBtn = styled.div`
  display: none;
  position: ${({ purchaseFix }) => (purchaseFix ? "fixed" : "absolute")};
  max-width: 1480px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: right;
  z-index: 99;
  &.active {
    display: block;
  }
  & .purchase_btn {
    width: 480px;
    border-radius: 4px 4px 0px 0px;
  }
  @media only screen and (max-width: 960px) {
    display: block;
    width: 100%;
    & .purchase_btn {
      width: 100%;
      left: 0;
      height: auto;
      padding: 20px 0 calc(constant(safe-area-inset-bottom) + 20px);
      padding: 20px 0 calc(env(safe-area-inset-bottom) + 20px);
      border-radius: 0;
      line-height: 23px;
    }
  }
`;
const LayerWrap = styled.div`
  z-index: 1;
  background: none;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  &.active {
    display: block;
  }
`;


export default ProductOption;