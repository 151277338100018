import {GET_MAIN_CATEGORY_ID, SET_CATEGORY, SET_CATEGORY_FILTER} from "actions/types";

const initialState = {
  loading: true,
  categoryProducts: [],
  categoryFilters: [],
  mainCateId: "",
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORY:
      return Object.assign({}, state, {
        loading: false, categoryProducts: action.payload.categoryProducts,
      });
    case SET_CATEGORY_FILTER:
      return Object.assign({}, state, {
        categoryFilters: action.payload.categoryFilters,
      });
    case GET_MAIN_CATEGORY_ID:
      return Object.assign({}, state, {
        mainCateId: action.payload.id
      })
    default:
      return state;
  }
};

export default categoryReducer;
