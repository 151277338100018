import {TabPanel} from "react-headless-tabs";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import React from "react";

const TabContents = (props) => {
    const setCurrentPage = props.setCurrentPage;
    const currentPage = props.currentPage;
    const loading = props.loading;
    const totalPage = props.totalPage;
    const listsMap = props.listsMap;

    return (
        <div className="TabContents">
            <TabPanel>
                <div className="notice_wrap">
                    <ul>
                        {!loading ?
                            listsMap ?
                            ( listsMap.length !== 0 ?
                                listsMap : (<p className="noList">등록된 게시물이 없습니다.</p>)) : <Loader /> : <Loader />}
                    </ul>
                </div>
                <ul className="reviewChat_cover_ul">
                    <Pagination
                        postsPerPage={10}
                        totalPosts={
                            totalPage
                        }
                        paginate={setCurrentPage}
                        currentpage={currentPage}
                    ></Pagination>

                </ul>
            </TabPanel>
        </div>
    )
}


export default TabContents;