import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import icon_dog_pictogram from 'images/icon_dog_pictogram.png'
import { stringPrice } from 'utils/StringPrice'

export default function Item ({ item }) {
    const [stockOut, setStockOut] = useState(false)
    const sellPrice = stringPrice(Number(item.sell_price))
    const price = stringPrice(item.price)
    const review = stringPrice(Number(item.review_count))
    const regular = item.is_regular_order === undefined
    const device = item.is_device

    return (
        <ItemWrapper>
            <Link
                to={{
                    pathname: `/products/details/${device ? 'device' : regular
                        ? 'regular'
                        : 'normal'}/${item.id}`,
                    state: { item },
                }}
            >
                <Items key={item.id}>
                    <div className="status_box">
                        {item.Icon ? item.Icon.map((tag, idx) => (
                                <span className="status" key={idx}>
                    <img src={tag.url} alt="태그"/>
                  </span>
                            ))
                            : null}
                    </div>
                    <div className="item_img">
                        {item.main_img ? (
                            <img src={item.main_img} alt="제품썸네일"/>
                        ) : (
                            <img src={icon_dog_pictogram} alt="제품대체이미지"
                                 style={{ width: '150px', height: '150px' }}/>
                        )}
                    </div>
                    {regular ? <React.Fragment>{item.brand !== null
                        ?
                        <p
                            className="itemTitle">{`[${item.brand}]`}</p>
                        : null}</React.Fragment> : null}
                    <p className="itemTitle">{item.name}</p>
                    <p className="price">
                        {
                            stockOut ?
                                <span className="after_discount">품절</span> :
                                <>
                                    {regular ? <>
                                        <span className="after_discount">
                                            {price}
                                            <i className="won">원</i>
                                        </span>
                                    </> : <>
                                        {
                                            item.discount_percent === 0 ?
                                                null :
                                                <span className="discount">{item.discount_percent}%{'\u00A0'}</span>
                                        }
                                        <span className="after_discount">{sellPrice}<i className="won">원</i></span>
                                        {
                                            item.discount_percent === 0 ? null :
                                            <span className="before_discount">{price === 0 ? '' : price}
                                                <i className="won">원</i>
                                            </span>}
                                    </>}
                                </>

                        }

                    </p>
                    <p className="review">리뷰 {review}</p>
                </Items>
            </Link>
        </ItemWrapper>
    )
}

const ItemWrapper = styled.div`
  width: calc((100% - 99px) / 4);
  margin: 80px 33px 0 0;
  position: relative;

  &:nth-child(4n) {
    margin-right: 0;
  }

  &:nth-child(-n + 4) {
    margin-top: 0;
  }

  @media only screen and (max-width: 960px) {
    width: calc((100% - 15px) / 2);
    margin: 40px 15px 0 0;
    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(-n + 4) {
      margin-top: 40px;
    }

    &:nth-child(-n + 2) {
      margin-top: 0;
    }
  }

  a {
    display: block;
    text-decoration: none;
    color: #000;
  }
`

const Items = styled.div`
  & .status_box {
    position: absolute;
    z-index: 1;

    & span {
      display: block;
      height: 29px;
      @media only screen and (max-width: 960px) {
        height: 24px;
      }
    }

    & .status {
      margin: 0 0 0 0;

      & img {
        height: 30px;
        @media only screen and (max-width: 960px) {
          height: 24px;
        }
      }
    }
  }

  p {
    font-size: 1.4rem;
    margin: 0;
    vertical-align: middle;
  }

  & .item_img {
    position: relative;
    overflow: hidden;
    width: 100%;

    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: auto;
      height: auto;
      width: 100%;
    }

    &.dimmed::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      z-index: 99;
    }
  }

  & .itemTitle {
    font-size: 1.125rem;
    margin: 16px 0 6px 0;
    line-height: 27px;
    font-style: normal;
    font-weight: 400;
    color: #222;
    word-break: break-all;

    @media only screen and (max-width: 960px) {
      margin: 12px 0 3px;
      font-size: 0.938rem;
      line-height: 22.5px;
    }
  }

  & .price {
    overflow: hidden;
    margin-bottom: 4px;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 30px;
    color: #222;

    @media only screen and (max-width: 960px) {
      margin-bottom: 8px;
      font-size: 1rem;
    }
  }

  & .won {
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;

    @media only screen and (max-width: 960px) {
      font-size: 0.875rem;
    }
  }

  & .discount {
    color: #1ea0fa;
    @media only screen and (max-width: 960px) {
      margin-left: 5px;
    }
  }

  @media only screen and (max-width: 960px) {
    & .after_discount {
      float: left;
      line-height: 28px;
    }
  }

  & .before_discount {
    padding-left: 8px;
    text-decoration: line-through;
    font-size: 0.875rem;
    font-weight: 400;
    color: #888888;
    @media only screen and (max-width: 960px) {
      display: block;
      padding: 0;
      font-size: 0.75rem;
    }
  }

  & .review {
    font-size: 0.875rem;
    color: #515355;
    line-height: 21px;
    text-align: left;
    @media only screen and (max-width: 960px) {
      font-size: 0.75rem;
      line-height: 18px;
    }
  }
`
