import {
    ADD_TO_CART,
    ADDJUST_SESSION_TO_AUTH,
    REMOVE_FROM_CART, SET_CART_NOMAL, SET_CART_REGUALAR, ADD_CART_NOMAL, ADD_CART_REGUALAR,
} from "./types";
import uuid from 'react-uuid'
import axios from "axios";

export const fetchCartLists = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: {userInfo}
        } = getState();

        if(userInfo){
            if (userInfo.data) {
                const config={
                    headers: {
                        'Content-Type': 'application/json',
                        "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
                    },
                }
                const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/order/cart`, config);
                dispatch({type: SET_CART_NOMAL, payload: [...data.carts]})
                dispatch({type: SET_CART_REGUALAR, payload: [...data.regular_carts]})
                return
            }
        }
        const readCartItemSession = localStorage.getItem('cartItems');

        if (readCartItemSession !== null && readCartItemSession !== "") {
            const jsonReadCartItemSession = JSON.parse(readCartItemSession);
            for (let i = 0; i < jsonReadCartItemSession.length; i++) {
                if (jsonReadCartItemSession[i].regular) {
                    dispatch({type: ADD_CART_REGUALAR, payload: jsonReadCartItemSession[i].Product});
                } else {
                    dispatch({type: ADD_CART_NOMAL, payload: jsonReadCartItemSession[i].Product});
                }
            }
        }
    }catch(error){
        console.log(error)
    }
}

export const addRegToCart = (id, name, quantity, cycle, price,itemInfo) => async(dispatch, getState) => {
    const {
        userLogin: {userInfo}
    } = getState();

    if(userInfo.data){
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
            },
        }
        await axios.post(`${process.env.REACT_APP_API_URL}api/order/addRegularCart`,{
            quantity, name, price, cycle ,regular_product_id: id
        }, config)
        const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/order/cart`, config);
        dispatch({type: SET_CART_NOMAL, payload: [...data.carts]})
        dispatch({type: SET_CART_REGUALAR, payload: [...data.regular_carts]})
    }else{
        let isChange = false;
        for (let i = 0; i < getState().cartItems.cartItems.length; i++) {
            const cartitem = getState().cartItems.cartItems[i];
            // cartitem.Options[0].cycle
            if (cartitem.Product.id === id) {
                if (cycle === cartitem.Options[0].cycle) {
                    cartitem.Options[0].quantity += quantity;
                    isChange = true;
                }
            }
        }
        if (isChange) {
            localStorage.setItem('cartItems', JSON.stringify(getState().cartItems.cartItems));
            return
        }

        dispatch({
            type: ADD_TO_CART,
            payload: {
                id: uuid(),
                ProductId: id,
                Product: itemInfo,
                Options: [{name, quantity, cycle, price}],
                regular: true,
            },
        });
        localStorage.setItem('cartItems', JSON.stringify(getState().cartItems.cartItems));
        dispatch({type: ADD_CART_REGUALAR, payload: {
                id: uuid(),
                ProductId: id,
                Product: itemInfo,
                Options: [{name, quantity, cycle, price}],
                regular: true,
            }})
    }
}

export const addToCart = (item) => async (dispatch, getState) => {

    try{
        const {userLogin: {userInfo}} = getState();

        if(userInfo.data){
            const config={
                headers: {
                    'Content-Type': 'application/json',
                    "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
                },
            };
            await axios.post(`${process.env.REACT_APP_API_URL}api/order/addCart`,{
                product_id: item.id,
                Options: item.selectedOption,
            }, config);
            const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/order/cart`, config);
            dispatch({type: SET_CART_NOMAL, payload: [...data.carts]})
            dispatch({type: SET_CART_REGUALAR, payload: [...data.regular_carts]})
        }else{
            for (let i = 0; i < getState().cartItems.cartItems.length; i++) {
                const cartitem = getState().cartItems.cartItems[i];
                let isOptions = [];
                if (cartitem.Product.id === item.id) {
                    if (item.selectedOption.length === cartitem.Product.selectedOption.length) {
                        for (let j = 0; j < item.selectedOption.length; j++) {
                            const filtered = cartitem.Product.selectedOption.findIndex(v => v.id === item.selectedOption[j].id);
                            if (filtered >= 0) {
                                cartitem.Product.selectedOption[filtered].quantity += item.selectedOption[j].quantity;
                                isOptions.push({status:true, id: item.selectedOption[j].id});
                            }
                        }

                        if (cartitem.Product.selectedOption.length === isOptions.length) {
                            localStorage.setItem('cartItems', JSON.stringify(getState().cartItems.cartItems));
                            return
                        }
                    }
                }
            }
            dispatch({
                type: ADD_TO_CART,
                payload: {
                    id: uuid(),
                    Product: item,
                    ProductId: item.id,
                    Options: item.selectedOption,
                    date: new Date(),
                    regular: false,
                },
            });
            localStorage.setItem('cartItems', JSON.stringify(getState().cartItems.cartItems));
            dispatch({type: ADD_CART_NOMAL, payload: {
                    id: uuid(),
                    Product: item,
                    ProductId: item.id,
                    Options: item.selectedOption,
                    regular: false,
                }})
        }
    }catch(error){
        console.log(error)
    }
}

export const adjustSessionToAuth = (item) => async (dispatch, getState) => {

    try{
        const {
            userLogin: {userInfo}
        } = getState();
        if (!userInfo.data.access_token) {
            return
        }
        const config={
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
            },
        };
        if(item.data.regular){
            const {data} = await axios.post(`${process.env.REACT_APP_API_URL}api/order/addRegularCart`,{
                quantity: item.data.Options[0].quantity,
                name: item.data.Options[0].name,
                price: item.data.Options[0].price,
                cycle: item.data.Options[0].cycle,
                regular_product_id: item.data.product_id
            }, config);

            dispatch({
                type: ADDJUST_SESSION_TO_AUTH,
                payload: data,
            });
        }else{
            const {data} = await axios.post(`${process.env.REACT_APP_API_URL}api/order/addCart`,{
                product_id: item.data.product_id,
                Options: item.data.Options,
            }, config);

            dispatch({
                type: ADDJUST_SESSION_TO_AUTH,
                payload: data,
            });
        }

    }catch(error){
        console.log(error)
    }
}

export const removeFromCart = (id) => {
    return {
        type: REMOVE_FROM_CART,
        payload: {
            id
        }
    };
};

