import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import Nav from './Nav'
import { GrClose } from 'react-icons/gr';
import { useLocation } from "react-router-dom";

const Head = styled.header``

const TopBaneer = styled.div`
  display: ${({ banner }) => banner ? "block" : "none"};
  position: relative;
  width: 100%;
  height: 40px;
  font-size: 0.938rem;
  text-align: center;
  background: #08090A;
  color: #fff;
  padding: 8px 0;
  line-height: 24px;
  font-weight: 500;

  & .banner_close {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0;
    top: 0;
    cursor: pointer;

    & path {
      stroke: #fff;
    }
  }
`

const Header = () => {
  const { pathname } = useLocation();
  const [ banner, setBanner ] = useState(true);

  useEffect(() => {
    setBanner(true);
  },[pathname])

  return (
    <Head id="header">
      {/* 독립몰 secret 정기배송 관련 hidden 처리 -> 정기배송 원복 시 50%로 수정 */}
      {/* <TopBaneer banner={banner}>
        <span>톡 채널 추가하고 3,000원 할인 받기</span>
        <span className="banner_close" onClick={() => setBanner(false)}><GrClose color="#fff" size="15" /></span>
      </TopBaneer> */}
      <Nav />
    </Head>
  )
}

export default Header