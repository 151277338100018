import React from 'react';
import styled from "styled-components";
import Dialog from "./Dialog";
import DaumPostcode from "react-daum-postcode";
import { useMediaQuery } from 'react-responsive';

const PostCode = ({ visible, onCancel, setPostCodePopup, setValue, getvalue }) => {
    const isPc = useMediaQuery({ query: '(min-width: 961px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddress += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddress +=
                    extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
        }
        if (getvalue) {
            getvalue.zoneCode = data.zonecode;
            getvalue.address = fullAddress;
            getvalue.addressDetail = '';
            setValue(getvalue);
        } else {
            setValue("zoneCode", data.zonecode)
            setValue("address", fullAddress)
            document.getElementById("Address3").focus();
        }
        setPostCodePopup(false);
    };

    const postCodeStyle = {
        width: "100%",
        height: isPc ? "500px" : "100%",
        display: "block",
        fontSize: "16px"
    };

    return (
        <>
            <Dialog
                title="우편번호 검색"
                cancelText="닫기"
                onCancel={onCancel}
                dialogSize="small"
                fullHeight
                fixHeight
                visible={visible}>
                <DaumPostcode style={postCodeStyle} onComplete={handleComplete} />
            </Dialog>
        </>
    );
};

export default PostCode;