import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Button from './Button';
import { CgClose } from 'react-icons/cg';
import zIndex from "@material-ui/core/styles/zIndex";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(200px);
  }
  to {
    transform: translateY(0px);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(200px);
  }
`;

const dialogSizes = {
  large: {
    width: '600px'
  },
  medium: {
    width: '540px'
  },
  small: {
    width: '520px'
  }
}

const dialogSizeStyle = css`
  /* 크기 */
  ${({ dialogSize }) => css`
    width: ${dialogSizes[dialogSize].width};

    /* @media only screen and (max-width: 960px) {
      max-width: ${dialogSizes[dialogSize].width};
    } */
  `}
`

const fullHeightStyles = css`
  ${props => props.fullHeight && css`
    @media only screen and (max-width: 960px) {
      overflow-y: hidden;
      width: 100%;
      height: 100vh;
      height: var(--vh, 1vh) * 100;
      max-height: 100vh;
      padding: 0;
      & .dialog_wrap {
        height: 100vh;
        padding: 0;
        & .dialog_inner {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      h3 {
        padding: 15px;
        background: #fff;
        border-bottom: 1px solid #f2f4f6;
        font-size: 1.125rem;
        line-height: 27px;
        color: #000;
      }
    }
  `}
`

const fixHeightStyles = css`
  ${props => props.fixHeight && css`
    & .dialog_wrap {
      overflow-y: hidden;
      height: 100%;
      padding: 50px 0 0 0;
      & .dialog_inner {
        overflow-y: auto;        
        max-height: calc(90vh - 50px);
        &::-webkit-scrollbar {
          position: absolute;
          right: 0;
          top: 0;
          width: 4px;
          border-radius: 15px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #888B9D;
        }
        &::-webkit-scrollbar-track {
          background-color: #DDDDDD;
        }
      }
    }
    @media only screen and (max-width: 960px) {
      height: 100%;
      & .dialog_wrap {
        height: 100%;
        padding: 0;
        & .dialog_inner {
          height: calc(100% - 60px);
          max-height: calc(100% - 60px);
        }
      }
    }
  `}
`

const DarkBackground = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: var(--vh, 1vh) * 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.5);
  z-index: 9999;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;

  @media only screen and (max-width: 960px) {
    animation-duration: 0s;
  }

  ${props =>
    props.disappear &&
    css`
      animation-name: ${fadeOut};
    `}
`;

const DialogBlock = styled.div`
  ${dialogSizeStyle};
  overflow: hidden;
  max-height: 90vh;
  background: white;

  & .dialog_wrap {
    padding: 50px;
    & h3 {
      margin-bottom: 40px;
      font-size: 1.375rem;
      font-weight: 500;
      line-height: 33px;
      text-align: center;
      color: #222;

      & span {
        display: inline-block;
        margin-left: 5px;
        color: #999;
      }
    }

    p {
      font-size: 1rem;
      line-height: 24px;
    }

    .close {
      position: absolute;
      top: 28px;
      right: 28px;
      cursor: pointer;
      & img {
        width: 23px
      }
    }
  }  

  @media only screen and (max-width: 960px) {
    overflow-y: auto;
    width: calc(100% - 30px);

    & .dialog_wrap {
      padding: 40px 28px;

      & h3 {
        margin-bottom: 0;
        font-size: 1.25rem;
        line-height: 30px;
      }

      & .close {
        position: absolute;
        top: 18px;
        right: 15px;
        cursor: pointer;
        & img {
          width: 25px
        }
      }
    }
  }

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${slideUp};
  animation-fill-mode: forwards;

  ${fullHeightStyles};
  ${fixHeightStyles};
  ${props => props.disappear && css`
    animation-name: ${slideDown}
  `}
`;

const ButtonWrap = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 960px) {
    margin-top: 24px;
  }
`

const ShortButton = styled(Button)`
  width: 160px;
  border-radius: 4px;

  @media only screen and (max-width: 960px) {
    width: 140px;
  }
`

function Dialog({ title, sub_title, children,  confirmText,  cancelText, visible, onConfirm, onCancel, onClose, dialogSize, fullHeight, fixHeight, className, btnHide, btnLogin, changeCard }) {
  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(visible);

  useEffect(() => {
    // visible true -> flase
    if (localVisible && !visible) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
    }
    setLocalVisible(visible);
  }, [localVisible, visible]);

  if (!localVisible && !animate) return null;

  return (
    <DarkBackground disappear={!visible} className={className}>
      <DialogBlock disappear={!visible} dialogSize={dialogSize} fullHeight={fullHeight} fixHeight={fixHeight}>
        <div className="dialog_wrap custom_scroll">
          { title && 
            <h3>{title}<span>{sub_title}</span></h3>
          }
          <span className="close" onClick={onClose?onClose:onCancel} style={{zIndex:10}}><CgClose size="29" style={{marginTop:"-3px"}}/></span>
          <div className="dialog_inner">{children}</div>
          { onConfirm &&
            <ButtonWrap className="ButtonWrap">
              {btnLogin ?
                  <ShortButton size="small" onClick={onConfirm}>{confirmText}</ShortButton>
                  : <React.Fragment>
                      {cancelText && <ShortButton size="small" color="white" outline onClick={onCancel}>{cancelText}</ShortButton>}
                      {btnHide ? null : <ShortButton size="small" onClick={onConfirm}>{confirmText}</ShortButton>}
                    </React.Fragment> }
            </ButtonWrap>
          }
        </div>
      </DialogBlock>
    </DarkBackground>
  );
}

Dialog.defaultProps = {
  confirmText: '확인',
  dialogSize: 'small'
}

export default Dialog;