import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { login } from "actions/userActions";
import { Link, useHistory } from "react-router-dom";
import Button from "./components/Button";
import ico_bubble from "images/ico_bubble.png";
import ico_checked from "images/ico_check_w.png";
import m_ico_checked from "images/m/ico_check_w.png";
import Loader from "./components/Loader";
import KakaoLogin from "./components/KakaoLogin";

const MainButton = styled(Button)`
  @media only screen and (max-width: 960px) {
    height: 50px;
    font-weight: 700;
    font-size: 1rem;
  }
`;

const LoginPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const emailRef = useRef();
    const passwordRef = useRef();

    const onInputChange = (e) => {
        const value = e.target.value;
        switch (e.target.name) {
            case "user_id":
                setEmail(value);
                break;
            case "user_pw":
                setPassword(value);
                break;
        }
    }
    const onkeydown = (e) => {
        if (e.keyCode !== 13) { return }

        switch (e.target.name) {
            case "user_id":
                passwordRef.current.focus();
                break;
            case "user_pw":
                onSubmit()
                break;
        }
    }

    const { loading, errorMsg  } = userLogin;

    useEffect(() => {
        if (!loading) {
            history.push("/");
        }
    }, [loading])
    useEffect(() => {
        if(errorMsg === "UnauthorizedUserError"){
            setSignInfo({
                ...signInfo,
                isLogin: false,
                errorId: true
            })
        }else if(errorMsg === "InvalidPasswordError"){
            setSignInfo({
                ...signInfo,
                isLogin: false,
                errorPw: true
            })
        }else if(errorMsg === "LoginTryLimitError"){
            setSignInfo({
                ...signInfo,
                isLogin: false,
                user_lock: true
            })
        }else{
            setSignInfo({
                ...signInfo,
                errorId: false,
                user_lock: false,
                errorPw: false
            })
        }
    },[errorMsg])

    const [signInfo, setSignInfo] = useState({
        errorMsg: "",
        errorId: false,
        errorPw: false,
        user_lock: false
    });

    const { errorPw, errorId, user_lock } = signInfo;

    const onSubmit = () => {
        let data = {user_id: email, user_pw: password};
        try {
            dispatch(login(data.user_id, data.user_pw)).then(setSignInfo({
                ...signInfo,
                isLogin: true,
            }));
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <LoginContainer>
            {!loading ? <Loader /> :
                <>
                    <div className="title_wrap">
                        <h2>로그인 및 회원가입 시작하기</h2>
                        <p>지금, 회원가입하고<br />바램펫 회원만을 위한 특별한 혜택을 받아보세요!</p>
                    </div>
                    <div className="login_wrap">
                        <div className="sns_login">
                            <KakaoLogin />
                        </div>
                        <p className="area_border">
                            <span>또는</span>
                        </p>
                        <div className="basic_login">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <input type="text" name="user_id" placeholder="이메일 혹은 전화번호" ref={emailRef}
                                       onChange={onInputChange} value={email} onKeyDown={onkeydown}></input>
                                {errorId ? <p className="error_msg">아이디를 확인해주세요.</p> : null}
                                <input type="password" name="user_pw" placeholder="비밀번호" ref={passwordRef}
                                       onChange={onInputChange} value={password} onKeyDown={onkeydown}></input>
                                {user_lock ? null : errorPw ? <p className="error_msg">비밀번호를 확인해주세요.</p> : null}
                                {user_lock ? <p className="error_msg">비밀번호를 5회 이상 잘못 입력하셨습니다. 해당 계정은 5분간 잠금처리됩니다.</p> : null}
                                <div className="find_wrap">
                                    <div className="checks">
                                        <input type="checkbox" name="" id="check_login"/>
                                        <label htmlFor="check_login">로그인 상태 유지</label>
                                    </div>
                                    <div className="find">
                                        <Link to="/FindId">
                                            <span>아이디 찾기</span>
                                        </Link>
                                        <Link to="/FindPw">
                                            <span>비밀번호 찾기</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="button_wrap">
                                    <MainButton onClick={() => {onSubmit()}} color="white" fullWidth outline>
                                        로그인
                                    </MainButton>
                                    <MainButton
                                        fullWidth
                                        onClick={() => {
                                            history.push("/Join");
                                        }}
                                    >
                                        회원가입 후 혜택받기
                                    </MainButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </>}
        </LoginContainer>
    );
};

const LoginContainer = styled.div`
  max-width: 1480px;
  width: 100%;
  margin: auto;
  & .title_wrap {
    margin-top: 80px;
    margin-bottom: 39px;
    text-align: center;
    color: #222;

    h2 {
      font-size: 2rem;
      line-height: 36px;
    }

    p {
      margin-top: 20px;
      font-size: 1.125rem;
      line-height: 27px;
    }
  }
  & .login_wrap {
    & > div {
      width: 500px;
      margin: 0 auto;
    }

    & .sns_login {
      p {
        margin-bottom: 5px;
        span {
          display: inline-block;
          width: 118px;
          height: 36px;
          padding-top: 5px;
          background: url(${ico_bubble}) no-repeat center top;
          background-size: cover;
          font-size: 0.75rem;
          line-height: 18px;
          text-align: center;
          b {
            color: #ff1010;
            font-weight: 500;
          }
        }
      }
      a {
        display: block;
        height: 54px;
        line-height: 54px;
        font-size: 1.125rem;
        font-weight: 500;
        color: #352929;
        background: #ffe13f;
        border-radius: 4px;
        text-align: center;
        img {
          height: 20px;
          margin: -2px 10px 0 0;
          vertical-align: middle;
        }
      }
    }
  }

  & .area_border {
    position: relative;
    margin: 50px 0;
    text-align: center;
    color: #999;
    font-size: 0.875rem;
    &:before,
    &:after {
      position: absolute;
      content: "";
      display: block;
      top: 50%;
      width: calc(50% - 23px);
      height: 1px;
      background: #e5e5e5;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }

  & .basic_login {
    padding-bottom: 88px;
    input[type="text"],
    input[type="password"] {
      width: 100%;
      padding: 11px 20px;
      line-height: 24px;
      font-size: 1rem;
      background: #f6f6f6;
      border: none;

      &:not(:first-child) {
        margin-top: 10px;
      }

      &::placeholder {
        line-height: 24px;
        font-size: 1rem;
        color: #999;
      }
    }

    .error_msg {
      margin-top: 8px;
      color: #ff2727;
      font-size: 0.75rem;
      line-height: 18px;
    }

    & .find_wrap {
      display: flex;
      margin: 16px 0 60px;
      line-height: 21px;
      font-size: 0.875rem;
      color: #555;

      .checks input[type="checkbox"] + label:before {
        width: 18px;
        height: 18px;
        line-height: 18px;
        margin: -2px 10px 0 0;
        background-image: none;
        border-radius: 0;
      }
      .checks input[type="checkbox"]:checked + label:before {
        background: #1ea0fa url(${ico_checked}) no-repeat left 2px bottom 4px;
        border: 1px solid #1ea0fa;
        background-size: 11px auto;
      }

      & .find {
        margin-left: auto;

        a {
          position: relative;

          &:first-child {
            padding-right: 10px;
            margin-right: 10px;
            &:after {
              position: absolute;
              content: "";
              display: block;
              right: 0;
              top: 3.5px;
              width: 1px;
              height: 14px;
              background: #cccccc;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    min-width: 100%;
    padding: 0 15px;
    & .title_wrap {
      margin-top: 40px;

      h2 {
        font-size: 1.5rem;
      }

      p {
        font-size: 0.938rem;
        line-height: 22.5px;
      }
    }
    & .login_wrap {
      & > div {
        width: 100%;
        max-width: 500px;
      }
      & .sns_login {
        p {
          span {
            width: 7.188rem;
            height: 1.688rem;
            padding-top: 0.313rem;
            font-size: 0.688rem;
            line-height: 1.031rem;
            box-sizing: content-box;
          }
        }
        a {
          height: 50px;
          line-height: 50px;
          font-size: 1rem;
          font-weight: 700;
          img {
            height: 18px;
            margin: -2px 10px 0 0;
          }
        }
      }
    }

    & .area_border {
      margin: 30px 0;
      font-size: 0.75rem;
    }

    & .basic_login {
      padding-bottom: 48px;
      input[type="text"],
      input[type="password"] {
        line-height: 22.5px;
        font-size: 0.938rem;
        &::placeholder {
          line-height: 22.5px;
          font-size: 0.938rem;
          color: #999;
        }
      }

      & .find_wrap {
        margin: 10px 0 40px;
        line-height: 18px;
        font-size: 0.75rem;

        .checks input[type="checkbox"]:checked + label:before {
          background: #1ea0fa url(${m_ico_checked}) no-repeat left 2px bottom 4px;
          background-size: 11px auto;
        }

        & .find {
          a {
            &:first-child {
              padding-right: 8px;
              margin-right: 8px;
              &:after {
                top: 3px;
                width: 1px;
                height: 12px;
              }
            }
          }
        }
      }
    }
  }
`;

export default LoginPage;
