import React , {useEffect , useState} from 'react';
import {stringPrice} from "../../utils/StringPrice";
import styled from "styled-components"
import Select from "react-select";
import Button from "./Button";
import Dialog from "./Dialog";
import {useSelector , useDispatch} from "react-redux";
import icon_close from "images/icon_close.png";
import {useMediaQuery} from "react-responsive";
import {addRegToCart} from "../../actions/cartActions";
import axios from "axios";

const RegularOrderOption = ({itemInfo}) => {
    const isPc = useMediaQuery({query: '(min-width: 961px)'})
    const isMobile = useMediaQuery({query: '(max-width: 960px)'})
    const userInfo = useSelector((state) => state.userLogin.userInfo.data || "");
    const [optionLayer , setOptionLayer] = useState(false);
    const [selectedOption , setSelectedOption] = useState(false);
    const [quantity , setQuantity] = useState(1);
    const [checkCartsReg , setCheckCartsReg] = useState([])
    const [optionCycle , setOptionCycle] = useState({
        label: "결제 배송/주기 선택하기" ,
        value: ""
    });
    const cartState = useSelector((state) => state.cartItems.cartItems);
    const cartlists = useSelector((state) => state.cartRefresh);
    const dispatch = useDispatch();
    const regularOptions = [
        {value: 2 , label: "2주"} ,
        {value: 3 , label: "3주"} ,
        {value: 4 , label: "4주"} ,
        {value: 5 , label: "5주"} ,
        {value: 6 , label: "6주"} ,
        {value: 7 , label: "7주"} ,
        {value: 8 , label: "8주"}
    ]


    const [ScrollY , setScrollY] = useState(0);
    const [BtnStatus , setBtnStatus] = useState(false);
    const [purchaseFix , setPurchaseFix] = useState(true);
    useEffect(() => {
        setCheckCartsReg(cartlists.cartRegularItems)
    } , [cartlists])

    const handleFollow = () => {
        if (!document.getElementById("ProductDetail")) {
            return
        }
        if (!document.getElementById("footer")) {
            return
        }
        setScrollY(window.pageYOffset);
        if (ScrollY > 500) {
            setBtnStatus(true);
        } else {
            setBtnStatus(false);
            if (isPc) {
                setOptionLayer(false);
            }
        }
        if (document.getElementById("footer") || document.getElementById("ProductDetail")) {
            let footerHeight = document.getElementById("footer").offsetHeight;
            let mainHeight = document.getElementById("ProductDetail").offsetHeight;
            let height = mainHeight - footerHeight - 550;
            if (ScrollY > height) {
                setPurchaseFix(false);
            } else {
                setPurchaseFix(true);
            }
        }
    };

    const handleSelect = (value , label) => {
        setOptionCycle({
            ...optionCycle ,
            value: value ,
            label: label
        })
        setSelectedOption(true)
    };
    const handleQuantityMinus = (e) => {
        e.preventDefault();
        quantity !== 1 && setQuantity(quantity - 1);
    };
    const handleQuantityPlus = (e) => {
        e.preventDefault();
        setQuantity(quantity + 1);
    };

    const getTotalPrice = () => {
        let total = quantity * itemInfo.price
        return total;
    };
    const total = getTotalPrice();

    const handleClickOrder = async (id , name , quantity , cycle , price) => {
        if (cartlists.cartRegularItems.length >= 1) {
            for (let i = 0; i < cartlists.cartRegularItems.length; i++) {
                if (cartlists.cartRegularItems[i].id === id) {
                    let str = '장바구니에 동일한 상품이 담겨있습니다.\n 장바구니에 추가하시겠습니까?2';
                    setDialog({...dialog , open: true , confirmText: "확인" , cancelText: "취소" , dialogContent: str});
                    return
                }
            }
        }
        if (itemInfo.is_soldout === true || itemInfo.sell === false) {
            setDialog({
                ...dialog ,
                open: true ,
                btnHide: true ,
                confirmText: "확인" ,
                cancelText: "확인" ,
                dialogContent: "상품이 품절되었습니다!"
            });
        } else if (optionCycle.label === "결제 배송/주기 선택하기") {
            setDialog({...dialog , open: true , btnHide: true , cancelText: "확인" , dialogContent: "결제 배송/주기를 선택해주세요"});
        } else if (cartState.map((el) => el.id).includes(id)) {
            let str = '장바구니에 동일한 상품이 담겨있습니다.\n 장바구니에 추가하시겠습니까?3';
            setDialog({...dialog , open: true , confirmText: "확인" , cancelText: "취소" , dialogContent: str});
        } else {
            dispatch(addRegToCart(id , name , quantity , cycle , price , itemInfo));
            setDialog({
                ...dialog ,
                open: true ,
                confirmText: "장바구니로 이동" ,
                cancelText: "계속 쇼핑하기" ,
                dialogContent: "장바구니에 잘 담겼어요!"
            });
        }
    };

    const [dialog , setDialog] = useState({
        open: false ,
        confirmText: "" ,
        cancelText: "" ,
        dialogContent: "" ,
        btnHide: false
    });

    const onConfirm = async (dialogText) => {
        if (dialogText === "장바구니로 이동") {
            window.location.href = "/shoppingcart/#regular";
        } else {
            try {
                dispatch(addRegToCart(itemInfo.id , itemInfo.name , quantity , optionCycle.value , total , itemInfo));
                setDialog(false);
                setDialog({
                    ...dialog ,
                    open: true ,
                    confirmText: "장바구니로 이동" ,
                    cancelText: "계속 쇼핑하기" ,
                    dialogContent: "장바구니에 잘 담겼어요!"
                });
            } catch (error) {
                console.log(error);
            }
        }
    }
    
    const onCancel = async (dialogText) => {
      if(dialogText === "계속 쇼핑하기"){
             window.history.back()
        } else {
           setDialog(false);
        }
        
    }

    const onClose = () => {
       setDialog(false);
    }

    useEffect(() => {
        const watch = () => {
            window.addEventListener("scroll" , handleFollow);
        };
        watch();
        return () => {
            window.removeEventListener("scroll" , handleFollow);
        };
    });

    useEffect(async () => {
        try {
            if (userInfo.data) {
                axios.defaults.headers.common["cid"] = userInfo.cid;
                axios.defaults.headers.common["encoded"] = userInfo.encoded;
                const {data} = await axios.get(`${process.env.REACT_APP_API_URL}api/order/cart`);
                setCheckCartsReg(data.regular_carts)
            }


        } catch (error) {
            console.log(error);
        }
    } , [])


    return (
        <OptionDetail>
            <Dialog
                confirmText={`${dialog.confirmText}`}
                cancelText={`${dialog.cancelText}`}
                onConfirm={() => {
                    onConfirm(dialog.confirmText)
                }}
                onCancel={() => {onCancel(dialog.cancelText)}}
                onClose={onClose}
                dialogSize="medium"
                visible={dialog.open}
                btnHide={dialog.btnHide}
                className="cartLayer"
            >
                <p style={{whiteSpace: "pre-wrap"}}>{dialog.dialogContent}</p>
            </Dialog>

            <PurchaseBtn className={BtnStatus ? "fix_purchase_btn active" : "fix_purchase_btn"}
                         purchaseFix={purchaseFix}>
                {
                    itemInfo.sell && !itemInfo.is_soldout ?
                        <Button size="large" className="purchase_btn" fullWidth
                                onClick={() => setOptionLayer(!optionLayer)}>정기배송 담기</Button> :
                        <Button size="large" color="gray" className="purchase_btn" fullWidth>일시품절</Button>}
            </PurchaseBtn>
            {isMobile &&
                <LayerWrap className={optionLayer ? "layer_wrap active" : "layer_wrap"}
                           onClick={() => setOptionLayer(!optionLayer)}></LayerWrap>
            }
            <OptionLayer className={optionLayer ? "option_layer active" : "option_layer"} purchaseFix={purchaseFix}>
                <p className="layer_tit">{itemInfo.name}<img src={icon_close} alt="옵션팝업닫기"
                                                             onClick={() => setOptionLayer(!optionLayer)}/></p>
                <div className="select_options">
                    <div>
                        <label>결제/배송 주기</label>
                        <Select
                            placeholder="결제/배송 주기 선택하기"
                            onChange={(option) => {
                                handleSelect(option.value , option.label)
                            }}
                            options={regularOptions}
                            className="selectBox"
                            classNamePrefix="select"
                            isSearchable={false}
                        />
                    </div>
                </div>
                {selectedOption ? <div className="selected_option">
                    <div className="option_wrap">
                        <div className="option_box">
                            <p>{optionCycle.label}</p>
                        </div>
                        <div className="option_box">
                            <p>{itemInfo.name}</p>
                            <div className="option_bottom">
                                <div className="option_count">
                                    <button onClick={(e) => handleQuantityMinus(e)}>-</button>
                                    <label>{quantity}</label>
                                    <button onClick={(e) => handleQuantityPlus(e)}>+</button>
                                </div>
                                <span>{stringPrice(itemInfo.price * quantity)}원</span>
                            </div>
                        </div>
                    </div>
                </div> : null}
                {selectedOption ? <div className="total_price">
                    <span>총 상품 금액</span>
                    <span className="price"><b>{quantity !== 1 ? stringPrice(total) : stringPrice(itemInfo.price)}</b>원</span>
                </div> : null}
                {itemInfo.sell && !itemInfo.is_soldout ? <div className="option_btn"><Button onClick={() => {
                    handleClickOrder(itemInfo.id , itemInfo.name , quantity , optionCycle.value , itemInfo.price)
                }}>정기배송 담기</Button></div> : <div className="option_btn">
                    <div className="option_btn"><Button Large fullWidth className="soldOut_btn_in"
                                                        color="gray">일시품절</Button></div>
                </div>}
            </OptionLayer>


            <h2>{itemInfo.name}</h2>
            <div className="sub_tit">{itemInfo.simple_description}</div>
            <form>
                <div className="price_option">
                    <span className="discount_span">혜택가</span>
                    <span className="price">{stringPrice(itemInfo.price)}<span className="won">원</span></span>
                </div>
                <div className="option_announcement">
                    <table>
                        <tbody>
                        <tr>
                            <td>배송비 안내</td>
                            <td><p>정기배송은 언제나 배송비 무료</p></td>
                        </tr>
                        <tr>
                            <td>배송 안내</td>
                            <td>
                                <p>14시 이전 주문 당일 출고</p>
                                <p>택배사 사정에 따라 출고 후 1~3일 소요될 수 있습니다.</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="select_options_wrap">
                    <div className="select_options">
                        <div>
                            <label>결제/배송 주기</label>
                            <Select
                                placeholder="결제/배송 주기 선택하기"
                                onChange={(option) => {
                                    handleSelect(option.value , option.label)
                                }}
                                options={regularOptions}
                                className="selectBox"
                                classNamePrefix="select"
                                isSearchable={false}
                            />
                        </div>
                    </div>
                    {selectedOption ? <div className="selected_option">
                        <div className="option_wrap">
                            <div className="option_box">
                                <p>{optionCycle.label}</p>
                            </div>
                            <div className="option_box">
                                <p>{itemInfo.name}</p>
                                <div className="option_bottom">
                                    <div className="option_count">
                                        <button onClick={(e) => handleQuantityMinus(e)}>-</button>
                                        <label>{quantity}</label>
                                        <button onClick={(e) => handleQuantityPlus(e)}>+</button>
                                    </div>
                                    <span>{stringPrice(itemInfo.price * quantity)}원</span>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                </div>
                {selectedOption ? <div className="total_price">
                    <span>총 상품 금액</span>
                    <span className="price"><b>{quantity !== 1 ? stringPrice(total) : stringPrice(itemInfo.price)}</b>원</span>
                </div> : null}
                {itemInfo.sell && !itemInfo.is_soldout ? <div className="option_btn">
                    <Button onClick={() => {
                        handleClickOrder(itemInfo.id , itemInfo.name , quantity , optionCycle.value , itemInfo.price)
                    }}>정기배송 담기</Button>
                </div> : <div className="option_btn"><Button Large fullWidth className="soldOut_btn_in"
                                                             color="gray">일시품절</Button></div>}
            </form>
        </OptionDetail>
    );
};
const OptionDetail = styled.div`
  @media only screen and (max-width: 960px) {
    form {
      padding: 0 15px;
    }
  }

  & .price_option {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 20px 0;
    @media only screen and (max-width: 960px) {
      border-top: none;
      padding: 0;
      margin: 30px 0 10px;
    }

    & .discount_span {
      line-height: 22.5px;
      flex: 2;

      @media only screen and (max-width: 960px) {
        font-size: 0.875rem;
        line-height: 21px;
        color: #222;
      }
    }

    & .price {
      display: inline-block;
      margin-right: 8px;
      font-size: 1.625rem;
      font-weight: 700;

      @media only screen and (max-width: 960px) {
        font-size: 1.5rem;
        line-height: 36px;
      }

      .won {
        font-size: 1.25rem;
        font-weight: 500;

        @media only screen and (max-width: 960px) {
          font-size: 0.875rem;
        }
      }
    }
  }

  & .option_announcement {
    width: 100%;
    padding-top: 20px;
    border-top: 1px solid #ddd;
    color: #515355;
    font-size: 0.813rem;

    @media only screen and (max-width: 960px) {
      border-top: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
      padding: 15px;
    }

    & table {
      width: 100%;

      & tr {
        & td:first-child {
          width: 90px;
          line-height: 19.5px;
        }

        & td:last-child {
          padding-left: 10px;

          p {
            line-height: 19.5px;

            &:not(:first-child) {
              margin-top: 3px;
            }
          }
        }
      }
    }

    & tr:first-child td {
      padding-bottom: 8px;
    }
  }

  & .select_options_wrap {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #ddd;
    @media only screen and (max-width: 960px) {
      display: none;
    }
  }

  & .select_options {
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;

    & .selectBox {
      width: 100%;
      height: 43px;

      & .select__placeholder {
        font-size: 14px;
      }

      & .select__single-value {
        font-size: 14px;
      }
    }

    & > div {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      color: #222;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    & label {
      flex: 113px 0 0;
      line-height: 21px;
      @media only screen and (max-width: 960px) {
        flex: auto;
      }
    }
  }

  & .option_wrap {
    @media only screen and (max-width: 960px) {
      display: none;
    }

    & .option_box {
      position: relative;
      padding: 15px;
      background: #f6f6f6;

      &:first-child {
        margin-top: 20px;
      }

      &:not(:first-child) {
        margin-top: 10px;
      }

      & p {
        display: inline-block;
        max-width: 440px;
        color: #222;
        font-size: 0.938rem;
        line-height: 22.5px;
      }

      & .option_bottom {
        display: flex;
        align-items: center;
        margin-top: 12px;

        & .option_count {
          display: flex;

          & button {
            height: 26px;
            width: 26px;
            border: 1px solid #ccc;
            background: #fff;
            font-size: 20px;
            line-height: 21px;
          }

          & label {
            display: inline-block;
            width: 48px;
            height: 26px;
            margin-bottom: 0;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            background: #fff;
            font-size: 0.875rem;
            line-height: 24px;
            color: #222;
            text-align: center;
          }
        }

        & span {
          margin-left: auto;
          font-size: 0.938rem;
          line-height: 22.5px;
          color: #515355;
        }
      }

      & .option_del {
        position: absolute;
        top: 18px;
        right: 18px;
        width: 17px;
        cursor: pointer;
      }
    }

    @media only screen and (max-width: 960px) {
      & .option_box {
        &:not(:first-child) {
          margin-top: 8px;
        }

        & > div {
          display: flex;
          align-items: center;
        }

        & p {
          display: block;
          max-width: 100%;
          margin-right: 5px;
          font-size: 0.813rem;
          line-height: 19.5px;
        }

        & .option_bottom {
          & .option_count {
            & button {
              height: 30px;
              width: 30px;
              font-size: 1.2rem;
            }

            & label {
              width: 36px;
              height: 30px;
              font-size: 0.813rem;
              line-height: 30px;
            }
          }

          & span {
            margin-left: auto;
            padding: 0 5px;
            font-size: 0.938rem;
            line-height: 22.5px;
            color: #515355;
          }
        }

        & .option_del {
          position: absolute;
          top: 18px;
          right: 18px;
          width: 17px;
          cursor: pointer;
        }
      }
    }
  }

  & .total_price {
    display: flex;
    position: relative;
    //justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    padding: 20px 0 0 0;
    border-top: 1px solid #ddd;
    font-size: 1.25rem;
    font-weight: 500;

    & span:first-child {
      font-size: 0.938rem;
      color: #222222;
      line-height: 22px;
    }

    & .price {
      margin-left: auto;
      line-height: 45px;
    }

    @media only screen and (max-width: 960px) {
      display: none;
    }

    b {
      font-size: 30px;
      font-weight: 500;
    }
  }

  & .option_btn {
    padding: 20px 0;
    display: flex;
    @media only screen and (max-width: 960px) {
      display: none;
    }

    & .option_submit {
      display: flex;
      justify-content: space-between;
    }

    & div {
      width: 100%;
    }
  }

  & .talk_coupon {
    & p {
      background: #FFE13F;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      font-size: 1rem;
      cursor: pointer;
      color: #222;

      & span {
        margin-left: 5px;
        font-weight: 700;
        color: #222;
      }

      @media only screen and (max-width: 960px) {
        height: 51px;
        margin-top: 15px;
        font-size: 0.875rem;
      }
    }
  }

  & .cartLayer {
    .dialog_wrap {
      width: auto;

      & p {
        text-align: center;
      }
    }
  }
`;
const OptionLayer = styled.div`
  display: none;
  position: ${({purchaseFix}) => (purchaseFix ? "fixed" : "absolute")};
  bottom: 0;
  right: calc((100% - 1480px) / 2);
  width: 480px;
  padding: 30px 17px 20px 30px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px 4px 0px 0px;
  z-index: 9998;

  &.active {
    display: block;
  }

  @media only screen and (max-width: 960px) {
    display: none;
    left: 0;
    right: 0;
    width: 100%;
    padding: 25px 5px 30px 15px;
    border: none;
    border-radius: 0;
  }

  .layer_tit {
    position: relative;
    margin-bottom: 16px;
    padding-right: 30px;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 23.4px;
    color: #222;

    img {
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    @media only screen and (max-width: 960px) {
      display: none;
    }
  }

  & .select_options {
    border-bottom: none;
    padding-bottom: 0;

    & label {
      flex: auto;
    }

    @media only screen and (max-width: 960px) {
      display: block;
      margin: 0;
    }

    & > div {
      flex-direction: column;
      padding-right: 13px;
      text-align: left;
      @media only screen and (max-width: 960px) {
        padding-right: 10px;
      }

      &:not(:first-child) {
        margin-top: 14px;
      }
    }

    label {
      display: block;
      width: 100%;
      margin-bottom: 6px;
      line-height: 21px;
    }

    & .selectBox {
      width: 100%;
      height: auto;

      & .select__control {
        height: 43px;
      }

      & .select__placeholder {
        font-size: 14px;
      }

      & .select__single-value {
        font-size: 14px;
      }

      & .select__menu {
        position: relative !important;
        top: -1px;

        & .select__menu-list {
          overflow-y: auto;
          max-height: 129px;

          &::-webkit-scrollbar {
            position: absolute;
            right: 0;
            top: 0;
            width: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #888B9D;
            border-radius: 15px;
          }

          &::-webkit-scrollbar-track {
            background-color: #DDDDDD;
            border-radius: 15px;
          }
        }
      }
    }
  }

  & .selected_option {
    max-height: 185px;
    width: 100%;
    overflow-y: auto;
    margin-top: 20px;

    &::-webkit-scrollbar {
      width: 3px;
      border-radius: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #515355;
    }

    &::-webkit-scrollbar-track {
      background-color: #dddddd;
    }
  }


  .option_wrap {
    & .option_box {
      width: 420px;

      &:first-child {
        margin-top: 0;
      }
    }

    @media only screen and (max-width: 960px) {
      display: block;
      & .option_box {
        width: calc(100% - 10px);
      }
    }
  }

  .option_wrap .option_box p {
    max-width: 375px;
    padding-right: 29px;
  }

  & .total_price {
    margin-bottom: 0;
    width: calc(100% - 13px);
    @media only screen and (max-width: 960px) {
      display: flex;
      align-items: center;
      padding-top: 16px;
      & span:first-child {
        font-size: 0.875rem;
      }

      & .price {
        margin-left: auto;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 36px;

        b {
          font-size: 1.5rem;
          line-height: 36px;
          font-weight: 500;
        }
      }
    }
  }

  & .option_btn {
    padding: 16px 12px 0 0;

    @media only screen and (max-width: 960px) {
      display: flex;
      padding: 30px 11px 0 0;
    }

    & div {
      height: 50px;
      width: 100%;
      font-size: 1rem;
    }
  }
`;
const PurchaseBtn = styled.div`
  display: none;
  position: ${({purchaseFix}) => (purchaseFix ? "fixed" : "absolute")};
  max-width: 1480px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: right;
  z-index: 99;

  &.active {
    display: block;
  }

  & .purchase_btn {
    width: 480px;
    border-radius: 4px 4px 0px 0px;
  }

  @media only screen and (max-width: 960px) {
    display: block;
    width: 100%;
    & .purchase_btn {
      width: 100%;
      left: 0;
      height: auto;
      padding: 20px 0 calc(constant(safe-area-inset-bottom) + 20px);
      padding: 20px 0 calc(env(safe-area-inset-bottom) + 20px);
      border-radius: 0;
      line-height: 23px;
    }
  }
`;
const LayerWrap = styled.div`
  z-index: 1;
  background: none;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);

  &.active {
    display: block;
  }
`;


export default RegularOrderOption;