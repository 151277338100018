import React, {useState, useEffect, useCallback} from "react";
import styled from "styled-components";
import 'rc-rate/assets/index.css';
import Button from './Button';
import Dialog from './Dialog';
import axios from "axios"
import {useForm} from "react-hook-form";
import PostCode from "./PostCode";
import {useSelector} from "react-redux";


function AddressDialog({ visible, Address, setAddress, formValues, token, regId, checkUser, onClose, isRegularAddress }) {
    const [addressList, setAddressList] = useState([]);
    const [postCodePopup, setPostCodePopup] = useState(false);
    const { register, handleSubmit,watch, reset, setValue} = useForm();
    const userInfo = useSelector((state) => state.userLogin.userInfo);

    useEffect(() => {
        const config={
            headers: {
                'Content-Type': 'application/json',
                 "cid" : userInfo.data.cid,
                "encoded" : userInfo.data.encoded,
            },
        }
        axios.get(`${process.env.REACT_APP_API_URL}api/address`, config).then((res) => {
            setAddressList(res.data);
        })
    },[])


    const selectAddress = async (list) => {
        onClose(list)
    }
    const [e2, e3] = watch(["e2", "e3"]);

    const onSubmit = (data, e) => {
        data = {
            title: data.title,
            phone : data.p1 + data.p2 + data.p3,
            email: data.e1 + "@" + data.e2,
            is_default: data.default_address,
            name: data.name,
            zipcode: data.zoneCode,
            address: data.address,
            detail_address: data.addressDetail
        }

        const fixAddress = (id) => {
            const config={
                headers: {
                    'Content-Type': 'application/json',
                    "cid" : userInfo.data.cid,
                    "encoded" : userInfo.data.encoded,
                },
            }
            axios.post(`${process.env.REACT_APP_API_URL}api/address/${id}`, data, config).then((res) => {
                setAddressMod({...AddressMod, fix: false, id: ""});
                setAddressList(res.data);
            })
        }
        fixAddress(AddressMod.list.id)
    }

    const deleteAddress = (id) => {
        const config={
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                "encoded" : userInfo.data.encoded,
            },
        }
        axios.delete(`${process.env.REACT_APP_API_URL}api/address/${id}`, config).then((res) => {
            setAddressList(res.data);
        })
    }

    const resetForm = (e) => {
        e.target.reset();
    }

    const onCancel = () => {
        setAddressMod({...AddressMod, fix: false, id: ""});
        setAddress(false);
    }

    // 배송지 수정 팝업
    const [ AddressMod, setAddressMod ] = useState({
        fix: false,
        list: ""
    });
    const phoneNumber = useCallback((ph) => {
        const regex = /^([0-9]{3})([0-9]{4})([0-9]{4})/gm;
        const subst = `$1-$2-$3`;

        return ph.replace(regex, subst);
    }, [])
    return (
        <>
            <AddressDialogContainer visible={visible}>
                <Dialog
                    title={AddressMod.fix ? "배송지 수정" : "배송지 선택"}
                    cancelText="닫기"
                    onCancel={onCancel}
                    dialogSize="small"
                    fullHeight
                    fixHeight
                    visible={visible}
                >
                    {AddressMod.fix === false ?
                        <div className="address_wrap custom_scroll">
                            <ul>
                                {addressList.map((list) => {
                                    return (
                                        <li key={list.id}>
                                            <p className="addr_name">{list.title}{list.is_default ? <span className="add_default">기본 배송지</span> : null}</p>
                                            <p className="addr">{list.address}<span>&nbsp;{list.detail_address}</span></p>
                                            <p className="receiver"><span>{list.name}</span><span>{list.phone ? phoneNumber(list.phone) : null}</span></p>
                                            <div className="button_wrap">
                                                <ShortWhiteButton onClick={() => setAddressMod({...AddressMod, fix: true, list: list })} outline>수정</ShortWhiteButton>
                                                <ShortWhiteButton onClick={() => deleteAddress(list.id)} outline>삭제</ShortWhiteButton>
                                                <ShortButton onClick={() => {selectAddress(list)}}>선택</ShortButton>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                            {isRegularAddress && <p className="guide">- 배송지 변경은 다음 정기배송부터 적용됩니다.</p>}
                        </div>
                        :
                        <div className="address_form_wrap custom_scroll">
                            <div className="address_form">
                                <form>
                                    <div className="row">
                                        <div className="form_title">배송지명<i className="required">*</i></div>
                                        <div className="form_cont"><input type="text" name="title" defaultValue={AddressMod.list.title} {...register("title", { required: true })} /></div>
                                    </div>
                                    <div className="row address">
                                        <div className="form_title">이름<i className="required">*</i></div>
                                        <div className="form_cont"><input type="text" name="name"  defaultValue={AddressMod.list.name} {...register("name", { required: true })} /></div>
                                    </div>
                                    <div className="row address">
                                        <div className="form_title">주소<i className="required">*</i></div>
                                        <div className="form_cont">
                                            <p><input type="text" id="Address1" name="zoneCode"  defaultValue={AddressMod.list.zipcode} {...register("zoneCode")} disabled /><Button size="small" color="white" onClick={() => {setPostCodePopup(!postCodePopup)}} outline>우편번호</Button></p>
                                            <p><input type="text" id="Address2" name="address"  defaultValue={AddressMod.list.address} {...register("address")} disabled /></p>
                                            <p><input type="text" id="Address3" name="addressDetail" defaultValue={AddressMod.list.detail_address} {...register("addressDetail")} placeholder="상세주소를 입력하세요." /></p>
                                        </div>
                                    </div>
                                    <div className="row phone">
                                        <div className="form_title">핸드폰 번호<i className="required">*</i></div>
                                        <div className="form_cont">
                                            <p>
                                                <select name="p1" {...register("p1")}  defaultValue={AddressMod.list.phone.slice(0,3)}>
                                                    <option>010</option>
                                                    <option>011</option>
                                                    <option>012</option>
                                                    <option>013</option>
                                                </select>
                                                <span>-</span>
                                                <input type="number" name="p2" defaultValue={AddressMod.list.phone.slice(3,7)} {...register("p2")} />
                                                <span>-</span>
                                                <input type="number" name="p3" defaultValue={AddressMod.list.phone.slice(7,11)} {...register("p3")} />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row email">
                                        <div className="form_title">이메일<i className="required">*</i></div>
                                        <div className="form_cont">
                                            <div>
                                                <p><input className="emailId" type="text" name="e1" defaultValue={AddressMod.list.email.split('@')[0]} {...register("e1")} /><span>@</span>
                                                    <input type="text" className="emailDomain" id="email2" defaultValue={AddressMod.list.email.split('@')[1]} name="e2" {...register("e2")} />
                                                </p>
                                                <select id="selectEmail" name="e3" onChange={(e)=>{
                                                    setValue("e2", e.target.value);
                                                }
                                                }>
                                                    <option value="">직접입력</option>
                                                    <option value="naver.com" >naver.com</option>
                                                    <option value="gmail.com">gmail.com</option>
                                                    <option value="daum.net">daum.net</option>
                                                    <option value="hanmail.net">hanmail.net</option>
                                                </select>
                                            </div>
                                            <div className="checks full_check">
                                                <input type="checkbox" name="default_address" {...register("default_address")} id="chk_adress_dafault2" />
                                                <label htmlFor="chk_adress_dafault2">기본 배송지로 설정</label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="button_wrap">
                                <Button onClick={handleSubmit(onSubmit)} fullWidth>저장하기</Button>
                            </div>
                        </div>
                    }
                </Dialog>
                <PostCode
                    setValue={setValue}
                    visible={postCodePopup}
                    setPostCodePopup={setPostCodePopup}
                    onCancel={() => setPostCodePopup(!postCodePopup)}
                />
            </AddressDialogContainer>
        </>
    );
}

const AddressDialogContainer = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  & div.dialog_wrap {
    padding-bottom: 30px;
    & h3 {
      margin-bottom: 30px;
    }
    & .dialog_inner {
      overflow: hidden;
      padding: 0 30px;
    }
    @media only screen and (max-width: 960px) {
      padding-bottom: 0;
      & h3 {
        margin-bottom: 0;
      }
      & .dialog_inner {
        padding: 0;
      }
    }
  }
  & .address_wrap {
    overflow-y: auto;
    height: calc(90vh - 143px);
    max-height: 537px;
    & ul {
      & li {
        padding: 20px;
        background: #f6f6f6;
        &:not(:first-child) {
          margin-top: 10px;
        }
        & .addr_name {
          display: flex;
          margin-bottom: 10px;
          font-size: 1.125rem;
          color: #222;
          line-height: 27px;
          font-weight: 500;
          & span {
            display: inline-block;
            margin-left: 10px;
            padding: 5px 12px 2px;
            border: 1px solid #1EA0FA;
            font-weight: 700;
            font-size: 0.75rem;
            line-height: 18px;
            border-radius: 25px;
            color: #1EA0FA;
          }
        }

        & .addr {
          margin-bottom: 6px;
          font-size: 1rem;
          line-height: 24px;
          color: #222;
        }

        & .receiver {
          & span {
            color: #888;
            font-size: 0.938rem;
            line-height: 22px;
            &:last-child {
              margin-left: 10px;
            }
          }
        }

        & .button_wrap {
          margin-top: 20px;
        }
      }
    }

    & .guide {
      margin-top: 15px;
      font-size: 0.813rem;
      line-height: 19px;
      color: #515355;
    }

    @media only screen and (max-width: 960px) {
      overflow-y: auto;
      max-height: inherit;
      padding: 30px 15px;
      height: 100%;
      max-height: 100%;

      & ul {
        & li {
          padding: 15px;
          & .addr_name {
            display: flex;
            font-size: 1rem;
            line-height: 24px;
            & span {
              display: inline-block;
              padding: 3px 10px;
            }
          }

          & .addr {
            margin-bottom: 6px;
            font-size: 0.875rem;
            line-height: 21px;
          }

          & .receiver {
            line-height: 19px;
            & span {
              font-size: 0.813rem;
              line-height: 19px;
            }
          }

          & .button_wrap {
            margin-top: 15px;
          }
        }
      }
      & .guide {
        margin-top: 10px;
      }
    }
  }

  & .address_form_wrap {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    & .address_form {
      margin: 0 0 35px 0;
      & .row {
        display: flex;
        & .form_title {
          flex-basis: 105px;
          padding-top: 11px;
        }
        & .form_cont {
          flex-basis: calc(100% - 105px);
        }

        &.address {
          margin-top: 10px;
          & input:disabled {
            color: #999;
          }
          & p:not(:first-child) {
            margin-top: 10px;
          }
          & p:first-child {
            display: flex;
            & input {
              width: calc(100% - 130px);
            }
          }
        }

        &.phone {
          margin-top: 20px;
          & .form_cont {
            & p {
              display: flex;
              align-items: center;
              & span {
                display: inline-block;
                width: 18px;
                text-align: center;
                color: #222;
              }
              & input, & select {
                flex-basis: calc((100% - 18px) / 2);
              }
            }
          }
        }

        &.email {
          margin-top: 20px;
          & .form_cont {
            & div {
              display: block !important;
              & p {
                width: 100% !important;
                & input {
                  width: calc((100% - 28px) / 2) !important;
                }
                & span {
                  display: inline-block;
                  width: 28px;
                  text-align: center;
                  color: #222;
                }
              }
              & select {
                width: 100% !important;
                margin: 10px 0 0 0 !important;
              }
            }
          }
          & .checks {
            margin-top: 20px;
          }
        }
      }
    }
    @media only screen and (max-width: 960px) {
      height: calc(100vh - 63px);
      max-height: calc(100vh - 63px);
      padding-bottom: 20px;
      & .address_form {
        margin: 5px 0 35px 0 !important;
        padding: 25px 15px 0 15px;
        & .row {
          display: block;
          & .form_title {
            width: 100% !important;
            padding-top: 0;
            margin-bottom: 10px;
            font-size: 0.938rem;
            line-height: 22px;
          }
          & .form_cont {
            width: 100% !important;
            & select, & input {
              font-size: 0.938rem;
              padding: 10px 15px;
              color: #222;
            }
          }

          &.address {
            margin-top: 20px;

            & .form_cont {
              & p:not(:first-child) {
                margin-top: 5px;
              }
              & p:first-child {
                display: flex;
                & input {
                  width: calc(100% - 115px);
                }
                & div {
                  margin-left: 5px;
                  font-size: 0.875rem;
                  font-weight: 500;
                }
              }
            }

          }

          &.phone {
            margin-top: 20px;
            & .form_cont {
              & p {
                & span {
                  width: 22px;
                }
                & input, & select {
                  width: calc((100% - 18px) / 2) !important;
                }
              }
            }
          }

          &.email {
            margin-top: 20px;
            & .form_cont {
              & div {
                display: block !important;
                & p {
                  width: 100% !important;
                  & input {
                    width: calc((100% - 28px) / 2) !important;
                  }
                  & span {
                    display: inline-block;
                    width: 28px;
                    text-align: center;
                    color: #222;
                  }
                }
                & select {
                  width: 100% !important;
                  margin-left: 0;
                  margin: 10px 0 0 0 !important;
                }
              }
            }
            & .checks {
              margin-top: 20px;
              & label {
                font-size: 0.938rem;
              }
            }
          }
        }
      }
      & .button_wrap {
        margin: 0 15px;
      }
    }
  }
`

const ShortButton = styled(Button)`
  width: 60px;
  height: 35px;
  float: right;
  font-size: 0.875rem;
  @media only screen and (max-width: 960px) {
    font-size: 0.813rem;
  }
`

const ShortWhiteButton = styled(Button)`
  width: 60px;
  height: 35px;
  color: #515355;
  background: #fff;
  font-size: 0.875rem;
  border: 1px solid #ddd;
  color: #515355;
  @media only screen and (max-width: 960px) {
    font-size: 0.813rem;
  }
`

export default AddressDialog;