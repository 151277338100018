import {
    SET_PROJECT_CATEGORY,
    PROJECT_LISTS_REQUEST,
    PROJECT_LISTS_SUCCESS,
    PROJECT_LISTS_FAIL,
    EVENT_LISTS_REQUEST,
    EVENT_LISTS_SUCCESS,
    EVENT_LISTS_FAIL,
    PROJECT_DETAILES_SUCCESS,
    PROJECT_DETAILES_FAIL,
    PROJECT_DETAILES_REQUEST,
    BOARD_LISTS_REQUEST,
    BOARD_LISTS_SUCCESS,
    BOARD_LISTS_FAIL,
    EVENT_DETAILES_REQUEST,
    EVENT_DETAILES_SUCCESS,
    EVENT_DETAILES_FAIL
} from "./types";
import axios from "axios";


//FAQ, 매뉴얼, 비디오가이드
export const setBoardLists = (name) => async (dispatch) => {
    try{
        dispatch({type: BOARD_LISTS_REQUEST});

        const res = await axios.get(
            `${process.env.REACT_APP_API_URL}api/board/list/${name}`
        );
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}api/board/list/${name}?start=0&length=${res.data.recordsTotal}`
        );
        dispatch({
            type: BOARD_LISTS_SUCCESS,
            payload: response.data
        })

    }catch(error){
        dispatch({
            type: BOARD_LISTS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
}

//건식프로젝트
export const setProjectLists = (pageStart='',pageNumber = '') => async (dispatch) => {
    try{
        dispatch({type: PROJECT_LISTS_REQUEST})

        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}api/board/list/Project?start=${pageStart}&length=${pageNumber}`)

        dispatch({
            type: PROJECT_LISTS_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: PROJECT_LISTS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};

export const setEventLists = (pageStart='', pageNumber = "") => async (dispatch) => {
    try{
        dispatch({type: EVENT_LISTS_REQUEST});
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}api/board/list/Event?start=${pageStart}&length=${pageNumber}`)

        dispatch({
            type: EVENT_LISTS_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: EVENT_LISTS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
}

export const setEventDetail = (id) => async (dispatch) => {
    try{
        dispatch({type: EVENT_DETAILES_REQUEST});
        const data = await axios.get(`${process.env.REACT_APP_API_URL}api/board/detail/Event/${id}`)
        dispatch({
            type: EVENT_DETAILES_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: EVENT_DETAILES_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const setProjectDetail = (id) => async (dispatch) => {
    try{
        dispatch({type: PROJECT_DETAILES_REQUEST});

        const data = await axios.get(`${process.env.REACT_APP_API_URL}api/board/detail/Project/${id}`)

        dispatch({
            type: PROJECT_DETAILES_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: PROJECT_DETAILES_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const setProjectCategory = () => async(dispatch) => {
    try{
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}api/board/projects_categories`)
        dispatch({
            type: SET_PROJECT_CATEGORY,
            payload: data
        })
    }catch(error) {
        console.log(error);
    }
}
