//유저 로그인/로그아웃
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_KAKAO_LOGIN_REQUEST = 'USER_KAKAO_LOGIN_REQUEST';
export const USER_KAKAO_LOGIN_SUCCESS = 'USER_KAKAO_LOGIN_SUCCESS';
export const USER_KAKAO_LOGIN_FAIL = 'USER_KAKAO_LOGIN_FAIL';

//유저 회원가입
export const USER_SIGNUP_REQUEST = 'USER_SIGNUP_REQUEST';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_FAIL = 'USER_SIGNUP_FAIL';

//우저 정보 조회
export const USER_DETAIL_REQUEST = 'USER_DETAIL_REQUEST';
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS';
export const USER_DETAIL_FAIL = 'USER_DETAIL_FAIL';
export const USER_DETAIL_RESET = 'USER_DETAIL_RESET';
export const USER_ACCESS_REQUEST = "USER_ACCESS_REQUEST";
export const DEFINE_APP_USER = "DEFINE_APP_USER";

//제품 목록
export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";
export const PRODUCT_DETAILS_RESET = "PRODUCT_DETAILS_RESET"
export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";
export const REGULAR_DETAILS_RESET = "REGULAR_DETAILS_RESET"
export const REGULAR_DETAILS_REQUEST = "REGULAR_DETAILS_REQUEST";
export const REGULAR_DETAILS_SUCCESS = "REGULAR_DETAILS_SUCCESS";
export const REGULAR_DETAILS_FAIL = "REGULAR_DETAILS_FAIL";

//정기 배송 결제
export const REGULAR_LIST_REQUEST = "REGULAR_LIST_REQUEST";
export const REGULAR_LIST_SUCCESS = "REGULAR_LIST_SUCCESS";
export const REGULAR_LIST_FAIL = "REGULAR_LIST_FAIL";

//장바구니
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_AUTH = "ADD_TO_CART_AUTH";
export const FETCH_CART_LISTS = "FETCH_CART_LISTS";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const ADDJUST_SESSION_TO_AUTH = "ADDJUST_SESSION_TO_AUTH";
export const SET_CART_NOMAL = "SET_CART_NOMAL";
export const SET_CART_REGUALAR = "SET_CART_REGUALAR";
export const ADD_CART_NOMAL = "ADD_CART_NOMAL";
export const ADD_CART_REGUALAR = "ADD_CART_REGUALAR";
export const GET_CART_LIST = "GET_CART_LIST";

//카테고리
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_CATEGORY_FILTER = "SET_CATEGORY_FILTER";
export const SET_TAG = "SET_TAG";
export const REMOVE_TAG = "REMOVE_TAG";
export const GET_MAIN_CATEGORY_ID = "GET_MAIN_CATEGORY_ID";

//옵션
export const CHECK_OPTIONS = "CHECK_OPTIONS";
export const ADD_OPTION = "ADD_OPTION";
export const REMOVE_OPTION = "REMOVE_OPTION";
export const SET_OPTION_QUANTITY = "SET_OPTION_QUANTITY";
export const CLEAR_OPTIONS = "CLEAR_OPTIONS";
export const SET_ARR_OPTIONS = "SET_ARR_OPTIONS";

//게시판
export const SET_PROJECT_CATEGORY = "SET_PROJECT_CATEGORY"
export const PROJECT_LISTS_REQUEST = "PROJECT_LISTS_REQUEST";
export const PROJECT_LISTS_SUCCESS = "PROJECT_LISTS_SUCCESS";
export const PROJECT_LISTS_FAIL = "PROJECT_LISTS_FAIL";
export const PROJECT_DETAILES_REQUEST = "PROJECT_DETAILES_REQUEST";
export const PROJECT_DETAILES_SUCCESS = "PROJECT_DETAILES_SUCCESS";
export const PROJECT_DETAILES_FAIL = "PROJECT_DETAILES_FAIL";
export const BOARD_LISTS_REQUEST = "BOARD_LISTS_REQUEST";
export const BOARD_LISTS_SUCCESS = "BOARD_LISTS_SUCCESS";
export const BOARD_LISTS_FAIL = "BOARD_LISTS_FAIL";
export const EVENT_LISTS_REQUEST = "EVENT_LISTS_REQUEST";
export const EVENT_LISTS_SUCCESS = "EVENT_LISTS_SUCCESS";
export const EVENT_LISTS_FAIL = "EVENT_LISTS_FAIL";
export const EVENT_DETAILES_REQUEST = "EVENT_DETAILES_REQUEST";
export const EVENT_DETAILES_SUCCESS = "EVENT_DETAILES_SUCCESS";
export const EVENT_DETAILES_FAIL = "EVENT_DETAILES_FAIL";

//결제
export const ADD_TO_ORDER = "ADD_TO_ORDER";
export const FETCH_ORDER_LISTS = "FETCH_ORDER_LISTS";
export const APPEND_ORDER_LISTS = "APPEND_ORDER_LISTS";
export const UPDATE_ORDER_LISTS = "UPDATE_ORDER_LISTS";
export const FETCH_ORDER_RESET = "FETCH_ORDER_RESET";
export const FETCH_ORDER_DETAILS = "FETCH_ORDER_DETAILS";
export const RESET_ORDER_DETAILS = "RESET_ORDER_DETAILS";
export const FETCH_ORDER_REG_LISTS = "FETCH_ORDER_REG_LISTS";
export const APPEND_ORDER_REG_LISTS = "APPEND_ORDER_REG_LISTS";
export const UPDATE_ORDER_REG_LISTS = "UPDATE_ORDER_REG_LISTS";

//리뷰
export const FETCH_REVIEW_ALL = "FETCH_REVIEW_ALL";
export const ADD_TO_REVIEW = "ADD_TO_REVIEW";
export const DELETE_REVIEW = "DELETE_REVIEW";
export const FETCH_REVIEW = "FETCH_REVIEW";
export const FETCH_REVIEW_REQUEST = "FETCH_REVIEW_REQUEST";
export const FETCH_REVIEW_SUCCESS = "FETCH_REVIEW_SUCCESS";
export const FETCH_REVIEW_FAIL = "FETCH_REVIEW_FAIL";

//배너
export const FETCH_BANNER = "FETCH_BANNER";
