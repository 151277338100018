import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'
import 'antd/dist/antd.css'
import icon_coolicon from 'images/icon/icon_coolicon.png'
import m_icon_coolicon from 'images/m/icon_coolicon.png'
import {BsDot} from 'react-icons/bs'
import 'styles/GlobalStyles.scss'
import Rate from 'rc-rate'
import {CgClose} from 'react-icons/cg'
import {MdKeyboardArrowDown} from 'react-icons/md'
import {MdKeyboardArrowUp} from 'react-icons/md'
import {useMediaQuery} from 'react-responsive'
import MyPageReviewPopup from 'pages/components/MyPageReviewPopup'
import list_open from 'images/m/m_list_open.png'
import list_close from 'images/m/m_list_close.png'
import {stringPrice} from 'utils/StringPrice'
import Pagination2 from '../../components/Pagination2'
import axios from 'axios'
import Dialog from '../../components/Dialog'
import default_thumb from "images/default_thumb.png";


const MyReviewAdmin = () => {
    const isMobile = useMediaQuery({query: '(max-width: 960px)'})
    const userInfo = useSelector((state) => state.userLogin.userInfo || '')
    let [reviews, setReviews] = useState([])
    const [total, setTotal] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [postPerPage, setPostPerPage] = useState(10)
    const history = useHistory()
    const LIMIT = 10
    const firstPage = 1
    const lastPage = Math.ceil(total / LIMIT)
    const handleImgError = (e) => {
        e.target.src = default_thumb;
    }
    const getThumbnail = (url, type) =>{
        if (url === null) { return }
        if(type === 'video'){
            let thumbnail = url.replace('/review', '/review-thumbnail')
            return thumbnail.substring(0, thumbnail.lastIndexOf(".")) + ".jpg";
        }else{
            return url.replace('/review', '/review-thumbnail');
        }
    }

    const fetchReviews = async (page) => {
        if (!userInfo.data) {
            history.push('/')
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
            },
        }
        const res = await axios.get(
            `${process.env.REACT_APP_API_URL}api/review/user/all?start=${(currentPage - 1) * 10}&length=${LIMIT}`, config)
        for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].Order) {
                if (typeof res.data.data[i].Order.option === 'string') {
                    res.data.data[i].Order.option = JSON.parse(res.data.data[i].Order.option)
                    res.data.data[i].Order.product = JSON.parse(res.data.data[i].Order.product)
                }
            }
        }

        setReviews(res.data.data)
        setTotal(res.data.total)
    }
    useEffect(() => {
        fetchReviews(currentPage)
    }, [currentPage, setReviews])

    const [dialog, setDialog] = useState({
        open: false,
        cancelText: '',
        dialogContent: '',
        id: '',
    })

    const onCancel = () => {
        setDialog({...dialog, open: false})
    }

    const onConfirm = async (id) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "cid" : userInfo.data.cid,
                    "encoded" : userInfo.data.encoded,
                },
            }
            const res = await axios.delete(
                `${process.env.REACT_APP_API_URL}api/review/${id}`, config)
            fetchReviews(currentPage)
            setDialog({...dialog, open: false})
        } catch (error) {
            console.log(error)
        }
    }

    const reviewDelete = async (id) => {
        let str = '구매평은 1회 작성 가능하며 \n 삭제시 재작성이 불가합니다'
        setDialog({
            ...dialog,
            open: true,
            cancelText: '취소',
            confirmText: '확인',
            dialogContent: str,
            id: id,
        })
    }

    const [WriteReview, setWriteReview] = useState(false)
    const [data, setData] = useState({})
    const closeWriteReview = () => {
        setWriteReview(false)
    }

    const [list, setList] = useState(true)
    const [myTextReview, setMyTextReview] = useState(true)
    const [myPhotoReview, setMyPhotoReview] = useState(true)
    const [reply, setReply] = useState([])

    const [Mobile, setMobile] = useState(false)
    const resizingHandler = () => {
        if (window.innerWidth > 960) {
            setMobile(false)
        } else {
            setMobile(true)
        }
    }

    // 팝업, 햄버거메뉴 오픈 시 body스크롤 고정
    useEffect(() => {
        if (window.innerWidth > 960) {
            setMobile(false)
        } else {
            setMobile(true)
        }
        if (Mobile) {
            function handleTouchMove(event) {
                if (WriteReview) {
                    event.preventDefault()
                }
            }

            function disableScroll() {
                if (WriteReview) {
                    document.body.style.overflowY = 'hidden'
                    document.body.style.overflowX = 'visible'
                    document.querySelector('html').scrollTop = window.scrollY // dimmed 되기 전 스크롤 위치 고정
                }
            }

            window.addEventListener('scroll', disableScroll)
            window.addEventListener('resize', resizingHandler)

            return () => {
                window.removeEventListener('scroll', disableScroll)
                document.body.style.overflow = 'visible'
                // 메모리 누수를 줄이기 위한 removeEvent
                window.removeEventListener('resize', resizingHandler)
            }
        } else {
            window.addEventListener('resize', resizingHandler)
            return () => {
                // 메모리 누수를 줄이기 위한 removeEvent
                window.removeEventListener('resize', resizingHandler)
            }
        }
    }, [WriteReview, Mobile])

    const [getReview, setGetReview] = useState(false)
    const [reviewed, setReviewed] = useState(false)

    const openModReview = (orderId, item, review) => {
        setGetReview(true)

        setData({orderId, item, review})
    }

    const closeMyReview = (result) => {
        setGetReview(false)
        fetchReviews(currentPage)
    }

    const indexOfLastPosts = currentPage * postPerPage
    const indexOfFirstPosts = indexOfLastPosts - postPerPage

    const getImage = (review) => {
        if (review?.Product) {
            return review.Product.main_img
        }
        if (review?.RegularProduct) {
            return review.RegularProduct.main_img
        }
    }
    const getName = (review) => {
        if (review?.Product) {
            return review.Product.name
        }
        if (review?.RegularProduct) {
            return review.RegularProduct.name
        }
    }

    const getOptionPrice = (review) => {
        if (review?.Order) {
            try {
                return review.Order.option[0].map((option, idx) => {
                    return
                    (<React.Fragment key={idx}>
                        <b>{stringPrice(option.price)}</b>
                        원 /
                        <b>{option.quantity})개</b>
                    </React.Fragment>)
                })
            } catch (e) {

            }
        }
    }

    const getOption = (review) => {
        if (!review.Order) {return null}
        const productidx = review.Order.product.findIndex(v => v.id === review.ProductId);
        const optionGroups = review.Order.product[productidx].OptionGroups

        const reviewoptions = review.Order.option.filter(v => v.findIndex(vj => vj.id === review.cart_option_id) !== -1)
        if (review?.Order) {
            try {
                return reviewoptions.map((option, idx) => {
                    return (
                        <React.Fragment key={idx}>
                            {option.map((v, idx) => {
                                const optdata = optionGroups.filter(vj => {
                                    return vj.Options.findIndex(vk=> {
                                        return vk.id === v.OptionId
                                    }) !== -1
                                })
                                if (optdata.length > 0) {
                                    return (
                                        <React.Fragment key={idx}>

                                            <span>[{optdata[0].name}] {v.name}  / {v.quantity} 개</span>
                                            <br/>
                                        </React.Fragment>
                                    )
                                } else {
                                    return (
                                        <React.Fragment key={idx}>

                                            <span>[옵션] {v.name} / {v.quantity} 개</span>
                                            <br/>
                                        </React.Fragment>
                                    )
                                }

                            })}
                        </React.Fragment>
                    )
                })
            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <MyReviewAdminContainer>
            <div className="mypage_title">
                <h2>나의 후기 관리</h2>
            </div>
            <div className="myreview_list">
                <ul>
                    {
                        reviews ?
                            reviews.length !== 0 ?
                                reviews.filter((e) => {
                                    return e.user_delete !== true
                                }).map((el, idx) => {
                                    return <li key={idx}>
                                        <p className="review_util">
                                                <span className="mod_review"
                                                      onClick={() => {
                                                          if (el.RegularProductId) {
                                                              openModReview(el.RegularOrderId, el.RegularProduct, el)
                                                          } else {
                                                              openModReview(el.OrderId, el.Product, el)
                                                          }
                                                      }}>수정</span>
                                            <button onClick={() => {
                                                reviewDelete(el.id)
                                            }}><CgClose/></button>
                                        </p>
                                        <div className="item_info_wrap">
                                            <div className="item_img"><img
                                                src={getImage(el)}
                                                alt="제품이미지"/></div>
                                            <div className="item_info">
                                                <p className="item_name">{getName(el)}</p>

                                                <p className="option">{getOption(el)}</p>
                                                <p className="price">{getOptionPrice(el)}</p>
                                            </div>
                                        </div>
                                        <div className="myreview">
                                            <div className="rate">
                                                <StyledRate disabled size="18" value={el.point}/>
                                            </div>
                                            <p className={
                                                myTextReview ?
                                                    'review_text hide' :
                                                    'review_text'
                                            }>
                                                {el.content}
                                            </p>
                                            <div className={
                                                myPhotoReview ?
                                                    'review_photo_wrap hide' :
                                                    'review_photo_wrap'
                                            }>
                                    <span className="review_more" onClick={() => setMyTextReview(!myTextReview)}>
                                        {
                                            el.content ?
                                                el.content.length > 213 ?
                                                    myTextReview ?
                                                        <>더보기<MdKeyboardArrowDown/></> :
                                                        <>접기<MdKeyboardArrowUp/></> :
                                                    null :
                                                null
                                        }
                                    </span>
                                                <div
                                                    className="review_photo">
                                                    {el.Files?.map(
                                                        (file, idx) => {
                                                            let limit = 5;
                                                            if (isMobile) {
                                                                limit = 3;
                                                            }
                                                            if (idx >= limit) { // 6개 이상부터 무시
                                                                return null
                                                            }
                                                            if (file.mimetype.indexOf('image') > -1) {
                                                                return (
                                                                    <div key={idx} className="review_img" onClick={() =>
                                                                        setMyPhotoReview(!myPhotoReview)}>
                                                                        <img src={getThumbnail(file.publicPath, 'image')} alt="제품이미지" onError={handleImgError}/>
                                                                        {
                                                                            idx === limit - 1 ?
                                                                                el.Files.length - limit === 0 ?
                                                                                    null :
                                                                                    <div className="gray_back">+{el.Files.length - limit} </div> :
                                                                                null
                                                                        }
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div
                                                                        key={idx}
                                                                        className="review_img"
                                                                        onClick={() => setMyPhotoReview(
                                                                            !myPhotoReview)}>
                                                                        <img src={getThumbnail(file.publicPath, 'video')} alt="제품이미지" onError={handleImgError}/>
                                                                        {
                                                                            idx === limit - 1 ?
                                                                                el.Files.length - limit === 0 ?
                                                                                    null :
                                                                                    <div className="gray_back">+{el.Files.length - limit} </div> :
                                                                                null
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            {
                                                el.admin_reply ?
                                                    <div className={reply.findIndex(v => v === el.id) >= 0 ? 'reply' : 'reply hide'}>
                                                        <p>고객만족팀</p>
                                                        <p onClick={() => {
                                                            const idx = reply.findIndex(v => v === el.id);
                                                            if (idx >= 0) {
                                                                setReply(prevState => prevState.filter(v => v !== el.id));
                                                            } else {
                                                                setReply(prevState => [...prevState, el.id]);
                                                            }
                                                        }}>
                                                            {el.admin_reply}
                                                        </p>
                                                        {
                                                            isMobile && <span className="review_more" onClick={() => {
                                                                const idx = reply.findIndex(v => v === el.id);
                                                                if (idx >= 0) {
                                                                    setReply(prevState => prevState.filter(v => v !== el.id));
                                                                } else {
                                                                    setReply(prevState => [...prevState, el.id]);
                                                                }
                                                            }}>
                                                            {
                                                                el.content ?
                                                                    el.content.length < 50 ?
                                                                        myTextReview ?
                                                                            <>더보기<MdKeyboardArrowDown/></> :
                                                                            <>접기<MdKeyboardArrowUp/></> :
                                                                        null :
                                                                    null
                                                            }
                                                        </span>
                                                        }
                                                    </div>
                                                    : null
                                            }

                                        </div>
                                    </li>
                                }) : <p className="noList">작성한 후기가 없습니다.</p>
                            : <p className="noList">작성한 후기가 없습니다.</p>}
                </ul>
            </div>
            <ul className="pagination_ul">
                {reviews && reviews.length !== 0 && (
                    <Pagination2
                        firstPage={firstPage}
                        lastPage={lastPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalData={total}
                        LIMIT={LIMIT}
                    />
                )}
            </ul>
            <div className="mypage_guide">
                <p><span><BsDot/></span><span>후기 작성 시 사진후기 1,000원 일반 후기 500원을 적립해드립니다.</span>
                </p>
                <p>
                    <span><BsDot/></span><span>BEST 후기로 선정 시 소정의 사은품을 지급해 드립니다.</span>
                </p>
                <p><span><BsDot/></span><span>지난 1년간의 주문 내역 조회가 가능합니다.</span>
                </p>
                <p>
                    <span><BsDot/></span><span>후기 작성은 배송 완료일로부터 30일 이내 작성이 가능합니다.</span>
                </p>
            </div>
            {getReview &&
                <MyPageReviewPopup visible={getReview} product={data}
                                   onCancel={closeMyReview}/>
            }
            <Dialog
                confirmText={`${dialog.confirmText}`}
                cancelText={`${dialog.cancelText}`}
                onConfirm={() => {
                    onConfirm(dialog.id)
                }}
                onCancel={onCancel}
                dialogSize="small"
                visible={dialog.open}
                className="cartLayer"
            >
                <p style={{whiteSpace: 'pre-wrap'}}>{dialog.dialogContent}</p>
            </Dialog>
        </MyReviewAdminContainer>
    )
}

const OrderListContent = styled.li`
  padding: 0 25px;

  &:not(:last-child) {
    & .order_list_content_in {
      border-bottom: 1px solid #E1E2E3;
    }
  }

  & .order_list_content_in {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;

    & .order_list_content_img {
      flex-basis: 50%;
      display: flex;
      align-items: center;

      img {
        display: inline-block;
        box-sizing: border-box;
        width: 80px;
        height: 80px;
        margin-right: 30px;
      }

      & .order_list_content_p {
        font-size: 14px;
        color: #515355;

        p:not(:first-child) {
          margin-top: 4px;
        }

        p.event_product {
          font-size: 13px;
          color: #1EA0FA;
          line-height: 19px;
        }

        p.order_title {
          font-size: 16px;
          color: #222;
          margin-top: 2px;
          line-height: 24px;
        }

        p.order_option {
          font-size: 14px;
          line-height: 21px;
          color: #515355;
        }

        p.order_price {
          font-size: 14px;
          line-height: 21px;
          color: #515355;

          & b {
            font-weight: 500;
          }
        }
      }
    }

    & .order_control_btn {
      flex-basis: 100px;

      & .control_btn {
        width: 100px;
        height: 30px;
        font-size: 13px;
        color: #222;
        margin: 2px 0;
        line-height: 19px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 40px;
      }

      & .control_btn.order_review {
        border: 1px solid #1EA0FA;
        color: #1EA0FA;
      }

      & .left_day {
        margin-top: 5px;
        font-size: 13px;
        line-height: 19px;
        color: #515355;
        text-align: center;
      }
    }
  }

  &:last-child > .order_list_content_in {
    border-bottom: 0;
  }

  @media only screen and (max-width: 960px) {
    display: ${({list}) => list ? 'block' : 'none'};
    padding: 0 15px;
    border-bottom: 1px solid #F0F0F0;

    & .order_list_content_in {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border: none !important;
      margin-bottom: 0;
      padding: 20px 0;

      &:first-child {
        padding-top: 12px;
      }

      & .order_list_content_img {
        width: 100%;
        flex-basis: 100%;
        display: flex;
        align-items: center;

        & .item_img {
          width: 80px;
          margin-right: 10px;

          img {
            display: inline-block;
            box-sizing: border-box;
            width: 100%;
          }
        }


        & .order_list_content_p {
          p:not(:first-child) {
            margin-top: 4px;
          }

          p.event_product {
            color: #1EA0FA;
            font-size: 0.813rem;
            line-height: 22px;
          }

          p.order_title {
            font-size: 1rem;
            color: #222;
            line-height: 22px;
          }

          p.order_option {
            color: #515355;
            font-size: 0.813rem;
            line-height: 19px;
          }

          p.order_price {
            color: #222;
            font-size: 0.813rem;
            line-height: 19px;

            & b {
              font-weight: 500;
            }
          }
        }
      }

      & .order_state {
        display: none;
      }

      & .order_control_btn {
        display: flex;
        flex-direction: row-reverse;
        flex-basis: 100% !important;
        margin-left: auto;
        align-items: center;

        & .control_btn {
          width: 95px;
          height: 32px;
          font-size: 0.813rem;
          color: #222;
          margin: 0;
          border: 1px solid #ccc;
          box-sizing: border-box;
          border-radius: 40px;
        }

        & .control_btn.order_review {
          border: 1px solid #1EA0FA;
          color: #1EA0FA;
        }

        & .left_day {
          margin-top: 0 !important;
          margin-right: 10px;
          font-size: 0.75rem;
          line-height: 19px;
          text-align: right;
        }
      }

    }

    &:last-child > .order_list_content_in {
      border-bottom: 0;
    }
  }
`

const MyReviewAdminContainer = styled.div`
  & .noList {
    font-weight: 400;
    color: #888;
    @media only screen and (max-width: 960px) {
      padding: 170px 0;
      border-bottom: 0;
      background: #fff;
    }
  }

  & .cartLayer {
    .dialog_wrap {
      overflow-y: initial;
      width: auto;

      & p {
        text-align: center;
      }
    }
  }

  & .reviewed_type {
    display: flex;
    border: 1px solid #ddd;

    & li {
      position: relative;
      flex-basis: 50%;

      & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        cursor: pointer;
      }

      & span {
        display: block;
        font-size: 18px;
      }

      &.active {
        border-bottom: 2px solid #1EA0FA;

        & span {
          color: #1EA0FA;
        }
      }

      &::after {
        position: absolute;
        content: "";
        top: 19px;
        right: 0;
        width: 1px;
        height: 26px;
        background: #E1E2E3;
      }
    }
  }


  @media only screen and (max-width: 960px) {
    & .reviewed_type {
      & li {
        border-bottom: 2px solid #EEF1F3;

        & div {
          height: 60px;
        }

        & span {
          font-size: 14px;
        }

        &::after {
          content: none;
        }
      }
    }
  }

  & .ant-tabs-nav {
    margin-bottom: 0;
    border: 1px solid #DDD;

    &::before {
      border-bottom: 0 !important;
    }

    & .ant-tabs-nav-list {
      width: 100%;
    }

    & .ant-tabs-tab {
      padding: 20px 0 !important;
      width: 50%;
      justify-content: center;
      margin: 0;

      & div {
        font-size: 17px;
        line-height: 25px;
        color: #222;
      }

      &.ant-tabs-tab-active {
        & div {
          color: #1EA0FA;
          font-weight: 500;
        }
      }
    }

    @media only screen and (max-width: 960px) {
      border: none;
      border-bottom: 2px solid #EEF1F3;

      & .ant-tabs-tab {
        & div {
          font-size: 0.875rem;
          line-height: 21px;
        }
      }
    }
  }

  & .order_lists {
    & ul > li.order_lists_list {
      margin-top: 30px;
      box-sizing: border-box;

      ul {
        border: 1px solid #ccc;
      }

      p.order_lists_date {
        font-size: 18px;
        color: #515355;
        margin-bottom: 17px;
      }

      & .order_lists_number {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        background-color: #F7F7F7;
        padding: 15px 25px 15px 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;

        & h4 {
          font-size: 16px;
          font-weight: 400;
          color: #515355;
          line-height: 24px;

          & span {
            margin-left: 5px;
            color: #222;
            font-weight: 500;
          }
        }

        & svg {
          margin-top: 2px;
          font-size: 23px;
        }
      }
    }

    @media only screen and (max-width: 960px) {
      background: #f6f6f6;
      padding-top: 6px;

      & ul > li.order_lists_list {
        margin: 0;

        &:not(:first-child) {
          margin-top: 8px;
        }

        &:last-child {
          & ul {
            margin-bottom: 0;
          }
        }

        background: #fff;

        ul {
          border: none;

          &:not(:first-child) {
            margin-top: 0;
          }
        }

        p.order_lists_date {
          display: none;
        }

        & .order_lists_number {
          background-color: #fff;
          padding: 15px 18px;
          box-sizing: border-box;
          border-bottom: none;
          margin-bottom: 0;
          background: url(${list_open}) no-repeat right 15px center;
          background-size: 12px auto;

          &.active {
            background: url(${list_close}) no-repeat right 15px center;
            background-size: 12px auto;
          }

          & h4 {
            display: flex;
            align-items: center;
            font-size: 1.125rem;
            font-weight: 500;
            line-height: 27px;
            color: #222;

            & span {
              margin-left: 8px;
              font-size: 0.938rem;
              color: #515355;
            }
          }

          & a {
            display: none;
          }
        }
      }
    }
  }

  & .myreview_list {
    & > ul {
      & > li {
        position: relative;
        margin-top: 30px;
        padding: 25px 30px;
        border: 1px solid #DDD;

        & .review_util {
          display: flex;
          align-items: center;
          position: absolute;
          right: 25px;
          top: 25px;

          & span {
            margin-right: 18px;
            font-size: 14px;
            line-height: 21px;
            color: #515355;
            text-decoration: underline;
            cursor: pointer;
          }

          & button {
            & svg {
              width: 20px;
              height: 20px;
              vertical-align: middle;
              margin-top: -1px;
            }
          }
        }

        & .item_info_wrap {
          display: flex;
          align-items: center;
          padding-bottom: 25px;
          margin-bottom: 20px;
          border-bottom: 1px solid #E1E2E3;

          & .item_img {
            width: 80px;
            margin-right: 30px;

            & img {
              width: 100%;
            }
          }

          & .item_info {
            & .item_name {
              margin-bottom: 4px;
              font-size: 1rem;
              line-height: 24px;
            }

            & .option {
              font-size: 0.875rem;
              line-height: 21px;
              color: #555;
            }

            & .gift {
              margin-bottom: 4px;
              font-size: 0.875rem;
              line-height: 21px;
              color: #1EA0FA;
            }

            & .price {
              font-size: 0.875rem;
              line-height: 21px;
              color: #555;

              & b {
                font-weight: 500;
              }
            }
          }
        }

        & .myreview {
          & .review_text {
            margin: 14px 0 12px;
            font-size: 0.938rem;
            color: #222;
            line-height: 1.375rem;
            word-break: break-all;

            &.hide {
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              max-height: 2.75rem;
              -webkit-box-orient: vertical;
            }
          }

          & .review_photo_wrap {
            position: relative;

            & .review_more {
              position: absolute;
              right: 0;
              top: 0;
              color: #515355;
              font-size: 0.813rem;
              line-height: 19px;
              cursor: pointer;

              & svg {
                margin-left: 5px;
                font-size: 18px;
                vertical-align: middle;
              }
            }

            & .review_photo {
              display: flex;

              & > div {
                display: inline-block;
                overflow: hidden;
                position: relative;
                width: 50px;
                height: 50px;

                &:not(:first-child) {
                  margin-left: 5px;
                }

                & img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }

                & .gray_back {
                  display: none;
                  position: absolute;
                  justify-content: center;
                  align-items: center;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background: rgba(0, 0, 0, 0.3);
                  color: #fff;
                  font-size: 1rem;
                  font-weight: 700;
                  cursor: pointer;
                }
              }
            }

            & .review_photo {
              & > div {
                display: none;

                & .gray_back {
                  display: none;
                }

                &:nth-child(-n+5) {
                  display: inline-block;
                }

                &:nth-child(5) {
                  & .gray_back {
                    display: inline-flex;
                  }
                }
              }
            }

            &.hide {
              & .review_more {
                display: inline-block;
              }
            }
          }
        }

        & .reply {
          margin-top: 20px;
          padding: 20px;
          background: #F7F7F7;

          & p:first-child {
            margin-bottom: 7px;
            padding-left: 20px;
            background: url(${icon_coolicon}) no-repeat left top 4px;
            font-size: 0.938rem;
            color: #222;
            line-height: 22px;
            font-weight: 700;
          }

          & p:last-child {
            font-size: 0.875rem;
            color: #222;
            line-height: 21px;
          }
        }
      }
    }

    @media only screen and (max-width: 960px) {
      & > ul {
        background: #f6f6f6;
        padding: 6px 0 8px;

        & > li {
          margin-top: 0;
          background: #fff;
          padding: 54px 15px 30px;
          border: none;

          &:not(:first-child) {
            margin-top: 8px;
          }

          & .review_util {
            right: 15px;
            top: 23px;

            & span {
              font-size: 0.813rem;;
              line-height: 19px;
            }
          }

          & .item_info_wrap {
            display: flex;
            align-items: flex-start;
            padding-bottom: 25px;
            margin-bottom: 20px;
            border-bottom: 1px solid #E1E2E3;

            & .item_img {
              flex: 1 1 80px;
              margin-right: 10px;

              & img {
                width: 100%;
              }
            }

            & .item_info {
              width: calc(100% - 90px);

              & .item_name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 22px;
              }

              & .option {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 0.813rem;
                line-height: 19px;
                color: #515355;
              }

              & .gift {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 0.813rem;
                line-height: 19px;
              }

              & .price {
                font-size: 0.813rem;
                line-height: 19px;
                color: #222;

                & b {
                  font-weight: 500;
                }
              }
            }
          }

          & .myreview {
            & .review_text {
              margin: 10px 0 5px;

              &.hide {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 4;
                max-height: 5.5rem;
                -webkit-box-orient: vertical;
              }
            }

            & .review_photo_wrap {
              padding-top: 15px;

              & .review_photo {
                flex-wrap: wrap;

                & > div {
                  width: 80px;
                  height: 80px;
                  margin: 5px 0;

                  &:not(:first-child) {
                    margin-left: 0;
                  }

                  &:not(:last-child) {
                    margin-right: 10px;
                  }

                  & .gray_back {
                    display: none;
                    font-size: 1.25rem;
                    font-weight: 700;
                  }

                  &:nth-child(-n+5) {
                    display: none;
                  }

                  &:nth-child(-n+3) {
                    display: inline-block;
                  }

                  &:nth-child(5) {
                    & .gray_back {
                      display: none;
                    }
                  }
                }
              }

              &.hide {
                & .review_photo {
                  & > div {
                    &:nth-child(-n+5) {
                      display: none;
                    }

                    &:nth-child(-n+3) {
                      display: inline-block;
                    }

                    &:nth-child(5) {
                      & .gray_back {
                        display: none;
                      }
                    }

                    &:nth-child(3) {
                      & .gray_back {
                        display: inline-flex;
                      }
                    }
                  }
                }
              }
            }
          }

          & .reply {
            margin-top: 15px;

            & p:first-child {
              background: url(${m_icon_coolicon}) no-repeat left top 4px;
              background-size: 11px;
            }

            & p:nth-child(2) {
              font-size: 0.875rem;
              line-height: 21px;
              color: #222;
            }

            & .review_more {
              display: none;
            }

            &.hide {
              & p:nth-child(2) {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              & .review_more {
                display: block;
                text-align: right;
                margin-top: 5px;
                text-align: right;
                color: #515355;
                font-size: 0.813rem;
                line-height: 19px;

                & svg {
                  margin-left: 5px;
                  font-size: 18px;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }

  & .pagination_ul {
    @media only screen and (max-width: 960px) {
      & nav {
        margin: 30px 0;
      }
    }
  }
`

const StyledRate = styled(Rate)`
  &.rc-rate {
    font-size: ${({size}) => size}px;

    & .rc-rate-star {
      margin-right: 3px;
      @media only screen and (max-width: 960px) {
        margin: 0 !important;
      }
    }
  }

  & .rc-rate-star-half .rc-rate-star-first, .rc-rate-star-full .rc-rate-star-second {
    color: #1EA0FA;
  }
`

export default MyReviewAdmin