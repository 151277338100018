import * as React from 'react';
import styled from "styled-components";

export const TabSelector = ({isActive, children, onClick,}) => (
    <StyledButton className={`${isActive ? 'active' : 'disabled'} ${children}`} onClick={onClick}>
        {children}
    </StyledButton>
);

const StyledButton = styled.button`
 

`