import React from "react";
import styled from "styled-components";
import 'rc-rate/assets/index.css';
import Dialog from './Dialog';
import PrivacyTerms from "./PrivacyTerms";

function PrivacyTermsPopup({ visible, onCancel }) {
    return (
        <PrivacyDiaglog visible={visible}>
            <Dialog
                title="[개인정보처리방침]"
                sub_title=""
                cancelText="닫기"
                onCancel={onCancel}
                fullHeight
                fixHeight
                visible={visible}
            >
                <PrivacyTerms />
            </Dialog>
        </PrivacyDiaglog>
    );
}

const PrivacyDiaglog = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};

  & .dialog_wrap {
    & h3 {
      margin-bottom: 30px;
    }
    & .dialog_inner {
      max-height: calc(90vh - 113px);
      padding: 0 30px 30px 30px;
    }
    & .cont_wrap {
      padding: 0;
    }
    & .tit_wrap {
      margin-top: 40px;
      & p:last-child {
        font-size: 0.875rem;
        &::before {
          content: none;
        } 
      }
    }
    & .cont_wrap2 {
      padding-top: 20px;
    }
  }
  
  @media only screen and (max-width: 960px) {
    & .dialog_wrap {
      & h3 {
        margin-bottom: 0;
        text-align: left;
      }
      & .dialog_inner {
        padding: 25px 15px;
        max-height: calc(100vh - 60px);
      }
      & .tit_wrap {
        margin-top: 50px;
        & p:first-child {
          font-weight: 500;
        }    
        & p:last-child {
          display: flex;
          position: relative;
          align-items: center;
          padding-left: 15px;
          margin-left: auto;
          color: #515355;
          font-size: 0.813rem;
          & svg {
            margin-left: 5px;
          }
          &::before {
            content: none;
          }
        }
      }
    }
  }
`

export default PrivacyTermsPopup;