import React, {useEffect, useState} from "react";
import styled from "styled-components";
import point from "images/m/ico_point.png";
import coupon from "images/m/ico_coupon.png";
import { MdNavigateNext } from "react-icons/md"
import { Link, useHistory } from "react-router-dom";
import {logout} from "../../actions/userActions";
import arr_my_nav from "images/m/arr_my_nav.png";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Moment from "moment";
import {stringPrice} from "../../utils/StringPrice";
import {USER_LOGOUT} from "../../actions/types";

const MyPageMo = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userInfo = useSelector((state) => state.userLogin.userInfo);
    const [checkUser, setCheckUser] = useState({})

    useEffect(()=>{
        try{
            const fetchProfile = async () => {
                if (!userInfo) {
                    history.push("/")
                }
                if (!userInfo.data) {
                    history.push("/")
                }
                const config={
                    headers: {
                        'Content-Type': 'application/json',
                        "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
                    },
                }
                try{
                    const res = await axios.get(`${process.env.REACT_APP_API_URL}api/user/info`, config);
                    setCheckUser(res.data)
                } catch(error){
                    localStorage.removeItem('userInfo');
                    dispatch({type: USER_LOGOUT});
                    history.push("/Login")
                }
            }
            fetchProfile()
        } catch (e) {
            history.push("/")
        }
    },[setCheckUser])


    const checkUserCoupon = () => {
        const couponArr = checkUser?.coupon?.filter((coupon) => {
            const now = Moment().format('LLL')
            const start_time = Moment(coupon?.Coupon?.start_date, "YYYY-MM-DD").format('LLL');
            const end_time = Moment(coupon?.Coupon?.end_date, "YYYY-MM-DD").format('LLL');

            const isShowing = Moment(now).isBetween(start_time, end_time)
            return isShowing
        })

        let arr = []
        for(let i = 0; i < couponArr?.length; i++){
            if(couponArr[i].Coupon !== null){
                arr.push(couponArr[i])
            }
        }
        return arr
    }

    const handleLogout = () => {
        try {
            dispatch(logout(checkUser?.additional_info?.user_id));
            document.location.href = "/"
        } catch (error) {
            return console.log(error)
        }
    };

  return (
    <MypageContainer>
      <div className="wrap_title mypage_title">
        <h2>마이페이지</h2>
      </div>
      <div className="mypage_userInfo_wrap">
        <div className="mypage_userInfo">
          <p><b>{checkUser?.data?.user_name}</b>님, 안녕하세요.</p>
          <p><Link to="/mypage/fund"><span><img src={point} alt="알갱이" />나의 알갱이</span> <MdNavigateNext color="#515355" /></Link><span className="investigate"><b>{stringPrice(checkUser?.balance?.data?.balance_point)}</b></span></p>
          <p><Link to="/mypage/coupon"><span><img src={coupon} alt="나의쿠폰" />나의 쿠폰</span> <MdNavigateNext color="#515355" /></Link><span className="coupon_amount"><b>{checkUserCoupon().length}</b>개</span></p>
        </div>
      </div>
      <div className="mypage_nav">
        <ul>
          <li><Link to="/mypage/myorders" activeClassName="current">주문 내역</Link></li>
          {/* 독립몰 serect 정기배송 관련 hidden 처리 */}
          {/* <li><Link to="/mypage/delivery" activeClassName="current">나의 정기배송</Link></li>
          <li><Link to="/mypage/reviewadmin" activeClassName="current">나의 후기관리</Link></li> */}
          <li><Link to="/mypage/fund" activeClassName="current">나의 알갱이</Link></li>
          <li><Link to="/mypage/coupon" activeClassName="current">나의 쿠폰</Link></li>
          <li><Link to="/mypage/mypets" activeClassName="current">우리 아이 정보</Link></li>
          <li><Link to="/mypage/userinfofix" activeClassName="current">회원정보 수정</Link></li>
        </ul>
        <ul>
          <li><Link to="/" onClick={handleLogout}>로그아웃</Link></li>
        </ul>
      </div>
    </MypageContainer>
  );
};

const MypageContainer = styled.div`
  background: #f6f6f6;

    & .mypage_title {
      background: #fff;
    }

    & .mypage_userInfo_wrap {
      padding: 0 15px 20px 15px;
      margin-bottom: 8px;
      background: #fff;
      & .mypage_userInfo {
        margin: 0 auto;
        padding: 25px;
        border-radius: 5px;
        background: linear-gradient(90deg, #DFF0F7 -1.59%, #E6F2F7 101.11%);
        & p:first-child {
          margin-bottom: 14px;
          font-size: 1.313rem;
          line-height: 31px;
          color: #222;
          font-weight: 300;
          & b {
            font-weight: 500;
          }
        }
        & p:not(:first-child) {
          display: flex;
          align-items: center;
          &:last-child {
            margin-top: 2px;
          }
          & img {
            width: 18px;
            margin-right: 5px;
            vertical-align: middle;
            margin-top: 2px;
          }
          & svg {
            vertical-align: middle;
          }
          & span {
            display: flex;
            align-items: center;
            &:first-child {
              min-width: 93px;
              font-size: 0.875rem;
              line-height: 21px;
              color: #515355;

            }
            &:last-child {
              align-items: baseline;
              margin-left: auto;
              font-size: 1rem;
              line-height: 24px;
              color: #0071BF;
              & b {
                margin-right: 5px;
                font-size: 1.5rem;
                line-height: 36px;
                font-weight: 500;
              }
            }
          }
        }
        & a {
          display: flex;
          align-items: center;
        }
      }
    }


    & .mypage_nav {
      & ul {
        background: #fff;
        &:not(:first-child) {
          margin-top: 8px;
        }
        & li {
          border-bottom: 1px solid #F0F0F0;
          & a {
            display: block;
            padding: 15px 20px;
            background: url(${arr_my_nav}) no-repeat top 20px right 15px;
            background-size: 8px 14px;
            font-size: 1.063rem;
            color: #222;
            line-height: 25px;
          }
        }
      }
    }

`


export default MyPageMo;
