import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";
import { useForm } from "react-hook-form";
import {useHistory} from "react-router-dom";
import {getUserInfo} from "actions/userActions";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import Button from './components/Button';
import arr_more from "images/arr_more.png";
import sublist_open from "images/sublist_open.png";
import sublist_close from "images/sublist_close.png";
import icon_refresh_thin from 'images/icon/icon_refresh_thin.png'
import m_icon_refresh_thin from 'images/m/icon_refresh_thin.png'
import photoreview_next from 'images/icon/photoreview_next.png';
import AddressDialog from './components/AddressDialog';
import PrivacyTermsPopup from './components/PrivercyTermsPopup';
import RegularUseTermsPopup from './components/RegularUseTermsPopup';
import RegularServicePopup from './components/RegularServicePopup';
import RegularServicePopup2 from './components/RegularServicePopup2';
import PostCode from "./components/PostCode";
import {stringPrice} from "utils/StringPrice";
import getCurrentDate from "utils/GetCurrentDate";
import {removeFromCart} from "../actions/cartActions";
import DotGuide from './components/DotGuide';
import {useSelector, useDispatch} from "react-redux";
import {checkAllItemsForPayment} from '../utils/Validated'
import Moment from "moment";
import '../styles/GlobalStyles.scss';

const OrderPage = () => {
    const isPc = useMediaQuery({ query: '(min-width: 960px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const dispatch = useDispatch();
    const history = useHistory();
    const userInfo = useSelector((state) => state.userLogin.userInfo.data);
    const orderItems = useSelector((state) => state.orderItems.orderItems)
    const {loading, user} = useSelector((state) => state.userDetails);
    const {item, price, regular} = orderItems
    const [addressList, setAddressList] = useState([])
    const [checkedDevice, setCheckedDevice] = useState([])
    const [devicePrice, setDevicePrice] = useState(0);
    const [loadUserInfo, setloadUserinfo] = useState(false);
    const [addAddress, setAddAddress] = useState(false);
    const [shipPrice, setShipPrice] = useState(price.shipprice)
    const checkFreeShipping = item.filter((el) => { return el.Product.is_free_shipping === true})
    const usePoint = item.filter((el) => { return el.Product.use_point === true})
    let isIncludeNoPointProduct = false
    let clickCount = 0
    const payPointPrice = item.reduce((acc, cur) => {
      if (cur.Product.use_point) {
        let productPrice = 0;
        let selectedOptions = cur.SelectedOptions;
        
        for (let i=0; i<selectedOptions.length; i++) {
          productPrice += (cur.Product.sell_price + selectedOptions[i].price) * selectedOptions[i].quantity;
        }

        const usePointAmount = productPrice*cur.Product.point_usage_range/100;
        const roundPrice = Math.round((productPrice-usePointAmount)/100)*100;

        return acc +  (productPrice - roundPrice)
      } else {
        isIncludeNoPointProduct = true;
        return acc
      }
    }, 0)
    const checkFree = checkFreeShipping.length !== 0
    const checkUsePoint = usePoint.length !== 0
    const checkisbasicref = useRef();
    const inputCurrent = useRef()
    const [headthCareDevices, setHealthCareDeivices] = useState([])

    const guideText = `알갱이 *최대사용금액 은 ${stringPrice(payPointPrice)}입니다.\n(*최대사용금액 : 총 구매금액에서 사용가능한 알갱이수)`;

    const [userInputData, setUserInputData] = useState({
        addrName: "",
        userName : "",
        zoneCode : "",
        address : "",
        addressDetail : "",
        p1: "010",
        p2: "",
        p3: "",
        e1: "",
        e2: "",
        e3: "",
        memoiput: "",
        memo: "",
        is_basic : false
    })

    useEffect(() => {
        axios.defaults.headers.common["cid"] = userInfo.cid;
        axios.defaults.headers.common["encoded"] = userInfo.encoded;
        axios.get(`${process.env.REACT_APP_API_URL}api/address`).then((res) => {
            setAddressList(res.data);
        })
        axios.get(`${process.env.REACT_APP_API_URL}api/order/devices`).then((response) => {
            const deviceData = response.data.devices.reduce((acc, cur) => {
                let Option = [];

                if (cur.OptionGroups.length >= 0) {
                    if (cur.OptionGroups[0]) {
                        cur.OptionGroups[0].Options[0].quantity = 1;
                        Option.push(cur.OptionGroups[0].Options[0]);
                    }
                }
                const data = {
                    ...cur, default_option : Option
                }
                acc.push(data)

                return acc

            }, [])
            setHealthCareDeivices(deviceData)
        })

    },[])

    let preZoneCode = useRef();
    useEffect(() => {
      try{
        if (userInputData.zoneCode) {
          if (userInputData.zoneCode.length > 0 && preZoneCode.current !== userInputData.zoneCode) {
            preZoneCode.current = userInputData.zoneCode

            axios.post(`${process.env.REACT_APP_API_URL}api/shipment/extra-cost`, {zipcode: `${preZoneCode.current}`})
                .then((res)=>{
                  setShipPrice(price.shipprice+res.data.cost)
                }).catch((error)=>{
                console.log(error)
            })
          }
        }
      } catch(e) {}
    }, [userInputData]);

    

    const {handleSubmit, getValues } = useForm({
        defaultValues: {
            userName: "",
            address: "",
            zoneCode: "",
            addressDetail: "",
            p1:"010",
            p2:"",
            p3:"",
            e1: "",
            e2: "",
            e3: "",
            memo:"",
            is_basic: true
        }
    });

    if (!item) {
        history.push("/shoppingcart");
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }


    const onChangeUserOrderInput = async (e) => {
        const {name, value} = e.target;
        switch (name) {
            case "usePoint":
                if (point >= pointState) {
                    try {
                        let usepoint = parseInt(value)
                        if (isNaN(usepoint)) {
                            usepoint = 0;
                        }
                        setPointState(prevState => (prevState, usepoint));
                    } catch (e) {
                        setPointState("");
                    }
                }
                break;
            case "coupon":
                setCoupon(prevState => (prevState, value));
                break

            case "checkUserInfo":
                let email = user.data.email.split("@")
                let name = user.data.user_name;
                let phone = user.data.phone_number;
                if(phone === null){
                    setUserInputData(prevState => ({...prevState, ["p1"]:"010"}));
                    setUserInputData(prevState => ({...prevState, ["p2"]:""}));
                    setUserInputData(prevState => ({...prevState, ["p3"]:""}));
                }else{
                    setUserInputData(prevState => ({...prevState, ["p1"]:phone.slice(0,3)}));
                    setUserInputData(prevState => ({...prevState, ["p2"]:phone.slice(3,7)}));
                    setUserInputData(prevState => ({...prevState, ["p3"]:phone.slice(7,11)}));
                }

                setUserInputData(prevState => ({...prevState, ["userName"]:name}));
                setUserInputData(prevState => ({...prevState, ["e1"]:email[0]}));
                setUserInputData(prevState => ({...prevState, ["e2"]:email[1]}));
                setUserInputData(prevState => ({...prevState, ["e3"]:"1"}));
                setloadUserinfo(!loadUserInfo);
                return
                break;
            case "e1":
                const reg = /^[a-zA-Z0-9]+$/g.test(value);
                if(reg){
                    return setUserInputData(prevState => ({...prevState, ["e1"]:value}));
                } else {
                    return setUserInputData(prevState => ({...prevState, ["e1"]:""}));
                }
                break
            case "e3":
                if (value === "-1") {
                    return  setUserInputData(prevState => ({...prevState, ["e2"]:""}));
                } else {
                    return setUserInputData(prevState => ({...prevState, ["e2"]:value}));
                }
                break;
            case "memo":
                if (value === "1") {
                    setUserInputData(prevState => ({...prevState, ["memo"]:value}));
                    return  setUserInputData(prevState => ({...prevState, ["memoiput"]: ""}));
                } else {
                    setUserInputData(prevState => ({...prevState, ["memo"]:value}));
                    return setUserInputData(prevState => ({...prevState, ["memoiput"]:value}));
                }
                break;
            case "is_basic":
                if(userInputData.is_basic === true){
                    return setUserInputData(prevState => ({...prevState, ["is_basic"]: false}));
                }else{
                    return setUserInputData(prevState => ({...prevState, ["is_basic"]: true}));
                }
                break;
        }

        return setUserInputData(prevState => ({...prevState, [name]:value}));
    }

    useEffect(async () => {
        if (!loading) {
            if (user.balance) {
                setPoint(parseInt(user?.balance?.data.balance_point));
            }
        }
        const default_address = addressList.filter((el) => el.is_default)[0]
        const checkAddrName = addressList.filter((el) => el.title === default_address?.title).length !== 0 ? true : false;
        if(checkAddrName){
            setAddAddress(true);
        }else{
            setAddAddress(false)
        }
        let email = default_address?.email
        if(addressList.length !== 0){
            setUserInputData(prevState => ({prevState, ["is_basic"]: default_address?.is_default}));
            setUserInputData(prevState => ({...prevState, ["addrName"]:default_address?.title}));
            setUserInputData(prevState => ({...prevState, ["userName"]:default_address?.name}));
            setUserInputData(prevState => ({...prevState, ["address"]:default_address?.address}));
            setUserInputData(prevState => ({...prevState, ["addressDetail"]:default_address?.detail_address}));
            setUserInputData(prevState => ({...prevState, ["zoneCode"]:default_address?.zipcode}));
            setUserInputData(prevState => ({...prevState, ["e1"]:email?.split("@")[0]}));
            setUserInputData(prevState => ({...prevState, ["e2"]:email?.split("@")[1]}));
            setUserInputData(prevState => ({...prevState, ["p1"]:default_address?.phone.slice(0,3)}));
            setUserInputData(prevState => ({...prevState, ["p2"]:default_address?.phone.slice(3,7)}));
            setUserInputData(prevState => ({...prevState, ["p3"]:default_address?.phone.slice(7,11)}));
        }
    }, [user, addressList])

    useEffect(() => {
        if (!headthCareDevices) {
            return
        }
        handleCheckRadio(true, 0)
        if (headthCareDevices.length >= 1) {
            handleCheckRadio(true, headthCareDevices[0].id)
        }
        checkisbasicref.current.click();
    }, [setUserInputData])

    const formValues = getValues();
    const onSubmit = (data) => {console.log(data)}

    useEffect( () => {
        dispatch(getUserInfo());

        if (regular) {
            if(headthCareDevices?.length > 0){
                setCheckRadio(headthCareDevices[0].id)
                setCheckedDevice((value) => [...value, headthCareDevices[0]])
            }
        }
    },[dispatch]);

    //결제 수단 선택
    const [selectedVal, setSelectedVal] = useState("card");
    const handleChange = (e) => {
        setSelectedVal(e.target.value)
    }

    // 스크롤시 오른쪽 결제금액 높이조절
    const [ScrollY, setScrollY] = useState(0);
    const [ScrollActive, setScrollActive] = useState(false);

    useEffect(() => {
        const jquery = document.createElement('script');
        jquery.src = 'https://code.jquery.com/jquery-1.12.4.min.js';
        jquery.async = true;
        const script = document.createElement('script');
        script.src = 'https://cdn.iamport.kr/js/iamport.payment-1.2.0.js';
        script.async = true;
        document.body.appendChild(jquery);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(jquery);
            document.body.removeChild(script);
        };
    }, []);

    // 우편번호 검색 팝업
    const [postCodePopup, setPostCodePopup] = useState(false);

    // 개인정보 처리 동의 팝업
    const [ privacy, setPrivacy ] = useState(false);
    const closePrivacy = () => {
        setPrivacy(false);
    }

    // 정기/반복결제 이용약관 동의
    const [ regularTerms, setRegularTerms ] = useState(false);
    const closeRegularTerms = () => {
      setRegularTerms(false);
    }

    // 정기배송 및 해지에 관한 내용 동의
    const [ regularService, setRegularService ] = useState(false);
    const [ regularService2, setRegularService2 ] = useState(false);
    const closeRegularService = () => {
      setRegularService(false);
    }

    const closeRegularService2 = () => {
        setRegularService2(false);
    }

    // 배송지 선택 팝업
    const [ Address, setAddress ] = useState(false);

    // 주문제품 전체보기
    const [ itemList, setItemList ] = useState(false);

    // 배송지 신규작성
    function resetUserInput() {
        setUserInputData({
            addrName: "",
            userName : "",
            zoneCode : "",
            address : "",
            addressDetail : "",
            p1: "010",
            p2: "",
            p3: "",
            e1: "",
            e2: "",
            e3: "",
            memoiput: "",
            memo: "1",
            is_basic : false
        })
    }

    //주문상품 접기/펼치기
    const toggleItemList = () => {
      if (item.length > 1) {
        const orderlist =  document.querySelector('.orderlist_wrap .sub_cont_wrap');
        if(document.querySelector('.orderlist_wrap .sub_cont_wrap.active')) {
          orderlist.style.display = "none";
          setItemList(!itemList);
        } else {
          orderlist.style.display = "block";
        }
        setItemList(!itemList);
      } else {
        setItemList(!itemList);
      }
    }

    // 헬스케어 디바이스 토글
    const [ deviceList, setDeviceList ] = useState(true);
    function orderComplate () {
        let removedids = [];
        let type = "";
        for (let i = 0; i < orderItems.item.length; i++) {
            removedids.push(orderItems.item[i].id);
        }
        if (regular) {
            type = "regular";
        } else {
            type = "nomal";
        }
        axios.delete(`${process.env.REACT_APP_API_URL}api/order/cart`, {
            headers: {
                "cid" : userInfo.cid,
                "encoded" : userInfo.encoded,
            },
            data: {
                id: removedids,
                type: type
            }
        })
        sessionStorage.setItem('orderItems', JSON.stringify([]));
    }

    const alertInputCheck = () => {
      if (++clickCount >= 7) {
        let alertMsg = ''
        let check = ''
        check = userInputData.addrName.length > 0 ? 'V' : '  '
        alertMsg += `[${check}]배송지명: ${userInputData.addrName}\n`
        check = userInputData.zoneCode.length > 0 ? 'V' : '  '
        alertMsg += `[${check}]우편번호: ${userInputData.zoneCode}\n`
        check = userInputData.address.length > 0 ? 'V' : '  '
        alertMsg += `[${check}]주소: ${userInputData.address}\n`
        check = userInputData.addressDetail.length > 0 ? 'V' : '  '
        alertMsg += `[${check}]주소상세: ${userInputData.addressDetail}\n`
        check = userInputData.userName.length > 0 ? 'V' : '  '
        alertMsg += `[${check}]이름: ${userInputData.userName}\n`
        check = email.length > 0 ? 'V' : '  '
        alertMsg += `[${check}]이메일: ${email}\n`
        check = phone.length > 0 ? 'V' : '  '
        alertMsg += `[${check}]연락처: ${phone}\n`
        check = checkNormal > 0 ? 'V' : '  '
        clickCount = 0;
        alert(alertMsg);
      }
    }

    // 일반결제 실행 함수
    const onClickPayment = async () => {
      const titles = orderItems.item.map((el) => el.Product.name);
      let products = [];
      let options = [];
      let removedids = "";
      let type = "";
      for (let i = 0; i < orderItems.item.length; i++) {
          products.push(orderItems.item[i].Product);
          options.push(orderItems.item[i].SelectedOptions);
          removedids += `${orderItems.item[i].id},`
      }
      if (regular) {
          type = "regular";
      } else {
          type = "nomal";
      }
      const vank_due = Moment().add(1, "days").format("YYYYMMDD");
      const merchantUid = getCurrentDate();
      const { IMP } = window;
      let data;
      let customData;

      const config = {
          headers: {
              'Content-Type': 'application/json',
              "cid" : userInfo.cid,
              "encoded" : userInfo.encoded,
          },
      }
      const res = await axios.get(`${process.env.REACT_APP_API_URL}api/user/userid`, config)
      const userid = res.data.user_id

      IMP.init("imp65301331");

      if (selectedVal === "naverpay") {
        let navProducts = [];
        for(let i=0; i<options.length; i++) {
          let option = options[i];
          for(let j=0; j<option.length; j++) {
            let product = {
              categoryType: "PRODUCT",
              categoryId: "GENERAL",
              uid: option[j].OptionId ? option[j].OptionId : option[j].id,
              name: option[j].name,
              count: option[j].quantity
            };
            navProducts.push(product);
          }
        }

        customData = {
          naverProducts: navProducts,
          user_id: userid,
          cid: userInfo.cid,
          encoded: userInfo.encoded,
          product: JSON.stringify(products),
          option: JSON.stringify(options),
          mileage_spent_amount : payPoint,
          coupon_discount_amount : String(afterCouponApply).substring(1),
          total_discount_amount : price.discount + payPoint,
          order_price_amount: price.price,
          actual_payment_amount : actualPayment(totalPayment, payPoint, coupon),
          shipping_amount : shipPrice,
          shipping_message : userInputData.memoiput,
          address_title: userInputData.addrName,
          address: userInputData.address,
          detail_address : userInputData.addressDetail,
          is_update : addAddress,
          is_default : userInputData.is_basic,
          cart_ids: removedids.replace(/,\s*$/, ""),
          cart_type: type
        };

        data = {
            pg: "naverpay",
            pay_method: selectedVal,
            merchant_uid: merchantUid,
            name: titles.length > 1 ? `${titles[0]} 외 ${titles.length - 1}개`: titles[0],
            amount: actualPayment(totalPayment, payPoint, coupon),
            buyer_email: userInputData.e1 + "@" + userInputData.e2,
            buyer_name: userInputData.userName,
            buyer_tel: `${userInputData.p1}${userInputData.p2}${userInputData.p3}`,
            buyer_addr: `${userInputData.address} / ${userInputData.addressDetail}`,
            buyer_postcode: userInputData.zoneCode,
            naverPopupMode : false,
            m_redirect_url: `${process.env.REACT_APP_API_URL}api/payment/redirect`,
            custom_data: customData
            // vbank_due: vank_due
        };
      } else {
          customData = {
            user_id: userid,
            cid: userInfo.cid,
            encoded: userInfo.encoded,
            product: JSON.stringify(products),
            option: JSON.stringify(options),
            mileage_spent_amount : payPoint,
            coupon_discount_amount : String(afterCouponApply).substring(1),
            total_discount_amount : price.discount + payPoint,
            order_price_amount: price.price,
            actual_payment_amount : actualPayment(totalPayment, payPoint, coupon),
            shipping_amount : shipPrice,
            shipping_message : userInputData.memoiput,
            address_title: userInputData.addrName,
            address: userInputData.address,
            detail_address : userInputData.addressDetail,
            is_update : addAddress,
            is_default : userInputData.is_basic,
            coupon : coupon,
            cart_ids: removedids.replace(/,\s*$/, ""),
            cart_type: type
          }

          data = {
            pg: "html5_inicis.MOIvrampet",
            pay_method: selectedVal,
            merchant_uid: merchantUid,
            name: titles.length > 1 ? `${titles[0]} 외 ${titles.length - 1}개`: titles[0],
            amount: actualPayment(totalPayment, payPoint, coupon),
            buyer_email: userInputData.e1 + "@" + userInputData.e2,
            buyer_name: userInputData.userName,
            buyer_tel: `${userInputData.p1}${userInputData.p2}${userInputData.p3}`,
            buyer_addr: `${userInputData.address} / ${userInputData.addressDetail}`,
            buyer_postcode: userInputData.zoneCode,
            m_redirect_url: `${process.env.REACT_APP_API_URL}api/payment/redirect`,
            custom_data: customData,
            vbank_due: vank_due
        };
      }
        
      IMP.request_pay(data, async (response) => {
          const {success, pay_method, paid_at, error_msg } = response;
          if(response.error_code === "F1001"){
              alert("적립금만을 사용하여 결제가 불가합니다.\n" +
                  "적립금 사용 금액을 변경해주세요!")
              return
          }
          if(success) {
              let historyPath = "/payComplete";
              if (pay_method === 'vbank' && paid_at === 0) {
                  historyPath = "/orderComplete";
              }
              orderComplate();
              history.push({
                  pathname: historyPath,
              })
          }else{
              alert(`결제 실패 : ${error_msg}`);
          }
      })
    }

    // 정기결제 실행 함수
    const onClickRegularPay = () => {
        const device = headthCareDevices.reduce((acc, cur) => {
            let data = {
                ...cur,
                amount: 1,
            }
            acc.push(data);
            return acc
        },[])

        const titles = orderItems.item?.map((el) => el.Product.name);
        const userId = orderItems.item[0].user_id;
        const regularItemInfo = orderItems.item[0]
        const devicePrice = checkRadio !== 0 ? checkedDevice[0]?.regular_order_price !== null
            ? checkedDevice[0]?.regular_order_price
            : checkedDevice[0]?.price : 0

        const { IMP } = window;
        IMP.init("imp65301331");
        const data3 = {
            pg: "html5_inicis.MOIvarramb",
            pay_method: "card",
            customer_uid: userId,
            merchant_uid: `${getCurrentDate()}`,
            amount: actualPaymentReg(price.totalprice, devicePrice, afterCouponApply, discountPrice),
            name: titles.length > 1 ? `${titles[0]} 외 ${titles.length - 1}개`: titles[0],
            buyer_email : userInputData.e1 + "@" + userInputData.e2,
            buyer_name :  userInputData.userName,
            buyer_tel : `${userInputData.p1}${userInputData.p2}${userInputData.p3}`,
            buyer_addr: `${userInputData.address} / ${userInputData.addressDetail}`,
            buyer_postcode: userInputData.zoneCode,
            coupon: coupon
        }

        IMP.request_pay(data3, (response) => {
            const {success, error_msg } = response;
            if(success){
                try {
                    axios.defaults.headers.common["cid"] = userInfo.cid;
                    axios.defaults.headers.common["encoded"] = userInfo.encoded;
                    const res = axios.post(`${process.env.REACT_APP_API_URL}api/payment/regular/pay`, {
                        ...response,
                        "devices": JSON.stringify(checkedDevice[0]) || null,
                        "product_id": regularItemInfo.ProductId,
                        "product_code": regularItemInfo.Product.code,
                        "product_name": regularItemInfo.Product.name,
                        "product_image": regularItemInfo.Product.main_img,
                        "cycle": regularItemInfo.cycle,
                        "product_amount": regularItemInfo.quantity,
                        "mileage_spent_amount" : payPoint,
                        "coupon_discount_amount" : String(afterCouponApply).substring(1),
                        "total_discount_amount" : discountPrice,
                        "order_price_amount": orderItems.price.price,
                        "actual_payment_amount" : actualPaymentReg(price.totalprice, devicePrice, afterCouponApply, discountPrice),
                        "shipping_amount" : shipPrice,
                        "shipping_message" : userInputData.memoiput,
                        "address_title": userInputData.addrName,
                        "address": userInputData.address,
                        "detail_address" : userInputData.addressDetail,
                        "is_update" : addAddress,
                        "is_default" : userInputData.is_basic,
                        "coupon": coupon
                    }).then(() => {
                        orderComplate();
                        history.push({
                            pathname: "/payComplete",
                            state: regular
                        })
                    }).catch((err) => {
                        console.log(err.response.data.message)
                        alert(`결제 실패 : ${err.response.data.message}`);
                    })
                }catch (e) {
                    console.log(e)
                }
            }else{
                alert(`결제 실패 : ${error_msg}`);
            }
        })
    }

    const [Mobile, setMobile] = useState(false);
    const resizingHandler = () => {
        if (window.innerWidth > 960) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    };

    useEffect(() => {
        if (window.innerWidth > 960) {
            setMobile(false);
        } else {
            setMobile(true);
        }
        if (Mobile) {
            function handleTouchMove(event) {
                if (Address || postCodePopup) { event.preventDefault(); }
            }
            function disableScroll() {
                if (Address || postCodePopup) {
                    document.body.style.overflowY = 'hidden';
                    document.body.style.overflowX = 'visible';
                    document.querySelector('html').scrollTop = window.scrollY;
                }
            }
            window.addEventListener('scroll', disableScroll);
            window.addEventListener("resize", resizingHandler);

            return () => {
                window.removeEventListener('scroll', disableScroll);
                document.body.style.overflow = 'visible';
                window.removeEventListener("resize", resizingHandler);
            }
        } else {
            window.addEventListener("resize", resizingHandler);
            return () => {
                window.removeEventListener("resize", resizingHandler);
            };
        }}, [Address, postCodePopup, Mobile]);

    const [agreeRegular1, setAgreeRegular1] = useState(false);
    const [agreeRegular2, setAgreeRegular2] = useState(false);
    const [checkNormal, setCheckNormal] = useState(false);
    const [checkRadio, setCheckRadio] = useState(0);
    const [checkDevicePrice, setCheckDevicePrice] = useState(0);


    //헬스케어 다비이스 선택값
    const handleCheckRadio = (checked, id) => {
       if (!headthCareDevices) {return}
       if (!Array.isArray(headthCareDevices)) {return}
       if(checked){
            setCheckRadio(id);
            const deviceFind = headthCareDevices.filter((el) => el.id === id);
            setCheckedDevice(deviceFind)
            if(deviceFind.length !== 0){
                if (deviceFind[0].regular_order_price) {
                    if (deviceFind[0].regular_order_price !== 0) {
                        setCheckDevicePrice(deviceFind[0].regular_order_price)
                        regular && setDevicePrice(deviceFind[0].regular_order_price)
                        return
                    }
                }
                setCheckDevicePrice(deviceFind[0].sell_price)
                regular && setDevicePrice(deviceFind[0].sell_price)
            } else {
                setCheckDevicePrice(0)
            }
        }
        if(id === 0){
            setCheckedDevice("")
            setDevicePrice(0)
            setCheckDevicePrice(0)
        }
    }


    //일반 동의함 선택
    const handleNormal = (e) => {
        const checked = e.target.checked;
        if(checked){
            setCheckNormal(!checkNormal)
        }else{
            setCheckNormal(false)
        }
    }

    //정기1 동의함 선택
    const handleRegular1 = (e) => {
        const checked = e.target.checked
        if(checked){
            setAgreeRegular1(!agreeRegular1);
        }else{
            setAgreeRegular1(false)
        }
    }
    //정기2 동의함 선택
    const handleRegular2 = (e) => {
        const checked = e.target.checked
        if(checked){
            setAgreeRegular2(!agreeRegular2);
        }else{
            setAgreeRegular2(false)
        }
    }

    const [point, setPoint] = useState(0);
    const [payPoint, setPayPoint] = useState(0);
    const [pointState, setPointState] = useState("");

    //알갱이 입력
    const selectPoints = () => {
        if(!regular){
            if (pointState > payPointPrice) {
              if (isIncludeNoPointProduct) {
                alert(`묶음 상품 구매시에는\n알갱이 적용 가능 상품에만 알갱이가 차감 적용 됩니다.`)
              } else {
                alert(`알갱이 *최대사용금액을 초과하였습니다.\n다시 확인해주세요.`)
              }
              
              setPointState(0)
              return;
            }

            setPayPoint(pointState);
        }
    }

    //아임포트 일반 실결제금액
    const actualPayment = (price, coupon) => {
        if(coupon !== ""){
            return price + afterCouponApply
        }
        return price
    }

    //아임포트 정기 실결제금액
    const actualPaymentReg = (price, devicePrice, coupon, discount) => {
        if(devicePrice === 0){
            return price - discount + devicePrice + coupon
        }
        if(coupon !== 0){
            return price + devicePrice + coupon
        }
        return price + devicePrice
    }

    const phone = userInputData.p1 + userInputData.p2 + userInputData.p3
    const email = userInputData.e1 + "@" + userInputData.e2 || userInputData.e3
    const checkAddress = userInputData.addrName !== "" && userInputData.zoneCode !== "" && userInputData.address !== "" && userInputData.addressDetail !== ""
    const [coupon, setCoupon] = useState("");
    const [couponBenefit, setCouponBenefit] = useState({
        sort: "",
        benefit: "",
    })


    //쿠폰 입력
    const handleSubmitCoupon = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.cid,
                "encoded" : userInfo.encoded,
            },
        }
        axios.post(`${process.env.REACT_APP_API_URL}api/order/apply-coupon`, {
            "coupon" : coupon
        }, config).then((res) => {
            setCouponBenefit({
                ...couponBenefit,
                sort: res.data.benefit_sort,
                benefit: res.data.benefit_amount
            })
        }).catch((err) => {
            console.log(err);
            if(err.response.data.status === "NotAvailableCoupon"){
                alert(`${err.response.data.message}`)
            }
            if(err.response.data.status === "NoMoreCoupon"){
                alert(`${err.response.data.message}`)
            }
            if(err.response.data.status === "DeletedCoupon"){
                alert(`${err.response.data.message}`)
            }
            if(err.response.data.status === "Expired"){
                alert(`${err.response.data.message}`)
            }
        })
    }

    //쿠폰값 계산
    const CouponPointsCalc = (price) => {
        if(couponBenefit.sort === "percentage"){
           return Math.round(price * couponBenefit.benefit / 100)
        }else{
           return couponBenefit.benefit
        }
    }


    const withOutShipping = price.totalprice - price.shipprice //상품값에 배송비 미포항된 결과값
    const discountPrice =  regular ? devicePrice !== 0 ? 0 : Math.round(price.price * 50 / 100) : price.discount //정기 사료 50% 할인
    const afterCouponApply = !checkFree ? - CouponPointsCalc(withOutShipping + devicePrice) :
        - CouponPointsCalc( withOutShipping + devicePrice - shipPrice ) //쿠폰적용값
    const totalPayment = !checkFree ? (price.totalprice + shipPrice -  price.shipprice) - payPoint + devicePrice :
        withOutShipping - payPoint + shipPrice + devicePrice  //최종금액 결과값 변수 할당

    return (
        <OrderContainer className="center" item={item}>
            <div className="wrap_title">
                <h2>주문/결제</h2>
                <div className="purchase_step">
                    <ul>
                        <li>01. 장바구니</li>
                        <li className="active">02. 주문/결제</li>
                        <li>03. 결제완료</li>
                    </ul>
                </div>
            </div>

            {/*기본 배송지*/}
            { isMobile && <p className="noArea"></p>}
            <div className="shopping_wrap">
                <div className="shopping_left">
                    <div className="address_wrap">
                        <div className="sub_tit_wrap">
                            <h3>기본 배송지</h3>
                            <div className="checks full_check">
                                <input type="checkbox" name="checkUserInfo" id="chk_userinfo" onChange={onChangeUserOrderInput} value={loadUserInfo}  />
                                <label htmlFor="chk_userinfo">회원 정보와 동일</label>
                            </div>
                            <div className="right_area">
                                <button className="refresh_btn" onClick={()=>{resetUserInput()}}>
                                    신규 작성
                                    {isPc && <img src={icon_refresh_thin} alt="전체지우기" />}
                                    {isMobile && <img src={m_icon_refresh_thin} alt="전체지우기" />}
                                </button>
                                {addressList.length !== 0 ? <SmallButton onClick={() => setAddress(true)} color="white" outline>배송지 선택</SmallButton> : <SmallButton color="gray" outline>배송지 선택</SmallButton>}
                            </div>
                        </div>
                        <div className="address_form">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="sub_cont_wrap address_info">
                                    <div className="row">
                                        <div className="form_title">배송지명<i className="required">*</i></div>
                                        <div className="form_cont"><input type="text" name="addrName" onChange={onChangeUserOrderInput}  value={userInputData.addrName}/></div>
                                    </div>
                                    <div className="row address">
                                        <div className="form_title">이름<i className="required">*</i></div>
                                        <div className="form_cont"><input type="text" name="userName" onChange={onChangeUserOrderInput}  value={userInputData.userName}/></div>
                                    </div>
                                    <div className="row">
                                        <div className="form_title"></div>
                                        <div className="name_required">*이름은 최소 2자 이상 기입해 주세요</div>
                                    </div>
                                    <div className="row address">
                                        <div className="form_title">주소<i className="required">*</i></div>
                                        <div className="form_cont">
                                            <span>
                                                <input type="text" id="Address1" name="zoneCode" onChange={onChangeUserOrderInput}  value={userInputData.zoneCode} disabled />
                                                <Button size="small" color="white" outline onClick={() => {setPostCodePopup(!postCodePopup)}}>우편번호</Button>
                                            </span>
                                            <p><input type="text" id="Address2" name="address" onChange={onChangeUserOrderInput}  value={userInputData.address} disabled /></p>
                                            <p><input type="text" id="Address3" name="addressDetail" onChange={onChangeUserOrderInput}  value={userInputData.addressDetail} placeholder="상세주소를 입력하세요." /></p>
                                        </div>
                                    </div>
                                    <div className="row phone">
                                        <div className="form_title">핸드폰 번호<i className="required">*</i></div>
                                        <div className="form_cont">
                                            <p>
                                                <select id="phone1" name="p1" onChange={onChangeUserOrderInput} value={userInputData.p1}>
                                                    <option value="010">010</option>
                                                    <option value="011">011</option>
                                                    <option value="012">012</option>
                                                    <option value="013">013</option>
                                                    <option value="013">014</option>
                                                    <option value="013">015</option>
                                                    <option value="013">016</option>
                                                    <option value="013">017</option>
                                                    <option value="013">018</option>
                                                </select>
                                                <span>-</span>
                                                <input type="number" id="phone2" name="p2" onChange={onChangeUserOrderInput} value={userInputData.p2}/>
                                                <span>-</span>
                                                <input type="number" id="phone3" name="p3" onChange={onChangeUserOrderInput} value={userInputData.p3}/>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row email">
                                        <div className="form_title">이메일<i className="required">*</i></div>
                                        <div className="form_cont">
                                            <div>
                                                <p>
                                                    <input type="text" className="emailId" id="email1" ref={inputCurrent} name="e1" onChange={onChangeUserOrderInput} value={userInputData.e1} />
                                                    <span>@</span>
                                                    <input type="text" className="emailDomain" id="email2" name="e2" onChange={onChangeUserOrderInput} value={userInputData.e2}/>
                                                </p>
                                                <select id="selectEmail" name="e3" onChange={onChangeUserOrderInput} value={userInputData.e3}>
                                                    <option value="-1">직접입력</option>
                                                    <option value="naver.com" >naver.com</option>
                                                    <option value="gmail.com">gmail.com</option>
                                                    <option value="daum.net">daum.net</option>
                                                    <option value="hanmail.net">hanmail.net</option>
                                                </select>
                                            </div>
                                            <div className="checks full_check">
                                                <input type="checkbox" ref={checkisbasicref} name="is_basic" onChange={onChangeUserOrderInput} checked={userInputData.is_basic ? true : false} value={userInputData.is_basic} id="chk_adress_dafault"  />
                                                <label htmlFor="chk_adress_dafault">기본 배송지로 설정</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row sub_cont_wrap delivery_msg">
                                    <div className="form_title">배송메모</div>
                                    <div className="form_cont">
                                        <p>
                                            <select onChange={onChangeUserOrderInput} name="memo" value={userInputData.memo}>
                                                <option value="" selected>배송메모를 선택해 주세요</option>
                                                <option value="부재시 전화 또는 문자 남겨주세요.">부재시 전화 또는 문자 남겨주세요.</option>
                                                <option value="부재시 경비실에 맡겨주세요.">부재시 경비실에 맡겨주세요.</option>
                                                <option value="배송 전 미리 연락 바랍니다.">배송 전 미리 연락 바랍니다.</option>
                                                <option value="1">직접 입력</option>
                                            </select>

                                        </p>
                                        {
                                            userInputData.memo === "1" && <p>
                                            <input name="memoiput" onChange={onChangeUserOrderInput} value={userInputData.memoinput} type="text" placeholder="직접 입력" />
                                            </p>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    {/*주문상품*/}
                    {isMobile && <p className="noArea"></p>}
                    {regular ? <div className="orderitem_wrap orderlist_wrap">
                        <div className={itemList ? "sub_tit_wrap active" : "sub_tit_wrap"} onClick={toggleItemList}>
                            <h3>주문상품<span>(<b>{item ? item.length : 0}</b>건)</span></h3>
                        </div>
                        <div className={itemList ? "sub_cont_wrap active" : "sub_cont_wrap default_list"}>
                            {item && item.map((el) => {
                                return (
                                    <div className="item_list" key={el.id}>
                                        <div className="item_img">
                                            <img src={el.Product.main_img} alt={el.Product.name} />
                                        </div>
                                        <div className="item_info">
                                            <div className="item_name_option">
                                                <p className="item_name">{el.Product.name} ({el.cycle}주기)</p>
                                            </div>
                                            <div className="item_count_price">
                                                <p className="item_count"> { isMobile && "/ "}{el.quantity}{ isMobile && "개"}</p>
                                                <p className="item_price">
                                                    <span className="price">{stringPrice(el.price * el.quantity)}<i>원</i></span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div> : <div className="orderitem_wrap orderlist_wrap">
                        <div className={itemList ? "sub_tit_wrap active" : "sub_tit_wrap"} onClick={toggleItemList}>
                            <h3>주문상품 <span>(<b>{item ? item.length : 0}</b>건)</span></h3>
                        </div>
                        <div className={itemList ? "sub_cont_wrap active" : "sub_cont_wrap default_list"}>
                            {item ? item.map((el) => {
                                let isFreeShipping = false;
                                item.map((cur) => {
                                    let result = false;
                                    if (!isFreeShipping) {
                                        isFreeShipping = cur.Product.is_free_shipping
                                        return;
                                    }
                                    const price = el.SelectedOptions.reduce((acc, cur) => {
                                        let result = 0;
                                        if (cur.require) {
                                            result = (el.Product.sell_price + cur.price) * cur.quantity
                                        } else {
                                            result = cur.price * cur.quantity
                                        }
                                        return acc + result;
                                    }, 0)
                                    if (price > cur.Product.free_shipping_price) {
                                        isFreeShipping = true;
                                    }
                                });

                                let isregualar = false;
                                let totalPrice = 0;
                                let price = 0;
                                if (el.regular) {
                                    isregualar = true;
                                    totalPrice = el.price * el.quantity;
                                } else {
                                    totalPrice = el.SelectedOptions.reduce((acc, cur) => {
                                        let result = 0;
                                        if (cur.require) {
                                            result = (el.Product.price + cur.price) * cur.quantity
                                        } else {
                                            result = cur.price * cur.quantity
                                        }
                                        return acc + result;
                                    }, 0)
                                    price = el.SelectedOptions.reduce((acc, cur) => {
                                        let result = 0;
                                        if (cur.require) {
                                            result = (el.Product.sell_price + cur.price) * cur.quantity
                                        } else {
                                            result = cur.price * cur.quantity
                                        }
                                        return acc + result;
                                    }, 0)
                                }
                                return (
                                    <div className="item_list" key={el.ProductId}>
                                        <div className="item_img">
                                            <img src={el.Product.main_img} alt={el.Product.name} />
                                        </div>
                                        <div className="item_info">
                                            <div className="item_name_option">
                                                <p className="item_name">{el.Product.name}</p>
                                                { isregualar ?
                                                    <p className="item_option" key={el.ProductId}>{el.name}<b>({el.quantity}개)</b></p> :
                                                    el.SelectedOptions.map((e) =>
                                                        <p className="item_option" key={e.id}>{e.name}<b>({e.quantity}개)</b></p>
                                                    )
                                                }
                                            </div>
                                            <div className="item_count_price">
                                                <p className="item_count"> { isMobile && "/ "}1{ isMobile && "개"}</p>
                                                <p className="item_price">
                                                    <span className="price">{stringPrice(price)}<i>원</i></span>
                                                    {isregualar?
                                                        null :
                                                        <span className="before_discount">{stringPrice(totalPrice)}<i>원</i></span>}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            ) : null}
                        </div>
                    </div> }

                    {/*헬스케어 디바이스 선택*/}
                    { isMobile && <p className="noArea"></p>}
                    {regular ? <div className="orderitem_wrap device_wrap">
                        <div className={deviceList ? "sub_tit_wrap active" : "sub_tit_wrap"} onClick={() => setDeviceList(!deviceList)}>
                            <h3>헬스케어 디바이스 선택</h3>
                        </div>
                        <div className={deviceList ? "sub_cont_wrap active" : "sub_cont_wrap"}>
                            {headthCareDevices.filter((e) => e.is_device === true && e.display === true).map((el) => {
                                let soldOutCheck = false;

                                if (el.is_soldout) {
                                    soldOutCheck = true;
                                } else if (!el.sell) {
                                    soldOutCheck = true;
                                }
                                let devicePrice = 0;
                                if (el.regular_order_price) {
                                    devicePrice = el.regular_order_price;
                                } else {
                                    devicePrice = el.sell_price;
                                }
                                return (
                                    <div className="item_list" key={el.id}>
                                        <div className="radios">
                                            {
                                                soldOutCheck ?
                                                    <input type='radio' name="reg_delivery_item" disabled={true}/> :
                                                    <input type='radio' name="reg_delivery_item" id={el.id} checked={checkRadio === el.id} onChange={(e) => {handleCheckRadio(e.target.checked, el.id)}}/>
                                            }

                                            <label htmlFor={el.id}></label>
                                        </div>
                                        <div className="item_img">
                                            <img src={el.main_img} alt={el.name} />
                                        </div>
                                        <div className="item_info">
                                            <p className="item_name">{el.name}</p>
                                            <div className="item_count_price">
                                                <p className="item_count"> { isMobile && "/"}1{ isMobile && "개"}</p>
                                                <p className="item_price">
                                                    {
                                                        soldOutCheck ?
                                                            <span className="price">품절</span> :
                                                            <span className="price">{stringPrice(devicePrice)}<i>원</i></span>
                                                            
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="item_list">
                                <div className="radios no_device_checks">
                                    <input type='radio' name="reg_delivery_item" id="no_device" checked={checkRadio === 0} onChange={(e) => handleCheckRadio(e.target.checked, 0)} />
                                    <label htmlFor="no_device">사료 50% 할인</label>
                                </div>
                            </div>
                        </div>
                    </div> : null}


                    {/*쿠폰/알갱이 혜택받기 */}
                    { isMobile && <p className="noArea"></p>}
                    <div className="coupon_wrap">
                        <div className="sub_tit_wrap">
                            {regular | !checkUsePoint ? <h3>쿠폰 혜택 받기</h3> : <h3>쿠폰/알갱이 혜택 받기</h3>}
                        </div>
                        <div className="sub_cont_wrap">
                            <div className="row">
                                <div className="form_title">쿠폰 번호</div>
                                <div className="form_cont">
                                    <p><input type="text" value={coupon} name="coupon" onChange={onChangeUserOrderInput} placeholder="쿠폰 번호 입력" />
                                        <BorderSmallButton size="small" color="black" onClick={handleSubmitCoupon}>쿠폰적용</BorderSmallButton>
                                    </p>
                                </div>
                            </div>
                            {regular | !checkUsePoint ? null : <div className="row">
                                <div className="form_title">알갱이</div>
                                <div className="form_cont">
                                    <p><input type="text" name="usePoint" value={pointState} onChange={onChangeUserOrderInput} placeholder="알갱이 입력"  />
                                        <BorderSmallButton size="small" color="black" onClick={() => selectPoints()}>사용</BorderSmallButton></p>
                                    <div className="small_desc">
                                        <p>보유 알갱이 : {stringPrice(point)}</p>
                                        <div className="guide">
                                            <p>{guideText}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>

                    {/*결제수단 선택 */}
                    { isMobile && <p className="noArea"></p>}
                    <div className="paymethod_wrap">
                        <div className="sub_tit_wrap">
                            <h3>결제수단</h3>
                            {regular && <p>* 정기배송은 카드만 결제 가능</p>}
                        </div>
                        <div className="sub_cont_wrap">
                            <div className="row">
                                <div className="radios">
                                    <input type="radio" name="paymethod" value="card" id="chk_pay1" onChange={handleChange} checked={selectedVal === "card"} />
                                    <label htmlFor="chk_pay1">신용카드</label>
                                </div>
                                {regular ? null : <React.Fragment>
                                    <div className="radios">
                                        <input type="radio" name="paymethod" value="vbank" id="chk_pay2" onChange={handleChange} checked={selectedVal === "vbank"} />
                                        <label htmlFor="chk_pay2">가상계좌</label>
                                    </div>
                                </React.Fragment>}
                                {/* <div className="radios">
                                    <input type="radio" name="paymethod" value="naverpay" id="chk_pay3" onChange={handleChange} checked={selectedVal === "naverpay"} />
                                    <label htmlFor="chk_pay3">네이버페이</label>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    {/*하단 최종 결제금액 정보*/}
                    { isMobile && <p className="noArea"></p>}
                    <div className="total_price_wrap">
                        <div className="sub_tit_wrap">
                            <h3>최종 결제금액</h3>
                        </div>
                        <div className="sub_cont_wrap">
                            <p>
                                <span>상품금액</span>
                                <span>{stringPrice(price.price + devicePrice)}<i>원</i></span>
                            </p>
                            <p>
                                <span>상품할인금액</span>
                                <span>{price.discount !== 0 ? "-" : null} {regular ? devicePrice === 0 ? "-" : null : null} {stringPrice(discountPrice)}<i>원</i></span>
                            </p>
                            <p>
                                <span>배송비</span>
                                <span>{price.shipprice > 0 ? "+" : null} {checkFree ? 0 : stringPrice(price.shipprice)}<i>원</i></span>
                            </p>
                            {shipPrice>price.shipprice ?
                                <p>
                                  <span>도서산간</span>
                                  <span>{`+${stringPrice(shipPrice-price.shipprice)}`}<i>원</i></span>
                                </p> : null}
                            <p>
                                <span>쿠폰할인금액</span>
                                <span>{coupon !== 0 ? stringPrice(afterCouponApply) : 0}<i>원</i></span>
                            </p>
                            {regular | !checkUsePoint ? null : <p>
                                <span>알갱이사용</span>
                                <span>{payPoint !== 0 ? "-" : null} {stringPrice(payPoint)}<i> </i></span>
                            </p>}

                        </div>
                        <p className="total_price">
                            <span>최종 결제금액</span>
                            {regular ? <span> {devicePrice !== 0 ? <>{coupon !== 0 ? stringPrice(totalPayment + afterCouponApply ) :
                                        stringPrice(totalPayment)}</> : stringPrice(price.price - discountPrice + afterCouponApply)}<i>원</i></span> :
                                <span>{coupon !== 0 ? stringPrice(totalPayment + afterCouponApply ) : stringPrice(totalPayment)}<i>원</i></span>}
                        </p>
                        { isMobile && <p className="noArea"></p>}
                        <div className="agreement_wrap">
                            {regular ? <React.Fragment>
                                <div className="agreement_cont">
                                    <div className="checks full_check">
                                        <input type="checkbox" name="chk_agree3" onChange={(e)=> handleRegular1(e)}  checked={agreeRegular1 ? true : false} />
                                        <label htmlFor="chk_agree3">정기/반복결제 이용약관 동의</label>
                                    </div>
                                    <div className="agreement">
                                        <p>정기/반복결제 이용약관 동의 <span>[필수]</span></p>
                                        <p onClick={() => setRegularService(true)}>약관보기 <img src={photoreview_next} alt="보기" /></p>
                                    </div>
                                </div>
                                <div className="agreement_cont">
                                    <div className="checks full_check">
                                        <input type="checkbox" name="chk_agree2" onChange={(e)=> handleRegular2(e)}  checked={agreeRegular2 ? true : false}  />
                                        <label htmlFor="chk_agree2">정기배송 및 해지에 관한 내용 동의</label>
                                    </div>
                                    <div className="agreement delivery">
                                        <p onClick={() => setRegularService2(true)}>약관보기 <img src={photoreview_next} alt="보기" /></p>
                                    </div>
                                </div>
                                <div className="agreement_guide">
                                    <DotGuide
                                        size={isPc ? "small" : "tiny"}
                                        color="gray"
                                        text="정기배송의 해지는 3회차 배송완료 10일 후 [마이페이지]-[나의정기배송]-[정기배송 상세] 하단에서 가능합니다."
                                    />
                                    <DotGuide
                                        size={isPc ? "small" : "tiny"}
                                        color="gray"
                                        text="스마트 디바이스는 3회 구독 약정 혜택으로, 만약 3회 이용 횟수 충족 전에 중도 해지할 경우 이미 받아보신 혜택에 대한 반환금이 청구됩니다."
                                    />
                                    <DotGuide
                                        size={isPc ? "small" : "tiny"}
                                        color="gray"
                                        text="4회차 부터 언제든 해지 및 변경이 가능합니다."
                                    />
                                    <DotGuide
                                        size={isPc ? "small" : "tiny"}
                                        color="gray"
                                        text="다음 회차에서 공급사의 사정에 따라 사료 가격이 변경될 수 있습니다."
                                    />
                                </div>
                            </React.Fragment> : <div className="agreement_cont">
                                <div className="checks full_check">
                                    <input type="checkbox" name="chk_agree1" id="chk_agree1" onChange={(e) => handleNormal(e)} checked={checkNormal ? true : false} />
                                    <label htmlFor="chk_agree1">결제 진행 필수 동의</label>
                                </div>
                                <div className="agreement">
                                    <p>개인정보 수집·이용 및 처리 동의 <span>[필수]</span></p>
                                    <p onClick={() => setPrivacy(true)}>약관보기 <img src={photoreview_next} alt="보기" /></p>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="button_wrap">
                        {regular ?
                            checkAddress && checkAllItemsForPayment(email, userInputData.userName, phone) && agreeRegular1 && agreeRegular2 ?
                                <Button color="blue" onClick={() => {onClickRegularPay(); handleSubmit(onSubmit);}} size="large" fullWidth>결제하기</Button> :
                                <Button color="gray" size="large" fullWidth>결제하기</Button> :
                            checkAddress && checkAllItemsForPayment(email, userInputData.userName, phone) && checkNormal ?
                                <Button color="blue" onClick={() => {onClickPayment(); handleSubmit(onSubmit);}} size="large" fullWidth>결제하기</Button> :
                                <Button color="gray" onClick={alertInputCheck} size="large" fullWidth>결제하기</Button> }
                    </div>
                </div>

                {/*우측 최종 결제금액 정보 */}
                <div className="shopping_right">
                    <div className={ScrollActive ? "total_price_fix scroll" : "total_price_fix"}>
                        <div className="total_price_top">
                            <div className="price_info">
                                <p>
                                    <span>상품금액</span>
                                    <span>{stringPrice(price.price + devicePrice)}<i>원</i></span>
                                </p>
                                <p>
                                    <span>상품할인금액</span>
                                    <span>{price.discount !== 0 ? "-" : null} {regular ? devicePrice === 0 ? "-" : null : null} {stringPrice(discountPrice)}<i>원</i></span>
                                </p>
                                <p>
                                    <span>배송비</span>
                                    <span>{price.shipprice !== 0 ? "+" : null} {checkFree ? 0 : stringPrice(price.shipprice)}<i>원</i></span>
                                </p>
                                {shipPrice>price.shipprice ?
                                <p>
                                  <span>도서산간</span>
                                  <span>{`+${stringPrice(shipPrice-price.shipprice)}`}<i>원</i></span>
                                </p> : null}
                                <p>
                                    <span>쿠폰할인금액</span>
                                    <span>{coupon !== 0 ? stringPrice(afterCouponApply) : 0}<i>원</i></span>
                                </p>
                                {regular | !checkUsePoint ? null : <p>
                                    <span>알갱이사용</span>
                                    <span>{payPoint !== 0 ? "-" : null} {stringPrice(payPoint)}<i> </i></span>
                                </p>}
                            </div>
                            <div className="total_price">
                                <p>
                                    <span>최종 결제금액</span>
                                    {regular ? <span> {devicePrice !== 0 ? <>{coupon !== 0 ? stringPrice(totalPayment + afterCouponApply ) :
                                            stringPrice(totalPayment)}</> : stringPrice(price.price - discountPrice + afterCouponApply)}<i>원</i></span> :
                                        <span>{coupon !== 0 ? stringPrice(totalPayment + afterCouponApply ) : stringPrice(totalPayment)}<i>원</i></span>}
                                </p>
                            </div>
                        </div>
                        <div className="total_price_bottom">
                            <div className="agreement_wrap">
                                {regular ? <React.Fragment>
                                    <div className="agreement_cont">
                                        <div className="checks full_check">
                                            <input type="checkbox" name="chk_agree3" id="chk_agree3"  onChange={(e)=> handleRegular1(e)} checked={agreeRegular1 ? true : false} />
                                            <label htmlFor="chk_agree3">정기/반복결제 이용약관 동의</label>
                                        </div>
                                        <div className="agreement">
                                            <p>정기/반복결제 이용약관 동의 <span>[필수]</span></p>
                                            <p onClick={() => setRegularService(true)}>약관보기 <img src={photoreview_next} alt="보기" /></p>
                                        </div>
                                    </div>
                                    <div className="agreement_cont">
                                        <div className="checks full_check">
                                            <input type="checkbox" name="chk_agree2" id="chk_agree2" onChange={(e)=> handleRegular2(e)} checked={agreeRegular2 ? true : false} />
                                            <label htmlFor="chk_agree2">정기배송 및 해지에 관한 내용 동의</label>
                                            <div className="agreement">
                                                <p onClick={() => setRegularService2(true)}>약관보기 <img src={photoreview_next} alt="보기" /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="agreement_guide">
                                        <DotGuide
                                            size={isPc ? "small" : "tiny"}
                                            color="gray"
                                            text="정기배송의 해지는 3회차 배송완료 10일 후 [마이페이지]-[나의정기배송]-[정기배송 상세] 하단에서 가능합니다."
                                        />
                                        <DotGuide
                                            size={isPc ? "small" : "tiny"}
                                            color="gray"
                                            text="스마트 디바이스는 3회 구독 약정 혜택으로, 만약 3회 이용 횟수 충족 전에 중도 해지할 경우 이미 받아보신 혜택에 대한 반환금이 청구됩니다."
                                        />
                                        <DotGuide
                                            size={isPc ? "small" : "tiny"}
                                            color="gray"
                                            text="4회차 부터 언제든 해지 및 변경이 가능합니다."
                                        />
                                        <DotGuide
                                            size={isPc ? "small" : "tiny"}
                                            color="gray"
                                            text="다음 회차에서 공급사의 사정에 따라 사료 가격이 변경될 수 있습니다."
                                        />
                                    </div>
                                </React.Fragment> : <div className="agreement_cont">
                                    <div className="checks full_check">
                                        <input type="checkbox" name="chk_agree1" id="chk_agree1" onChange={(e) => handleNormal(e)} checked={checkNormal ? true : false} />
                                        <label htmlFor="chk_agree1">결제 진행 필수 동의</label>
                                    </div>
                                    <div className="agreement">
                                        <p>개인정보 수집·이용 및 처리 동의 <span>[필수]</span></p>
                                        <p onClick={() => setPrivacy(true)}>약관보기 <img src={photoreview_next} alt="보기" /></p>
                                    </div>
                                </div>}
                                {regular ? checkAddress && checkAllItemsForPayment(email, userInputData.userName, phone) && agreeRegular1 && agreeRegular2 ? <Button color="blue" onClick={onClickRegularPay} size="large" fullWidth>결제하기</Button> : <Button color="gray" size="large" fullWidth>결제하기</Button>
                                    : checkAddress && checkAllItemsForPayment(email, userInputData.userName, phone) && checkNormal ? <Button color="blue" onClick={onClickPayment} size="large" fullWidth>결제하기</Button> : <Button color="gray" onClick={alertInputCheck} size="large" fullWidth>결제하기</Button> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 배송지선택 / 우편번호검색 / 약관보기 팝업 */}
            {Address &&
                <AddressDialog
                    visible={Address}
                    setAddress={setAddress}
                    formValues={formValues}
                    token={userInfo}
                    onClose={(data)=>{
                        let newdata = {
                            addrName:  data.title,
                            userName : data.name,
                            zoneCode : data.zipcode,
                            address : data.address,
                            addressDetail : data.detail_address,
                            p1: data.phone.slice(0,3),
                            p2: data.phone.slice(3,7),
                            p3: data.phone.slice(7,11),
                            e1: data.email.split("@")[0],
                            e2: data.email.split("@")[1],
                            e3: "1",
                            is_basic : data.is_default
                        }
                        setUserInputData(newdata);
                        setAddress(false);
                    }}
                />
            }
            <PostCode
                getvalue={userInputData}
                setValue={setUserInputData}
                visible={postCodePopup}
                setPostCodePopup={setPostCodePopup}
                onCancel={() => setPostCodePopup(!postCodePopup)}
            />
            <PrivacyTermsPopup visible={privacy} onCancel={closePrivacy} />
            <RegularUseTermsPopup visible={regularTerms} onCancel={closeRegularTerms} />
            <RegularServicePopup visible={regularService} onCancel={closeRegularService} />
            <RegularServicePopup2 visible={regularService2} onCancel={closeRegularService2} />
        </OrderContainer>
    );
};

const SmallButton = styled(Button)`
  width: 100px;
  height: 36px;
  font-size: 0.875rem;
  border-radius: 0;
  font-weight: 400;
  @media only screen and (max-width: 960px) {
    height: 32px;
    width: 83px;
    font-size: 0.813rem;
    font-weight: 500;
    margin-left: 8px;
  }
`


const BorderSmallButton = styled(Button)`
  border: 1px solid #ccc;
`

const OrderContainer = styled.div`
  padding: 80px 0;

  & .wrap_title {
    display: flex;
    align-items: flex-end;
    & .purchase_step {
      margin-left: auto;
      & ul {
        display: flex;
        & li {
          padding-right: 26px;
          line-height: 22.5px;
          color: #888;
          font-size: 0.938rem;
          &:not(:last-child) {
            background: url(${arr_more}) no-repeat right 11px center;
            background-size: 4.5px 9px;
          }
          &.active {
            font-weight: 500;
            color: #222;
          }
        }
      }
    }
  }

  & .shopping_wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;

    & .shopping_left {
      flex-basis: 1040px;
      & > div:not(:first-child) {
        margin-top: 80px;
      }

      & .sub_tit_wrap {
        display: flex;
        align-items: center;
        padding-bottom: 16px;
        border-bottom: 1px solid #ccc;

        & h3 {
          font-size: 1.25rem;
          line-height: 30px;
          color: #222;
        }
        & .checks {
          margin-left: 20px;
          font-size: 1rem;
          color: #222;
          line-height: 24px;
        }
        & span {
          margin-left: auto;
          color: #515355;
          font-size: 0.875rem;
          line-height: 21px;
        }
        & .right_area {
          margin-left: auto;
          & .refresh_btn {
            padding: 7px 12px;
            font-size: 0.875rem;
            color: #515355;
            line-height: 21px;
            & img {
              vertical-align: middle;
              margin-top: -2px;
            }
          }
        }
      }
    }

    & .sub_cont_wrap {
      padding: 20px;
      border-bottom: 1px solid #eee;
    }

    & .button_wrap {
      width: 500px;
      margin: 60px auto 0 !important;
    }
  }

  & .name_required {
      font-size: 0.75rem;
      font-weight: 400;
      margin-top: 5px;
      color: #e00;
    }

  & .address_form {
    margin-bottom: 80px;
    & > div {
      &.address_info {
        border-bottom: 1px solid #eee;
      }
    }
    & .row {
      display: flex;
      & .form_title {
        flex-basis: 210px;
        padding-top: 11px;
      }
      & .form_cont {
        width: 500px;
      }
      &.address {
        margin-top: 10px;
        & input:disabled {
          color: #999;
        }
        & p:not(:first-child) {
          margin-top: 10px;
        }
        & span:first-child {
          display: flex;
          & input {
            width: calc(100% - 130px);
          }
        }
      }

      &.phone {
        margin-top: 20px;
        & .form_cont {
          & p {
            display: flex;
            align-items: center;
            & span {
              display: inline-block;
              width: 18px;
              text-align: center;
              color: #222;
            }
            & select {
              width: 140px;
            }
            & input {
              width: calc((100% - 176px) / 2);
            }
          }
        }
      }

      &.email {
        margin-top: 20px;
        & .form_cont {
          & div {
            display: flex;
            align-items: center;
            & p {
              display: flex;
              width: 66%;
              align-items: center;
              & input {
                &.emailId {
                  width: 140px;
                }
                &.emailDomain {
                  width: calc(100% - 168px);
                }
              }
              & span {
                display: inline-block;
                width: 28px;
                text-align: center;
                color: #222;
              }
            }
            & select {
              width: calc(34% - 10px);
              margin-left: 10px;
            }
          }
        }
        & .checks {
          margin-top: 20px;
        }
      }
    }
    & .delivery_msg {
      & input {
        margin-top: 10px;
      }
    }
  }

  & .orderitem_wrap {
    & .sub_tit_wrap {
      background: url(${sublist_open}) no-repeat right 15px center;
      cursor: pointer;
      &.active {
        background: url(${sublist_close}) no-repeat right 15px center;
      };
      & h3 {
        & span {
          font-weight: 400;
          font-size: 1.25rem !important;
          line-height: 30px !important;
          color: #222 !important;
          & b {
            color: #1EA0FA;
            font-weight: 500;
          }
        }
      }
    }

    & .sub_cont_wrap {
      display: block;
      padding: 20px 0;
      border-bottom: 1px solid #eee;

      & .item_list {
        display: none;
        align-items: center;
        padding: 0 20px;
        &:not(:first-child) {
          padding-top: 20px;
          margin-top: 20px;
          border-top: 1px solid #eee;
        }
      }

      &.default_list {
        & .item_list:first-child {
          display: flex;
        }
      }

      &.active {
        padding: 20px 0;
        //border-bottom: 1px solid #eee;
        border-bottom: none;

        & .item_list {
          display: ${(props) => props.item.length === 1 ? "none" : "flex"};
          &:first-child {
            display: ${(props) => props.item.length === 1 ? "none" : "flex"};
          }
        }
      }

      & .item_img {
        position: relative;
        overflow: hidden;
        flex: 80px 0 0;
        margin-right: 30px;
        &::after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
        img{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      & .item_info {
        display: flex;
        flex-grow: 1;
        & .item_name {
          font-size: 1rem;
          line-height: 24px;
          color: #222;
          & span {
            display: block;
            margin-top: 6px;
            line-height: 21px;
            font-size: 0.875rem;
            color: #888;
          }
        }

        & .item_option {
          font-size: 0.875rem;
          color: #888;
          line-height: 21px;
        }
      }

      & .item_count_price {
        display: flex;
        align-items: center;
        margin-left: auto;
        & .item_count {
          font-size: 1rem;
          line-height: 24px;
          color: #222;
        }
        & .item_price {
          width: 180px;
          margin-left: 40px;
          text-align: right;
          & span {
            display: block;
            &.price {
              font-weight: 500;
              font-size: 1.25rem;
              line-height: 30px;
              color: #222;
              & i {
                font-size: 1rem;
                line-height: 24px;
                font-weight: 400;
              }
            }
            &.before_discount {
              font-size: 0.875rem;
              color: #888;
              line-height: 21px;
              text-decoration: line-through;
            }
          }
        }
      }

      & .no_device_checks {
        &.radios input[type=radio] + label:before {
          margin-right: 20px;
        }
      }
    }

    &.device_wrap {
      & .sub_cont_wrap {
        display: block;
        padding: 0;
        border-bottom: none;

        & .item_list {
          display: none;
        }

        &.active {
          padding: 20px 0;
          border-bottom: 1px solid #eee;
          & .item_list {
            display: flex;
            & .item_list {
              display: flex;
            }
          }
        }
      }
    }
  }

  & .coupon_wrap {
    & .row {
      display: flex;
      &:not(:first-child) {
        margin-top: 10px;
      }

      & .form_title {
        flex-basis: 210px;
        padding-top: 11px;
      }
      & .form_cont {
        flex-basis: 500px;
        & p {
          display: flex;
          & input {
            width: calc(100% - 130px);
          }
        }
        & .small_desc {
          margin-top: 10px;
          & p {
            color: #515355;
            font-size: 0.875rem;
            line-height: 21px;
            font-weight: 500;
          }
          & .guide {
            & p {
              margin-top: 6px;
              padding-top: 10px;
              padding-bottom: 10px;
              padding-left: 6px;
              padding-right: 6px;
              font-size: 0.813rem;
              line-height: auto;
              font-weight: 400;
              background: #fdf8df;
              white-space: pre-wrap;
            }
          }
        }
      }
    }
  }

  & .paymethod_wrap {
    & .sub_tit_wrap {
      & p {
        margin-left: auto;
        color: #515355;
        font-size: 0.875rem;
      }
    }
    & .sub_cont_wrap {
      padding: 30px 20px;
      & .row {
        display: flex;
        & .radios {
          &:not(:first-child) {
            margin-left: 80px;
          }
        }
      }
    }
  }

  & .total_price_wrap {
    & .sub_cont_wrap {
      & p {
        display: flex;
        align-items: center;
        &:not(:first-child) {
          margin-top: 20px;
        }
        & span {
          line-height: 24px;
          color: #222;
          &:last-child {
            margin-left: auto;
          }
        }
      }
    }

    & .total_price {
      display: flex;
      align-items: center;
      padding: 23px 20px;
      background: #f6f6f6;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      & span {
        &:first-child {
          color: #222;
          font-size: 1.125rem;
          line-height: 27px;
          font-weight: 500;
        }
        &:last-child {
          margin-left: auto;
          color: #1EA0FA;
          font-weight: 700;
          line-height: 30px;
          font-size: 1.25rem;
          & i {
            font-size: 1rem;
            line-height: 24px;
            font-weight: 400;
          }
        }
      }
    }

    & .agreement_wrap {
      padding: 30px 20px;
      border-bottom: 1px solid #eee;
      & .agreement_cont {
        display: flex;
        align-items: center;
        &:not(:first-child) {
          margin-top: 20px;
        }
        & .checks {
          & label {
            font-weight: 500;
            line-height: 24px;
            font-size: 1rem;
          }
        }
        & .agreement {
          display: flex;
          margin-left: auto;
          & p {
            font-size: 0.875rem;
            color: #222;
            line-height: 21px;
            & span {
              color: #999;
            }
            &:last-child {
              margin-left: 10px;
              cursor: pointer;
              & img {
                vertical-align: middle;
                margin-top: -3px;
                margin-left: 2px;
              }
            }
          }
        }
      }
      & .agreement_guide {
        margin-top: 10px;
        padding: 20px 33px;
        background: #f9f9f9;
        & p + p {
          margin-top: 5px;
        }
        & span {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }


  & .shopping_right {
    position: relative;
    width: 400px;
    margin-left: 20px;
    &.scroll {
      padding-top: 80px;
    }

    & .total_price_fix {
      position: sticky;
      top: 140px;
      left: 0;
      width: 100%;
      border: 1px solid #ddd;
      &.scroll {
        top: 140px;
      }

      & .total_price_top {
        background: #f6f6f6;
        padding: 30px 30px 23px 30px;

        & .price_info {
          padding-bottom: 22px;
          border-bottom: 1px solid #ccc;
          & p {
            display: flex;
            align-items: center;
            &:not(:first-child) {
              margin-top: 13px;
            }

            & span {
              font-size: 1rem;
              color: #222;
              line-height: 24px;
            }

            & span:last-child {
              margin-left: auto;
              & i {
                font-size: 0.875rem;
                line-height: 21px;
              }
            }
          }
        }

        & .total_price {
          margin: 23px 0 0 0;
          & p {
            display: flex;
            align-items: center;
          }

          & span {
            color: #222;
          }

          & span:first-child {
            font-size: 1.125rem;
            color: #222;
            line-height: 27px;
            font-weight: 500;
          }

          & span:last-child {
            font-size: 1.375rem;
            font-weight: 700;
            line-height: 33px;
            margin-left: auto;
            & i {
              font-size: 1.125rem;
              line-height: 27px;
              font-weight: 400;
            }
          }
        }
      }

      & .total_price_bottom {
        padding: 30px;
        & .agreement_cont {
          &:not(:first-child) {
            margin-top: 20px;
          }
        }
        & .checks {
          align-items: center;
          margin-right: 0;
          & label {
            font-size: 1rem;
            line-height: 24px;
            font-weight: 500;
          }
          & .agreement {
            margin: 0 0 0 auto;
            padding: 0;
          }
        }
        & .agreement {
          display: flex;
          margin-top: 6px;
          padding-left: 36px;
          & p {
            font-size: 0.813rem;
            line-height: 19px;
            & span {
              color: #999;
            }
            &:last-child {
              cursor: pointer;
              margin-left: auto;
            }
          }
        }
        & .agreement_guide {
          margin: 10px 0 20px;
          padding: 15px;
          background: #f9f9f9;
          & p + p {
            margin-top: 8px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    padding: 40px 0;

    & .wrap_title {
      display: block;
      & .purchase_step {
        display: none;
      }
    }

    & .shopping_wrap {
      display: block;
      margin-top: 0;

      & .shopping_left {
        width: 100%;
        & > div:not(:first-child) {
          margin-top: 0;
        }

        & .sub_tit_wrap {
          position: relative;
          padding: 30px 15px;
          border-bottom: none;

          & h3 {
            line-height: 28px;
          }
          & .checks {
            position: absolute;
            bottom: -23px;
            left: 15px;
            margin-left: 0;
            font-size: 0.938rem;
            line-height: 22px;
          }
          & .right_area {
            & .refresh_btn {
              padding: 6px 5px;
              font-size: 0.813rem;
              line-height: 19px;
              & img {
                vertical-align: middle;
                margin: -4px 0 0 3px;
                width: 14px;
              }
            }

            div {
              margin-left: 8px;
            }
          }
        }
      }

      & .sub_cont_wrap {
        padding: 0 15px 30px 15px;
        border-bottom: 1px solid #eee;
      }

      & .button_wrap {
        width: 100%;
        padding: 0 15px;
        margin: 0 !important;
      }
    }

    & .address_form {
      margin: 45px 0 0 0;
      & .sub_cont_wrap {
        border-bottom: 1px solid #eee;
      }
      & > div {
        &.address_info {
          border-bottom: 1px solid #eee;
          padding-bottom: 20px;
        }
      }
      & .row {
        display: block;
        & .form_title {
          width: 100%;
          padding-top: 0;
          margin-bottom: 10px;
          font-size: 0.938rem;
          line-height: 22px;
        }
        & .form_cont {
          width: 100%;
          & input, & select {
            padding: 10px 15px;
            color: #222;
            font-size: 0.938rem;
          }
        }
        &.address {
          margin-top: 20px;
          & p:not(:first-child) {
            margin-top: 5px;
          }
          & p:first-child {
            display: flex;
            & input {
              width: calc(100% - 115px);
            }
            & div {
              margin-left: 5px;
              font-size: 0.875rem;
              font-weight: 500;
            }
          }
        }

        &.phone {
          margin-top: 20px;
          & .form_cont {
            & p {
              & span {
                width: 22px;
              }
              & select, & input {
                width: calc((100% - 44px) / 3);
              }
            }
          }
        }

        &.email {
          margin-top: 20px;
          & .form_cont {
            & div {
              display: block;
              & p {
                width: 100%;
                & input {
                  width: calc((100% - 28px) / 2);
                }
                & span {
                  display: inline-block;
                  width: 28px;
                  text-align: center;
                  color: #222;
                }
              }
              & select {
                width: 100%;
                margin-left: 0;
                margin-top: 5px;
              }
            }
          }
          & .checks {
            margin-top: 20px;
            & label {
              font-size: 0.938rem;
            }
          }
        }
      }
      & .delivery_msg {
        padding-top: 24px;
        & input {
          margin-top: 10px;
        }
      }
    }

    & .orderitem_wrap {
      & .sub_tit_wrap {
        background: url(${(props) => props.item.length > 1 ? sublist_open : sublist_open}) no-repeat right 15px center;
        cursor: ${(props) => props.item.length > 1 ? "pointer" : "normal"};
        &.active {
          background: url(${(props) => props.item.length > 1 ? sublist_close : sublist_open}) no-repeat right 15px center;
        };
        & h3 {
          & span {
            font-weight: 400;
            font-size: 1.25rem !important;
            line-height: 30px !important;
            & b {
              color: #1EA0FA;
              font-weight: 500;
            }
          }
        }
      }

      & .sub_cont_wrap {
        display: block;
        align-items: center;
        &.active {
          padding: 0 15px 30px 15px;
        }

        ////////////////////

        & .item_list {
          padding: 0;
          align-items: flex-start;
          & .item_img {
            margin-right: 10px;
          }
          & .item_info {
            display: block;
            flex-grow: 1;
            & .item_name {
              line-height: 22px;
            }

            & .item_option {
              margin-top: 4px;
              font-size: 0.813rem;
              line-height: 18px;
            }
          }

          & .item_count_price {
            display: flex;
            align-items: center;
            justify-content: start;
            margin-left: 0;
            margin-top: 4px;
            & .item_count {
              font-size: 0.813rem;
              line-height: 18px;
              order: 2;
            }
            & .item_price {
              width: auto;
              margin: 0 3px 0 0;
              text-align: left;
              order: 1;
              & span {
                display: inline-block;
                &.price {
                  font-weight: 500;
                  font-size: 0.813rem;
                  line-height: 18px;
                  & i {
                    font-size: 0.813rem;
                    font-weight: 400;
                  }
                }
                &.before_discount {
                  display: none;
                }
              }
            }
          }
        }
        & .no_device_checks {
          &.radios input[type=radio] + label:before {
            margin-right: 10px;
          }
        }
      }

      &.device_wrap {
        & .sub_cont_wrap {
          display: block;
          border-bottom: none;

          & .item_list {
            display: none;
            align-items: center;
            border-bottom: none;
            &:not(:first-child) {
              padding-top: 20px;
              border-top: 1px solid #eee;
            }
            & div:first-child {
              margin-right: 0;
            }
            & .item_img {
              flex: 75px 0 0;
            }
          }

          &.active {
            padding: 0 15px 20px 15px;
            & .item_list {
              display: flex;
            }
          }
        }
      }
    }

    & .coupon_wrap {
      & .row {
        display: block;
        &:not(:first-child) {
          margin-top: 20px;
        }

        & .form_title {
          width: 100%;
          padding-top: 0;
          margin-bottom: 10px;
          line-height: 22px;
          font-size: 0.938rem;
        }
        & .form_cont {
          width: 100%;
          & p {
            display: flex;
            & input {
              width: calc(100% - 105px);
            }
            & div {
              margin-left: 5px;
              font-size: 0.875rem;
              font-weight: 500;
            }
          }
          & .small_desc {
            & .guide {
              & p {
                font-size: 0.75rem;
                line-height: 18px;
              }
            }
          }
        }
      }
    }

    & .paymethod_wrap {
      & .sub_tit_wrap {
        & p {
          font-size: 0.813rem;
        }
      }
      & .sub_cont_wrap {
        padding: 0 15px 30px;
        & .row {
          display: block;
          & .radios {
            &:not(:first-child) {
              margin-left: 0;
              margin-top: 20px;
            }
          }
        }
      }
    }

    & .total_price_wrap {
      & .sub_cont_wrap {
        border-bottom: none;
        & p {
          &:not(:first-child) {
            margin-top: 16px;
          }
          & span {
            line-height: 24px;
            color: #222;
            &:first-child {
              font-size: 0.875rem;
            }
            &:last-child {
              margin-left: auto;
              & i {
                font-size: 0.875rem;
              }
            }
          }
        }
      }

      & .total_price {
        width: calc(100% - 30px);
        margin: 0 auto;
        padding: 20px 0 25px;
        background: #fff;
        border-top: 1px solid #ccc;
        border-bottom: none;
        & span {
          &:first-child {
            font-size: 1rem;
            line-height: 22px;
          }
          &:last-child {
            font-size: 1.375rem;
            & i {
              font-size: 1.125rem;
              line-height: 25px;
            }
          }
        }
      }

      & .agreement_wrap {
        display: block;
        padding: 30px 15px;
        border-bottom: none;
        & .agreement_cont {
          display: block;
          position: relative;
          & .checks {
            margin-right: 0;
          }
          & .agreement {
            display: flex;
            margin-left: 0;
            margin-top: 6px;
            & p {
              font-size: 0.813rem;
              line-height: 19px;
              flex-grow: 1;
              &:first-child {
                padding-left: 35px;
              }
              &:last-child {
                margin-left: 0;
                cursor: pointer;
                text-align: right;
                & img {
                  vertical-align: middle;
                  margin-top: -3px;
                  margin-left: 2px;
                }
              }
            }
            &.delivery {
              position: absolute;
              right: 0;
              top: -5px;
            }
          }
        }
        & .agreement_guide {
          padding: 20px 15px;
          & span {
            font-size: 13px;
            line-height: 19.5px;
          }
        }
      }
    }


    & .shopping_right {
      display: none;
    }
  }

  & .noArea {
    width: 100%;
    height: 8px;
    background: #f6f6f6;
  }
`;

export default OrderPage;