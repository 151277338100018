import React, {useCallback, useRef, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import Button from "pages/components/Button";
import {validateCodeNum} from "../utils/Validated";
import AuthTimer from "./components/AuthTimer";
import {useSelector} from "react-redux";

const UserInfoUpdate = () => {
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const [inputState, setInputState] = useState({phone_number: "", number_check: ""}); //인풋에 작성하는 값
  const {phone_number, number_check} = inputState; //인풋 작성의 구조분해 할당 값
  const [hideNumber, setHideNumber] = useState(true);
  const [counter, setCounter] =useState(0); //카운터 default 값
  const [phone, setPhone] = useState('');
  const [checkPhoneCode, setCheckPhoneCode] = useState(false)
  const [phoneNumSend, setPhoneNumSend] = useState({ //번호 전송시 변하는 버튼 값
    btnColor: "gray",
    phoneDisabled: false,
    codeNumDisabled: true,
  })

  const checkPhone = useCallback( //휴대폰 번호 전송
      (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}api/auth/validPhone`, {phone_number: phone})
            .then((res)=>{
              if(res.data.success){
                setCounter(180)
                setPhoneNumSend( {
                  ...phoneNumSend,
                  btnColor: "blue",
                  phoneDisabled: true,
                  codeNumDisabled: false})
              }else{
                console.log(res)
              }
            }).catch((error)=>{
          console.log(error)
        })
      }
  )

  const checkNumber = useCallback( //인증된 번호 전송
      (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}api/auth/checkPhone`,{
          phone_number: phone, "code": number_check, "type": "general"
        }).then((res) => {
          if(res.data.type === "success"){  //인증번호 승인
            alert("인증되었습니다.")
            setPhoneNumSend( {
              ...phoneNumSend,
              btnColor: "blue",
              phoneDisabled: false,
              codeNumDisabled: true})
            setCheckPhoneCode(true)
          }else{
            alert("인증번호가 맞지않습니다.")
          }
        }).catch((error)=>{
          console.log(error)
        })
      }
  )

  const onInputChange = (e) => {
    const value = e.target.value;
    switch (e.target.name) {
      case "phone_number":
        setPhone(value);
        break;
    }
  }

  const changePhone = async () => {
    try{
      axios.defaults.headers.common["cid"] = userInfo.data.cid;
      axios.defaults.headers.common["encoded"] = userInfo.data.encoded;
      const res = await axios.post(`${process.env.REACT_APP_API_URL}api/user/changePhone`, {
        phone_number: phone
      })
      document.location.href="/mypage/userinfofix"
    }catch(e){
      console.log(e)
    }
  }

  const inputHandler = useCallback((key) => (e) => {
        return setInputState({
          ...inputState,
          [key]: e.target.value,
        });
      },[inputState]
  )

  const phoneref = useRef();
  const phoneCheckref = useRef();

  
	return (
    <FindPwPhoneContainer>
      <div className="find_wrap">
        <p><span>휴대폰 번호</span>를 입력해 주세요.</p>
        <div className="find_form chk_phone">
          <form>
            <p>
              <input type="text" name="phone_number" value={phone} disabled={phoneNumSend.phoneDisabled ? true : false}
                tabIndex="5" placeholder="숫자만 입력해주세요." ref={phoneref} onChange={onInputChange} value={phone} />
               <SmallButton onClick={checkPhone} size="small">인증번호 받기</SmallButton>
            </p>
            <p>
              <input type="text" name="phone_check" value={number_check} disabled={phoneNumSend.codeNumDisabled ? true : false} onFocus={(e) => {setHideNumber(!hideNumber)}} onChange={inputHandler("number_check")} placeholder="인증번호 입력" tabIndex="6" ref={phoneCheckref} />
              <SmallButton color={phoneNumSend.btnColor} onClick={validateCodeNum(number_check) ? checkNumber : null} onClick={checkNumber} style={{"height": "46px"}}>인증번호 확인</SmallButton>
            </p>
            {phoneNumSend.phoneDisabled ? <span>
                              {counter === 0 ? <p className="sub_desc"><span className="counter_msg">인증번호를 받지못했습니다.</span></p> : <p className="sub_desc">인증번호를 발송하였습니다.
                                <span className="counter_msg">유효시간 : <AuthTimer counter={counter} setCounter={setCounter} /></span></p>}
                        </span> : null}
            <div className="button_wrap">
              {checkPhoneCode ? <MainButton color="blue" fullWidth onClick={checkPhone} onClick={() => {changePhone()}}>인증하기</MainButton> : <MainButton color="gray" fullWidth onClick={checkPhone} >인증하기</MainButton> }
            </div>
          </form>
        </div>
      </div>
    </FindPwPhoneContainer>
	);
}

const MainButton = styled(Button)`
  &:not(:first-child) {
    margin-top: 15px;
  }
	@media only screen and (max-width: 960px) {
		height: 50px;
    font-weight: 700;
    font-size: 1rem;
	}
`

const SmallButton = styled(Button)`
  width: auto;
  padding: 0 14px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
  @media only screen and (max-width: 960px) {
    flex: 100px 0 0;
    padding: 0;
    border-radius: 0;
    font-size: 14px;
  }
`

const FindPwPhoneContainer = styled.div`
  max-width: 1480px;
  margin: 0 auto;

  & .find_wrap {
    width: 500px;
    margin: 0 auto;
    & > p {
      margin: 228px 0 60px;
      font-size: 26px;
      font-weight: 300;
      line-height: 39px;
      & span {
        font-weight: 400;
      }
    }
    & .find_form {
      p:not(:first-child) {
        margin-top: 10px;
      }
    }

    & .chk_phone {
      p {
        display: flex;
      }
    }

    & .button_wrap {
      margin: 80px 0 228px;
    }
  }

  & .sub_desc {
    margin-top: 8px;
    color: #1ea0fa;
    font-size: 0.75rem;
    line-height: 18px;
  }

  & .counter_msg{
    color: #ff2727 !important;
    font-size: 0.75rem;
    line-height: 18px;
  }

  @media only screen and (max-width: 960px) {
    margin: 0;
    & .find_wrap {
      width: 100%;
      margin: 0;
      padding: 0 15px;
      & > p {
        margin: 60px 0 30px;
        font-size: 22px;
        line-height: 33px;
        & span {
          font-weight: 400;
        }
      }
      & .find_form {
        p:not(:first-child) {
          margin-top: 5px;
        }
        input {
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
      }

      & .button_wrap {
        margin: 40px 0 46px;
      }
    }
  }
`

export default UserInfoUpdate;