import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Button from "./components/Button";
import welcome_img from "images/welcome_img.png";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {useSelector, useDispatch} from "react-redux";

const MainButton = styled(Button)`
	@media only screen and (max-width: 960px) {
    font-weight: 700;
    font-size: 1rem;
	}
`

const JoinCompletePage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userLogin.userInfo);
    const [checkUser, setCheckUser] = useState("")
    const [coupon, setCoupon] = useState("")
    const checkType = checkUser?.data?.login_type === "kakao" ? "kakao_account" : "new_account"
    const goToMain = () => {
        history.push("/");
    }
    console.log(checkType)
    useEffect(()=>{
        try {
            const fetchProfile = async () => {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
                    },
                }
                try {
                    const res = await axios.get(`${process.env.REACT_APP_API_URL}api/user/info`, config);
                    setCheckUser(res.data)
                } catch (error) {
                    console.log(error)
                }
            }
            fetchProfile()
        } catch (e) {
            console.log(e)
        }

    },[setCheckUser])

    const handleSubmitCoupon = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
            },
        }
        try{
            const res = await axios.post(`${process.env.REACT_APP_API_URL}api/user/issue/coupon`, {
                "coupon_code" : checkType
            }, config)
            console.log(res)
            {checkType === "kakao_account" ? document.location.href = "/" : document.location.href = "/mypage/coupon"}
        }catch(e){
            console.log(e)
        }
    }


    return (
    <JoinCompleteContainer>
      <p className="welcome"><img src={welcome_img} alt="만나서 반가워요 :)" /></p>
      <p className="welcome_tit">반려가족의<br />행복 파트너, <b>바램펫</b></p>
      <p className="welcome_txt">행복한 반려생활을 위해<br />바램펫이 함께 하겠습니다.</p>
        {checkUser?.data?.login_type !== "kakao" ? <div className="button_wrap">
            {/*<MainButton fullWidth onClick={handleSubmitCoupon}>신규회원 쿠폰 받기</MainButton>*/}
            <MainButton color="white" onClick={goToMain} fullWidth outline>계속 쇼핑하기</MainButton>
        </div> : <div className="button_wrap">
            <MainButton color="blue" onClick={handleSubmitCoupon} fullWidth>계속 쇼핑하기</MainButton>
        </div>}

    </JoinCompleteContainer>
	);
}

const JoinCompleteContainer = styled.div`
  width: 100%;
  min-width: 1480px;
  margin: 64px auto;
  text-align: center;

  & .welcome {
    margin-bottom: 40px;

    img {
      width: 240px;
    }
  }

  & .welcome_tit {
    margin-bottom: 10px;
    font-size: 2.5rem;
    line-height: 52px;

    b {
      font-weight: 500;
    }
  }

  & .welcome_txt {
    margin-bottom: 60px;
    line-height: 30px;
    font-size: 1.25rem;
    color: #515355;
  }

  & .button_wrap {
    max-width: 440px;
    width: 100%;
    margin: 0 auto;
  }

  @media only screen and (max-width: 960px) {
    min-width: 100%;
    margin: 50px auto;
    padding: 0 15px;
    & .welcome {
      margin-bottom: 30px;
      img {
        width: 160px;
      }
    }

    & .welcome_tit {
      margin-bottom: 14px;
      font-size: 1.75rem;
      line-height: 36.4px;

      b {
        font-weight: 500;
      }
    }

    & .welcome_txt {
      margin-bottom: 60px;
      line-height: 27px;
      font-size: 1.125rem;
    }
  }
`

export default JoinCompletePage;