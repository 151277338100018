import React from 'react';
import styled from 'styled-components';
import UseTerms from './components/UseTerms';

const TermsPage = () => {
  return (
    <TermsContainer>
      <div className="board_wrap">
        <div className="wrap_title board_title">
          <h2>이용약관</h2>
        </div>
        <div className="cont_wrap">
          <UseTerms />
        </div>
      </div>
    </TermsContainer>
  )
}

const TermsContainer = styled.div`
  width: 1200px;
  margin: 0 auto;
  & .cont_wrap {
    padding: 60px 0;
    margin: 0 auto;

    & p {
      font-size: 0.875rem;
      color: #515355;
      line-height: 21px;
      & b {
        font-weight: 500;
      }
    }
  }
  @media only screen and (max-width: 960px) {
    width: 100%;
    & .cont_wrap {
      padding: 0;
      & .dialog_wrap {
        & h3 {
          margin-bottom: 0;
          text-align: left;
        }
      }
    }
  }
`;

export default TermsPage;