import React, {useEffect, useState, useRef} from 'react';
import Styled from 'styled-components';
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';
import ProgressBar from "@ramonak/react-progress-bar";
import arr_next from "images/arr_next.png";
import arr_prev from "images/arr_prev.png";
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { useMediaQuery } from 'react-responsive';
import axios from "axios";
import default_thumb from "images/default_thumb.png";

SwiperCore.use([Navigation, Pagination, Scrollbar]);


const DetailRate = ({ info, point, bests, setReview, setReviewItem, id, type}) => {
    const isPc = useMediaQuery({ query: '(min-width: 960px)' })
    const [starReview, setStarReview] = useState(0)
    const progressBar = [...Array(5).keys()]
    const [photoTotal, setPhotoTotal] = useState(0);
    const [photoReviews, setPhotoReviews] = useState([]);
    const [photoStart, setPhotoStart] = useState(0);
    //포토 구매평 모아보기 슬라이드 네비게이션
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    let files = [];
    bests.map(best =>{
        files.push(best.Files[0].publicPath);
    })

    const handleImgError = (e) => {
        e.target.src = default_thumb;
    }
    const getThumbnail = (url, type) =>{
        if(type == 'video'){
            let thumbnail = url.replace('/review', '/review-thumbnail');
            return thumbnail.substring(0, thumbnail.lastIndexOf(".")) + ".jpg";
        }else{
            return url.replace('/review', '/review-thumbnail');
        }
    }


    useEffect(() => {
        const num = Number(info.avg)
        if (!num) {
            return
        }

        setStarReview(num.toFixed(1))
    }, [info])
    useEffect(() => {
        return () => {
            setPhotoReviews([]);
            setPhotoTotal(0);
            setStarReview(0);
            setPhotoStart(0);
        }
    }, [])
    useEffect(async () => {
        let length = 4;
        if(!isPc){
            length = 100;
        }
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/review/photo/${id}?type=${type}&start=${photoStart}&length=${length}`, config);
        setPhotoTotal(res.data.total);
        setPhotoReviews(res.data.data);
    }, [id,type])


    const getPercent = (idx)=>{
        let thisPercent = point.find(p=>p.point == idx)
        if(thisPercent){
            return (thisPercent.count * 100 /  info.count)
        }else{
            return 0;
        }
    }
    return (
        <Wrapper>
            <div className="rate_wrap">
                <div className="starRate">
                    <StyledRate size="26" disabled allowHalf={true} value={starReview} />
                    {
                        starReview !== 0 ?
                            <h3>{starReview}</h3>:
                            <h3></h3>
                    }
                    <span>(총 {info.count}개의 구매평)</span>
                </div>
                <div className="progressBarWrap">
                    <div className="progressBar">
                        {progressBar.map((bar, idx) => (
                            <div key={idx}>
                                <span className="progress_span">{-idx + 5}점</span>
                                <ProgressBar className="bar" bgColor={-idx + 5 == 5 ? "#1EA0FA" : "#999999"} width={"100%"} height={"6px"} isLabelVisible={false} completed={getPercent(-idx + 5)} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="photoReview">
                <h3>포토 구매평 모아보기</h3>
                <Swiper
                    spaceBetween={4}
                    slidesPerView={isPc ? "5" : "auto"}
                    navigation={isPc ? {
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    } : false}
                    onInit={(swiper) => {
                        setTimeout(() => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current
                            swiper.params.navigation.nextEl = navigationNextRef.current
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                        })
                    }}
                    scrollbar={isPc ? false : true}
                    className="photoReview_swiper"
                >
                    {files.map((el, idx) => (
                        <SwiperSlide key={idx}>
                            <div  className="img_wrap">
                                <img src={getThumbnail(el)} key={idx} alt="구매평 이미지" onError={handleImgError} onError={handleImgError} onClick={() => {
                                    const selectedBestPhoto = bests.filter(v => v.Files.findIndex(v => v.publicPath === el) >= 0)[0];
                                    const selectedData = photoReviews.filter(v => v.id === selectedBestPhoto.id)[0];

                                    setReviewItem(selectedData)
                                    setReview(true);
                                }}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                {isPc &&
                    <>
                        <div ref={navigationPrevRef} className="swiper-button-prev">이전</div>
                        <div ref={navigationNextRef} className="swiper-button-next">다음</div>
                    </>
                }
            </div>
        </Wrapper>
    );
};


const Wrapper = Styled.div`
    max-width: 1480px;
    width: 100%;
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 100px 0 80px;
    padding: 0 60px;
    height: 255px;

    @media only screen and (max-width: 960px) {
        flex-direction: column;
        min-width: 100%;
        height: auto;
        margin: 30px 0 50px;
        padding: 0;
    }

    & .rate_wrap {
        display: flex;
        flex-basis: 700px;
        align-items: center;

        @media only screen and (max-width: 960px) {
            width: 100%;
            flex-basis: auto;
            padding: 30px 15px;
        }
    }
    
    & .starRate{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-right: 9%;
        min-width: 140px;

        @media only screen and (max-width: 960px) {
            margin: 0;
            width: 50%;

            .rc-rate-star {
                font-size: 18px;
            }
        }
        
        & h3{
            font-size: 40px;
            color: #222;
            margin: 19px 0;
            line-height: 52px;
        }
        & span {
            font-size: 14px;
            color: #888;

            @media only screen and (max-width: 960px) {
                font-size: 0.813rem;
            }
        }
    }

    & .progressBarWrap {
        width: 52%;
    }
    
    @media only screen and (max-width: 960px) {
        & .progressBarWrap {
            width: 50%;
            text-align: center;
        }
    }

    & .progressBar{
        @media only screen and (max-width: 960px) {
            display: inline-flex;
            flex-direction: column;
            transform: rotate(-90deg);
            padding-left: 20px;

            & > div{
                display: flex;
                position: relative;
                align-items: center;
                width: 100px;
                margin-bottom: 22px;
            }
        }
        
        & > div{
            display: flex;
            position: relative;
            align-items: center;
            width: 100%;
        }

        & > div:last-child {
            margin-bottom: 0;
        }
        
        & .progress_span {
            width: 30px;
            margin-right: 20px;
            display: inline-block;
            color: #515355;
            font-size: 14px;
            line-height: 21px;

            @media only screen and (max-width: 960px) {
                position: absolute;
                bottom: -5px;
                left: -30px;
                margin: 0 auto;
                text-align: center;
                transform: rotate(90deg);
                font-size: 0.813rem;
            }
        }

        & .bar {
            width: calc(100% - 22px);

            @media only screen and (max-width: 960px) {
                width: 100px;
            }
        }
    }
    
    & .photoReview {
        position: relative;
        margin-left: auto;
        flex-basis: 720px;

        @media only screen and (max-width: 960px) {
            flex-basis: auto;
            width: 100%;
            background: #fff;
            padding: 20px 0 0 15px;
        }

        h3{
            font-size: 16px;
            color: #222;
            margin-bottom: 20px;
            line-height: 24px;
            text-align: center;
            font-weight: 400;

            @media only screen and (max-width: 960px) {
                font-weight: 500;
                text-align: left;
            }
        }

        & .swiper-container {
            width: 90%;
            height: 120px;
        }

        & .swiper-button-prev {
            content: "";
            text-indent: -9999px;
            top: 104px;
            left: 0;
            background:transparent url(${arr_prev}) no-repeat center;
            &::after {
                display: none;
            }
        }
        & .swiper-button-next {
            content: "";
            text-indent: -9999px;
            top: 104px;
            right: 0;
            background:transparent url(${arr_next}) no-repeat center;
            &::after {
                display: none;
            }
        }

        @media only screen and (max-width: 960px) {
            & .swiper-container {
                width: 100%;
                height: auto;
                & .swiper-slide {
                    width: 100px;
                    height: 100px;
                }
            }
            & .swiper-button-prev {
                display: none;
            }
            & .swiper-button-next {
                display: none;
            }
            & .swiper-container-horizontal > .swiper-scrollbar {
                position: relative;
                bottom: 0;
                left: 0;
                height: 3px;
                width: 97%;
                margin-top: 15px;
            }
        }

        .photoReview_swiper {
            & .img_wrap {
              position: relative;
                width: 120px;
                height: 120px;
                cursor: pointer;

                & img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            @media only screen and (max-width: 960px) {
                display: flex;
                flex-direction: column-reverse;
                margin: 0;
                white-space: nowrap;

                & .swiper-slide {
                    width: 100px !important;
                    height: 100px;
                    & .img_wrap {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        @media only screen and (max-width: 960px) {
            button {
                display: none;
            }
        }
    }
`
const StyledRate = Styled(Rate)`
  &.rc-rate {
        font-size: ${({ size }) => size}px;
        
        & .rc-rate-star{
            margin-right: 0px;
        }
    }
    
   & .rc-rate-star-half .rc-rate-star-first, .rc-rate-star-full .rc-rate-star-second{
       color: #1EA0FA;
    }
  }
`

export default DetailRate;