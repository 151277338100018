import React, {useState, useEffect, useRef} from 'react';
import axios from "axios"
import Styled from "styled-components";
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';
import banner_ad from "images/review_ad.png"
import m_banner_ad from "images/m/detail_banner_ad.png"
import ItemReviewChat from "./ItemReviewChat"
import Pagination from './Pagination';
import {Link} from "react-router-dom";
import {useMediaQuery} from 'react-responsive';
import SwiperCore, {Navigation, Scrollbar} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import m_photoreview_next from "images/m/arr_my_nav.png";
import m_photoreview_prev from "images/m/photoreview_prev.png";
import {BsChevronLeft} from 'react-icons/bs'
import {BsChevronRight} from 'react-icons/bs'
import icon_coolicon from "images/icon/icon_coolicon.png"
import default_thumb from "images/default_thumb.png";

SwiperCore.use([Navigation, Scrollbar]);

const ItemReview = ({id, type, setReview, setReviewItem}) => {
    const isPc = useMediaQuery({query: '(min-width: 961px)'})
    const isMobile = useMediaQuery({query: '(max-width: 960px)'})
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [photoTotal, setPhotoTotal] = useState(0);
    const [photoReviews, setPhotoReviews] = useState([]);
    const [photoStart, setPhotoStart] = useState(0);
    const [reviews, setReviews] = useState([]);
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const handleImgError = (e) => {
        e.target.src = default_thumb;
    }
    const getThumbnail = (url, type) => {
        if (url === null) { return }
        if (type === 'video') {
            let thumbnail = url.replace('/review', '/review-thumbnail')
            return thumbnail.substring(0, thumbnail.lastIndexOf(".")) + ".jpg";
        } else {
            return url.replace('/review', '/review-thumbnail');
        }
    }
    const fetchReview = async (page) => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/review/list/${id.id}?type=${type}&start=${(page - 1) * 5}&length=5`, config);
        for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].Order) {
                if (typeof res.data.data[i].Order.option === "string") {
                    res.data.data[i].Order.option = JSON.parse(res.data.data[i].Order.option)
                }
                if (typeof res.data.data[i].Order.product === "string") {
                    res.data.data[i].Order.product = JSON.parse(res.data.data[i].Order.product)
                }
            }
        }
        setTotal(res.data.total);
        setReviews(res.data.data);
    }
    const fetchPhotoReviews = async (page) => {
        let length = 4;
        if (isMobile) {
            length = 100;
        }
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/review/photo/${id.id}?type=${type}&start=${photoStart}&length=${length}`, config);
        setPhotoTotal(res.data.total);
        setPhotoReviews(res.data.data);
    }
    useEffect(()=>{
        return () =>{
            setReviews([]);
            setPhotoReviews([])
            setCurrentPage(1);
            setTotal(0);
            setPhotoTotal(0);
            setPhotoStart(0);
        }
    },[])

    useEffect(() => {
        fetchReview(currentPage);
    }, [currentPage, id]);
    useEffect(() => {
        fetchPhotoReviews();
    }, [photoStart, id]);

    const indexOfLast = currentPage * 5;
    const indexOfFirst = indexOfLast - 5;

    const currentPosts = (tmp) => {
        let currentPosts = 0;
        currentPosts = tmp.slice(indexOfFirst, indexOfLast);
        return currentPosts;
    }

    //구매평 -> 포토구매평 슬라이드 네비게이션
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    return (
        <Review>
            <div className="container">
                {/*포토구매평*/}
                <PhotoReview>
                    <div className="photo_tit">
                        <h3>포토구매평<span>({photoTotal})</span></h3>
                        <Link to="#">더보기</Link>
                        {isPc &&
                            <div className="page_wrap">
                                <span className="page_left" onClick={() => {
                                    if (photoStart == 0) {
                                        return
                                    } else {
                                        setPhotoStart(photoStart - 4)
                                    }
                                }}><BsChevronLeft size="12" alt="이전페이지"/></span>
                                <span className="page_right" onClick={() => {
                                    if (photoStart + 4 >= photoTotal) {
                                        return
                                    } else {
                                        setPhotoStart(photoStart + 4)
                                    }
                                }}><BsChevronRight size="12" alt="다음페이지"/></span>
                            </div>
                        }
                    </div>
                    {isPc &&
                        <ul className="photoReview_ul">
                            {photoReviews.map((e) => {
                                if(e.is_dummy){
                                    return (
                                        <li className="photoReview_li" key={e.id} onClick={() => {
                                            setReviewItem(e);
                                            setReview(true)
                                        }}>
                                            <div className="reviewDescWrap">
                                                <StyledRate size="15" disabled value={e.point}/>
                                                <div className="reviewDesc">
                                                    <p>{e.content}</p>
                                                </div>
                                            </div>
                                            <div className="thumb_img">
                                                    {<div className="review_img">
                                                        <img src={e.d_images} onError={handleImgError} alt="리뷰이미지3"/>
                                                    </div>}
                                            </div>
                                        </li>
                                    )
                                }
                                return (
                                    <li className="photoReview_li" key={e.id} onClick={() => {
                                        setReviewItem(e);
                                        setReview(true)
                                    }}>
                                        <div className="reviewDescWrap">
                                            <StyledRate size="15" disabled value={e.point}/>
                                            <div className="reviewDesc">
                                                <p>{e.content}</p>
                                            </div>
                                        </div>
                                        <div className="thumb_img">
                                            {e.Files[0].mimetype.indexOf("image") > -1 ?
                                                <div className="review_img">
                                                    <img src={getThumbnail(e.Files[0].publicPath, 'image')} onError={handleImgError} alt="리뷰이미지3"/>
                                                </div> : <div className="review_img">
                                                    <img src={e.image} onError={handleImgError} alt="리뷰이미지3"/>
                                                </div>}
                                            {e.Files[0].mimetype.indexOf("video") > -1 ?
                                                <img src={getThumbnail(e.Files[0].publicPath, 'video')} onError={handleImgError} alt="리뷰이미지3"/> : null
                                            }
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    }
                    {isMobile &&
                        <>
                            <Swiper
                                scrollbar={true}
                                navigation={{
                                    prevEl: navigationPrevRef.current,
                                    nextEl: navigationNextRef.current,
                                }}
                                onInit={(swiper) => {
                                    setTimeout(() => {
                                        swiper.params.navigation.prevEl = navigationPrevRef.current
                                        swiper.params.navigation.nextEl = navigationNextRef.current
                                        swiper.navigation.destroy()
                                        swiper.navigation.init()
                                        swiper.navigation.update()
                                    })
                                }}
                                spaceBetween={10}
                                slidesPerView={'auto'}
                                className="photoReview_ul">
                                {photoReviews.map((el) => {
                                    if(!el.Files){
                                        <SwiperSlide key={el.id} className="photoReview_li" onClick={() => {
                                            setReviewItem(el);
                                            setReview(true)
                                        }}>
                                            <div className="reviewDescWrap">
                                                <StyledRate size="15" disabled value={el.point}/>
                                                <div className="reviewDesc">
                                                    <p>{el.content}</p>
                                                </div>
                                            </div>
                                            <div className="thumb_img">
                                                <img src={process.env.REACT_APP_URL + el.image} alt="리뷰이미지"/>
                                            </div>
                                        </SwiperSlide>
                                    }
                                    return (
                                    <SwiperSlide key={el.id} className="photoReview_li" onClick={() => {
                                        setReviewItem(el);
                                        setReview(true)
                                    }}>
                                        <div className="reviewDescWrap">
                                            <StyledRate size="15" disabled value={el.point}/>
                                            <div className="reviewDesc">
                                                <p>{el.content}</p>
                                            </div>
                                        </div>
                                        <div className="thumb_img">
                                            {el.is_dummy ? <img src={process.env.REACT_APP_URL + el.d_images} onError={handleImgError}  alt="리뷰이미지"/>
                                                : <img src={process.env.REACT_APP_URL + el.Files[0].publicPath} onError={handleImgError}  alt="리뷰이미지"/> }
                                        </div>
                                    </SwiperSlide>
                                )}
                                )}
                            </Swiper>
                            <div ref={navigationPrevRef} className="swiper-button-prev">이전</div>
                            <div ref={navigationNextRef} className="swiper-button-next">다음</div>
                        </>
                    }
                </PhotoReview>

                {/*전체구매평*/}
                <ReviewChat>
                    <div className="reviewChat">
                        {/* 독립몰 serect 배너 hidden 처리 */}
                        {/* <div className="banner">
                            {isPc &&
                                <img src={banner_ad} alt="리뷰광고"/>
                            }
                            {isMobile &&
                                <img src={m_banner_ad} alt="리뷰광고"/>
                            }
                        </div> */}
                        <div className="reviewChat_cover">
                            <h3>전체 구매평<span>추천순 리뷰입니다.</span></h3>
                            <div className="reviewChat_cover_ul">
                                <ItemReviewChat reviews={reviews} setReview={setReview} setReviewItem={setReviewItem} />
                                {reviews &&
                                    <Pagination postsPerPage={5} totalPosts={total} paginate={setCurrentPage} currentpage={currentPage}></Pagination>}
                            </div>
                        </div>
                    </div>
                </ReviewChat>

            </div>
        </Review>
    );
};


const Review = Styled.div`
    & .container {
        max-width: 1200px;
        width: 100%;
        padding-top: 80px;
        margin: 0 auto;
        @media only screen and (max-width: 960px) {
          padding-top: 0;
      }
    }
`

const PhotoReview = Styled.div`
    position: relative;
    margin-bottom: 60px;
    padding: 30px;
    border: 1px solid #DDDDDD;

    @media only screen and (max-width: 960px) {
        margin: 0 0 50px 0;
        padding: 0;
        border: none;
    }

    & .photo_tit {
        display: flex;
        @media only screen and (max-width: 960px) {
            padding: 0 15px;
        }
        h3 {
            margin-bottom: 30px;
            font-size: 1.125rem;
            font-weight: normal;
            line-height: 27px;

            @media only screen and (max-width: 960px) {
                margin-bottom: 17px;
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 21px;
            }
        }
        & a {
            margin: 6px 20px 0 auto;
            color: #515355;
            font-size: 0.875rem;

            @media only screen and (max-width: 960px) {
                margin: 5px 90px 0 auto;
            }
        }
    }

    @media only screen and (max-width: 960px) {
        & .swiper-container {
            display: flex;
            flex-direction: column-reverse;
        }
        & .swiper-container-horizontal > .swiper-scrollbar {
            position: relative;
            left: 15px;
            bottom: 0;
            height: 3px;
            width: calc(100% - 30px);
            margin-top: 15px;
        }
    }

    & .page_wrap {
        position: relative;
        & span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 25px;
            cursor: pointer;
            &.page_left {
                top: 30px;
                right: 70px;
                margin: 0;
                left: auto;
                border: 1px solid #ddd;
            }
            &.page_right {
                top: 30px;
                right: 30px;
                margin: 0;
                border: 1px solid #ddd;
            }
        }
    }

    @media only screen and (max-width: 960px) {
        & .page_wrap {
            display: none;
        }
        & div.swiper-button-prev {
            display: inline-block;
            content: "";
            top: 20px;
            right: 55px;
            left: auto;
            width: 40px;
            height: 25px;
            background:#fff url(${m_photoreview_prev}) no-repeat center;
            background-size: 4.5px 9px;
            border: 1px solid #ddd;
            text-indent: -9999px;
            &::after {
                display: none;
            }
        }
        & div.swiper-button-next {
            display: inline-block;
            content: "";
            top: 20px;
            right: 15px;
            left: auto;
            width: 40px;
            height: 25px;
            background:#fff url(${m_photoreview_next}) no-repeat center;
            background-size: 4.5px 9px;
            border: 1px solid #ddd;
            text-indent: -9999px;
            &::after {
                display: none;
            }
        }
    }

    & .photoReview_ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & .photoReview_li{
            display: flex;
            width: calc((100% - 60px) / 2);
            cursor: pointer;
            @media only screen and (max-width: 960px) {
                //flex-basis: 100px;
                &:first-child {
                    margin-left: 15px;
                }
            }

            &:nth-child(-n+2) {
                margin-bottom: 40px;
                @media only screen and (max-width: 960px) {
                    margin-bottom: 0;
                }
            }
            & .reviewDescWrap {
                width: 100%;
            }

            & .thumb_img {
                overflow: hidden;
                flex-basis: 120px;
                height: 120px;
                flex-shrink: 0;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                & .review_img {
                        position: relative;
                        height: 100px;
                        flex: 100px 0 0;
                        cursor: pointer;
                }
                & img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            @media only screen and (max-width: 960px) {
                display: block;
                width: 100px;
                height: 100px;
                & .reviewDescWrap {
                    display: none;
                }
                &:last-child {
                    margin-top: 0;
                }
                & .thumb_img {
                    overflow: hidden;
                    width: 100px;
                    height: 100px;
                }
            }
            & .reviewDesc{
                display: flex;

                & p {
                    word-break: break-word;
                }
            }

            .rc-rate .rc-rate-star {
                height: 22px;
                margin: 0;
                font-size: 18px;

                @media only screen and (max-width: 960px) {
                    display: none;
                }
            }

            p {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                max-height: 3.939rem;
                padding-right: 20px;
                margin-top: 12px;
                line-height: 1.313rem;
                font-size: 0.875rem;
                color: #222;

                @media only screen and (max-width: 960px) {
                    display: none;
                }
            }
        }
    }

    .photoReview_ul::-webkit-scrollbar{
        height: 3px;
    }
    .photoReview_ul::-webkit-scrollbar-thumb{
        height: 3px;
        background-color: #515355;
        border-radius: 3px;
    }
    .photoReview_ul::-webkit-scrollbar-track{
        background-color: #ddd;
    }
    .swiper-button-next,
    .swiper-button-prev {
        display: none;
    }
`

const ReviewChat = Styled.div`
    & .banner {
       margin-bottom: 60px;

       @media only screen and (max-width: 960px) {
            margin-bottom: 50px;
            padding: 0 15px;
        }

       img {
           width: 100%;
       }
    }

    & .reviewChat_cover{
        @media only screen and (max-width: 960px) {
            padding: 0 15px;
        }

        h3{
            display: flex;
            font-size: 1.125rem;
            line-height: 18px;
            margin-bottom: 12px;
            font-weight: 500;
            color: #222;

            @media only screen and (max-width: 960px) {
                font-size: 0.875rem;
                line-height: 21px;
            }

            & span {
                margin-left: auto;
                line-height:21px;
                font-size:0.875rem;
                font-weight: 400;
                color: #515355;

                @media only screen and (max-width: 960px) {
                    font-size: 0.75rem;
                    line-height: 18px;
                }
            }
        }

        & .reviewChat_cover_ul{
            padding: 0 0 20px;
            border-top: 1px solid #222;

            @media only screen and (max-width: 960px) {
                padding: 0;
            }

             .rc-rate .rc-rate-star {
                height: 22px;
                font-size: 18px;
                padding: 0;

                @media only screen and (max-width: 960px) {
                    height: auto;
                    margin: 23px 0 16px;
                    font-size: 13px;
                }
            }

             & .reviewChat_li{
                border-bottom: 1px solid #ddd;
                padding: 0 20px 20px;

                @media only screen and (max-width: 960px) {
                    padding: 0 0 15px 0;
                }

                & .li_tit {
                    position: relative;

                    span {
                        position: absolute;
                        right: 0;
                        top: 20px;
                        line-height: 21px;
                        font-size: 0.875rem;
                        color: #888;
                    }
                }

                & .review_wrap {
                    display: flex;

                    @media only screen and (max-width: 960px) {
                        flex-direction: column;
                    }
                    & > div {
                        width: 100%;
                    }
                    & .reviewChat_options{
                        color: #888;
                        display: block;
                        margin-bottom: 2px;
                        font-size: 0.875rem;
                        line-height: 21px;

                        @media only screen and (max-width: 960px) {
                            display: -webkit-box;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            margin-bottom: 8px;
                        }
                    }

                    & .review_wrap_2 {
                        display: flex;
                        flex-direction: row;
                    }
                    & .reviewDesc{
                        display: flex;
                        width: 100%;
                        justify-content: space-between;

                        & p{
                            display: -webkit-box;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            width: calc(100% - 100px);
                            max-height: 3rem;
                            margin-right: 60px;
                            line-height: 1.5rem;
                            font-size: 1rem;
                            cursor: pointer;

                            @media only screen and (max-width: 960px) {
                                display: -webkit-box;
                                overflow: hidden;
                                width: 100%;
                                max-height: 5.5rem;
                                margin-right: 0;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 4;
                                -webkit-box-orient: vertical;
                                font-size: 0.938rem;
                                line-height: 1.375rem;
                            }
                        }
                    }
                    & .review_img {
                        position: relative;
                        height: 100px;
                        flex: 100px 0 0;
                        cursor: pointer;
                        & img {
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                        }
                        & .img_cnt {
                          display: inline-block;
                          position: absolute;
                          right: 0;
                          bottom: 0;
                          width: 24px;
                          height: 24px;
                          background: rgba(0,0,0,0.5);
                          font-size: 13px;
                          line-height: 24px;
                          font-weight: 500;
                          color: #fff;
                          text-align: center;
                        }

                        @media only screen and (max-width: 960px) {
                            flex: 80px 0 0;
                            width: 80px;
                            height: 80px;
                            & + & {
                                margin-left: 10px;
                            }
                            margin-top: 15px;
                            & .img_cnt {
                              width: 100%;
                              height: 100%;
                              background: rgba(0,0,0,0.3);
                              font-size: 20px;
                              line-height: 80px;
                              font-weight: 700;
                            }
                        }
                        }
                    }
                    & .reply_btn {
                        display: inline-block;
                        margin-top: 14px;
                        font-size: 0.875rem;
                        color: #222;
                        line-height: 21px;
                        cursor: pointer;
                        position: relative;
                        z-index: 1000;
                    }
                    & .reply {
                        display: block;
                        padding: 20px;
                        margin-top: 5px;
                        & p:first-child {
                            margin-bottom: 7px;
                            padding-left: 20px;
                            background: url(${icon_coolicon}) no-repeat left top 4px;
                            font-size: 0.938rem;
                            color: #222;
                            line-height: 22px;
                            font-weight: 700;
                        }
                        & p:last-child {
                            font-size: 0.875rem;
                            color: #222;
                            line-height: 21px;
                        }
                        &.hide {
                            display: none;
                        }
                    }
                }
            }
        }
    }
`

const StyledRate = Styled(Rate)`
  &.rc-rate {
        font-size: ${({size}) => size}px;

        & .rc-rate-star{
            margin: 20px 0px 12px 0;
            @media only screen and (max-width: 960px) {
                margin: 0 !important;
            }
        }
    }

    & .rc-rate-star-half .rc-rate-star-first, .rc-rate-star-full .rc-rate-star-second{
       color: #1EA0FA;
    }
  }
`

export default ItemReview;