import {
    SET_OPTION_QUANTITY,
    ADD_OPTION,
    REMOVE_OPTION,
    CLEAR_OPTIONS,
    CHECK_OPTIONS,  SET_ARR_OPTIONS,
} from "actions/types";
// import {checkOptions} from "../actions/optionActions";

const OptionReducer = (state = { product_option: [], regular_option: [] }, action) => {
    switch (action.type){
        case ADD_OPTION:
            let has = state.product_option.find(op=> op.id === action.payload.id);
            if(has){
                return state;
            }else{
                return Object.assign({}, state, {
                    product_option: [...state.product_option, action.payload]
                })
            }
        case CHECK_OPTIONS:
            if(action.payload.options) {
                return Object.assign({}, state, {
                    product_option: [...state.product_option, action.payload.options]
                })
            }
            break;
        case REMOVE_OPTION:
            return Object.assign({}, state, {
                product_option: state.product_option.filter((el) => el.id !== action.payload.id),
            })
        case SET_OPTION_QUANTITY:
            let idx = state.product_option.findIndex(el => el.id === action.payload.id);
            return Object.assign({}, state, {
                product_option: [ ...state.product_option.slice(0, idx), action.payload,
                    ...state.product_option.slice(idx + 1)]
            });
        case CLEAR_OPTIONS:
            return Object.assign({}, state, {
                product_option: [],
            })
        case SET_ARR_OPTIONS:
            return Object.assign({}, state, {
                product_option: action.payload.arr,
            })

        default: return state;
    }
};

export default OptionReducer;