import React from 'react';
import styled from 'styled-components';

const PrivacyTerms = () => {

  return (
      <PrivacyTermsContainer>
        <div className="cont_wrap">
            <p style={{ lineHeight: "21px"}}>
              ■ '(주)바램시스템’과 회사가 제공하는 서비스 VARRAM(이하 “바램” 또는 “회사”라 함)은 개인정보 보호 법령에 따라 고객의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공지합니다. 본 개인정보처리방침은 회사가 제공하는 바램시스템 웹(모바일웹, 모바일앱) 서비스에 적용됩니다.
            </p><br/>
            <p style={{ lineHeight: "21px"}}>
              ■ 회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
            </p>
          <div className="terms_box">
            <p className="terms_tit">제 1조 (개인정보 수집 동의)</p>
            <div className="terms_cont">
              - 회사는 회원가입시 개인정보처리방침에 대한 사용자 동의를 얻고 있으며, 바램의 서비스 이용을 위해서는 반드시 동의를 해야만 이용할 수 있습니다.
            </div>
          </div>
          <div className="terms_box">
            <p className="terms_tit">제 2조 (개인정보의 수집·이용 목적)</p>
            <div className="terms_cont">
              - 다음의 목적을 위하여 개인정보를 수집 및 이용합니다.
              수집 · 이용하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 별도 안내 후 동의를 받는 등 필요한 조치를 이행할 예정입니다. <br/><br/>

              ① 회원 가입: 회원 가입의 의사 확인, 이용자 식별 및 본인여부, 회원자격 유지· 관리,계약 이행 및 약관변경 등의 고지를 위한 연락, 본인의사 확인 및 민원 등의 고객 고충 처리, 부정이용 방지, 비인가 사용방지 및 서비스 제공 및 계약의 이행, 서비스 이용 및 상담, 문의 등 원활한 의사소통 경로 확보, 반려동물 맞춤형 회원 서비스 제공, 고객 고충 처리를 위한 A/S 등<br /><br />
              ② 재화 또는 서비스 제공 : 물품배송, 서비스 제공, 계약서 청구서 발송, 콘텐츠 제공, 반려동물 맞춤 서비스 제공, 반려동물 동적 데이터를 기반으로한 관리 서비스 제공, 디바이스 이용 데이터 맞춤 서비스 제공, 본인인증, 요금결제·정산, 환불 등<br /><br />
              ③ 서비스 이용에 따른 자동 수집 및 생성 정보 : 반려동물 맞춤 서비스 제공, 반려동물 동적 데이터를 기반으로한 관리 서비스 제공, 디바이스 이용 데이터를 기반으로 한 맞춤 서비스 제공, 본인 확인 및 서비스 이용 통계, 부정 이용 방지, 비인가 사용 방지 등<br /><br />
              ④ 마케팅 및 광고 : 웹 페이지 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계, 앱 접속 빈도 파악 및 반려동물 동적 데이터를 활용한 서비스 이용에 대한 통계, 디바이스 이용에 대한 통계, 이벤트 등 광고성 정보 전달, 서비스 품질에 대한 후기 게시, 서비스 개선 및 처리결과 통보 등
            </div>
          </div>
          <div className="terms_box">
            <p className="terms_tit">제 3조 (개인정보 항목)</p>
            <div className="terms_cont">
              ① 회사가 개인정보 보호법 제32조에 따라 등록․공개하는 개인정보파일의 처리목적은 다음과 같습니다. 회사는 회원가입, 상품구매, 상담, 맞춤 서비스 이용에 필요한 최소한의 개인정보를 ‘필수항목’으로, 그 외 개인정보는 ‘선택항목’으로 구분하여 개별적으로 동의할 수 있는 절차를 마련하고 있습니다. 또한 회사는 이용자가 필요한 최소항늬 개인정보를 제공하지 아니한다는 이유로 해당 서비스 제공을 거부하지 않으며, 수집한 모든 개인정보는 고지한 목적 범위 내에서만 이용합니다.<br />
              <p className="pd10">
                1. 회원가입 : 홈페이지,모바일 웹<br />
                • [필수]이름, 비밀번호, 휴대폰 번호, 이메일, SNS 계정으로 간편 가입 시 ( 네이버,카카오톡, 페이스북, 애플의 이메일 주소 및 전화번호) <br/>
                • [선택] 추천인 아이디, 프로필 사진 <br/>
                2. 회원가입 : 모바일 앱<br />
                • [필수]이름, 닉네임, 비밀번호, 휴대폰 번호, 이메일, SNS 계정으로 간편 가입 시 ( 네이버,카카오톡, 페이스북, 애플의 이메일 주소 및 전화번호) <br/>
                • [선택]반려동물 생년월일, 프로필 사진, 반려동물 프로필 사진 모바일앱, SNS 계정으로 간편 가입 시 성별, 배송지정보, 출생연도, 생일, 카카오톡 채널 추가 상태 및 내역<br/>
                3. 재화 또는 서비스 제공 : 비회원 주문<br />
                • [필수] 주문자 이름, 주문자 휴대폰 번호, 주문자 이메일, 주문자 결제정보, 수취인 이름, 수취인 휴대폰 번호, 수취인 주소 홈페이지,모바일웹, 모바일앱,이메일, 서면양식,고객센터를 통한 전화와 온라인 상담, 이벤트 응모 <br/>
                4. 재화 또는 서비스 제공 : 취소 및 환불<br />
                • [필수]예금주, 은행명, 계좌번호 <br/>
                5. 서비스 이용에 따른 자동 수집 및 생성정보: 디바이스, 모바일 앱<br />
                • [필수] 디바이스ID(토큰), 반려동물 종류, 반려동물 이름, 품종, 나이, 성별, 중성화 여부, 몸무게, 체형정보, 활동량, 반려동물 동적데이터(식사량,음수량,체중 등), 반려동물 질병 내용 등 <br/>
                • [선택] 반려동물 프로필 사진 <br/><br/>

                ※ 회사의 서비스 이용 과정에서 서비스 이용기록, 방문기록, 불량 이용기록, IP주소, 쿠키, MAC주소, 모바일 기기 정보(앱 버전, OS 버전), ADID/IDFA(광고식별자), 이벤트 로그, 앱 이용 시 모바일 기기 정보(UUID, 푸시토큰) 등의 정보가 자동으로 생성되어 수집될 수 있습니다. <br/>
                ※ 진행하는 이벤트에 따라 수집 항목이 상이할 수 있으므로 응모 시 별도 동의를 받으며, 목적 달성 즉시 파기합니다. <br/><br/>
              </p>
              ② 회사는 정보통신망법 등에서 규정하고 있는 민감 정보를 수집하지 않습니다.<br /><br />
              ③ 회사는 개인식별이 가능한 개인정보를 수집하는 경우에는 이용자로부터 동의를 받습니다. 이용자가 동의 버튼을 클릭하거나, 회원 정보 수정 등을 통해 추가로 수집하는 개인정보를 기재한 후 저장한 경우 개인정보 수집에 동의한 것으로 봅니다.<br /><br />
            </div>
          </div>
          <div className="terms_box">
            <p className="terms_tit">제 4조 (개인정보의 처리 및 보유 기간) </p>
            <div className="terms_cont">
              <p>① 회사는 법령에 따른 개인정보 보유·이용기간 또는 이용자로부터 개인정보 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</p><br/>
              <p>② 회원 가입 시 수집된 개인정보는 수집·이용에 관한 동의일로부터 &lt;회원 탈퇴 시 파기 &gt; 까지 위 이용목적을 위하여 보유·이용됩니다. 다만 아래의 사유로 인하여 보관이 필요한 경우 외부와 차단된 별도의 DB 또는 테이블에 분리·보관 됩니다.</p>
              <p className="pd10">
                1. 전자상거래 등에서의 소비자보호에 관한 법률 계약 또는 청약철회 등에 관한 기록 : 5년<br />
                2. 대금 결제 및 재화 등의 공급에 관한 기록 : 5년 <br />
                3. 소비자의 불만 또는 분쟁 처리에 관한 기록 : 6개월 <br />
                4. 표시·광고에 관한 기록 : 6개월<br />
                5. 국세기본법 세법기 규정하는 모든 거래에 관한 장부 및 증빙서류 : 5년<br />
                6. 전자금융거래법 전자금융거래에 관한 기록 : 5년<br />
                7. 통신비밀보호법 서비스 방문기록 : 3개월<br /><br/>

                ※ 외부 사이트 연동을 통한 로그인/회원가입을 한 경우에는 연동해제와 함께 ‘회사’가 운영하는 ‘앱’또는 ‘웹’에서 별도로 탈퇴 처리를 하여야 회원가입이 해지됩니다. <br/>
              </p>
            </div>
          </div>
          <div className="terms_box">
            <p className="terms_tit">제 5조(개인정보의 제3자 제공 및 목적 외 이용의 제한)</p>
            <div className="terms_cont">
              ① 회사는 이용자의 개인정보를 수집 시 명시한 범위 내에서만 개인정보를 처리하며, 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다.<br /><br />
              ② 고객이 회사의 반려동물 맞춤 정보 추천 및 반려동물 건강 데이터 제공 서비스를 이용하는 경우 회사는 정보주체의 동의를 얻어 “회사”와 연계된 단체, 기관에게 다음 각호와 같이 정보를 제공할 수 있습니다.<br />
              <p className="pd10">
                1. 회원정보 : 성명, 닉네임, 생년월일, 이메일, 휴대폰전화번호, 주소, 문의 정보, 구매이력<br />
                2.반려동물 정보 : 반려동물 등록 정보(종류, 성별, 이름, 몸무게 등), 반려동물 동적 데이터, 반려동물 질병 정보<br />
              </p><br/>
              ③ 제 1항에도 불구하고 다음의 경우에는 이용자 또는 제3자의 이익을 부당하게 침해할 우려가 있을 때를 제외하고는 이용자의 개인정보를 이용자의 동의 없이 제3자에게 제공하거나 목적 외의 용도로 이용할 수 있습니다. <br/>
              <p className="pd10">
                1. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우<br />
                2. 공공기관이 법령 등에서 정하는 소관 업무의 수행을 위하여 불가피한 경우 <br />
                3. 이용자 또는 그 법정 대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 업는 경우로서 명백히 이용자 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우<br />
                4. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우<br />
                5. 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관업무를 수행할 수 없는 경우로서 보호위원회의 심의/의결을 거친 경우<br />
                6. 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한 경우<br />
                7. 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우<br />
                8. 법원의 재판업무 수행을 위하여 필요한 경우<br />
                9. 형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우<br />
              </p>
            </div>
          </div>
          <div className="terms_box">
            <p className="terms_tit">제 6조 (개인정보처리의 위탁)</p>
            <div className="terms_cont">
              ① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.<br />
              <p className="pd10">
                1. ㈜롯데글로벌로지스 : 상품 배송 서비스 제공<br />
                2. ㈜케이지이니시스 : 고객의 이용대금 결제<br />
                3. ㈜코리아포트원 : 결제시스템 연동<br />
                4. Amazon Web Services, Inc. : 데이터 관리 보관<br />
                5. ㈜더화이트커뮤니케이션 : 고객 상담 시스템 제공<br />
                6. 수의사 : 반려동물 건강 데이터 통계 작성, 반려동물 맞춤 서비스 자문<br />
                - 회원 탈퇴 시 또는 위탁 계약 종료 시 까지 개인정보 처리 업무 위탁
              </p><br/>
              ② 회사는 위탁계약 체결 시 위탁업무 수행목적 외 개인정보 처리금지, 기술적 관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리 감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.<br /><br />
              ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
            </div>
          </div>
          <div className="terms_box">
            <p className="terms_tit">제 7조 (이용자의 권리, 의무 및 행사 방법)</p>
            <div className="terms_cont">
              ① 이용자는 회사에 대하여 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.<br />
              <p className="pd10">
                1. 개인정보의 처리에 관한 정보를 제공받을 권리<br />
                2. 개인정보의 처리에 관한 동의 여부를 결정할 권리<br />
                3. 개인정보의 처리 여부를 확인하고 개인정보에 대한 열람(사본 발급을 포함)을 요구할 권리<br />
                4. 개인정보의 처리 정지, 정정·삭제 및 파기를 요구할 권리<br />
                5. 개인정보의 처리로 인하여 발생한 피해를 신속하고 공정한 절차에 따라 구제받을 권리<br /><br/>
              </p>
              ② 제1항에 따른 권리 행사는 회사에 대해 서면(전자문서 또는 전자우편을 포함) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다. 다만, 관련 법령에서 개인정보가 수집 대상으로 명시되어 있는 경우 회사는 이용자에게 삭제할 수 없음을 알려 드립니다.<br /><br />
              ③ 이용자가 개인정보의 오류 등에 대한 정정을 요청한 경우에 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br /><br />
              ④ 이용자는 제1항에 따른 권리 행사를 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있으며, 이 경우 이용자는 회사에 대하여 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. <br /><br />
              ⑤ 이용자는 고유식별정보 등 개인정보의 관리 책임을 부담하며, 타인의 개인정보를 도용하거나 사용권한을 넘어 사용하여서는 아니되며, 이로 인하여 발생되는 일체의 법적 책임은 이용자가 부담합니다.<br /><br />
              ⑥ 이용자는 서비스의 이용과 관련하여 타인의 개인정보를 훼손하여서는 아니됩니다. 만일 이용자가 회사의 서비스와 직·간접적인 영역(온라인 또는 오프라인)에서 타인의 개인정보를 훼손할 경우 그 훼손으로 인하여 회사 및 이용자에게 발생하는 일체의 유·무형적 손해에 대하여 법적 책임을 부담합니다. <br /><br />
            </div>
          </div>
          <div className="terms_box">
            <p className="terms_tit">제 8조(개인정보의 파기)</p>
            <div className="terms_cont">
              ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br /><br />
              ② 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우, 회사는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. <br/><br/>
              ③ 이용자가 회원탈퇴를 요청할 경우, 제공한 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 또는 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 이용자의 개인정보는 제 3조 2항에 따라 파기됩니다.<br /><br />
              ④ 개인정보 파기의 절차 및 방법은 다음과 같습니다. <br />
              - 파기절차: 회사는 파기 사유가 발생한 개인정보를 선정하고, 개인정보 보호책임자의 승인 후 개인정보를 파기합니다. <br/>
              - 파기방법: 회사는 전자적 파일 형태로 처리된 정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 의하여 처리된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다. <br/><br/>
              ⑤ 개인정보 보호 책임자는 개인정보 파기 시행 후 파기 결과를 확인합니다.<br /><br />
            </div>
          </div>
          <div className="terms_box">
            <p className="terms_tit">제 9조 (모바일 앱 사용 시 광고 식별자(ADID/IDFA) 수집)</p>
            <div className="terms_cont">
              회사는 이용자의 ADID/IDFA를 수집할 수 있습니다. ADID/IDFA란 모바일 앱 이용자의 광고 식별 값으로서, 사용자의 맞춤 서비스 제공이나 더 나은 환경의 광고를 제공하기 위한 측정을 위해 수집될 수 있습니다.<br /><br />
              <p className="pd10">
                [거부방법]<br />
                - Android : 설정 > 구글(구글설정) > 광고 > 광고 맞춤설정 선택 해제<br />
                - IOS : 설정 > 개인 정보 보호 > 광고 > 광고 추적 제한<br />
              </p>
            </div>
          </div>
          <div className="terms_box">
            <p className="terms_tit">제 10조 (온라인 맞춤형 광고 서비스)</p>
            <div className="terms_cont">
              회사는 다음과 같이 온라인 맞춤형 광고 사업자가 행태정보를 수집하도록 허용하고 있습니다.<br />
              <p className="pd10">
                1. 행태정보를 수집 및 처리하는 광고 사업자 : 구글, 페이스북, Amplitude, 카카오<br />
                2. 행태정보 수집 방법 : 이용자가 당사 웹사이트 방문하거나 앱을 실행할 때 자동 수집 및 전송<br />
              </p>
            </div>
          </div>
          <div className="terms_box">
            <p className="terms_tit">제 11조 ( 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항)</p>
            <div className="terms_cont">
              ① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 쿠키(cookie)를 사용합니다.<br /><br />
              ② 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다. <br/>
              <p className="pd10">
                1. 쿠키의 사용목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br />
                2.  쿠키의 설치·운영 및 거부 : 이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 웹브라우저 별 옵션 선택을 통해 모든 쿠키를 허용 또는 거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 할 수 있습니다. 쿠키 설치 허용여부를 지정하는 방법은 다음과 같습니다.<br />
                Internet Explorer : 도구 메뉴 > 인터넷 옵션 > 개인정보 탭 > 개인정보처리 수준 설정 <br/>
                Chrome : 설정 메뉴 > 고급 설정 표시 선택 > 개인정보-콘텐츠 설정 > 쿠키수준 설정 <br/>
                Firefox : 옵션 메뉴 > 개인정보 > 방문기록-사용자 정의 설정 > 쿠키수준 설정 <br/>
                Safari : 환경설정 메뉴 > 개인정보 탭 > 쿠키 및 웹 사이트 데이터 수준 설정 <br/>
              </p><br/>
              ③ 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다. <br /><br />
            </div>
          </div>
          <div className="terms_box">
            <p className="terms_tit">제 12조 (개인정보 보호책임자)</p>
            <div className="terms_cont">
              ① 회사는 고객의 개인정보보호를 매우 소중하게 생각하며, 고객의 개인정보가 훼손, 침해, 누설되지 않도록 최선을 다하고 있습니다. 개인정보보호 담당자와 연락하기를 원하시면 아래의 이메일로 문의해 주시기 바랍니다. 개인정보 관련 문의사항에 대해 신속하고 성실하게 답변해 드릴 수 있도록 하겠습니다.<br />
              - 서병조 대표 <br/>
              - 고객만족센터 : 1670.8921 <br/>
              - E-mail: support@varram.com <br/><br/>
              ② 기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의 가능합니다. <br/>
              -개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118) <br/>
              -대검찰정 사이버수사과 (www.spo.go.kr / 국번없이 1301) <br/>
              -경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182) <br/>
            </div>
          </div>
          <div className="terms_box">
            <p className="terms_tit">제 13조 (개인정보 처리방침의 공개 및 개정)</p>
            <div className="terms_cont">
              ① 회사는 이용자가 쉽게 확인할 수 있도록 이 개인정보처리방침을 회사의 홈페이지 또는 앱에 게재하는 방식으로 공개합니다.<br /><br />
              ② 개인정보처리방침은 법령, 행정규칙, 행정지침, 회사 서비스 정책 등의 변동에 의하여 개정될 수 있으며, 본 개인정보 처리방침을 개정할 경우에는 최소 7일전에 홈페이지 또는 이메일을 통해 변경 및 내용 등을 공지하도록 하겠습니다. <br/>
              -공고일자 : 2021년 8월 13일 <br/>
              -시행일자 : 2021년 09월 08일 <br/>
            </div>
          </div><br/>
        </div>

        <p>이전 개인정보처리방침은 홈페이지 공지사항에서 확인가능합니다.</p>
      </PrivacyTermsContainer>
  )
}

const PrivacyTermsContainer = styled.div`
  & .terms_box {
    &:first-child {
      margin-top: 0;
      & .terms_tit {
        margin-top: 0;
      }
    }
    &:not(:first-child) {
      margin-top: 10px;
    }

    & .terms_tit {
      margin: 30px 0 10px;
      font-size: 1rem;
      line-height: 24px;
      color: #222;
      font-weight: 500;
    }
    & .terms_cont {
      font-size: 1rem;
      color: #515355;
      line-height: 21px;
    }
    & .pd10 {
      padding-left: 10px;
    }
    @media only screen and (max-width: 960px) {
      & .popup_tit {
        font-size: 0.875rem;
        line-height: 21px;
      }
    }
  }
`

export default PrivacyTerms;