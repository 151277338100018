import React, {useState, useEffect, useRef, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import 'rc-rate/assets/index.css';
import Button from './Button';
import Dialog from './Dialog';
import Rate from 'rc-rate';
import { useMediaQuery } from 'react-responsive';
import photo_del from 'images/icon/photo_del.png';
import m_photo_del from 'images/m/photo_del.png';
import icon_add_photo from 'images/icon/icon_add_photo.png';
import m_icon_add_photo from 'images/m/icon_add_photo.png';
import axios from "axios";
import Loader from "./Loader";
import default_thumb from "images/default_thumb.png";

function MyPageReviewPopup({ visible, onCancel, product }) {
    let is_regular = product.item.RegularProduct ? true : false ;
    let is_regularadmin = false;
    let is_regulardetailadmin = false;
    const isPc = useMediaQuery({ query: '(min-width: 960px)' })
    const [valueRate, setValueRate] = useState(0)
    const [guide, setGuide] = useState("")
    const [checkRevImg, setCheckRevImg] = useState(false);
    const [reviewId, setReviewId] = useState("")
    const dispatch = useDispatch();
    const fileRef= useRef();
    const vidoeRef= useRef();
    const [images,setImages] = useState([]);
    const [isUpload, setIsUpload] = useState("");
    const [typeFile, setTypeFile] = useState("");
    const [loading, setLoading ] = useState(false);
    const [fileSize, setFileSize] = useState(0);
    const [videoFileSize, setVideoFileSize] = useState(0);
    const [video,setVideo] = useState(null);
    const userInfo = useSelector((state) => state.userLogin.userInfo);


    const [data, setData] = useState({
        name: "",
        OrderId: "",
        ProductId: "",
        RegularProductId: "",
        point: 5,
        content: "",
        image: [],
        video: "",
        is_best: 0,
        is_worst: 0
    });
    const {item, orderId, product2} = product
    let RegularProductId = 0;
    let RegularOrderId = 0;

    if (product.item.RegularProductId) {
        is_regular = true;
        RegularProductId = product.item.RegularProductId;
        RegularOrderId = product.item.id;
    }
    const ProductId = item.product ?  product.product.id : item.id;
    let productInfo = item.product? product.product : item
    if (product.review) {
        if (product.review.RegularOrderId && product.orderId) {
            is_regularadmin = true;
            RegularProductId = product.review.RegularProductId;
            RegularOrderId = product.review.RegularOrderId;
            productInfo = product.review.RegularProduct
        } else if (product.review.RegularOrderId && !product.orderId) {
            is_regulardetailadmin = true;
            RegularProductId = product.review.RegularProductId;
            RegularOrderId = product.review.RegularOrderId;
            productInfo = product.item
        }
    }

    useEffect(async () => {
        try{
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                     "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
                }
            }
            var images = [];
            var images_size = 0;
            if (!item.OrderProducts) {
                const reseponseOrderId =
                    !is_regular ?
                        !is_regularadmin ?
                            !is_regulardetailadmin ?
                                await axios.get(`${process.env.REACT_APP_API_URL}api/review/user/popup?ProductId=${ProductId}&cartOptionId=${product.review.cart_option_id}`, config) :
                                await axios.get(`${process.env.REACT_APP_API_URL}api/review/user/popup?RegularProductId=${RegularProductId}`, config):
                            await axios.get(`${process.env.REACT_APP_API_URL}api/review/user/popup?RegularProductId=${RegularProductId}`, config):
                        await axios.get(`${process.env.REACT_APP_API_URL}api/review/user/popup?RegularProductId=${RegularProductId}`, config);
                setData(reseponseOrderId.data);
                setValueRate(reseponseOrderId.data.point);
                setReviewId(reseponseOrderId.data.id)
                reseponseOrderId.data.Files.map(file =>{
                    if(file.mimetype.indexOf('video') > -1) {
                        setVideo({id: file.id, size: file.size, url: file.publicPath});
                        setVideoFileSize(file.size);
                    }else{
                        images.push({id: file.id, size: file.size, url: process.env.REACT_APP_URL+file.publicPath});
                        images_size += file.size;
                    }
                });

                setFileSize(images_size);
                setImages(images);
                setCheckRevImg(!checkRevImg)
            } else {
                const res =
                    !is_regular ?
                        !is_regularadmin ?
                            !is_regulardetailadmin ?
                                await axios.get(`${process.env.REACT_APP_API_URL}api/review/user/popup?OrderId=${orderId}&ProductId=${ProductId}&cartOptionId=${item.OrderProducts[product.idx].option_id}`, config) :
                                await axios.get(`${process.env.REACT_APP_API_URL}api/review/user/popup?RegularOrderId=${RegularOrderId}&RegularProductId=${RegularProductId}`, config):
                            await axios.get(`${process.env.REACT_APP_API_URL}api/review/user/popup?RegularOrderId=${RegularOrderId}&RegularProductId=${RegularProductId}`, config):
                        await axios.get(`${process.env.REACT_APP_API_URL}api/review/user/popup?RegularOrderId=${RegularOrderId}&RegularProductId=${RegularProductId}`, config);

                const guide = await axios.get(`${process.env.REACT_APP_API_URL}api/review/guide`, config);

                setGuide(guide.data.content);
                if (res.data) {
                    setData(res.data);
                    setValueRate(res.data.point);
                    setReviewId(res.data.id)
                    res.data.Files.map(file =>{
                        if(file.mimetype.indexOf('video') > -1) {
                            setVideo({id: file.id, size: file.size, url: file.publicPath});
                            setVideoFileSize(file.size);
                        }else{
                            images.push({id: file.id, size: file.size, url: process.env.REACT_APP_URL+file.publicPath});
                            images_size += file.size;
                        }
                    });

                    setFileSize(images_size);
                    setImages(images);
                    setCheckRevImg(!checkRevImg)
                }
            }
        }catch(error){
            console.log(error);
        }

    },[product, ProductId, orderId, RegularOrderId, RegularProductId])

    const clearState = useCallback(() => {
        setFileSize(0);
        setImages([]);
        setData({name: "",
            OrderId: "",
            ProductId: "",
            point: 5,
            content: "",
            image: [],
            RegularProductId: "",
            video: "",
            is_best: 0,
            is_worst: 0});
        setValueRate(0);
        setCheckRevImg(false);
        setReviewId(0)
    }, [])

    const inputHandler = useCallback((key) => (e) => {
        const counter = document.getElementById("counter");
        let strValue = e.target.value;
        const strLen = strValue.length;
        const strLenComma = strLen.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const maxByte = 2000;
        const maxByteComma = maxByte.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let totalByte = 0;
        let len = 0;
        let str2 = "";
        counter.innerHTML = "("+strLenComma+"/"+maxByteComma+"자)";

        for (let i=0; i < strLen; i++) {
          totalByte++;
          if (totalByte <= maxByte) {
            len = i + 1;
          }
        }

        if (strLen > maxByte){
            alert("최대 "+maxByteComma+"자까지 입력 가능합니다.");
            str2 = strValue.substr(0, len);
            strValue = str2;
            counter.innerHTML = "("+maxByteComma+"/"+maxByteComma+"자)";
        }

        if(key === "content"){
            return setData({
                ...data,
                [key]: strValue,
            });
        }
    }, [data])

    const handleSubmit = async() => {
        if(data.content === ""){
            alert("구매평을 작성하세요.")
            return
        }
        const reviewFormData = new FormData();
        reviewFormData.append("name", data.name)
        if (is_regular || is_regularadmin || is_regulardetailadmin) {
            reviewFormData.append("RegularOrderId",  RegularOrderId)
            reviewFormData.append("RegularProductId", RegularProductId)
        }else {
            reviewFormData.append("OrderId", data.OrderId)
            reviewFormData.append("ProductId", data.ProductId)
            if (product.idx !== undefined) {
                reviewFormData.append("cartOptionId", product.item.OrderProducts[product.idx].option_id);

            }else{
                reviewFormData.append("cartOptionId", product.review.cart_option_id);
            }

        }
        reviewFormData.append("point", valueRate)
        reviewFormData.append("content", data.content)
        reviewFormData.append("image", images.length !== 0 ? images.map(image => image.id).join(',') : null)
        reviewFormData.append("video", video? video.id : null)

        try {
            const response = await axios({
                method: "PUT",
                url: `${process.env.REACT_APP_API_URL}api/review/${reviewId}`,
                data: reviewFormData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "cid" : userInfo.data.cid,
                    "encoded" : userInfo.data.encoded,
                },
            });
            onCancel(response);

        } catch(error) {
            console.log(error)
        }
    }

    function handleChange(value){
        setValueRate(value);
    }

    const delImgUpload = async (e) => {
        e.preventDefault();
        setCheckRevImg(false)
    }

    const handleFileButtonClick = () => {
        fileRef.current.click();
    }
    const handleVideoButtonClick = () => {
        vidoeRef.current.click();
    }

    const handleImageChange = async (e) => {
        try {
            let file = e.target.files[0]
            let checkFileSize = Math.round(file.size / 1024 / 1024 * 100) / 100
            let totalFileSize = Math.round((fileSize + file.size) / 1024 / 1024 * 100) / 100
            if (fileSize + file.size > 1024 * 1024 * 20) {
                alert('20MB 까지 등록할 수 있습니다.\n\n' + '현재파일 용량 : ' + checkFileSize.toFixed(2) + 'MB\n\n등록된 파일용량 : ' + totalFileSize.toFixed(2) + 'MB');
            } else {
                setFileSize(fileSize + file.size);
                const formImg = new FormData();
                formImg.append("upload", e.target.files[0]);
                const imgRes = await axios.post(`${process.env.REACT_APP_API_URL}upload/review`, formImg);

                if (e.target.files && e.target.files[0]) {
                    setTypeFile(e.target.files[0].type);
                    let reader = new FileReader();
                    reader.onload = function (e2) {
                        setImages(oldImages => [...oldImages, {id:imgRes.data.id, size:imgRes.data.size, url:e2.target.result}]);
                        fileRef.current.value = "";
                    }
                    reader.readAsDataURL(e.target.files[0]);
                }
            }
        } catch (error) {
            console.log(error)
        }

    }

    const handleVideoChange = async (e) => {
        try {
            let file = e.target.files[0]
            let checkFileSize = Math.round(file.size / 1024 / 1024 * 100) / 100
            let totalFileSize = Math.round((file.size + videoFileSize) / 1024 / 1024 * 100) / 100
            if (file.size > 1024 * 1024 * 500) {
                 alert('500MB 까지 등록할 수 있습니다.\n\n' + '현재파일 용량 : ' + checkFileSize.toFixed(2) + 'MB\n\n등록된 파일용량 : ' + totalFileSize.toFixed(2) + 'MB');
            } else {
                setVideoFileSize(file.size);
                const formImg = new FormData();
                formImg.append("upload", e.target.files[0]);
                const imgRes = await axios.post(`${process.env.REACT_APP_API_URL}upload/review`, formImg);


                if (e.target.files && e.target.files[0]) {
                    setTypeFile(e.target.files[0].type);
                    let reader = new FileReader();
                    reader.onload = function (e) {
                        setVideo( {id:imgRes.data.id, size:imgRes.data.size, url:e.target.result});
                    }
                    reader.readAsDataURL(e.target.files[0]);
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    const getThumbnail = (url, type) =>{
        if(type == 'video'){
            if(url.indexOf('data:video') > -1){
                return <video id="video" width="100%" height="100%"  src={url} />

            }else {
                let thumbnail = url.replace('/review', '/review-thumbnail')
                return  <div className="attach_thumb" style={{backgroundImage:`url(\"${thumbnail.substring(0, thumbnail.lastIndexOf("."))}.jpg\"), url("${default_thumb}")`, backgroundSize: "cover"}}></div>
            }
        }else{
            if(url.indexOf('data:image') > -1) {
                return <div className="attach_thumb" style={{backgroundImage:`url(\"${url}\")`, backgroundSize: "cover"}}></div>;
            }else{
                return  <div className="attach_thumb" style={{backgroundImage:`url(\"${url.replace('/review', '/review-thumbnail')}\"), url("${default_thumb}")`, backgroundSize: "cover"}}></div>

            }
        }
    }

    return (
        <React.Fragment>
            {
                productInfo ?
                    <MyPageReviewDialog visible={visible}>
                        <Dialog
                            title="구매평 보기"
                            cancelText="닫기"
                            onCancel={() => {onCancel(); clearState();}}
                            dialogSize="large"
                            fullHeight
                            fixHeight
                            visible={visible}
                        >
                            <div className="item_wrap">
                                <div className="item_img">
                                    {
                                        is_regulardetailadmin ?
                                            <img src={productInfo.product_image} alt="제품이미지" />:
                                            is_regularadmin ?
                                                <img src={productInfo.main_img} alt="제품이미지" />:
                                                is_regular ?
                                                    <img src={productInfo.product_image} alt="제품이미지" />:
                                                    <img src={productInfo.main_img} alt="제품이미지" />

                                    }
                                </div>
                                <div className="item_info">
                                    <p>{product.item.name}</p>
                                </div>
                            </div>
                            <p className="sub_tit">상품은 어떠셨나요?</p>
                            <div className="rate">
                                <StyledRate size="30" onChange={handleChange} defaultValue={valueRate} value={valueRate} disabled/>
                            </div>
                            <p className="sub_tit">구매평을 작성해주세요.</p>
                            <div className="textarea_wrap">
                                <textarea name="content" onChange={inputHandler("content")} placeholder={guide} onFocus={(e) => {setGuide("") }} rows="4" id="text_review"
                                          value={data.content} disabled>
                                </textarea>
                                <span id="counter">{data.content.length}/2,000자</span>
                            </div>
                            <p className="sub_tit">포토 첨부 <span>{(fileSize/1024/1024).toFixed(2)}MB/20MB</span></p>
                            <div className="attach_wrap"  id="image_area">
                                {images.map((image, idx) => {
                                    return (
                                        <div className="attach_area" key={idx}>
                                            {getThumbnail(image.url, 'img')}
                                            <span className="del_img" onClick={() => {
                                                var imgs =images.filter(img => img.id != image.id);
                                                setImages(imgs);
                                                let reducer = (previousValue, currentValue) => {
                                                    return previousValue.size  + currentValue.size ;
                                                }
                                                if(imgs.length == 1) {
                                                    setFileSize(imgs[0].size);
                                                }else if(imgs.length > 1) {
                                                    setFileSize(imgs.reduce(reducer));
                                                }else{
                                                    setFileSize(0);
                                                }
                                            }}>
                                {/* {isPc ? <img src={photo_del} alt="포토지우기"/> :<img src={m_photo_del} alt="포토지우기"/>} */}
                                </span>
                                        </div>)
                                })
                                }
                                {/* <div className="attach_area">
                                    <div className="attach_thumb" style={{cursor: "pointer"}} onClick={() => handleFileButtonClick()}>
                                        <input type="file"  ref={fileRef} hidden={true} accept="image/*" name="file" onChange={handleImageChange} />
                                    </div>
                                </div> */}
                            </div>
                            {/* 독립몰 serect 동영상 첨부 hidden 처리 */}
                            {/* <p className="sub_tit">동영상 첨부 <span>{(videoFileSize/1024/1024).toFixed(2)}MB/500MB</span></p>
                            <div className="attach_wrap attach_video">
                                {video && (
                                    <div className="attach_area">

                                            {getThumbnail(video.url, 'video')}
                                        <span className="del_img" onClick={() => {
                                            setVideo(null);
                                            setVideoFileSize(0);
                                        }} >
                    {
                        <img src={isPc ? photo_del: m_photo_del} alt="포토지우기" />
                    }
                        </span>
                                    </div>
                                )
                                }
                                {!video && (
                                    <div className="attach_area">
                                        <div className="attach_thumb" style={{cursor: "pointer"}} onClick={() => handleVideoButtonClick()}>
                                            <input type="file"  ref={vidoeRef} hidden={true} accept="video/*"  name="file" onChange={handleVideoChange} />
                                        </div>
                                        <span className="del_img">
                        </span>
                                    </div>
                                )
                                }

                            </div> */}

                            {/* <div className="button_wrap">
                                <Button size="small" onClick={() => {handleSubmit(); clearState(); onCancel()}}>수정</Button>
                            </div> */}
                            <p className="sub_desc">* 작성된 구매평은 수정할 수 없습니다.</p>
                        </Dialog>
                    </MyPageReviewDialog>:
                    <Loader/>
            }
        </React.Fragment>
    );
}

MyPageReviewPopup.defaultProps = {
    newReview: 'true'
}

const MyPageReviewDialog = styled.div`
    display: ${({ visible }) => visible ? 'flex' : 'none'};

    & div.dialog_wrap {
        padding: 25px 0 0 0;

        & h3 {
            margin-bottom: 30px;
        }

        & .dialog_inner {
            padding: 0 50px 30px 50px;
            max-height: calc(90vh - 88px);
        }

        @media only screen and (max-width: 960px) {
            padding: 0;

            & h3 {
                margin-bottom: 0;
            }

            & .dialog_inner {
                max-height: calc(100% - 60px);
                padding: 15px 15px 30px 15px;
            }
        }
    }

    & .item_wrap {
        display: flex;
        align-items: center;
        height: 60px;
        margin-top: 30px;
        border: 1px solid #ddd;
        &:first-child {
            margin-top: 0;
        }
        & .item_img {
            width: 58px;
            & img {
                display: block;
                width: 100%;
            }
        }
        & .item_info {
            width: calc(100% - 60px);
            padding: 18px 20px 18px 30px;
            & p {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                font-size: 1rem;
                color: #222;
            }
        }

        @media only screen and (max-width: 960px) {
            margin-top: 0;
            & .item_img {
                width: 60px;
                & img {
                    display: block;
                    width: 100%;
                }
            }
            & .item_info {
                width: calc(100% - 60px);
                padding: 19px 10px;
                & p {
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    font-size: 0.875rem;
                    color: #515355;
                }
            }
        }
    }

    & .sub_tit {
        margin: 20px 0 10px;
        line-height: 24px;
        color: #222;
        font-weight: 500;

        @media only screen and (max-width: 960px) {
            line-height: 22px;
            font-size: 0.938rem;
        }
    }

     & .sub_desc {
        margin: 20px 0 10px;
        line-height: 24px;
        color: #808080;
        font-weight: 400;

        @media only screen and (max-width: 960px) {
            line-height: 22px;
            font-size: 0.35rem;
        }
    }

    & .rate {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        border: 1px solid #ddd;
    }

    & .textarea_wrap {
        position: relative;
        & textarea {
            padding: 15px 20px;
            border: 1px solid #ddd;
            font-size: 0.875rem;
            color: #222;
            &::placeholder {
                color: #515355;
                font-size: 0.875rem;
            }
        }
        & span {
            position: absolute;
            bottom: 7px;
            right: 11px;
            color: #999;
            font-size: 0.75rem;
            line-height: 18px;
        }
    }

    & .attach_wrap {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
        border-bottom: 1px solid #e2e2e2;
        & .attach_area {
            position: relative;
            width: 56px;
            height: 56px;
            margin: 3px;
            border: 1px solid #ddd;
            border-radius: 10px;
            & .img_preview{
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }
            & .attach_thumb {
                display: inline-block;
                overflow: hidden;
                width: 56px;
                height: 56px;
                border-radius: 10px;
                background: url(${icon_add_photo}) no-repeat center;
                & img {
                    width: 58px;
                }
            }
            & .attach_thumb_selected{
              display: inline-block;
              position: relative;
              overflow: hidden;
              width: 55px;
              height: 55px;
              border-radius: 10px;
              background: none;
              &::after {
                content: "";
                display: block;
                padding-bottom: 100%;
              }
              & img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 100%;
                width: auto;
                height: auto;
                width: 100%;
              }
            }
            & span {
                position: absolute;
                top: -5px;
                right: -5px;
                & img {
                    display: none;
                }
                &.del_img {
                    & img {
                        display: block;
                        width: 20px;
                    }
                }
            }
        }

        @media only screen and (max-width: 960px) {
            & .attach_area {
                overflow: hidden;
                width: 62px;
                height: 62px;
                &:not(:first-child) {
                    margin-left: 5px;
                }
                & .attach_thumb {
                    width: 62px;
                    height: 62px;
                    background: url(${m_icon_add_photo}) no-repeat center;
                    background-size: 12px;
                    & img {
                        width: 100%;
                    }
                }
                & span {
                    & img {
                        display: none;
                    }

                    &.del_img {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba(0,0,0,0.4);
                        & img {
                            display: block;
                            width: 22px;
                        }
                    }
                }
            }

            &.attach_video {
                border-bottom: none;
            }
        }
    }

    &  .button_wrap {
        display: flex;
        margin: 30px auto 0;
        justify-content: center;
        & div {
            width: 170px;
            border-radius: 4px;
        }
        @media only screen and (max-width: 960px) {
            & div {
                width: ${({ newReview }) => newReview ? 'clac(100% - 5px)' : '100%'};
                height: 50px;
                border-radius: 4px;
            }
        }
    }

    & .ButtonWrap {
        display: none;
    }

`

const StyledRate = styled(Rate)`
  &.rc-rate {
    font-size: ${({ size }) => size}px;

    & .rc-rate-star{
      margin: 0 1px;
    }
  }

  & .rc-rate-star-half .rc-rate-star-first, .rc-rate-star-full .rc-rate-star-second{
      color: #1EA0FA;
  }
`;

export default MyPageReviewPopup;