import {CHECK_OPTIONS, ADD_OPTION, CLEAR_OPTIONS, REMOVE_OPTION, SET_OPTION_QUANTITY, SET_ARR_OPTIONS} from "./types";

export const checkOptions = (options) => {
    return {
        type: CHECK_OPTIONS,
        payload: {options}
    }
}

export const setAddOption = (id, name, price,require) => {
    return {
        type: ADD_OPTION,
        payload: {
            quantity: 1,
            id,
            name,
            price,
            require
        },
    };
};

export const setQuantity = (id, quantity, name, price,require) => {
    return {
        type: SET_OPTION_QUANTITY,
        payload: {
            id,
            quantity,
            name,
            price,
            require
        },
    };
};


export const removeOptions = (id) => {
    return {
        type: REMOVE_OPTION,
        payload: {
            id,
        },
    };
};

export const clearOptions = (...id) => {
    return {
        type: CLEAR_OPTIONS,
        payload: {
            id,
        }
    }
}

export const setArrOptions = (arr) => {
    return {
        type: SET_ARR_OPTIONS,
        payload: {
            arr,
        }
    }
}
