import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import 'styles/GlobalStyles.scss';
import {ReactComponent as DogSvg } from 'images/img_character_dog.svg';
import {ReactComponent as CatSvg} from 'images/img_character_cat.svg';
import moment from 'moment';
import axios from "axios";
import {useSelector} from "react-redux";

const MyPetsInfo = () => {
  const userInfo = useSelector((state) => state.userLogin.userInfo.data || "");
  const [petLists, setPetLists] = useState([])
    moment.locale('kr');


  useEffect(async () => {
      try{
        const config={
          headers: {
            'Content-Type': 'application/json',
              "cid" : userInfo.cid,
              "encoded" : userInfo.encoded,
          },
        }
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/user/pet`, config)
        setPetLists(res.data.data)

      }catch(error){
        console.log(error)
      }
  }, [])

   const calDays = (date)=>{
        var years = Math.floor(Math.round(moment.duration( moment().startOf('day') - moment(date)).asDays())/365);
        var month = Math.floor(((moment.duration( moment().startOf('day') - moment(date)).asDays()) - (365*years))/30) ;
        var days =  Math.floor(((moment.duration( moment().startOf('day') - moment(date)).asDays()) - (365*years))%30);
        return `${years} 년 ${month}개월 ${days}일`;
   }
  return (
    <MyPetsInfoContainer>
      <div className="mypage_title">
        <h2>우리아이 정보</h2>
      </div>
      <div className="pets_list">
        <ul>
          {petLists?.length !== 0 ? petLists?.map((list) => {
            return (
                <li key = {list.pet_id}>
                  <div className="pet_img">
                      {list.profile_img === null ? list.pet_type === "dog" ? <DogSvg width="100%" height="100%" /> : <CatSvg width="100%" height="100%" />
                          : <img src={list.profile_img} alt="아이 이미지" />}
                  </div>
                  <div className="pet_info">
                    <p>{list.name}</p>
                    <p>{calDays(list.birthday)}</p>
                    <div className="pet_item noscroll">
                        {list.devices.map(device => {
                            return(
                                    <span>{device}</span>
                                )
                           }
                        )}
                    </div>
                  </div>
                </li>
            )}) : <div>
                  <p className="no_list">등록된 우리 아이 정보가 없습니다.</p>
          </div>}
        </ul>
      </div>
      <div className="list_more_btn">
      </div>
    </MyPetsInfoContainer>
  );
};

const MyPetsInfoContainer = styled.div`
  & .no_list{
    text-align: center;
    margin: 90px auto;
    color: #888;
  }
  
  @media only screen and (max-width: 960px) {
    padding-bottom: 30px;
  }
  & .mypage_title {
    margin-bottom: 15px;
    @media only screen and (max-width: 960px) {
      margin-bottom: 0;
    }
  }
  & .pets_list {
    border-top: 1px solid #555;
    padding-top: 30px;

    & ul {
      & li {
        display: flex;
        align-items: center;
        padding: 25px;
        background: #F8F8F8;
        border-radius: 10px;
        &:not(:first-child) {
          margin-top: 10px;
        }

        & .pet_img {
          position: relative;
          overflow: hidden;
          width: 120px;
          height: 120px;
          margin-right: 30px;

          & span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #eee;
            width: 100%;
            height: 100%;
            background: #fff;
            & img {
              height: 56px;
            }
          }

          & > img {
            position: absolute;
            top: -50%;
            left: -50%;
            transform: translate(50%,50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin: auto;
          }
        }

        & .pet_info {
          p:first-child {
            margin-bottom: 4px;
            font-size: 22px;
            line-height: 33px;
            color: #222;
          }
          p:nth-child(2) {
            margin-bottom: 4px;
            font-size: 18px;
            line-height: 27px;
            color: #222;
          }
          p:nth-child(3) {
            font-size: 14px;
            line-height: 21px;
            color: #515355;
          }

          & .pet_item {
            margin-top: 14px;
            & span {
              display: inline-block;
              min-width: 92px;
              padding: 4px 15px;
              border-radius: 40px;
              border: 1px solid #1EA0FA;
              color: #1EA0FA;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              text-align: center;
              &:not(:first-child) {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 960px) {
      border-top: none;
      padding-top: 0;

      & ul {
        padding: 8px 0;
        background: #f6f6f6;
        & li {
          position: relative;
          padding: 30px 15px 83px;
          background: #fff;
          border-radius: 0;
          &:not(:first-child) {
            margin-top: 8px;
          }

          & .pet_img {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            margin-right: 15px;

            & span {
              & img {
                height: 35px;
              }
            }

            & > img {
              width: 100%;
            }
          }

          & .pet_info {
            width: calc(100% - 115px);
            p:first-child {
              font-size: 1.25rem;
              line-height: 30px;
            }
            p:nth-child(2) {
              margin-bottom: 4px;
              font-size: 1rem;
              line-height: 24px;
            }
            p:nth-child(3) {
              font-size: 0.875rem;
            }

            & .pet_item {
              overflow-x: auto;
              white-space: nowrap;
              position: absolute;
              bottom: 30px;
              left: 0;
              width: 100%;
              margin-top: 0;
              padding: 0 15px;
              & span {
                padding: 6px 15px;
                background: #fff;
              }
            }
          }
        }
      }
    }
  } 
`

export default MyPetsInfo;