import React, {useEffect, useState} from 'react';

const AuthTimer = ({counter, setCounter}) => {
    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    },[counter]);

    const timeFormat = (time) => {
        const m = Math.floor(time / 60).toString()
        let s = (time % 60).toString()
        if (s.length === 1) s = `0${s}`
        return `${m}분 ${s}초`
    }
    return (
        <div style={{display:"inline"}}>
            {timeFormat(counter)}
        </div>
    );
};

export default AuthTimer;