import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';
import { useMediaQuery } from 'react-responsive';
import ReviewPopup from './components/ReviewPopup';
import review_tit_bg from 'images/bg/review_tit_bg.png';
import best_review_bg from 'images/bg/best_review_bg.png';
import top_review_contents from 'images/top_review_contents.png';
import top_review_bg from 'images/top_review_bg.png';
import bottom_review_contents from 'images/bottom_review_contents.png';
import bottom_review_bg from 'images/bottom_review_bg.png';
import m_review_event_banner from 'images/m/review_event_banner.jpg';
import axios from "axios";
import moment from 'moment';
import Pagination from "./components/Pagination";
import default_thumb from "images/default_thumb.png";

const ReviewPage = () => {
  const isPc = useMediaQuery({ query: '(min-width: 961px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const [total, setTotal] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [reviewItem, setReviewItem] = useState({})
  const [currentPage, setCurrentPage] = useState(1);
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const getThumbnail = (url, type) =>{
    if(!url){return}
      if (type == 'video') {
        let thumbnail = url.replace('/review', '/review-thumbnail');
        return <div className="thumb_img" style={{
          backgroundImage: `url(\"${thumbnail.substring(0, thumbnail.lastIndexOf("."))}.jpg\"), url("${default_thumb}")`,
          backgroundSize: "cover"
        }}></div>
      } else {
          return <div className="thumb_img" style={{
            backgroundImage: `url(\"${url.replace('/review', '/review')}\"), url("${default_thumb}")`,
            backgroundSize: "cover"
          }}></div>
      }
  }

  const hide_string = (email) => {
    if(email === null){return}
    if(String(email).includes("@")){
      id = email
    }
    let id = String(email).split("@")[0]
    let answer = ""
    for(let i = 0; i < id.length; i++){
      if(i < id.length/2){
        answer = answer + id[i]
      }else{
        answer = answer + "*";
      }
    }
    return answer
  }
  const fetchReviews = async () => {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}api/review/photo/all?start=${(currentPage-1)*8}`, config);
      setReviews(res.data.data);
      setTotal(res.data.total);
  }
  useEffect(() => {
    fetchReviews()
  }, [currentPage]);

  const [ review, setReview ] = useState(false);
  const onCancelReview = () => {
      setReview(false);
  }

  const newReview = (date) =>{
      return moment().diff(moment(date), 'days') < 2
  }

  return (
    <ReviewWrapper>
      { isPc &&
        <>
          <div className="top_review">
            <img src={top_review_contents} alt="리뷰이벤트" />
          </div>
          <div className="bottom_review">
            <img src={bottom_review_contents} alt="리뷰이벤트" />
          </div>
        </>
      }
      { isMobile &&
        <img src={m_review_event_banner} alt="리뷰이벤트" style={{width:'100%'}} />
      }
      <div className="photoreview_wrap center">
        <div className="wrap_title board_title">
          <h2>포토 리뷰<span>쇼핑몰에서 추천한 리뷰와{isMobile && <br />}고객들에게 도움이 많이 된 리뷰순으로 노출됩니다.</span></h2>
        </div>
        <ul className="review_list">
          {reviews && reviews.map((el) => {
            if(!el.Files){return}
            if(el.is_dummy){
              return(
                  <li key={el.id} onClick={() => {setReview(true); setReviewItem(el);}}>
                    <div className="item_img">
                      <div className="thumb_img" style={{
                        backgroundImage: `url(\"${el.d_images[0]}\"), url("${default_thumb}")`,
                        backgroundSize: "cover"
                      }}></div>
                    </div>
                    <p className="review_txt">{el.content}
                      {newReview(el.createdAt) &&
                          <span>N</span>
                      }
                    </p>
                    <p className="writer">{el.d_username}</p>
                  </li>
              )
            }
            return (
            <li key={el.id} onClick={() => {setReview(true); setReviewItem(el);}}>
              <div className="item_img">
                {el.Files[0].mimetype.indexOf("image") > -1 &&
                    getThumbnail(el.Files[0].publicPath, 'image')}
                {el.Files[0].mimetype.indexOf("video") > -1 &&
                    getThumbnail(el.Files[0].publicPath, 'video')}
              </div>
              <p className="review_txt">{el.content}
                {newReview(el.createdAt) &&
                  <span>N</span>
                }
              </p>
              <p className="writer">{el.email !== null ? hide_string(el.email) : hide_string(el.d_username)}</p>
            </li>)
          })}
        </ul>
        <ul className="review_pagination">
          {reviews.length === 0 ? null : (
              <Pagination
                  postsPerPage={8}
                  totalPosts={
                    total
                  }
                  paginate={setCurrentPage}
                  currentpage={currentPage}
              ></Pagination>
          )}
        </ul>
        {review &&
            <ReviewPopup visible={review} onCancelReview={onCancelReview} review={reviewItem} />
        }
      </div>
    </ReviewWrapper>
  );
};

const ReviewWrapper = styled.div`
  & .top_review {
    padding: 100px 0 120px;
    background: url(${top_review_bg}) no-repeat;
    background-size: cover;
    text-align: center;
  }
  & .bottom_review {
    padding: 30px 0;
    background: url(${bottom_review_bg}) no-repeat;
    background-size: cover;
    text-align: center;
  }
  & .photoreview_wrap {
    padding: 100px 0 110px;
    & .review_list {
      display: flex;
      flex-wrap: wrap;
      margin: 60px 0 100px;
      & li {
        width: calc((100% - 99px) / 4);
        cursor: pointer;
        :not(:nth-child(4n)) {
          margin-right: 33px;
        }
        :not(:nth-child(-n+4)) {
          margin-top: 80px;
        }

        & .item_img {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: auto;
          padding-top: 100%;
          margin-bottom: 20px;
          & .thumb_img {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-size: cover;
            background-position: center;
          }
        }

        & .review_txt {
          max-height: 3rem;
          font-size: 1rem;
          color: #222;
          line-height: 1.5rem;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          & span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 22px;
            height: 22px;
            margin-left: 3px;
            background: #1ea0fa;
            border-radius: 50%;
            color: #fff;
            font-size: 0.813rem;
            line-height: 20px;
            font-weight: 600;
          }
        }

        & .writer {
          margin-top: 10px;
          font-size: 0.938rem;
          color: #999;
          line-height: 22.5px;
        }
      }
    }

    & .review_pagination {
      nav {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 960px) {
    & .best_review_wrap {
      background: linear-gradient(180deg, #DFE9F2 0%, rgba(223, 233, 242, 0.4) 100%), url(${best_review_bg}) no-repeat center;
    }

    & .best_review {
      width: 100%;
      padding: 50px 0 60px;

      & .main_tit {
        padding-top: 85px;
        margin-bottom: 24px;
        background: url(${review_tit_bg}) no-repeat top center;
        background-size: 200px;
        font-size: 2rem;
        line-height: 45px;
      }

      & .sub_tit {
        width: 290px;
        margin: 0 auto 16px;
        font-size: 1.25rem;
        line-height: 28px;
        & span {
          padding-top: 0px;
          &:after {
            content: none;
          }
        }
      }

      & .sub_txt {
        margin-bottom: 50px;
        font-size: 0.813rem;
        color: #3D5566;
        line-height: 18px;
      }

      & .big_reivew {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 0 15px;
        margin-bottom: 20px;
        & .review_box {
          width: 100%;
        }
      }

      & .review_box {
        position: relative;
        padding: 30px 20px 40px;
        background: #fff;

        & .review_tit {
          position: absolute;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          top: -42px;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 330px;
          height: 60px;
          border-radius: 70px;
          background: #1ea0fa;
          font-size: 1.375rem;
          color: #fff;
          line-height: 35px;
          text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
          & b {
            margin-right: 3px;
            font-weight: 700;
            line-height: 23px;
          }
        }
        & .my_review {
          & img {
            width: 100%;
            margin-bottom: 20px;
          }

          & .rate {
            display: flex;
            align-items: center;
            & .writer {
              margin-left: auto;
            }
          }

          & p {
            margin-top: 17px;
            font-size: 1.125rem;
            color: #222;
            line-height: 27px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        & .writer {
          color: #999;
          font-size: 1.125rem;
          line-height: 29px;
        }
      }

      & .group_reivew {
        margin-bottom: 120px;
        & .review_box {
            padding: 30px 20px 20px;
          & .group_my_review {
            display: flex;
            justify-content: space-between;
            & .my_review {
              width: 230px;
              text-align: center;
            }
          }
        }
      }
    }

    & .photoreview_wrap {
      padding: 60px 0 110px;
      & .wrap_title {
        padding-bottom: 30px;
      }
      & .review_list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 60px;
        padding: 0 15px;
        & li {
          width: calc((100% - 15px) / 2);
          &:not(:nth-child(4n)) {
            margin-right: 0;
          }
          &:nth-child(2n-1) {
            margin-right: 15px;
          }
          &:not(:nth-child(-n+2)) {
            margin-top: 40px;
          }

          & .item_img {
            margin-bottom: 12px;
          }

          & .review_txt {
            -webkit-line-clamp: 4;
            max-height: 6rem;
            word-break: break-all;
            & span {
              width: 18px;
              height: 18px;
              margin-top: -2px;
              font-size: 0.625rem;
              line-height: 15px;
              vertical-align: middle;
            }
          }

          & .writer {
            margin-top: 8px;
            font-size: 0.75rem;
            line-height: 18px;
          }
        }
      }

      & .review_pagination {
        nav {
          margin: 60px 0 0 0;
        }
      }
    }
  }
`;

const StyledRate = styled(Rate)`
  &.rc-rate {
    font-size: ${({ size }) => size}px;

    & .rc-rate-star{
      margin-right: 3px;
      @media only screen and (max-width: 960px) {
          margin: 0 !important;
      }
    }
  }
    
  & .rc-rate-star-half .rc-rate-star-first, .rc-rate-star-full .rc-rate-star-second{
      color: #1EA0FA;
  }
`;

export default ReviewPage;