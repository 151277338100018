import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import icon_user from "images/m/user.png";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import m_icon_close from "images/m/icon_close.png";
import axios from "axios";
import { setCategory, setCategoryFiliter, getCategoryId } from "actions/categoryActions";
import {adjustSessionToAuth, fetchCartLists} from "actions/cartActions";
import LeftNav from "./LeftNav";
import m_logo from "images/m/logo_varram.png";
import m_logo_w from "images/m/logo_varram_w.png";
import logo from "images/logo_varram.png";
import logo_w from "images/logo_varram_w.png";
import search_ico from "images/icon/ico_search.png";
import cart_ico from "images/icon/ico_cart.png";
import mypage_ico from "images/icon/ico_mypage.png";
import search_ico_w from "images/icon/ico_search_w.png";
import cart_ico_w from "images/icon/ico_cart_w.png";
import mypage_ico_w from "images/icon/ico_mypage_w.png";
import m_ico_search from "images/m/ico_search.svg";
import m_ico_cart from "images/m/ico_cart.svg";
import m_search_ico_w from "images/m/ico_search_w.svg"
import m_cart_ico_w from "images/m/ico_cart_w.svg"

const NavContents = ({ location, openSearch }) => {
    const isPc = useMediaQuery({ query: "(min-width: 961px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 960px)" });
    const dispatch = useDispatch();
    const cartlists = useSelector((state) => state.cartRefresh);
    const cartItems = Object.freeze([...cartlists.cartNomalItems, ...cartlists.cartRegularItems]);
    const category = useSelector((state) => state.categoryReducer.categoryProducts);
    const {loading, userInfo} = useSelector(state => state.userLogin);
    const [checkUser, setCheckUser] = useState({})
    const item = JSON.parse(localStorage.getItem("cartItems")) || []; //세션에 저장된 장바구니 가져오기
    const [display, setDisplay] = useState(false);

    useEffect(async () => {
        if (loading){return}
        if (item) {
            if (item.length >= 0) {
                item.forEach((el) => {
                    let data = {product_id: el.ProductId, Options : el.Options, regular: el.regular}
                    dispatch(adjustSessionToAuth({data}))
                    localStorage.removeItem('cartItems');
                });
            }
        }
    },[userInfo])

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}api/product/category`);
                dispatch(setCategory(res.data));
                dispatch(setCategoryFiliter(res.data));
            } catch (err) {
                console.log(err);
            }
        };
        const fetchProfile = async () => {
            if (!userInfo) {return}
            if (!userInfo.data) {return}
            const config={
                headers: {
                    'Content-Type': 'application/json',
                    "cid" : userInfo.data.cid,
                    "encoded" : userInfo.data.encoded,
                },
            }
            try{
                const res = await axios.get(`${process.env.REACT_APP_API_URL}api/user/info`, config);
                setCheckUser(res.data)
            } catch(error){
                console.log(error)
            }
        }
        dispatch(fetchCartLists());
        fetchProfile()
        fetchItems();
    }, [dispatch, userInfo]);

    const onMouseOver = (e) => {
        let gnbsub_wrap = e.target.nextSibling;
        gnbsub_wrap.classList.add("active");
    }

    const onMouseLeave = (e) => {
        let els = document.querySelectorAll(".gnbsub_wrap.active");
        for(var i = 0; i < els.length; i++) {
            els[i].classList.remove("active");
        }
    }

    const [open, setOpen] = useState(false);
    const [Mobile, setMobile] = useState(false);
    const resizingHandler = () => {
        if (window.innerWidth > 960) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    };

    // 팝업, 햄버거메뉴 오픈 시 body스크롤 고정
    useEffect(() => {
        if (window.innerWidth > 960) {
            setMobile(false);
        } else {
            setMobile(true);
        }
        if (Mobile) {
            function disableScroll() {
                if (open) {
                    document.body.style.overflowY = 'hidden';
                    document.body.style.overflowX = 'visible';
                    document.querySelector('html').scrollTop = window.scrollY; // dimmed 되기 전 스크롤 위치 고정
                }
            }
            window.addEventListener('scroll', disableScroll);
            window.addEventListener("resize", resizingHandler);

            return () => {
                window.removeEventListener('scroll', disableScroll);
                document.body.style.overflow = 'visible';
                // 메모리 누수를 줄이기 위한 removeEvent
                window.removeEventListener("resize", resizingHandler);
            }
        } else {
            window.addEventListener("resize", resizingHandler);
            return () => {
                // 메모리 누수를 줄이기 위한 removeEvent
                window.removeEventListener("resize", resizingHandler);
            };
        }}, [open, Mobile]);

    useEffect(() => {
        if(window.innerWidth > 960) {
            var gnb_wrap = document.querySelector(".gnb_wrap");
            var h1 = document.querySelector("h1");
            var gnbsub_tit_width = gnb_wrap.offsetLeft - h1.offsetLeft - 76;
            var gnbsub_tit = document.querySelectorAll(".gnbsub_tit");
            for ( var i = 0; i < gnbsub_tit.length; i++ ) {
                gnbsub_tit[i].style.width = gnbsub_tit_width+"px";
            }
        }
        window.onresize = function(){
            var gnb_wrap = document.querySelector(".gnb_wrap");
            var h1 = document.querySelector("h1");
            var gnbsub_tit_width = gnb_wrap?.offsetLeft - h1?.offsetLeft - 76;
            var gnbsub_tit = document.querySelectorAll(".gnbsub_tit");
            for ( var i = 0; i < gnbsub_tit.length; i++ ) {
                gnbsub_tit[i].style.width = gnbsub_tit_width+"px";
            }
        }
        const sub_menus = document.querySelectorAll(".nav a")
        for (const sub_menu of sub_menus) {
            sub_menu.addEventListener('click', function(event) {
                onMouseLeave();
            })
        }
    })

    const clickLink = (id, type, order) => {
        dispatch(getCategoryId(id, type, order))
    }

    return (
        <div className="container">
            {isMobile &&
                <>
                    <span className="ham_menu" onClick={() => setOpen(!open)}>메뉴</span>
                    <LeftNavWrap open={open} className="m_nav_wrap">
                        <div className="gnb_top">
                            <p className="gnb_close"><img src={m_icon_close} alt="메뉴닫기" onClick={() => setOpen(!open)} /></p>
                            <div className="login_wrap">
                                {!loading ?
                                    <div>
                                  <span className="profile" >
                                      <Link onClick={() => setOpen(!open)}  to="/mypage_m">
                                      <img src={icon_user} alt="프로필사진" /></Link>
                                  </span>
                                        <Link onClick={() => setOpen(!open)}  to="/mypage_m">
                                            {checkUser?.data?.user_name}</Link>
                                    </div>
                                    : <Link onClick={() => setOpen(!open)}  to="/login">
                                        <div>
                                  <span className="profile">
                                  <img src={icon_user} alt="프로필사진" /></span>로그인하기
                                        </div>
                                    </Link>}
                            </div>
                        </div>
                        <LeftNav open={open} setOpen={setOpen}></LeftNav>
                    </LeftNavWrap>
                </>
            }
            <h1>
                <Link to="/" className="logo">
                  {isPc ? <img src={location.pathname === "/" ? logo_w : logo} alt="VARRAM" className="logo_img" /> :
                    <img src={location.pathname === "/" ? m_logo_w : m_logo} alt="VARRAM" className="logo_img" />}
                </Link>
            </h1>
            <MenuList location={location} className="nav">
                <ul className="gnb_wrap">
                    <li onMouseLeave={onMouseLeave}>
                        <Link
                            to="/brand"
                            className="menu"
                            onMouseOver={onMouseOver}
                            onClick={onMouseLeave}
                        >브랜드</Link>
                        <div className="gnbsub_wrap">
                            <div className="gnbsub">
                                <p className="gnbsub_tit" id="gnbsub_tit">브랜드</p>
                                <ul>
                                    <li><Link to="/brand" className="sub1_menu">바램펫 이야기</Link></li>
                                    <li><Link to="/brand/awards" className="sub1_menu">어워즈</Link></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    {category.map((depth1) => {
                        const product1 =  `${depth1.type}${depth1.order}` === "product" && depth1.order === 0
                        const product2 =  depth1.type === "product" && depth1.order === 1
                        const regular =  depth1.type === "regular"  && depth1.order === 2
                        return(
                            <li key={depth1.id} onMouseLeave={onMouseLeave}>
                                <Link
                                    to={{
                                        pathname: `/products/list/${depth1.type}${depth1.order}/${depth1.id}`,
                                        mainId: depth1.id
                                    }}
                                    className="menu"
                                    onMouseOver={onMouseOver}
                                    onClick={() => {onMouseLeave(); clickLink(depth1.id, depth1.type, depth1.order);}}
                                >{depth1.name}</Link>
                                <div className="gnbsub_wrap">
                                    <div className="gnbsub">
                                        <p className="gnbsub_tit" id="gnbsub_tit">{depth1.name}</p>
                                        <ul>
                                            {depth1.sub_category.map((depth2) =>(
                                                <li key={depth2.id} >
                                                    <p key={depth2.id} className="sub1_menu gnbsub2_wrap">
                                                        <Link to={{
                                                            pathname: `/products/list/${depth1.type}${depth1.order}/${depth2.id}`,
                                                            mainId: depth2.parentCategoryId
                                                        }}
                                                              onClick={() => {clickLink(depth1.id, depth1.type, depth1.order);}}
                                                        >
                                                            {depth2.name}
                                                        </Link>
                                                    </p>
                                                    <ul className="gnbsub2">
                                                        {depth2.sub_category.map((depth3) => (
                                                            <React.Fragment key={depth3.id}>
                                                                <li>
                                                                    <Link key={depth3.id} to={{
                                                                        pathname: `/products/list/${depth1.type}${depth1.order}/${depth3.id}`,
                                                                        mainId: depth2.parentCategoryId}}
                                                                          className="sub2_menu"
                                                                          onClick={() => {clickLink(depth1.id, depth1.type, depth1.order);}}
                                                                    >{depth3.name}</Link>
                                                                </li>
                                                            </React.Fragment>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                    <li onMouseLeave={onMouseLeave}>
                        <Link
                            to="/support/event"
                            className="menu"
                            onMouseOver={onMouseOver}
                            onClick={onMouseLeave}
                        >서포트</Link>
                        <div className="gnbsub_wrap">
                            <div className="gnbsub">
                                <p className="gnbsub_tit" id="gnbsub_tit">서포트</p>
                                <ul>
                                    <li><Link to="/support/event" className="sub1_menu">이벤트</Link></li>
                                    <li><Link to="/support/notice" className="sub1_menu">공지사항</Link></li>
                                    <li><Link to="/support/guide?id=0" className="sub1_menu">FAQ</Link></li>
                                    <li><Link to="/support/project" className="sub1_menu">바램 육아백과</Link></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </MenuList>
            <div className="util_wrap">
                {/* <span className="h_search" id="search_btn" onClick={() => {openSearch()}}>
                {isPc ? <img src={location.pathname === "/" ? search_ico_w : search_ico} alt="검색" className="search_img" /> :
                    <img src={location.pathname === "/" ? m_search_ico_w : m_ico_search} alt="검색" className="search_img" />}
                </span> */}
                
                <Link to="/shoppingcart" className="h_cart">
                  {isPc ? <img src={location.pathname === "/" ? cart_ico_w : cart_ico} alt="장바구니" className="cart_img" /> :
                    <img src={location.pathname === "/" ? m_cart_ico_w : m_ico_cart} alt="장바구니" className="cart_img" />}
                    {cartItems.length !== 0 ?
                    <span className="count">{cartItems.length}</span> : null}</Link>
                {loading ? <Link className="h_mypage" style={{display: !isPc ? "none" : "block"}} to="/login"><img src={location.pathname === "/" ? mypage_ico_w : mypage_ico} alt="로그인" className="mypage_img" /></Link>
                    : <Link className="h_mypage" style={{display: !isPc ? "none" : "block"}} to="/mypage"><img src={location.pathname === "/" ? mypage_ico_w : mypage_ico} alt="로그인" className="mypage_img" /></Link> }
            </div>
        </div>
    );
};

const LeftNavWrap = styled.div`
  position: fixed;
  top: 0;
  left: -100%;
  min-height: calc(100vh - 40px);
  height: 100%;
  max-height: 100%;
  transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
  display: ${({ open2 }) => open2 ? 'none' : 'block'};
  transition: transform 0.3s ease-in-out;
  width: 100%;
  background: #fff;
  z-index: 9999;

  .gnb_top {
    .gnb_close {
      padding: 13px 15px 12px;
      background: #fff;
      cursor: pointer;

      img {
        width: 28px;
      }
    }
  }
  .login_wrap {
    padding: 15px 25px;
    background: #1ea0fa;

    & div {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 1rem;
      line-height: 21px;
      font-weight: 500;
      & > a {
        color: #fff;
      }
      & .profile {
        display: flex;
        width: 50px;
        height: 50px;
        margin-right: 20px;
        border-radius: 50%;
        background: #fff;
        justify-content: center;
        align-items: center;
        & img {
          width: 30px;
        }
      }
    }
  }
`;

const MenuList = styled.div`
  & .gnb_wrap {
    white-space: nowrap;

    & .menu {
      display: inline-block;
      padding: 0 30px;
      line-height: 80px;
      font-size: 1rem;
      color: ${(props) => props.location.pathname === "/" ? "#fff" : "#222"};
      font-weight: normal;
      cursor: pointer;
      &:hover {
        color: #1EA0FA;
      }
      @media only screen and (max-width: 1200px) {
        padding: 0 25px;
      }
    }
    & > li {
      &:first-child .menu {
        padding-left: 0;
      }
      &:last-child .menu {
        padding-right: 0;
      }
    }

    & .gnbsub_wrap {
      display: none;
      position: absolute;
      left: 0;
      width: 100%;
      background: #fff;
      box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
      z-index: 9999;

      &.active {
        display: block;
        transform: ${({ open }) => open ? 'translateY(100%)' : 'translateY(0%)'};
      }

      & .gnbsub {
        display: flex;
        width: 1480px;
        margin: 0 auto;
        box-sizing: border-box;

        & .gnbsub_tit {
          width: 283px;
          padding: 40px 60px 60px 0;
          border-right: 1px solid #e1e1e1;
          line-height: 31px;
          font-size: 1.5rem;
          font-weight: 500;
          box-sizing: border-box;
          text-align: right;
        }

        & .sub1_menu {
          min-width: 43px;
          margin-right: 63px;
          font-size: 1rem;
          font-weight: 500;
          line-height: 21px;
        }

        & > ul {
          display: flex;
          padding:40px 0 60px 76px;
          flex-direction: column;
          & > li {
            display: flex;
            &:not(:first-child) {
              margin-top: 40px;
            }
            & .gnbsub2 {
              display: flex;
              & li:not(:first-child) {
                margin-left: 30px;
              }
              & .sub2_menu {
                line-height: 20px;
                font-size: 0.938rem;
                color: #515355;
                &:hover {
                  color: #1EA0FA;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    display: none;
  }
`;

export default NavContents;