import React from "react";
import styled from "styled-components";

const MainPopup = ({popup, visible, closePop, handleExpire}) => {
    const closingDay = (close) => {
        switch(close){
            case "month" : return "30일간 ";
            case "week" : return "일주일간 ";
            default : return "오늘 하루 "
        }
    }

    return (
        <React.Fragment>
            <PopupStyle popup={popup} visible={visible}>
                <div className="popup_inner">
                    <a href={popup.link}>
                        <img src={popup.image} alt={popup.title}/>
                    </a>
                    <div className="button_wrap">
                        <button type="button" onClick={() =>handleExpire(popup.id, popup.close) }> {closingDay(popup.close)}보지 않기</button>
                        <button type="button" onClick={() => closePop(popup.id)}>닫기</button>
                    </div>
                </div>
                <div className="popup_bg"></div>
            </PopupStyle>
        </React.Fragment>
    );
}

const PopupStyle = styled.div`
  display: ${({ visible }) => visible ? "block" : "none"};
  position: absolute;
  top: ${({popup}) => `${popup.top}px`};
  left: ${({popup}) => `${popup.left}px`};
  background: #fff;
  z-index: 9999;
  & .popup_inner {
    width: 450px;
    & a {
      width: 100%;
      height: 100%;
    }
    & img {
      display: block;
      width: 100%;
    }
    & .button_wrap {
      display: flex;
      border: 1px solid #ddd;
      & button {
        width: 50%;
        background: #fff;
        font-size: 0.938rem;
        line-height: 49px;
        text-align: center;
        &:first-child {
          border-right: 1px solid #ddd;
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    display: ${({ visible }) => visible ? "flex" : "none"};
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;

    & .popup_bg {
      position: fixed;
      background: rgba(0,0,0,0.5);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    & .popup_inner {
      position: absolute;
      top: ${({popup}) => `${popup.top}px`};
      width: 90%;
      z-index: 9999;
      & img {
        width: 100%;
      }
      & .button_wrap {
        display: flex;
        & button {
          width: 50%;
          background: #fff;
          font-size: 0.938rem;
          line-height: 49px;
          text-align: center;
          &:first-child {
            border-right: 1px solid #ddd;
          }
        }
      }
    }
  }
`

export default MainPopup;