import {
    PRODUCT_LIST_FAIL,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL, REGULAR_LIST_REQUEST, REGULAR_LIST_SUCCESS, REGULAR_LIST_FAIL,
    REGULAR_DETAILS_REQUEST, REGULAR_DETAILS_SUCCESS, REGULAR_DETAILS_FAIL, PRODUCT_DETAILS_RESET, REGULAR_DETAILS_RESET
} from "../actions/types";

export const productListReducer = (state = { loading: true, products: []}, action) => {
    switch(action.type){
        case PRODUCT_LIST_REQUEST:
            return { ...state, loading: true, products: [] }
        case PRODUCT_LIST_SUCCESS:
            return {loading: false, products: action.payload.data,}
        case PRODUCT_LIST_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}

export const regularListReducer = (state = {loading: true, regular: []}, action) => {
    switch(action.type){
        case REGULAR_LIST_REQUEST:
            return {...state, loading: true, regulars: [] }
        case REGULAR_LIST_SUCCESS:
            return {loading: false, regulars: action.payload.data }
        case REGULAR_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}


export const productDetailsReducer = (
    state = { productInfo: { reviews: [] }, regularInfo: {reviews:[] } }, action ) => {
    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
            return { ...state, loading: true };
        case REGULAR_DETAILS_REQUEST:
            return {...state, loading: true}
        case PRODUCT_DETAILS_SUCCESS:
            return { loading: false, productInfo: { reviews: [], info: action.payload } };
        case REGULAR_DETAILS_SUCCESS:
            return {loading: false, regularInfo: {reviews: [], info: action.payload}};
        case PRODUCT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        case REGULAR_DETAILS_FAIL:
            return {loading: false, error: action.payload};
        case PRODUCT_DETAILS_RESET:
            return {  roductInfo: { reviews: [] }}
        case REGULAR_DETAILS_RESET:
            return {  regularInfo: { reviews:[] }}
        default:
            return state;
    }
};



