import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useForm} from "react-hook-form";
import m_ico_search from "images/m/ico_search.svg";
import { useHistory } from "react-router-dom";
import {useMediaQuery} from "react-responsive";


const SearchArea = (props) => {
    const isPc = useMediaQuery({ query: '(min-width: 960px)' })
    const { register, handleSubmit, setValue } = useForm();
    const history = useHistory()

    useEffect(() => {
        if(props.searchOpen === false){
            setValue("keyword", "" )
        }
        return (() => {
            setValue("keyword", "" )
        })
    },[props.searchOpen])
    const onSubmit = data => {
         if(!data.keyword ){
            alert('키워드를 입력하세요')
            return
        }
        let base64 = Buffer.from(JSON.stringify({params:{search: data.keyword, start: 0, limit: 8}}, "utf8")).toString('base64')
        history.push( `/searchlist?base=${encodeURIComponent(base64)}`, data.keyword);
        setValue("keyword", data.keyword )
    };

    return (
    <SearchAreaContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="search_box">
                <input type="text" name="keyword" placeholder="궁금하신 내용을 입력해주세요." {...register("keyword")} defaultValue={props.state} />
                <button type="submit"><img src={m_ico_search} alt="검색하기" /></button>
            </div>
            <div className="keyword_wrap">
            <ul>
              <li><a onClick={() => onSubmit({keyword:"자동급식기"})}>자동급식기</a></li>
              <li><a onClick={() => onSubmit({keyword:"고양이 츄르"})}>고양이 츄르</a></li>
              <li><a onClick={() => onSubmit({keyword:"고양이"})}>고양이</a></li>
              <li><a onClick={() => onSubmit({keyword:"강아지"})}>강아지</a></li>
            </ul>
          </div>
        </form>
    </SearchAreaContainer>
  )
}

const SearchAreaContainer = styled.div`
  width: 800px;
  margin: 0 auto;

  & .search_box {
    position: relative;

    & input {
      width: 100%;
      border: none;
      border-bottom: 2px solid #222;
      padding: 0 55px 13px 15px;
      outline: none;
      &::placeholder {
        font-size: 1rem;
        color: #515355;
      }
    }

    & button {
      position: absolute;
      bottom: 10px;
      right: 15px;
      width: 30px;
      height: 30px;
      text-align: center;
      background: none;
      cursor: pointer;

      & img {
        width: 28px;
      }
    }
  }
  & .keyword_wrap {
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    text-align: left;
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }

    & ul {
      display: flex;
      & li {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
        & a {
          display: inline-block;
          padding: 10px 32px;
          line-height: 21px;
          background: #f2f4f7;
          border-radius: 21px;
          font-size: 0.875rem;
          color: #222;
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    width: 100%;
    & .search_box {
      padding: 15px 15px 0 15px;
      & input {
        width: 100%;
        padding: 18px 48px 18px 10px;
        &::placeholder {
          font-size: 1rem;
          color: #515355;
        }
      }
    }
    & button {
      right: 20px;
      bottom: 18px;
    }
    & .keyword_wrap {
      overflow-x: auto;
      -ms-overflow-style: none;
      padding: 0 15px;
      & ul {
        white-space: nowrap;
        & li {
          margin-right: 8px;
          & a {
            padding: 8px 20px;
            line-height: 17px;
            border-radius: 30px;
            font-size: 0.75rem;
          }
        }
      }
    }
  }
`

export default SearchArea;