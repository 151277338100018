import React,{ useEffect, useState } from 'react';
import { Collapse } from 'antd';
import 'antd/dist/antd.css';
import styled from "styled-components"
import ItemReview from "./ItemReview"
import { Link } from "react-scroll"
import { useMediaQuery } from 'react-responsive';
import detail_banner1 from "images/detail_banner1.png"
import detail_banner2 from "images/detail_banner2.png"
import m_detail_banner1 from "images/m/detail_banner1.jpg"
import m_detail_banner2 from "images/m/detail_banner2.jpg"
import list_open from "images/list_open.png";
import list_close from "images/list_close.png";
const {Panel} = Collapse;

const DetailDesc = ({product, itemInfo, reviewCount, setReview, setReviewItem, type}) => {
    const isPc = useMediaQuery({ query: '(min-width: 961px)' })
    const productTips = itemInfo.Tips;
    const productAs = itemInfo.ShoppingGuide;

    const delay = 15;
    const [ScrollY, setScrollY] = useState(0);
    const [activeMenu, setActiveMenu] = useState("tabMenu1");

    const handleFollow = () => {
        setScrollY(window.pageYOffset);
        if(!document.getElementById("tab1")){
            return
        }
        const tab1Height = document.getElementById("tab1").offsetTop + 1000;
        const tab2Height = document.getElementById("tab2").offsetTop + 1000;
        const tab3Height = document.getElementById("tab3").offsetTop + 1000;
        const tab4Height = document.getElementById("tab4").offsetTop + 500;
        const tabMenu= document.getElementById("tab_menu");
        const height = document.body.scrollHeight - 1000;


        if (ScrollY > height) {
          setActiveMenu("tabMenu4");
        } else {
          if(ScrollY > tab4Height) {
            setActiveMenu("tabMenu4");
          } else if(ScrollY > tab3Height) {
              setActiveMenu("tabMenu3");
          } else if(ScrollY > tab2Height) {
              setActiveMenu("tabMenu2");
          } else {
              setActiveMenu("tabMenu1");
          }
        }

        if(ScrollY > tab1Height) {
          tabMenu.classList = "tab_menu fixed";
        } else {
          tabMenu.classList = "tab_menu";
        }
    };

    useEffect(() => {
        const watch = () => {
            window.addEventListener("scroll", handleFollow);
        };

        watch()
        return () => {
            window.removeEventListener("scroll", handleFollow);
        };
    });

    return (
        <DetailContainer>
            <div className="tab_menu" id="tab_menu">
                <ul>
                    <li className={activeMenu === "tabMenu1" ? "active" : null}>
                        <Link to="tab1" spy={true} offset={-100} smooth={true}>상세정보</Link>
                    </li>
                    <li className={activeMenu === "tabMenu2" ? "active" : null}>
                        <Link to="tab2" spy={true} offset={-100} smooth={true}>구매평({reviewCount})</Link>
                    </li>
                    <li className={activeMenu === "tabMenu3" ? "active" : null}>
                        <Link to="tab3" spy={true} offset={-100} smooth={true}>쇼핑안내</Link>
                    </li>
                    <li className={activeMenu === "tabMenu4" ? "active" : null}>
                        <Link to="tab4" spy={true} offset={-100} smooth={true}>사용TIP</Link>
                    </li>
                </ul>
            </div>
            <div id="tab1">
                <div className="detail_wrap">
                    {/* 독립몰 secret hidden 처리 */}
                    {/* {isPc ?
                        <img src={detail_banner1} alt="" />
                    :
                        <img src={m_detail_banner1} alt="" />
                    }
                    <a href="https://www.varram.co.kr/notice/?q=YToxOntzOjEyOiJrZXl3b3JkX3R5cGUiO3M6MzoiYWxsIjt9&bmode=view&idx=4344989&t=board"  rel="noopener noreferrer" target="_blank">
                        {isPc ?
                            <img src={detail_banner2} alt="" />
                            :
                            <img src={m_detail_banner2} alt="" />
                        }
                    </a> */}
                    {productAs.length !== 0 && productAs.map((el, idx) => {
                        if (el.id === 7) { // 상품상세 상단안내
                            return (
                            <React.Fragment key={idx}>
                                { el.content.indexOf('</') !== -1
                                    ? (
                                        <div className="product_desc_img" style={{marginBottom: '10px'}} dangerouslySetInnerHTML={{__html: el.content.replace(/(<? *script)/gi, 'illegalscript')}} >
                                        </div>
                                    )
                                    : el.content
                                }
                            </React.Fragment>
                            )
                        }
                    })}
                    <React.Fragment>
                        { product.content.indexOf('</') !== -1
                            ? (
                                <div dangerouslySetInnerHTML={{__html: product.content.replace(/(<? *script)/gi, 'illegalscript')}} >
                                </div>
                            )
                            : product.content
                        }
                    </React.Fragment>
                </div>
            </div>
            <div id="tab2">
                <ItemReview id={itemInfo} type={type} setReview={setReview} setReviewItem={setReviewItem}/>
            </div>
            <div id="tab3">
                <div className="borderLine"></div>
                <div className="guide_wrap">
                    {productAs.length !== 0 && productAs.map((el, idx) => {
                        if (el.id < 4) {
                            return (
                                <React.Fragment key={idx}>
                                    { el.content.indexOf('</') !== -1
                                        ? (
                                            <div className="product_desc_img" dangerouslySetInnerHTML={{__html: el.content.replace(/(<? *script)/gi, 'illegalscript')}} >
                                            </div>
                                        )
                                        : el.content
                                    }
                                </React.Fragment>
                            )
                        }
                    })}
                </div>
            </div>
            <div id="tab4">
                <div className="desc_tips">
                    <Collapse>
                        {productTips && productTips.map((el, idx) => (
                            <Panel key={idx} header={el.title} >
                                {el.answer}
                            </Panel>
                        ))}
                    </Collapse>
                </div>
            </div>
        </DetailContainer>
    );
};

const DetailContainer = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    & .tab_menu {
        background: #fff;
        z-index: 990;
        &.fixed {
            position: fixed;
            width: 1200px;
            top: 80px;
            left: 50%;
            transform: translateX(-50%);
        }
        & ul {
            display: flex;
            & li {
                width: 25%;

                border-bottom: 2px solid #EEF1F3;
                text-align: center;

                cursor: pointer;
                &.active {
                    border-bottom: 2px solid #1EA0FA;
                    & a {
                        color: #1EA0FA;
                        font-weight: 700;
                    }
                }
                & a {
                    display: block;
                    padding: 18px 0;
                    color: #515355;
                    font-size: 1rem;
                    line-height: 24px;
                }
            }
        }
    }
  @media only screen and (max-width: 960px){
    & .tab_menu {
        &.fixed {
            position: fixed;
            width: 100%;
            top: 60px;
            left: 0;
            transform: translateX(0);
        }
    }
  }

    & .ant-tabs-nav {
        margin-bottom: 0;
    }

    & .ant-tabs-nav-list {
        width: 100%;
    }

    & .ant-tabs-tab.ant-tabs-tab {
        padding: 16px 0 !important;
        width: 25%;
        justify-content: center;

        @media only screen and (max-width: 960px) {
            margin: 0;
            padding: 20px 0;
            font-size: 0.875rem;
            line-height: 21px;
            color: #515355;
        }
    }

    & .detail_wrap {
        width: 860px;
        padding: 80px 0 120px;
        margin: 0 auto;
        text-align: center;

        @media only screen and (max-width: 960px) {
            width: 100%;
            padding: 30px 0;
        }

        img {
            margin-bottom: 0px;
            vertical-align: bottom;
            width: 100% !important;
            height: auto !important;
        }

        & .product_desc_mainImg{
            width: 100%;
            margin: auto;

            @media only screen and (max-width: 960px) {
                margin: 0px 0 0 0;
            }
        }
    }

    & .borderLine {
      width: 100%;
      height: 1px;
      background-color: #d2d2d2;
      @media only screen and (max-width: 960px) {
        width: 95%;
        margin: 0 auto;
      }
    }

    & .guide_wrap {
        padding-top: 80px;
        @media only screen and (max-width: 960px) {
            padding: 30px 15px;
        }

        & .product_desc_img{
            width: 860px;
            margin: 0 auto;
            box-sizing: border-box;
            & img {
                display: block;
                width: 100%;
            }

            @media only screen and (max-width: 960px) {
              width: 100%;
              & img {
                  max-width: 100%;
              }
            }
        }
    }

    & .desc_tips{
        max-width: 1200px;
        width: 100%;
        margin: 80px auto 0;
        min-height: 1px;

        @media only screen and (max-width: 960px) {
            padding: 0 15px;
            margin: 0;
        }
    }

    .ant-tabs-nav-operations {
        display: none !important;
    }

    .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after {
        width: 0;
    }

    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
    }

    .ant-collapse {
        border: none;
    }

    .ant-collapse-item {
        border: none;
        border-bottom: 1px solid #ccc;
        background: url(${list_open}) no-repeat right 20px top 30px;
        &.ant-collapse-item-active {
            background: url(${list_close}) no-repeat right 20px top 30px;
        }

        @media only screen and (max-width: 960px) {
            background: url(${list_open}) no-repeat right 15px top 28px;
            &.active {
                background: url(${list_close}) no-repeat right 15px top 28px;
            }
        }
        &:first-child {
            border-top: 1px solid #222;
        }
    }

    .ant-collapse-header {
        padding: 20px !important;
        line-height: 27px !important;
        background-size: 12px;
        font-size: 1.125rem;
        span {
            display: none !important;
        }

        @media only screen and (max-width: 960px) {
            padding: 20px 43px 20px 15px !important;
            font-size: 1rem;
            line-height: 24px !important;
        }
    }

    .ant-collapse-content-box {
        padding: 30px 30px 60px 30px;
        font-size: 1rem;
        line-height: 24px;
        color: #222;
        word-break: break-all;

        @media only screen and (max-width: 960px) {
            padding: 20px 20px 40px 20px;
            font-size: 0.875rem;
            line-height: 21px;
        }
    }
`


export default DetailDesc;