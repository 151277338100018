import React from "react";
import styled from "styled-components";
import Dialog from './Dialog';
import UseTerms from "./UseTerms";

function TermsPopup({ visible, onCancel }) {
	return (
    <TermsDialog visible={visible}>
      <Dialog
        title="서비스 이용약관"
        sub_title="[필수]"
        cancelText="닫기"
        onCancel={onCancel}
        fullHeight
        fixHeight
        visible={visible}
      >
        <UseTerms />
      </Dialog>
    </TermsDialog>
	);
}

const TermsDialog = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};

  & .dialog_wrap {
    & h3 {
      margin-bottom: 30px;
    }
    & .dialog_inner {
      max-height: calc(90vh - 130px);
      padding: 0 30px 30px 30px;
      & p {
        font-size: 0.875rem;
        color: #515355;
        line-height: 21px;
        & b {
          font-weight: 500;
        }
      }
    }
    & .terms_box {
      &:first-child {
          margin-top: 0;
          & .terms_tit {
            margin-top: 0;
          }
      }
      &:not(:first-child) {
          margin-top: 10px;
      }

      & .terms_tit {
        margin: 30px 0 10px;
        font-size: 1rem;
        line-height: 24px;
        color: #222;
        font-weight: 500;
      }
      & .terms_cont {
          font-size: 14px;
          color: #515355;
          line-height: 21px;
      }
    }
  }
  @media only screen and (max-width: 960px) {
    & .dialog_wrap {
      & h3 {
        margin-bottom: 0;
        text-align: left;
      }
      & .dialog_inner {
        max-height: calc(100vh - 60px);
        padding: 25px 15px;
      }

      & .popup_tit {
        font-size: 0.875rem;
        line-height: 21px;
      }
    }
  }
`

export default TermsPopup;