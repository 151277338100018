import React from 'react';
import styled, { css } from 'styled-components';
import icon_unchecked from 'images/ico_unchecked.png';
import icon_checked from 'images/ico_check_w.png';
import m_icon_unchecked from 'images/m/ico_unchecked.png';
import m_icon_checked from 'images/m/ico_check_w.png';

const sizes = {
  medium: {
    width: '24px',
    height: '24px',
    lineHeight: '24px',
    backgroundSize: '12px auto',
    backgroundPosition: 'center top 6px'
  },
  small: {
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    backgroundSize: '9px auto',
    backgroundPosition: 'center'
  }
}

const sizeStyles = css`
  ${({ size }) => css`
    width: ${sizes[size].width};
    height: ${sizes[size].height};
    line-height: ${sizes[size].lineHeight};
    background-size: ${sizes[size].backgroundSize};
    background-position: ${sizes[size].backgroundPosition};
  `}
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-right:10px;
  & input[type="checkbox"] {
    position: absolute;
    width:1px;
    height:1px;
    padding:0;
    margin:-1px;
    overflow:hidden;
    clip:rect(0,0,0,0);
    border:0;
  }
  & input[type="checkbox"] + label {
    display:inline-flex;
    position:relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    line-height: 24px;
    word-break: break-all;
    &::before{
      content:'';
      display:inline-block;
      margin: 0 10px 0 0;
      text-align:center; 
      vertical-align:middle;
      background-color: #fff;
      background-repeat: no-repeat;
      background-image: url(${icon_unchecked});
      border:1px solid #ccc;
      border-radius:50%;
      @media only screen and (max-width: 960px) {
        background-image: url(${m_icon_unchecked});
      }
      ${sizeStyles};
    }
  }
  & input[type="checkbox"]:checked + label:before {
    background-color: #1EA0FA;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${icon_checked});
    border:1px solid #1EA0FA;
    @media only screen and (max-width: 960px) {
      background-image: url(${m_icon_checked});
    }
    ${sizeStyles};
  }
`

function Checkbox({ id, checked, onChange, label, size }) {
  return (
    <CheckboxContainer size={size}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </CheckboxContainer>
  )
}

Checkbox.defaultProps = {
  size: 'medium'
}

export default Checkbox;