import React, {useState, useEffect,useCallback, useRef} from "react";
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import Button from "./components/Button";
import { useMediaQuery } from 'react-responsive';
import ico_txt_del from "images/ico_txt_del.png";
import ico_unchecked from "images/ico_unchecked.png";
import ico_checked from "images/ico_check_w.png";
import m_ico_checked from "images/m/ico_check_w.png";
import m_ico_check_blue from "images/m/ico_check_blue.png";
import ico_check_blue from "images/ico_check_blue.png";
import img_agree_sns from "images/img_agree_sns.svg";
import { IoIosArrowForward } from "react-icons/io";
import { GrClose } from "react-icons/gr";
import axios from "axios";
import {
    validateEmail,
    matchPassword,
    checkAllItems,
    isPhoneNumOk, validateCodeNum, isPassWordSpecial, isPassWordUpper, isPassWordLower
} from '../utils/Validated'
import TermsPopup from './components/TermsPopup';
import PrivacyTermsPopup from "./components/PrivercyTermsPopup"
import AuthTimer from "./components/AuthTimer";
import ConfirmDialog from "./components/ConfirmDialog";
import Dialog from "./components/Dialog";
import { useForm } from "react-hook-form";
import {signUp} from "actions/userActions";


const JoinPage = (props) => {
    const kakao = props.history.location.state?.kakaoInfo;
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const errorMsg = useSelector((state) => state.userRegister.errorMsg || "")
    const dispatch = useDispatch();
    const {register, handleSubmit, getValues, setValue, watch} = useForm();
    const [counter, setCounter] =useState(1);
    const [accounts, setAccounts] = useState([]);
    const [createdForm, setCreatedForm] = useState([]);
    const [signUpInfo, setSignUpInfo] = useState({
        user_name: "",
        phone_number: "",
        phone_check: "",
        email: "",
        password: "",
        passwordConfirm: "",
    });


    const fetchForm = async () => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/auth/signupItem`);
            setCreatedForm(res.data.items)
        }catch (error){
            console.log(error)
        }
    }

    const {
        user_name,
        phone_number,
        phone_check,
        email,
        password,
        passwordConfirm,
        user_birthday,
    } = signUpInfo;

    useEffect(() => {
        if(kakao){
            if(!kakao.email){
                return
            }
            setValue("email", kakao.email)
            return setSignUpInfo({
                ...signUpInfo,
                ["email"] : kakao.email
            })
        }

        if(counter === 0){
            alert("인증번호를 받지 못했습니다.")
            setPhoneNumSend( {...phoneNumSend, btnColor: "gray", phoneDisabled: false, codeNumDisabled: true});
        }

        if(errorMsg){
            if(errorMsg === "InvalidAuthRequest"){
                alert("휴대폰 인증시간이 만료되었습니다.")
            }
        }

        fetchForm()
    }, [setSignUpInfo, counter]);

    const [pw1, setPw1] = useState(false)
    const [pw2, setPw2] = useState(false)
    const [pw3, setPw3] = useState(false)
    const [hideEmail, setHideEmail] = useState(true);
    const [hideNumber, setHideNumber] = useState(false);
    const [hidePassword, setHidePassword] = useState(true);
    const [hidePasswordConfirm, setHidePasswordConfirm] = useState(true);


    useEffect(() => {
        if(password.length !== 0){
            setHidePassword(false)
            if(password.length >= 8 && pw1 && pw2 && pw3){
                setHidePassword(true)
            }else{
                setHidePassword(false)
            }
            if (password.length !== 0 && isPassWordUpper(password)) {
                setPw1(true);
            } else{
                setPw1(false);
            }
            if (password.length !== 0 && isPassWordLower(password)) {
                setPw2(true);
            } else {
                setPw2(false);
            }
            if (password.length !== 0 && isPassWordSpecial(password)) {
                setPw3(true);
            } else {
                setPw3(false);
            }
        }else{
            setHidePassword(true)
            setPw1(false)
            setPw2(false)
            setPw3(false)
        }

        if(email.length !== 0 && !validateEmail(email)){
            setHideEmail(false)
        }else{
            setHideEmail(true)
        }

        if(passwordConfirm.length !== 0 && !matchPassword(password, passwordConfirm)){
            setHidePasswordConfirm(true)
        }else{
            setHidePasswordConfirm(false)
        }

        return (() => {
            setCheckedPhone(false)
        })
    }, [password, email, passwordConfirm, pw2, pw1, pw3, hidePassword])


    const onKeydown = (e) => {
        console.log(e.keyCode, e.target.name);
    }

    //input값 입력
    const inputHandler = useCallback((key) => (e) => {
            return setSignUpInfo({
                ...signUpInfo,
                [key]: e.target.value,
            });
        },[signUpInfo]
    )

    //x버튼 초기화
    const inputReset = useCallback((key) => (e) => {
            setValue(`${key}`, '')
            e.preventDefault()
            if(key==="password"){
                setPw1(false)
                setPw2(false)
                setPw3(false)
            }
            return setSignUpInfo({
                ...signUpInfo,
                [key]: "",
            })
        },[signUpInfo]
    )
    const [authAccepted, setAuthAccepted] = useState(false);
    const [phoneNumSend, setPhoneNumSend] = useState({
        btnColor: "gray",
        phoneDisabled: false,
        codeNumDisabled: true,
    })
    const checkEmail = useCallback(
        (e) => {
            e.preventDefault();
            axios.post(`${process.env.REACT_APP_API_URL}api/auth/validEmail`, {email})
                .then((res)=>{
                    if(res.data.type === "success"){
                        if(res.data?.data?.has_account){  //가입한 아이디가 있을 경우
                            setAccounts([...accounts, res.data.data.accounts])
                            for(let i = 0; i < res.data.data.accounts.length; i++){
                                return setAccountCheck({...accountCheck, open: true, email: res.data.data.accounts[i].email, time: res.data.data.accounts[i].login_time})
                            }
                        }else{
                            setAccountOk(true);
                        }
                    }else{
                        if(res.data.msg === "DeletedAccountDuplicationError"){
                            alert("기존 가입 이력이 있는 아이디입니다. 탈퇴 30일 후 재가입 또는 다른 아이디로 회원가입을 진행해주세요.")
                        }
                    }
                }).catch((error)=>{
                console.log(error)
            })
        }
    )

    const checkPhone = useCallback(
        (e) => {
            e.preventDefault();
            axios.post(`${process.env.REACT_APP_API_URL}api/auth/validPhone`, {phone_number})
                .then((res)=>{
                    console.log(res.data)
                    if(res.data.success){
                        setCounter(180)
                        setPhoneNumSend( {
                            ...phoneNumSend,
                            btnColor: "blue",
                            phoneDisabled: true,
                            codeNumDisabled: false})
                    }else{
                        console.log(res)
                    }
                }).catch((error)=>{
                console.log(error)
            })
        }
    )

    const checkNumber = useCallback(
        (e) => {
            e.preventDefault();
            axios.post(`${process.env.REACT_APP_API_URL}api/auth/checkPhone`,{
                phone_number, "code": phone_check, "type": "signup"
            }).then((res) => {
                console.log(res.data)
                if(res.data.type === "success"){  //인증번호 승인
                    setPhoneNumSend( {...phoneNumSend, btnColor: "gray", phoneDisabled: false, codeNumDisabled: true});
                    if(res.data.data.has_account){  //가입한 아이디가 있을 경우
                        setAccounts([...accounts, res.data.data.accounts])
                        for(let i = 0; i < res.data.data.accounts.length; i++){
                            if(kakao){
                                return setAccountCheckKakao({...accountCheckLocal, open: true, email: res.data.data.accounts[i].email, time: res.data.data.accounts[i].login_time})
                            }else{
                                return setAccountCheckLocal({...accountCheckLocal, open: true, email: res.data.data.accounts[i].email, time: res.data.data.accounts[i].login_time})
                            }
                        }
                    }else{
                        setAuthAccepted(true)
                        setAfterPhone(true);
                        setCheckedPhone(true)
                        setPhoneNumSend( {...phoneNumSend, btnColor: "gray", phoneDisabled: false, codeNumDisabled: false});
                    }
                    setHideNumber(!hideNumber)
                }else{
                    setHideNumber(true)
                    alert("인증번호가 맞지않습니다.")
                }
            }).catch((error)=>{
                console.log(error.response)
            })
        }
    )

    const checkEmailDomain = useCallback(
        (e) => {
            e.preventDefault()
            axios.post(`${process.env.REACT_APP_API_URL}api/auth/checkEmail`,{email})
                .then((res) => {
                    if(res.data.success === true){
                        setHideEmail(true)
                    }else{
                        setHideEmail(false)
                    }
                });
        }
    )

    const [all_agree, setAll_agree] = useState(false);
    const [agree_age, setAgree_age] = useState(false);
    const [agree_privacy, setAgree_privacy] = useState(false);
    const [agree_terms, setAgree_terms] = useState(false);
    const [smsAgree, setSmsAgree] = useState(false);
    const [emailAgree, setemailAgree] = useState(false);

    const onInputChange = (e) => {
        const value = e.target.value;
        if(agree_age && agree_privacy && agree_terms){
            setAll_agree(e.target.checked? true: false);
        }
        switch (e.target.name) {
            case "all_agree":
                setAll_agree(e.target.checked? true: false);
                setAgree_age(e.target.checked? true: false);
                setAgree_privacy(e.target.checked? true: false);
                setAgree_terms(e.target.checked? true: false);
                setSmsAgree(e.target.checked? true: false);
                setemailAgree(e.target.checked? true: false);
                break
            case "agree_age":
                setAgree_age(e.target.checked? true: false);
                break
            case "agree_privacy":
                setAgree_privacy(e.target.checked? true: false);
                break
            case "agree_terms":
                setAgree_terms(e.target.checked? true: false);
                break
            case "agree_notice":
                setemailAgree(e.target.checked? true: false);
                setSmsAgree(e.target.checked? true: false);
                break;
            case "agree_sms":
                setSmsAgree(e.target.checked? true: false);
                setemailAgree(e.target.checked? true: false);
                break;
            case "agree_email":
                setemailAgree(e.target.checked? true: false);
                setSmsAgree(e.target.checked? true: false);
                break;
        }
    }
    const requiredCheck = agree_age && agree_privacy && agree_terms

    const onSubmit = (data) => {
        const signUpData = {
            ...checkAddtional,
            "user_name": data.user_name,
            "phone_number" : data.phone_number,
            "email": email,
            "password" : data.password,
            "user_birthday": data.user_birthday,
            "recommend_id": data.recommender,
            "marketing_ad_allow_email" : smsAgree,
            "marketing_ad_allow_sms": emailAgree
        }
        const checkAddtional = createdForm.filter((el) => el.is_used === true).map((el) => ( signUpData[`additional_info_${el.id}`] = data[`additional_info_${el.id}`]))
        dispatch(signUp(signUpData))
        setCheckedPhone(false)
    }

    const kakaoLogin = (e, boolean) => {
        let click = e.target.click
        if(click){

            axios.post(`${process.env.REACT_APP_API_URL}api/auth/kakao/login`, {
                "email": kakao.email,
                "kakao_id": kakao.id,
                "phone_number": getValues("phone_number"),
                "local_sharing": boolean
            }).then((res) => {
                console.log(res)
                localStorage.setItem("userInfo", JSON.stringify({loading: false, userInfo:res.data, errorMsg: {}}));
                if(boolean){
                    document.location.href = "/"
                }else{
                    document.location.href = "/joinComplete"
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    const [ privacy, setPrivacy ] = useState(false);
    const closePrivacy = () => {
        setPrivacy(false);
    }

    const [ terms, setTerms ] = useState(false);
    const closeTerms = () => {
        setTerms(false);
    }
    const hide_string = (email) => {
        let domain = email.split("@")[1]
        let id = email.split("@")[0]
        let answer = ""
        for(let i=0; i < id.length; i++){
            if(i < id.length/2){
                answer = answer + "*";
            }else{
                answer = answer + id[i]
            }
        }
        return answer + "@" + domain
    }




    const [accountCheck, setAccountCheck] = useState({open: false, email: "", time: "", type: ""});
    const [accountOk, setAccountOk] = useState(false)
    const [afterPhone, setAfterPhone] = useState(false);
    const [checkedPhone, setCheckedPhone] = useState(false);
    const [ accountCheckLocal, setAccountCheckLocal] = useState({ open: false, email: "", time: ""});
    const [ accountCheckKakao, setAccountCheckKakao] = useState({ open: false, email: "", time: ""});
    const [ accountCheck3, setAccountCheck3] = useState(false);
    const [ accountCheck4, setAccountCheck4] = useState(false);
    const emailRef = useRef();

    const onkeydown = (e) => {
        if(e.key === "Enter"){
            emailRef.current.blur();
        }
    }

    return (
        <JoinContainer>
            <h2>{kakao ? "카카오 " : "일반 "}회원가입</h2>
            {kakao ? <form className="join_wrap" handleSubmit={onSubmit}>
                <div className="join_box">
                    <div className="join_title">이메일 아이디<span className="required">*</span></div>
                    <div className="join_cont">
                        <div>
                            <p><input type="text" name="email" {...register("email", { required: true})} disabled onFocus={(e) => {setHideEmail(!hideEmail)}} onChange={inputHandler("email")} placeholder="@까지 입력해주세요."  /></p>
                        </div>
                    </div>
                </div>
                <div className="join_box">
                    <div className="join_title">휴대폰 번호<span className="required">*</span></div>
                    <div className="join_cont phone">
                        <div>
                            <p><input type="text" name="phone_number" {...register("phone_number", { required: true })} onChange={inputHandler("phone_number")} placeholder="숫자만 입력해주세요." disabled={phoneNumSend.phoneDisabled ? true : false} /><button className="del_text" onClick={inputReset("phone_number")} ><img src={ico_txt_del} alt="지우기" /></button></p>
                            {isPhoneNumOk(phone_number) ?
                                <p><SmallButton onClick={!phoneNumSend.phoneDisabled ? checkPhone : null} size="small">인증번호 받기</SmallButton></p>
                                : <p><SmallButton size="small" >인증번호 받기</SmallButton></p> }
                        </div>
                        <div>
                            <p><input type="text"
                                      name="phone_check"
                                      {...register("phone_check", { required: true })}
                                      onChange={inputHandler("phone_check")}
                                      disabled={phoneNumSend.codeNumDisabled ? true : false}
                                      placeholder="인증번호 입력" />
                                <button onClick={inputReset("phone_check")} className="del_text"><img src={ico_txt_del} alt="지우기" /></button>
                            </p>
                            <p>
                                {!authAccepted ? <SmallButton color={phoneNumSend.btnColor} size="small" onClick={validateCodeNum(phone_check) ? checkNumber : null} disabled={phoneNumSend.codeNumDisabled ? true : false} >인증번호 확인</SmallButton>
                                    : <SmallButton color="gray" size="small" >인증번호 확인</SmallButton>}
                            </p>
                        </div>
                        {hideNumber && !authAccepted ? <span>
              </span> : null}
                        {phoneNumSend.phoneDisabled ?<span>
              <p className="sub_desc">인증번호를 발송하였습니다. <b className="error_msg">유효시간 : <AuthTimer counter={counter} setCounter={setCounter} /></b></p>
            </span> : null}
                    </div>
                </div>
                <div className="join_button_wrap">
                    <MainButton color="blue" onClick={(e) => {kakaoLogin(e,false)}} fullWidth >가입하기</MainButton>
                </div>
            </form> : <form className="join_wrap">
                <div className="join_box">
                    <div className="join_title">이메일 아이디<span className="required">*</span></div>
                    <div className="join_cont">
                        <div>
                            <p><input type="text" name="email" onChange={inputHandler("email")} ref={emailRef} onKeyDown={onkeydown} onBlur={checkEmailDomain} placeholder="@까지 입력해주세요." /><button className="del_text" onClick={inputReset("email")}><GrClose size="13" alt="지우기" /></button></p>
                            <p>{hideEmail ? <SmallButton onClick={!validateEmail(email) ? null : checkEmail} size="small">중복확인</SmallButton> :
                                <SmallButton size="small">중복확인</SmallButton>}</p>
                        </div>
                        <p className="sub_desc onMarked" style={{color: "#999"}}>이메일은 아이디 및 비밀번호 찾기에 사용됩니다.  {hideEmail ? null : <>
                            <b className="error_msg">정확한 이메일 형식으로 입력해주세요.</b>
                        </> }</p>

                    </div>
                </div>
                <div className="join_box">
                    <div className="join_title">비밀번호<span className="required">*</span></div>
                    <div className="join_cont">
                        <p><input type="password" name="password" tabIndex="2" {...register("password")} onChange={inputHandler("password")} placeholder="영문 대소문자+특수문자 (8자리 이상)"  /><button className="del_text" onClick={inputReset("password")}><GrClose size="13" alt="지우기" /></button></p>
                        <p className="sub_desc onMarked">
                            <b>
                                {pw1 ? <span className="active">영문대문자</span> : <span>영문대문자</span>}
                                {pw2 ? <span className="active">영문소문자</span> : <span>영문소문자</span>}
                                {pw3 ? <span className="active">특수문자</span> : <span>특수문자</span>}</b>
                            {hidePassword ? null : <>
                                <b className="error_msg">대문자, 소문자, 특수문자를 모두 포함해야 합니다.</b>
                            </>}
                        </p>
                    </div>
                </div>
                <div className="join_box">
                    <div className="join_title">비밀번호 확인<span className="required">*</span></div>
                    <div className="join_cont">
                        <p><input type="password" tabIndex="3" name="passwordConfirm" {...register("passwordConfirm")} onChange={inputHandler("passwordConfirm")} placeholder="영문 대소문자+특수문자 (8자리 이상)" /><button className="del_text" onClick={inputReset("passwordConfirm")}><GrClose size="13" alt="지우기" /></button></p>
                        <p className="sub_desc onMarked">
                            {matchPassword(password, passwordConfirm) ? <span className="active">비밀번호 일치</span> : <span>비밀번호 일치</span> }
                            {hidePasswordConfirm ? <b>
                                <b className="error_msg">비밀번호가 올바르지 않습니다.</b>
                            </b> : null}
                        </p>
                    </div>
                </div>
                <div className="join_box">
                    <div className="join_title">이름<span className="required">*</span></div>
                    <div className="join_cont">
                        <p><input type="text" placeholder="ex) 홍길동" tabIndex="4" name="user_name" {...register("user_name")} onChange={inputHandler("user_name")} /><button onClick={inputReset("user_name")} className="del_text"><GrClose size="13" alt="지우기" /></button></p>
                    </div>
                </div>
                <div className="join_box">
                    <div className="join_title">휴대폰 번호<span className="required">*</span></div>
                    <div className="join_cont phone">
                        <div>
                            <p><input type="text" name="phone_number" tabIndex="5" {...register("phone_number")} onChange={inputHandler("phone_number")} placeholder="숫자만 입력해주세요." disabled={phoneNumSend.phoneDisabled ? true : false} /><button className="del_text" onClick={inputReset("phone_number")} ><img src={ico_txt_del} alt="지우기" /></button></p>
                            {isPhoneNumOk(phone_number) ?
                                <p><SmallButton onClick={!phoneNumSend.phoneDisabled ? checkPhone : null} size="small">인증번호 받기</SmallButton></p>
                                : <p><SmallButton size="small" >인증번호 받기</SmallButton></p> }
                        </div>
                        <div>
                            <p><input type="text"
                                      name="phone_check"
                                      {...register("phone_check")}
                                      onChange={inputHandler("phone_check")}
                                      disabled={phoneNumSend.codeNumDisabled ? true : false}
                                      placeholder="인증번호 입력"
                                      onKeyDown={() => {setHideNumber(false)}}
                                      tabIndex="6"/>
                                <button onClick={inputReset("phone_check")} className="del_text"><img src={ico_txt_del} alt="지우기" /></button>
                            </p>
                            <p>
                                {!authAccepted ? <SmallButton color={phoneNumSend.btnColor} size="small" onClick={validateCodeNum(phone_check) ? checkNumber : null} disabled={phoneNumSend.codeNumDisabled ? true : false} >인증번호 확인</SmallButton>
                                    : <SmallButton color="gray" size="small" >인증번호 확인</SmallButton>}
                            </p>
                        </div>
                        {hideNumber && !authAccepted ? <span>
              </span> : null}
                        {phoneNumSend.phoneDisabled ?<span>
              <p className="sub_desc">인증번호를 발송하였습니다. <b className="error_msg">유효시간 : <AuthTimer counter={counter} setCounter={setCounter} /></b></p>
            </span> : null}
                        {counter === 0 ? <p className="sub_desc error_msg">인증번호를 받지못했습니다.</p> : null}
                    </div>
                </div>
                <div className="join_box">
                    <div className="join_title">반려동물 생년월일</div>
                    <div className="join_cont">
                        <p><input type="text" name="user_birthday" tabIndex="7" {...register("user_birthday")} onChange={inputHandler("user_birthday")} placeholder="YYYY / MM / DD" /><button className="del_text" onClick={inputReset("user_birthday")}><GrClose size="13" alt="지우기" /></button></p>
                        <p className="sub_desc">반려동물의 생일 또는 입양 날짜를 알려주세요!{isMobile && <br />}특별한 혜택을 드립니다.</p>
                    </div>
                </div>
                {createdForm.map((formList) => {
                    return (
                        <>{formList.is_used && <div className="join_box" key={formList.id}>
                            <div className="join_title">{formList.item_name}{formList.is_essential && <span className="required">*</span>}</div>
                            <div className="join_cont">
                                {formList.is_essential ? <p><input type="text" name={formList.item_name} {...register(`additional_info_${formList.id}`, {required: true})} /> <button className="del_text" onClick={inputReset(`additional_info_${formList.id}`)}><GrClose size="13" alt="지우기" /></button></p> :
                                    <p><input type="text" name={formList.item_name} {...register(`additional_info_${formList.id}`)} /> <button className="del_text" onClick={inputReset(`additional_info_${formList.id}`)}><GrClose size="13" alt="지우기" /></button></p>}
                            </div>
                        </div>}</>
                    )
                })}
                <div className="join_box agreement_box">
                    <div className="join_title">이용약관동의<span className="required">*</span></div>
                    <div className="join_cont">
                        <div className="checks all_check">
                            <input type="checkbox" name="all_agree" id="all_agree" checked={agree_age && agree_terms && agree_privacy ? true : false} onChange={onInputChange} />
                            <label htmlFor="all_agree">전체 동의합니다.</label>
                        </div>
                        <p className="small_desc">선택 항목에 동의하지 않은 경우도 회원가입 및 일반적인 서비스를 이용할 수 있습니다.</p>
                        <div className="agree_wrap">
                            <div className="checks no_more">
                                <input type="checkbox" name="agree_age" id="agree_age" checked={agree_age} onChange={onInputChange}  />
                                <label htmlFor="agree_age">[필수] 만 14세 이상</label>
                            </div>
                            <div className="checks">
                                <input type="checkbox" name="agree_terms" id="agree_terms" checked={agree_terms} onChange={onInputChange} />
                                <label htmlFor="agree_terms">[필수] 이용약관 동의</label>
                                <span className="agree_more" onClick={() => setTerms(true)}><IoIosArrowForward size="20" color="#999" /></span>
                            </div>
                            <div className="checks">
                                <input type="checkbox" name="agree_privacy" id="agree_privacy" checked={agree_privacy} onChange={onInputChange} />
                                <label htmlFor="agree_privacy">[필수] 개인정보 수집 및 이용 동의</label>
                                <span className="agree_more" onClick={() => setPrivacy(true)}><IoIosArrowForward size="20" color="#999" /></span>
                            </div>
                            <div className="checks">
                                <input type="checkbox" name="agree_notice" id="agree_notice" checked={smsAgree || emailAgree} onChange={onInputChange} />
                                <label htmlFor="agree_notice">[선택] 무료배송, 할인 쿠폰 등 마케팅 혜택 및 정보 수신 동의</label>
                            </div>
                            <p className="small_desc">마케팅 정보 수신 동의 시, 꼭 필요한 정보(사료 추천, 신제품 출시, 할인 등)만 알려드립니다. (앱 푸쉬, SMS,알림톡,이메일)</p>
                            <div className="sns_agree">
                                <div className="checks">
                                    <input type="checkbox" name="agree_sms" id="agree_sms" checked={smsAgree} onChange={onInputChange} />
                                    <label htmlFor="agree_sms">SMS</label>
                                </div>
                                <div className="checks">
                                    <input type="checkbox" name="agree_email" id="agree_email" checked={emailAgree} onChange={onInputChange} />
                                    <label htmlFor="agree_email">이메일</label>
                                </div>
                            </div>
                            <p className="img_agree_sns">
                                <img src={img_agree_sns} alt="동의하고, 우리 아이 맞춤 혜택을 받아보세요!" />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="join_button_wrap">
                    {checkAllItems(email, password, passwordConfirm, user_name) && requiredCheck && pw1 && pw2 && pw3 && checkedPhone ?
                        <MainButton color="blue" onClick={handleSubmit(onSubmit)} fullWidth >가입하기</MainButton> :
                        <MainButton color="gray" fullWidth >가입하기</MainButton>}
                </div>
            </form>}

            {/* 동의 항목 팝업창*/}
            <TermsPopup visible={terms} onCancel={closeTerms} />
            <PrivacyTermsPopup visible={privacy} onCancel={closePrivacy} />


            {/*이메일 중복확인 */}
            <div className="account_check_dialog">
                <Dialog
                    onCancel= {() => {setAccountCheck({ ...accountCheck, open: false, email: "", time: ""}); setAccounts([]);}}
                    onConfirm={(e) => {setAccountCheck({ ...accountCheck, open: false, email: "", time: ""}); setAccounts([]);}}
                    confirmText="로그인"
                    cancelText="닫기"
                    dialogSize="small"
                    visible={accountCheck.open}
                >
                    <p>이미 가입된 계정입니다.</p>
                    {accounts[0]?.map((el) => {
                        return <div>
                            <p>{el.login_type === "local" ? "이메일 " : "카카오 "} 아이디</p>
                            <p>{hide_string(el.email)}</p>
                            <p>마지막 로그인 기록 {el.login_time.replaceAll("-", ".")}</p>
                        </div>
                    })}
                </Dialog>
            </div>
            <div className="account_check_dialog">
                <Dialog
                    onCancel= {() => setAccountOk(false)}
                    onConfirm={() => setAccountOk(false)}
                    cancelText="닫기"
                    dialogSize="small"
                    visible={accountOk}
                >
                    <p>사용 가능한 계정입니다.</p>
                </Dialog>
            </div>
            <div className="account_check_dialog">
                <Dialog
                    onCancel= {() => setAfterPhone(false)}
                    onConfirm={() => setAfterPhone(false)}
                    cancelText="확인"
                    dialogSize="small"
                    visible={afterPhone}
                    btnHide={true}
                >
                    <p>인증되었습니다.</p>
                </Dialog>
            </div>
            {/*이메일 중복확인 */}

            {/* 휴대폰 인증번호 확인 시 (기존로컬 가입O > 로컬 가입 (불가) */}
            <div className="account_check_dialog">
                <Dialog
                    onCancel={() => setAccountCheckLocal({ ...accountCheckLocal, open: false})}
                    onConfirm={(e) => {setAccountCheckLocal({ ...accountCheckLocal, open: false, email: "", time: ""}); setAccounts([]);}}
                    confirmText="로그인"
                    cancelText="닫기"
                    dialogSize="small"
                    visible={accountCheckLocal.open}
                >
                    <p>이미 가입된 계정입니다.</p>
                    {accounts[0]?.map((el) => {
                        return <div>
                            <p>{el.login_type === "local" ? "이메일 " : "카카오 "} 아이디</p>
                            <p>{hide_string(el.email)}</p>
                            <p>마지막 로그인 기록 {el.login_time.replaceAll("-", ".")}</p>
                        </div>
                    })}
                </Dialog>
            </div>
            {/* 휴대폰 인증번호 확인 시 (기존로컬 가입O > 로컬 가입 (불가) */}

            {/* 휴대폰 인증번호 확인 시 (기존카카오 가입O > 로컬 가입 (불가) */}
            <div className="account_check_dialog">
                <Dialog
                    onCancel={(e) => {setAccountCheckKakao({ ...accountCheckKakao, open: false, email: "", time: ""}); setAccounts([]);}}
                    onConfirm={(e) => {setAccountCheckKakao({ ...accountCheckKakao, open: false, email: "", time: ""}); setAccounts([]); kakaoLogin(e, true)}}
                    confirmText="로그인"
                    cancelText="닫기"
                    dialogSize="small"
                    visible={accountCheckKakao.open}
                >
                    <p>이미 가입된 계정입니다.</p>

                    <div>
                        {accounts[0]?.map((el) => {
                            return <div>
                                <p>{el.login_type === "local" ? "이메일 " : "카카오 "} 아이디</p>
                                <p>{hide_string(el.email)}</p>
                                <p>마지막 로그인 기록 {el.login_time.replaceAll("-", ".")}</p>
                            </div>
                        })}
                    </div>
                </Dialog>
            </div>
            {/* 휴대폰 인증번호 확인 시 (기존카카오 가입O > 로컬 가입 (불가) */}

            {/* 카카오 회원가입 시 기존 로컬 가입 이력이 있는 경우 (기존로컬 가입O > 카카오 가입 시 (가능) */}
            <div className="account_check_dialog">
                <Dialog
                    onCancel={() => setAccountCheck3(false)}
                    onConfirm={() => setAccountCheck3(false)}
                    confirmText="로그인"
                    cancelText="닫기"
                    dialogSize="small"
                    visible={accountCheck3}
                >
                    <p>이미 가입된 계정입니다.<span>그래도 진행하시겠습니까?</span></p>
                    <div>
                        <p>이메일 아이디</p>
                        <p>rami****@naver.com</p>
                        <p>마지막 로그인 기록  2021.11.03</p>
                    </div>
                </Dialog>
            </div>
            {/* 휴대폰 인증번호 확인 시 (기존카카오 가입O > 로컬 가입 (불가) */}

            {/* 카카오 회원가입 시 기존 로컬 가입 이력이 있는 경우 (기존카카오 가입O > 카카오 가입 시 (불가) */}
            <div className="account_comfirm_dialog">
                <ConfirmDialog
                    onConfirm={() => setAccountCheck4(false)}
                    confirmText="닫기"
                    dialogSize="medium"
                    visible={accountCheck4}
                >
                    <p>이미 가입된 이력이 있습니다.</p>
                </ConfirmDialog>
            </div>
            {/* 휴대폰 인증번호 확인 시 (기존카카오 가입O > 로컬 가입 (불가) */}
        </JoinContainer>
    );
}

const SmallButton = styled(Button)`
  width: 120px;
  border-radius: 0;
  margin-left: 10px;
  @media only screen and (max-width: 960px) {
    height: 45px;
    width: 100px;
    font-weight: 500;
    font-size: 0.875rem;
    margin-left: 5px;
  }
`

const MainButton = styled(Button)`
  @media only screen and (max-width: 960px) {
    height: 50px;
    font-weight: 700;
    font-size: 1rem;
  }
`

const JoinContainer = styled.div`
  max-width: 1480px;
  margin: 0 auto;

  & h2 {
    padding: 80px 0 60px;
    line-height: 48px;
    font-size: 2rem;
    text-align: center;
  }

  & .join_wrap {
    width: 900px;
    margin: 0 auto;
    padding: 40px 0 60px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    & .join_box {
      display: flex;
      align-items: flex-start;
      width: 100%;
      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    & .join_title {
      width: 230px;
      padding-top: 10px;
      font-size: 1rem;
      line-height: 24px;
    }

    & .join_cont {
      width: calc(100% - 230px);

      & > div {
        display: flex;
        width: 100%;

        & p:first-child {
          width: calc(100%);
        }
      }

      &.phone > div:not(:first-child) {
        margin-top: 10px;
      }

      p {
        position: relative;
      }

      & .del_text {
        position: absolute;
        right: 10px;
        top: 13px;
        & path {
          stroke: #ccc;
        }
      }

      & .sub_desc {
        margin-top: 8px;
        color: #1ea0fa;
        font-size: 0.75rem;
        line-height: 18px;

        span {
          display: inline-block;
          margin-right: 24px;
          padding-right: 13px;
          background: url(${ico_unchecked}) no-repeat right top 5px;
          background-size: 8px auto;
          font-size: 0.75rem;
          color: #999;
          line-height: 18px;

          &.active {
            background: url(${ico_check_blue}) no-repeat right top 5px;
            color: #1EA0FA;
            background-size: 8px auto;
            @media only screen and (max-width: 960px) {
              background: url(${m_ico_check_blue}) no-repeat right top 5px;
              background-size: 8px auto;
            }
          }
        }
      }

      & .sub_desc .error_msg{
        margin-top: 0;
      }

      & .onMarked {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-top: 8px;

        @media only screen and (max-width: 960px) {
          flex-wrap: wrap;
        }
      }
      
      
    }
  }

  & .agreement_box {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #ccc;

    & .join_cont {
      & .checks {
        margin-right: 0;
        & input[type="checkbox"]:checked + label:before {
          background:url(${ico_check_blue}) no-repeat center;
          background-position: center;
          @media only screen and (max-width: 960px) {
            background:url(${m_ico_check_blue}) no-repeat center;
            background-size: 12px auto;
          }
        }
        & input[type="checkbox"] + label:before {
          width: 24px;
          height: 24px;
          background-position: center;
        }
      }
      & .all_check {
        & input[type="checkbox"] + label:before {
          background-size: 13px 11px;
        }
        input[type="checkbox"]:checked + label:before {
          background: #1EA0FA url(${ico_checked}) no-repeat center;
          @media only screen and (max-width: 960px) {
            background: #1EA0FA url(${m_ico_checked}) no-repeat center;
            background-size: 13px auto;
          }
        }
        label {
          font-size: 1.125rem;
          line-height: 27px;
          color: #222;
        }
      }

      & > .small_desc {
        margin: 8px 0 21px;
      }
    }

    .small_desc {
      margin: 8px 0 0 27px;
      font-size: 0.75rem;
      color: #999;
      line-height: 18px;
    }

    & .agree_wrap {
      margin-top: 0;
      flex-direction: column;

      & >.checks {
        display: flex;
        &:not(.no_more) {
          span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            margin-left: auto;
            cursor: pointer;
          }
          &:not(:first-child) {
            margin-top: 12px;
          }
        }
      }
      .checks input[type="checkbox"] + label:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        line-height: 24px;
        margin-right: 17px;
        border: none;
      }
      label {
        font-size: 0.875rem;
        line-height: 24px;
        color: #222;
        padding: 0 27px;
      }
    }

    & .sns_agree {
      display: flex;
      padding-left: 35px;
      margin-top: 13px;

      & .checks:first-child {
        margin-right: 105px;
      }
    }

    & .img_agree_sns {
      margin: 8px 0 0 28px;
    }
  }

  & .join_button_wrap {
    margin: 60px auto 88px;
    width: 500px;
  }

  @media only screen and (max-width: 960px) {
    width: 100%;
    min-width: 100%;
    padding: 0 15px;

    & h2 {
      padding: 40px 0;
      line-height: 36px;
      font-size: 1.5rem;
    }

    & .join_wrap {
      width: 100%;
      padding: 30px 0 20px;

      & .join_box {
        align-items: flex-start;
        flex-direction: column;
        &:not(:first-child) {
          margin-top: 20px;
        }
      }

      & .join_title {
        width: 100%;
        padding: 0px;
        margin-bottom: 10px;
        font-size: 0.938rem;
        line-height: 22.5px;
      }

      & .join_cont {
        width: 100%;
        input {
          padding: 10px 15px;
          &:disabled {
            background: #fff;
          }
        }
        & > div {
          width: 100%;
          & p:first-child {
            width: calc(100%);
          }
        }
        &.phone > div:not(:first-child) {
          margin-top: 10px;
        }
        & .sub_desc {
          span {
            margin-right: 17px;
            padding-right: 13px;
          }
        }
      }
    }

    & .agreement_box {
      margin-top: 30px;
      padding-top: 30px;

      & .join_title {
        margin-bottom: 20px;
      }

      & > div {
        & > p {
          margin: 8px 0 0 0;
          padding-bottom: 10px;
          border-bottom: 1px solid #eee;
        }
      }

      & .agree_wrap {
        margin-left: 0;
        flex-direction: column;
        & >.checks:not(:first-child) {
          margin-top: 17px;
        }
      }

      & .sns_agree {

        & .checks:first-child {
          margin-right: 100px;
        }
      }

      & .img_agree_sns {
        margin: 5px 0 0 33px;
      }
    }

    & .join_button_wrap {
      margin: 40px auto 44px;
      width: 100%;
    }
  }

  & .account_check_dialog {
    & .dialog_wrap {
      padding: 50px 30px 30px 30px;
      text-align: center;
      & .close {
        display: none;
      }
      & .dialog_inner {
        & > p:first-child {
          margin-bottom: 30px;
          font-size: 20px;
          line-height: 30px;
          font-weight: 500;
          & span {
            display: block;
            margin-top: 2px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
          }
        }
        & div {
          margin-top: 10px;
          padding: 20px 0;
          background: #f7f7f7;
          & p:first-child {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            color: #555;
          }
          & p:nth-child(2) {
            margin-bottom: 12px;
            font-size: 18px;
            line-height: 27px;
            color: #1EA0FA;
          }
          & p:nth-child(3) {
            font-size: 13px;
            line-height: 19.5px;
            color: #888;
          }
        }
      }
      & .ButtonWrap {
        width: 100%;
        margin-top: 40px;
        & div {
          width: calc((100% - 5px) / 2);
          &:not(:first-child) {
            margin-left: 5px;
          }
        }
      }
    }
    @media only screen and (max-width: 960px) {
      & .dialog_wrap {
        padding: 40px 27.5px 30px 27.5px;
        text-align: center;
        & .close {
          display: none;
        }
        & .dialog_inner {
          & > p {
            font-size: 18px;
            line-height: 27px;
          }
        }
        & .ButtonWrap {
          & div {
            width: calc((100% - 10px) / 2);
            font-weight: 700;
            &:not(:first-child) {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  & .account_comfirm_dialog {
    & .dialog_block {
      padding: 130px 30px 30px;
      & .close_btn {
        display: none;
      }
      & .button_wrap {
        margin-top: 100px;
        & div {
          width: 227px;
          font-weight: 700;
        }
      }
    }
  }
`

export default JoinPage;
