import React from 'react';
import styled from 'styled-components';
import PrivacyTerms from 'pages/components/PrivacyTerms';

const PrivacyPage = () => {
  return (
    <PrivacyContainer>
      <div className="board_wrap">
        <div className="wrap_title board_title">
          <h2>개인정보 처리방침</h2>
        </div>
        <PrivacyTerms />
      </div>
    </PrivacyContainer>
  )
}

const PrivacyContainer = styled.div`
  width: 1200px;
  margin: 0 auto;
  & .cont_wrap {
    padding: 60px 0;
  }

  @media only screen and (max-width: 960px) {
    width: 100%;
    & .cont_wrap {
      padding: 0;
    }
  }
`;

export default PrivacyPage;