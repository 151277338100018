import React, {useState} from "react";
import styled from "styled-components";
import Button from "pages/components/Button";
import Dialog from "pages/components/Dialog";
import DotGuide from "pages/components/DotGuide";

const ChangePhone = () => {
  const getCode = () => {

  }
  const checkCode = () => {

  }
  const [ dialog, setDialog ] = useState(false);
  const checkPhone = () => {
    setDialog(true);
  }

	return (
    <ChangePhoneContainer>
      <div className="find_wrap">
        <p><span>휴대폰 번호</span>를 입력해 주세요.</p>
        <div className="find_form chk_phone">
          <form>
            <p>
              <input type="number" name="" placeholder="숫자만 입력해주세요."></input>
              <SmallButton size="small" onclick={getCode}>인증번호 받기</SmallButton> 
            </p>
            <p>
              <input type="number" name="" placeholder="인증번호 입력"></input>
              <SmallButton size="small" color="gray" onclick={checkCode}>인증번호 확인</SmallButton> 
            </p>
            <div className="button_wrap">
              <MainButton color="gray" fullWidth onClick={checkPhone}>인증하기</MainButton>
            </div>
          </form>
        </div>
      </div>
      <div className="account_check_dialog">
        <Dialog
          onConfirm={() => window.location.href="/login"}
          visible={dialog}
          confirmText="로그인"
          dialogSize="small"
        >
          <p>이메일 계정을 찾았습니다.</p>
          <div>
            <p>카카오 아이디</p>
            <p>rami****@naver.com</p>
            <p>마지막 로그인 기록  2021.11.03</p>
          </div>
          <div>
            <p>이메일 아이디</p>
            <p>rami****@naver.com</p>
            <p>마지막 로그인 기록  2021.11.03</p>
          </div>
          <DotGuide
            text="전체 이메일을 확인하고자 하는 경우에는 고객센터로 문의해 주세요."
            size="tiny"
            color="lightgray"
          />
        </Dialog>
      </div>
    </ChangePhoneContainer>
	);
}

const SmallButton = styled(Button)`
  width: auto;
  padding: 0 14px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
  @media only screen and (max-width: 960px) {
    flex: 100px 0 0;
    padding: 0;
    border-radius: 0;
    font-size: 14px;
  }
`

const MainButton = styled(Button)`
	@media only screen and (max-width: 960px) {
		height: 50px;
    font-weight: 700;
    font-size: 1rem;
	}
`

const ChangePhoneContainer = styled.div`
  max-width: 1480px;
  margin: 0 auto;

  & .find_wrap {
    width: 500px;
    margin: 0 auto;
    & > p {
      margin: 100px 0 60px;
      font-size: 26px;
      font-weight: 300;
      line-height: 39px;
      & span {
        font-weight: 400;
      }
    }
    & .find_form {
      p:not(:first-child) {
        margin-top: 10px;
      }
    }

    & .chk_phone {
      p {
        display: flex;
      }
    }

    & .button_wrap {
      margin: 80px 0;
    }
  }

  @media only screen and (max-width: 960px) {
    margin: 0;
    & .find_wrap {
      width: 100%;
      margin: 0;
      padding: 0 15px;
      & > p {
        margin: 60px 0 30px;
        font-size: 22px;
        line-height: 33px;
        & span {
          font-weight: 400;
        }
      }
      & .find_form {
        p:not(:first-child) {
          margin-top: 5px;
        }
        input {
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
      }

      & .button_wrap {
        margin: 40px 0 46px;
      }
    }
  }

  & .account_check_dialog {
    & .dialog_wrap {
      padding: 50px 30px 30px 30px;
      text-align: center;
      & .close {
        display: none;
      }
      & .dialog_inner {
        & > p:first-child {
          margin-bottom: 30px;
          font-size: 20px;
          line-height: 30px;
          font-weight: 500;
          & span {
            display: block;
            margin-top: 2px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
          }
        }
        & div {
          margin-top: 10px;
          padding: 20px 0;
          background: #f7f7f7;
          & p:first-child {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            color: #555;
          }
          & p:nth-child(2) {
            margin-bottom: 12px;
            font-size: 18px;
            line-height: 27px;
            color: #1EA0FA;
          }
          & p:nth-child(3) {
            font-size: 13px;
            line-height: 19.5px;
            color: #888;
          }
        }
        & .dot_guide {
          margin-top: 10px;
        }
      }
      & .ButtonWrap {
        width: 100%;
        margin-top: 40px;
        & div {
          width: calc((100% - 5px) / 2);
          &:not(:first-child) {
            margin-left: 5px;
          }
        }
      }
    }
    @media only screen and (max-width: 960px) {
      & .dialog_wrap {
        padding: 40px 27.5px 30px 27.5px;
        text-align: center;
        & .close {
          display: none;
        }
        & .dialog_inner {
          & > p {
            font-size: 18px;
            line-height: 27px;
          }
        }
        & .ButtonWrap {
          & div {
            width: calc((100% - 10px) / 2);
            font-weight: 700;
            &:not(:first-child) {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
`

export default ChangePhone;