import React, {useEffect, useState, useCallback} from 'react';
import Styled from "styled-components";
import {Link} from "react-router-dom";
import {useMediaQuery} from 'react-responsive';
import 'styles/GlobalStyles.scss';
import OrderCancelPopup from "pages/components/OrderCancelPopup";
import WriteReviewPopup from "pages/components/WriteReviewPopup";
import MyPageReviewPopup from "pages/components/MyPageReviewPopup";
import ReqRefundPopup from "pages/components/ReqRefundPopup";
import Dialog from "pages/components/Dialog";
import delivery_state from "images/example/delivery_state.png";
import {useSelector, useDispatch} from "react-redux";
import {appendOrderLists, fetchOrderLists, updateOrderLists} from "actions/orderActions";
import {IoIosArrowForward} from "react-icons/io";
import {stringPrice} from "utils/StringPrice";
import ReqExchangePopup from "../../components/ReqExchangePopup";
import Moment from "moment"
import {FETCH_ORDER_RESET} from "../../../actions/types";

const OrderDetails = () => {
    const isPc = useMediaQuery({query: '(min-width: 960px)'})
    const isMobile = useMediaQuery({query: '(max-width: 960px)'})
    const dispatch = useDispatch()
    const orderLists = useSelector((state) => state.nomalOrderList);
    const orderListsData = useSelector((state) => state.nomalOrderList.orderLists);  //일반 주문내역 리스트
    const [limit, setLimit] = useState(10); //더보기 버튼 제한
    const [reviewSubmit, setReviewSubmit] = useState(false);
    const [orderListNormal, setOrderListNormal] = useState([]);
    const [orderListsReg, setorderListsReg] = useState([]);
    const [checkedTab, setCheckedTab] = useState({ //탭 선택 default
        tab1: "전체", // "전체" : "일반배송" : "정기배송"
        tab2: "전체" //"전체" : 입급확인 : 배송중 : 배송완료 : 배송지연 : 환불완료 : 반품완료
    })
    const [orderPage, setOrderPage] = useState(1);
    const [isUpdate, setIsUpdate] = useState({date: new Date().getTime(), page: 1});
    const [updatePage, setUpdatePage] = useState(1);
    const [loadCount, setLoadCount] = useState(0);

    const handleTabs = useCallback((key) => (e) => {  //선택한 탭으로 변경
            setLoadCount(1);
            setCheckedTab(prevState => { return {...prevState, [key]: e.target.value} })
        }, [checkedTab]
    )
    useEffect(() => {
        setLimit(10);
        setOrderPage(1);
        dispatch(fetchOrderLists(orderPage, false, checkedTab))
    }, [checkedTab])
    useEffect(() => {
        return () => {
            dispatch({type: FETCH_ORDER_RESET})
            setLimit(10);
            setReviewSubmit(false);
            setOrderListNormal([]);
            setorderListsReg([]);
            setCheckedTab({
                tab1: "전체",
                tab2: "전체"
            })
            setOrderPage(1);
            setIsUpdate({date: new Date().getTime(), page: 1});
            setUpdatePage(1);
        }
    }, [])
    useEffect(() => {
        if (orderLists.data_count < 10) { // 더보기 눌렀을때 데이터 중첩시키기
            return
        }
        dispatch(appendOrderLists(orderPage, false, checkedTab));
    }, [orderPage])
    useEffect(() => { // 내용 업데이트 필요할때
        // closeWriteReview 함수 리뷰작성했을때
        // closeMyReview 리뷰 보고 나왔을때 주문서내역 업데이트 (해당하는 리뷰의 페이지만 업데이트)
        if (orderLists.data_count < 1) {
            return
        }
        dispatch(updateOrderLists(updatePage, false, checkedTab));
    }, [isUpdate])
    useEffect(() => {
        setLoadCount(loadCount+1);

        if (orderListsData.length < 1) {
            return
        }
        if (!orderListsData[0]) {
            return
        }
    }, [orderListsData, orderLists])

    const closePop = () => {
        dispatch(fetchOrderLists(orderPage, false));
    }

    useEffect(() => {
        if (orderListsData.length < 1) {
            return
        }
    }, [orderListNormal, orderListsReg, checkedTab, orderLists])

    const orderStatus = (status, delay) => {
        switch (status) {
            case "입금전" :
                return "입금전";
            case "환불관리" :
                return "취소";
            case "교환" :
                return "교환신청";
            case "재배송" :
                return "재배송";
            case "배송지연" :
                return <p className='delay_state'>{"배송지연"} {delay ? <span>({delay})</span> : null}</p>
            case "취소완료" :
                return "취소";
            default:
                return status
        }
    }

    const alertReview = (orderId, item, product, idx, del_review) => {
        if (del_review) {
            alert("구매평은 1회 작성 가능하며, 삭제시 재작성이 불가합니다.")
            return
        }
    }


    //주문된 제품 상태값에 따라 나오는 버튼
    const orderBtn = (status, orderId, item, idx, product, checkReview) => {
        const ship_completed = Moment(item.treat_date, "YYYY-MM-DD").format('LLL');
        const ship_completed_after = Moment(item.treat_date, "YYYY-MM-DD").add(10, "days").format('LLL');
        const now = Moment().format('LLL')
        const isOkToChange = Moment(now).isBetween(ship_completed, ship_completed_after)
        const returnStatus = item.OrderProducts.map((e) => e.return_status)
        const trackingUrl = "https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=" + (item.re_waybill_number ? item.re_waybill_number:item.waybill_number)
        let orderStatus = item.order_status
       
        orderStatus = returnStatus[idx] ? returnStatus[idx] : orderStatus

        switch (orderStatus) {
            // case "입금전" :
            //     return <button className="control_btn order_delete" onClick={() => setOrderCancel({
            //         ...OrderCancel,
            //         visible: true,
            //         id     : orderId,
            //         info   : item,
            //         idx    : idx
            //     })}>취소</button>;
            case "결제완료" :
                return <button className="control_btn order_delete" onClick={() => setOrderCancel({
                    ...OrderCancel,
                    visible: true,
                    id     : orderId,
                    info   : item,
                    idx    : idx
                })}>취소</button>;
            case "배송준비중" :
                return <></>
            case "배송지연" :
                return <button className="control_btn state_view">
                    <a onClick={() => {alert("송장번호가 입력되지 않았습니다.")}}>배송조회</a></button>
            case "배송중" :
                return <button className="control_btn state_view">
                    <a href={trackingUrl} target="_blank">배송조회</a>
                </button>
            case "배송완료" :
                return <>
                    <button className="control_btn state_view">
                        <a href={trackingUrl} target="_blank">배송조회</a>
                    </button>
                    {
                        checkReview.length !== 0 ?
                            <button onClick={() => openModReview(orderId, item, product, idx)} className="control_btn btn_b">구매평보기</button> :
                            <button onClick={() => openNewReview(orderId, item, product, idx)} className="control_btn btn_b">구매평작성</button>
                    }

                    {isOkToChange ? <button onClick={() => openReqRefund(orderId, item, product, idx)} className="control_btn order_request">{item?.RegularOrderId === undefined ? "교환/반품" : "교환"}</button>
                        : <button className="control_btn order_request" onClick={() => {alert("교환 및 환불 가능한 기간이 지났습니다.\n" + "교환, 환불은 배송완료 후 10일 이내에 가능합니다.")}}>{item?.RegularOrderId === undefined ? "교환/반품" : "교환"}</button>
                    }
                </>;
            case "취소" :
                return <></>
            default :
                return <></>
        }
    }
    const orderBtnReg = (status, orderId, item, checkReview, CheckReturn) => {
        const ship_completed = Moment(item.treat_date, "YYYY-MM-DD").format('LLL');
        const ship_completed_after = Moment(item.treat_date, "YYYY-MM-DD").add(10, "days").format('LLL');
        const now = Moment().format('LLL')
        const isOkToChange = Moment(now).isBetween(ship_completed, ship_completed_after)
        const checkingReturn = item.return_way !== null
        const trackingUrl = "https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=" + (item.re_waybill_number ? item.re_waybill_number:item.waybill_number)

        switch (status) {
            case "결제완료" :
                return <></>
            case "배송준비중" :
                return <></>
            case "배송중" :
                return <button className="control_btn state_view">
                    <a href={trackingUrl} target="_blank">배송조회</a>
                </button>
            case "배송지연" :
                return <button className="control_btn state_view">
                    <a onClick={() => {alert("송장번호가 입력되지 않았습니다.")}}>배송조회</a></button>
            case "배송완료" :
                return <React.Fragment>
                    <button className="control_btn state_view">
                        <a href={trackingUrl} target="_blank">배송조회</a>
                    </button>
                    {
                        checkReview.length !== 0 ?
                            <button onClick={() => openModReview(orderId, item,)} className="control_btn btn_b">구매평보기</button> :
                            <button onClick={() => openNewReview(orderId, item)} className="control_btn btn_b">구매평작성</button>
                    }
                    {isOkToChange ? checkingReturn ?
                            <button onClick={() => {alert("교환 상품이 있어, 교환 및 반품 진행이 어렵습니다. 바램펫 고객센터를 통해 접수해주세요.")}} className="control_btn">교환</button> :
                            <button onClick={() => {
                                setReqExchange({
                                    open: true,
                                    item: item
                                });
                            }} className="control_btn">교환</button>
                        :
                        <button onClick={() => {alert("교환 및 환불 가능한 기간이 지났습니다.\n" + "교환, 환불은 배송완료 후 10일 이내에 가능합니다.")}} className="control_btn">교환</button>
                    }
                </React.Fragment>;
            default :
                return <></>
        }
    }

    const [OrderCancel, setOrderCancel] = useState({
        visible: false,
        id     : "",
        info   : "",
        idx    : "",
    });
    const closeOrderCancel = () => {
        setOrderCancel({...OrderCancel, visible: false});
    }

    const [WriteReview, setWriteReview] = useState(false);
    const [getReview, setGetReview] = useState(false);
    const [data, setData] = useState({});


    //구매평 작성
    const openNewReview = (orderId, item, product, idx) => {
        if (item.RegularProduct) {
            if (!item.Reviews) {
                return
            }
            if (item.Reviews.length > 0) {
                if (item.Reviews[0].is_hide || item.Reviews[0].deleted) {
                    alert("관리자에 의해 삭제된 구매평입니다.")
                    return
                }
                if (item.Reviews[0].user_delete) {
                    alert("구매평은 1회 작성 가능하며, 삭제시 재작성이 불가합니다.")
                    return
                }
            }
        } else {
            const filter = item.Reviews.filter(v => v.product_id === item.OrderProducts[idx].ProductId);
            if (filter.length > 0) {
                if (filter[0].is_hide || filter[0].user_delete) {
                    alert("관리자에 의해 삭제된 구매평입니다.")
                    return
                }
                if (filter[0].user_delete) {
                    alert("구매평은 1회 작성 가능하며, 삭제시 재작성이 불가합니다.")
                    return
                }
            }
        }
        setUpdatePage(item.page);
        setWriteReview(true);
        setData({...data, orderId, item, product, idx})
    }

    //구매평 보기
    const openModReview = (orderId, item, product, idx) => {
        if (item.RegularProduct) {
            var review = item.Reviews.find(review => {
                return review.RegularProductId
            })
            if (!item.Reviews) {
                return
            }
            if (review) {
                if (item.Reviews[0].is_hide || item.Reviews[0].deleted) {
                    alert("관리자에 의해 삭제된 구매평입니다.")
                    return
                }
                if (item.Reviews[0].user_delete) {
                    alert("구매평은 1회 작성 가능하며, 삭제시 재작성이 불가합니다.")
                    return
                }
            }
        } else {
            var review = item.Reviews.find(review => {
                return review.ProductId == product.id
            })
            if (review) {
                if (review.is_hide || review.deleted) {
                    alert("관리자에 의해 삭제된 구매평입니다.")
                    return
                }
                if (review.user_delete) {
                    alert("구매평은 1회 작성 가능하며, 삭제시 재작성이 불가합니다.")
                    return
                }
            }
        }
        setUpdatePage(item.page);
        setGetReview(true);
        setData({...data, orderId, item, product, idx})
    }
    const closeWriteReview = (page) => {
        setWriteReview(false);
        dispatch(fetchOrderLists(orderPage, false, checkedTab));
    }
    const closeMyReview = (page) => {
        setGetReview(false);
        dispatch(fetchOrderLists(orderPage, false, checkedTab));
    }

    const [reqRefund, setReqRefund] = useState(false);
    const [delivery, setDelivery] = useState(false);
    const openReqRefund = (orderId, item, product, idx) => {
        setReqRefund(true)
        setData({...data, orderId, item, product, idx})
    }

    const closeDelivery = () => {
        setDelivery(false);
    }
    const moreView = () => {  //더보기 버튼
        setLimit(prevState => prevState + 10);
        setOrderPage(prevState => prevState + 1);
    }

    function date_descending(a, b) {
        a = new Moment(a.createdAt);
        b = new Moment(b.createdAt);

        return a > b ? -1 : a < b ? 1 : 0;
    };
    const [reqExchange, setReqExchange] = useState({open: false, item: "",});
    const MemoizedOrderList = React.memo(({orderlist}) => {
        if (loadCount <= 1 && orderlist.orderLists.length === 0) {
            return <div className="noList"><p>주문내역을 확인중입니다.</p></div>
        } else if (loadCount > 1 && orderlist.orderLists.length === 0) {
            return <div className="noList"><p>주문내역이 없습니다.</p></div>
        }

        const ListsAll = orderlist.orderLists;
        return ListsAll.map((allList, idx) => {
            allList.createdAt = allList.created_at;
            const orderDate = allList.createdAt.slice(0, 10).replaceAll("-", ".")
            const is_product = allList.RegularProductId === undefined
            const is_deletedReview = allList.Reviews.filter((e, index) => index === idx && e.user_delete === true).length !== 0;
            const checkReview = is_product ? allList.Reviews.filter(v => v.product_id) : allList.Reviews.filter(v => v.RegularProductId)
            let old_option_idx = -1
            if (!is_product) { // 정기배송
                return (
                    <div className="order_lists_list" key={idx}>
                        <p className="order_lists_date">{orderDate}</p>
                        <div className="day_order_list">
                            <div key={allList.id}>
                                <div className="order_lists_number">
                                    <h4>주문번호 <span>{allList.merchant_uid}</span></h4>
                                    <Link to={{
                                        pathname: `/mypage/myorders/detail/${allList.id}?isregular=${allList.RegularProductId ? true : false}&order=${allList.merchant_uid}`,
                                        state   : allList
                                    }}><IoIosArrowForward size="23" color="#5f5f5f"/></Link>
                                </div>
                                <ul>
                                    <li className="order_list_content" key={idx}>
                                        <div className="order_list_content_in">
                                            <div className="order_list_content_img">
                                                <Link to={{
                                                    pathname: `/products/details/${is_product ? "normal" : "regular"}/${allList.RegularProductId}`,
                                                    state   : {allList}
                                                }}>
                                                    {
                                                        is_product ?
                                                            <div className="item_img">
                                                                <img src={allList.product_image} alt={allList.product_name}/>
                                                            </div> :
                                                            <div className="item_img">
                                                                <img src={allList.product_image} alt={allList.product_name}/>
                                                            </div>
                                                    }
                                                    <div className="order_list_content_p">
                                                        <div className="order_title">
                                                            {is_product ? <p className="item_name">{allList.name}</p> :
                                                                <p className="item_name">{allList.product_name}</p>}
                                                        </div>
                                                        <div key={allList.id}>
                                                            {isPc &&
                                                                <p className="order_price">
                                                                    <b>{stringPrice(allList.order_price_amount)}</b>원 / <b>{allList.product_amount}</b>개
                                                                </p>}
                                                            {isMobile &&
                                                                <p className="order_price">
                                                                    <b>{stringPrice(allList.order_price_amount)}</b>원 / {allList.product_amount}개
                                                                </p>}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <span className="order_state">{
                                                orderStatus(allList.front_status, allList.delay_reason)
                                            }</span>
                                            <div className="order_control_btn">
                                                {orderBtnReg(allList.front_status, allList.id, allList, checkReview, is_deletedReview)}
                                            </div>
                                        </div>
                                    </li>
                                    {allList.total_ordered_times === 1 && allList.devices !== null ? <li className="order_list_content" key={idx}>
                                        <div className="order_list_content_in">
                                            <div className="order_list_content_img">
                                                <Link to={{
                                                    pathname: `/products/details/normal/${allList.devices.id}`,
                                                    state: {allList}
                                                }}>

                                                    <div className="item_img"><img src={allList.devices.main_img} alt={allList.devices.product_name} /></div>
                                                    <div className="order_list_content_p">
                                                        <div className="order_title"><p className="item_name">{allList.devices.name}</p>
                                                        </div>
                                                        <div key={allList.id}>
                                                            {isPc &&
                                                                <p className="order_price">
                                                                    <b>{allList.devices.regular_order_price !== null ? stringPrice(allList.devices.regular_order_price)
                                                                        : stringPrice(allList.devices.sell_price)}</b>원 / <b>1</b>개
                                                                </p>}
                                                            {isMobile &&
                                                                <p className="order_price">
                                                                    <b>{allList.devices.regular_order_price !== null ? stringPrice(allList.devices.regular_order_price)
                                                                        : stringPrice(allList.devices.sell_price)}</b>원 / <b>1</b>개
                                                                </p>}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <span className="order_state">{
                                                orderStatus(allList.front_status, allList.delay_reason)
                                            }</span>
                                            <div className="order_control_btn" >
                                                {/*{orderBtnReg(allList.device_status, allList.devices.id, allList, checkReview, is_deletedReview)}*/}
                                            </div>
                                        </div>
                                    </li> : null}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            } else { // 일반배송
                return (
                    <div className="order_lists_list" key={idx}>
                        <p className="order_lists_date">{orderDate}</p>
                        <div className="day_order_list">
                            <div key={allList.id}>
                                <div className="order_lists_number">
                                    <h4>주문번호 <span>{allList.merchant_uid}</span></h4>
                                    <Link to={{
                                        pathname: `/mypage/myorders/detail/${allList.id}?isregular=${allList.RegularProductId ? true : false}`,
                                        state   : allList
                                    }}><IoIosArrowForward size="23" color="#5f5f5f"/></Link>
                                </div>
                                <ul>
                                    {allList.OrderProducts.map((orderitem, idx) => {
                                        //item.ProductId
                                        const productinfo = allList.product.filter(v => v.id === orderitem.ProductId);
                                        if (productinfo.length < 1) {
                                            return null
                                        }
                                        //orderitem.option_id
                                        let op = [];
                                        let current_option_idx = allList.option.findIndex(v => {
                                            const idx = v.findIndex(vj => {
                                                if (vj.id === orderitem.option_id) {
                                                    return true;
                                                } else {
                                                    const optionId = vj.OptionId ? vj.OptionId : vj.id;
                                                    return optionId === orderitem.option_id;
                                                }
                                            });
                                            if (idx === -1) {
                                                return false
                                            }
                                            if (v[idx].require) {
                                                return true;
                                            } else {
                                                return false
                                            }
                                        })
                                        if (old_option_idx !== current_option_idx) {
                                            old_option_idx = current_option_idx
                                        } else {
                                            return null
                                        }
                                        const optionGroup = allList.option.filter(v => {
                                            const idx = v.findIndex(vj => {
                                                if (vj.id === orderitem.option_id) {
                                                    return true;
                                                } else {
                                                    const optionId = vj.OptionId ? vj.OptionId : vj.id;
                                                    return optionId === orderitem.option_id;
                                                }
                                            });
                                            if (idx === -1) {
                                                return false
                                            }
                                            if (v[idx].require) {
                                                return true;
                                            } else {
                                                return false
                                            }
                                        })
                                        if (optionGroup.length <= 0) {
                                            return null
                                        }


                                        op = optionGroup[0];

                                        const item = productinfo[0];
                                        const chosenIdx = idx

                                        const checkReview = allList.Reviews.filter(v => v.cart_option_id === orderitem.option_id)
                                        const ck_filter = allList.OrderProducts.filter((el, index) => idx === index && el.status_sort !== null).length !== 0;

                                        const checkReturnStatus = (id) => {
                                            for(let i=0; i<allList.OrderProducts.length; i++) {
                                                if(allList.OrderProducts[i].ProductId === id) {
                                                    return allList.OrderProducts[i].return_status;
                                                }
                                            }
                                            return null;
                                        }
                                        const productidx = allList.product.findIndex(v => v.id === orderitem.ProductId);
                                        const optionGroups = allList.product[productidx].OptionGroups

                                        let requireQuantity = 0;
                                        let returnStatus = checkReturnStatus(item.id);
                                        let totalPrice = op.reduce((acc, cur) => {
                                            if (cur.require) {
                                                requireQuantity += cur.quantity;
                                                return acc + ((allList.product[productidx].sell_price + cur.price) * cur.quantity)
                                            } else {
                                                return acc + (cur.price * cur.quantity);
                                            }
                                        }, 0)

                                        // 1. OrderProduct에서 재송장번호 등록건이 있는지 체크
                                        let reWaybillNumber = null
                                        let treatDate = null
                                        for(let i=0; i<allList.OrderProducts.length; i++) {
                                            let wNumber = allList.OrderProducts[i].re_waybill_number
                                            let tDate = allList.OrderProducts[i].treat_date
 
                                            if(wNumber !== null) {
                                                if (reWaybillNumber) {
                                                    if (treatDate<tDate) {
                                                        reWaybillNumber = wNumber
                                                        treatDate = tDate
                                                    }
                                                } else {
                                                    reWaybillNumber = wNumber
                                                    treatDate = tDate
                                                }
                                            }
                                        }
                                        // 2. 재송장번호 등록건이 있으면 저장 (재송장번호 트래킹 안되는 이슈 대응)
                                        if (reWaybillNumber) {
                                            allList.re_waybill_number = reWaybillNumber;
                                        }

                                        return (
                                            <li className="order_list_content" key={idx}>
                                                <div className="order_list_content_in">
                                                    <div className="order_list_content_img">
                                                        <Link to={{
                                                            pathname: `/products/details/normal/${item.id}`,
                                                            state   : {item}
                                                        }}>
                                                            <div className="item_img">
                                                                <img src={item.main_img} alt={item.name}/></div>
                                                            <div className="order_list_content_p">
                                                                <div className="order_title">
                                                                    <p className="item_name">{item.name}</p>
                                                                </div>
                                                                <React.Fragment>
                                                                    {
                                                                        op.map((optlist, idx) => {
                                                                            const optdata = optionGroups.filter(v => {
                                                                                return v.Options.findIndex(vj => vj.id === optlist.OptionId || vj.id === optlist.id) !== -1
                                                                            })
                                                                            if (optdata.length <= 0) {
                                                                                return
                                                                            }
                                                                            const isrequire = optlist.require
                                                                            return (
                                                                                <React.Fragment key={idx}>
                                                                                    {isrequire && <>
                                                                                        <p key={idx} className="order_option">[{optdata[0].name}]{optlist.name} ({optlist.quantity}개)</p>
                                                                                    </>
                                                                                    }
                                                                                    {!isrequire &&
                                                                                        <>
                                                                                            <p key={idx} className="order_option_addition">[{optdata[0].name}]{optlist.name} ({optlist.quantity}개)</p>
                                                                                        </>
                                                                                    }
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                    }
                                                                    <p className="order_price">
                                                                        <b>{stringPrice(totalPrice)}</b>원 / {requireQuantity}개
                                                                    </p>
                                                                </React.Fragment>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <span className="order_state">{orderStatus(returnStatus?returnStatus:allList.order_status, allList.delay_reason)}</span>
                                                    <div className="order_control_btn">
                                                        {orderBtn(allList.front_status, allList.id, allList, chosenIdx, item, checkReview, ck_filter)}
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }

        })
    })
    const MemoizedMoreView = React.memo(({count, total, limit}) => {
        if (limit === total) {
            return <></>
        }
        if (count === limit) {
            return (
                <div className="list_more_btn">
                    <button className="moreView" onClick={() => {moreView()}}>더보기 +</button>
                </div>
            )
        } else {
            return <></>
        }
    })




    return (
        <>
            <OrderLists>
                <div className="order_header mypage_title">
                    <div className="order_title ">
                        <h2>주문 내역 <span>지난 1년간의 주문 내역 조회가 가능합니다.</span></h2>
                    </div>
                    {/*상단 탭 영역*/}
                    <div className="delivery_type">
                        <ul>
                            <li className={checkedTab.tab1 === "전체" ? "active" : null}>
                                <button onClick={handleTabs("tab1")} value="전체">전체</button>
                            </li>
                            {/* 독립몰 serect 정기배송 관련 hidden 처리 */}
                            {/* <li className={checkedTab.tab1 === "일반배송" ? "active" : null}>
                                <button onClick={handleTabs("tab1")} value="일반배송">일반배송</button>
                            </li>
                            <li className={checkedTab.tab1 === "정기배송" ? "active" : null}>
                                <button onClick={handleTabs("tab1")} value="정기배송">정기배송</button>
                            </li> */}
                        </ul>
                    </div>
                    {/*상단 탭 영역*/}
                </div>
                {isMobile && <p className="noArea"></p>}

                {/*하단 탭 영역*/}
                <div className="order_type">
                    <ul className="noscroll">
                        <li className={checkedTab.tab2 === "전체" ? "active" : null}>
                            <button onClick={handleTabs("tab2")} value="전체">전체</button>
                        </li>
                        <li className={checkedTab.tab2 === "결제완료" ? "active" : null}>
                            <button onClick={handleTabs("tab2")} value="결제완료">결제완료</button>
                        </li>
                        <li className={checkedTab.tab2 === "배송중" ? "active" : null}>
                            <button onClick={handleTabs("tab2")} value="배송중">배송중</button>
                        </li>
                        <li className={checkedTab.tab2 === "배송지연" ? "active" : null}>
                            <button onClick={handleTabs("tab2")} value="배송지연">배송지연</button>
                        </li>
                        <li className={checkedTab.tab2 === "배송완료" ? "active" : null}>
                            <button onClick={handleTabs("tab2")} value="배송완료">배송완료</button>
                        </li>
                        <li className={checkedTab.tab2 === "교환/반품" ? "active" : null}>
                            <button onClick={handleTabs("tab2")} value="교환/반품">교환/반품</button>
                        </li>
                        <li className={checkedTab.tab2 === "환불완료" ? "active" : null}>
                            <button onClick={handleTabs("tab2")} value="환불완료">환불완료</button>
                        </li>
                    </ul>
                </div>
                {/*하단 탭 영역*/}

                {/* 주문내역 리스트 */}
                <div className="order_lists">
                    {/*/!*전체 리스트 *!/*/}
                    <MemoizedOrderList orderlist={orderLists} checkedTab={checkedTab}/>
                </div>
                {/* 주문내역 리스트 */}

                {/* 더보기 버튼*/}
                <MemoizedMoreView count={orderLists.data_count} total={orderLists.total_count} limit={limit}/>

                {/*/팝업창 영역/*/}
                {OrderCancel.visible &&
                    <OrderCancelPopup
                        setOrderCancel={setOrderCancel}
                        OrderCancel={OrderCancel}
                        visible={OrderCancel.visible}
                        onCancel={() => {
                            closeOrderCancel();
                        }}
                        id={OrderCancel.id}
                        info={OrderCancel.info}
                        idx={OrderCancel.idx}
                    />}
                {WriteReview &&
                    <WriteReviewPopup visible={WriteReview} product={data} onCancel={closeWriteReview} setWriteReview={setWriteReview} setReviewSubmit={setReviewSubmit} reviewSubmit={reviewSubmit}/>}
                {getReview && <MyPageReviewPopup visible={getReview} product={data} onCancel={() => {
                    closeMyReview();
                }}/>}
                {reqRefund &&
                    <ReqRefundPopup visible={reqRefund} setReqRefund={setReqRefund} product={data} onCancel={closePop}/>}
                {reqExchange.open &&
                    <ReqExchangePopup visible={reqExchange.open} setReqExchange={setReqExchange} item={reqExchange.item} onClose={closePop}/>}
                {/*/팝업창 영역/*/}
                <div className="delivery_dialog">
                    <Dialog
                        cancelText="취소"
                        onCancel={closeDelivery}
                        dialogSize="large"
                        visible={delivery}
                    >
                        <img src={delivery_state} alt="배송현황"/>
                    </Dialog>
                </div>
            </OrderLists>
        </>
    );
};

const OrderLists = Styled.div`
    max-width: 1230px;
    width: 100%;
    margin-top: 50px !important;
    @media only screen and (max-width: 960px) {
        margin-top: 0 !important;
    }

    & .order_header{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 16px;
        margin-bottom: 0;
        & .order_title{
            h2{
                font-size: 24px;
                font-weight: 400;
                span {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 400;
                    color: #515355;
                    margin-left: 20px;
                }
            }

        }
        & .delivery_type{
            margin-left: auto;
            margin-bottom: 3px;
            & ul {
                display: flex;
                & li {
                    padding: 0 16px;
                    &:not(:last-child) {
                        position: relative;
                        &::after {
                            position: absolute;
                            content: "";
                            right: 0;
                            top: 2.5px;
                            width: 1px;
                            height: 16px;
                            background: #ddd;
                        }
                    }
                    & button {
                        font-size: 14px;
                        line-height: 21px;
                        color: #515355;
                    }
                    &.active {
                        & button {
                            color: #1EA0FA;
                            font-weight: 500;
                        }
                    }
                    &:hover button {
                        color: #1EA0FA;
                    }
                }
            }
        }

        @media only screen and (max-width: 960px) {
            display: block;
            padding-bottom: 0;
            border-bottom: none;
            & .order_title{
                margin-bottom: 40px;
                h2{
                    font-size: 1.375rem;
                    line-height: 33px;
                    color: #222;
                    text-align: center;
                    span {
                        display: none;
                    }
                }

            }
            & .delivery_type{
                margin: 0;
                & ul {
                    display: flex;
                    & li {
                        width: calc(100% / 3);
                        padding: 0;
                        border-bottom: 2px solid #eef1f3;
                        &:not(:last-child) {
                            &::after {
                                content: none;
                            }
                        }
                        & button {
                            height: 60px;
                            width: 100%;
                        }
                        &.active {
                            border-bottom: 2px solid #1EA0FA;
                        }
                        &:hover button {
                            color: inherit;
                        }
                    }
                }
            }
        }

    }

    & .order_type {
        & ul {
            display: flex;
            border: 1px solid #ddd;
            & li {
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;
                width: calc(100% / 7);
                &:not(:last-child)::after {
                    position: absolute;
                    content: "";
                    right: 0;
                    top: 17px;
                    width: 1px;
                    height: 22px;
                    background: #E1E2E3;
                }
                & button {
                    width: 100%;
                    height: 54px;
                    font-size: 15px;
                    color: #000;
                    white-space: nowrap;
                }
                &:hover button{
                    color: #1EA0FA;
                }
                &.active {
                    border-bottom: 2px solid #1EA0FA;
                    & button {
                        font-weight: 500;
                        color: #1EA0FA;
                    }
                }
            }
        }
        @media only screen and (max-width: 960px) {
            padding: 30px 0 30px 15px;
            border-bottom: 1px solid #eee;
            & ul {
                display: flex;
                overflow-x: auto;
                border: none;
                & li {
                    display: inline-block;
                    width: auto;
                    align-items: center;
                    justify-content: center;
                    &:not(:first-child) {
                        margin-left: 8px;
                    }
                    &:not(:last-child)::after {
                        content: none;
                    }
                    &:last-child {
                        padding-right: 15px;
                    }
                    & button {
                        display: inline-block;
                        width: auto;
                        height: auto;
                        padding: 6px 20px;
                        line-height: 19px;
                        font-size: 14px;
                        color: #515355;
                        border-radius: 33px;
                        border: 1px solid #ddd;
                    }
                    &:hover button{
                        color: inherit;
                    }
                    &.active {
                        border-bottom: none;
                        & button {
                            border: 1px solid #1EA0FA;
                            font-weight: 500;
                            color: #1EA0FA;
                        }
                    }
                }
            }
        }
    }

    & .order_lists{
        & .order_lists_list  {
            margin: 30px 0;
            box-sizing: border-box;
            & .day_order_list {
                margin-bottom: 30px;
                & > div {
                    border: 1px solid #ccc;
                    &:not(:first-child) {
                        margin-top: 30px;
                    }
                }
                & li.order_list_content{
                    padding: 0 25px;
                    &:not(:last-child) {
                        & .order_list_content_in {
                            border-bottom: 1px solid #E1E2E3;
                        }
                    }

                    & .order_list_content_in {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 25px 0;

                        & .order_list_content_img{
                            flex-basis: 50%;
                            display: flex;
                            align-items: center;

                            a {
                                display: flex;
                                align-items: flex-start;
                                width: 100%;
                            }

                            & .item_img {
                                position: relative;
                                overflow: hidden;
                                flex: 80px 0 0;
                                margin-right: 30px;
                                word-break: break-all;
                                &::after {
                                  content: "";
                                  display: block;
                                  padding-bottom: 100%;
                                }
                                img{
                                  position: absolute;
                                  top: 50%;
                                  left: 50%;
                                  transform: translate(-50%, -50%);
                                  height: 100%;
                                  width: auto;
                                  height: auto;
                                  width: 100%;
                                }
                            }

                            & .order_list_content_p{
                                word-break: break-all;
                                font-size: 14px;
                                color: #515355;
                                white-space: nowrap;
                                p:not(:first-child) {
                                    margin-top: 4px;
                                }
                                p.event_product{
                                    font-size: 13px;
                                    color: #1EA0FA;
                                    line-height: 19px;
                                    margin-bottom: 4px;
                                }

                                .order_title .item_name {
                                    font-size: 16px;
                                    color: #222;
                                    line-height: 24px;
                                }

                                p.order_option {
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: #515355;
                                }

                                p.order_option_addition {
                                    color: #888888;
                                    line-height:1.2;
                                    font-size: 13px;
                                }

                                p.order_price {
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: #515355;
                                    & b {
                                        font-weight: 500;
                                    }
                                }
                                .add_option {
                                    margin-top: 10px;
                                    color: #888;
                                    & p {
                                        font-size: 13px;
                                        line-height: 19.5px;
                                        &:not(:first-child) {
                                            margin-top: 2px;
                                        }
                                    }
                                }
                            }
                        }
                        & .order_state {
                            color: #222;
                            & p {
                              line-height: 24px;
                            }
                            & .delay_state {
                              text-align: center;
                              & span {
                                display: block;
                                font-size: 0.875rem;
                                color: #888;
                                line-height: 21px;
                              }
                            }
                        }
                        & .order_control_btn{
                            flex-basis: 100px;
                            & .control_btn{
                                width: 100px;
                                height: 30px;
                                font-size: 13px;
                                color: #222;
                                margin: 3px 0;
                                border: 1px solid #ccc;
                                box-sizing: border-box;
                                border-radius: 40px;
                                &.btn_b {
                                    border: 1px solid #1EA0FA;
                                    color: #1EA0FA;
                                }
                            }
                        }
                    }
                }
            }

            p.order_lists_date{
                font-size: 18px;
                color: #515355;
                line-height: 27px;
                margin-bottom: 16px;
            }
            & .order_lists_number {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #F7F7F7;
                padding: 15px 25px 15px 30px;
                box-sizing: border-box;
                border-bottom: 1px solid #ccc;

                & h4 {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #515355;
                    & span {
                        font-weight: 500;
                        margin-left: 5px;
                        color: #222;
                    }
                }
            }
        }

        @media only screen and (max-width: 960px) {
            background: #f6f6f6;
            padding-bottom: 8px;

            & .order_lists_date {
                display: none;
            }

            & .order_lists_list  {
                margin: 0;
                &:first-child {
                    & .day_order_list {
                        & > div:first-child {
                            & .order_lists_number {
                                border-top: none;
                            }
                        }
                    }
                }
                &:not(:first-child) {
                    margin-top: 8px;
                }
                background: #fff;
                & .day_order_list {
                    margin-bottom: 0;
                    & > div {
                        border: none;
                        &:not(:first-child) {
                        padding-top: 8px;
                        margin-top: 0;
                        background: #F6F6F6;
                    }
                }

                & > div {
                    border: none;
                    margin-bottom: 0;
                    & .order_lists_number {
                        padding: 18px 12px 18px 15px;
                        border-top: 1px solid #eee;
                        border-bottom: 1px solid #eee;
                        background: #F7F7F7;
                        & h4 {
                            display: flex;
                            align-items: center;
                            font-size: 0.938rem;
                            font-weight: 400;
                            line-height: 24px;
                            color: #515355;
                            font-weight: 500;
                            & b {
                                color: #222;
                                font-size: 1rem;
                            }
                            & span {
                                display: inline-block;
                                margin-left: 8px;
                                font-size: 1rem;
                            }
                        }
                        & svg {
                            width: 20px;
                            width: 20px;
                            color: #888 !important;
                        }
                    }
                    & ul {
                        background: #fff;
                        & li.order_list_content{
                            padding: 0;

                            &:nth-child(2) {
                                display: block;
                            }
                            &:not(:last-child) {
                                & .order_list_content_in {
                                    border-bottom: none;
                                }
                            }

                            & .order_list_content_in {
                                display: block;
                                padding: 0;
                                & h5 {
                                    display: inline-block;
                                    width: 90px;
                                    color: #515355;
                                    font-weight: 400;
                                    line-height: 22px;
                                }

                                & .order_list_content_img{
                                    flex-basis: 100%;
                                    display: flex;
                                    justify-content: flex-start;
                                    padding: 15px 15px 0 15px;

                                    & .item_img {
                                      margin-right: 0;
                                    }

                                    & .order_list_content_p{
                                        padding: 0 0 0 10px;
                                        white-space: normal;
                                        font-size: 14px;
                                        color: #515355;
                                        & .delivery_count {
                                            padding: 2.5px 10px;
                                            border-radius: 30px;
                                            font-size: 12px;
                                            line-height: 18px;
                                        }
                                        p.item_name{
                                            font-size: 16px;
                                            line-height: 22px;
                                            font-weight: 400;
                                        }
                                        p.order_price {
                                            font-size: 13px;
                                            line-height: 19.5px;
                                            color: #222;
                                            & b {
                                                font-weight: 700;
                                            }
                                        }
                                        p.delivery_info {
                                            margin-top: 4px;
                                            font-size: 13px;
                                            line-height: 19.5px;
                                        }
                                    }
                                }
                                & .order_state {
                                    display: block;
                                    margin-top: 4px;
                                    padding: 0 0 20px 105px;
                                    font-size: 14px;
                                    line-height: 21px;
                                    & p {
                                      line-height: 24px;
                                    }
                                    & .delay_state {
                                      text-align: left;
                                      & span {
                                        display: inline-block;
                                        font-size: 0.813rem;
                                        color: #888;
                                        line-height: 21px;
                                      }
                                    }
                                }
                                & .order_control_btn{
                                    display: flex;
                                    width: 100%;
                                    flex-basis: 100%;
                                    flex-wrap: wrap;
                                    border-bottom: 1px solid #e5e5e5;
                                    & .control_btn{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 50%;
                                        height: 50px;
                                        font-size: 14px;
                                        color: #515355;
                                        margin: 0;
                                        border: none;
                                        border-top: 1px solid #e5e5e5;
                                        border-right: 1px solid #e5e5e5;
                                        box-sizing: border-box;
                                        border-radius: 0;
                                        &.order_delete, &.state_view {
                                            width: 100%;
                                            border-right: none;
                                        }
                                        &.btn_b {
                                            border: none;
                                            border-top: 1px solid #e5e5e5;
                                            border-right: 1px solid #e5e5e5;
                                            color: #515355;
                                        }
                                         &:last-child {
                                            border-right: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & .delivery_dialog {
        & img {
            width: 100%;
        }

        & .dialog_wrap {
            padding: 0;
        }

        & .ButtonWrap {
            display: none;
        }
    }

`

export default OrderDetails;