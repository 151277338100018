import {REMOVE_TAG, SET_CATEGORY, SET_CATEGORY_FILTER, SET_TAG, GET_MAIN_CATEGORY_ID} from "./types";

export const getCategoryId = (id, type, order) => {
    localStorage.setItem("mainCateId", id);
    localStorage.setItem("mainCateType", type);
    localStorage.setItem("mainCateOrder", order);
    return{
        type: GET_MAIN_CATEGORY_ID,
        payload: {
            id
        }
    }
}

export const setCategory = (categoryProducts) => {
    return {
        type: SET_CATEGORY,
        payload: {
            categoryProducts,
        },
    };
};

export const setCategoryFiliter = (categoryFilters) => {
    return {
        type: SET_CATEGORY_FILTER,
        payload: {
            categoryFilters,
        },
    };
};

export const setFiliteredItems = (filteredItems) => {
    return {
        type: SET_TAG,
        payload: filteredItems,
    };
};

export const setRemoveFilteredItems = (filteredItems) => {
    return {
        type: REMOVE_TAG,
        payload: filteredItems,
    };
};
