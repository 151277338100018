import React, {useCallback, useEffect, useState} from 'react';
import {useMediaQuery} from "react-responsive";
import Styled from "styled-components";
import arr_my_nav from "images/m/arr_my_nav.png";
import list_open from "images/m/m_list_open.png";
import list_close from "images/m/m_list_close.png";
import {RiErrorWarningFill} from "react-icons/ri";
import DotGuide from "pages/components/DotGuide";
import {stringPrice} from "utils/StringPrice";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import QueryString from "qs";
import {fetchOrderRegularDetailsdetail} from "../../../actions/orderActions";
import Loader from "../../components/Loader";
import OrderCancelPopup from "../../components/OrderCancelPopup";
import ReqExchangePopup from "../../components/ReqExchangePopup";
import {RESET_ORDER_DETAILS} from "../../../actions/types";

const DeliveryProductDetail = () => {
    const dispatch = useDispatch();
    const itemitemInfo = useSelector((state) => state.orderDetails.orderDetails || []);
    const queryData = QueryString.parse(window.location.search, {ignoreQueryPrefix: true});
    const isPc = useMediaQuery({ query: '(min-width: 961px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const [devicePrice, setDevicePrice] = useState(0)
    const [deviceAmount, setDeviceAmount] = useState(1)
    const [replacePhone, setReplacePhone] = useState("");

    useEffect(() => {
        if (!itemitemInfo) { return }
        if (!itemitemInfo.RegularOrders) { return }
        setReplacePhone(itemitemInfo.RegularOrders[0].buyer_tel.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    }, [itemitemInfo])

    useEffect(()=>{
        dispatch(fetchOrderRegularDetailsdetail(queryData.id))
        if(!device){return}
        if(has_device){
            setDevicePrice(device.regular_order_price ? device.regular_order_price : device.sell_price)
        }
    },[dispatch])

    useEffect(() => {
        return () => {
            dispatch({type: RESET_ORDER_DETAILS})
        }

    }, [])

    const device = itemitemInfo?.device?.devices
    const has_device = itemitemInfo.total_discount_amount === 0 && device
    const orderBtn = (status, orderId, item, idx) => {
        switch(status){
            case "결제완료" : return <></>;
            case "배송지연" : return <></>
            case "배송완료" : return <><p></p></>;
            case "배송중" : return <></>;
            case "교환완료" : return <></>;
            case "반품완료" : return <></>;
            case "교환신청" : return <><p></p></>;
            case "반품신청" : return <><p></p></>;
            default : return <></>
        }
    }

    const [ orderList, setOrderList ] = useState(true);
    const [ deliveryInfo, setDeliveryInfo ] = useState(true);
    const [ deliveryState, setDeliveryState ] = useState(true);
    const [ payMethod, setPayMethod ] = useState(true);
    const [ payInfo, setPayInfo ] = useState(true);
    const phoneNumber = useCallback((ph) => {
        const regex = /^([0-9]{3})([0-9]{4})([0-9]{4})/gm;
        const subst = `$1-$2-$3`;

        return ph.replace(regex, subst);
    }, [])

    const [ reqExchange, setReqExchange ] = useState({open : false, item: "",});
    const [ OrderCancel, setOrderCancel ] = useState({
        visible: false,
        id: "",
        info: "",
        idx: "",
    });
    const closeOrderCancel = () => {
        setOrderCancel({...OrderCancel, visible: false});
    }

    return (
        <Container>
            {!itemitemInfo.RegularOrderId ?
                    <Loader/> :
                    <React.Fragment>
                        <div className="header mypage_title">
                            <div className="order_title">
                                <h2>나의 정기배송 주문상세 <span>주문번호 : {itemitemInfo.merchant_uid}</span></h2>
                            </div>
                        </div>
                        { isMobile && <p className="noArea"></p>}
                        <div className="delivery_lists">
                            <div className="order_lists delivery_information">
                                { isMobile &&
                                    <div className={orderList ? "header delivery active" : "header delivery"} onClick={() => setOrderList(!orderList)}>
                                        <div className="order_title">
                                            <h2>주문번호<span>{itemitemInfo.merchant_uid}</span></h2>
                                        </div>
                                    </div>
                                }
                                <ul className={orderList ? "active" : ""}>
                                    <li className="order_lists_list">
                                        <ul>
                                            <li className="order_list_content">
                                                <div className="order_list_content_in">
                                                    <Link to={`/products/details/regular/${itemitemInfo.product_id}`}>
                                                        <div className="order_list_content_img">
                                                            <div className="item_img">
                                                                <img src={itemitemInfo.product_image} alt={itemitemInfo.product_name} />
                                                            </div>
                                                            <div className="order_list_content_text">
                                                                <div className="order_list_content_p">
                                                                    <p className="order_title">{itemitemInfo.product_name}</p>
                                                                    <p className="order_price"><b>{stringPrice(itemitemInfo.order_price_amount)}</b>원 / <b>{itemitemInfo.product_amount}</b> 개</p>
                                                                </div>
                                                                <div className="order_state">{itemitemInfo.front_status}</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    </div>
                                            </li>
                                            {itemitemInfo.device_name !== null ?
                                                <li className="order_list_content">
                                                    <div className="order_list_content_in">
                                                        <Link to={`/products/details/normal/${device.id}`}>
                                                            <div className="order_list_content_img">
                                                            <div className="item_img">
                                                                <img src={device.main_img} alt=""/>
                                                            </div>
                                                            <div className="order_list_content_text">
                                                                <div className="order_list_content_p">
                                                                    <p className="order_title">{device.name}</p>
                                                                    <p className="order_price"><b>{stringPrice(device.regular_order_price ? device.regular_order_price : device.price)}</b>원 / <b>1</b> 개</p>
                                                                </div>
                                                                <div className="order_state">{itemitemInfo.front_status}</div>
                                                            </div>
                                                        </div>
                                                        </Link>
                                                    </div>
                                                </li> : null}
                                        </ul>
                                    </li>
                                    <div className="order_all_delete">
                                        {orderBtn(itemitemInfo.front_status, itemitemInfo.id, itemitemInfo)}
                                    </div>
                                </ul>
                            </div>
                        </div>
                        { isMobile && <p className="noArea"></p>}
                        <div className="delivery_information">
                            <div className={deliveryInfo ? "header delivery active" : "header delivery"} onClick={() => setDeliveryInfo(!deliveryInfo)}>
                                <div className="order_title">
                                    <h2>주문자/배송정보</h2>
                                </div>
                            </div>
                            <table className={deliveryInfo ? "delivery_info address_info active" : "delivery_info address_info"}>
                                <tbody>
                                <tr>
                                    <td>이름</td>
                                    <td>{itemitemInfo.buyer_name}</td>
                                </tr>
                                <tr>
                                    <td>주소</td>
                                    <td>{itemitemInfo.buyer_addr ? itemitemInfo.buyer_addr.replace("/", "") : null}</td>
                                </tr>
                                <tr>
                                    <td>핸드폰</td>
                                    <td>{replacePhone ? phoneNumber(replacePhone) : null}</td>
                                </tr>
                                <tr>
                                    <td>메모</td>
                                    <td>{itemitemInfo.shipping_message}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        { isMobile && <p className="noArea"></p>}
                        <div className="delivery_information">
                            {itemitemInfo.front_status === "해지완료" || itemitemInfo.front_status === "교환완료" ? null : <React.Fragment>
                                <div className={deliveryState ? "header delivery active" : "header delivery"} onClick={() => setDeliveryState(!deliveryState)}>
                                    <div className="order_title">
                                        <h2>배송정보</h2>
                                    </div>
                                </div>
                                <table className={deliveryState ? "delivery_info active" : "delivery_info"}>
                                    <tbody>
                                    {itemitemInfo.front_status === "결제완료" ? <tr className="no_delivery">
                                        <td colSpan="2" align="center">배송중 단계부터 조회가 가능합니다.</td>
                                    </tr> :  <React.Fragment><tr>
                                        <td>{itemitemInfo.name}</td>
                                        <td>
                                            <a href={"https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=" + (itemitemInfo.re_waybill_number?itemitemInfo.re_waybill_number:itemitemInfo.waybill_number)} target="_blank">
                                                <button>롯데택배 <img src={arr_my_nav} alt="자세히보기" /></button>
                                            </a>
                                        </td>
                                    </tr>
                                        {itemitemInfo.device_name ?
                                            <React.Fragment>
                                                 <tr>
                                                    <td>{itemitemInfo.device_name}</td>
                                                    <td>
                                                        <a href={"https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=" + (itemitemInfo.re_waybill_number?itemitemInfo.re_waybill_number:itemitemInfo.waybill_number)} target="_blank">
                                                            <button>롯데택배 <img src={arr_my_nav} alt="자세히보기" /></button>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </React.Fragment>:
                                            null
                                        }
                                    </React.Fragment>}


                                    </tbody>
                                </table>
                            </React.Fragment>}

                        </div>
                        { isMobile && <p className="noArea"></p>}
                        <div className="delivery_information">
                            <div className={payMethod ? "header delivery active" : "header delivery"} onClick={() => setPayMethod(!payMethod)}>
                                <div className="order_title">
                                    <h2>결제수단</h2>
                                </div>
                            </div>
                            <table className={payMethod ? "delivery_info active" : "delivery_info"}>
                                <tbody>
                                <tr>
                                    <td>신용카드</td>
                                    <td>{itemitemInfo.card_name}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        { isMobile && <p className="noArea"></p>}
                        <div className="delivery_information">
                            <div className={payInfo ? "header delivery active" : "header delivery"} onClick={() => setPayInfo(!payInfo)}>
                                <div className="order_title">
                                    <h2>결제정보</h2>
                                </div>
                            </div>
                            <div className={payInfo ? "delivery_cached_info active" : "delivery_cached_info"}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>상품금액</td>
                                        <td>
                                            <b>{
                                            itemitemInfo.device_name !== null ?
                                                itemitemInfo.device.devices.regular_order_price !== null ?
                                                stringPrice(itemitemInfo.order_price_amount + itemitemInfo.device.devices.regular_order_price)
                                                : stringPrice(itemitemInfo.order_price_amount + itemitemInfo.device.devices.sell_price)
                                                : stringPrice(itemitemInfo.order_price_amount)
                                            }</b>원
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>상품할인금액</td>
                                        <td><b>{stringPrice(itemitemInfo.total_discount_amount !== 0 ? "-" + (itemitemInfo.total_discount_amount - itemitemInfo.mileage_spent_amount) : 0)}</b>원</td>
                                    </tr>
                                    <tr>
                                        <td>쿠폰할인금액</td>
                                        <td><b>{itemitemInfo.coupon_discount_amount ? "-" : null} {stringPrice(itemitemInfo.coupon_discount_amount || 0)}</b>원</td>
                                    </tr>
                                    <tr >
                                        <td>알갱이사용</td>
                                        <td><b>{itemitemInfo.mileage_spent_amount ? "-" : null} {stringPrice(itemitemInfo.mileage_spent_amount || 0)}</b>(원)</td>
                                    </tr>
                                    {itemitemInfo.front_status === "해지신청" || itemitemInfo.front_status === "해지보류" || itemitemInfo.front_status === "해지완료" || itemitemInfo.front_status === "반납중" ? <tr >
                                        <td>중도해지 반환금</td>
                                        <td><b>{itemitemInfo.mileage_spent_amount !== 0 ? "-" : null} {itemitemInfo.mileage_spent_amount || 0}</b>원</td>
                                    </tr> : null}
                                    </tbody>
                                    <tfoot>

                                    {itemitemInfo.front_status === "해지신청" || itemitemInfo.front_status === "해지보류" || itemitemInfo.front_status === "해지완료" ||
                                    itemitemInfo.front_status === "반납중" ?
                                        <tr className="cached_total">
                                            <td>최종 환불 금액</td>

                                            <td><b>{stringPrice(itemitemInfo.refund_amount || 0)}</b>원</td>
                                        </tr> :
                                        <tr className="cached_total">
                                            <td>최종 결제 금액</td>
                                            <td><b>{stringPrice(
                                                itemitemInfo.device_name !== null ?
                                                    itemitemInfo.device.devices.regular_order_price !== null ?
                                                       itemitemInfo.order_price_amount + itemitemInfo.device.devices.regular_order_price - itemitemInfo.mileage_spent_amount - itemitemInfo.coupon_discount_amount
                                                        : itemitemInfo.order_price_amount + itemitemInfo.device.devices.sell_price - itemitemInfo.mileage_spent_amount - itemitemInfo.coupon_discount_amount
                                                    : itemitemInfo.order_price_amount + itemitemInfo.shipping_amount - itemitemInfo.total_discount_amount - itemitemInfo.mileage_spent_amount - itemitemInfo.coupon_discount_amount)}</b>원</td>
                                        </tr>}
                                    </tfoot>
                                </table>

                                <div className="price_guide">
                                    <p className="tit"><RiErrorWarningFill color="#515355" size="21" />&nbsp;&nbsp;유의사항 안내</p>
                                    <div className="sub_guide">
                                        <DotGuide
                                            size={isPc ? "small" : "tiny"}
                                            color="gray"
                                            text="정기배송의 해지는 3회차 배송완료 10일 후 [마이페이지]-[나의정기배송]-[정기배송 상세] 하단에서 가능합니다."
                                        />
                                        <DotGuide
                                            size={isPc ? "small" : "tiny"}
                                            color="gray"
                                            text="스마트 디바이스는 3회 구독 약정 혜택으로, 만약 3회 이용 횟수 충족 전에 중도 해지할 경우 이미 받아보신 혜택에 대한 반환금이 청구됩니다."
                                        />
                                        <DotGuide
                                            size={isPc ? "small" : "tiny"}
                                            color="gray"
                                            text="4회차 부터 언제든 해지 및 변경이 가능합니다."
                                        />
                                        <DotGuide
                                            size={isPc ? "small" : "tiny"}
                                            color="gray"
                                            text="다음 회차에서 공급사의 사정에 따라 사료 가격이 변경될 수 있습니다."
                                        />
                                        <DotGuide
                                            size={isPc ? "small" : "tiny"}
                                            color="gray"
                                            text="정기배송 결제 3일 전 알림톡을 통해 결제 금액을 안내 드립니다."
                                        />
                                        <DotGuide
                                            size={isPc ? "small" : "tiny"}
                                            color="gray"
                                            text="결제일 및 배송 사료 변경은 다음 결제 예정일 1일 전 14시까지 변경이 가능합니다."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
            }
            {/* 주문취소, 교환 */}
            {OrderCancel.visible &&
                <OrderCancelPopup setOrderCancel={setOrderCancel} OrderCancel = {OrderCancel} visible={OrderCancel.visible} onCancel={closeOrderCancel} id={OrderCancel.id} info={OrderCancel.info} idx={OrderCancel.idx} />}
            {reqExchange.open && <ReqExchangePopup visible={reqExchange.open} setReqExchange={setReqExchange} item={reqExchange.item} />}
        </Container>
    );
};

const Container = Styled.div`
    max-width: 1230px;
    width: 100%;
    
    & .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 14px;
        border-bottom: 1px solid #555;
        & .order_title{
            h2{
                font-size: 24px;
                font-weight: 400;
                span {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 400;
                    color: #515355;
                    margin-left: 20px;
                }
            }
            
        }
        & .order_select{
            cursor: pointer;
            font-size: 14px;
            color: #515355;
            padding: 10px 11px 10px;
            border: 1px solid #ddd;
        }

        @media only screen and (max-width: 960px) {
            &.mypage_title {
                justify-content: center;
                padding-bottom: 40px;
                border-bottom: none;
                & .order_title {
                    h2{
                        font-size: 1.375rem;
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    & .delivery_lists {
        margin-bottom: 100px;
        @media only screen and (max-width: 960px) {
            margin-bottom: 0;
        }
    }
    
    & .order_lists{
        & ul > li.order_lists_list  {
            margin: 30px 0 10px;
            box-sizing: border-box;
            ul {
                border: 1px solid #ccc;
                
                & li.order_list_content{
                    padding: 0 25px;
                    &:not(:first-child) {
                        & .order_list_content_in {
                            border-top: 1px solid #E1E2E3;    
                        }
                    }
                    & .order_list_content_in {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 25px 0;
                        & a {
                        width: 100%;
                        height: 100%;
                      }
                        & .order_list_content_img{
                            display: flex;
                            align-items: center;
                            width: 100%;
                            flex-grow: 1;

                            & .item_img {
                              position: relative;
                              overflow: hidden;
                              flex: 80px 0 0;
                              height: 80px;
                              margin-right: 30px;
                              &::after {
                                content: "";
                                display: block;
                                padding-bottom: 100%;
                              }
                              & img{
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                height: 100%;
                                width: auto;
                                height: auto;
                                width: 100%;
                              }
                            }

                            & .order_list_content_text {
                                display: flex;
                                flex-grow: 1;
                                align-items: center;
                            }
                            & .order_list_content_p{
                                font-size: 14px;
                                color: #515355;
                                p:not(:first-child) {
                                    margin-top: 4px;
                                }
                                p.event_product{
                                    font-size: 13px;
                                    color: #1EA0FA;
                                    line-height: 19px;
                                }
                                
                                p.order_title {
                                    font-size: 16px;
                                    color: #222;
                                    line-height: 24px;
                                }

                                p.order_option {
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: #515355;
                                }

                                p.order_price {
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: #515355;
                                    & b {
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                        & .order_state{
                            margin-right: 75px;
                            margin-left: auto;
                            color: #222;
                        }
                        
                    }
                }
                 & li.order_list_content:last-child > .order_list_content_in {
                    border-bottom: 0;
                 }
            }
        }

        @media only screen and (max-width: 960px) {
            & > ul {
                display: none;
                &.active {
                    display: block;
                }
            }
            & ul > li.order_lists_list  {
                margin: 0;
                padding-bottom: 10px;
                box-sizing: border-box;
                ul {
                    border: none;
                    
                    & li.order_list_content{
                        padding: 0 20px;
                        &:first-child {
                            & .order_list_content_in {
                                padding-top: 12px;
                            }
                        }
                        &:not(:first-child) {
                            & .order_list_content_in {
                                border-top: 1px solid #F0F0F0;    
                            }
                        }
                        & .order_list_content_in {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 20px 0;
                            
                            & .order_list_content_img{
                                display: flex;
                                align-items: flex-start;
                                width: 100%;
                                flex-grow: 1;
                                
                                & .item_img {
                                  flex: 80px 0 0;
                                  height: 80px;
                                  margin-right: 10px;
                                }

                                & .order_list_content_text {
                                    flex-direction: column;
                                    width: calc(100% - 90px);
                                }
                                & .order_list_content_p{
                                    width: 100%;
                                    p {
                                      word-break: break-all;
                                    }
                                    p:not(:first-child) {
                                        margin-top: 4px;
                                    }
                                    p.event_product{
                                        font-size: 13px;
                                        color: #1EA0FA;
                                        line-height: 19px;
                                    }
                                    
                                    p.order_title {
                                        font-size: 1rem;
                                        line-height: 22px;
                                    }

                                    p.order_option {
                                        font-size: 0.813rem;
                                        line-height: 19px;
                                    }

                                    p.order_price {
                                        font-size: 0.813rem;
                                        line-height: 19px;
                                        color: #222;
                                        & b {
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }
                            & .order_state{
                                width: 100%;
                                margin: 4px 0 0 0;
                                font-size: 0.875rem;
                                font-weight: 500;
                                line-height: 21px;
                            }  
                        }
                    }
                    & li.order_list_content:last-child > .order_list_content_in {
                        border-bottom: 0;
                    }
                }
            }
        }
    }

    & .guide_desc {
        font-size: 0.875rem;
        line-height: 21px;
        color: #888;
        @media only screen and (max-width: 960px) {
            padding: 10px 15px 20px;
            font-size: 00.813rem;
            line-height: 18px;
        }
    }

    & .delivery_information{
        margin-top: 80px;
        &.order_lists {
            margin-top: 0;
        }
        & .header.delivery {
            border-bottom: 1px solid #CCCCCC;
            & h2{
                font-size: 1.25rem;
                font-weight: 500;
                line-height: 30px;
                color: #222;
            }
        }   
        & .delivery_info{
            width: 100%;
            & tr{
                & td {
                    padding: 21px 20px 0 20px;
                    color: #222;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    vertical-align: middle;
                    &:last-child {
                        text-align: right;
                    }
                    & img {
                        width: 6px;
                        margin-left: 5px;
                        margin-top: -2px;
                    }
                }

                &.no_delivery {
                    color: #999;
                    line-height: 24px;
                    & td {
                        padding-top: 40px;
                        text-align: center;
                        color: #999;
                        width: 100%;
                        line-height: 24px;
                        &:first-child {
                            width: 210px;
                        }
                    }
                }
            }

            &.address_info {
                & tr {
                    & td {
                        text-align: left;
                    }
                }
            }

            & button {
                white-space: nowrap;
            }
        }
        & .delivery_cached_info{
            & table {
                position: relative;
                width: 100%;
                & tr{
                    &:first-child {
                        & td {
                            padding-top: 20px;
                        }
                    }
                    & td {
                        padding: 0 20px 22px 20px;
                        color: #515355;
                        line-height: 24px;
                        vertical-align: middle;
                        & b {
                            font-weight: 500;
                        }
                        &:last-child {
                            text-align: right;
                        }
                    }
                }
                & tfoot {
                    border-top: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    background: #F6F6F6;
                    & tr {          
                        &:first-child {
                            & td {
                                padding-top: 20px;
                                padding-bottom: 16px;
                            }
                        }
                        &:last-child {
                            & td {
                                padding-bottom: 20px;
                            }
                        }
                        &.cached_total{
                            & td:first-child {
                                color: #222;
                                font-size: 1.125rem;
                                line-height: 27px;
                                font-weight: 700;
                            } 
                            & td:last-child {
                                color: #1EA0FA;
                                font-size: 1rem;
                                line-height: 24px;
                                & b {
                                    line-height: 33px;
                                    font-size: 1.375rem;
                                    font-weight: 700;
                                }
                            }
                        }
                        &.refund_info {
                            padding-top: 0;
                            & td {
                                padding-bottom: 20px;
                            }
                        }                        
                    }
                }
            }
            & .refund_info_desc {
                margin-top: 10px;
                font-size: 0.875rem;
                line-height: 21px;
                color: #888;
            }
        }

        @media only screen and (max-width: 960px) {
            margin-top: 0;
            & .header.delivery {
                padding: 18px 16px;
                border-bottom: none;
                background: url(${list_open}) no-repeat right 16px center;
                background-size: 14px 8px;
                cursor: pointer;

                &.active {
                    background: url(${list_close}) no-repeat right 16px center;
                    background-size: 14px 8px;
                }

                & h2{
                    font-size: 1.125rem;
                    font-weight: 500;
                    line-height: 27px;
                    color: #222;
                    & span {
                        margin-left: 10px;
                        font-size: 0.938rem;
                        color: #515355;
                        font-weight: 500;
                    }
                }
            }   
            & .delivery_info{
                display: none;
                width: 100%;
                margin: 12px 0 30px;
                &.active {
                    display: table;
                }
                
                & tr{
                    & td {
                        line-height: 21px;
                        padding: 0 15px 16px 15px;
                        color: #222;
                        font-size: 0.875rem;
                        font-weight: 400;
                    }
                    &:last-child {
                        & td {
                            padding-bottom: 0;
                        }
                    }

                    &.no_delivery {
                        & td {
                            width: 100%;
                            padding-top: 0;
                            line-height: 21px;
                            font-size: 0.875rem;
                        }
                    }
                }
                &.address_info {
                    & tr {
                        & td {
                            text-align: left;
                            word-break: break-all;
                            &:first-child {
                                width: 80px;
                            }
                        }
                    }
                }
            }
            & .delivery_cached_info{
                display: none;
                &.active {
                    display: block;
                }
                & table {
                    width: calc(100% - 30px);
                    margin: 0 auto;
                    
                    & tr{
                        & td {
                            padding: 0 0 16px 0;
                            line-height: 20px;
                            font-size: 0.875rem;
                            & b {
                                font-size: 1rem;
                                line-height: 22px;
                            }
                        }
                        &:first-child {
                            & td {
                                padding-top: 12px;
                            }
                        }
                        &:last-child {
                            & td {
                                padding-bottom: 25px;
                            }
                        }
                    }

                    & tfoot {
                        border-top: 1px solid #ccc;
                        border-bottom: none;
                        background: transparent;
                        & tr {          
                            &:first-child {
                                & td {
                                    padding-top: 24px;
                                    padding-bottom: 13px;
                                }
                            }
                            &:last-child {
                                & td {
                                    padding-bottom: 30;
                                }
                            }
                            &.cached_total{
                                & td:first-child {
                                    font-size: 1rem;
                                    line-height: 22px;
                                } 
                                & td:last-child {
                                    font-size: 1.125rem;
                                    line-height: 25px;
                                }
                            }
                            &.refund_info td{
                                padding: 0 0 10px 0;
                            }
                        }
                    }
                }
                & .refund_info_desc {
                    padding: 0 15px;
                    margin-top: 0;
                    font-size: 0.75rem;
                    line-height: 17px;
                    color: #888;
                    letter-spacing: -0.02em;
                }
            }
        }
    }

    & .bottom_order_all_delete {
        padding: 30px 15px 40px;
        & div {
            font-weight: 700;
        }
        & p {
            margin-top: 10px;
            font-size: 0.813rem;
            color: #888;
            line-height: 18px;
        }
    }

    & .price_guide {
        margin-top: 60px;
        padding: 25px;
        background: #f6f6f6;
        & .tit {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            font-size: 14px;
            color: #515355;
            font-weight: 500;
        }
        & .sub_guide {
            padding-left: 30px;
        }
        @media only screen and (max-width: 960px) {
            margin-top: 30px;
            padding: 30px 15px;
            & .tit {
                margin-bottom: 5px;
                font-size: 13px;
                line-height: 19.5px;
            }
            & .sub_guide {
                padding-left: 5px;
                & span {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }
`

export default DeliveryProductDetail;