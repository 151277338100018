import React from 'react';
import Styled from "styled-components";
import { ReactComponent as Reservation } from "utils/icon-loader.svg"

const Loading = Styled.div`
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Loader = () => {
    return (
        <Loading>
            <Reservation width="70" height="70" fill="#1EA0FA" />
        </Loading>
    )
};

export default Loader;
