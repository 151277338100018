import {
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_DETAIL_REQUEST,
    USER_DETAIL_SUCCESS,
    USER_DETAIL_FAIL,
    USER_DETAIL_RESET,
    DEFINE_APP_USER, USER_SIGNUP_REQUEST, USER_SIGNUP_SUCCESS, USER_SIGNUP_FAIL
} from '../actions/types';

export const userLoginReducer = (state = {loading: true, userInfo:{}, errorMsg: {}}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true };
        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_LOGIN_FAIL:
            return { loading: true, errorMsg: action.payload };
        case USER_LOGOUT:
            return {loading: true, userInfo: {}};
        default:
            return state;
    }
};

export const userDetailsReducer = (state = {user: {}}, action) => {
    switch (action.type){
        case USER_DETAIL_REQUEST:
            return {loading: true, user: {}};
        case USER_DETAIL_SUCCESS:
            return {loading: false, user: action.payload};
        case USER_DETAIL_FAIL:
            return {loading: false, error: action.payload};
        case USER_DETAIL_RESET:
            return {user: {}};
        case DEFINE_APP_USER:
            return {user: {device: action.payload}}
        default:
            return state;
    }
}

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type){
        case USER_SIGNUP_REQUEST:
            return {loading: true }
        case USER_SIGNUP_SUCCESS:
            return {loading: false, userInfo: action.payload}
        case USER_SIGNUP_FAIL:
            return {loading: false, errorMsg: action.payload}
        default:
            return state;
    }
};

