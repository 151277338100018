import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useSelector} from "react-redux"
import 'antd/dist/antd.css';
import { IoIosArrowDown } from 'react-icons/io'
import { BsDot } from "react-icons/bs";
import { useMediaQuery } from 'react-responsive';
import axios from "axios";
import Moment from "moment";
import {stringPrice} from "../../../utils/StringPrice";

const MySavedMoney = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const [checkPoints, setCheckPoints] = useState([]);
  const [limit, setLimit] = useState(20)

  const moreView = () => {  //더보기 버튼
    setLimit(limit + 10);
  }

  useEffect(()=>{
    const fetchPoints = async () => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          "cid" : userInfo.data.cid,
          "encoded" : userInfo.data.encoded,
        },
      }
      try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}api/user/point`,{}, config);
        setCheckPoints(res)
      } catch(error){
        console.log(error)
      }
    }
    fetchPoints()
  },[setCheckPoints])
  const pointsHistory = checkPoints?.data?.history?.data?.history || []
  const myPoints = checkPoints?.data?.history?.data

  return (
    <MySavedMoneyContainer>
      <div className="mypage_title">
        <h2>나의 알갱이</h2>
      </div>
      <div className="money_wrap">
        <div className="money_info">
          <p><span>사용 가능 알갱이</span><span className="current"><b>{stringPrice(myPoints?.balance_point)}</b></span></p>
        </div>
        <div className="money_list">
          <div className="list_header">
            <span className="date">날짜</span>
            <span className="content">내용</span>
            <span className="exp_date">유효기간</span>
            <span className="amount">사용수량</span>
          </div>
          <ul className="list_body">
            {myPoints?.balance_point === 0 ? <li>
              <p className="no_list">현재 알갱이가 없습니다.</p>
            </li> : null}
            {pointsHistory.slice(0, limit).map((el,idx) => {
              const isMinus = String(el.point).search("-") > -1

              return <>{<li key={idx}>
                <p>
                  <span className="date">{Moment(el.date).format("YYYY-MM-DD")}</span>
                  <span className="content">{el.point_name}</span>
                </p>
                {isMinus ? <span className="amount minus_amount"><b>{stringPrice(el.point)}</b></span> : <span className="amount plus_amount"><b>+{stringPrice(el.point)}</b></span>}
              </li>}
              </>
            })}
          </ul>
        </div>
        <div className="list_more_btn">
          {pointsHistory.length > limit && pointsHistory.length >= limit ? <button onClick={moreView}>더보기<IoIosArrowDown /></button>: null}
        </div>
      </div>
      <div className="mypage_guide">
        <p><span><BsDot /></span><span>주문 취소 시, 지급되었던 알갱이는 자동 차감됩니다.</span></p>
        <p><span><BsDot /></span><span>알갱이 사용 내역은 최근 90일까지만 조회할 수 있습니다.</span></p>
        <p className="red"><span><BsDot /></span><span>소멸 예정 알갱이는 유효기한이 당월 말일인 알갱이입니다.</span></p>
      </div>
    </MySavedMoneyContainer>
  );
};

const MySavedMoneyContainer = styled.div`
  & .no_list{
    text-align: center;
    width: 100%;
    color: #888;
    margin: 60px auto;
  }
  
  & .money_info {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    border: 1px solid #ddd;
    & > p {
      display: flex;
      position: relative;
      align-items: center;
      width: 50%;
      padding: 30px 110px 30px 100px;
      &:first-child::after {
        content: "";
        position: absolute;
        right: 0;
        top: 30px;
        height: 40px;
        width: 1px;
        visibility:hidden;
        background: #E1E2E3;
      }
      & span:first-child {
        font-size: 1.063rem;
        line-height: 25px;
        color: #222;
      }
      & span:last-child {
        margin-left: auto;
        font-size: 1.125rem;
        line-height: 27px;
        color: #888;
        &.current {
          color: #009DE5;
        }
        & b {
          line-height: 39px;
          margin-right: 3px;
          font-weight: 500;
          font-size: 1.625rem;
        }
      }
    }

    @media only screen and (max-width: 960px) {
      width: calc(100% - 30px);
      margin: 0 auto 20px;
      border-radius: 10px;
      & > p {
        flex-direction: column;
        width: 50%;
        padding: 25px 0;
        &:first-child::after {
          right: 0;
          top: 33px;
          height: 50px;
          background: #eee;
        }
        & span {
          display: block;
          text-align: center;
        }
        & span:first-child {
          font-size: 0.938rem;
          line-height: 22px;
        }
        & span:last-child {
          margin-left: 0;
          margin-top: 6px;
          font-size: 1.063rem;
          line-height: 25px;
          & b {
            line-height: 34px;
            font-size: 1.438rem;
          }
        }
      }
    }
  }

  & .money_list {
    & .list_header {
      border-top:1px solid #555555;
      border-bottom: 1px solid #ccc;
      & span {
        &.amount {
          padding-right: 43px;
        }
      }
    }

    & ul {
      & li {
        display: flex;
        border-bottom:1px solid #E1E2E3;
        & p {
          width: calc(100% - 260px);
          & .content {
            width: calc(100% - 335px);
          }
        }
      }
    }

    & span {
      display: inline-block;
      padding: 17px 0;
      color: #222;
      line-height: 24px;
      font-size: 1rem;
      &.date {
        width: 125px;
        margin-right: 125px;
        text-align: center;
      }
      &.content {
        width: calc(100% - 595px);
      }
      &.exp_date {
        width: 85px;
        text-align: center;
        visibility:hidden;
        & b {
          font-weight: 400;
        }
      }
      &.amount {
        width: 260px;
        padding-right: 30px;
        text-align: right;
        &.plus_amount {
          color: #1EA0FA;
        }
        &.minus_amount {
          color: #888888;
        }
        b {
          font-weight: 400;
        }
      }
    }

    @media only screen and (max-width: 960px) {
      border-top : 2px solid #EEF1F3;
      & .list_header {
        display: none;
      }

      & ul {
        padding: 0 15px;
        & li {
          padding: 20px 0;
          border-bottom:1px solid #eee;
          align-items: center;
          & p {
            width: calc(100% - 100px);
            & .content {
              width: 100%;
            }
          }
        }
      }

      & span {
        display: inline-block;
        padding: 0;
        color: initial;
        line-height: 21px;
        font-size: 0.875rem;
        &:not(:first-child) {
          margin-top: 4px;
        }
        &.date {
          display: block;
          width: 100%;
          margin-right: 0;
          text-align: left;
          color: #888;
        }
        &.content {
          width: 100%;
          font-size: 1rem;
          line-height: 24px;
          font-weight: 500;
        }
        &.exp_date {
          width: 100%;
          text-align: left;
          color: #515355;
          visibility:hidden;
          & b {
            font-weight: 500;
          }
        }
        &.amount {
          width: 100px;
          padding-right: 0;
          font-size: 1.063rem;
          b {
            font-weight: 700;
          }
        }
      }
    }
  }
`

export default MySavedMoney;