import React from 'react';
import styled from "styled-components";
import Button from "pages/components/Button";
import ico_unchecked from "images/ico_unchecked.png";
import ico_check_blue from "images/ico_check_blue.png";

const ChangPW = () => {


  return (
    <ChangePWContainer>
      <div className="mypage_title">
        <h2>비밀번호 변경</h2>
      </div>
      <div className="user_info_wrap">
        <form className="join_wrap">
          <div className="join_box">
            <div className="join_title">임시 비밀번호</div>
            <div className="join_cont">
              <p><input type="password" name="password" placeholder="현재 비밀번호 입력"  /></p>
              <p className="sub_desc">
                <span>영문대문자</span>
                <span>영문소문자</span>
                <span>특수문자</span>
              </p>
              {/* <p className="sub_desc error_msg">입력하신 비밀번호가 맞지 않습니다.</p> */}
            </div>
          </div>
          <div className="join_box">
            <div className="join_title">새 비밀번호</div>
            <div className="join_cont">
              <p><input type="password" name="passwordConfirm" placeholder="영문 대소문자+특수문자 (8자리 이상)" /></p>
              {/* <p className="sub_desc error_msg">대문자, 소문자, 특수문자를 모두 포함해야 합니다.</p> */}
            </div>
          </div>
          <div className="join_box">
            <div className="join_title">새 비밀번호 확인</div>
            <div className="join_cont">
              <p><input type="password" name="passwordConfirm" placeholder="새 비밀번호 확인" /></p>
              {/* <p className="sub_desc error_msg">입력하신 비밀번호가 맞지 않습니다.</p> */}
            </div>
          </div>
        </form>
      </div>
      <div className="button_wrap">
        <MainButton fullWidth>
        비밀번호 변경
        </MainButton>
    </div>
    </ChangePWContainer>
  );
};

const MainButton = styled(Button)`
  @media only screen and (max-width: 960px) {
    height: 50px;
    font-weight: 700;
    font-size: 1rem;
  }
`;

const ChangePWContainer = styled.div`
  & .mypage_title {
    margin-bottom: 15px;
    & span {
      margin-left: 20px;
      font-size: 0.875rem;
      color: #515355;
      line-height: 21px;
      font-weight: 400;
    }
  }

  & .user_info_wrap {
    border-top: 1px solid #333;
    border-bottom: 1px solid #ccc;

    @media only screen and (max-width: 960px) {
      padding: 0 15px;
      border: none;
    }
  }

  & .join_wrap {
    width: 730px;
    margin: 0 auto;
    padding: 60px 0;

    & .join_box {
      display: flex;
      align-items: flex-start;
      width: 100%;
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    & .join_title {
      width: 230px;
      padding-top: 10px;
      font-size: 1rem;
      line-height: 24px;
      color: #222;
    }

    & .join_cont {
      width: calc(100% - 230px);

      & > div {
        display: flex;
        width: 100%;

        & p:first-child {
          width: calc(100% - 130px);
        }
      }

      input {
        color: #222;
      }

      & .sub_desc {
        margin-top: 8px;
        color: #1ea0fa;
        font-size: 0.75rem;
        line-height: 18px;

        span {
          display: inline-block;
          margin-right: 24px;
          padding-right: 13px;
          background: url(${ico_unchecked}) no-repeat right top 5px;
          background-size: 8px;
          font-size: 0.75rem;
          color: #999;
          line-height: 18px;

          &.active {
            background: url(${ico_check_blue}) no-repeat right top 5px;
            color: #1EA0FA;
            background-size: 8px;
          }
        }
      }
    }

    @media only screen and (max-width: 960px) {
      width: 100%;
      padding: 0 0 30px 0;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;

      & .join_box {
        flex-direction: column;
        width: 100%;
        margin-top: 30px;
      }

      & .join_title {
        width: 100%;
        padding-top: 0;
        margin-bottom: 10px;
        font-size: 0.938rem;
        line-height: 22px;
      }

      & .join_cont {
        width: 100%;

        & > div {
          display: flex;
          width: 100%;

          & p:first-child {
            width: calc(100% - 105px);
          }
        }

        & .sub_desc {
          span {
            display: inline-block;
            margin-right: 13px;
            padding-right: 18px;
            background: url(${ico_unchecked}) no-repeat right top 5px;
            background-size: 8px;

            &.active {
              background: url(${ico_check_blue}) no-repeat right top 5px;
              color: #1EA0FA;
              background-size: 8px;
            }
          }
        }
      }
    }
  }

  & .button_wrap {
    margin: 60px auto 0;
    width: 240px;

    @media only screen and (max-width: 960px) {
      margin: 30px 0;
      padding: 0 15px;
      width: 100%;
    }
  }
`

export default ChangPW;