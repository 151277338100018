import React, {useEffect} from 'react';
import styled from "styled-components";
import event from "images/example/event.png";
import m_event from "images/example/m_event.png";
import { useMediaQuery } from 'react-responsive';
import { Link } from "react-router-dom";
import list_prev from "images/icon/prev_list.png";
import list_next from "images/icon/next_list.png";
import icon_event_list from "images/icon/icon_event_list.png";
import {setEventDetail, setProjectCategory, setProjectDetail} from "../actions/boardActions";
import {useSelector, useDispatch} from "react-redux";

const EventDetailFull = (props) => {
  const isPc = useMediaQuery({ query: '(min-width: 961px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const dispatch = useDispatch();
  const detailInfo = useSelector((state) => state.eventDetail.eventDetail)
  const getId = props.match.params.id

  useEffect(() => {
    dispatch(setEventDetail(getId));
  }, [dispatch, props.match])


  return (
    <EventDetailFullContainer>
      { isPc &&
        <img src={event} alt="이벤트이미지" />
      }
      { isMobile &&
        <img src={m_event} alt="이벤트이미지" />
      }
      <div className="event_list">
        <div className="list_prev">

          <Link to="/event/eventdetail">
            <span>이전글</span>
            <p>업그레이드된 7월의 혜택!</p>
          </Link>

        </div>
        <span className="go_list"><Link to="/event"><img src={icon_event_list} alt="전체목록보기" /></Link></span>
        <div className="list_next">

          <Link to="/event/eventdetail">
            <p>바램펫 카카오톡 채널 추가하고 매월 혜택 소식 받기</p>
            <span>다음글</span>
          </Link>
          
        </div>
      </div>
    </EventDetailFullContainer>
  );
};

const EventDetailFullContainer = styled.div`
  & img {
    display: block;
    width: 100%;
  }

  & .event_list {
    display: flex;
    position: relative;
    max-width: 1480px;
    margin: 60px auto 60px;
    margin-top: 60px;
    padding-top: 40px;
    border-top: 1px solid #515355;
    justify-content: space-between;

    & div {
      display: flex;
      align-items: center;
      width: calc((100% - 70px) / 2);

      & a {
        display: flex;
        align-items: center;
      }
      
      & p {
        width: 280px;
        font-size: 1.375rem;
        color: #222;
        line-height: 33px;
      }

      & span {
        display: inline-block;
        content: "";
        width: 28px;
        height: 20px;
        text-indent: -9999px;
      }

      &.list_prev {
        & span {
          margin-right: 31px;
          background: url(${list_prev}) no-repeat;
        }
      }

      &.list_next {
        justify-content: flex-end;
        & span {
          margin-left: 31px;
          background: url(${list_next}) no-repeat;
        }
      }
      & .go_list {
        & img {
          width: 60px;
        }
      }
    }
    @media only screen and (max-width: 960px) {
      display: block;
      position: relative;
      margin-top: 0;
      padding: 0 0 90px 0;

      & div {
        display: block;
        width: 100%;
        padding: 20px 10px;
        &:first-child {
          border-bottom: 1px solid #E5E5E5;
        }
        &:last-child {
          border-bottom: 1px solid #515355;
        }

        & a {
          display: flex;
          width: 100%;
          align-items: center;
        }
        
        & p {
          display: -webkit-box;
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 1rem;
          line-height: 24px;
        }

        & span {
          display: inline-block;
          margin-right: 20px;
          content: "";
          width: auto;
          height: auto;
          text-indent: inherit;
          color: #999;
          font-size: 0.875rem;
          line-height: 21px;
        }

        &.list_prev {
          & span {
            margin-right: 20px;
            background: none;
          }
        }

        &.list_next {
          justify-content: left;
          & a {
            flex-direction: row-reverse;
            justify-content: left;
          }
          & span {
            margin-left: 0;
            background: none;
          }
        }
      }
    }

    & .go_list {
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      & img {
        width: 60px;
      }
    }
  }
`;

export default EventDetailFull;