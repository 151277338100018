import {
    ADD_TO_ORDER,
    FETCH_ORDER_LISTS,
    FETCH_ORDER_DETAILS,
    APPEND_ORDER_LISTS,
    UPDATE_ORDER_LISTS,
    FETCH_ORDER_REG_LISTS,
    APPEND_ORDER_REG_LISTS,
    UPDATE_ORDER_REG_LISTS
} from "./types";
import axios from "axios";

export const addToOrder = (item, price, healthDevice) => (dispatch,getState) => {
    let regular = item[0].regular || null
    try{
        dispatch({
            type: ADD_TO_ORDER,
            payload: {item, price,regular, healthDevice},
        });
        sessionStorage.setItem('orderItems', JSON.stringify(getState().orderItems.orderItems));
        document.location.href = "/order"
    }catch(error){
        console.log(error)
    }
}

export const fetchOrderDetails = (id) => async (dispatch, getState) => {
    const { userLogin: {userInfo} } = getState();
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
            },
        }
        const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/user/order/detail/${id}`, config);


        let fixedData = data.data

        const reqOption = fixedData.option.reduce((arr, opt) => {
            arr.push(opt.filter((el) => el.require === true))
            return arr
        },[])
        const addOption = fixedData.option.reduce((arr, opt) => {
            arr.push(opt.filter((el) => el.require === false))
            return arr
        },[])


        fixedData = {
            ...data.data,
            products: { product: fixedData.product, reqOption, addOption}
        }

        dispatch({
            type: FETCH_ORDER_DETAILS,
            payload: fixedData
        })

    } catch(error){
        console.log(error);
    }
}
//regular_detail
export const fetchOrderRegularDetails = (id) => async (dispatch, getState) => {
    const { userLogin: {userInfo} } = getState();
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
            },
        }
        // const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/user/order/regular_detail/${id}`, config);
        const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/user/order/regular_list/${id}`, config);

        let fixedData = data.data

        if (typeof fixedData.devices === "string") {
            fixedData.devices = JSON.parse(fixedData.devices);
        }

        fixedData = {
            ...data.data,
            // ...data.data.RegularOrders[0],
            products: { product: fixedData.product},
            review: data.review
        }

        dispatch({
            type: FETCH_ORDER_DETAILS,
            payload: fixedData
        })

    } catch(error){
        console.log(error);
    }
}
export const fetchOrderRegularDetailsdetail = (id) => async (dispatch, getState) => {
    const { userLogin: {userInfo} } = getState();
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                        "encoded" : userInfo.data.encoded,
            },
        }
        const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/user/order/regular_detail/${id}`, config);

        let fixedData = data.data
        let fixedData2 = data.data2


        fixedData = {
            ...data.data,
            device: fixedData2,
            is_first: data.data2.total_ordered_times,
            products: { product: fixedData.product}
        }

        dispatch({
            type: FETCH_ORDER_DETAILS,
            payload: fixedData
        })

    } catch(error){
        console.log(error);
    }
}


export const fetchOrderLists = (page, isRegular, tab) => async (dispatch, getState) => {
    const { userLogin: {userInfo} } = getState();
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                "encoded" : userInfo.data.encoded,
            },
        }
        let tab1State = 0;
        let tab2State = 0;

        if (tab) {
            switch (tab.tab1) {
                case "전체":
                    tab1State = 0;
                    break;
                case "일반배송":
                    tab1State = 1;
                    break;
                case "정기배송":
                    tab1State = 2;
                    break;
                default:
                    tab1State = 0;
                    break;
            }
            switch (tab.tab2) {
                case "전체":
                case "입금전":
                    tab2State = 0;
                    break;
                case "결제완료":
                    tab2State = 1;
                    break;
                case "배송중":
                    tab2State = 2;
                    break;
                case "배송지연":
                    tab2State = 3;
                    break;
                case "배송완료":
                    tab2State = 4;
                    break;
                case "교환/반품":
                    tab2State = 5;
                    break;
                case "환불완료":
                    tab2State = 6;
                    break;
                default:
                    tab2State = 0;
                    break;
            }


        }
        const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/user/order?page=${page}&isRegular=${isRegular}&tab1=${tab1State}&tab2=${tab2State}`, config);

        for (let i = 0; i < data.data.length; i++) {
            data.data[i].page = page;
        }
        dispatch({
            type: FETCH_ORDER_LISTS,
            payload: {data : data.data, data_count : data.data.length, total_count: data.count}  //orderDetails.js에서 확인
        })

    }catch(error){
        console.log(error);
    }
}
export const appendOrderLists = (page, isRegular, tab) => async (dispatch, getState) => {
    const { userLogin: {userInfo} } = getState();
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                "encoded" : userInfo.data.encoded,
            },
        }
        let tab1State = "0";
        let tab2State = "0";

        if (tab) {
            switch (tab.tab1) {
                case "전체":
                    tab1State = 0;
                    break;
                case "일반배송":
                    tab1State = 1;
                    break;
                case "정기배송":
                    tab1State = 2;
                    break;
                default:
                    tab1State = 0;
                    break;
            }
            switch (tab.tab2) {
                case "전체":
                case "입금전":
                    tab2State = 0;
                    break;
                case "결제완료":
                    tab2State = 1;
                    break;
                case "배송중":
                    tab2State = 2;
                    break;
                case "배송지연":
                    tab2State = 3;
                    break;
                case "배송완료":
                    tab2State = 4;
                    break;
                case "교환/반품":
                    tab2State = 5;
                    break;
                case "환불완료":
                    tab2State = 6;
                    break;
                default:
                    tab2State = 0;
                    break;
            }


        }
        const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/user/order?page=${page}&isRegular=${isRegular}&tab1=${tab1State}&tab2=${tab2State}`, config);
        for (let i = 0; i < data.data.length; i++) {
            data.data[i].page = page;
        }
        dispatch({
            type: APPEND_ORDER_LISTS,
            payload: {data : data.data, data_count : data.data.length}  //orderDetails.js에서 확인
        })

    }catch(error){
        console.log(error);
    }
}
export const updateOrderLists = (page, isRegular, tab) => async (dispatch, getState) => {
    const { userLogin: {userInfo} } = getState();
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                "encoded" : userInfo.data.encoded,
            },
        }
        let tab1State = "0";
        let tab2State = "0";

        if (tab) {
            switch (tab.tab1) {
                case "전체":
                    tab1State = 0;
                    break;
                case "일반배송":
                    tab1State = 1;
                    break;
                case "정기배송":
                    tab1State = 2;
                    break;
                default:
                    tab1State = 0;
                    break;
            }
            switch (tab.tab2) {
                case "전체":
                case "입금전":
                    tab2State = 0;
                    break;
                case "결제완료":
                    tab2State = 1;
                    break;
                case "배송중":
                    tab2State = 2;
                    break;
                case "배송지연":
                    tab2State = 3;
                    break;
                case "배송완료":
                    tab2State = 4;
                    break;
                case "교환/반품":
                    tab2State = 5;
                    break;
                case "환불완료":
                    tab2State = 6;
                    break;
                default:
                    tab2State = 0;
                    break;
            }


        }
        const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/user/order?page=${page}&isRegular=${isRegular}&tab1=${tab1State}&tab2=${tab2State}`, config);

        dispatch({
            type: UPDATE_ORDER_LISTS,
            payload: {data : data.data, data_count : data.data.length, total_count: data.count}  //orderDetails.js에서 확인
        })

    }catch(error){
        console.log(error);
    }
}

export const fetchOrderLists_reg = (page, isRegular, tab) => async (dispatch, getState) => {
    const { userLogin: {userInfo} } = getState();
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                "encoded" : userInfo.data.encoded,
            },
        }
        let tab1State = 0;
        let tab2State = 0;

        if (tab) {
            switch (tab.tab1) {
                case "전체":
                    tab1State = 0;
                    break;
                case "일반배송":
                    tab1State = 1;
                    break;
                case "정기배송":
                    tab1State = 2;
                    break;
                default:
                    tab1State = 0;
                    break;
            }
            switch (tab.tab2) {
                case "전체":
                case "입금전":
                    tab2State = 0;
                    break;
                case "결제완료":
                    tab2State = 1;
                    break;
                case "배송중":
                    tab2State = 2;
                    break;
                case "배송지연":
                    tab2State = 3;
                    break;
                case "배송완료":
                    tab2State = 4;
                    break;
                case "교환/반품":
                    tab2State = 5;
                    break;
                case "환불완료":
                    tab2State = 6;
                    break;
                default:
                    tab2State = 0;
                    break;
            }


        }
        const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/user/order?page=${page}&isRegular=${isRegular}&tab1=${tab1State}&tab2=${tab2State}`, config);

        for (let i = 0; i < data.data.length; i++) {
            data.data[i].page = page;
        }
        dispatch({
            type: FETCH_ORDER_REG_LISTS,
            payload: {data : data.data, data_count : data.data.length, total_count: data.count}  //orderDetails.js에서 확인
        })

    }catch(error){
        console.log(error);
    }
}
export const appendOrderLists_reg = (page, isRegular, tab) => async (dispatch, getState) => {
    const { userLogin: {userInfo} } = getState();
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                "encoded" : userInfo.data.encoded,
            },
        }
        let tab1State = "0";
        let tab2State = "0";

        if (tab) {
            switch (tab.tab1) {
                case "전체":
                    tab1State = 0;
                    break;
                case "일반배송":
                    tab1State = 1;
                    break;
                case "정기배송":
                    tab1State = 2;
                    break;
                default:
                    tab1State = 0;
                    break;
            }
            switch (tab.tab2) {
                case "전체":
                case "입금전":
                    tab2State = 0;
                    break;
                case "결제완료":
                    tab2State = 1;
                    break;
                case "배송중":
                    tab2State = 2;
                    break;
                case "배송지연":
                    tab2State = 3;
                    break;
                case "배송완료":
                    tab2State = 4;
                    break;
                case "교환/반품":
                    tab2State = 5;
                    break;
                case "환불완료":
                    tab2State = 6;
                    break;
                default:
                    tab2State = 0;
                    break;
            }


        }

        const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/user/order?page=${page}&isRegular=${isRegular}&tab1=${tab1State}&tab2=${tab2State}`, config);
        for (let i = 0; i < data.data.length; i++) {
            data.data[i].page = page;
        }
        dispatch({
            type: APPEND_ORDER_REG_LISTS,
            payload: {data : data.data, data_count : data.data.length}  //orderDetails.js에서 확인
        })

    }catch(error){
        console.log(error);
    }
}
export const updateOrderLists_reg = (page, isRegular, tab) => async (dispatch, getState) => {
    const { userLogin: {userInfo} } = getState();
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "cid" : userInfo.data.cid,
                "encoded" : userInfo.data.encoded,
            },
        }
        let tab1State = "0";
        let tab2State = "0";

        if (tab) {
            switch (tab.tab1) {
                case "전체":
                    tab1State = 0;
                    break;
                case "일반배송":
                    tab1State = 1;
                    break;
                case "정기배송":
                    tab1State = 2;
                    break;
                default:
                    tab1State = 0;
                    break;
            }
            switch (tab.tab2) {
                case "전체":
                case "입금전":
                    tab2State = 0;
                    break;
                case "결제완료":
                    tab2State = 1;
                    break;
                case "배송중":
                    tab2State = 2;
                    break;
                case "배송지연":
                    tab2State = 3;
                    break;
                case "배송완료":
                    tab2State = 4;
                    break;
                case "교환/반품":
                    tab2State = 5;
                    break;
                case "환불완료":
                    tab2State = 6;
                    break;
                default:
                    tab2State = 0;
                    break;
            }


        }
        const {data} = await axios.get( `${process.env.REACT_APP_API_URL}api/user/order?page=${page}&isRegular=${isRegular}&tab1=${tab1State}&tab2=${tab2State}`, config);

        dispatch({
            type: UPDATE_ORDER_REG_LISTS,
            payload: {data : data.data, data_count : data.data.length, total_count: data.count}  //orderDetails.js에서 확인
        })

    }catch(error){
        console.log(error);
    }
}