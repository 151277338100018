import {
    ADD_TO_CART,
    // FETCH_CART_LISTS,
    REMOVE_FROM_CART,
    // GET_CART_LIST, SET_CART_SERVER_LIST,
    SET_CART_NOMAL, SET_CART_REGUALAR, ADD_CART_NOMAL, ADD_CART_REGUALAR

} from 'actions/types';

export const cartReducer = (state = { cartItems: []}, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            if (action.payload.id) {
                let item = JSON.parse(localStorage.getItem("cartItems"));
                if (item) {
                    item.push(action.payload);
                } else {
                    item = [action.payload];
                }
                localStorage.setItem('cartItems', JSON.stringify(item))
            }

            return Object.assign({}, state, {
                cartItems: [...state.cartItems, action.payload]
            })
        case REMOVE_FROM_CART:
            localStorage.setItem('cartItems', JSON.stringify(state.cartItems.filter(el => el.id !== action.payload.id)))
            return Object.assign({}, state, {
                cartItems: state.cartItems.filter(el => el.id !== action.payload.id)
            });
        default:
            return state;
    }
};

export const refreshCartList = (state = { cartNomalItems: [],cartRegularItems: []}, action) => {
    switch (action.type) {
        case SET_CART_NOMAL:
            return Object.assign({}, state, {cartNomalItems: action.payload})
        case SET_CART_REGUALAR:
            return Object.assign({}, state, {cartRegularItems: action.payload})
        case ADD_CART_NOMAL:
            return Object.assign({}, state, {cartNomalItems: [...state.cartNomalItems, action.payload]})
        case ADD_CART_REGUALAR:
            return Object.assign({}, state, {cartRegularItems: [...state.cartRegularItems, action.payload]})
        default:
            return state;
    }
};
