import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { useTabs } from "react-headless-tabs";
import { TabSelector } from "pages/components/TabSelector";
import $ from "jquery";
import { useMediaQuery } from "react-responsive";
import icon_new from "images/icon/icon_new.png";
import icon_paperclip from "images/icon/icon_paperclip.png";
import icon_download from "images/icon/icon_download.png";
import m_icon_new from "images/m/icon_new.png";
import m_icon_paperclip from "images/m/icon_paperclip.png";
import m_icon_download from "images/m/icon_download.png";
import {setBoardLists} from "../../../actions/boardActions";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {useCookies} from "react-cookie";
import TabContents from "./TabContents";
import {useHistory} from "react-router-dom";
import QueryString from "qs";
import Moment from "moment";

const MeunDownloads = (props) => {
    const isPc = useMediaQuery({ query: "(min-width: 960px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 960px)" });
    const [cookies, setCookie] = useCookies(["MANUAL_:id"])
    const dispatch = useDispatch();
    const boardLists = useSelector((state) => state.boardLists)
    const {lists, loading} = boardLists;
    const [selectedTab, setSelectedTab] = useTabs([
        "밀리",
        "웰리",
        "피트니스 (Pro)",
        "피트니스",
        "라일리",
    ]);

    const [noticeData, setNoticeData] = useState([]);
    const [selectSub, setSelectSub] = useState("listMain1");
    const [showDetail, setShowDetail] = useState([]);
    const [listsMap, setListsMap] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [clickContent, setClickContent] = useState({id:0,state:false});
    let startContent = 0;
    let endContent = 0;
    const history =   useHistory();
    const queryData = QueryString.parse(window.location.search, {ignoreQueryPrefix: true});
    let is_rendering = false
    useEffect(() => {
        is_rendering = true
        setSelectedTab("밀리");
        setSelectSub("listMain1");
        setTimeout(() => {
            is_rendering = false
        }, 500)
    },[])
    useEffect( () => {
        if (Object.keys(queryData).length < 1 || !queryData.category)  { return }
        if(is_rendering){return}
        if (!boardLists.loading) {
            for (let i = 0; i < Object.keys(queryData).length; i++) {
                switch (Object.keys(queryData)[i]) {
                    case "category":
                        switch (queryData.category) {
                            case "밀리":
                                setSelectSub("listMain1");
                                break;
                            case "피트니스 (Pro)":
                                setSelectSub("listMain2");
                                break;
                            case "피트니스":
                                setSelectSub("listMain3");
                                break;
                            case "라일리":
                                setSelectSub("listMain4");
                                break;
                            case "웰리":
                                setSelectSub("listMain5");
                                break;
                        }
                        setSelectedTab(queryData.category);
                        break;
                    case "page":
                        setCurrentPage(queryData.page);
                        break;

                }
            }
        }
    }, [loading])

    useEffect(() => {
        const params = new URLSearchParams();
        params.set("id",props.id);
        params.set("category", selectedTab);
        if (currentPage >= 1) {
            params.set("page", currentPage);
        }
        history.push({search: params.toString() });
    }, [selectSub, selectedTab, currentPage])

    useEffect(() => {
        dispatch(setBoardLists("Manual"));
    }, [dispatch]);
    useEffect(() => {
        if (!noticeData[`${selectSub}`]) {
            return
        }
        const selectedNotice = noticeData[`${selectSub}`].filter((e) => e.category === `${selectedTab}`);
        setTotalPage(selectedNotice.length);
    }, [noticeData, selectSub, selectedTab])
    useEffect(()=>{
        if (lists.data) {
            try {
                setNoticeData({
                    listMain1: lists.data.filter((e) => e.category === "밀리"),
                    listMain2: lists.data.filter((e) => e.category === "피트니스 (Pro)"),
                    listMain3: lists.data.filter((e) => e.category === "피트니스"),
                    listMain4: lists.data.filter((e) => e.category === "라일리"),
                    listMain5: lists.data.filter((e) => e.category === "웰리"),
                });
            } catch (e) {
                console.log(e)
            }
        }

        $(function () {
            $(".list_title_wrap").on("click", function () {
                $(".text_wrap").not($(this).siblings(".text_wrap")).hide();
                $(this).siblings(".text_wrap").toggle();
            });
        });
    },[lists])
    useEffect(() => {
        if (!noticeData[`${selectSub}`]) {
            return
        }
        const selectedNotice = noticeData[`${selectSub}`].filter((e) => e.category === `${selectedTab}`);
        setTotalPage(selectedNotice.length);
        selectedNotice.map((e) => {
            if (!showDetail[e.id]) {
                showDetail[e.id] = false;
                setShowDetail(showDetail);
            }
        })



        endContent = 10;
        startContent = (currentPage * 10) - 10;

        let pagedata = noticeData[`${selectSub}`]
            .filter((e) => e.category === `${selectedTab}`)
            .map((el, idx) => {
                const totalData = noticeData[`${selectSub}`].length

                return (
                <li key={idx}>
                    <div className="list_title_wrap" onClick={(e) => {
                        viewDetail(el.id);
                    }}>
                        <p className="num_wrap">
                            <span className="num">{totalData - idx}</span>
                        </p>
                        <p className="notice_title">
                            <span className="title">{el.title}</span>
                            {el.file &&
                                <span className="clip">
                                    <img src={isPc ? icon_paperclip : m_icon_paperclip} alt="첨부파일" />
                                </span>
                            }
                            {isItNew(el.createdAt) ? (
                                    <span className="new">
                                        <img src={isPc ? icon_new : m_icon_new} alt="new" />
                                    </span>
                            ) : null}
                        </p>
                        <p className="count">{el.hit}</p>
                    </div>
                    {showDetail[el.id] ? (
                        <div className="text_wrap">
                            <div className="board_cont">
                                <div dangerouslySetInnerHTML={ {__html: el.content.replace(/(<? *script)/gi, 'illegalscript')}}/>
                                {el.file ?
                                    <a href={`${process.env.REACT_APP_API_URL}api/download?url=`+el.file+`&filename=`+el.filename} download={el.file.replace(/^.*[\\\/]/, '')}>
                                        <span className="attachment">
                                            {el.filename}
                                        </span>
                                    </a>
                                    : null}
                            </div>
                        </div>
                    ) : null
                    }
                </li>
            )})
        const splice = pagedata.splice(startContent, endContent);
        setListsMap(splice);
        if (splice.length === 0) {
            setCurrentPage(1);
        }
    }, [noticeData, selectSub, selectedTab, currentPage, clickContent])

    const isItNew = (createdAt) => {
        const afterSeven = Moment(createdAt).add(7, "days").format("YYYY-MM-DD");
        const now = Moment().format('YYYY-MM-DD')

        return !Moment(now).isAfter(afterSeven)
    };


    const viewDetail = async (id) => {
        for (let i = 1; i < showDetail.length; i++) {
            if (i === id) {
                showDetail[i] = !showDetail[i];
            } else {
                showDetail[i] = false;
            }
        }
        setShowDetail(showDetail);
        setClickContent({id: id, state: !clickContent.state});
        let now = new Date;
        let afterHour = new Date();
        afterHour.setMinutes(now.getMinutes() +60);
        setCookie(`MANUAL_${id}`, id, {path: '/notice/guide', expires: afterHour});
        if(cookies[`MANUAL_${id}`] === undefined){
            await axios.get(`${process.env.REACT_APP_API_URL}api/board/hit/Manual/${id}`, { withCredentials: true })
        }
    };

    return (
        <StyledDownload>
            <div className="tabNav">
                <TabSelector
                    className="tab"
                    isActive={selectedTab === "밀리"}
                    onClick={(e) => {setSelectedTab("밀리"); setSelectSub("listMain1");}}
                >
                    밀리
                </TabSelector>
                <TabSelector
                    className="tab"
                    isActive={selectedTab === "웰리"}
                    onClick={(e) => {setSelectedTab("웰리"); setSelectSub("listMain5");}}
                >
                    웰리
                </TabSelector>
                <TabSelector
                    className="tab"
                    isActive={selectedTab === "피트니스 (Pro)"}
                    onClick={(e) => {setSelectedTab("피트니스 (Pro)"); setSelectSub("listMain2");}}
                >
                    피트니스 PRO
                </TabSelector>
                <TabSelector
                    className="tab"
                    isActive={selectedTab === "라일리"}
                    onClick={(e) => {setSelectedTab("라일리"); setSelectSub("listMain4");}}
                >
                    라일리
                </TabSelector>
                <TabSelector
                    className="tab"
                    isActive={selectedTab === "피트니스"}
                    onClick={(e) => {setSelectedTab("피트니스"); setSelectSub("listMain3");}}
                >
                    피트니스
                </TabSelector>
            </div>
            <TabContents loading={loading} totalPage={totalPage} selectedTab={selectedTab} listsMap={listsMap} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </StyledDownload>
    );
};

const StyledDownload = styled.div`
  width: 100%;

  & .tabNav {
    margin-bottom: 80px;
    border-bottom: 1px solid #ddd;
    button {
      width: calc(100% / 4);
      height: 78px;
      position: relative;
      font-size: 20px;
      font-weight: 400;
    }
    button:after {
      content: "";
      background: #ddd;
      width: 1px;
      height: 21px;
      left: 0px;
      display: inline-block;
      position: absolute;
    }
    button:first-child:after {
      display: none;
    }
    & .disabled {
      color: #888888;
    }
    & .active {
      font-weight: 500;
      color: #0065ab;
      background: #e8f2f8;
    }

    @media only screen and (max-width: 960px) {
      margin-bottom: 40px;
      border-bottom: none;
      border-top: 1px solid #ddd;
      button {
        width: calc(100% / 2);
        height: 64px;
        border-bottom: 1px solid #ddd;
        font-size: 1rem;
        &:nth-child(2n-1) {
          border-right: 1px solid #ddd;
        }
      }
      button:after {
        content: none;
      }
      button:first-child:after {
        display: none;
      }
    }
  }

  & .TabContents {
    & .notice_wrap {
      border-top: 2px solid #555;
    }

    & .list_title_wrap {
      display: flex;
      align-items: center;
      height: 75px;
      padding: 0 20px;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      &:hover {
        background: #f5fafc;
      }
      & .num_wrap {
        & span {
          display: inline-block;
          width: 80px;
          text-align: center;
          font-size: 0.875rem;
          color: #515355;
          line-height: 21px;
          &.ico_notice {
            padding: 3px 17px;
            background: #fff;
            color: #0065ab;
            border: 1px solid #0065ab;
            border-radius: 30px;
            font-weight: 700;
            line-height: 21px;
          }
        }
      }

      & .notice_title {
        & .title {
          display: inline-block;
          overflow: hidden;
          max-width: 900px;
          margin-left: 40px;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 1.125rem;
          line-height: 27px;
          color: #222;
          &.notice {
            font-weight: 500;
          }
        }

        & .clip {
          margin-left: 2px;
          img {
            width: 20px;
            vertical-align: top;
            margin-top: 5px;
          }
        }

        & .new {
          margin-left: 4px;
          img {
            width: 22px;
            vertical-align: top;
            margin-top: 3px;
          }
        }
      }
      & .count {
        display: inline-block;
        width: 80px;
        margin-left: auto;
        text-align: center;
        color: #515355;
        font-size: 0.875rem;
        line-height: 21px;
      }
    }

    & .text_wrap {
      display: block;
      padding: 20px 10px;
      background: #f8f8f8;
      border-bottom: 1px solid #ddd;

      & > div {
        overflow-y: auto;
        max-height: 535px;
        padding: 40px 120px;

        &::-webkit-scrollbar {
          width: 6px;
          margin-right: 10px;
        }
        &::-webkit-scrollbar-thumb {
          height: auto;
          background-color: #ccc;
          border-radius: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      }

      img {
        margin-bottom: 30px;
      }

      p {
        font-size: 1rem;
        line-height: 24px;
        color: #222;
      }

      .attachment {
        display: inline-block;
        margin-top: 30px;
        padding: 12px 23px 12px 47px;
        border: 1px solid #ddd;
        border-radius: 4px;
        background: #fff url(${icon_download}) no-repeat left 23px center;
        color: #515355;
        line-height: 21px;
        font-size: 0.875rem;
      }
    }

    & .text_wrap_hide{
      display: none;
    }

    @media only screen and (max-width: 960px) {
      padding: 0 15px;
      & .notice_wrap {
        border-top: 1px solid #ccc;
      }
      & .list_title_wrap {
        height: 65px;
        padding: 0;
        &:hover {
          background: #fff;
        }
        & .num_wrap {
          & span {
            width: auto;
            font-size: 0.75rem;
            &.ico_notice {
              width: 45px;
              padding: 3px 11px;
              line-height: 18px;
            }
            &.num {
              display: none;
            }
          }
        }

        & .notice_title {
          overflow: hidden;
          width: 100%;
          & .title {
            margin-left: 0;
            max-width: calc(100% - 53px);
            font-size: 1rem;
            line-height: 24px;
            &.notice {
              margin-left: 8px;
            }
          }

          & .clip {
            img {
              width: 20px;
              margin-top: 4px;
            }
          }

          & .new {
            img {
              width: 18px;
              margin-top: 4px;
            }
          }
        }
        & .count {
          display: none;
        }
      }

      & .text_wrap {
        display: block;
        padding: 20px;
        background: #f8f8f8;
        border-bottom: 1px solid #ddd;

        & > div {
          overflow-y: auto;
          max-height: 100%;
          padding: 0;
        }

        img {
          width: 100%;
          margin-bottom: 16px;
        }

        p {
          font-size: 0.875rem;
          line-height: 21px;
        }

        .attachment {
          margin-top: 16px;
          padding: 12px 14px 12px 41px;
          background: #fff url(${m_icon_download}) no-repeat left 14px center;
          background-size: 15px 15px;
          line-height: 19px;
          font-size: 0.813rem;
        }
      }
    }
  }
`;

export default MeunDownloads;
