import React from "react";
import styled from "styled-components";
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';
import {GrClose} from "react-icons/gr";
import SwiperCore, {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Link} from "react-router-dom"
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Pagination]);

const hide_string = (email) => {
    let id = String(email).split("@")[0]
    if(String(!email).includes("@")){
        id = email
    }
    let answer = ""
    for(let i = 0; i < id.length; i++){
        if(i < id.length/2){
            answer = answer + id[i]
        }else{
            answer = answer + "*";
        }
    }
    return answer
}
const getImage = (review) => {
    if (review.Product) {
        return review.Product.main_img
    }
    if (review.RegularProduct) {
        return review.RegularProduct.main_img
    }
}
const getName = (review) => {
    if (review.Product) {
        return review.Product.name
    }
    if (review.RegularProduct) {
        return review.RegularProduct.name
    }
}

const getOption = (review) => {
    if (review.Order) {
        if (typeof review.Order.option === "string") {
            review.Order.option = JSON.parse(review.Order.option);
            review.Order.product = JSON.parse(review.Order.product);
        }

        const idx = review.Order.option.findIndex(v => v.findIndex(vj => vj.id === review.cart_option_id) !== -1);
        if (idx === -1) return null

        const reviewOption = review.Order.option[idx];
        const productidx = review.Order.product.findIndex(v => v.id === review.ProductId);
        if (productidx === -1) {
            return null
        }
        const optionGroups = review.Order.product[productidx].OptionGroups;

        try {
            return (
                reviewOption.map((v, vidx) => {
                    const optdata = optionGroups.filter(vj => {
                        return vj.Options.findIndex(vk => vk.id === v.OptionId) !== -1
                    })
                    if (optdata.length > 0) {
                        return <><span key={`sub${vidx}`}>[{optdata[0].name}] {v.name} / {v.quantity} 개</span><br/></>
                    } else {
                        return <><span key={`sub${vidx}`}>[옵션] {v.name} / {v.quantity} 개</span><br/></>
                    }
                })
            );
        } catch (e) {

        }
        try {
            return review.Order.option.map((option, idx) => {
                return (
                    <React.Fragment key={idx}>
                        {option.map((v, idx2) => {
                            return (
                                <React.Fragment key={`sub${idx2}`}>
                                    <span>[옵션] {v.name} / {v.quantity} 개</span>
                                    <br/>
                                </React.Fragment>
                            )
                        })}
                    </React.Fragment>
                )
            });
        } catch (e) {
            console.log(e)
        }
    }
    return null
}
const getSwipe = (review) => {
    if(review.is_dummy && review.d_images !== null){
        return (
            review.d_images.map((file, idx) => {
                return (
                    <SwiperSlide key={idx}>
                        <div className="review_img photo"
                             style={{backgroundImage: "url(" + process.env.REACT_APP_URL + file + ")"}}
                        ></div>
                    </SwiperSlide>
                )
            })
        )
    }else{
        if (!review) return
        if (!review.Files) return

        return (
            review.Files.map((file, idx) => {
                if (file.mimetype.indexOf("image") > -1) {
                    return <SwiperSlide key={idx}>
                        <div
                            className="review_img photo"
                            style={{backgroundImage: "url(" + process.env.REACT_APP_URL + file?.publicPath + ")"}}
                        ></div>
                    </SwiperSlide>
                } else {
                    return <SwiperSlide className="swiper-slide-active" key={idx}>
                        <div className="review_img">
                            <video autoPlay={false} preload={true} playsInline={true}>
                                <source src={process.env.REACT_APP_URL + file?.publicPath} type="video/mp4" />
                            </video>
                        </div>
                    </SwiperSlide>
                }
            })
        )
    }
}

function ReviewPopup({visible, onCancelReview, review}) {
    const productLink = review.RegularProductId ? `/products/details/regular/${review.RegularProductId}` : `/products/details/normal/${review.ProductId}`

    return (
        <ReviewContainer visible={visible}>
            <div className="layer_wrap review_layer_wrap">
                <div className="layer_review">
                    <span className="close" onClick={onCancelReview}><GrClose size="20" color="#1B1B1B" alt="닫기"/></span>
                    <div className="review_wrap">
                        <div className="item_info_wrap">
                            <Link to={{
                                pathname: `${productLink}`,
                            }}>
                                <div className="item_info">
                                    <div className="item_img"><img src={getImage(review)} alt="제품이미지"/></div>
                                    <p>{getName(review)}</p>
                                </div>
                            </Link>
                            <p>
                                {review.is_dummy ? <React.Fragment>
                                    <span>{review.name}</span>
                                    <br/>
                                </React.Fragment> : getOption(review)}
                            </p>

                        </div>
                        <div className="review_img_wrap">
                            <Swiper
                                pagination={
                                    {
                                        clickable: true,
                                        type     : "bullets"
                                    }
                                }
                                observer={true}
                                observeParents={true}
                                onSlideChange={(e) => {
                                    const current = e.slides[e.activeIndex];
                                    if (current.children.length < 1) {
                                        return;
                                    }
                                    if (current.children[0].children.length > 0) {
                                        current.children[0].children[0].play();
                                    } else {
                                        const videos = document.querySelectorAll(".review_img > video");
                                        for (let i = 0; i < videos.length; i++) {
                                            videos[i].pause();
                                        }
                                    }
                                }}
                            >
                                {getSwipe(review)}
                            </Swiper>
                        </div>
                        <div className="my_review">
                            <div className="rate">
                                <StyledRate disabled size="16" value={review.point}/><span>{review.email ? hide_string(review.email) : hide_string(review.d_username)}</span>
                            </div>
                            <p>{review.content}</p>
                        </div>
                        <div className="comment_wrap">
                            {review.admin_reply &&
                                <>
                                    <p>댓글 <span>1</span></p>
                                    <div className="comment">
                                        <p>고객만족팀</p>
                                        <p>{review.admin_reply}</p>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </ReviewContainer>
    );
}

const ReviewContainer = styled.div`
  display: ${({visible}) => visible ? 'flex' : 'none'};

  & .layer_wrap {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6) !important;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    & .layer_review {
      display: flex;
      position: relative;
      width: 100%;
      max-width: 1000px;
      max-height: 540px;
      height: 100%;
      padding-right: 2px;
      background: #fff;

      @media only screen and (max-width: 960px) {
        overflow-y: auto;
        flex-direction: column;
        min-height: 100%;
        max-width: 100%;
        padding: 76px 0 0 0;
      }

      & .close {
        display: flex;
        position: absolute;
        top: -40px;
        right: -40px;
        width: 40px;
        height: 40px;
        background: rgba(256, 256, 256, 0.5);
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media only screen and (max-width: 960px) {
          display: inline-block;
          top: 17px;
          right: 17px;
          background: none;
          width: auto;
          height: auto;
          z-index: 9999;
        }

        img {
          width: 20px;

          @media only screen and (max-width: 960px) {
            width: 22px;
          }
        }
      }

      & .swiper-container {
        background: #eee;
      }

      & .review_wrap {
        overflow-y: auto;
        width: 100%;
        padding: 30px 40px 30px 570px;

        @media only screen and (min-width: 961px) {
          & .review_img_wrap {
            position: absolute;
            left: 0;
            top: 0;
            width: 540px;
            height: 540px;

            & .review_img {
              position: relative;
              width: 100%;
              height: 540px;
              max-width: 540px;

              & video {
                position: absolute;
                top: -100%;
                right: -100%;
                bottom: -100%;
                left: -100%;
                width: auto;
                height: auto;
                max-height: 100%;
                max-width: 100%;
                margin: auto;
              }

              &.photo {
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
              }
            }
          }
        }

        & .swiper-pagination {
          display: flex;
          justify-content: center;
          align-items: center;
          bottom: 16px;

          & .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            background: #fff;
            margin: 0 5px;
            opacity: 0.5;

            &.swiper-pagination-bullet-active {
              opacity: 1;
            }
          }
        }

        @media only screen and (max-width: 960px) {
          overflow-y: initial;
          padding: 15px;
          & .swiper-container {
            background: #eee;

            & .swiper-wrapper {
              align-items: center;

              & .swiper-slide {
                width: 100% !important;
                align-items: center;
              }
            }
          }

          & .review_img_wrap {
            margin-bottom: 20px;
            width: 100%;

            & .review_img {
              display: flex;
              align-items: center;
              padding-top: 100%;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;

              & video {
                position: absolute;
                top: 50%;
                left: 50%;
                max-width: 100%;
                max-height: 100%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }

      & .review_wrap::-webkit-scrollbar {
        width: 4px;
        margin-right: 2px;
      }

      & .review_wrap::-webkit-scrollbar-thumb {
        height: auto;
        background-color: #ccc;
        border-radius: 4px;
      }

      & .review_wrap::-webkit-scrollbar-track {
        background-color: #fff;
      }

      & .item_info_wrap {
        padding-bottom: 20px;
        border-bottom: 1px solid #e2e2e2;

        @media only screen and (max-width: 960px) {
          padding-bottom: 15px;
          border-bottom: none;
        }
      }

      & .item_info {
        display: flex;
        margin-bottom: 10px;
        border: 1px solid #ddd;

        & .item_img {
          position: relative;
          overflow: hidden;
          flex: 60px 0 0;

          &::after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }

          & img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            width: auto;
            height: auto;
            width: 100%;
          }
        }

        p {
          display: -webkit-box;
          overflow: hidden;
          padding: 20px 10px;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          height: 21px;
          font-size: 0.875rem;
          line-height: 21px;
        }
      }

      & .item_info_wrap > p {
        word-break: break-all;
        color: #999;
        line-height: 21px;
        font-size: 0.875rem;
      }

      & .my_review {
        padding-bottom: 40px;
        border-bottom: 1px solid #e2e2e2;

        @media only screen and (max-width: 960px) {
          padding-bottom: 30px;
        }

        .rate {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          & span {
            margin-left: auto;
          }
        }

        @media only screen and (max-width: 960px) {
          .rate {
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 76px;
            padding: 0 20px;
            border-bottom: 1px solid #f2f4f6;
            justify-content: center;
          }

          & ul {
            @media only screen and (max-width: 960px) {
              display: block;
              width: 100%;
            }

            .rc-rate-star {
              margin-bottom: 20px;
            }
          }

          span {
            margin: 20px 0 0 auto;
            font-size: 0.75rem;
            color: #999;
            line-height: 18px;

            @media only screen and (max-width: 960px) {
              display: block;
              width: 100%;
              margin: 4px 0 0 0;
              line-height: 150%;
            }
          }
        }

        p {
          white-space: pre-wrap;
          font-size: 1rem;
          line-height: 24px;
          word-break: break-all;
          @media only screen and (max-width: 960px) {
            font-size: 0.938rem;
            line-height: 22.5px;
          }
        }
      }

      & .comment_wrap {
        > p {
          margin: 10px 0 20px;
          font-size: 0.875rem;
          line-height: 21px;

          & span {
            color: #1EA0FA;
          }

          @media only screen and (max-width: 960px) {
            margin: 13px 0 20px;
            line-height: 25px
          }
        }

        & .comment {
          p {
            white-space: pre-wrap;
            font-size: 0.938rem;
            color: #222;
            line-height: 22.5px;

            @media only screen and (max-width: 960px) {
              font-size: 0.875rem;
              line-height: 21px;
            }
          }

          p:first-child {
            margin-bottom: 6px;
            font-weight: 500;
          }
        }
      }
    }
  }
`

const StyledRate = styled(Rate)`
  &.rc-rate {
    font-size: ${({size}) => size}px;

    & .rc-rate-star {
      margin: 20px 0px 12px 0;
      @media only screen and (max-width: 960px) {
        margin: 0 !important;
      }
    }

    & .rc-rate-star-half .rc-rate-star-first, .rc-rate-star-full .rc-rate-star-second {
      color: #1EA0FA;
    }
  }
`

export default ReviewPopup;