import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import FAQ from "./contents/FAQ";
import MeunDownloads from "./contents/MeunDownloads";
import VideoGuidance from "./contents/VideoGuidance";
import { useMediaQuery } from "react-responsive";
import QueryString from "qs";
import Loader from "../components/Loader";

const GuidePage = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 960px)" });
    const [activeId, setActiveId] = useState(3);
    const queryData = QueryString.parse(window.location.search, {ignoreQueryPrefix: true});
    const tagNamesref = useRef();


    const Contents = {
        3: <Loader/>,
        0: <FAQ id={0}/>,
        1: <MeunDownloads id={1}/>,
        2: <VideoGuidance id={2}/>,
    };
    const TagName = ["자주 묻는 질문", "매뉴얼 다운로드", "비디오가이드"];
    const clickHander = (index) => {
        setActiveId(index);
    };
    useEffect(() => {
        if (Object.keys(queryData).length === 1) {
            for (let i = 0; i < Object.keys(queryData).length; i++)   {
                switch (Object.keys(queryData)[i]) {
                    case "id":
                        setActiveId(i);
                        tagNamesref.current.childNodes[queryData.id].click();
                        break;
                }
            }
        }
    }, [queryData])
    useEffect(() => {
        if (Object.keys(queryData).length < 1) {
            setActiveId(0);
            return;
        }

        for (let i = 0; i < Object.keys(queryData).length; i++) {
            switch (Object.keys(queryData)[i]) {
                case "id":
                    tagNamesref.current.childNodes[queryData.id].click();
                    break;
            }
        }
    }, [])
    return (
        <Guide>
            <div className="container">
                <div className="head">
                    <h2 className="title">FAQ</h2>
                    <ul className="tagNames" ref={tagNamesref}>
                        {TagName.map((tag, index) => (
                            <li key={index}
                                className={activeId === index ? "active": ""}
                                onClick={() => clickHander(index)}
                            >
                                {tag}
                            </li>
                        ))}
                    </ul>
                </div>
                {isMobile && <p className="noArea"></p>}
                <div className="content">{Contents[activeId]}</div>
            </div>
        </Guide>
    );
};

const Guide = styled.div`
  & .container {
    max-width: 1480px;
    width: 100%;
    margin: 100px auto;

    & .head {
      display: flex;
      align-items: flex-end;
      padding-bottom: 30px;
      border-bottom: 2px solid #555;
      & .title {
        font-size: 32px;
        font-weight: 400;
      }
      & .tagNames {
        display: flex;
        margin-left: 20px;
        & li{
          cursor: pointer;
          padding: 0 20px;
          font-size: 20px;
          box-sizing: border-box;
          position: relative;
          color: #515355;
        }
        & li.active {
          color: #1ea0fa;
          font-weight: 500;
        }
        & li:first-child:before {
          display: none;
        }
        & li:before {
          content: "";
          position: absolute;
          left: 0;
          top: 3px;
          display: inline-block;
          background: #ccc;
          width: 1px;
          height: 15px;
        }
      }
    }
    @media only screen and (max-width: 960px) {
      max-width: 100%;
      margin: 40px auto 0;

      & .head {
        flex-direction: column;
        align-items: center;
        padding-bottom: 0;
        border-bottom: 2px solid #eef1f3;
        & .title {
          margin-bottom: 10px;
          font-size: 1.375rem;
        }
        & .tagNames {
          width: 100%;
          margin-left: 0;
          & li{
            width: 33.3%;
            padding: 19px 0;
            font-size: 0.938rem;
            line-height: 22px;
            color: #222222;
            text-align: center;
            margin: 0;
          }
          & li.active {
            border-bottom: 2px solid #1ea0fa;
            color: #1ea0fa;
            font-weight: 700;
          }
          & li:first-child:before {
            display: none;
          }
          & li:before {
            content: none;
          }
        }
      }
    }
  }
`;

export default GuidePage;
