import React, {useState, useEffect, useRef, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import 'rc-rate/assets/index.css';
import Button from './Button';
import Dialog from './Dialog';
import Rate from 'rc-rate';
import {useMediaQuery} from 'react-responsive';
import $ from 'jquery';
import photo_del from 'images/icon/photo_del.png';
import m_photo_del from 'images/m/photo_del.png';
import icon_add_photo from 'images/icon/icon_add_photo.png';
import m_icon_add_photo from 'images/m/icon_add_photo.png';
import axios from "axios";
import {fetchReviewDetails} from "../../actions/reviewActions";
import default_thumb from "images/default_thumb.png";


function WriteReviewPopup({visible, onCancel, product, setReviewSubmit, reviewSubmit}) {
    const is_regular = product.item.RegularProductId ? true : false;
    let is_regularadmin = product.item.RegularOrderId ? true : false;
    let itemInfo = is_regular ? product.item : product.item;
    const idx = product.idx;
    const product2 = product.product;
    const isPc = useMediaQuery({query: '(min-width: 960px)'})
    const [valueRate, setValueRate] = useState(0)
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userLogin.userInfo.data || "");
    const fileRef = useRef();
    const vidoeRef = useRef()
    const [images, setImages] = useState([]);
    const [guide, setGuide] = useState("")
    const [typeFile, setTypeFile] = useState("");
    const [fileSize, setFileSize] = useState(0);
    const [videoFileSize, setVideoFileSize] = useState(0);
    const [video, setVideo] = useState(null);
    const [review, setReview] = useState({});
    const [data, setData] = useState({
        name: "",
        OrderId: "",
        ProductId: "",
        RegularProductId: "",
        point: 5,
        content: "",
        image: [],
        video: "",
        is_best: 0,
        is_worst: 0
    });


    const getThumbnail = (url, type) =>{
        if(type == 'video'){
            if(url.indexOf('data:video') > -1){
                return <video id="video" width="100%" height="100%"  src={url} />
                setLoadingVideo(false);
            }else {
                let thumbnail = url.replace('/review', '/review-thumbnail')
                return  <div className="attach_thumb" style={{backgroundImage:`url(\"${thumbnail.substring(0, thumbnail.lastIndexOf("."))}.jpg\"), url("${default_thumb}")`, backgroundSize: "cover"}}></div>

            }
        }else{
            if(url.indexOf('data:image') > -1) {
                return  <div className="attach_thumb" style={{backgroundImage:`url(\"${url}\")`, backgroundSize: "cover"}}></div>
            }else{
                return  <div className="attach_thumb" style={{backgroundImage:`url(\"${url.replace('/review', '/review-thumbnail')}\"), url("${default_thumb}")`, backgroundSize: "cover"}}></div>
            }
        }
    }

    const config = {
        headers: {
            'Content-Type': 'application/json',
            "cid" : userInfo.cid,
            "encoded" : userInfo.encoded,
        }
    }
    useEffect(() => {
        if (is_regularadmin) {

        } else {
            const selectreview = itemInfo.Reviews.filter(v => v.product_id === itemInfo.OrderProducts[idx].ProductId);
            if (selectreview.length > 0) {
                setReview(selectreview)
                setValueRate(selectreview[0].point)
            }
        }
    }, [])
    useEffect(async () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/review/guide`, config).then((guide) => {
            console.log(guide)
            setGuide(guide.data.content);
            // dispatch(fetchReviewDetails())
            setData({
                ...data,
                name: is_regular ? itemInfo?.name : itemInfo.OrderProducts[idx].product_name,
                OrderId: is_regular ? product?.orderId : itemInfo.OrderProducts[0].OrderId,
                ProductId: is_regular ? null : itemInfo.OrderProducts[0].ProductId,
                RegularProductId: is_regular ? itemInfo?.RegularProductId : null,
                point: valueRate,
            })
        }).catch(e => {
            console.log(e);
        });
        return () => {
        }
    }, [dispatch, product, valueRate])


    const inputHandler = useCallback((key) => (e) => {
        if (key === "content") {
            return setData({
                ...data,
                [key]: e.target.value,
            });
        }
        if(key === "point"){
            return setData({
                ...data,
                ["point"]: e.target.value
            })
        }
    }, [data])


    function handleChange(value) {
        setValueRate(value);
    }
    const handleSubmit = async () => {
        if(data.content === ""){
            alert("구매평을 작성해주세요")
            return
        }
        const reviewFormData = new FormData();
        reviewFormData.append("name", product.item.name)
        if (is_regular || is_regularadmin) {
            reviewFormData.append("RegularOrderId", is_regularadmin ? product.item.RegularOrderId : product.orderId)
            reviewFormData.append("RegularProductId", is_regularadmin ? product.item.product_id : product.item.RegularProductId)
        } else {
            reviewFormData.append("OrderId", product.item.OrderProducts[idx].OrderId)
            reviewFormData.append("ProductId", product.item.OrderProducts[idx].ProductId)
            reviewFormData.append("cart_option_id", product.item.OrderProducts[idx].option_id)
        }
        reviewFormData.append("point", data.point)
        reviewFormData.append("content", data.content)
        reviewFormData.append("image", images.length !== 0 ? images.map(image => image.id).join(',') : null)
        reviewFormData.append("video", video ? video.id : null)
        try {
            const response = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}api/review`,
                data: reviewFormData,
                headers: {
                    "Content-Type": "application/json",
                    "cid" : userInfo.cid,
                    "encoded" : userInfo.encoded,
                },
            });
            onCancel("test");
        } catch (error) {
            console.log(error)
                alert("이미 작성된 리뷰입니다.")
        }

    }

    const handleFileButtonClick = () => {
        fileRef.current.click();
    }
    const handleVideoButtonClick = () => {
        vidoeRef.current.click();
    }
    const handleImageChange = async (e) => {
        try {
            let file = e.target.files[0]
            let checkFileSize = Math.round(file.size / 1024 / 1024 * 100) / 100
            let totalFileSize = Math.round((fileSize + file.size) / 1024 / 1024 * 100) / 100
            if (fileSize + file.size > 1024 * 1024 * 20) {
                alert('20MB 까지 등록할 수 있습니다.\n\n' + '현재파일 용량 : ' + checkFileSize.toFixed(2) + 'MB\n\n등록된 파일용량 : ' + totalFileSize.toFixed(2) + 'MB');
            } else {
                setFileSize(fileSize + file.size);
                const formImg = new FormData();
                formImg.append("upload", e.target.files[0]);
                const imgRes = await axios.post(`${process.env.REACT_APP_API_URL}upload/review`, formImg);

                if (e.target.files && e.target.files[0]) {
                    setTypeFile(e.target.files[0].type);
                    let reader = new FileReader();
                    reader.onload = function (e2) {
                        setImages(oldImages => [...oldImages, {
                            id: imgRes.data.id,
                            size: imgRes.data.size,
                            url: e2.target.result
                        }]);
                        fileRef.current.value = "";
                    }
                    reader.readAsDataURL(e.target.files[0]);
                }
            }
        } catch (error) {
            console.log(error)
        }

    }

    const [loadingVideo, setLoadingVideo] = useState(false);

    const handleVideoChange = async (e) => {
        try {
            let file = e.target.files[0]
            let checkFileSize = Math.round(file.size / 1024 / 1024 * 100) / 100
            let totalFileSize = Math.round((file.size + videoFileSize) / 1024 / 1024 * 100) / 100
            if (file.size > 1024 * 1024 * 500) {
                alert('500MB 까지 등록할 수 있습니다.\n\n' + '현재파일 용량 : ' + checkFileSize.toFixed(2) + 'MB\n\n등록된 파일용량 : ' + totalFileSize.toFixed(2) + 'MB');
            } else {
                setVideoFileSize(file.size);
                const formImg = new FormData();
                formImg.append("upload", e.target.files[0]);
                const imgRes = await axios.post(`${process.env.REACT_APP_API_URL}upload/review`, formImg);

                if (e.target.files && e.target.files[0]) {
                    setTypeFile(e.target.files[0].type);
                    let reader = new FileReader();
                    reader.onload = function (e) {
                        setVideo({id: imgRes.data.id, size: imgRes.data.size, url: e.target.result});

                    }

                    reader.readAsDataURL(e.target.files[0]);
                }
            }

        } catch (error) {
            console.log(error)
        }

    }

    const onkeyup = () => {
        let content = $(".text_area").val();
        $('#counter').html("(" + content.length + "/2,000자)");    //글자수 실시간 카운팅

        if (content.length > 2000) {
            alert("최대 2000자까지 입력 가능합니다.");
            $(this).val(content.substring(0, 2000));
            $('#counter').html("(2,000/2,000자)");
        }
    }


    return (
        <WriteReviewDialog visible={visible}>
            <Dialog
                title="구매평 작성"
                cancelText="닫기"
                onCancel={onCancel}
                dialogSize="large"
                fullHeight
                fixHeight
                visible={visible}
            >
                <div className="item_wrap">
                    <div className="item_img">
                        <img src={is_regularadmin ? product.item.product_image : is_regular ? itemInfo.product_image : product2.main_img} alt="제품이미지"/>
                    </div>
                    <div className="item_info">
                        <p>{is_regularadmin ? product.item.product_name : is_regular ? itemInfo?.name : product2.name}</p>
                    </div>
                </div>
                <p className="sub_tit">상품은 어떠셨나요?</p>
                <div className="rate"><StyledRate size="30" onChange={handleChange} value={valueRate}/></div>
                <p className="sub_tit">구매평을 작성해주세요.</p>
                <div className="textarea_wrap">
                    <textarea name="content" onChange={inputHandler("content")}
                              placeholder={guide}
                              onFocus={(e) => {setGuide("") }}
                              rows="4"
                              onKeyUp={() => onkeyup()}
                              className="text_area"
                    >
                    </textarea>
                    <span id="counter">0/2,000자</span>
                </div>
                <p className="sub_tit">포토 첨부 <span>{(fileSize / 1024 / 1024).toFixed(2)}MB/20MB</span></p>

                <div className="attach_wrap" id="image_area">
                    {images.map((image, idx) => {
                        return (
                            <div className="attach_area" key={idx}>
                                {getThumbnail( image.url ,'image')}
                                <span className="del_img" onClick={() => {
                                    var imgs = images.filter(img => img.id !== image.id);
                                    setImages(imgs);
                                    let reducer = (previousValue, currentValue) => {
                                        return previousValue.size + currentValue.size;
                                    }
                                    if (imgs.length === 1) {
                                        setFileSize(imgs[0].size);
                                    } else if (imgs.length > 1) {
                                        setFileSize(imgs.reduce(reducer));
                                    } else {
                                        setFileSize(0);
                                    }
                                }}>
                                {isPc ? <img src={photo_del} alt="포토지우기"/> : <img src={m_photo_del} alt="포토지우기"/>}
                                </span>
                            </div>)
                    })
                    }
                    <div className="attach_area">
                        <div className="attach_thumb" style={{cursor: "pointer"}} onClick={() => handleFileButtonClick()}>
                            <input type="file" ref={fileRef} hidden={true} accept="image/*" name="file" onChange={handleImageChange}/>
                        </div>
                    </div>
                </div>
                {/* 독립몰 serect 동영상 첨부 hidden 처리 */}
                {/* <p className="sub_tit">동영상 첨부 <span>{(videoFileSize / 1024 / 1024).toFixed(2)}MB/500MB</span></p>


                <div className="attach_wrap attach_video">
                    {video && (
                        <div className="attach_area">
                                {getThumbnail(video.url, 'video')}

                            <span className="del_img" onClick={() => {
                                setVideo(null);
                                setVideoFileSize(0);
                            }}>
                                {
                                    <img src={isPc ? photo_del : m_photo_del} alt="동영상지우기"/>
                                }
                            </span>
                        </div>
                    )
                    }

                    {!video && (
                        <div className="attach_area">
                            <div className="attach_thumb" style={{cursor: "pointer"}} onClick={() => handleVideoButtonClick()}>
                                <input type="file" ref={vidoeRef} hidden={true} accept="video/*" name="file" onChange={handleVideoChange}/>
                            </div>
                        </div>
                    )
                    }

                </div> */}
                <div className="button_wrap">
                    <Button size="small" onClick={() => {
                        handleSubmit();
                    }}>등록</Button>
                </div>
            </Dialog>
        </WriteReviewDialog>
    );
}

WriteReviewPopup.defaultProps = {
    newReview: 'true'
}

const WriteReviewDialog = styled.div`
  display: ${({visible}) => visible ? 'flex' : 'none'};

  & div.dialog_wrap {
    padding: 25px 4px;

    & h3 {
      margin-bottom: 30px;
    }

    & .dialog_inner {
      padding: 0 50px 30px 50px;
      max-height: calc(90vh - 92px);
    }

    @media only screen and (max-width: 960px) {
      padding: 0;

      & h3 {
        margin-bottom: 0;
      }

      & .dialog_inner {
        max-height: calc(100% - 60px);
        padding: 15px 15px 30px 15px;
      }
    }
  }

  & .item_wrap {
    display: flex;
    align-items: center;
    height: 60px;
    margin-top: 30px;
    border: 1px solid #ddd;

    &:first-child {
      margin-top: 0;
    }

    & .item_img {
      width: 58px;

      & img {
        display: block;
        width: 100%;
      }
    }

    & .item_info {
      width: calc(100% - 60px);
      padding: 18px 20px 18px 30px;

      & p {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 1rem;
        color: #222;
      }
    }

    @media only screen and (max-width: 960px) {
      margin-top: 0;
      & .item_img {
        width: 60px;

        & img {
          display: block;
          width: 100%;
        }
      }

      & .item_info {
        width: calc(100% - 60px);
        padding: 19px 10px;

        & p {
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 0.875rem;
          color: #515355;
        }
      }
    }
  }

  & .sub_tit {
    margin: 20px 0 10px;
    line-height: 24px;
    color: #222;
    font-weight: 500;

    @media only screen and (max-width: 960px) {
      line-height: 22px;
      font-size: 0.938rem;
    }
  }

  & .rate {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    border: 1px solid #ddd;
  }

  & .textarea_wrap {
    position: relative;

    & textarea {
      padding: 15px 20px;
      border: 1px solid #ddd;
      font-size: 0.875rem;
      color: #222;

      &::placeholder {
        color: #515355;
        font-size: 0.875rem;
      }
    }

    & span {
      position: absolute;
      bottom: 7px;
      right: 11px;
      color: #999;
      font-size: 0.75rem;
      line-height: 18px;
    }
  }

  & .attach_wrap {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    border-bottom: 1px solid #e2e2e2;

    & .attach_area {
      position: relative;
      width: 56px;
      height: 56px;
      margin: 3px;
      border: 1px solid #ddd;
      border-radius: 10px;

      & .img_preview {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

      &:not(:first-child) {
        margin-left: 7px;
      }

      & .attach_thumb {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: url(${icon_add_photo}) no-repeat center;

        & img {
          width: 58px;
        }
      }

      & .attach_thumb_selected {
        display: inline-block;
        position: relative;
        overflow: hidden;
        width: 54px;
        height: 54px;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
      }

      & span {
        position: absolute;
        top: -5px;
        right: -5px;

        & img {
          display: none;
        }

        &.del_img {
          & img {
            display: block;
            width: 20px;
          }
        }
      }
      & span.loading{
        top: 9px;
        right: 9px;
        background: #fff;
      }
    }

    @media only screen and (max-width: 960px) {
      & .attach_area {
        overflow: hidden;
        width: 62px;
        height: 62px;

        &:not(:first-child) {
          margin-left: 5px;
        }

        & .attach_thumb {
          width: 62px;
          height: 62px;
          background: url(${m_icon_add_photo}) no-repeat center;
          background-size: 12px;

          & img {
            width: 100%;
          }
        }

        & span {
          & img {
            display: none;
          }

          &.del_img {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.4);

            & img {
              display: block;
              width: 22px;
            }
          }
        }
      }

      &.attach_video {
        border-bottom: none;
      }
    }
  }

  & .button_wrap {
    display: flex;
    margin: 30px auto 0;
    justify-content: center;

    & div {
      width: 170px;
      border-radius: 4px;
    }

    @media only screen and (max-width: 960px) {
      & div {
        width: ${({newReview}) => newReview ? 'clac(100% - 5px)' : '100%'};
        height: 50px;
        border-radius: 4px;
      }
    }
  }

  & .ButtonWrap {
    display: none;
  }

`

const StyledRate = styled(Rate)`
  &.rc-rate {
    font-size: ${({size}) => size}px;

    & .rc-rate-star {
      margin: 0 1px;
    }
  }

  & .rc-rate-star-half .rc-rate-star-first, .rc-rate-star-full .rc-rate-star-second {
    color: #1EA0FA;
  }
`;

export default WriteReviewPopup;
